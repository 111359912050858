import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueries } from "../../../../query/WSQuery";
import { useMemberClientsQuery } from "../../../../query/payments/queries";
import { selectorRedactedUserName } from "../../../../shared/selectors/selectorRedactedUserName";
import { pluralize } from "../../../../shared/utils/pluralize";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { useMutationUpdateCurrentStep } from "../../mutations/useMutationUpdateCurrentStep";
import { RouteComponentProps } from "../../types";
import { Requirement } from "./Requirement";
import { selectorClientsWithEligibilityRequirements } from "./selectorClientsWithEligibilityRequirements";
import { selectorHasPendingEligibilityRequirements } from "./selectorHasPendingEligibilityRequirements";

export const RouteEligibilityRequirements: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const queryMemberClients = useMemberClientsQuery();
  const [updateCurrentStep] = useMutationUpdateCurrentStep();

  return (
    <LayoutPaymentsSetup onBack={onBack}>
      <WSQueries queries={{ queryMemberClients }}>
        {({ queryMemberClients: { data: memberClients } }) => {
          const hasPendingEligibilityRequirements =
            selectorHasPendingEligibilityRequirements(memberClients);
          const clientsWithEligibilityRequirements =
            selectorClientsWithEligibilityRequirements(memberClients);

          return (
            <>
              <WSText.Heading4 mb="XL">Review & Sign Documents</WSText.Heading4>

              {clientsWithEligibilityRequirements.map(memberClient => (
                <>
                  <WSText mb="XL">
                    {selectorRedactedUserName(memberClient.client.user)} has
                    assigned{" "}
                    {pluralize(
                      memberClient.eligibilityRequirements?.length || 0,
                      { one: "document", other: "documents" }
                    )}{" "}
                    to review. Need help? Contact{" "}
                    {selectorRedactedUserName(memberClient.client.user)}.
                  </WSText>

                  {(memberClient.eligibilityRequirements || []).map(
                    eligibilityRequirement => (
                      <Requirement requirement={eligibilityRequirement} />
                    )
                  )}
                </>
              ))}

              <WSButton
                mb="M"
                onClick={() => {
                  updateCurrentStep({ newStep: 5 });
                  onNext();
                }}
                fullWidth
                name="next"
                disabled={hasPendingEligibilityRequirements}
              >
                Continue
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </LayoutPaymentsSetup>
  );
};
