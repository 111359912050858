import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import { FlowSwitch } from "../../modules/Onboarding/components/OnboardingFlow";
import { OnboardingStep } from "../../modules/Onboarding/types";
import { Layout } from "./Layout";

export const FlowContext = createContext<{
  value: object;
  setValue: Dispatch<SetStateAction<object>>;
}>({ value: {}, setValue: () => {} });

export const useFlowContext = <Value,>() => {
  const { value, setValue } = useContext(FlowContext);

  return [value, setValue] as [Value, Dispatch<SetStateAction<Value>>];
};

type Props = {
  basePath: string;
  title?: string;
  onBack?: (context: any) => void;
  onNext?: (context: any) => void;
  steps: ((context: any) => OnboardingStep[]) | OnboardingStep[];
};

export const Flow: React.FC<Props> = props => {
  const location = useLocation();
  const [value, setValue] = useState<object>({});

  const steps = useMemo(() => {
    if (typeof props.steps === "function") {
      return props.steps(value);
    }

    return props.steps;
  }, [props, value]);

  const currentStep = useMemo(() => {
    return steps.find(step => location.pathname.includes(step.slug));
  }, [location.pathname, steps]);

  const title = useMemo(
    () => currentStep?.title || props.title || "",
    [currentStep?.title, props.title]
  );

  return (
    <FlowContext.Provider value={{ value, setValue }}>
      <Layout
        title={title}
        navigation={steps}
        onClose={() => {
          props.onBack?.(value);
        }}
      >
        <FlowSwitch
          title={title}
          slug={props.basePath}
          path={props.basePath}
          steps={steps}
          onNext={() => {
            props.onNext?.(value);
          }}
          onBack={() => {
            props.onBack?.(value);
          }}
        />
      </Layout>
    </FlowContext.Provider>
  );
};
