import React from "react";
import { usePayeeTaxForms } from "../../../../../query/taxForm/queries/usePayeeTaxForms";
import { WSQueries } from "../../../../../query/WSQuery";
import { MAX_FAILURE_COUNT } from "./RouteVerifyYourIdentityById";
import { Redirect, useLocation } from "react-router-dom";
import { CURRENT_YEAR } from "../../../constants/currentYear";
import { selectorUnverifiedTaxForms } from "../../../selectors/selectorUnverifiedTaxForms";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  useActivities,
  useMemberProfile
} from "../../../../../query/users/queries";
import { selectorPayeeTaxFormIsW9Completed } from "../../../selectors/selectorPayeeTaxFormIsW9Completed";
import { Fullscreen } from "../../../../../components/Fullscreen/Fullscreen";
import { WSLoader } from "@wingspanhq/fe-component-library";

export const RouteVerifyYourIdentity: React.FC = () => {
  const userId = useUserId();
  const location = useLocation();
  const queryTaxForms = usePayeeTaxForms(CURRENT_YEAR);
  const queryMember = useMemberProfile(userId);
  const queryActivity = useActivities(userId);

  return (
    <WSQueries
      queries={{ queryTaxForms, queryMember, queryActivity }}
      renderLoader={() => (
        <Fullscreen>
          <WSLoader.Spinner mt="6XL" size="M" />
        </Fullscreen>
      )}
    >
      {({ queryTaxFormsData, queryMemberData, queryActivityData }) => {
        const lockedTaxForm = queryTaxFormsData.find(
          taxForm =>
            (taxForm.recipientVerification?.failureCount || 0) >=
            MAX_FAILURE_COUNT
        );
        const unverifiedTaxForms =
          selectorUnverifiedTaxForms(queryTaxFormsData);

        const hasTaxForms = queryTaxFormsData.length > 0;
        const allFormsAreVerifiedOrNotRequired = queryTaxFormsData.every(
          taxForm =>
            taxForm.recipientVerification?.verified === true ||
            taxForm.recipientVerification?.verified === undefined
        );

        const skipVerificationStep =
          hasTaxForms && allFormsAreVerifiedOrNotRequired;

        const isRecipientReadyToSubmit =
          queryTaxFormsData.length &&
          queryTaxFormsData.every(
            taxForm =>
              taxForm.recipientReadyToSubmitW9 ||
              taxForm.recipientConfirmedW9Info
          );

        const isW9FlowTotallyCompleted = selectorPayeeTaxFormIsW9Completed(
          queryTaxFormsData,
          queryMemberData
        );

        if (
          location.pathname !==
            "/member/1099-filing/tax-information/confirmation" &&
          queryActivityData.flows?.w9Confirmation?.complete
        ) {
          return <Redirect to="/member/tax-documents" />;
        } else if (lockedTaxForm) {
          return (
            <Redirect
              to={`/member/1099-filing/tax-information/${lockedTaxForm.taxFormId}/locked`}
            />
          );
        } else if (unverifiedTaxForms[0]) {
          return (
            <Redirect
              to={`/member/1099-filing/tax-information/verify-identity/${unverifiedTaxForms[0].taxFormId}`}
            />
          );
        } else if (isW9FlowTotallyCompleted) {
          return <Redirect to="/member/tax-documents" />;
        } else if (isRecipientReadyToSubmit) {
          return (
            <Redirect to="/member/1099-filing/tax-information/confirm-preferences" />
          );
        } else if (skipVerificationStep) {
          return (
            <Redirect to="/member/1099-filing/tax-information/personal-info" />
          );
        }

        return <Redirect to="/member/tax-documents" />;
      }}
    </WSQueries>
  );
};
