import { WSService } from "../../../../utils/WSService";
import { IPayoutListResponse, IPayoutResponse } from "./types";
import { isAfter } from "date-fns";

const service = new WSService("/v2/payments/payouts");

//payouts generated before the API update need to be filtered out.
const apiUpdateDate = new Date(2024, 10, 1);

const isPayoutAfterApiUpdate = (payout: IPayoutResponse): boolean => {
  if (!payout?.createdAt) return false;

  const payoutDate = new Date(payout.createdAt);
  return isAfter(payoutDate, apiUpdateDate);
};

export const getPayouts = async (): Promise<IPayoutListResponse> => {
  const { data } = await service.get("");
  return data.filter(isPayoutAfterApiUpdate);
};

export const getPayout = async (payoutId: string): Promise<IPayoutResponse> => {
  const { data } = await service.get(`/${payoutId}`);
  return data;
};
