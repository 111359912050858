import { DashboardFocusedFeatureContext } from "./useGetDashboardFocusedFeaturesContext";
import { selectorIsTaxOnlyPayeeContext } from "./selectors/selectorIsTaxOnlyPayeeContext";
import {
  PlatformContextType,
  UserAccountType
} from "@wingspanhq/users/dist/lib/interfaces";
import { selectorIsTaxOnlyPayerContext } from "./selectors/selectorIsTaxOnlyPayerContext";

export type CreateDashboardFocusedFeature = (
  context: DashboardFocusedFeatureContext
) => boolean;

export const DashboardFocusedFeaturesConfig = {
  showContractorsEligibility: (context: DashboardFocusedFeatureContext) => {
    return !selectorIsTaxOnlyPayerContext(context);
  },
  // showTaxOnlyPayeeNavigation: (context: DashboardFocusedFeatureContext) => {
  //   return selectorIsTaxOnlyPayeeContext(context);
  // },
  showContractorDetailsTaxOnlyPayerEligibilityWarning: (
    context: DashboardFocusedFeatureContext
  ) => {
    return selectorIsTaxOnlyPayerContext(context);
  },
  showSideNavSwitchAccount: (context: DashboardFocusedFeatureContext) => {
    // isRequestingUserSessionExists ||  isAdminSessionExists || isRequestingUserTokenFromURL ? null : (

    return (
      !context.session.isAdminSessionExists &&
      !context.session.isRequestingUserSessionExists &&
      !context.session.isRequestingUserTokenFromURL
    );
  },
  showSideNavNew: (context: DashboardFocusedFeatureContext) => {
    return (
      !selectorIsTaxOnlyPayeeContext(context) &&
      !selectorIsTaxOnlyPayerContext(context)
    );
  },
  showSideNavGettingStarted: (context: DashboardFocusedFeatureContext) => {
    //         isVisible={!is1099OnlyRecipient}
    return true;
  },
  showSideNavGetPaid: (context: DashboardFocusedFeatureContext) => {
    //showGetPaidNav && !is1099OnlyRecipient && hasPaymentsScope
    return (
      context.hardcoded.showGetPaidNav &&
      !selectorIsTaxOnlyPayeeContext(context) &&
      context.access.hasPaymentsScope
    );
  },
  showSideNavSendPayments: (context: DashboardFocusedFeatureContext) => {
    // showSendPaymentsNav &&  !is1099OnlyRecipient && (hasPaymentsScope || hasCollaboratorsScope)
    return (
      context.hardcoded.showSendPaymentsNav &&
      !selectorIsTaxOnlyPayeeContext(context) &&
      (context.access.hasPaymentsScope || context.access.hasCollaboratorsScope)
    );
  },
  showSideNavPayables: (context: DashboardFocusedFeatureContext) => {
    //   hasPaymentsScope
    return context.access.hasPaymentsScope;
  },
  showSideNavPayees: (context: DashboardFocusedFeatureContext) => {
    // hasCollaboratorsScope
    return context.access.hasCollaboratorsScope;
  },
  showSideNavTaxFillingSection: (context: DashboardFocusedFeatureContext) => {
    return false;

    //hasPaymentsScope && featureFlagsQuery.data?.nec1099FilingV2 && isEnterpriseUser
    return (
      context.access.hasPaymentsScope &&
      // TODO: context.experiments.nec1099FilingV3?
      // context.experiments.nec1099FilingV2 &&
      (context.user.contexts.some(
        c =>
          c === PlatformContextType.TaxOnlyPayer ||
          c === PlatformContextType.Payer
      ) ||
        context.user.userType === UserAccountType.enterprise)
    );
  },
  showSideNavFinancialBenefits: (context: DashboardFocusedFeatureContext) => {
    //!is1099OnlyRecipient && hasFinancesScope
    return (
      !selectorIsTaxOnlyPayeeContext(context) && context.access.hasFinancesScope
    );
  },
  showSideNavLifestyleBenefits: (context: DashboardFocusedFeatureContext) => {
    //!is1099OnlyRecipient && hasAdminScope && !isEnterpriseUser
    return (
      !selectorIsTaxOnlyPayeeContext(context) &&
      context.access.hasAdminScope &&
      context.user.userType !== UserAccountType.enterprise
    );
  },
  showSideNavReports: (context: DashboardFocusedFeatureContext) => {
    //!is1099OnlyRecipient && featureFlagsQuery.data?.reports && (hasPaymentsScope || hasCollaboratorsScope)

    return (
      !selectorIsTaxOnlyPayeeContext(context) &&
      context.experiments.reports &&
      (context.access.hasPaymentsScope || context.access.hasCollaboratorsScope)
    );
  },
  showSideNavHeadway: (context: DashboardFocusedFeatureContext) => {
    // !is1099OnlyRecipient && (
    return !selectorIsTaxOnlyPayeeContext(context);
  },
  showSideNavDataAndIntegrations: (context: DashboardFocusedFeatureContext) => {
    // hasDocumentsScope || hasPaymentsScope
    return context.access.hasDocumentsScope || context.access.hasPaymentsScope;
  },
  showSideNavDocuments: (context: DashboardFocusedFeatureContext) => {
    //hasDocumentsScope
    return context.access.hasDocumentsScope;
  },
  showSideNavCustomFields: (context: DashboardFocusedFeatureContext) => {
    //hasAdminScope && showCustomFieldsNav
    return (
      context.access.hasAdminScope && context.hardcoded.showCustomFieldsNav
    );
  },
  showSideNavButchImports: (context: DashboardFocusedFeatureContext) => {
    //hasPaymentsScope && showBatchImportsNav
    return (
      context.access.hasPaymentsScope && context.hardcoded.showBatchImportsNav
    );
  },
  showSideNavIntegrations: (context: DashboardFocusedFeatureContext) => {
    //hasAdminScope && featureFlagsQuery.data?.qboOnboarding && isEnterpriseUser

    return (
      context.access.hasAdminScope &&
      context.experiments.qboOnboarding &&
      context.user.userType === UserAccountType.enterprise
    );
  },
  showSideNavTokens: (context: DashboardFocusedFeatureContext) => {
    //hasAdminScope
    return context.access.hasAdminScope;
  },
  showSideNavOrganizationAccounts: (
    context: DashboardFocusedFeatureContext
  ) => {
    //hasAdminScope && isEnterpriseUser
    return (
      context.access.hasAdminScope &&
      context.user.userType === UserAccountType.enterprise
    );
  },
  showSideNavSettings: (context: DashboardFocusedFeatureContext) => {
    //hasAdminScope || hasPaymentsScope
    return context.access.hasAdminScope || context.access.hasPaymentsScope;
  },
  showSideNavAccountSettings: (context: DashboardFocusedFeatureContext) => {
    //hasAdminScope
    return context.access.hasAdminScope;
  },
  // TODO: this looks strange
  showSideNavPayoutMethodSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    //hasPaymentsScope && showPayoutNav
    return context.access.hasPaymentsScope && context.hardcoded.showPayoutNav;
  },
  // TODO: this looks strange
  showSideNavPaymentMethodSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    //hasPaymentsScope && showPayoutNav
    return context.access.hasPaymentsScope && context.hardcoded.showPaymentNav;
  },
  // TODO: this looks strange
  showSideNavPayoutAndPaymentsMethodSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    //hasPaymentsScope && !showPayoutNav && !showPaymentNav
    return (
      context.access.hasPaymentsScope &&
      !context.hardcoded.showPayoutNav &&
      !context.hardcoded.showPaymentNav
    );
  },
  showSideNavPersonalInfoSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    //hasAdminScope && !featureFlagsQuery.data?.newAccountSettings
    return (
      context.access.hasAdminScope && !context.experiments.newAccountSettings
    );
  },
  showSideNavBusinessInfoSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    //hasAdminScope && !featureFlagsQuery.data?.newAccountSettings
    return (
      context.access.hasAdminScope && !context.experiments.newAccountSettings
    );
  },
  showSideNavPayrollSettings: (context: DashboardFocusedFeatureContext) => {
    // (hasPaymentsScope || hasAdminScope) &&
    //               showPayeableAndPayrollSettingsNav &&
    //               !featureFlagsQuery.data?.sendPaymentsSettings

    return (
      (context.access.hasPaymentsScope || context.access.hasAdminScope) &&
      context.hardcoded.showPayableAndPayrollSettingsNav &&
      !context.experiments.sendPaymentsSettings
    );
  },
  showSideNavSendPaymentsSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    //(hasPaymentsScope || hasAdminScope) &&
    //               showPayeableAndPayrollSettingsNav &&
    //               featureFlagsQuery.data?.sendPaymentsSettings

    return (
      (context.access.hasPaymentsScope || context.access.hasAdminScope) &&
      context.hardcoded.showPayableAndPayrollSettingsNav &&
      context.experiments.sendPaymentsSettings
    );
  },
  showSideNavEngagementRequirements: (
    context: DashboardFocusedFeatureContext
  ) => {
    //   hasAdminScope && isEngagementsActive

    return context.access.hasAdminScope && !!context.user.labels?.engagements;
  },
  showSideNavTaxProfileSettings: (context: DashboardFocusedFeatureContext) => {
    //   hasAdminScope
    return context.access.hasAdminScope;
  },
  showSideNavTaxDocumentsSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    // hasAdminScope

    return (
      context.access.hasAdminScope &&
      context.user.contexts.some(
        c =>
          c === PlatformContextType.TaxOnlyPayee ||
          c === PlatformContextType.Payee
      )
    );
  },
  showSideNavNotificationsSettings: (
    context: DashboardFocusedFeatureContext
  ) => {
    // hasAdminScope
    return context.access.hasAdminScope;
  },
  showSideNavTeamsSettings: (context: DashboardFocusedFeatureContext) => {
    // hasAdminScope
    return context.access.hasAdminScope;
  },
  showSideNavEmailSettings: (context: DashboardFocusedFeatureContext) => {
    //hasAdminScope && hasEmailSnippets
    return (
      context.access.hasAdminScope &&
      Object.keys(context.customization.emailCustomization?.templates ?? {})
        .length > 0
    );
  }
} satisfies Record<string, CreateDashboardFocusedFeature>;

export type DashboardFocusedFeatureKey =
  keyof typeof DashboardFocusedFeaturesConfig;
