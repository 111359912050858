import { useHistory } from "react-router-dom";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import { WSQueries } from "../../../../query/WSQuery";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useActivities,
  useMemberProfile
} from "../../../../query/users/queries";
import { FlowSetup } from "../../../../shared/components/FlowSetup";
import { useWSAnalytics } from "../../../../utils/WSAnalytics";
import { RouteSetupCalculationSettings } from "./routes/RouteSetupCalculationSettings";
import { RouteSetupStateFiling } from "./routes/RouteSetupStateFiling";
import { RouteSetupPayerInformation } from "./routes/RouteSetupPayerInformation";
import {
  TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_SUB_PATH,
  TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_SUB_PATH,
  TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_SUB_PATH
} from "../../index";

type PayerSetupFlowProps = {
  basePath: string;
  onBack: () => Promise<void> | void;
  onFinish: () => Promise<void> | void;
};
export const PayerSetupFlow: React.FC<PayerSetupFlowProps> = ({
  basePath,
  onBack,
  onFinish
}) => {
  const history = useHistory();
  const userId = useUserId();
  const qActivity = useActivities(userId);
  const qMember = useMemberProfile(userId);
  const { track } = useWSAnalytics();

  const [finishOnboarding, finishOnboardingMeta] = useWSMutation(async () => {
    track.other("Product activation");

    await onFinish();
  });

  return (
    <WSQueries queries={{ qMember, qActivity }}>
      {() => {
        return (
          <>
            {finishOnboardingMeta.isLoading && <OverlaySpinner />}

            <FlowSetup
              basePath={basePath}
              routes={[
                {
                  path: TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_SUB_PATH,
                  component: RouteSetupPayerInformation,
                  onBack: onBack,
                  onNext: () => {
                    history.push(
                      basePath +
                        TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_SUB_PATH
                    );
                  }
                },
                {
                  path: TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_SUB_PATH,
                  component: RouteSetupCalculationSettings,
                  onBack: () => {
                    history.push(
                      basePath +
                        TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_SUB_PATH
                    );
                  },
                  onNext: () => {
                    history.push(
                      basePath +
                        TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_SUB_PATH
                    );
                  }
                },
                {
                  path: TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_SUB_PATH,
                  component: RouteSetupStateFiling,
                  onBack: () => {
                    history.push(
                      basePath +
                        TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_SUB_PATH
                    );
                  },
                  onNext: context => {
                    finishOnboarding();
                  }
                }
              ]}
            />
          </>
        );
      }}
    </WSQueries>
  );
};
