import {
  SelectOptionOld,
  useModalContext,
  WSButton,
  WSButtons,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSInputNumberOld,
  WSModal,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { formatMoney } from "accounting";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { WSPersistentUpgradeButton } from "../../../components/Membership/WSPersistentUpgradeButton";
import { useUserId } from "../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../query/integrations/queries";
import { isQuickbooksServiceActive } from "../../../query/integrations/selectors";
import { useCollaboratorsQuery } from "../../../query/payments/queries";
import { getVisibleCollaborators } from "../../../query/payments/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { paymentsService } from "../../../services/payments";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { WSFrontendFeature } from "../../../Settings/utils/subscriptionUtils";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import { getYupValidationSchema } from "../../../utils/jsonFormSchema";
import { prepareDynamicFormFields } from "../AddCollaboratorForm/AddCollaboratorForm";
import { CollaboratorField } from "../PayableForm/CollaboratorField";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { selectQuickbooksUserOptions } from "../../../modules/Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../modules/Integrations/selectors/selectQuickbooksAccountOrItemOptions";

export type CollaboratorValues = {
  email: string;
  description: string;
  amount: number;
  remove: string;
  qboVendorId?: string;
  qboExpenseAccountId?: string;
  labels?: {
    [key: string]: string;
  };
};

export type CollaboratorsSectionValues = {
  collaborators: CollaboratorValues[];
};

type Props = {} & WSElementProps;

export const collaboratorValidationSchema = Yup.object().shape({
  email: validatorEmail.required("Required"),
  description: Yup.string().required("Required"),
  amount: Yup.number().typeError("Required").required("Required")
});

export const CollaboratorsSection: React.FC<Props> = ({ ...elementProps }) => {
  const { openModal, closeModal } = useModalContext();
  const { control, register } = useFormContext();
  const {
    fields: collaboratorsField,
    append,
    remove,
    insert
  } = useFieldArray({
    control,
    name: "collaborators"
  });
  const collaboratorsQuery = useCollaboratorsQuery();

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ collaboratorsQuery }}>
        {({ collaboratorsQuery }) => {
          const collaborators = getVisibleCollaborators(
            collaboratorsQuery.data
          );
          const emailValues = new Set<string>();
          collaborators.forEach(collaborator => {
            emailValues.add(collaborator.member.user.email || "");
          });

          const addCollaboratorFlow = async (
            values: CollaboratorValues,
            onSuccess: (data?: any) => void
          ) => {
            const matchingCollaborator = collaborators.find(
              collaborator => collaborator.member.user.email === values.email
            );

            if (matchingCollaborator) {
              let hasFirstInvoice = true;
              try {
                hasFirstInvoice =
                  (
                    await paymentsService.payable.list({
                      filter: {
                        memberClientId: {
                          in: [matchingCollaborator.collaboratorId]
                        }
                      }
                    })
                  ).summary.totalValue > 0;
              } catch (e) {
                console.error(e);
              }

              if (hasFirstInvoice) {
                onSuccess();
              } else {
                openModal(CONFIRM_COLLABORATOR_MODAL, {
                  data: {
                    name: `${matchingCollaborator.member.user.profile?.firstName} ${matchingCollaborator.member.user.profile?.lastName}`.trim(),
                    company: matchingCollaborator.member.profile?.company?.name,
                    email: matchingCollaborator.member.user.email
                  },
                  onConfirm: () => {
                    closeModal(CONFIRM_COLLABORATOR_MODAL);
                    const collaboratorWithAdditionalData = {
                      ...values,
                      labels: {
                        ...((matchingCollaborator as any)?.labels || {})
                      }
                    };
                    onSuccess(collaboratorWithAdditionalData);
                  },
                  onCancel: () => {
                    closeModal(CONFIRM_COLLABORATOR_MODAL);
                  }
                });
              }
            } else {
              openModal(INVITE_COLLABORATOR_MODAL, {
                email: values.email,
                additionalFormData: values.labels,
                onSubmit: (inviteFormData: any) => {
                  const {
                    email,
                    confirmEmail,
                    qboVendorId,
                    qboExpenseAccountId,
                    ...additionalData
                  } = inviteFormData;
                  const collaboratorWithAdditionalData = {
                    ...values,
                    labels: {
                      ...additionalData
                    },
                    ...(qboVendorId !== ""
                      ? {
                          qboVendorId,
                          qboExpenseAccountId
                        }
                      : {})
                  };
                  closeModal(INVITE_COLLABORATOR_MODAL);
                  onSuccess(collaboratorWithAdditionalData);
                },
                onCancel: () => {
                  closeModal(INVITE_COLLABORATOR_MODAL);
                }
              });
            }
          };

          return (
            <>
              <CollaboratorFormModal />
              <InviteCollaboratorModal />
              <ConfirmCollaboratorModal />

              <WSText.Heading5 mb="XS">Contractors</WSText.Heading5>
              <WSText color="gray600" mb="XL">
                Automatically route payments to subcontractors when invoice is
                paid.
              </WSText>

              <WSElement mt="XL" mb="XS">
                <WSGrid gutter="M">
                  <WSGrid.Item span={{ xs: "6", s: "7" }}>
                    <WSText.ParagraphSm color="gray500">
                      Description
                    </WSText.ParagraphSm>
                  </WSGrid.Item>
                  <WSGrid.Item span={{ xs: "6", s: "5" }}>
                    <WSText.ParagraphSm color="gray500">
                      Amount
                    </WSText.ParagraphSm>
                  </WSGrid.Item>
                </WSGrid>
              </WSElement>
              <WSDivider mb="M" />

              {collaboratorsField.map((collaborator, index) => (
                <WSElement
                  my="M"
                  key={collaborator.id}
                  hidden={collaborator.remove}
                >
                  <input
                    type="hidden"
                    ref={register()}
                    name={`collaborators[${index}].email`}
                    defaultValue={collaborator.email}
                  />
                  <input
                    type="hidden"
                    ref={register()}
                    name={`collaborators[${index}].description`}
                    defaultValue={collaborator.description}
                  />
                  <input
                    type="hidden"
                    ref={register({ valueAsNumber: true })}
                    name={`collaborators[${index}].amount`}
                    defaultValue={collaborator.amount}
                  />
                  <input
                    type="hidden"
                    ref={register()}
                    name={`collaborators[${index}].remove`}
                    defaultValue={collaborator.remove}
                  />
                  <input
                    type="hidden"
                    ref={register()}
                    name={`collaborators[${index}].qboVendorId`}
                    defaultValue={collaborator.qboVendorId}
                  />
                  <input
                    type="hidden"
                    ref={register()}
                    name={`collaborators[${index}].qboExpenseAccountId`}
                    defaultValue={collaborator.qboExpenseAccountId}
                  />
                  {Object.keys(collaborator?.labels || {}).map(
                    customFieldKey => {
                      return (
                        <input
                          key={customFieldKey}
                          type="hidden"
                          ref={register()}
                          name={`collaborators[${index}].labels[${customFieldKey}]`}
                          defaultValue={collaborator.labels[customFieldKey]}
                        />
                      );
                    }
                  )}
                  <WSGrid gutter="M">
                    <WSFormOld.Value name="collaborators">
                      {items => {
                        const item = items[index];

                        return (
                          <>
                            <WSGrid.Item span={{ xs: "6", s: "7" }}>
                              <WSText>{item?.email}</WSText>
                              <WSText.ParagraphSm color="gray600">
                                {item?.description}
                              </WSText.ParagraphSm>
                            </WSGrid.Item>
                            <WSGrid.Item span={{ xs: "4", s: "4" }}>
                              <WSFlexBox.CenterY
                                wrap="nowrap"
                                style={{ height: "100%" }}
                              >
                                <WSText>{formatMoney(item?.amount)}</WSText>
                              </WSFlexBox.CenterY>
                            </WSGrid.Item>
                          </>
                        );
                      }}
                    </WSFormOld.Value>
                    <WSGrid.Item span={{ xs: "2", s: "1" }}>
                      <WSFlexBox.CenterY
                        wrap="nowrap"
                        style={{ height: "100%" }}
                      >
                        <WSButton.Link
                          type="button"
                          onClick={() => {
                            openModal(COLLABORATOR_FORM_MODAL, {
                              defaultValues: collaborator,
                              onSubmit: (values: CollaboratorValues) => {
                                closeModal(COLLABORATOR_FORM_MODAL);
                                addCollaboratorFlow(
                                  { ...collaborator, ...values },
                                  (collaboratorWithAdditionalData = values) => {
                                    remove(index);
                                    insert(
                                      index,
                                      collaboratorWithAdditionalData
                                    );
                                  }
                                );
                              },
                              onCancel: () => {
                                closeModal(COLLABORATOR_FORM_MODAL);
                              }
                            });
                          }}
                          name={`editCollaborator${index}`}
                        >
                          Edit
                        </WSButton.Link>
                      </WSFlexBox.CenterY>
                    </WSGrid.Item>
                  </WSGrid>
                </WSElement>
              ))}

              <WSPersistentUpgradeButton
                feature={WSFrontendFeature.InviteAndPayCollaborator}
                kind="Link"
                mt="M"
                type="button"
                icon="plus-circle"
                onClick={() => {
                  openModal(COLLABORATOR_FORM_MODAL, {
                    onSubmit: (values: CollaboratorValues) => {
                      closeModal(COLLABORATOR_FORM_MODAL);
                      addCollaboratorFlow(
                        values,
                        (collaboratorWithAdditionalData = values) => {
                          const matchingCollaborator = collaborators.find(
                            memberClient =>
                              memberClient.member.user.email === values.email
                          );
                          const dataToAppend = {
                            ...collaboratorWithAdditionalData,
                            ...((matchingCollaborator as any)?.labels
                              ? {
                                  labels: (matchingCollaborator as any)?.labels
                                }
                              : {})
                          };
                          append(dataToAppend);
                        }
                      );
                    },
                    onCancel: () => {
                      closeModal(COLLABORATOR_FORM_MODAL);
                    }
                  });
                }}
                name="addCollaborator"
              >
                Add contractor
              </WSPersistentUpgradeButton>

              <WSFormOld.Error name="collaborators" />

              <WSDivider mt="M" mb="M" />
            </>
          );
        }}
      </WSQueries>
    </WSElement>
  );
};

export type CollaboratorFormModalProps = {
  defaultValues?: CollaboratorValues;
  onSubmit: (values: CollaboratorValues) => void;
  onCancel: () => void;
  onDelete?: () => void;
};

export const COLLABORATOR_FORM_MODAL = "COLLABORATOR_FORM_MODAL";

export const CollaboratorFormModal = () => {
  const collaboratorsQuery = useCollaboratorsQuery();
  const { watch } = useFormContext();
  const firstLineItem = watch("lineItems[0]");
  const defaultDesctiption = firstLineItem?.description;

  return (
    <WSModal name={COLLABORATOR_FORM_MODAL} size="S">
      {({ defaultValues, onCancel, onSubmit }: CollaboratorFormModalProps) => (
        <WSQueries queries={{ collaboratorsQuery }}>
          {({ collaboratorsQuery: { data: collaborators } }) => {
            return (
              <>
                <WSText.Heading5 mb="M">
                  {defaultValues ? "Update contractor" : "New contractor"}
                </WSText.Heading5>
                <WSFormOld<CollaboratorValues>
                  defaultValues={
                    defaultValues || {
                      email: "",
                      description: defaultDesctiption,
                      remove: "",
                      qboVendorId: "",
                      qboExpenseAccountId: ""
                    }
                  }
                  validationSchema={collaboratorValidationSchema}
                >
                  {({ handleSubmit, register, setValue }) => {
                    return (
                      <>
                        <CollaboratorField
                          name="email"
                          label=""
                          collaborators={collaborators}
                        />
                        <WSFormOld.Field
                          my="M"
                          name="description"
                          label="Description"
                          component={WSTextInput}
                          defaultValue={defaultValues?.description || ""}
                        />
                        <WSFormOld.Field
                          mb="M"
                          name="amount"
                          label="Amount"
                          component={WSInputNumberOld}
                          componentProps={{
                            mode: "currency",
                            currency: "USD"
                          }}
                        />

                        <input
                          type="hidden"
                          ref={register()}
                          name="remove"
                          defaultValue={defaultValues?.remove}
                        />

                        <input
                          type="hidden"
                          ref={register()}
                          name="qboVendorId"
                          defaultValue={defaultValues?.qboVendorId}
                        />

                        <input
                          type="hidden"
                          ref={register()}
                          name={"qboExpenseAccountId"}
                          defaultValue={defaultValues?.qboExpenseAccountId}
                        />

                        <WSButtons mt="XL" format="modal">
                          <WSButton.Primary
                            type="button"
                            onClick={handleSubmit(onSubmit)}
                            name="saveCollaborator"
                          >
                            Save
                          </WSButton.Primary>
                          <WSButton.Tertiary onClick={onCancel} type="button">
                            Cancel
                          </WSButton.Tertiary>
                          {defaultValues && (
                            <WSButton.Secondary
                              destructive
                              type="button"
                              onClick={() => {
                                setValue("remove", "1");
                                handleSubmit(onSubmit)();
                              }}
                              name="deleteCollaborator"
                            >
                              Delete item
                            </WSButton.Secondary>
                          )}
                        </WSButtons>
                      </>
                    );
                  }}
                </WSFormOld>
              </>
            );
          }}
        </WSQueries>
      )}
    </WSModal>
  );
};

export type InviteCollaboratorModalProps = {
  email: string;
  additionalFormData?: {
    [key: string]: string;
  };
  onSubmit: (values: CollaboratorValues) => void;
  onCancel: () => void;
};

export const INVITE_COLLABORATOR_MODAL = "INVITE_COLLABORATOR_MODAL";

export const InviteCollaboratorModal = () => {
  const collaboratorsQuery = useCollaboratorsQuery();
  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const qIntegrationsQuickbooksExpenseAccounts =
    useIntegrationsQuickbooksAccountExpenses();

  const qFeatureFlags = useFeatureFlags();

  const isQuickbooksActive =
    qIntegrationsQuickbooks.data &&
    (qFeatureFlags.data?.forceShowQBO ||
      isQuickbooksServiceActive(qIntegrationsQuickbooks.data));

  const { openModal } = useModalContext();

  return (
    <WSModal
      name={INVITE_COLLABORATOR_MODAL}
      size="S"
      title="Invite contractor"
    >
      {({
        email,
        additionalFormData = {},
        onCancel,
        onSubmit
      }: InviteCollaboratorModalProps) => (
        <WSQueries
          queries={{
            collaboratorsQuery,
            qCollaboratorCustomFields,
            qIntegrationsQuickbooks,
            qIntegrationsQuickbooksVendors,
            qIntegrationsQuickbooksExpenseAccounts
          }}
        >
          {({
            collaboratorsQuery: { data: collaborators },
            qCollaboratorCustomFields: { data: collaboratorCustomFields = [] },
            qIntegrationsQuickbooks,
            qIntegrationsQuickbooksVendors,
            qIntegrationsQuickbooksExpenseAccounts
          }) => {
            const emailValues = new Set<string>();
            collaborators.forEach(collaborator => {
              emailValues.add(collaborator.member.user.email || "");
            });
            const additionalDataValidationSchema = getYupValidationSchema(
              collaboratorCustomFields
            );

            const integrationState = qIntegrationsQuickbooks.data;

            const integrationQuickbooksExpenseAccounts =
              qIntegrationsQuickbooksExpenseAccounts.data || [];

            const quickbooksVendorOptions: SelectOptionOld[] =
              selectQuickbooksUserOptions(qIntegrationsQuickbooksVendors.data);

            const quickbooksAccountsOptions: SelectOptionOld[] =
              selectQuickbooksAccountOrItemOptions(
                qIntegrationsQuickbooksExpenseAccounts.data
              );

            const defaultAccount = integrationQuickbooksExpenseAccounts.find(
              acct =>
                acct.accountId === integrationState?.defaults?.expenseAccountId
            );

            return (
              <>
                <WSText mb="M">
                  This contractor does not exist yet. Confirm email to send
                  invite and create their invoice.
                </WSText>
                <WSFormOld<{
                  email: string;
                  confirmEmail: string;
                  qboVendorId: string;
                  qboExpenseAccountId: string;
                }>
                  defaultValues={{
                    email,
                    confirmEmail: "",
                    qboVendorId: "",
                    qboExpenseAccountId: "",
                    ...additionalFormData
                  }}
                  validationSchema={Yup.object().shape({
                    email: validatorEmail.required("Required"),
                    confirmEmail: validatorEmail.oneOf(
                      [Yup.ref("email"), null],
                      "Emails should match"
                    ),
                    ...additionalDataValidationSchema
                  })}
                >
                  {({ handleSubmit }) => {
                    return (
                      <>
                        <WSFormOld.Field
                          mb="XL"
                          name="email"
                          label="Contractor email"
                          component={WSTextInput}
                          defaultValue={email}
                        />
                        <WSFormOld.Field
                          mb="2XL"
                          name="confirmEmail"
                          label="Confirm contractor email"
                          component={WSTextInput}
                          defaultValue=""
                        />

                        {isQuickbooksActive && (
                          <>
                            <WSDivider mb="XL" label="Quickbooks Mapping" />
                            <WSText mb="XL">
                              Because you have an active integration with QBO,
                              please map this new Contractor to an existing
                              Vendor, or leave blank and we will create a new
                              one.
                            </WSText>
                            <WSFormOld.Field
                              mb="2XL"
                              name="qboVendorId"
                              component={WSSelectOld}
                              componentProps={{
                                placeholder: "Not mapped, create new Vendor",
                                options: quickbooksVendorOptions,
                                searchable: true,
                                cleanable: true,
                                placeholderActions: [
                                  {
                                    label: "Resync QBO Vendors",
                                    icon: "refresh-v",
                                    callback() {
                                      openModal(
                                        INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
                                        {
                                          entity: QUICKBOOKS_ENTITY.VENDORS
                                        }
                                      );
                                    }
                                  }
                                ]
                              }}
                              label=""
                            />

                            <WSFormOld.Field
                              mb="2XL"
                              name="qboExpenseAccountId"
                              component={WSSelectOld}
                              componentProps={{
                                options: quickbooksAccountsOptions,
                                placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                                cleanable: true,
                                searchable: true,
                                placeholderActions: [
                                  {
                                    label: "Resync QBO Expenses Accounts",
                                    icon: "refresh-v",
                                    callback() {
                                      openModal(
                                        INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
                                        {
                                          entity: QUICKBOOKS_ENTITY.EXPENSES
                                        }
                                      );
                                    }
                                  }
                                ]
                              }}
                              label="Default QBO Expense Account"
                            />

                            <RefreshModal />
                          </>
                        )}

                        {collaboratorCustomFields.length > 0 && (
                          <>
                            <WSDivider mb="XL" label="Additional Data" />
                            <WSText mb="XL">
                              We need additional data to invite a contractor.
                            </WSText>
                            {prepareDynamicFormFields(collaboratorCustomFields)}
                            <WSElement mb="2XL" />
                          </>
                        )}

                        <WSButtons format="modal">
                          <WSButton.Primary
                            type="button"
                            onClick={handleSubmit(onSubmit)}
                            name="inviteCollaborator"
                          >
                            Invite
                          </WSButton.Primary>
                          <WSButton.Tertiary onClick={onCancel} type="button">
                            Cancel
                          </WSButton.Tertiary>
                        </WSButtons>
                      </>
                    );
                  }}
                </WSFormOld>
              </>
            );
          }}
        </WSQueries>
      )}
    </WSModal>
  );
};

export type ConfirmCollaboratorModalProps = {
  data: {
    name: string;
    company: string;
    email: string;
  };
  onConfirm: () => void;
  onCancel: () => void;
};

export const CONFIRM_COLLABORATOR_MODAL = "CONFIRM_COLLABORATOR_MODAL";

export const ConfirmCollaboratorModal = () => {
  const userId = useUserId();
  const collaboratorsQuery = useCollaboratorsQuery();

  return (
    <WSModal
      name={CONFIRM_COLLABORATOR_MODAL}
      size="S"
      title="Confirm contractor"
    >
      {({ data, onCancel, onConfirm }: ConfirmCollaboratorModalProps) => (
        <WSQueries queries={{ collaboratorsQuery }}>
          {({ collaboratorsQuery: { data: collaborators } }) => {
            const emailValues = new Set<string>();
            collaborators.forEach(collaborator => {
              emailValues.add(collaborator.member.user.email || "");
            });

            return (
              <>
                <WSText mb="M">
                  This is the first time you're paying this contractor. Confirm
                  their info to create their invoice.
                </WSText>

                {data.name && (
                  <WSElement mb="XL">
                    <WSText.ParagraphSm mb="M">
                      Contractor name
                    </WSText.ParagraphSm>
                    <WSText>{data.name}</WSText>
                  </WSElement>
                )}

                {data.company && (
                  <WSElement mb="XL">
                    <WSText.ParagraphSm mb="M">
                      Contractor business name
                    </WSText.ParagraphSm>
                    <WSText>{data.company}</WSText>
                  </WSElement>
                )}

                <WSText.ParagraphSm mb="M">Contractor email</WSText.ParagraphSm>
                <WSText mb="XL">{data.email}</WSText>

                <WSButtons format="modal">
                  <WSButton.Primary
                    type="button"
                    name="confirmCollaborator"
                    onClick={onConfirm}
                  >
                    Confirm
                  </WSButton.Primary>
                  <WSButton.Tertiary
                    name="cancelConfirmCollaborator"
                    onClick={onCancel}
                    type="button"
                  >
                    Cancel
                  </WSButton.Tertiary>
                </WSButtons>
              </>
            );
          }}
        </WSQueries>
      )}
    </WSModal>
  );
};
