import {
  toWSDateString,
  WSMarginProps,
  WSTable,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import { IEngagement } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { buildEngagementDetailsPath } from "../../utils";
import { useRequirementDefinitionListAllQuery } from "../../../../query/requirementDefinitions/query/useRequirementDefinitionListAllQuery";
import { ContractorsCountCell } from "./ContractorsCountCell";

export interface EngagementRowsTableProps extends WSMarginProps {
  engagements: IEngagement[];
  loading?: boolean;
  isArchivedOnly?: boolean;
  filters?: any;
}

export const EngagementRowsTable: React.FC<EngagementRowsTableProps> = ({
  engagements,
  loading,
  isArchivedOnly,
  filters,
  ...elementProps
}) => {
  const history = useHistory();
  const requirementsDefinitionsListQuery =
    useRequirementDefinitionListAllQuery();
  const requirementDefinitions = requirementsDefinitionsListQuery.data;

  const isComponentLoading = requirementsDefinitionsListQuery.isLoading;
  const tableData = engagements.map(e => {
    return {
      data: e,
      id: e.engagementId
    };
  });

  return (
    <WSTable<IEngagement>
      {...elementProps}
      loading={loading || isComponentLoading}
      tableData={tableData}
      onRowClick={({ data }) => {
        history.push(buildEngagementDetailsPath(data.engagementId));
      }}
      // headerAction={{
      //   icon: "download",
      //   onClick: async () => {
      //     // TODO: Implement download csv
      //   },
      //   loading: false
      // }}
      columns={[
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "1.5fr",
            header: {
              text: "Name"
            }
          },
          renderFunction: ({ data }) => {
            return (
              <WSTableCell text={data.name} secondaryText={data.description} />
            );
          }
        },
        // {
        //   config: {
        //     gridTemplateSizeMin: "0",
        //     gridTemplateSizeMax: "0.5fr",
        //     header: {
        //       text: "Type"
        //     },
        //     hideOnScreens: ["XS"]
        //   },
        //   renderFunction: ({ data }) => {
        //     return (
        //       <WSTableCell
        //         text={getEngagementLabelByType(data.type)}
        //       />
        //     );
        //   }
        // },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "0.5fr",
            header: {
              text: "Requirements"
            },
            hideOnScreens: ["XS"]
          },
          renderFunction: ({ data }) => {
            const requirementIds = data.requirementIds ?? [];
            const requirements = requirementIds.map(id =>
              requirementDefinitions?.find(
                r => r.requirementDefinitionId === id
              )
            );

            return requirements?.length > 0 ? (
              <WSTableCell
                text={requirements[0]?.name}
                secondaryText={
                  requirementIds.length > 1
                    ? `+${requirements.length - 1} more`
                    : undefined
                }
              />
            ) : (
              <WSTableCell text="--" />
            );
          }
        },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "0.5fr",
            header: {
              text: "Contractors"
            },
            hideOnScreens: ["XS"]
          },
          renderFunction: ({ id }) => {
            return <ContractorsCountCell engagementId={id} />;
          }
        },
        {
          config: {
            gridTemplateSizeMin: "0",
            gridTemplateSizeMax: "0.5fr",
            header: {
              text: "Modified"
            },
            hideOnScreens: ["XS"]
          },
          renderFunction: ({ data }) => {
            return (
              <WSTableCell
                text={toWSDateString(data.updatedAt, "monthDayYear")}
              />
            );
          }
        }
        // {
        //   config: {
        //     gridTemplateSizeMin: "0",
        //     gridTemplateSizeMax: "0.5fr",
        //     header: {
        //       text: "Status"
        //     },
        //   },
        //   renderFunction: ({ data }) => {
        //     if (data.status === EngagementStatus.Active) {
        //       return (
        //         <WSTableCell
        //           pill={{
        //             icon: true,
        //             theme: "success",
        //             text: "Active"
        //           }}
        //         />
        //       );
        //     } else if (data.status === EngagementStatus.Inactive) {
        //       return (
        //         <WSTableCell
        //           pill={{
        //             icon: "archive",
        //             theme: "neutral",
        //             text: "Inactive"
        //           }}
        //         />
        //       );
        //     }
        //   }
        // }
      ]}
    />
  );
};
