import {
  useWSSnackbar,
  WSButton,
  WSCheckboxToggle,
  WSElementProps,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import {
  IPayerResponse,
  PayeeTaxDocumentSharePermission
} from "@wingspanhq/payments/dist/interfaces";
import { useUpdatePayer } from "../../../query/payers/mutations/useUpdatePayer";
import { getPayerNames } from "../../../modules/Payers/utils/getPayerNames";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";

type FormW9ConsentProps = {
  onClose: () => void;
  payer: IPayerResponse;
} & WSElementProps;

export const ModalShareTaxInformationWithPayer: React.FC<
  FormW9ConsentProps
> = ({ onClose, payer }) => {
  const { openSnackbar } = useWSSnackbar();
  const [consent, setConsent] = useState(false);
  const [updatePayer, updatePayerMeta] = useUpdatePayer();

  const payerName = getPayerNames(payer).getResolvedName();

  return (
    <WSList gap="XL">
      <WSText.ParagraphSm>
        To receive payments from {payerName}, please consent to sharing your tax
        information with them.
      </WSText.ParagraphSm>

      <WSCheckboxToggle
        name="consent"
        value={consent}
        onChange={setConsent}
        label={`I authorize Wingspan to share the tax information I've provided with my payer(s) on the Wingspan platform (${payerName}). I understand this information will be used for tax reporting purposes in accordance with applicable laws and regulations.`}
      />

      <WSErrorMessage
        contextKey="Other"
        forceShowApiErrors
        error={updatePayerMeta.error}
      />

      <WSButton
        disabled={!consent}
        fullWidth
        size="M"
        onAsyncClick={async () => {
          await updatePayer(
            {
              id: payer.payerId,
              payeeOwnedData: {
                shareTaxDocument: PayeeTaxDocumentSharePermission.Allow
              }
            },
            {
              onSuccess() {
                openSnackbar({
                  type: "success",
                  duration: 3000,
                  message: `Tax information shared`
                });
                onClose();
              }
            }
          );
        }}
        loading={updatePayerMeta.isLoading}
      >
        Submit
      </WSButton>
    </WSList>
  );
};
