import { useHistory } from "react-router-dom";
import { PATH_ONBOARDING_TAX_PAYER } from "..";
import { OnboardingFlow } from "../components/OnboardingFlow";
import { OnboardingModule } from "../types";

const taxPayerOnboardingModules: OnboardingModule[] = [
  {
    type: "create_account",
    options: {
      accountTypes: ["Business"],
      verificationLevel: "Banking"
    }
  },
  {
    type: "business_profile"
  }
];
export const RouteTaxPayerOnboarding: React.FC = () => {
  const history = useHistory();

  return (
    <OnboardingFlow
      basePath={PATH_ONBOARDING_TAX_PAYER}
      modules={taxPayerOnboardingModules}
      onSuccess={() => {
        history.push("/member/dashboard");
      }}
    />
  );
};
