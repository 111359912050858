import { IProfitAndLoss } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  WSDateRangeSelectOptions,
  WSGrid,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  useCatchUpTransactionsExpense,
  useProfitAndLoss
} from "../../../query/bookkeeping/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { useActivities } from "../../../query/users/queries";
import { WSQueries } from "../../../query/WSQuery";
import { Review } from "../../components/Review/Review";
import { ProfitAndLoss } from "../../components/ProfitAndLoss/ProfitAndLoss.component";
import { isValidDateRange } from "../../utils";
import { useBookkeepingFilters } from "./BookkeepingIndex";

export const BookkeepingProfitAndLoss: React.FC = () => {
  const { filters } = useBookkeepingFilters();

  const userId = useUserId();
  const qCatchUpExpenseTransactions = useCatchUpTransactionsExpense(userId);

  const dateRange = (
    isValidDateRange(filters.customDateRange)
      ? filters.customDateRange
      : WSDateRangeSelectOptions.LastYear.range
  ) as any;

  const qProfitAndLoss = useProfitAndLoss({
    startDate: dateRange ? dateRange[0] : new Date(),
    endDate: dateRange ? dateRange[1] : new Date()
  });

  const qActivity = useActivities(userId);

  return (
    <>
      <WSQueries
        queries={{
          qCatchUpExpenseTransactions,
          qActivity
        }}
      >
        {({ qActivity, qCatchUpExpenseTransactions }) => {
          const profitAndLossData = (qProfitAndLoss.data ||
            {}) as IProfitAndLoss;
          const profitAndLossIsLoading = qProfitAndLoss.isLoading;

          const mainContent = (
            <>
              <WSText.Heading1>Profit & Loss</WSText.Heading1>
              <ProfitAndLoss
                isLoading={profitAndLossIsLoading}
                profitAndLossData={profitAndLossData}
              />
            </>
          );

          const sideContent = (
            <Review
              isShort
              profitAndLoss={profitAndLossData}
              activity={qActivity.data}
              expenseCatchUp={qCatchUpExpenseTransactions.data}
            />
          );

          return (
            <>
              <BrowserPageTitle title="Income & Expenses: Profit&Loss" />

              <WSScreen.Mobile>
                <WSGrid>
                  <WSGrid.Item>
                    {mainContent}
                    {sideContent}
                  </WSGrid.Item>
                </WSGrid>
              </WSScreen.Mobile>
              <WSScreen.TabletAndDesktop>
                <WSGrid>
                  <WSGrid.Item span={{ s: "7", m: "7", l: "8" }}>
                    {mainContent}
                  </WSGrid.Item>
                  <WSGrid.Item span={{ s: "5", m: "5", l: "4" }}>
                    {sideContent}
                  </WSGrid.Item>
                </WSGrid>
              </WSScreen.TabletAndDesktop>
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
