import {
  WSElement,
  WSElementProps,
  WSGrid,
  WSMessageBox,
  WSSelectOption,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { Nullable } from "primereact/ts-helpers";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { selectorIsEngagementsActiveForPayer } from "../../../modules/Payees/selectors/selectorIsEngagementsActiveForPayer";
import { selectorPayerEngagementsTmp } from "../../../modules/Payees/selectors/selectorPayerEngagementsTmp";
import { selectorPayerPayeeEngagements } from "../../../modules/Payees/selectors/selectorPayerPayeeEngagements";
import { SelectPayerEngagement } from "../../../modules/Payers/components/SelectPayerEngagement";
import { useQueryPayerRows } from "../../../query/search/payer/queries/useQueryPayerRows";
import { AdditionalEmailsFieldOld } from "../AdditionalEmailsField/AdditionalEmailsFieldOld";
import { useInvoicesFormContext } from "./InvoicesForm";

export type ClientSectionProps = WSElementProps;

export type ClientSectionValues = {
  client?: {
    payerId: string | null;
    payerPayeeEngagementId: string | null;
    emailsCC: { email: string }[];
  };
};

export const ClientSection: React.FC<ClientSectionProps> = props => {
  const { register, setValue, clearErrors, errors, getValues } =
    useFormContext();
  const {
    defaultValues,
    invoicingConfigForPayee,
    payerRow,
    invoice,
    invoiceTemplate
  } = useInvoicesFormContext();

  useEffect(() => {
    if (payerRow) {
      setValue(
        "client.emailsCC",
        (payerRow?.payeeOwnedData?.payerEmailCC || []).map(email => ({ email }))
      );
    }
  }, [payerRow, setValue]);

  const [selectValue, setSelectValue] =
    useState<Nullable<WSSelectOption>>(null);

  const [autoFocus, setAutoFocus] = useState(false);
  const preselectedEmail = defaultValues?.email;

  useQueryPayerRows(
    {
      filter: {
        searchString: preselectedEmail
      }
    },
    {
      enabled: !!preselectedEmail,
      onSuccess: result => {
        if (!preselectedEmail) {
          return;
        }

        const payerRows = result.flat();
        const payer = payerRows.find(
          payer => payer.user.email === preselectedEmail
        );
        const engagements = payer?.engagements || [];

        const filteredEngagements = selectorIsEngagementsActiveForPayer(
          engagements
        )
          ? selectorPayerPayeeEngagements(engagements)
          : selectorPayerEngagementsTmp(engagements, false, false);

        if (payer && filteredEngagements.length === 1) {
          setAutoFocus(false);
          setValue("client.payerId", payer.payerId);
          setValue(
            "client.payerPayeeEngagementId",
            filteredEngagements[0].payerPayeeEngagementId
          );
        } else {
          setAutoFocus(true);
        }
      }
    }
  );

  useEffect(() => {
    if (!payerRow || !payerRow.user) {
      setSelectValue(null);
      return;
    }

    const payerName = wsName({
      user: payerRow.user,
      member: payerRow.member,
      payeeOwnedData: payerRow.payeeOwnedData
    }).getResolvedName();

    const formValues = getValues() as ClientSectionValues;

    const payerPayeeEngagementId = formValues?.client?.payerPayeeEngagementId;

    const engagement = payerRow.engagements.find(
      engagement => engagement.payerPayeeEngagementId === payerPayeeEngagementId
    );

    setSelectValue({
      value: payerRow.payerId + "/" + payerPayeeEngagementId,
      label: `${payerName} • ${engagement?.engagementName}`,
      labels: [payerRow.user.email],
      avatar: {
        type: "text",
        text: payerName
      }
    });
  }, [getValues, payerRow]);

  const isDisabled = !!invoice || !!invoiceTemplate;

  return (
    <WSElement {...props}>
      <WSText.Heading5 mb="XL">Client</WSText.Heading5>

      <input type="hidden" name="client.memberClientId" ref={register()} />

      <input type="hidden" name="client.payerId" ref={register()} />
      <input
        type="hidden"
        name="client.payerPayeeEngagementId"
        ref={register()}
      />

      <SelectPayerEngagement
        defaultSearchText={preselectedEmail}
        onAddNewPayer={(payer, engagements) => {
          if (engagements.length) {
            const payerEngagement = engagements[0];
            const payerNames = wsName({
              user: payer.user!,
              member: payer.member,
              payeeOwnedData: payer.payeeOwnedData
            });

            const payerName = payerNames.getResolvedName();

            clearErrors("client");
            setValue("client.payerId", payer.payerId);
            setValue(
              "client.payerPayeeEngagementId",
              payerEngagement.payerPayeeEngagementId
            );

            setSelectValue({
              value:
                payer.payerId + "/" + payerEngagement.payerPayeeEngagementId,
              label: `${payerName} • ${payerEngagement.engagementName}`,
              labels: [payerNames.email],
              avatar: {
                type: "text",
                text: payerName
              }
            });
          }
        }}
        autoFocus={autoFocus}
        disabled={isDisabled}
        value={selectValue}
        onChange={newValue => {
          if (newValue) {
            clearErrors("client");
            const [payerId, payerPayeeEngagementId] = newValue.value.split("/");
            setValue("client.payerId", payerId);
            setValue("client.payerPayeeEngagementId", payerPayeeEngagementId);
            setSelectValue(newValue);
          } else {
            setValue("client.payerId", null);
            setValue("client.payerPayeeEngagementId", null);
            setSelectValue(null);
          }
        }}
        status={errors?.client?.payerPayeeEngagementId ? "error" : undefined}
        message={errors?.client?.payerPayeeEngagementId?.message}
      />

      <PreviewPayerEngagement />

      {invoicingConfigForPayee?.additionalGuidelines.enabled &&
      invoicingConfigForPayee?.additionalGuidelines?.value ? (
        <WSMessageBox.Info
          data-testid="additionalGuidelines"
          my="M"
          noBorder
          // title={`Guidelines from Client (${memberClient?.name || "-"}):`}
        >
          <WSText.ParagraphSm color="gray500" weight="book">
            {invoicingConfigForPayee?.additionalGuidelines.value}
          </WSText.ParagraphSm>
        </WSMessageBox.Info>
      ) : null}
    </WSElement>
  );
};

const PreviewPayerEngagement: React.FC = () => {
  const { payerRow } = useInvoicesFormContext();
  const { watch } = useFormContext();

  const payerPayeeEngagementId = watch("client.payerPayeeEngagementId");

  const engagement = useMemo(
    () =>
      payerRow?.engagements.find(
        engagement =>
          engagement.payerPayeeEngagementId === payerPayeeEngagementId
      ),
    [payerPayeeEngagementId, payerRow?.engagements]
  );

  return (
    <>
      {payerRow ? (
        <WSGrid mt="M" mb="L" gutter="M">
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Contact name
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {payerRow.payeeOwnedData?.payerName || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Client company
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {payerRow.payeeOwnedData?.payerCompanyName || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Contact email
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>{payerRow.user?.email}</WSText.ParagraphSm>
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "6" }}>
            <WSText.ParagraphSm mb="S" color="gray500">
              Engagement
            </WSText.ParagraphSm>
            <WSText.ParagraphSm>
              {engagement?.engagementName || "–"}
            </WSText.ParagraphSm>
          </WSGrid.Item>
        </WSGrid>
      ) : null}

      <AdditionalEmailsFieldOld
        hidden={!payerRow}
        name="client.emailsCC"
        inputLabel="Contact email CC"
        buttonLabel="Add email recipient"
        buttonDescription="Additional email addresses will be cc’d"
      />
    </>
  );
};
