import {
  WSFormOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { FormActions } from "./FormActions";

import {
  IOtpSessionCreateRequest,
  OtpVerificationChannel
} from "@wingspanhq/users/dist/lib/interfaces";
import { useHistory, useLocation } from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useBeginOTPSession,
  useCreateSession,
  useUpdateUserProfile
} from "../../../query/users/mutations";
import { ISessionCreateRequest } from "../../../services/users";
import styles from "./SettingsUpdateForms.module.scss";
import { validatorEmail } from "../../../shared/validators/validatorEmail";

interface UpdateFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const UpdateEmailForm: React.FC<UpdateFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const history = useHistory();
  const location = useLocation();
  const userId = useUserId();
  const [createUserSession, { error: createUserSessionError }] =
    useCreateSession();
  const [updateUserProfile, { error: updateUserProfileError }] =
    useUpdateUserProfile();

  const [beginOTPSession, beginOTPSessionMeta] = useBeginOTPSession();

  const onFormSubmit = async (data: any) => {
    const { currentEmail: email, password } = data;

    const request: ISessionCreateRequest = {
      email,
      password
    };
    await createUserSession(request, {
      throwOnError: true,
      onSuccess: () => {
        // new email verification request
        const payload: IOtpSessionCreateRequest = {
          channel: OtpVerificationChannel.Email,
          appRedirectPath: `${location.pathname}${location.search}`
        };

        // Update email id if session is created
        const request = {
          userId,
          email: data.newEmail
        };
        updateUserProfile(request, {
          onSuccess: async () => {
            onSubmit(data);
            await beginOTPSession(payload, {
              onSuccess: () => {
                history.push("/member/authorize/2fa/email");
              }
            });
          }
        });
      }
    });
  };
  return (
    <WSFormOld
      onSubmit={onFormSubmit}
      className={styles.form}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        currentEmail: validatorEmail,
        newEmail: validatorEmail.required("This field is required"),
        password: Yup.string().required("This field is required")
      })}
    >
      <WSFormOld.Field name="currentEmail" mb="2XL">
        <WSFormOld.Label>Current email address</WSFormOld.Label>
        <WSFormOld.Input
          render={props => <WSTextInput type="email" {...props} />}
        />
        <WSFormOld.Error />
      </WSFormOld.Field>

      <WSFormOld.Field name="newEmail" mb="2XL">
        <WSFormOld.Label>New email address</WSFormOld.Label>
        <WSFormOld.Input
          render={props => <WSTextInput type="email" {...props} />}
        />
        <WSFormOld.Error />
        <WSText.ParagraphSm
          weight="medium"
          className={styles.errorText}
          mt="XS"
          dangerouslySetInnerHTML={{
            __html: updateUserProfileError?.response?.data["error"] || ""
          }}
        />
      </WSFormOld.Field>

      <WSFormOld.Field name="password">
        <WSFormOld.Label>Confirm Password</WSFormOld.Label>
        <WSFormOld.Input
          render={props => <WSTextInput type="password" {...props} />}
        />
        <WSFormOld.Error />
        <WSText.ParagraphSm
          weight="medium"
          className={styles.errorText}
          mt="XS"
        >
          {createUserSessionError?.response?.data["error"]}
        </WSText.ParagraphSm>
      </WSFormOld.Field>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
