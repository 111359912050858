import {
  WSButton,
  WSButtonProps,
  WSElementProps,
  WSFlexBox
} from "@wingspanhq/fe-component-library";

interface CancelActionFullWidthButtonsProps extends Pick<WSElementProps, "mt"> {
  cancelText?: string;
  cancelProps?: WSButtonProps<"Tertiary">;
  onCancel: () => void;
  actionText?: string;
  onAction?: () => void;
  actionProps?: WSButtonProps<"Primary">;
}

const CancelActionFullWidthButtons: React.FC<
  CancelActionFullWidthButtonsProps
> = ({
  cancelText = "Cancel",
  cancelProps,
  onCancel,
  actionText = "OK",
  onAction,
  actionProps,
  mt
}) => (
  <WSFlexBox justify="space-between" mt={mt || "L"}>
    <WSButton.Tertiary
      type="button"
      style={{ flexGrow: 1 }}
      mr="XS"
      onClick={onCancel}
      {...cancelProps}
    >
      {cancelText}
    </WSButton.Tertiary>
    <WSButton.Primary
      type="button"
      style={{ flexGrow: 1 }}
      ml="XS"
      onClick={onAction}
      {...actionProps}
    >
      {actionText}
    </WSButton.Primary>
  </WSFlexBox>
);

export default CancelActionFullWidthButtons;
