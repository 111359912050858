import { TaxEstimateType } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import {
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces/member";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useSetBookkeepingEnable } from "../../../query/bookkeeping/mutations";
import { useUserId } from "../../../query/hooks/helpers";
import { useTaxEstimate } from "../../../query/taxes/queries";
import {
  useUpdateMemberProfile,
  useUpdateTaxesSetupActivity
} from "../../../query/users/mutations";
import { useMemberProfile } from "../../../query/users/queries";

export const TaxesEnablingServices: React.FC = () => {
  const history = useHistory();
  const memberId = useUserId();
  const qMember = useMemberProfile(memberId);
  const [updateMemberProfile, updateMemberProfileMeta] =
    useUpdateMemberProfile();

  const [updateTaxesSetupActivity, updateTaxesSetupActivityMeta] =
    useUpdateTaxesSetupActivity({
      throwOnError: true
    });

  const [updateBookkeepingStatus, updateBookkeepingStatesMeta] =
    useSetBookkeepingEnable();

  useBrowserPageTitle("Update Tax Profile - Enabling services...", "");

  const year = moment().year();

  const qTaxEstimate = useTaxEstimate(
    {
      type: TaxEstimateType.Base,
      year
    },
    {
      enabled:
        updateTaxesSetupActivityMeta.isSuccess &&
        updateBookkeepingStatesMeta.isSuccess,
      onSuccess: async data => {
        await updateMemberProfile(
          {
            memberId,
            profile: {
              withholdings: {
                tax: {
                  rate: Math.round(data.taxRates.effective),
                  status: MemberWithholdingStatus.Paused
                }
              }
            }
          },
          { throwOnError: true }
        );

        history.push("/member/taxes/onboarding/tax-rate");
      }
    }
  );

  useEffect(() => {
    (async () => {
      if (qMember.data) {
        if (typeof qMember.data.profile?.taxInfo?.numExemptions !== "number") {
          await updateMemberProfile(
            {
              memberId,
              profile: {
                taxInfo: {
                  numExemptions: 0,
                  expensesEstimateForYear: 0
                }
              }
            },
            { throwOnError: true }
          );
        }

        await updateBookkeepingStatus(memberId, {
          throwOnError: true
        });
        await updateTaxesSetupActivity(memberId);
      }
    })();
  }, [qMember.isSuccess]);

  const isError =
    updateTaxesSetupActivityMeta.isError ||
    updateBookkeepingStatesMeta.isError ||
    qTaxEstimate.isError ||
    updateMemberProfileMeta.isError;

  return (
    <WSLayout
      line
      headerCenter={<WSText weight="medium">Preparing tax estimate</WSText>}
      headerLeft={
        isError ? (
          <WSIcon
            block
            name="chevron-left"
            size="M"
            color="gray500"
            onClick={() => history.push("/member/taxes/onboarding/state")}
          />
        ) : null
      }
    >
      <WSFlexBox.CenterX py="2XL">
        {isError ? (
          <WSText color="garnet">Something went wrong</WSText>
        ) : (
          <WSLoader.Spinner />
        )}
      </WSFlexBox.CenterX>
    </WSLayout>
  );
};
