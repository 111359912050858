import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSIcon,
  WSPill,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import {
  InvoicePayoutDestinationType,
  InvoiceStatus,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { WSQueries } from "../../../query/WSQuery";
import { useQueryInternalAccounts } from "../../../query/banking/queries/useQueryInternalAccounts";
import { useWSQuery } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useInvoiceQuery,
  usePayoutSettings
} from "../../../query/payments/queries";
import { getInvoicePayoutDestinationDescription } from "../../../query/payments/selectors";
import { useMemberProfile } from "../../../query/users/queries";
import { selectorActiveBankingAccount } from "../../../shared/selectors/selectorActiveBankingAccount";
import { selectorInstantPayoutDefaultFee } from "../../../shared/selectors/selectorInstantPayoutDefaultFee";
import { instantPayoutService } from "../../../shared/services/instantPayout";
import { invoicePayoutService } from "../../../shared/services/invoicePayouts";
import { openInNewTab } from "../../../utils/openInNewTab";
import { calculateLineItemsTotal } from "../../utils";
import { getLineItemsTotals } from "../../utils/getLineItemsTotals";
import { getPayoutMethodName, usePayoutMethods } from "../../utils/payout";
import { Totals, TotalsSection } from "../Totals/Totals";
import { getInvoiceTaxWithholdings } from "./getInvoiceTaxWithholdings";

type Props = {
  invoiceId: string;
  is1099Flow?: boolean;
} & WSElementProps;

const QUERY_INVOICE_PAYOUTS = "QUERY_INVOICE_PAYOUTS";

const useQueryInvoicePayouts = (invoiceId: string) =>
  useWSQuery([QUERY_INVOICE_PAYOUTS, invoiceId], () =>
    invoicePayoutService.getInvoicePayouts(invoiceId)
  );

export const InvoiceTotals: React.FC<Props> = ({
  invoiceId,
  is1099Flow,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryInvoice = useInvoiceQuery(invoiceId);
  const queryMember = useMemberProfile(userId);
  const queryPayoutSettings = usePayoutSettings(userId);
  const queryInvoicePayouts = useQueryInvoicePayouts(invoiceId);
  const queryInternalAccounts = useQueryInternalAccounts();

  const { payoutPreferences, payoutMethods } = usePayoutMethods();

  return (
    <WSElement {...elementProps}>
      <WSQueries
        queries={{
          queryInvoice,
          queryMember,
          queryPayoutSettings,
          queryInvoicePayouts,
          queryInternalAccounts
        }}
      >
        {({
          queryInvoice: { data: invoice },
          queryMember: { data: member },
          queryPayoutSettings: { data: payoutSettings },
          queryInvoicePayouts: { data: invoicePayouts },
          queryInternalAccounts: { data: internalAccounts }
        }) => {
          const payoutExternalTransfers =
            invoicePayoutService.getAllExternalTransfers(invoicePayouts);
          const payoutBankingTransfers =
            invoicePayoutService.getAllBankingTransfers(invoicePayouts);

          const activeBankingAccount = internalAccounts.find(
            selectorActiveBankingAccount
          );

          const instantPayoutDefaultFee =
            selectorInstantPayoutDefaultFee(payoutSettings);

          const totalElement =
            invoice.status !== InvoiceStatus.Paid &&
            invoice.status !== InvoiceStatus.PaymentInTransit ? (
              <WSFlexBox.CenterY>
                <WSText mr="M">Total</WSText>
                <WSPill icon="time" text="Pending" />
              </WSFlexBox.CenterY>
            ) : (
              "Total"
            );

          const totalsSections: TotalsSection[] = [];

          // Line items

          const lateFee = invoice.chargedFees?.lateFee?.amount || 0;
          const lineItemsAmount = calculateLineItemsTotal(invoice.lineItems);
          const lineItemsSum = lineItemsAmount + lateFee;

          const totalsLineItemsSection = getLineItemsTotals(
            invoice.lineItems,
            is1099Flow
          );

          if (lateFee > 0) {
            totalsLineItemsSection.items.push([
              "Late fee",
              toWSMoneyString(lateFee)
            ]);
          }

          totalsSections.push(totalsLineItemsSection);

          // Fees & collaborator payments

          const creditCardFee =
            invoice.creditFeeHandling?.memberPays === 100
              ? (invoice.processingFees?.creditCardFee?.amount || 0) +
                (invoice.amountDetails?.wingspanTopUp || 0)
              : 0;

          const instantPayoutFee = invoice.processingFees?.instantPayoutFee
            ?.amount
            ? invoice.processingFees.instantPayoutFee.amount
            : invoice.status !== InvoiceStatus.Paid &&
              payoutPreferences === PayoutPreferences.Instant
            ? instantPayoutService.calculateInvoiceInstantPayoutFee(
                lineItemsSum,
                payoutSettings
              )
            : 0;

          const collaboratorPayments = invoice.amountDetails
            ?.collaboratorPayments
            ? invoice.amountDetails.collaboratorPayments
            : invoice.collaborators
            ? invoice.collaborators.reduce(
                (total, collaborator) => total + collaborator.amount,
                0
              )
            : 0;

          const grossIncome =
            lineItemsSum -
            creditCardFee -
            instantPayoutFee -
            collaboratorPayments;

          if (
            creditCardFee > 0 ||
            instantPayoutFee > 0 ||
            collaboratorPayments > 0
          ) {
            const totalsFeesSection: TotalsSection = {
              items: [
                {
                  bold: true,
                  left: totalElement,
                  right: toWSMoneyString(lineItemsSum)
                }
              ]
            };

            if (creditCardFee) {
              totalsFeesSection.items.push([
                "Credit card fee",
                toWSMoneyString(-creditCardFee)
              ]);
            }

            if (instantPayoutFee) {
              if (invoice.processingFees?.instantPayoutFee?.amount) {
                totalsFeesSection.items.push([
                  `Instant payout fee (${
                    (invoice.processingFees.instantPayoutFee.percentage || 0) *
                    100
                  }%)`,
                  toWSMoneyString(
                    -invoice.processingFees.instantPayoutFee.amount
                  )
                ]);
              } else {
                totalsFeesSection.items.push([
                  `Instant payout fee (${instantPayoutDefaultFee}%)`,
                  toWSMoneyString(-instantPayoutFee)
                ]);
              }
            }

            if (collaboratorPayments) {
              totalsFeesSection.items.push([
                "Payment to contractors",
                toWSMoneyString(-collaboratorPayments)
              ]);
            }

            totalsSections.push(totalsFeesSection);
          }

          let netIncome = grossIncome;

          // Tax withholdings & deductions
          const { amount: taxWithholdingAmount, rate: taxWithholdingRate } =
            getInvoiceTaxWithholdings(member, invoice, netIncome);
          netIncome -= taxWithholdingAmount;

          const deductions = invoice.deductions || [];
          deductions.forEach(deduction => {
            netIncome -= deduction.amount;
          });

          if (deductions.length > 0 || taxWithholdingAmount > 0) {
            const section: TotalsSection = {
              items: [
                {
                  bold: true,
                  left:
                    grossIncome === lineItemsSum
                      ? totalElement
                      : "Gross income",
                  right: toWSMoneyString(grossIncome)
                },
                ...deductions.map(d => ({
                  left: d.name,
                  right: toWSMoneyString(-d.amount)
                }))
              ]
            };

            if (taxWithholdingAmount > 0) {
              section.items.push({
                left: `Tax withholdings (${taxWithholdingRate}%)`,
                right: toWSMoneyString(-taxWithholdingAmount)
              });
            }

            totalsSections.push(section);
          }

          // Payout destinations

          const payoutDestinationsSection: TotalsSection = {
            items: [
              {
                bold: true,
                left: netIncome === grossIncome ? totalElement : "Net income",
                right: toWSMoneyString(netIncome)
              }
            ]
          };

          if (
            payoutExternalTransfers.length > 0 ||
            payoutBankingTransfers.length > 0
          ) {
            payoutExternalTransfers.forEach(transfer =>
              payoutDestinationsSection.items.push([
                invoicePayoutService.getExternalTransferDescription(transfer),
                toWSMoneyString(transfer.amount)
              ])
            );

            payoutBankingTransfers.forEach(transfer =>
              payoutDestinationsSection.items.push([
                invoicePayoutService.getBankingTransferDescription(
                  activeBankingAccount
                ),
                toWSMoneyString(transfer.amount)
              ])
            );
          } else if (
            invoice.payoutDestinations &&
            invoice.payoutDestinations.length > 0
          ) {
            invoice.payoutDestinations.forEach(payoutDestination => {
              payoutDestinationsSection.items.push(
                payoutDestination.destinationType ===
                  InvoicePayoutDestinationType.WeGift
                  ? [
                      <WSButton.Link
                        rightIcon="arrow-right"
                        onClick={() => {
                          if (payoutDestination.destinationId)
                            openInNewTab(payoutDestination.destinationId);
                        }}
                      >
                        Redeem gift card
                      </WSButton.Link>,
                      null
                    ]
                  : [
                      <WSFlexBox alignItems="center">
                        <WSText color="gray500">
                          {getInvoicePayoutDestinationDescription(
                            payoutDestination
                          )}
                        </WSText>
                        {payoutDestination.payoutMethod ===
                          PayoutPreferences.Instant && (
                          <WSIcon block name="magic" ml="M" />
                        )}
                      </WSFlexBox>,
                      toWSMoneyString(payoutDestination.amount)
                    ]
              );
            });
          } else {
            if (payoutPreferences === PayoutPreferences.Instant) {
              payoutMethods.instant.active.forEach(payoutMethod => {
                payoutDestinationsSection.items.push([
                  getPayoutMethodName(payoutMethod),
                  toWSMoneyString(
                    (netIncome * (payoutMethod.percentage || 0)) / 100
                  )
                ]);
              });
            } else {
              payoutMethods.standard.active.forEach(payoutMethod => {
                payoutDestinationsSection.items.push([
                  getPayoutMethodName(payoutMethod),
                  toWSMoneyString(
                    (netIncome * (payoutMethod.percentage || 0)) / 100
                  )
                ]);
              });
            }
          }

          totalsSections.push(payoutDestinationsSection);

          return <Totals sections={totalsSections} />;
        }}
      </WSQueries>
    </WSElement>
  );
};
