import {
  SelectOptionOld,
  useModalContext,
  useWSModal,
  WSActions,
  WSForm,
  WSSelect
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdatePayer } from "../../../../query/payers/mutations/useUpdatePayer";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers,
  useIntegrationsQuickbooksItems
} from "../../../../query/integrations/queries";
import { useUpdatePayerEngagement } from "../../../../query/payerEngagements/mutations/useUpdatePayeeEngagement";
import { WSQueries } from "../../../../query/WSQuery";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { selectQuickbooksAccountOrItemOptions } from "../../../Integrations/selectors/selectQuickbooksAccountOrItemOptions";
import { selectQuickbooksUserOptions } from "../../../Integrations/selectors/selectQuickbooksUserOptions";

type Props = {
  payerId: string;
  engagementId: string | undefined;
  itemId: string | undefined;
  customerId: string | undefined;
  onClose: () => void;
};

const EditPayerIntegrationModal: React.FC<Props> = ({
  onClose,
  payerId,
  engagementId,
  itemId,
  customerId
}) => {
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers();
  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems();

  const [updatePayer, updatePayerMeta] = useUpdatePayer();
  const [updateEngagement, updateEngagementMeta] = useUpdatePayerEngagement();

  const { openModal, closeModal } = useModalContext();

  return (
    <WSQueries
      queries={{
        qIntegrationsQuickbooks,
        qIntegrationsQuickbooksCustomers,
        qIntegrationsQuickbooksItems
      }}
    >
      {({
        qIntegrationsQuickbooks: { data: integrationsQuickbooks },
        qIntegrationsQuickbooksCustomers: {
          data: integrationsQuickbooksCustomers
        },
        qIntegrationsQuickbooksItems: { data: integrationsQuickbooksItems }
      }) => {
        const quickbooksCustomerOptions: SelectOptionOld[] =
          selectQuickbooksUserOptions(integrationsQuickbooksCustomers);

        const quickbooksItemsOptions: SelectOptionOld[] =
          selectQuickbooksAccountOrItemOptions(integrationsQuickbooksItems);

        const defaultItem = integrationsQuickbooksItems.find(
          item => item.itemId === integrationsQuickbooks.defaults?.itemId
        );

        return (
          <>
            <WSForm<{
              qboCustomerId?: string | undefined;
              qboItemId?: string | undefined;
            }>
              defaultValues={{
                qboCustomerId: customerId,
                qboItemId: itemId
              }}
              onSubmit={values => {
                if (engagementId) {
                  updateEngagement(
                    {
                      payerId,
                      engagementId,
                      payeeOwnedData: {
                        integration: {
                          quickbooks: {
                            customerId: values.qboCustomerId
                              ? values.qboCustomerId
                              : undefined,
                            itemId: values.qboItemId
                              ? values.qboItemId
                              : undefined
                          }
                        }
                      }
                    },
                    {
                      onSuccess: () => {
                        onClose();
                      }
                    }
                  );
                } else {
                  updatePayer(
                    {
                      id: payerId,
                      payeeOwnedData: {
                        integration: {
                          quickbooks: {
                            customerId: values.qboCustomerId
                              ? values.qboCustomerId
                              : undefined,
                            itemId: values.qboItemId
                              ? values.qboItemId
                              : undefined
                          }
                        }
                      }
                    },
                    {
                      onSuccess: () => {
                        onClose();
                      }
                    }
                  );
                }
              }}
            >
              <WSForm.Field
                mb="XL"
                name="qboCustomerId"
                component={WSSelect}
                componentProps={{
                  placeholder: "Not mapped, create new Customer",
                  options: quickbooksCustomerOptions,
                  action: {
                    text: "Resync Customers",
                    // icon: "refresh-v",
                    onClick: () => {
                      openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                        entity: QUICKBOOKS_ENTITY.CUSTOMERS
                      });
                    }
                  }
                }}
                label="QBO Customer"
              />
              <WSForm.Field
                mb="2XL"
                name="qboItemId"
                component={WSSelect}
                componentProps={{
                  options: quickbooksItemsOptions,
                  placeholder: `Use default "${defaultItem?.fullyQualifiedName}"`,
                  action: {
                    text: "Resync Items",
                    // icon: "refresh-v",
                    onClick: () => {
                      openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                        entity: QUICKBOOKS_ENTITY.ITEMS
                      });
                    }
                  }
                }}
                label="Default QBO Item"
              />

              <WSErrorMessage
                mb="XL"
                error={updatePayerMeta.error}
                contextKey="EditClientIntegrations"
              />

              <WSActions
                alignment="fill"
                buttons={[
                  {
                    label: "Update",
                    kind: "Primary",
                    type: "submit",
                    loading: updatePayerMeta.isLoading
                  },
                  {
                    kind: "Secondary",
                    label: "Cancel",
                    onClick: onClose
                  }
                ]}
              />
            </WSForm>
            <RefreshModal />
          </>
        );
      }}
    </WSQueries>
  );
};

export const useEditPayerIntegrationModal = () =>
  useWSModal(EditPayerIntegrationModal, {
    title: "Edit integration",
    size: "S"
  });
