import { useLocalStorage } from "../../../../shared/hooks/useLocalStorage";
import { useUserId } from "../../../../query/hooks/helpers";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import queryString from "query-string";
import { useXWingspanExpansion } from "../../../../shared/hooks/useXWingspanExpansion";

const getKey = (userId: string) => "xWingspanPayerPayeeIds" + userId;

export const useXWingspanPayerId = (payeeId: string) => {
  const userId = useUserId();
  const history = useHistory();
  const setPayerId = useSetXWingspanUserPayerId();

  const [storage, set] = useLocalStorage(
    getKey(userId),
    {} as Record<string, string>
  );

  useEffect(() => {
    const payerId = queryString.parse(history.location.search)
      .payerId as string;

    if (payerId && storage[payeeId] !== payerId) {
      setPayerId(payeeId, payerId);
    }
  }, [history.location.search]);

  return storage[payeeId] as string;
};

export const useSetXWingspanUserPayerId = () => {
  const userId = useUserId();
  const [storage, set] = useLocalStorage(
    getKey(userId),
    {} as Record<string, string>
  );

  const xWingspanExpansion = useXWingspanExpansion();

  return (payeeId: string, payerId: string) => {
    xWingspanExpansion &&
      payeeId &&
      payerId &&
      set({ ...storage, [payeeId]: payerId });
  };
};
