import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { RouteComponentProps } from "react-router";
import { WSQueries } from "../../../query/WSQuery";
import { useCustomFieldsAll } from "../../../query/customFields/queries/useCustomFieldsAll";
import { PayableForm } from "../../components/PayableForm/PayableForm";

export const CreatePayable: React.FC<
  RouteComponentProps<any, any, { email?: string; backPath?: string }>
> = ({ location }) => {
  const email = location.state?.email;

  const queryCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.LineItem]
  });
  const backPath = location.state?.backPath;

  return (
    <WSQueries queries={{ queryCustomFields }}>
      {({ queryCustomFields: { data: customFields } }) => {
        return (
          <PayableForm
            backPath={backPath}
            customFields={customFields}
            email={email}
          />
        );
      }}
    </WSQueries>
  );
};
