import {
  toWSDate,
  useModalContext,
  WSActionsButton,
  WSButton,
  WSCard,
  WSElement,
  WSGrid,
  WSPage,
  WSPillProps,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IntegrationsQuickbooksAuthStatus,
  IntegrationsQuickbooksSyncStatus
} from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooks";
import { IIntegrationsQuickbooksSyncActivityGetResponse } from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooksSyncActivity";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksSyncActivitiesPreview
} from "../../../query/integrations/queries";
import { WSQueries } from "../../../query/WSQuery";
import { openIntercom } from "../../../shared/utils/intercom";
import { SettingsLoader } from "../../components/SettingsLoader/SettingsLoader";
import { SettingsSections } from "../../components/SettingsSections/SettingsSections.component";
import {
  INTEGRATIONS_QUICKBOOKS_DISCONNECT_MODAL,
  IntegrationsQuickbooksDisconnectModal
} from "./quickbooks/modals/IntegrationsQuickbooksDisconnectModal";

export interface IntegrationsTabProps {}

export const IntegrationsTab: React.FC<IntegrationsTabProps> = () => {
  const { openModal } = useModalContext();

  const history = useHistory();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksSyncActivitiesPreview =
    useIntegrationsQuickbooksSyncActivitiesPreview();

  let sections = [];

  sections.push({
    key: "Accounting",
    title: "Accounting",
    render() {
      return (
        <WSGrid pt="XL">
          <IntegrationsQuickbooksDisconnectModal />
          <WSGrid.Item span={{ s: "8", m: "6" }}>
            <WSQueries
              queries={{
                qIntegrationsQuickbooks
              }}
              renderLoader={() => (
                <WSCard header="Quickbooks">
                  <SettingsLoader repeat={1} />
                </WSCard>
              )}
              renderErrors={() => (
                <WSCard
                  header={{
                    label: {
                      text: "Quickbooks",
                      helperText:
                        "We're having some trouble loading your configuration!"
                    }
                  }}
                />
              )}
            >
              {({ qIntegrationsQuickbooks }) => {
                const { authStatus, syncStatus } = qIntegrationsQuickbooks.data;

                let syncActivities: IIntegrationsQuickbooksSyncActivityGetResponse[] =
                  [];

                if (
                  qIntegrationsQuickbooksSyncActivitiesPreview &&
                  qIntegrationsQuickbooksSyncActivitiesPreview.data &&
                  qIntegrationsQuickbooksSyncActivitiesPreview.data.data
                    .length > 0
                ) {
                  syncActivities =
                    qIntegrationsQuickbooksSyncActivitiesPreview.data?.data;
                }

                let cardPrimaryAction: WSActionsButton | undefined = {
                  label: "Set up Quickbooks",
                  onClick: () =>
                    history.push(
                      "/member/settings/integrations/quickbooks/setup/step/1"
                    )
                };
                let cardOtherAction: WSActionsButton | undefined = undefined;
                let cardSubtitle: string =
                  "Wingspan can automatically push data into your Quickbooks Online account.";
                let cardPill: WSPillProps = {
                  theme: "neutral",
                  icon: "dollar-circle"
                };
                let cardContent: ReactNode | undefined;

                if (
                  authStatus === IntegrationsQuickbooksAuthStatus.Disconnected
                ) {
                  cardPrimaryAction = {
                    label: "Reconnect Quickbooks",
                    onClick: () =>
                      history.push(
                        "/member/settings/integrations/quickbooks/setup/step/1"
                      )
                  };
                  cardPill = {
                    icon: "alert-circle",
                    theme: "error"
                  };
                  cardSubtitle = "Your Quickbooks has been disconnected.";
                } else if (
                  authStatus === IntegrationsQuickbooksAuthStatus.Connected
                ) {
                  cardOtherAction = {
                    label: "Disconnect Quickbooks",
                    kind: "Secondary",
                    onClick: () =>
                      openModal(INTEGRATIONS_QUICKBOOKS_DISCONNECT_MODAL)
                  };
                  if (syncStatus === IntegrationsQuickbooksSyncStatus.Synced) {
                    cardPrimaryAction = {
                      label: "Update Quickbooks Mappings",
                      onClick: () =>
                        history.push(
                          "/member/settings/integrations/quickbooks/setup/step/2"
                        )
                    };
                    cardPill = {
                      icon: "dollar-circle",
                      theme: "success"
                    };
                    cardSubtitle =
                      "Wingspan is currently pushing data to your Quickbooks account.";
                  } else {
                    let primaryButtonText = "";
                    if (
                      syncStatus ===
                        IntegrationsQuickbooksSyncStatus.PendingSync ||
                      syncStatus ===
                        IntegrationsQuickbooksSyncStatus.NeverSynced
                    ) {
                      cardPill = {
                        icon: "dollar-circle",
                        theme: "warning"
                      };
                      cardSubtitle =
                        "Your Quickbooks is connected but not synced up. Please continue setting up";
                      primaryButtonText = "Set up Quickbooks";
                    } else {
                      cardPill = {
                        icon: "alert-circle",
                        theme: "warning"
                      };
                      cardSubtitle =
                        "Your Quickbooks is out of sync! Please re-map your data.";
                      primaryButtonText = "Fix Quickbooks sync";
                    }
                    cardPrimaryAction = {
                      label: primaryButtonText,
                      onClick: () =>
                        history.push(
                          "/member/settings/integrations/quickbooks/setup/step/1"
                        )
                    };
                  }
                  cardContent =
                    syncActivities.length > 0
                      ? [
                          <WSElement mt="XL">
                            <WSButton.Link
                              mb="M"
                              name="lastUpdated"
                              onClick={() =>
                                history.push(
                                  "/member/settings/integrations/quickbooks/history"
                                )
                              }
                            >
                              {`Last updated ${toWSDate(
                                syncActivities[0].createdAt,
                                "timeframe"
                              )}`}
                            </WSButton.Link>
                          </WSElement>
                        ]
                      : [];
                } else if (
                  authStatus ===
                  IntegrationsQuickbooksAuthStatus.RetryingConnection
                ) {
                  cardOtherAction = {
                    label: "Disconnect Quickbooks",
                    kind: "Secondary",
                    onClick: () =>
                      openModal(INTEGRATIONS_QUICKBOOKS_DISCONNECT_MODAL)
                  };
                  cardPill = {
                    icon: "alert-circle"
                  };
                  cardSubtitle =
                    "We're having some trouble communicating with your Quickbooks. We're trying to fix it and will alert you if we need your help.";
                  cardContent = [
                    <WSText>
                      If this error persists, please{" "}
                      <WSButton.Link onClick={openIntercom}>
                        contact support.
                      </WSButton.Link>
                    </WSText>
                  ];
                  cardPrimaryAction = undefined;
                }

                return (
                  <WSCard
                    divider
                    header={{
                      label: { text: "Quickbooks", helperText: cardSubtitle },
                      value: {
                        pill: cardPill
                      }
                    }}
                    actions={{
                      buttons: [cardPrimaryAction, cardOtherAction].filter(
                        Boolean
                      ) as WSActionsButton[]
                    }}
                  >
                    {cardContent}
                  </WSCard>
                );
              }}
            </WSQueries>
          </WSGrid.Item>
        </WSGrid>
      );
    }
  });

  return (
    <WSPage title="Integrations">
      <SettingsSections
        title="Integrations"
        parentPathName="/member/settings/integrations"
        sections={sections}
      />
    </WSPage>
  );
};
