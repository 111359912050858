import { TaxFilingCode } from "@wingspanhq/users/dist/lib/interfaces";
import country from "@wingspanhq/users/dist/lib/services/fixtures/country-iso-codes-list.json";
import { USStates } from "./USstates";

export const SINGLE_OPTIONS = [
  {
    value: 40000,
    label: "0-40k"
  },
  {
    value: 80000,
    label: "40k-80k"
  },
  {
    value: 160000,
    label: "80k-160k"
  },
  {
    value: 200000,
    label: "160k-200k"
  },
  {
    value: 500000,
    label: "200k-500k"
  },
  {
    value: 510000,
    label: "500k+"
  }
];
export const MARRIED_OPTIONS = [
  {
    value: 80000,
    label: "0-80k"
  },
  {
    value: 160000,
    label: "80k-160k"
  },
  {
    value: 320000,
    label: "160k-320k"
  },
  {
    value: 400000,
    label: "320k-400k"
  },
  {
    value: 600000,
    label: "400k-600k"
  },
  {
    value: 610000,
    label: "600k+"
  }
];
export const HEAD_OF_HOUSEHOLD_OPTIONS = [
  {
    value: 50000,
    label: "0-50k"
  },
  {
    value: 80000,
    label: "50k-80k"
  },
  {
    value: 160000,
    label: "80k-160k"
  },
  {
    value: 200000,
    label: "160k-200k"
  },
  {
    value: 500000,
    label: "200k-500k"
  },
  {
    value: 510000,
    label: "500k+"
  }
];
export const FILLING_CODE_OPTIONS = [
  {
    value: TaxFilingCode.Single,
    label: "Single"
  },
  {
    value: TaxFilingCode.Married,
    label: "Married"
  },
  {
    value: TaxFilingCode.HeadOfHousehold,
    label: "Head of Household"
  },
  {
    value: TaxFilingCode.MarriedFilingSeparately,
    label: "Married Filing Separately"
  },
  {
    value: TaxFilingCode.QualifiedWidower,
    label: "Qualified Widower"
  }
];
export const EXPENSES_OPTIONS = [
  {
    value: 1000,
    label: "0-1k"
  },
  {
    value: 5000,
    label: "1k-10k"
  },
  {
    value: 11000,
    label: "10k+"
  }
];

export const US_STATES_OPTIONS = USStates.map(s => ({
  value: s.abbreviation,
  label: s.name,
  searchText: s.name + " " + s.abbreviation
}));

export const US_STATES_OPTIONS_SHORT_NAME = USStates.map(s => ({
  value: s.abbreviation,
  label: s.abbreviation,
  searchText: s.name
}));

const usTerritoryCodes = ["PR", "GU", "VI", "AS", "MP", "UM"];

const taxCountries = country.filter(
  country => !usTerritoryCodes.includes(country["alpha-2"])
);

export const COUNTRY_OPTIONS = taxCountries.map(i => ({
  value: i["alpha-2"],
  label: i.name,
  searchText: i.name + " " + i["alpha-2"]
}));
