import { servicePayments } from "../servicePayments";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";

export const getPayeeEngagement = async (
  payeeId: string,
  engagementId: string,
  orgPayerId?: string
): Promise<IPayeeEngagementResponse> => {
  const { data } = await servicePayments.get(
    `/payee/${payeeId}/engagement/${engagementId}`,
    {
      ...(orgPayerId
        ? {
            headers: {
              "x-wingspan-user": orgPayerId
            }
          }
        : {})
    }
  );

  return data;
};
