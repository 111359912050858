import {
  useIsMobile,
  WSContainer,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { WingspanProductAspects } from "@wingspanhq/users/dist/lib/interfaces";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Header } from "../../Auth/components/Header/Header";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { useCMSWebflowPartner } from "../../query/cms/queries";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { useSetWSStore, useWSStore } from "../../store";
import { GrowthSourceNames } from "../../utils/growthAttribution";
import { OnboardingQuestion } from "../components/OnboardingQuestion/OnboardingQuestion";
import styles from "./styles.module.scss";

interface OnboardingStep3BookkeepingProps {}

export const OnboardingStep3Bookkeeping: React.FC<
  OnboardingStep3BookkeepingProps
> = () => {
  const [answer, setAnswer] = useLocalStorage("pre-sign-up:q3", "");
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const setStore = useSetWSStore();
  const store = useWSStore();

  const partnerId =
    (store.growthAttributionDetails?.growthSource ===
      GrowthSourceNames.Partner &&
      store.growthAttributionDetails?.growthName) ||
    "";
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });

  const partner = qPartner.data;

  const onBack = () => {
    history.push(`/member/pre-sign-up/questionnaire/2${location.search}`);
  };

  const onSubmit = async (data: any) => {
    setAnswer(data.productAspectImportance);
    history.push(`/member/sign-up${location.search}`);
  };
  return (
    <WSLayout
      onBack={isMobile ? undefined : onBack}
      line
      headerCenter={<Header hideBorder partnerLogo={partner?.logo?.src} />}
    >
      <BrowserPageTitle title="Onboarding: Step 3 | Bookkeeping" />
      <WSMessageBox kind="Warning" noBorder centered className={styles.banner}>
        {" "}
      </WSMessageBox>
      <WSContainer verticalPadding>
        {isMobile ? (
          <WSFlexBox.CenterY mb="M" onClick={() => history.goBack()}>
            <WSIcon
              block
              name="chevron-left"
              size="XS"
              mr="XS"
              color="gray600"
            />
            <WSText.ParagraphSm color="gray500">
              <b>Back</b>
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
        ) : null}
        <OnboardingQuestion
          name="productAspectImportance"
          question={{
            question: "What could be better?",
            options: [
              {
                label: "Track income streams",
                value: WingspanProductAspects.TrackIncomeStreams,
                virtualAssistantMsg:
                  "Wingspan helps you see how much you're making across various accounts and income streams (i.e. PayPay, Venmo, Zelle, etc.)",
                ctaBtnText: "Try Bookkeeping"
              },
              {
                label: "Tracking expenses",
                value: WingspanProductAspects.ExpenseTracking,
                virtualAssistantMsg:
                  "Wingspan automatically flags deductible expenses, so you don't have to go through a shoebox of receipts at tax time.",
                ctaBtnText: "Try Bookkeeping"
              },
              {
                label: "Finding tax deductions",
                value: WingspanProductAspects.TaxDeductions,
                virtualAssistantMsg:
                  "Wingspan automatically flags deductible expenses, so you don't have to go through a shoebox of receipts at tax time.",
                ctaBtnText: "Try Bookkeeping"
              },
              {
                label: "Doing taxes",
                value: WingspanProductAspects.TaxFiling,
                virtualAssistantMsg:
                  "Wingspan not only calculates what to set aside as you get paid, it automates quarterly filings and payments too.",
                ctaBtnText: "Try Bookkeeping"
              }
            ]
          }}
          onSubmit={onSubmit}
          defaultValues={{
            productAspectImportance: answer
          }}
        />
      </WSContainer>
    </WSLayout>
  );
};
