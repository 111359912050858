import { WSInfoBox, WSList } from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerifications } from "../../../../../query/onboarding/queries/useQueryVerifications";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { DataView } from "../../../../../shared/components/DataView";
import { openIntercom } from "../../../../../shared/utils/intercom";
import { ReviewPerson } from "../../../../Onboarding/components/ReviewPerson";
import { DataViewTaxpayerInformation } from "../../../components/DataViewTaxpayerInformation";
import { useModalEditAddress } from "../components/FormEditAddress";
import { useModalEditBusinessPhoneNumber } from "../components/FormEditBusinessPhoneNumber";
import { useModalEditDBA } from "../components/FormEditDBA";
import { useModalEditStateOfInroproration } from "../components/FormEditStateOfInroproration";
import { useModalEditWebsite } from "../components/FormEditWebsite";
import { useMemo } from "react";

type Props = {};

export const RouteBusinessInformation: React.FC<Props> = () => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity();
  const queryVerifications = useQueryVerifications();
  const modalEditDBA = useModalEditDBA();
  const modalEditBusinessPhoneNumber = useModalEditBusinessPhoneNumber();
  const modalEditWebsite = useModalEditWebsite();
  const modalEditAddress = useModalEditAddress();
  const modalEditStateOfInroproration = useModalEditStateOfInroproration();

  const country = useMemo(
    () =>
      queryMonitoredEntity.data?.country ||
      queryMember.data?.profile.address?.country ||
      "US",
    [
      queryMember.data?.profile.address?.country,
      queryMonitoredEntity.data?.country
    ]
  );

  if (!queryUser.data || !queryMember.data) {
    return null;
  }

  const user = queryUser.data;
  const member = queryMember.data;

  return (
    <WSList gap="2XL">
      <WSInfoBox title="Please ensure your information is up to date and accurate">
        <ol>
          <li>Click edit to edit your information.</li>
          <li>
            Your account type and fields with a green checkmark cannot be edited
            once your account has been verified.
          </li>
          <li>To make changes to verified fields, click “Request update”.</li>
        </ol>
      </WSInfoBox>

      <DataViewTaxpayerInformation
        monitoredEntity={queryMonitoredEntity.data}
        user={user}
        member={member}
        verificationStatus={queryVerifications.data?.tax}
      />

      <DataView
        title="Business information"
        data={[
          {
            label: {
              text: "Doing business as (DBA)",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditDBA.open();
                }
              }
            },
            value: member.profile.company?.name
          },
          {
            label: "Business email",
            value: member.profile.company?.supportEmail
          },
          {
            label: {
              text: "Business phone number",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditBusinessPhoneNumber.open();
                }
              }
            },
            value: member.profile.company?.phoneNumber
          },
          {
            label: {
              text: "Business website",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditWebsite.open();
                }
              }
            },
            value: member.profile.company?.website
          },
          {
            label: {
              text: "Business address",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditAddress.open();
                }
              }
            },
            value: member.profile.address
              ? [
                  member.profile.address.addressLine1,
                  member.profile.address.addressLine2,
                  [
                    member.profile.address.city,
                    member.profile.address.state,
                    member.profile.address.postalCode
                  ].join(" ")
                ]
                  .filter(Boolean)
                  .join("\n")
              : undefined,
            fsExclude: true
          },
          country === "US" || country === "CA"
            ? {
                label: {
                  text: "State/Territory of incorporation",
                  actionPosition: "left",
                  action: {
                    label: "Edit",
                    onClick: () => {
                      modalEditStateOfInroproration.open();
                    }
                  }
                },
                value: member.profile.company?.stateOfIncorporation
              }
            : null
        ]}
      />

      <ReviewPerson
        type="Representative"
        user={user}
        member={member}
        header={{
          label: "Representative information (You)",
          value: {
            action: {
              label: "Contact support",
              onClick: openIntercom
            }
          }
        }}
      />
    </WSList>
  );
};
