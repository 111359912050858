import {
  useIsMobile,
  useModalContext,
  WSButton,
  WSFlexBox,
  WSFormOld,
  WSInputMaskOld,
  WSMessageBox,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { getSsnInputProps } from "../../../shared/utils/getSsnInputProps";
import { validatorSSN } from "../../../utils/validators";

export const MODAL_ADD_SSN = "MODAL_ADD_SSN";

export const TaxesModalAddSSN: React.FC = () => {
  const isMobile = useIsMobile();
  const { closeModal } = useModalContext();
  const memberId = useUserId();
  const [updateMemberProfile, updateMemberProfileMeta] =
    useUpdateMemberProfile();

  return (
    <WSModal name={MODAL_ADD_SSN} size="S">
      {({ onSuccess, onFail }) => (
        <>
          <WSText.Heading5 mb="M">
            Add social security number to send payment.
          </WSText.Heading5>
          <WSText my="XL">
            The IRS requires your full SSN to receive your payment.
          </WSText>

          <WSFormOld
            defaultValues={{
              ssn: ""
            }}
            validationSchema={Yup.object().shape({
              ssn: validatorSSN.required("Required")
            })}
            onSubmit={async formData => {
              await updateMemberProfile({
                memberId,
                profile: {
                  ssn: formData.ssn
                }
              });
              closeModal(MODAL_ADD_SSN);
              onSuccess();
            }}
          >
            <WSFormOld.Field
              label="SSN"
              name="ssn"
              component={WSInputMaskOld}
              componentProps={getSsnInputProps()}
              mb="2XL"
            />

            {updateMemberProfileMeta.isError ? (
              <WSMessageBox.Error>Something went wrong</WSMessageBox.Error>
            ) : null}

            <WSFlexBox
              wrap="nowrap"
              direction={isMobile ? "column" : undefined}
            >
              <WSButton.Secondary
                fullWidth={isMobile}
                size="M"
                type="button"
                onClick={() => {
                  closeModal(MODAL_ADD_SSN);
                  onFail();
                }}
                mr={isMobile ? undefined : "S"}
              >
                Cancel
              </WSButton.Secondary>
              <WSButton.Primary
                fullWidth={isMobile}
                size="M"
                mt={isMobile ? "M" : undefined}
                type="submit"
                loading={updateMemberProfileMeta.isLoading}
              >
                Submit SSN
              </WSButton.Primary>
            </WSFlexBox>
          </WSFormOld>

          <WSText.ParagraphSm mt="XL">
            Wingspan uses bank-grade security to protect your data.{" "}
            <a
              href="https://www.wingspan.app/security"
              target="_blank"
              rel="noreferrer"
            >
              Learn more.
            </a>
          </WSText.ParagraphSm>
        </>
      )}
    </WSModal>
  );
};
