import { EnabledExperimentsResponse } from "../types/experiments";
import { WSService } from "../utils/WSService";
import { getAnonymousStamp } from "../utils/anonymousStamp";

const service = new WSService("/experiments");

export const getEnabledExperiments =
  async (): Promise<EnabledExperimentsResponse> => {
    const {
      data: { enabledExperiments }
    } = await service.get("/enabled-experiments");

    return enabledExperiments;
  };

export const getEnabledAnonymousExperiments =
  async (): Promise<EnabledExperimentsResponse> => {
    const anonymousId = getAnonymousStamp();
    const {
      data: { enabledExperiments }
    } = await service.get(`/enabled-experiments/anonymous/${anonymousId}`);

    return enabledExperiments;
  };
