import {
  useWSModal,
  WSForm,
  WSInputText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { queryCache } from "react-query";
import * as Yup from "yup";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_MEMBER_PROFILE } from "../../../../../query/users/keys";
import { useMemberProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { validatorStringTextNumbersSymbols } from "../../../../../shared/validators/validatorStringTextNumbersSymbols";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { newValue: string };

type Props = {
  onClose: () => void;
};

export const FormEditDBA: React.FC<Props> = props => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const member = await usersService.member.update(userId, {
        memberId: userId,
        profile: {
          company: {
            name: data.newValue
          }
        }
      });

      queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);
    },
    {
      dependencies: [QUERY_USERS_MEMBER_PROFILE],
      onSuccess: props.onClose
    }
  );

  return (
    <WSForm<FormData>
      defaultValues={{
        newValue: queryMember.data?.profile.company?.name
      }}
      onSubmit={mutate}
      validationSchema={Yup.object({
        newValue: validatorStringTextNumbersSymbols
      })}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        <WSForm.Field
          name="newValue"
          label="Doing business as (DBA) (optional)"
          component={WSInputText}
        />
      </LayoutFormEdit>
    </WSForm>
  );
};

export const useModalEditDBA = () =>
  useWSModal(FormEditDBA, {
    title: "Doing business as (DBA)",
    size: "S"
  });
