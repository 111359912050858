import {
  WSActions,
  WSAlert,
  WSDivider,
  WSForm,
  WSFormProps,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import merge from "lodash/merge";
import { WSFormErrorSummary } from "../../../../shared/components/WSFormErrorSummary";
import { WS_LINKS } from "../../../../types/wsLinks";
import { FormPartialDataPerson, FormPartialPerson } from "../FormPartialPerson";
import { useModalWhyWeCollectInfo } from "../ModalWhyWeCollectInfo";
import { emptyValues } from "./emptyValues";
import { getValidationSchema } from "./validationSchema";

export type FormDataPersonalInformation = { person: FormPartialDataPerson };

type Props = {
  defaultValues?: WSFormProps<FormDataPersonalInformation>["defaultValues"];
  onSubmit: WSFormProps<FormDataPersonalInformation>["onSubmit"];
  onBack?: () => void;
  mismatch?: boolean;
  country: string;
};

export const FormPersonalInformation: React.FC<Props> = ({
  defaultValues,
  onBack,
  onSubmit,
  mismatch,
  country
}) => {
  const modal = useModalWhyWeCollectInfo();

  return (
    <WSForm<FormDataPersonalInformation>
      defaultValues={merge(emptyValues, defaultValues)}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
    >
      <WSList gap="2XL">
        {mismatch && (
          <WSAlert
            icon="alert"
            theme="warning"
            title="IRS TIN Mismatch Detected"
          >
            <WSInfoBox transparent>
              <p>
                The information you provided does not match IRS records. Please
                review and update:
              </p>
              <ul>
                <li>
                  Full Legal Name (as it appears on your Social Security card)
                </li>
                <li>Social Security Number (SSN)</li>
              </ul>
              <p>To ensure accuracy:</p>
              <ol>
                <li>Double-check for typos or transposed numbers</li>
                <li>
                  Verify your name/business name exactly matches IRS records
                </li>
                <li>
                  For recent name changes, ensure you've updated the Social
                  Security Administration or IRS
                </li>
                <li>
                  Confirm you're using the correct TIN type (SSN vs EIN) for
                  your situation
                </li>
              </ol>
            </WSInfoBox>
          </WSAlert>
        )}

        <WSPanel>
          <WSSectionToolbar
            title="Personal information"
            button={{
              label: "Why do we collect this info?",
              onClick: modal.open
            }}
          />
          <WSText.ParagraphSm color="gray500">
            Wingspan is required to collect this information for compliance
            purposes. Your information is{" "}
            <a href={WS_LINKS.security} target="_blank" rel="noreferrer">
              certified secure
            </a>
            .
          </WSText.ParagraphSm>

          <WSDivider my="L" />

          <FormPartialPerson
            type="Individual"
            name="person"
            requireTaxId
            mismatch={mismatch}
            country={country}
          />
        </WSPanel>
        <WSFormErrorSummary />

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              onClick: onBack,
              kind: "Secondary",
              type: "button",
              visible: !!onBack
            },
            {
              label: "Continue"
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
