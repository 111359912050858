import {
  IPayerTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayerTaxFormIsBusiness = (
  taxForm: IPayerTaxFormResponse
) => {
  return taxForm.data.w9Info?.tinType === TinType.Business;
};
