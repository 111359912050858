import { loadStripe, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import {
  useModalContext,
  WSButton,
  WSFormOld,
  WSModal,
  WSStripeInput,
  WSStripeInputRef,
  WSText
} from "@wingspanhq/fe-component-library";
import { IClientUpdateRequest, IPaymentMethod } from "@wingspanhq/users";
import React, { useMemo, useRef, useState } from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCreateClient,
  useUpdateClient
} from "../../../query/users/mutations";
import { useClientQuery } from "../../../query/users/queries";
import { addElementToArray } from "../../../utils/serviceHelper";
import { CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL } from "./ChooseAddPaymentMethodFlowModal";

export const SAVED_PAYMENT_METHOD_CREDIT_CARD_MODAL =
  "SAVED_PAYMENT_METHOD_CREDIT_CARD_MODAL";

interface SavedPaymentMethodCreditCardFormProps {
  shouldSetDefaultPaymentMethod?: boolean;
  shouldSetSubscriptionPaymentMethod?: boolean;
  onFinish?: () => void;
}

export const SavedPaymentMethodCreditCardForm: React.FC<
  SavedPaymentMethodCreditCardFormProps
> = ({
  shouldSetDefaultPaymentMethod,
  shouldSetSubscriptionPaymentMethod,
  onFinish = () => {}
}) => {
  const { closeModal } = useModalContext();
  const stripeInput = useRef<WSStripeInputRef>(null);
  const [stripeComplete, setStripeComplete] = useState(false);
  const [stripeInputState, setStripeInputState] = useState<
    StripeCardElementChangeEvent | undefined
  >();
  const stripePromise = useMemo(
    () => loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`),
    []
  );
  const userId = useUserId();
  const clientQuery = useClientQuery(userId);
  const [createClient, createClientMeta] = useCreateClient(userId);
  const [updateClient, updateClientMeta] = useUpdateClient(userId);

  const onFormSubmit = async () => {
    let token: string | undefined;
    if (stripeInputState?.complete) {
      if (!clientQuery.data && clientQuery.error?.response?.status === 400) {
        createClient({ userId });
      }
      const result = await stripeInput?.current?.createPaymentMethod();
      if (result?.token) {
        token = result?.token.id;
      }

      if (token) {
        const request: IClientUpdateRequest = {
          clientId: userId,
          profile: {
            savedPaymentMethods: addElementToArray<IPaymentMethod>(
              clientQuery.data?.profile.savedPaymentMethods?.length || 0,
              { paymentMethodId: token }
            ),
            ...(shouldSetDefaultPaymentMethod
              ? {
                  defaultPaymentMethod: {
                    paymentMethodId: token
                  }
                }
              : {}),
            ...(shouldSetSubscriptionPaymentMethod
              ? {
                  subscriptionPaymentMethod: {
                    paymentMethodId: token
                  }
                }
              : {})
          }
        };
        await updateClient(request, {
          onSuccess: () => {
            closeModal(SAVED_PAYMENT_METHOD_CREDIT_CARD_MODAL);
            closeModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL);
            onFinish();
          }
        });
      }
    }
  };
  return (
    <WSFormOld onSubmit={onFormSubmit}>
      {({ formState }) => (
        <>
          <WSStripeInput
            ref={stripeInput}
            stripeInstance={stripePromise}
            onInputChange={event => {
              setStripeInputState(event);
              setStripeComplete(event.complete);
            }}
            name="creditCard"
            mb="XS"
          />
          <WSErrorMessage
            contextKey="BankCard"
            error={updateClientMeta.error}
          />
          <WSButton
            icon="lock"
            mt="XL"
            type="submit"
            disabled={!stripeComplete}
            fullWidth
            loading={formState.isSubmitting}
            name="submit"
          >
            Confirm payment method
          </WSButton>
        </>
      )}
    </WSFormOld>
  );
};

export const SavedPaymentMethodCreditCardModal = () => {
  return (
    <WSModal
      name={SAVED_PAYMENT_METHOD_CREDIT_CARD_MODAL}
      size="S"
      title="Add credit/debit card"
    >
      {modalProps => (
        <SavedPaymentMethodCreditCardForm
          shouldSetDefaultPaymentMethod={
            modalProps.shouldSetDefaultPaymentMethod
          }
          shouldSetSubscriptionPaymentMethod={
            modalProps.shouldSetSubscriptionPaymentMethod
          }
          onFinish={modalProps.onFinish}
        />
      )}
    </WSModal>
  );
};
