import {
  useModalContext,
  WSButton,
  WSButtons,
  WSCheckboxGroup,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSModal,
  WSPage,
  WSScreen,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  AuthorizedAction,
  IUserCreateRequest,
  SubscriptionPackage,
  UserStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import cn from "classnames";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import {
  useCreateSubscriptionGrant,
  useDeleteSubscriptionGrant,
  useUpdateSubscriptionGrant
} from "../../../../query/subscriptions/mutations";
import {
  useCreateAuthorization,
  useCreateUser,
  useDeleteAuthorization
} from "../../../../query/users/mutations";
import { usersService } from "../../../../services/users";
import { goToParentRoute } from "../../../../utils/goToParentRoute";
import { WSServiceError } from "../../../../utils/serviceHelper";
import {
  validatorFirstName,
  validatorLastName
} from "../../../../utils/validators";
import {
  SubscriptionGrantOptionsModal,
  SUBSCRIPTION_GRANT_OPTIONS_MODAL
} from "../../components/SubscriptionGrantOptionsModal/SubscriptionGrantOptionsModal";
import {
  ITeamMemberRecord,
  scopeGroups
} from "../../../../shared/utils/teamUtils";
import { TwoLineLabel } from "../RouteAddTeamMember/AddTeamMember";
import styles from "../RouteAddTeamMember/AddTeamMember.module.scss";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import {
  SUBSCRIPTION_SELECT_OPTIONS,
  SubscriptionGrantOption
} from "../../constants/subscriptionOptions";

interface EditTeamMemberProps {}

export const EditTeamMember: React.FC<EditTeamMemberProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const [formError, setFormError] = React.useState("");
  const [isModalOpened, setIsModalOpened] = React.useState(false);

  const [createAuthorization, createAuthorizationMeta] =
    useCreateAuthorization();
  const [deleteAuthorization, deleteAuthorizationMeta] =
    useDeleteAuthorization();
  const [createUser] = useCreateUser();
  const [createSubscriptionGrant] = useCreateSubscriptionGrant();
  const [updateSubscriptionGrant] = useUpdateSubscriptionGrant();
  const [deleteSubscriptionGrant] = useDeleteSubscriptionGrant();
  const { openModal } = useModalContext();
  const teamMember = (location.state as any).teamMember as ITeamMemberRecord;
  const { authorization, subscriptionGrant } = teamMember;
  const [selectedSubscription, setSelectedSubscription] = React.useState(() => {
    if (subscriptionGrant) {
      return (
        SUBSCRIPTION_SELECT_OPTIONS.find(
          option =>
            option.value.package === subscriptionGrant.package &&
            option.value.packageTier === subscriptionGrant.packageTier
        ) || SUBSCRIPTION_SELECT_OPTIONS[0]
      );
    }
    return SUBSCRIPTION_SELECT_OPTIONS[0];
  });

  useEffect(() => {
    if (!teamMember) {
      history.replace("/member/settings/team");
    }
  }, [location.state]);

  const onFormSubmit = async (data: any, teamMember: ITeamMemberRecord) => {
    setFormError("");
    createAuthorizationMeta.reset();
    deleteAuthorizationMeta.reset();
    const trimmedFormData = {
      ...data,
      email: data.email.trim(),
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim()
    };
    const request: any = {
      id: teamMember.authorization?.authorizationId,
      allowedAction: AuthorizedAction.Write
    };
    if (trimmedFormData.email) {
      try {
        const userByEmailRes = await usersService.user.email.get(
          trimmedFormData.email
        );
        request.requestingUserId = userByEmailRes.userId;
      } catch (err) {
        if ((err as WSServiceError).response?.status === 404) {
          const payload: IUserCreateRequest = {
            email: trimmedFormData.email,
            status: UserStatus.Pending,
            notificationSettings: {
              reviewNotifications: true,
              newsletters: true
            }
          };
          if (trimmedFormData.firstName && trimmedFormData.lastName) {
            payload.profile = {
              firstName: trimmedFormData.firstName,
              lastName: trimmedFormData.lastName
            };
          }
          const newUser = await createUser(payload);
          request.requestingUserId = newUser?.userId as string;
        }
      }
    }
    // Edit feature is implemented with Add and Remove
    const oldScopeGroups =
      teamMember.authorization?.allowedScopeGroupIds.map(
        ({ allowedScopeGroupId }: any) => allowedScopeGroupId
      ) || [];
    const { permissions: newScopeGroups = [] } = trimmedFormData;

    // Create authorizations if user adds new ones
    const newScopeGroupIds = newScopeGroups.filter(
      (sg: string) => !oldScopeGroups.includes(sg)
    );
    await Promise.all(
      newScopeGroupIds.map(async (scopeGroupId: string) => {
        const payload = {
          ...request,
          allowedScopeGroupId: scopeGroupId
        };
        await createAuthorization(payload);
      })
    );

    // Delete authorization if user removes old ones
    const removedScopeGroupIds = oldScopeGroups.filter(
      (sg: string) => !newScopeGroups.includes(sg)
    );
    await Promise.all(
      removedScopeGroupIds.map(async (scopeGroupId: string) => {
        const allowedScopeGroup =
          teamMember.authorization?.allowedScopeGroupIds.find(
            (sg: any) => sg.allowedScopeGroupId === scopeGroupId
          );
        allowedScopeGroup &&
          (await deleteAuthorization(allowedScopeGroup.authorizationId));
      })
    );

    const { value: subscriptionPkgProps } = selectedSubscription;
    if (
      [
        SubscriptionPackage.Benefits,
        SubscriptionPackage.Essentials,
        SubscriptionPackage.Professional
      ].includes(subscriptionPkgProps.package)
    ) {
      if (!!teamMember.subscriptionGrant) {
        await updateSubscriptionGrant({
          id: teamMember.subscriptionGrant.subscriptionGrantId,
          ...subscriptionPkgProps
        });
      } else {
        await createSubscriptionGrant({
          granteeId: request.requestingUserId,
          package: subscriptionPkgProps.package,
          packageTier: subscriptionPkgProps.packageTier
        });
      }
    }
    // Delete subscription-grant if subscription-grant exists
    // and None is selected
    if (
      !!teamMember.subscriptionGrant &&
      subscriptionPkgProps.package === SubscriptionPackage.None
    ) {
      await deleteSubscriptionGrant(
        teamMember.subscriptionGrant.subscriptionGrantId
      );
    }

    goToParentRoute(history);
  };
  const { teamMemberWSUser } = teamMember;
  const editTeamMemberForm = (
    <WSFormOld
      onSubmit={data => onFormSubmit(data, teamMember)}
      defaultValues={{
        email: teamMemberWSUser.email,
        firstName: teamMemberWSUser?.profile?.firstName || "",
        lastName: teamMemberWSUser?.profile?.lastName || "",
        permissions:
          authorization?.allowedScopeGroupIds.map(
            ({ allowedScopeGroupId }: any) => allowedScopeGroupId
          ) || []
      }}
      validationSchema={Yup.object().shape({
        email: validatorEmail.required("Email is required"),
        firstName: validatorFirstName,
        lastName: validatorLastName
      })}
    >
      <WSFormOld.Field
        mb="2XL"
        name="firstName"
        label="First name"
        component={WSTextInput}
        componentProps={{
          disabled: true
        }}
      />
      <WSFormOld.Field
        mb="2XL"
        name="lastName"
        label="Last name"
        component={WSTextInput}
        componentProps={{
          disabled: true
        }}
      />

      <WSFormOld.Field
        mb="2XL"
        name="email"
        label="Email"
        component={WSTextInput}
        componentProps={{
          type: "email",
          disabled: true
        }}
      />

      <WSFormOld.Field name="permissions" mb="2XL">
        <WSText.Heading5 mb="M">Permissions</WSText.Heading5>
        <WSFormOld.Input
          render={props => (
            <>
              <WSCheckboxGroup
                {...props}
                options={Object.values(scopeGroups).map(
                  ({ title, scopes, scopeGroupId }) => ({
                    label: <TwoLineLabel title={title} scopes={scopes} />,
                    value: scopeGroupId
                  })
                )}
              />
              <WSFormOld.Error />
            </>
          )}
        />
      </WSFormOld.Field>

      <WSFlexBox direction="column" mb="XL">
        <WSText.Heading5 mb="M">Membership</WSText.Heading5>
        <WSFlexBox.CenterY
          className={styles.membership}
          p="S"
          onClick={() => {
            setIsModalOpened(true);
            openModal(SUBSCRIPTION_GRANT_OPTIONS_MODAL, {
              selectedSubscription,
              onChange: (sg: SubscriptionGrantOption) => {
                setFormError("");
                setSelectedSubscription(sg);
              },
              onCloseModal: () => {
                setIsModalOpened(false);
              },
              action: "Edit"
            });
          }}
          justify="space-between"
          wrap="nowrap"
          data-testid="subscriptionGrantOptionsModal"
        >
          <WSFlexBox.CenterY
            direction="column"
            alignItems="flex-start"
            wrap="wrap"
          >
            <WSText mb="XS">{selectedSubscription.title}</WSText>
            <WSText.ParagraphSm>
              {selectedSubscription.description}
            </WSText.ParagraphSm>
          </WSFlexBox.CenterY>
          <WSIcon block name="caret-down" color="gray500" size="M" />
        </WSFlexBox.CenterY>
      </WSFlexBox>

      {formError && (
        <WSText.ParagraphSm my="M" color="garnet">
          {formError}
        </WSText.ParagraphSm>
      )}
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <WSButtons format="modal">
            <WSButton.Primary
              type="submit"
              loading={isSubmitting}
              name="save"
              className={styles.saveBtn}
            >
              Save
            </WSButton.Primary>
            <WSButton.Tertiary
              onClick={() => goToParentRoute(history)}
              name="cancel"
            >
              Cancel
            </WSButton.Tertiary>
          </WSButtons>
        )}
      </WSFormOld.Context>
      {createAuthorizationMeta.isError && (
        <WSText.ParagraphSm my="M" color="garnet">
          {createAuthorizationMeta.error?.response?.data["error"]}
        </WSText.ParagraphSm>
      )}
      {deleteAuthorizationMeta.isError && (
        <WSText.ParagraphSm my="M" color="garnet">
          {deleteAuthorizationMeta.error?.response?.data["error"]}
        </WSText.ParagraphSm>
      )}
    </WSFormOld>
  );
  return (
    <>
      <BrowserPageTitle title="Edit Team member" />
      <SubscriptionGrantOptionsModal
        onClose={() => {
          setIsModalOpened(false);
        }}
      />

      <WSScreen.MobileAndTablet>
        <WSModal
          fullScreen
          fullScreenOnMobile
          size="XS"
          onClose={() => goToParentRoute(history)}
          className={cn({
            [styles.hideParentModal]: isModalOpened
          })}
        >
          <WSPage
            title="Edit team member"
            breadcrumb={{
              label: "Team members",
              icon: "arrow-left",
              onClick: () => goToParentRoute(history)
            }}
          >
            {editTeamMemberForm}
          </WSPage>
        </WSModal>
      </WSScreen.MobileAndTablet>

      <WSScreen.Desktop>
        <WSModal
          size="XS"
          onClose={() => goToParentRoute(history)}
          className={cn({
            [styles.hideParentModal]: isModalOpened
          })}
          title="Edit team member"
        >
          {editTeamMemberForm}
        </WSModal>
      </WSScreen.Desktop>
    </>
  );
};
