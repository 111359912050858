import React, { useCallback, useMemo, useState } from "react";
import {
  WSElement,
  WSSelectOption,
  WSSelectSearch,
  WSSelectSearchProps,
  WSText
} from "@wingspanhq/fe-component-library";

import { getPayeeNames } from "../../../modules/Payees/selectors/getPayeeNames";
import { usePayeeRowsQuery } from "../../../query/search/payee/queries/usePayeeRowsQuery";
import { Nullable } from "primereact/ts-helpers";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { PAYEES_ROOT_PATH } from "../../../modules/Payees/utils";

export interface PayeeSearchFieldProps
  extends Omit<WSSelectSearchProps, "options"> {
  engagementId: string;
  label?: string;
  defaultSearchText?: string;
}

export const PayeeSearchField: React.FC<PayeeSearchFieldProps> = ({
  label,
  defaultSearchText,
  engagementId,
  ...otherProps
}) => {
  const history = useHistory();
  const [searchText, setSearchText] = useState(defaultSearchText);
  const queryPayeeRows = usePayeeRowsQuery({
    filter: {
      searchString: searchText || undefined
    }
  });

  const onReachMenuEnd = useCallback(() => {
    if (queryPayeeRows.canFetchMore) {
      queryPayeeRows.fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryPayeeRows.canFetchMore]);

  const payeeOptions = useMemo(() => {
    const payees = queryPayeeRows.data || [];
    return payees
      .map(payee => {
        if (!payee.user) {
          return null;
        }

        const payeeNames = getPayeeNames(payee);

        const name = payeeNames.getResolvedName();
        const secondaryName = payeeNames.getResolvedSecondaryName();

        return {
          value: payee.payeeId,
          label: name,
          labels: [secondaryName, payee.payerOwnedData.payeeExternalId].filter(
            Boolean
          ) as string[],
          avatar: {
            type: "text",
            text: name
          },
          delisted: payee.engagements?.find(
            pe => pe.engagementId === engagementId
          )
        };
      })
      .filter(Boolean) as WSSelectOption[];
  }, [queryPayeeRows.data, engagementId]);
  return (
    <WSElement>
      {label && (
        <WSText.ParagraphSm mb="S" color="gray500">
          {label}
        </WSText.ParagraphSm>
      )}
      <WSSelectSearch
        {...otherProps}
        placeholder="Select a contractor"
        options={payeeOptions}
        status={queryPayeeRows.isLoading ? "loading" : otherProps.status}
        isListLoading={
          queryPayeeRows.isFetching && !queryPayeeRows.isFetchingMore
        }
        externalSearchText={searchText}
        onExternalSearchChange={setSearchText}
        onReachMenuEnd={onReachMenuEnd}
        menuFooterAction={{
          label: "Create new contractor",
          icon: "plus",
          onClick() {
            history.push(PAYEES_ROOT_PATH + "?add_contractor=true");
          }
        }}
        menuFooterText="Don’t see a contractor?"
      />
    </WSElement>
  );
};

export interface FormPartialPayeeSearchFieldProps {
  name: string;
  engagementId: string;
}
export const FormPartialPayeeSearchField: React.FC<
  FormPartialPayeeSearchFieldProps
> = ({ name, engagementId }) => {
  const { errors, register, clearErrors, setValue, ...other } =
    useFormContext();
  const [selectedPayeeOption, setSelectedPayeeOption] =
    useState<Nullable<WSSelectOption>>(null);
  return (
    <>
      <input type="hidden" name={name} ref={register()} />
      <PayeeSearchField
        engagementId={engagementId}
        name={name}
        label="Contractor"
        value={selectedPayeeOption}
        onChange={option => {
          clearErrors(name);
          if (option) {
            setValue(name, option.value);
            setSelectedPayeeOption(option);
          } else {
            setValue(name, null);
            setSelectedPayeeOption(null);
          }
        }}
        status={errors?.[name] ? "error" : undefined}
        message={errors?.[name]?.message}
      />
    </>
  );
};
