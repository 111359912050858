import { validatorStringGeneric } from "./validatorStringGeneric";

const regExp = new RegExp(
  "^[ a-zA-Z0-9\u00C0-\u017F'\\#’\".,:;()?&*/@!$%^=+_~{}\\[\\]-]+$"
);

export const validatorStringTextNumbersSymbolsExt =
  validatorStringGeneric.matches(regExp, {
    excludeEmptyString: true,
    message:
      "May contain only characters: a-z, A-Z, 0-9, #, ', ., &, - and inner spaces"
  });
