import { get } from "lodash";
import { SortKey } from "../routes/RouteDashboard/RouteDashboard";
import { IPayoutResponse } from "../../../services/api/payments/payouts/types";
import { getPayoutTotalAmount } from "./getPayoutTotalAmount";

export const sortData =
  (config: Record<SortKey, "asc" | "desc">) =>
  (a: IPayoutResponse, b: IPayoutResponse) => {
    const sortByParam = Object.getOwnPropertyNames(
      config
    )[0] as keyof IPayoutResponse;

    if (!sortByParam) return 0;

    const direction = config[sortByParam] === "asc" ? 1 : -1;

    const valueA = get(a, sortByParam);
    const valueB = get(b, sortByParam);

    if (typeof valueA === "number" && typeof valueB === "number") {
      if (sortByParam === "currentPeriodBalance") {
        return (getPayoutTotalAmount(a) - getPayoutTotalAmount(b)) * direction;
      }

      return (valueA - valueB) * direction;
    }

    if (valueA instanceof Date && valueB instanceof Date) {
      return (valueA.getTime() - valueB.getTime()) * direction;
    }

    const isDateA = !isNaN(Date.parse(`${valueA}`));
    const isDateB = !isNaN(Date.parse(`${valueB}`));

    if (isDateA && isDateB) {
      const dateA = new Date(valueA as string).getTime();
      const dateB = new Date(valueB as string).getTime();
      return (dateA - dateB) * direction;
    }

    return (
      `${valueA}`.toLowerCase().localeCompare(`${valueB}`.toLowerCase()) *
      direction
    );
  };
