import React from "react";
import {
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useNotifications } from "../../../../query/notifications/queries/useNotifications";
import { useUserId } from "../../../../query/hooks/helpers";
import { useTrackClickNotification } from "../../utils/useTrackClickNotification";
import styles from "./styles.module.scss";
import { selectorW9ConsentNotifications } from "../../selectors/selectorW9ConsentNotifications";
import { getClientNameFromContext } from "../../components/Notifications";
import { useModalFormW9Consent } from "../../components/ModalW9Consent/useModalFormW9Consent";

type InformationToShare = {
  title: string;
  description: React.ReactNode;
  actionTitle: string;
  onClick: () => Promise<void>;
};

export const RouteW9Consent: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const trackClickNotification = useTrackClickNotification(userId);
  const queryNotifications = useNotifications();
  const isMobile = useIsMobile();

  const consentModal = useModalFormW9Consent();

  return (
    <WSQueries queries={{ queryNotifications }}>
      {({ queryNotificationsData }) => {
        const informations: InformationToShare[] = [];

        const notifications = selectorW9ConsentNotifications(
          queryNotificationsData
        );

        notifications.forEach(notification => {
          const clientName = getClientNameFromContext(notification.context);

          informations.push({
            title: `Share your W-9 information with ${clientName}`,
            description: (
              <>
                {clientName} requires that you consent to share your W-9
                information. Please consent to share your W-9 information in
                order to be paid.
              </>
            ),
            actionTitle: "Share W-9 information",
            onClick: async () => {
              trackClickNotification(notification);
              consentModal.open(
                {
                  clients: [
                    {
                      memberClientId:
                        notification.context?.memberClient?.memberClientId!,
                      name: getClientNameFromContext(notification.context!)
                    }
                  ]
                },
                {
                  title: `Share your W-9 information with ${getClientNameFromContext(
                    notification.context!
                  )}`
                }
              );
            }
          });
        });

        return (
          <WSPage
            breadcrumb={{
              label: "Back",
              onClick() {
                history.push("/member/dashboard");
              },
              icon: "arrow-left"
            }}
          >
            <WSFlexBox.CenterY justify="space-between" mb="XL">
              <WSText.Heading4 my="XL">
                Share tax information with clients ({informations.length})
              </WSText.Heading4>
              <WSButton.Primary
                disabled={!informations.length}
                onAsyncClick={async () => {
                  consentModal.open(
                    {
                      clients: notifications.map(prop => ({
                        memberClientId:
                          prop.context?.memberClient?.memberClientId!,
                        name: getClientNameFromContext(prop.context!)
                      }))
                    },
                    {
                      title: `Share your W-9 information with all clients`
                    }
                  );
                }}
              >
                Share W-9 information with all
              </WSButton.Primary>
            </WSFlexBox.CenterY>
            {informations.length === 0 ? (
              <WSText.ParagraphSm mt="3XL" align="center" color="gray400">
                No sharing requests to consent
              </WSText.ParagraphSm>
            ) : (
              informations.map(
                ({ title, onClick, description, actionTitle }) => {
                  return (
                    <WSPanel
                      key={title}
                      mb="M"
                      as="label"
                      className={styles.item}
                    >
                      <WSFlexBox.CenterY
                        justify="space-between"
                        wrap={isMobile ? "wrap" : "nowrap"}
                      >
                        <WSElement>
                          <WSText weight="medium" pb="M">
                            {title}
                          </WSText>
                          <WSText.ParagraphSm>{description}</WSText.ParagraphSm>
                        </WSElement>
                        <WSButton.Secondary
                          fullWidth={isMobile}
                          ml={isMobile ? "NONE" : "M"}
                          mt={isMobile ? "M" : "NONE"}
                          size="S"
                          onAsyncClick={onClick}
                        >
                          {actionTitle}
                        </WSButton.Secondary>
                      </WSFlexBox.CenterY>
                    </WSPanel>
                  );
                }
              )
            )}
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
