import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import plaidLogo from "../../../../assets/icons/plaid.png";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { useCreateAccount } from "../../../../query/users/mutations";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { usePaymentCardForm } from "../../../../shared/hooks/usePaymentCardForm";
import { selectorInstantPayoutDefaultFee } from "../../../../shared/selectors/selectorInstantPayoutDefaultFee";
import { useWSPlaidLink } from "../../../../utils/usePlaidLink";
import { FlowOption } from "../../components/FlowOption";
import { BannerProps } from "../../components/NotificationBanner";

export const RouteAddInstant: React.FC<RouteComponentProps> = () => {
  const history = useHistory<{ notificationBanner?: BannerProps }>();
  const [value, setValue] = useState<
    "debitCard" | "plaid" | "manually" | undefined
  >();

  const [createAccount, createAccountMeta] = useCreateAccount();
  const plaidLink = useWSPlaidLink({
    onSuccess: async publicToken => {
      const createResponse = await createAccount({
        publicToken
      });

      const createdAccounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      const hasRtpAccounts = createdAccounts.some(
        account => account.canBeUsedFor?.rtp
      );

      if (hasRtpAccounts) {
        history.push("/member/settings/payment-methods/add-instant-plaid");
      } else {
        const hasPayoutAccounts = createdAccounts.some(
          account => account.canBeUsedFor?.payouts
        );
        history.push({
          pathname: "/member/settings/payment-methods",
          state: {
            notificationBanner: {
              type: "error",
              message: "Selected account is not eligible for instant payouts",
              description: hasPayoutAccounts
                ? "Account was added as a standard payout method. To update or add another payout method, click edit/add payout methods below."
                : "To try again or add another payout method, click add payout method below."
            }
          }
        });
      }
    }
  });

  const queryFeatureFlags = useFeatureFlags();
  const paymentCardForm = usePaymentCardForm();
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      title={`Add instant payout method (${selectorInstantPayoutDefaultFee(
        queryPayoutSettings.data
      )}% fee)`}
      description={`Receive your funds in under an hour. A ${selectorInstantPayoutDefaultFee(
        queryPayoutSettings.data
      )}% fee will be applied when the
    transaction is made.`}
      onBack={() => {
        history.push("/member/settings/payment-methods/add");
      }}
    >
      <FlowOption
        icon={{
          name: "card",
          color: "gray100",
          secondaryColor: "blue500"
        }}
        mb="M"
        name="Connect a debit card"
        desctiption="Receive payments by connecting an eligible Visa or Mastercard debit card"
        onClick={() => {
          setValue("debitCard");
        }}
        isSelected={value === "debitCard"}
        tooltip={
          <>
            <WSText mb="XS" color="white">
              <b>Debit card</b>
            </WSText>
            <WSText color="white">
              Receive payments instantly by connecting a debit card (most U.S.
              bank issued Visa or Mastercard debit cards are compatible). Only
              one card can be added at a time for this method.
            </WSText>
          </>
        }
      />

      <FlowOption
        icon={plaidLogo}
        mb="M"
        name="Set up direct deposit with Plaid"
        desctiption="Instantly connect your bank accounts securely (Instant verification)"
        onClick={() => {
          setValue("plaid");
        }}
        isSelected={value === "plaid"}
        tooltip={
          <>
            <WSText mb="XS" color="white">
              <b>Direct deposit (Plaid)</b>
            </WSText>
            <WSText color="white">
              Instantly connect your financial account(s) securely with Plaid.
              Your information is protected by 256-bit encryption. Verification
              is instant.
            </WSText>
          </>
        }
      />

      <FlowOption
        icon={{
          name: "bank",
          color: "gray100",
          secondaryColor: "black"
        }}
        mb="M"
        name="Set up direct deposit manually"
        desctiption="Manually enter your routing and account numbers"
        onClick={() => {
          setValue("manually");
        }}
        isSelected={value === "manually"}
        tooltip={
          <>
            <WSText mb="XS" color="white">
              <b>Direct deposit (Manual)</b>
            </WSText>
            <WSText color="white">
              Manually enter your routing and account numbers from your
              preferred financial institution(s).
            </WSText>
          </>
        }
      />

      <WSButton
        mt="XL"
        fullWidth
        disabled={!value}
        loading={createAccountMeta.isLoading}
        onClick={() => {
          if (value === "debitCard") {
            if (queryFeatureFlags.data?.paymentCardAsPayoutMethod) {
              paymentCardForm.open({
                onSuccess: paymentCard => {
                  history.push("/member/settings/payment-methods");
                }
              });
            } else {
              history.push(
                "/member/settings/payment-methods/add-instant-debit-card"
              );
            }
          } else if (value === "plaid") {
            plaidLink.open();
          } else if (value === "manually") {
            history.push(
              "/member/settings/payment-methods/add-instant-manually"
            );
          }
        }}
      >
        Continue
      </WSButton>

      <WSText.ParagraphXs mt="XL" color="gray400">
        Instant payouts only work with U.S. based bank accounts or debit cards
        that participate in instant transfer services. Find out if your bank
        account or card is eligible by <b>adding</b> it to your Wingspan
        account. Once added, <b>click edit payout methods</b> to see if the
        account is available under instant payout methods.
      </WSText.ParagraphXs>
    </LayoutFullscreen>
  );
};
