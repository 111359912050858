import {
  toWSDate,
  WSElement,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import cn from "classnames";
import isEqual from "lodash/isEqual";
import React from "react";
import { useUserId } from "../../query/hooks/helpers";
import { useQueryVerifications } from "../../query/onboarding/queries/useQueryVerifications";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import {
  getIsDomesticMember,
  getMemberCountryName
} from "../../query/users/selectors";
import { selectorAddressDefaultValues } from "../../shared/components/FormPartialAddress";
import { openIntercomMessage } from "../../shared/utils/intercom";
import { DefaultSettingItem } from "../components/DefaultSettingItem/DefaultSettingItem.component";
import { SettingsLoader } from "../components/SettingsLoader/SettingsLoader";
import { PersonalAddressFormValues } from "../components/SettingsUpdateForms/PersonalAddressForm";
import { getAddressDisplayValue } from "../utils";
import {
  default as mainStyles,
  default as styles
} from "./SettingsMain.module.scss";

export interface PersonalInformationTabProps {}

export const PersonalInformationTab: React.FC<
  PersonalInformationTabProps
> = props => {
  const userId = useUserId();
  const { status: userProfileStatus, data: userData } = useUserProfile(userId);
  const { status: memberProfileStatus, data: memberData } =
    useMemberProfile(userId);
  const queryVerifications = useQueryVerifications();
  const isVerified = queryVerifications.data?.tax === "Verified";

  const isDomesticMember = memberData ? getIsDomesticMember(memberData) : false;
  const memberCountry = memberData
    ? getMemberCountryName(memberData)
    : undefined;

  const isLoading =
    userProfileStatus === "loading" ||
    memberProfileStatus === "loading" ||
    queryVerifications.status === "loading";
  const content = (
    <WSElement className={styles.contentPane} {...props}>
      {isLoading ? (
        <SettingsLoader />
      ) : (
        <>
          <DefaultSettingItem
            label="Legal first name"
            description="Enter your first name exactly as it is recorded with government tax authorities."
            value={userData?.profile.firstName || ""}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [styles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "First name is not added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="firstName"
            action={
              userData?.profile.firstName
                ? isVerified
                  ? "Request change"
                  : "Edit"
                : "Add"
            }
          />
          <DefaultSettingItem
            label="Legal last name"
            description="Enter your last name exactly as it is recorded with government tax authorities."
            value={userData?.profile.lastName || ""}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [styles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "Last name is not added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="lastName"
            action={
              userData?.profile.lastName
                ? isVerified
                  ? "Request change"
                  : "Edit"
                : "Add"
            }
          />
          <DefaultSettingItem
            label="Preferred name"
            description="A public-facing name that will appear to clients and contractors."
            value={userData?.profile.preferredName || ""}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [styles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "Preferred name is not added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="preferredName"
            action={userData?.profile.preferredName ? "Edit" : "Add"}
          />
          <DefaultSettingItem
            label="Date of Birth"
            value={
              userData?.profile.dob
                ? toWSDate(userData?.profile.dob, "input").toString()
                : ""
            }
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [styles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "Date of birth is not added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="dob"
            action={userData?.profile.dob ? "Request change" : "Add"}
          />

          {/*  title: "ID Verification",*/}
          {/*  description:*/}
          {/*    "Securely store your personal identification for streamlined verification with our partners.",*/}

          {isDomesticMember ? (
            <DefaultSettingItem
              label="Social Security Number"
              description=""
              value={memberData?.profile.ssnProvided}
              valueRenderer={() => {
                return (
                  <WSText.ParagraphSm
                    className={cn({
                      [styles.emptyStateValue]: !memberData?.profile.ssnProvided
                    })}
                  >
                    {memberData?.profile.ssnProvided
                      ? "***-**-****"
                      : "No SSN added yet"}
                  </WSText.ParagraphSm>
                );
              }}
              valueType="secret"
              field="ssn"
              readonly={memberData?.profile.ssnProvided && isVerified}
              onClick={
                memberData?.profile.ssnProvided && isVerified
                  ? () => {
                      openIntercomMessage(
                        `Hi there! I want to update my SSN.\n\n`
                      );
                    }
                  : undefined
              }
              action={
                memberData?.profile.ssnProvided
                  ? isVerified
                    ? "Request change"
                    : "Edit"
                  : "Add"
              }
            />
          ) : (
            <DefaultSettingItem
              label={`${memberCountry} Tax ID`}
              value=""
              valueRenderer={(value: string) => {
                return (
                  <WSText.ParagraphSm
                    className={cn({
                      [styles.emptyStateValue]: !value
                    })}
                  >
                    *********
                    {/* {memberData?.profile.foreignTaxIdProvided
                                ? "*********"
                                : `No ${memberCountry} Tax ID added yet`} */}
                  </WSText.ParagraphSm>
                );
              }}
              action={
                // memberData?.profile.foreignTaxIdProvided ? "Edit" : "Add"
                "Edit"
              }
              field="foreignTaxId"
            />
          )}
        </>
      )}

      <DefaultSettingItem
        formContainerClassName={styles.formContainerClassName}
        label="Personal Address"
        value={{
          businessAddress: selectorAddressDefaultValues(
            memberData?.profile.address
          ),
          isHomeAddressSame: isEqual(
            memberData?.profile.address,
            memberData?.profile.homeAddress
          ),
          homeAddress: selectorAddressDefaultValues(
            memberData?.profile.homeAddress
          )
        }}
        valueRenderer={({ homeAddress }: PersonalAddressFormValues) => {
          return (
            <>
              <WSText.ParagraphSm
                className={cn({
                  [mainStyles.emptyStateValue]: !homeAddress?.addressLine1
                })}
              >
                {homeAddress?.addressLine1
                  ? getAddressDisplayValue(homeAddress)
                  : "Personal address is not added yet"}
              </WSText.ParagraphSm>
            </>
          );
        }}
        field="personalAddress"
        action={memberData?.profile.address?.addressLine1 ? "Edit" : "Add"}
      />
    </WSElement>
  );
  return (
    <WSPage title="Personal Info">
      <WSGrid>
        <WSGrid.Item span={{ m: "10" }}>{content}</WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
