import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { useRouteMatch } from "react-router-dom";
import { useBulkCalculate1099BatchSummary } from "../../../../BulkImporter/query/bulkCalculation1099/queries";
import { EditLayout } from "../../../components/EditLayout";
import { WSQueries } from "../../../../../query/WSQuery";
import {
  WSButton,
  WSAvatar,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";

export const RouteSubmittingSuccess: React.FC<SetupRouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const queryBulkCalculate1099Summary =
    useBulkCalculate1099BatchSummary(bulkBatchId);

  return (
    <EditLayout title="1099-NEC Filing" noBack>
      <WSQueries
        queries={{
          queryBulkCalculate1099Summary
        }}
      >
        {({ queryBulkCalculate1099SummaryData }) => {
          const processed = queryBulkCalculate1099SummaryData.processedTaxForms;

          return (
            <>
              <WSFlexBox.Center mt="3XL">
                <WSAvatar.Icon
                  icon="check"
                  color="white"
                  colorBackground="green500"
                />
              </WSFlexBox.Center>

              <WSText.Heading4 mt="XL">
                You successfully submitted {processed} tax forms to the IRS for
                filing
              </WSText.Heading4>

              <WSText.ParagraphSm color="gray500" mt="XL">
                The 1099-NEC forms mentioned above is submitted to the IRS. By
                the IRS submission deadline of January 31st, 2024, each
                recipient will receive a copy of their 1099-NEC delivered by
                mail or electronically based on their preference. This allows
                sufficient time for the IRS to accept or reject the submissions
                and prevents the potential high volume of back and forth that
                can occur thereafter.
              </WSText.ParagraphSm>
              <WSButton.Primary fullWidth onClick={onNext} mt="XL">
                Go to 1099 Filing Dashboard
              </WSButton.Primary>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
