import { useHistory } from "react-router-dom";
import { PATH_SETTINGS_PAYMENTS_METHOD } from "..";
import { Layout } from "../../../../../shared/components/Layout";
import { ManageFundingMethod } from "../../../components/funding/ManageFundingMethod";

export const RouteManageFundingMethod: React.FC = () => {
  const history = useHistory();

  return (
    <Layout title="Manage payroll funding method">
      <ManageFundingMethod
        onNext={() => {
          history.push(PATH_SETTINGS_PAYMENTS_METHOD);
        }}
      />
    </Layout>
  );
};
