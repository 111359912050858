import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import { components } from "../../services/api/onboarding/types";

export const selectorAccountType = (
  monitoredEntity?: components["schemas"]["CustomerEntity"],
  member?: IMember
) => {
  if (monitoredEntity?.type) {
    return monitoredEntity.type;
  }

  if (member?.profile.company?.taxId) {
    return "Business";
  }

  return "Individual";
};
