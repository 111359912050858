import {
  toWSDateString,
  toWSMoneyString,
  WSAvatar,
  WSButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSMessageBox,
  WSPill,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  CalculationSettings1099Toggle,
  DeductionType,
  ICollaboratorSchema,
  IPayableSchema
} from "@wingspanhq/payments/dist/interfaces";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces/invoice";
import React, { useState } from "react";
import { QueryConfig } from "react-query";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Card } from "../../../ClientPayments/components/Card/Card";
import { PublicAttachmentDownloadLink } from "../../../components/PublicAttachmentDownloadLink/PublicAttachmentDownloadLink";
import { UrlIdKey } from "../../../constants/routing";
import { WSQueries } from "../../../query/WSQuery";
import { useWSQuery } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYABLE } from "../../../query/payments/keys";
import {
  useCollaboratorDeductions,
  useCollaboratorQuery,
  useInvoiceQuery,
  useMemberClientQuery,
  usePayrollSettings
} from "../../../query/payments/queries";
import {
  getCollaboratorCompany,
  getCollaboratorName,
  getCurrentYearForm1099Balance,
  getIsPayableCreator
} from "../../../query/payments/selectors";
import { useUserProfile } from "../../../query/users/queries";
import { getUserName } from "../../../query/users/selectors";
import { paymentsService } from "../../../services/payments";
import { getParentPath } from "../../../utils/goToParentRoute";
import {
  PaidDeductionsList,
  UnpaidDeductionsList
} from "../../components/Deductions/DeductionsList";
import { InvoiceRecentUpdate } from "../../components/InvoicesTable/InvoiceRecentUpdate";
import { buildInvoiceIcon } from "../../components/InvoicesTable/buildInvoiceIcon";
import { PayableActivity } from "../../components/PayableActivity";
import { PayableDetailsHeader } from "../../components/PayableDetailsHeader";
import { QuickbooksHistory } from "../../components/QuickbooksHistory/QuickbooksHistory";
import { QuickbooksWrapper } from "../../components/QuickbooksWrapper/QuickbooksWrapper";
import { Totals, TotalsSection } from "../../components/Totals/Totals";
import { calculateLineItemsTotal } from "../../utils";
import { getLineItemsTotals } from "../../utils/getLineItemsTotals";
import styles from "../InvoicesInvoiceDetails.module.scss";
import sortBy from "lodash/sortBy";

export const usePayableQuery = (
  payableId: string,
  config?: QueryConfig<IPayableSchema>
) =>
  useWSQuery<IPayableSchema>(
    [QUERY_PAYABLE, payableId],
    () => paymentsService.payable.get(payableId),
    {
      ...config
    }
  );

type Props = RouteComponentProps<
  { payableId: string; year: string },
  {},
  { backPath?: string }
>;

export const PayableDetails: React.FC<Props> = ({
  match,
  history,
  location
}) => {
  const backPath = location.state?.backPath || getParentPath(history);
  const year = parseInt(
    match.params[UrlIdKey.Year]
  ) as keyof ICollaboratorSchema["form1099Balances"];
  const is1099Flow = !isNaN(year);
  const onBack = () => {
    if (backPath) {
      history.push({
        pathname: backPath,
        search: location.search,
        state: {
          ...(location.state ?? {})
        }
      });
    }
  };

  return (
    <WSSidebar.Container onClose={onBack}>
      <Inner
        year={year}
        is1099Flow={is1099Flow}
        payableId={match.params.payableId}
        onBack={onBack}
      />
    </WSSidebar.Container>
  );
};

type InnerProps = {
  year?: keyof ICollaboratorSchema["form1099Balances"];
  is1099Flow?: boolean;
  payableId: string;
  onBack: () => void;
};

const Inner: React.FC<InnerProps> = ({
  payableId,
  onBack,
  year,
  is1099Flow
}) => {
  const history = useHistory();
  const [detailsVisible, setDetailsVisible] = useState(true);
  const payableQuery = usePayableQuery(payableId);
  const collaboratorQuery = useCollaboratorQuery(
    payableQuery.data?.collaboratorId as string,
    {
      retry: false,
      enabled: !!payableQuery.data?.collaboratorId
    }
  );
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const qPayrollSettings = usePayrollSettings(userId);

  const qDeductions = useCollaboratorDeductions(
    {
      memberId: payableQuery.data?.memberId as string,
      clientId: payableQuery.data?.clientId as string,
      type: DeductionType.PostPayment
    },
    {
      enabled: !!payableQuery.data
    }
  );

  const parentInvoiceId = (payableQuery.data as any)?.parentInvoiceId;
  const parentInvoiceQuery = useInvoiceQuery(parentInvoiceId, {
    enabled: !!parentInvoiceId
  });
  const parentInvoiceMemberClientQuery = useMemberClientQuery(
    parentInvoiceQuery.data?.memberClientId as any,
    {
      enabled: !!parentInvoiceQuery.data?.memberClientId
    }
  );
  return (
    <WSQueries
      queries={{
        payableQuery,
        collaboratorQuery,
        userQuery,
        qDeductions,
        qPayrollSettings
      }}
    >
      {({
        payableQuery: { data: payable },
        collaboratorQuery: { data: collaborator },
        userQuery: { data: user },
        qDeductions: { data: deductions },
        qPayrollSettings: { data: payrollSettings }
      }) => {
        const totalsSections: TotalsSection[] = [];

        const payableLineItems = sortBy(payable?.lineItems, lineItem =>
          lineItem.labels.bulkImporterItemNumber
            ? Number(lineItem.labels.bulkImporterItemNumber)
            : lineItem.createdAt
        );

        const totalsLineItemsSection = getLineItemsTotals(
          payableLineItems,
          is1099Flow
        );

        deductions = deductions.filter(deduction => {
          if (deduction.application?.length > 0) {
            return deduction.application.some(
              application => application.payableId === payable.payableId
            );
          }

          return false;
        });

        const currentForm1099Balance = getCurrentYearForm1099Balance(
          collaborator,
          year
        );
        if (
          is1099Flow &&
          payrollSettings.calculationSettings1099?.cardProcessingFees ===
            CalculationSettings1099Toggle.Include
        ) {
          totalsLineItemsSection.items.push([
            <>
              <WSFlexBox.CenterY wrap="nowrap">
                <WSText color="gray600">Processing fee</WSText>
                <WSPill theme="blue" ml="M" text="Included in 1099" />
              </WSFlexBox.CenterY>
            </>,
            toWSMoneyString(currentForm1099Balance?.paymentProcessingFees)
          ]);
        }

        if (payable.chargedFees?.lateFee) {
          totalsLineItemsSection.items.push([
            "Late fee",
            toWSMoneyString(payable.chargedFees?.lateFee.amount)
          ]);
        }

        totalsSections.push(totalsLineItemsSection);

        totalsSections.push({
          items: [
            {
              bold: true,
              left: "Total",
              right: toWSMoneyString(
                calculateLineItemsTotal(payable.lineItems) +
                  (is1099Flow &&
                  payrollSettings.calculationSettings1099
                    ?.cardProcessingFees ===
                    CalculationSettings1099Toggle.Include
                    ? currentForm1099Balance?.paymentProcessingFees ?? 0
                    : 0) +
                  (payable.chargedFees?.lateFee?.amount || 0)
              )
            },
            ...deductions.map(d => ({
              left: d.name,
              right: toWSMoneyString(-d.amount)
            }))
          ]
        });

        if (deductions.length) {
          totalsSections.push({
            items: [
              {
                bold: true,
                left: "Contractor gross income",
                right: toWSMoneyString(
                  calculateLineItemsTotal(payable.lineItems) +
                    (payable.chargedFees?.lateFee?.amount || 0) -
                    deductions.reduce(
                      (partialSum, a) => partialSum + a.amount,
                      0
                    )
                )
              }
            ]
          });
        }

        return (
          <WSSidebar.Layout
            header={`Invoice #${payable.invoiceNumber || " Pending"}`}
          >
            <WSElement className={styles.main}>
              <Card mb="XL">
                <WSText.Heading4 mb="M">
                  {getCollaboratorCompany(collaborator)}
                </WSText.Heading4>
                <PayableDetailsHeader payableId={payableId} />

                {payable.status === InvoiceStatus.Pending ? (
                  <WSMessageBox.Warning noBorder mt="XL">
                    <WSFlexBox.Center>
                      <WSFlexBox.CenterY mt="M">
                        <WSIcon
                          block
                          name="alert-circle"
                          size="S"
                          color="amber400"
                          mr="M"
                        />
                        <WSText weight="medium" inline>
                          Not yet eligible for payments
                        </WSText>
                      </WSFlexBox.CenterY>
                    </WSFlexBox.Center>
                    <WSText.ParagraphSm mt="XL">
                      Your invite was sent. We're still waiting for this person
                      to complete their onboarding requirements before they can
                      receive payments.
                    </WSText.ParagraphSm>
                    {/*<WSButton destructive
                        mt="XL"
                        fullWidth
                        onClick={() => {
                          sendCollaboratorInvite(collaborator.collaboratorId, {
                            onSuccess: () => {
                              openSnackbar({
                                message: `Invite re-sent to ${collaborator
                                  .member.user.profile?.firstName ||
                                  collaborator.member.user.email}`
                              });
                            }
                          });
                        }}
                      >
                        Resend invite
                      </WSButton>
                      */}
                  </WSMessageBox.Warning>
                ) : null}
              </Card>

              <Card mb="XL">
                <WSFlexBox wrap="nowrap" justify="space-between">
                  <WSButton.Link
                    onClick={() => {
                      setDetailsVisible(!detailsVisible);
                    }}
                    rightIcon={detailsVisible ? "caret-up" : "caret-down"}
                  >
                    {detailsVisible ? "Hide" : "Show"} invoice details
                  </WSButton.Link>
                  <WSText.Heading5>
                    {toWSMoneyString(
                      calculateLineItemsTotal(payable.lineItems) +
                        (is1099Flow &&
                        payrollSettings.calculationSettings1099
                          ?.cardProcessingFees ===
                          CalculationSettings1099Toggle.Include
                          ? currentForm1099Balance?.paymentProcessingFees ?? 0
                          : 0)
                    )}
                  </WSText.Heading5>
                </WSFlexBox>
                {detailsVisible && (
                  <WSElement mt="XL">
                    <WSElement mb="XL">
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        Bill to
                      </WSText.ParagraphSm>
                      <WSFlexBox.CenterY mb="XL">
                        <WSText inline>{getUserName(user)}</WSText>
                        {getIsPayableCreator(payable, userId) ? (
                          <WSPill theme="blue" ml="M" text="Created By" />
                        ) : null}
                      </WSFlexBox.CenterY>

                      <WSText.ParagraphSm mb="XS" color="gray500">
                        From
                      </WSText.ParagraphSm>

                      <WSFlexBox.CenterY mb="XL">
                        <WSText inline>
                          {getCollaboratorCompany(collaborator) ||
                            getCollaboratorName(collaborator)}
                        </WSText>
                        {getIsPayableCreator(payable, collaborator.memberId) ? (
                          <WSPill theme="blue" ml="M" text="Created By" />
                        ) : null}
                      </WSFlexBox.CenterY>
                    </WSElement>

                    <WSElement mb="XL">
                      <WSText.ParagraphSm mb="XS" color="gray500">
                        Due date{!!payable.client?.payDate && " (updated)"}
                      </WSText.ParagraphSm>
                      <WSText>
                        {toWSDateString(
                          payable.client?.payDate || payable.dueDate,
                          "monthDayYear"
                        )}
                      </WSText>
                    </WSElement>
                    {payable.metadata?.purchaseOrderNumber ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          PO Number
                        </WSText.ParagraphSm>
                        <WSText>{payable.metadata?.purchaseOrderNumber}</WSText>
                      </WSElement>
                    ) : null}
                    {payable.labels?.projectName ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Project Name
                        </WSText.ParagraphSm>
                        <WSText>{payable.labels?.projectName}</WSText>
                      </WSElement>
                    ) : null}
                    {payable.invoiceNotes ? (
                      <WSElement mb="XL">
                        <WSText.ParagraphSm mb="XS" color="gray500">
                          Notes
                        </WSText.ParagraphSm>
                        <WSText>{payable.invoiceNotes}</WSText>
                      </WSElement>
                    ) : null}

                    <WSElement>
                      <Totals sections={totalsSections} />
                    </WSElement>

                    {payable.lateFeeHandling &&
                      (!!payable.lateFeeHandling.lateFeePercentage ||
                        !!payable.lateFeeHandling.lateFeeAmount) && (
                        <WSElement mt="XL">
                          <WSText.ParagraphSm mb="XS" color="gray500">
                            Late fee
                          </WSText.ParagraphSm>
                          <WSText>
                            {payable.lateFeeHandling.lateFeePercentage
                              ? `${payable.lateFeeHandling.lateFeePercentage}%`
                              : toWSMoneyString(
                                  payable.lateFeeHandling.lateFeeAmount
                                )}{" "}
                            every {payable.lateFeeHandling.frequency.every}{" "}
                            {payable.lateFeeHandling.frequency.interval} overdue
                          </WSText>
                        </WSElement>
                      )}

                    {payable.attachments?.customAttachmentIds && (
                      <WSElement mt="XL">
                        {payable.attachments?.customAttachmentIds.map(id => (
                          <PublicAttachmentDownloadLink id={id} mb="M" />
                        ))}
                      </WSElement>
                    )}
                  </WSElement>
                )}

                {parentInvoiceId && (
                  <WSElement mt="2XL">
                    <WSText.Heading5 mb="XL">Parent invoice</WSText.Heading5>
                    <WSQueries
                      queries={{
                        parentInvoiceQuery,
                        parentInvoiceMemberClientQuery
                      }}
                    >
                      {({
                        parentInvoiceQuery: { data: parentInvoice },
                        parentInvoiceMemberClientQuery: {
                          data: parentInvoiceMemberClient
                        }
                      }) => (
                        <WSFlexBox
                          wrap="nowrap"
                          justify="space-between"
                          alignItems="center"
                          onClick={() => {
                            history.push("/member/invoices/" + parentInvoiceId);
                          }}
                        >
                          <WSFlexBox wrap="nowrap" alignItems="center">
                            <WSAvatar.Icon
                              {...buildInvoiceIcon(parentInvoice)}
                              mr="M"
                            />
                            <WSElement>
                              <WSText
                                mb={
                                  parentInvoiceMemberClient.name
                                    ? "XS"
                                    : undefined
                                }
                                color="gray600"
                              >
                                {parentInvoiceMemberClient.company ||
                                  parentInvoiceMemberClient.emailTo}
                              </WSText>
                              <WSText.ParagraphXs color="gray500">
                                {parentInvoiceMemberClient.name}
                              </WSText.ParagraphXs>
                            </WSElement>
                          </WSFlexBox>

                          <WSFlexBox direction="column" alignItems="flex-end">
                            <WSText mb="XS" formatMoney color="gray600">
                              {calculateLineItemsTotal(parentInvoice.lineItems)}
                            </WSText>
                            <InvoiceRecentUpdate invoice={parentInvoice} />
                          </WSFlexBox>
                        </WSFlexBox>
                      )}
                    </WSQueries>
                  </WSElement>
                )}
              </Card>

              {payable.status === InvoiceStatus.Paid ? (
                <PaidDeductionsList
                  payableId={payable.payableId}
                  collaboratorId={payable.collaboratorId}
                  clientId={payable.clientId}
                  memberId={payable.memberId}
                  collaboratorEmail={collaborator.member.user.email}
                  onRowClick={d => {
                    history.push(
                      `${history.location.pathname}/deduction/scheduled/${d.deductionId}?${history.location.search}`
                    );
                  }}
                />
              ) : (
                <UnpaidDeductionsList
                  collaboratorId={payable.collaboratorId}
                  clientId={payable.clientId}
                  memberId={payable.memberId}
                  collaboratorEmail={collaborator.member.user.email}
                  onRowClick={d => {
                    history.push(
                      `${history.location.pathname}/deduction/scheduled/${d.deductionId}?${history.location.search}`
                    );
                  }}
                />
              )}

              <Card>
                <WSText.Heading5 mb="XL">All activity</WSText.Heading5>
                <PayableActivity
                  payableId={payableId}
                  collaboratorId={collaborator.collaboratorId}
                />
              </Card>
              <QuickbooksWrapper>
                <QuickbooksHistory
                  Wrapper={Card}
                  mt="XL"
                  entityId={payable.payableId}
                />
              </QuickbooksWrapper>
            </WSElement>
          </WSSidebar.Layout>
        );
      }}
    </WSQueries>
  );
};
