import {
  IPayerTaxFormResponse,
  TinType
} from "@wingspanhq/payments/dist/interfaces";

export const selectorPayerTaxFormIsIndividual = (
  taxForm: IPayerTaxFormResponse
) => {
  return taxForm.data.w9Info?.tinType === TinType.Individual;
};
