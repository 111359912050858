import { Route, Switch } from "react-router-dom";
import { RouteDetails } from "./routes/RouteDetails";
import { RouteIndex } from "./routes/RouteIndex";
import { RouteRequestCorrection } from "./routes/RouteDetails/RouteRequestCorrection";
import { RouteTaxPreferences } from "./routes/RouteTaxPreferences";

export const TAX_DOCUMENTS_PATH = "/member/tax-documents";

export const TaxDocuments: React.FC<{}> = () => {
  return (
    <Switch>
      <Route
        path={TAX_DOCUMENTS_PATH + "/tax-preferences"}
        component={RouteTaxPreferences}
      />
      <Route
        path={TAX_DOCUMENTS_PATH + "/:taxFormId/request-correction"}
        component={RouteRequestCorrection}
      />
      <Route
        path={TAX_DOCUMENTS_PATH + "/:taxFormId"}
        component={RouteDetails}
      />
      <Route path={TAX_DOCUMENTS_PATH} exact component={RouteIndex} />
    </Switch>
  );
};
