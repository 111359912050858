import React, { useEffect } from "react";
import {
  toWSMoneyString,
  WSAlert,
  WSFlexBox,
  WSForm,
  WSIcon,
  WSInfoBox,
  WSInputTextarea,
  WSList,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { useBackgroundCheckFeatureUpgradeModal } from "../modals/useBackgroundCheckFeatureUpgradeModal";
import { useBackgroundChecksPackagesQuery } from "../../../../../query/backgroundChecks/queries/useBackgroundChecksPackagesQuery";
import { useFormContext } from "react-hook-form";
import { selectorBackgroundChecksActivePackages } from "../../../selectors/selectorBackgroundChecksActivePackages";

const ListItem: React.FC<{ label: string }> = ({ label }) => {
  return (
    <WSFlexBox.CenterY wrap="nowrap" alignItems="flex-start">
      <WSIcon name="shield-check" size="S" mr="M" />
      <WSText.ParagraphSm>{label}</WSText.ParagraphSm>
    </WSFlexBox.CenterY>
  );
};

export const CreateBackgroundChecksPartialForm: React.FC = () => {
  const { register } = useFormContext();
  const backgroundCheckFeatureUpgradeModal =
    useBackgroundCheckFeatureUpgradeModal();
  const queryBackgroundChecks = useBackgroundChecksPackagesQuery();

  const packages = queryBackgroundChecks.data || [];

  const backgroundChecksOptions = selectorBackgroundChecksActivePackages(
    packages
  ).map(backgroundCheckPackage => ({
    value: backgroundCheckPackage.backgroundCheckPackageId,
    label: `${backgroundCheckPackage.title} (${toWSMoneyString(
      backgroundCheckPackage.costPerCheck
    )} per check)`
  }));

  useEffect(() => {
    register("package");
    register("instructions");
  }, []);

  return (
    <WSList gap="XL">
      <WSAlert
        icon="info-circle"
        theme="info"
        title="About background check requirements"
        // action={{
        //   text: "Learn more",
        //   onClick: () => {
        //     backgroundCheckFeatureUpgradeModal.open({
        //       onSuccess: () => {
        //         alert("BACKGROUND_CHECK_TODO");
        //       }
        //     });
        //   }
        // }}
      >
        Contractor(s) assigned with this requirement (via Engagements) will be
        asked to complete the selected background check package via the
        background check portal. Keep track of the contractor’s requirement
        status on their contractor detail page.
      </WSAlert>

      <WSForm.Field
        name="package"
        label="Background check package"
        component={WSSelect}
        shimmer={queryBackgroundChecks.isLoading}
        componentProps={{
          required: true,
          options: backgroundChecksOptions
        }}
      />

      {queryBackgroundChecks.isFetched &&
        backgroundChecksOptions.length === 0 && (
          <WSAlert theme="error">
            No background check packages available. Please contact support to
            add a package.
          </WSAlert>
        )}

      <WSForm.Value name="package">
        {selectedPackageId => {
          const selectedPackage = queryBackgroundChecks.data?.find(
            itemPackage =>
              itemPackage.backgroundCheckPackageId === selectedPackageId
          );

          return selectedPackageId ? (
            <WSInfoBox>
              <WSList gap="L" py="S">
                <WSText.ParagraphSm color="gray500">
                  Included reports
                </WSText.ParagraphSm>
                {selectedPackage?.searches.map((search, index) => (
                  <ListItem label={search.title!} key={index} />
                ))}
              </WSList>
            </WSInfoBox>
          ) : null;
        }}
      </WSForm.Value>

      <WSForm.Field
        name="instructions"
        label="Instructions to contractor"
        placeholder="Include any instructions or guidelines for the contractor here (optional)."
        component={WSInputTextarea}
      />

      <WSText.ParagraphXs weight="book" color="gray400">
        Wingspan can auto-fill known info like names, emails, custom fields
        which you can configure in the next step.
      </WSText.ParagraphXs>
    </WSList>
  );
};
