import { IPayrollSettings } from "@wingspanhq/payments/dist/interfaces";
import {
  IAccount,
  IActivity,
  IClient
} from "@wingspanhq/users/dist/lib/interfaces";
import { selectorAccountFullName } from "../../../shared/selectors/selectorAccountFullName";
import { PayrollFundingMethod } from "../steps/PayrollFundingMethod";
import {
  OnboardingContext,
  OnboardingModulePayrollFundingMethod,
  OnboardingStep
} from "../types";

const getStatus = (
  payrollSettings?: IPayrollSettings,
  activity?: IActivity,
  client?: IClient,
  isManaged?: boolean
) => {
  const isActivityComplete =
    !!activity?.events?.onboardingFundingMethodCompletedAt;

  const hasFundingSource =
    !!payrollSettings?.fundingSource ||
    !!client?.profile.defaultPaymentMethod?.accountId ||
    !!client?.profile.defaultPaymentMethod?.paymentMethodId;

  if (isManaged) {
    if (!isActivityComplete) {
      return "None";
    }

    if (!hasFundingSource) {
      return "Pending";
    }
  } else {
    if (!hasFundingSource) {
      return "None";
    }
  }

  return "Complete";
};

export const getPayrollFundingMethodStepConfig = (
  _: OnboardingModulePayrollFundingMethod,
  __: OnboardingContext,
  payrollSettings?: IPayrollSettings,
  accounts?: IAccount[],
  activity?: IActivity,
  client?: IClient,
  isManaged?: boolean
): OnboardingStep => {
  const slug = "payroll-funding-method";

  const status = getStatus(payrollSettings, activity, client, isManaged);

  const account = accounts?.find(
    account =>
      account.accountId === payrollSettings?.fundingSource?.fundingSourceId
  );

  const accountText = account ? selectorAccountFullName(account) + " " : "";

  return {
    title: "Payroll funding method",
    slug,
    component: PayrollFundingMethod,

    status,
    result: {
      Complete: {
        title: "Your payroll funding method was added",
        description: `The payroll funding method ${accountText}was added. Click continue for the next step.`
      },
      Pending: {
        type: "card",
        title: "Awaiting payroll funding method",
        description:
          "A payroll funding method must to added to send scheduled payments.  Any incomplete setup tasks will appear on your “Get started” homepage. Incomplete tasks will delay your ability to send payments. Click continue for the next step."
      }
    }
  };
};
