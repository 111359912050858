import * as Yup from "yup";
import { validationSchemaAddress } from "../../../../../../shared/components/FormPartialAddress";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import {
  companyNameValidator,
  validatorFirstName,
  validatorLastName
} from "../../../../../../utils/validators";

export const getValidationSchema = (ssnProvided?: boolean) =>
  Yup.object().shape({
    ssn: Yup.string().when("identificationNumberType", {
      is: TinType.Individual,
      then: ssnProvided
        ? Yup.string()
            .trim()
            .test("test-ssn", "Must be 9 digits", ssn => {
              if (typeof ssn !== "string") return true;

              return ssn.length > 0 ? ssn.length === 9 : true;
            })
        : Yup.string().required("Required").length(9, "Must be 9 digits")
    }),
    ein: Yup.string().when("identificationNumberType", {
      is: TinType.Business,
      then: Yup.string().required("Required")
    }),
    firstName: Yup.string().when("identificationNumberType", {
      is: TinType.Individual,
      then: validatorFirstName.required("Required")
    }),
    lastName: Yup.string().when("identificationNumberType", {
      is: TinType.Individual,
      then: validatorLastName.required("Required")
    }),
    businessName: Yup.string().when("identificationNumberType", {
      is: TinType.Business,
      then: companyNameValidator.required("Required")
    }),
    address: validationSchemaAddress,
    exemptFatcaReportingCode: Yup.string().when(
      "exemptFatcaReportingCodeVisible",
      {
        is: true,
        then: Yup.string().required("Required")
      }
    ),
    exemptPayeeCode: Yup.string().when("exemptPayeeCodeVisible", {
      is: true,
      then: Yup.string().required("Required")
    })
  });
