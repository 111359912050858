import React from "react";
import { useWSSnackbar, WSTableCell } from "@wingspanhq/fe-component-library";
import { IMemberClientRequirementResponse } from "@wingspanhq/payments/dist/interfaces";
import { CellsConfig, RequirementTableCallback } from "../../../../types";
import { RequirementStatus } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { SIGNATURE_REQUIREMENT_EVENT } from "../utils";
import {
  useDownloadMemberDocument,
  useSignDocument
} from "../../../../../../query/files/mutations";
import {
  WSTableAction,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { selectorIsMemberClientRequirementReadyToSignAsPayee } from "../../../../selectors/selectorIsRequirementReadyToSignAsPayee";
import { selectorRequirementHumanReadableType } from "../../../../selectors/selectorRequirementHumanReadableType";

export const usePayerSignatureMemberClientRequirementResponseConfig = (
  callback: RequirementTableCallback
): CellsConfig<IMemberClientRequirementResponse> => {
  const { openSnackbar } = useWSSnackbar();
  const [signAsPayee, signAsPayeeMeta] = useSignDocument();
  const [downloadPDFAsPayee, downloadPDFAsPayeeMeta] =
    useDownloadMemberDocument();

  const handleRequirementDownload = async (
    requirement: IMemberClientRequirementResponse
  ) => {
    await downloadPDFAsPayee(
      {
        documentId: requirement.documentId!
      },
      {
        onSuccess() {
          callback(SIGNATURE_REQUIREMENT_EVENT.DownloadAsPayee, requirement);
        }
      }
    );
  };

  const handleRequirementSign = async (
    requirement: IMemberClientRequirementResponse
  ) => {
    await signAsPayee(
      { documentId: requirement.documentId },
      {
        onSuccess() {
          callback(SIGNATURE_REQUIREMENT_EVENT.SignAsPayee, requirement);
        }
      }
    );
  };

  return {
    nameCell: requirement => {
      return <WSTableCell text={requirement.document?.title || "--"} />;
    },
    typeCell: requirement => {
      return (
        <WSTableCell
          icon="pen"
          text={selectorRequirementHumanReadableType(
            requirement.requirementType
          )}
        />
      );
    },
    statusCell: requirement => {
      const isMemberSignedAndClientNotSigned =
        requirement.document?.events?.memberSignedAt &&
        !requirement.document?.events?.clientSignedAt;

      const isClientSignedAndMemberNotSigned =
        requirement.document?.events?.clientSignedAt &&
        !requirement.document?.events?.memberSignedAt;

      const secondaryText =
        requirement.status === RequirementStatus.Pending
          ? isMemberSignedAndClientNotSigned
            ? "Awaiting client signature"
            : isClientSignedAndMemberNotSigned
            ? "Your signature required"
            : ""
          : "";

      return (
        <WSTableCell
          // pill={statusToComponentMap[requirement.status as RequirementStatus]}
          pill={
            requirement.status === RequirementStatus.Complete
              ? {
                  theme: "success",
                  text: "Complete",
                  icon: true
                }
              : isMemberSignedAndClientNotSigned
              ? {
                  theme: "neutral",
                  text: "Awaiting client",
                  icon: "time"
                }
              : {
                  theme: "warning",
                  text: "Pending",
                  icon: true
                }
          }
          secondaryText={secondaryText}
        />
      );
    },
    rowMenuActions: requirement => {
      const actions: WSTableRowMenuAction<IMemberClientRequirementResponse>[] =
        [];

      if (requirement?.documentId) {
        actions.push({
          name: "DownloadDocument",
          onAsyncClick: async () => {
            await handleRequirementDownload(requirement);
          },
          label: "Download PDF",
          icon: "download"
        });
      }

      if (selectorIsMemberClientRequirementReadyToSignAsPayee(requirement)) {
        actions.push({
          name: "SignDocument",
          onAsyncClick: async () => {
            await handleRequirementSign(requirement);
          },
          label: "Sign",
          icon: "edit-line"
        });
      }

      // hide actions if only sign is available
      return requirement?.documentId ? actions : [];
    },
    rowActions: requirement => {
      const actions: WSTableAction[] = [];

      if (selectorIsMemberClientRequirementReadyToSignAsPayee(requirement)) {
        actions.push({
          name: "SignDocument",
          onAsyncClick: async () => {
            await handleRequirementSign(requirement);
          },
          text: "Start",
          kind: "Link",
          hideOn: []
        });
      }

      return actions;
    }
  };
};
