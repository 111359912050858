import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSInputMaskOld,
  WSMessageBox,
  WSRadioInputGroup,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { WSErrorMessage } from "../../../../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../../../../query/WSQuery";
import { useUserId } from "../../../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../../query/users/queries";
import { SetupRouteComponentProps } from "../../../../../../shared/components/FlowSetup";
import { FormPartialAddress } from "../../../../../../shared/components/FormPartialAddress";
import { selectorDefaultValues } from "../../../../../../shared/components/FormPartialAddress/selectorDefaultValues";
import {
  SELECT_OPTIONS_BUSINESS_TYPE,
  SELECT_OPTIONS_BUSINESS_TYPE_SSN
} from "../../../../../../shared/constants/member";
import { getSsnInputProps } from "../../../../../../shared/utils/getSsnInputProps";
import { LayoutW9 } from "../../../../components/LayoutW9";
import { selectorTaxClassificationLabel } from "../../../../../../shared/selectors/selectorTaxClassificationLabel";
import { ValidationFailedInstructions } from "./ValidationFailedInstructions";
import { getValidationSchema } from "./getValidationSchema";
import { selectorNo1099 } from "./selectorNo1099";
import { FormData } from "./types";
import { useMutationSubmit } from "./useMutationSubmit";

export const RouteTaxpayerInformation: React.FC<SetupRouteComponentProps> = ({
  params,
  onNext,
  onBack
}) => {
  const location = useLocation<{ verificationFailed: boolean }>();
  const [isOptionalInfoVisible, setIsOptionalInfoVisible] = useState(false);

  const isVerificationFailed = !!location.state?.verificationFailed;
  const isEdit = !!params?.edit;

  const userId = useUserId();
  const queryUserProfile = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);

  const [submit, submitMeta] = useMutationSubmit({
    onSuccess: onNext,
    onError: () => {
      queryMember.remove();
    }
  });

  return (
    <LayoutW9
      onBack={onBack}
      title="Taxpayer Information"
      description="This is the company that received payments"
    >
      <WSQueries queries={{ queryMember, queryUserProfile }}>
        {({
          queryMember: { data: member },
          queryUserProfile: { data: user }
        }) => (
          <WSFormOld<FormData>
            defaultValues={{
              identificationNumberType:
                member.profile.formW9Options?.tinPreference,
              ein: member.profile.company?.taxId,
              ssn: "",
              firstName: member.user?.profile.firstName,
              lastName: member.user?.profile.lastName,
              businessName: member.profile.company?.legalBusinessName,
              taxClassification:
                member.profile.company?.structure ||
                member.profile.formW9Options?.tinPreference === TinType.Business
                  ? SELECT_OPTIONS_BUSINESS_TYPE[0].value
                  : SELECT_OPTIONS_BUSINESS_TYPE_SSN[0].value,
              address: selectorDefaultValues(member.profile.address),
              exemptFatcaReportingCodeVisible:
                !!member.profile.formW9Options?.exemptFatcaCode,
              exemptFatcaReportingCode:
                member.profile.formW9Options?.exemptFatcaCode,
              exemptPayeeCode: member.profile.formW9Options?.exemptPayeeCode,
              exemptPayeeCodeVisible:
                !!member.profile.formW9Options?.exemptPayeeCode
            }}
            validationSchema={getValidationSchema(member.profile.ssnProvided)}
            onSubmit={values => {
              submit(values);
            }}
          >
            {isEdit || isVerificationFailed ? (
              <ValidationFailedInstructions
                error={submitMeta.error}
                member={member}
                user={user}
              />
            ) : (
              submitMeta.error && (
                <ValidationFailedInstructions
                  error={submitMeta.error}
                  member={member}
                  user={user}
                />
              )
            )}

            <WSElement mb="2XL">
              <WSFormOld.Field
                mb="XL"
                name="identificationNumberType"
                label="Identification number"
                component={WSRadioInputGroup}
                componentProps={{
                  optionHasBorder: true,
                  options: [
                    {
                      label: "Social Security Number (SSN)",
                      description:
                        "Ideal for individuals, sole proprietors without a formal business structure. This is your personal 9-digit number.",
                      value: TinType.Individual
                    },
                    {
                      label: "Employer Identification Number (EIN)",
                      description:
                        "Choose this if you’re representing a legally registered business. If you’ve set up an LLC, Corporation, or Partnership, you likely have an EIN.",
                      value: TinType.Business
                    }
                  ]
                }}
              />

              <WSFormOld.Value name="identificationNumberType">
                {identificationNumberType => (
                  <>
                    <WSFormOld.Field
                      name="ssn"
                      label="SSN"
                      component={WSInputMaskOld}
                      componentProps={getSsnInputProps({
                        showPlaceholder: true,
                        isValueProvided: member.profile.ssnProvided
                      })}
                      hidden={identificationNumberType !== TinType.Individual}
                    />
                    <WSFormOld.Field
                      name="ein"
                      label="Employer Identification Number"
                      component={WSInputMaskOld}
                      componentProps={{
                        mask: "99-9999999",
                        placeholder: "Employer Identification Number",
                        unmask: true,
                        fsExclude: true
                      }}
                      hidden={identificationNumberType !== TinType.Business}
                    />
                  </>
                )}
              </WSFormOld.Value>
            </WSElement>

            <WSFormOld.Value name="identificationNumberType">
              {identificationNumberType => (
                <>
                  <WSElement
                    mb="2XL"
                    hidden={identificationNumberType === TinType.Business}
                  >
                    <WSFormOld.Label>What is your legal name?</WSFormOld.Label>
                    <WSFormOld.Field
                      mb="M"
                      name="firstName"
                      component={WSTextInput}
                      componentProps={{ placeholder: "First name" }}
                    />
                    <WSFormOld.Field
                      mb="XS"
                      name="lastName"
                      component={WSTextInput}
                      componentProps={{ placeholder: "Last name" }}
                    />
                    <WSText.ParagraphSm color="gray400">
                      Your name should match exactly with what's listed on your
                      federal tax return to avoid discrepancies with the IRS.
                    </WSText.ParagraphSm>
                  </WSElement>

                  <WSFormOld.Field
                    mb="2XL"
                    hidden={identificationNumberType === TinType.Individual}
                    name="businessName"
                    component={WSTextInput}
                    componentProps={{ placeholder: "Warren Workshop Co." }}
                    label="Business name"
                    description="Your name should match exactly with what's listed on your federal tax return to avoid discrepancies with the IRS."
                  />
                </>
              )}
            </WSFormOld.Value>

            <WSElement mb="2XL">
              <WSFormOld.Value name="identificationNumberType">
                {identificationNumberType => (
                  <WSFormOld.Field
                    key={identificationNumberType}
                    name="taxClassification"
                    label="Federal tax classification"
                    component={WSSelectOld}
                    componentProps={{
                      options:
                        identificationNumberType === TinType.Business
                          ? SELECT_OPTIONS_BUSINESS_TYPE
                          : SELECT_OPTIONS_BUSINESS_TYPE_SSN
                    }}
                  />
                )}
              </WSFormOld.Value>

              <WSFormOld.Value name="taxClassification">
                {taxClassification =>
                  selectorNo1099(taxClassification) && (
                    <WSMessageBox.Info noBorder mt="M">
                      <WSText.ParagraphSm weight="medium" mb="S">
                        No 1099-NEC form will be issued
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mb="M" color="gray500">
                        As a {selectorTaxClassificationLabel(taxClassification)}
                        , you're not eligible for a 1099 according to IRS
                        guidelines. Instead, Wingspan will provide a summary of
                        your 2023 payments to assist you during tax season. If
                        this doesn't align with your business structure, update
                        your details promptly.
                      </WSText.ParagraphSm>
                    </WSMessageBox.Info>
                  )
                }
              </WSFormOld.Value>
            </WSElement>

            <WSElement mb="2XL">
              <FormPartialAddress name="address" mb="M" />
              <WSText.ParagraphSm color="gray400">
                Your form 1099 will be mailed to this address.
              </WSText.ParagraphSm>
            </WSElement>

            <WSElement mb="2XL">
              <WSFlexBox.CenterY
                wrap="nowrap"
                justify="space-between"
                onClick={() => {
                  setIsOptionalInfoVisible(prev => !prev);
                }}
              >
                <WSText weight="medium">
                  Add exemptions or optional information
                </WSText>
                <WSIcon
                  block
                  name={isOptionalInfoVisible ? "chevron-up" : "chevron-down"}
                  size="XS"
                />
              </WSFlexBox.CenterY>

              <WSElement hidden={!isOptionalInfoVisible} mt="XL">
                <WSElement mb="XL">
                  <WSFormOld.Field
                    name="exemptPayeeCodeVisible"
                    component={WSCheckboxToggle}
                    componentProps={{
                      label: "I have an exempt payee code"
                    }}
                  />
                  <WSFormOld.Value name="exemptPayeeCodeVisible">
                    {exemptPayeeCodeVisible => (
                      <WSFormOld.Field
                        mt="M"
                        hidden={!exemptPayeeCodeVisible}
                        name="exemptPayeeCode"
                        component={WSTextInput}
                        componentProps={{
                          placeholder: "Exempt payee code (if any)"
                        }}
                      />
                    )}
                  </WSFormOld.Value>
                </WSElement>

                <WSElement mb="XL">
                  <WSFormOld.Field
                    name="exemptFatcaReportingCodeVisible"
                    component={WSCheckboxToggle}
                    componentProps={{
                      label: "I have an exempt FATCA reporting code"
                    }}
                  />
                  <WSFormOld.Value name="exemptFatcaReportingCodeVisible">
                    {exemptFatcaReportingCodeVisible => (
                      <WSFormOld.Field
                        mt="M"
                        hidden={!exemptFatcaReportingCodeVisible}
                        name="exemptFatcaReportingCode"
                        component={WSTextInput}
                        componentProps={{
                          placeholder: "Exemption from FATCA reporting code"
                        }}
                      />
                    )}
                  </WSFormOld.Value>
                </WSElement>
              </WSElement>
            </WSElement>

            <WSErrorMessage
              mb="2XL"
              contextKey="NEC1099Onboarding"
              error={submitMeta.error}
            />

            {isEdit ? (
              <WSButtons>
                <WSFormOld.SubmitButton
                  fullWidth
                  loading={submitMeta.isLoading}
                >
                  Save changes
                </WSFormOld.SubmitButton>
                <WSButton.Secondary onClick={onBack}>Cancel</WSButton.Secondary>
              </WSButtons>
            ) : (
              <WSFormOld.SubmitButton fullWidth loading={submitMeta.isLoading}>
                Continue
              </WSFormOld.SubmitButton>
            )}
          </WSFormOld>
        )}
      </WSQueries>
    </LayoutW9>
  );
};
