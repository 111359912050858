import {
  WSAlert,
  WSAlertProps,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { ErrorContextKey } from "../../services/platform";
import { IS_DEV_ENV } from "../../shared/constants/environment";
import { FSButton, platformErrors } from "../../utils/platformErrors";

const ignoredErrorSubtypes = ["OtpRequired"];

type TransformParams = {
  error?: any;
  contextKey: keyof typeof ErrorContextKey;
  forceShowApiErrors?: boolean;
};

export type WSErrorMessageProps = TransformParams &
  WSElementProps &
  WSAlertProps;

export const transformErrorMessage = ({
  error,
  contextKey,
  forceShowApiErrors
}: TransformParams) => {
  let message: React.ReactNode = "";

  if (typeof error === "string") {
    message = error;
  } else if (typeof error === "object") {
    if (error.response) {
      // Api service error. Here error mapping file will be applied

      // Check global errors mapping for corresponding error message
      const type: string =
        error.response.data.type || error.response.data.errorType || "Other";
      const subtype: string =
        error.response.data.subtype ||
        error.response.data.errorSubType ||
        "Other";
      const errorMessage = error.response.data.error;
      if (platformErrors[contextKey]) {
        if (errorMessage && platformErrors[contextKey]?.errorRegexs) {
          // Maybe it's in the regex map
          for (let er of platformErrors[contextKey]
            ?.errorRegexs as Array<any>) {
            if (new RegExp(er.pattern).test(errorMessage)) {
              message = er.message;
              break;
            }
          }
        } else if (platformErrors[contextKey]?.wsErrors?.[type]) {
          // If not, lets check the wsErrors map
          if (
            React.isValidElement(platformErrors[contextKey]?.wsErrors[type])
          ) {
            message = platformErrors[contextKey]?.wsErrors[type] as string;
          } else {
            message = (platformErrors[contextKey]?.wsErrors[type] as any)[
              subtype
            ];
          }
        }
      }

      // If there is no matching error in global mapping, just put api repsonse
      if (!message) {
        if (IS_DEV_ENV) {
          message = `"${error?.response?.data.error}"`;
        } else if (forceShowApiErrors) {
          message = error?.response?.data.error;
        } else {
          console.error(`WS API ERROR "${error?.response?.data.error}"`);
        }
      }
    } else {
      // Api service error without data
      // It means something is wrong on network level
      // Examples: No internet connection, CORS error

      if (error.message === "Network Error") {
        message =
          "We're having trouble communicating with our servers. Please check your internet connection and try again!";
      }

      if (!message) {
        if (IS_DEV_ENV) {
          message = `"${error.message}"`;
        } else if (forceShowApiErrors) {
          message = error.message;
        } else {
          console.error(`WS ERROR "${error.message}"`);
        }
      }
    }
  }

  if (!message) {
    message = (
      <>
        Sorry! Something went wrong here. Please try again or{" "}
        <FSButton>contact our support</FSButton> if this issue continues.
      </>
    );
  }

  return message;
};

export const WSErrorMessage: React.FC<WSErrorMessageProps> = ({
  error,
  contextKey,
  forceShowApiErrors,
  ...elementProps
}) => {
  if (error) {
    if (
      error.response?.data?.subtype &&
      ignoredErrorSubtypes.includes(error.response.data.subtype)
    ) {
      return null;
    }

    const message = transformErrorMessage({
      error,
      contextKey,
      forceShowApiErrors
    });

    return (
      <WSAlert
        {...elementProps}
        theme="error"
        icon="info-circle"
        data-testid="ws-form-api-error"
      >
        <>{message}</>
      </WSAlert>
    );
  } else {
    return null;
  }
};
