// @ts-ignore
import lucene from "lucene-query-string-builder";

import { InfiniteQueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../../utils/serviceHelper";
import { useWSInfiniteQuery, useWSQuery } from "../../../helpers";
import { getTaxFormRows, ITaxFormSearchRow } from "../../../../services/search";
import {
  QUERY_SEARCH_TAX_FORM_ROWS,
  QUERY_SEARCH_TAX_FORM_ROWS_COUNT
} from "../keys";
import { CURRENT_YEAR } from "../../../../modules/1099NECFiling/constants/currentYear";
import {
  TaxFormEventType,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import times from "lodash/times";
import flatten from "lodash/flatten";
import { WSTableColumnSortDirection } from "@wingspanhq/fe-component-library";
import { TaxFormCorrectionStatus } from "@wingspanhq/payments/dist/interfaces";

export enum TaxFormRowSearchSortFields {
  Amount = "data.totalAmount"
}

export type TaxFormRowSearchSort = {
  [K in keyof typeof TaxFormRowSearchSortFields]: WSTableColumnSortDirection;
};

export enum ConfirmationStatusSearchText {
  NotEligble = "status:Excluded",
  InviteQueued = "",
  InviteSent = "eventHistory.eventType:InvitationSent",
  SignedUp = "payee.user.status:Active",
  Shared = `eventHistory.eventType:RecipientSharedW9Info`
}

export enum W9SharedOption {
  Shared = "shared",
  NotShared = "notShared"
}

export enum GroupedTINOption {
  GroupedFormsOnly = "groupedFormsOnly",
  NoGroupedForms = "noGroupedForms"
}

export interface TaxFormRowSearchQueryFilters {
  year?: string;
  search: string;
  organizationId?: string;
  confirmationStatus?: TaxFormEventType | TaxFormStatus | string;
  statuses?: string;
  w9Shared?: W9SharedOption;
  amountMin?: number;
  amountMax?: number;
  groupedTin?: GroupedTINOption;
  taxFormStatus?: TaxFormStatus;
  correctionStatus?: TaxFormCorrectionStatus;
}

export const TAX_ROW_FORM_SEARCH_QUERY_DEFAULT_FILTERS: TaxFormRowSearchQueryFilters =
  {
    year: `${CURRENT_YEAR}`,
    search: "",
    organizationId: undefined,
    confirmationStatus: undefined,
    statuses: undefined,
    w9Shared: undefined,
    amountMin: undefined,
    amountMax: undefined,
    groupedTin: undefined,
    taxFormStatus: undefined,
    correctionStatus: undefined
  };

export const CONFIRMATION_STATUS_OPTIONS: Array<{
  //format should be `key:value`
  value: string;
  label: string;
}> = [
  {
    value: ConfirmationStatusSearchText.NotEligble,
    label: `Not eligible for invite`
  },
  {
    value: ConfirmationStatusSearchText.InviteQueued,
    label: "Invite queued"
  },
  {
    value: ConfirmationStatusSearchText.InviteSent,
    label: "Invite sent"
  },
  {
    value: ConfirmationStatusSearchText.SignedUp,
    label: "Signed up"
  },
  {
    value: ConfirmationStatusSearchText.Shared,
    label: "W-9 Shared"
  }
].filter(item => item.value);

export const COMBINED_CONFIRMATION_STATUS_OPTIONS: Array<{
  //format should be `key:value`
  value: ConfirmationStatusSearchText[];
  label: string;
}> = [
  {
    value: [ConfirmationStatusSearchText.Shared],
    label: `W-9 shared`
  },
  {
    value: [
      ConfirmationStatusSearchText.InviteQueued,
      ConfirmationStatusSearchText.InviteSent,
      ConfirmationStatusSearchText.SignedUp,
      ConfirmationStatusSearchText.NotEligble
    ],
    label: "W-9 not shared"
  }
];

export const W9_SHARED_OPTIONS: Array<{
  //format should be `key:value`
  value: W9SharedOption;
  label: string;
}> = [
  {
    value: W9SharedOption.Shared,
    label: "W-9 shared"
  },
  {
    value: W9SharedOption.NotShared,
    label: "W-9 not shared"
  }
];

export const GROUPED_TIN_OPTIONS: Array<{
  value: GroupedTINOption;
  label: string;
}> = [
  {
    value: GroupedTINOption.GroupedFormsOnly,
    label: "Grouped Forms Only"
  },
  {
    value: GroupedTINOption.NoGroupedForms,
    label: "No Grouped Forms"
  }
];

export const TAX_FORM_STATUS_OPTIONS: Array<{
  value: TaxFormStatus;
  label: string;
}> = [
  {
    value: TaxFormStatus.Pending,
    label: "Pending"
  },
  {
    value: TaxFormStatus.NeedsAction,
    label: "Needs Action"
  },
  {
    value: TaxFormStatus.ReadyToSubmitToIrs,
    label: "Ready to Submit"
  },
  {
    value: TaxFormStatus.SubmittedToIrs,
    label: "Submitted"
  },
  {
    value: TaxFormStatus.AcceptedByIrs,
    label: "Accepted"
  },
  {
    value: TaxFormStatus.RejectedByIrs,
    label: "Rejected"
  },
  // should be substituted by latest correction status
  // {
  //   value: TaxFormStatus.PendingCorrection,
  //   label: "Pending Correction"
  // },
  {
    value: TaxFormStatus.Excluded,
    label: "Excluded"
  }
];

export const TAX_FORM_CORRECTIONS_STATUS_OPTIONS: Array<{
  value: TaxFormCorrectionStatus;
  label: string;
}> = [
  {
    value: TaxFormCorrectionStatus.Pending,
    label: "Correction requested"
  },
  {
    value: TaxFormCorrectionStatus.Submitted,
    label: "Correction submitted"
  },
  {
    value: TaxFormCorrectionStatus.Rejected,
    label: "Correction not accepted"
  }
];

// https://www.npmjs.com/package/lucene-query-string-builder <-- syntax here
const buildLuceneQueryString = lucene.builder(function (
  data: TaxFormRowSearchQueryFilters
) {
  const _ = lucene;
  const luceneParts: any[] = [];

  // year
  if (data.year) luceneParts.push(_.field("yearString", data.year));

  // org Ids
  if (data.organizationId)
    luceneParts.push(_.field("clientId", data.organizationId));

  // confirmation status
  if (data.confirmationStatus) {
    const [key, value] = data.confirmationStatus.split(":");
    if (key && value) luceneParts.push(_.field(key, value));
  }

  // status
  if (data.statuses) luceneParts.push(data.statuses);

  // w9 shared
  if (data.w9Shared) {
    if (data.w9Shared === W9SharedOption.Shared) {
      luceneParts.push(
        _.field("eventHistory.eventType", "RecipientSharedW9Info")
      );
    } else {
      // for some dumb reason have to pass an empty string here
      luceneParts.push(
        _.not("", _.field("eventHistory.eventType", "RecipientSharedW9Info"))
      );
    }
  }

  // amounts
  if (data.amountMin || data.amountMax) {
    const min = data.amountMin || 0;
    const max = data.amountMax || 100000000000; // absurdly high number https://www.youtube.com/watch?v=BRAkobf-tVI

    luceneParts.push(_.field("data.totalAmount", _.range(`${min}`, `${max}`)));
  }

  // grouped tin
  if (data.groupedTin) {
    if (data.groupedTin === GroupedTINOption.GroupedFormsOnly) {
      luceneParts.push(_.field("isGroupedTinString", "true"));
    } else if (data.groupedTin === GroupedTINOption.NoGroupedForms) {
      luceneParts.push(_.field("isGroupedTinString", "false"));
    }
  }

  // tax form status
  if (data.taxFormStatus) {
    luceneParts.push(_.field("status", data.taxFormStatus));
  }

  // tax form status
  if (data.correctionStatus) {
    luceneParts.push(_.field("lastCorrectionStatus", data.correctionStatus));
  }

  // // text search should always go at the end because of the wildcard
  // if (data.search) luceneParts.push(`${_.term(`${data.search}`)}*`);

  return _.and(...luceneParts);
});

export const getLuceneQueryStringFromFilters = (
  filters: TaxFormRowSearchQueryFilters
) => {
  const { search: searchDirty, ...filtersParts } = filters;
  const search = searchDirty.trim();

  var luceneQueryString = buildLuceneQueryString({
    ...filtersParts,
    search
  })
    .replaceAll("\\", "")
    .replaceAll('"', "");

  return luceneQueryString;
};

export const usePaginatedTaxFormRowSearch = (
  params: {
    filters: TaxFormRowSearchQueryFilters;
    expandOrganizationsForUserId?: string;
  },
  config?: InfiniteQueryConfig<ITaxFormSearchRow[], WSServiceError>
) => {
  const { filters, expandOrganizationsForUserId, ...filter } = params;
  const luceneQueryString = getLuceneQueryStringFromFilters(filters);

  // TODO: WIN-???? Remove when this is fixed
  const { search: searchDirty } = filters;
  const searchString = searchDirty.trim();

  return useWSInfiniteQuery<any, WSServiceError>(
    [QUERY_SEARCH_TAX_FORM_ROWS, params],
    async (queryKey, queryParams, pageNumber = 1) => {
      const response = await getTaxFormRows(
        {
          luceneQueryString,
          searchString,
          ...(expandOrganizationsForUserId
            ? { expandOrganizationsForUserId }
            : {})
        },
        {
          filter,
          // disable sort when there is a search string
          ...(!searchString ? { sort: { "data.totalAmount": "desc" } } : {}),
          page: { size: 10, number: pageNumber }
        }
      );
      return response.data;
    },
    {
      getFetchMore: (lastPage, allPages) => {
        if (lastPage.length < 10) {
          return undefined;
        }

        return allPages.length + 1;
      },
      ...config
    }
  );
};

export const usePaginatedTaxFormRowSearchCount = (
  params: {
    filters: TaxFormRowSearchQueryFilters;
    expandOrganizationsForUserId?: string;
  },
  config?: InfiniteQueryConfig<ITaxFormSearchRow[], WSServiceError>
) => {
  const { filters, expandOrganizationsForUserId, ...filter } = params;
  const luceneQueryString = getLuceneQueryStringFromFilters(filters);

  // TODO: WIN-???? Remove when this is fixed
  const { search: searchDirty } = filters;
  const searchString = searchDirty.trim();

  return useWSQuery(
    [QUERY_SEARCH_TAX_FORM_ROWS_COUNT, params],
    async () => {
      const response = await getTaxFormRows(
        {
          luceneQueryString,
          searchString,
          ...(expandOrganizationsForUserId
            ? { expandOrganizationsForUserId }
            : {})
        },
        {
          filter,
          // disable sort when there is a search string
          ...(!searchString ? { sort: { "data.totalAmount": "desc" } } : {}),
          page: { size: 1, number: 1 }
        }
      );
      return response.summary.listSize;
    },
    {}
  );
};

export const downloadAllTaxFormRowSearchResults = async (params: {
  filters: TaxFormRowSearchQueryFilters;
  expandOrganizationsForUserId?: string;
}) => {
  const { filters, expandOrganizationsForUserId, ...filter } = params;
  const luceneQueryString = getLuceneQueryStringFromFilters(filters);

  // TODO: WIN-???? Remove when this is fixed
  const { search: searchDirty } = filters;
  const searchString = searchDirty.trim();

  const response = await getTaxFormRows(
    {
      luceneQueryString,
      searchString,
      ...(expandOrganizationsForUserId ? { expandOrganizationsForUserId } : {})
    },
    {
      page: { size: 1, number: 1 }
    }
  );
  const allCount = response.summary.listSize || 0;

  if (allCount === 0) {
    return [];
  }

  const pageSize = 100;
  const pagesCount = Math.ceil(allCount / pageSize);

  // searchQueryFilterConfig

  const actions = times(pagesCount).map(
    (_, i) => () =>
      getTaxFormRows(
        {
          luceneQueryString,
          ...(expandOrganizationsForUserId
            ? { expandOrganizationsForUserId }
            : {})
        },
        {
          page: { size: pageSize, number: i + 1 }
        }
      )
  );

  const allPages = await concurrentActions(actions, {
    concurrentLimit: 5
  });

  return flatten(allPages.map(page => page.data));
};
