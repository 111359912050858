import {
  useWSModal,
  WSButton,
  WSButtons,
  WSFormOld,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import {
  ICustomField,
  IPayeeResponse
} from "@wingspanhq/payments/dist/interfaces";
import { prepareDynamicFormFields } from "../../../../Invoices/components/AddCollaboratorForm/AddCollaboratorForm";
import { getChangedData } from "../../../../utils/getChangedData";
import {
  getDefaultValuesForAdditionalData,
  getYupValidationSchema,
  prepareCollaboratorLabelsRequest
} from "../../../../utils/jsonFormSchema";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";

type Props = {
  payee: IPayeeResponse;
  allCustomFields: ICustomField[];
  isEnterpriseUser: boolean;
  onClose: (result: boolean) => void;
};

const UpdateCustomFieldsModal: React.FC<Props> = ({
  onClose,
  payee,
  isEnterpriseUser,
  allCustomFields
}) => {
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const additionalDataValidationSchema =
    getYupValidationSchema(allCustomFields);

  return (
    <WSFormOld
      defaultValues={{
        externalId: payee.payerOwnedData.payeeExternalId,
        ...getDefaultValuesForAdditionalData(
          allCustomFields,
          payee.payerOwnedData.customFields
        )
      }}
      onSubmit={({ externalId, ...customFields }) => {
        updatePayee(
          {
            payeeId: payee.payeeId,
            payerOwnedData: getChangedData(payee.payerOwnedData, {
              ...(isEnterpriseUser
                ? {
                    payeeExternalId: externalId
                  }
                : {}),
              customFields: {
                ...prepareCollaboratorLabelsRequest(
                  allCustomFields,
                  customFields
                )
              }
            })
          },
          {
            onSuccess: () => {
              onClose(true);
            }
          }
        );
      }}
      validationSchema={Yup.object().shape({
        ...additionalDataValidationSchema
      })}
    >
      {isEnterpriseUser && (
        <>
          <WSFormOld.Field
            name="externalId"
            label="External Contractor ID (optional)"
            component={WSTextInput}
            mb="XL"
          />
        </>
      )}
      {prepareDynamicFormFields(allCustomFields)}
      <WSErrorMessage
        mb="XL"
        error={updatePayeeMeta.error}
        contextKey="EditContact"
      />
      <WSButtons format="modal">
        <WSButton loading={updatePayeeMeta.isLoading}>Update</WSButton>
        <WSButton.Tertiary
          onClick={() => {
            onClose(false);
          }}
        >
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSFormOld>
  );
};

export const useUpdateCustomFieldsModal = () =>
  useWSModal(UpdateCustomFieldsModal, {
    title: "Update custom fields",
    size: "S"
  });
