import { useHistory } from "react-router-dom";
import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSAlert,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import {
  IEngagementResponse,
  IRequirementDefinitionResponse
} from "@wingspanhq/payments/dist/interfaces";
import { useDeleteRequirementFromEngagement } from "../../../query/engagements/mutations/useDeleteRequirementFromEngagement";
import { FieldView } from "../../../shared/components/FieldView/FieldView";
import { getEngagementLabelByType } from "../../Payers/utils/engagement";
import {
  selectorRequirementHumanReadableType,
  selectorRequirementIcon
} from "../../Requirements/selectors/selectorRequirementHumanReadableType";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";

type RemoveRequirementFromEngagementModalProps = {
  engagement: IEngagementResponse;
  requirementDefinition: IRequirementDefinitionResponse;
  onClose: () => void;
};

const DataCell: React.FC<{ label: string; value: React.ReactNode }> = ({
  label,
  value
}) => {
  return (
    <WSGrid.Item key={label} span={{ xs: "12", s: "6", l: "6" }}>
      <FieldView label={label} value={value} />
    </WSGrid.Item>
  );
};

const RemoveRequirementFromEngagementModal: React.FC<
  RemoveRequirementFromEngagementModalProps
> = ({ requirementDefinition, engagement, onClose }) => {
  const history = useHistory();
  const { openSnackbar } = useWSSnackbar();

  const [removedRequirement, removedRequirementMeta] =
    useDeleteRequirementFromEngagement();

  return (
    <WSList gap="XL">
      <WSText.ParagraphSm weight="book" color="gray500">
        Are you sure you want to remove the requirement from this engagement?
      </WSText.ParagraphSm>

      <WSPanel>
        <WSGrid gutter="XL">
          <DataCell
            label="Requirement name"
            value={requirementDefinition.name}
          />
          <DataCell
            label="Requirement type"
            value={
              <WSFlexBox.CenterY gap="XS">
                <WSIcon
                  name={selectorRequirementIcon(
                    requirementDefinition.requirementType
                  )}
                />
                <WSText.ParagraphSm color="gray700">
                  {selectorRequirementHumanReadableType(
                    requirementDefinition.requirementType
                  )}
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>
            }
          />
          <DataCell label="Engagement name" value={engagement.name} />
          <DataCell
            label="Engagement type"
            value={getEngagementLabelByType(engagement.type, {
              descriptive: true
            })}
          />
        </WSGrid>
      </WSPanel>

      <WSAlert title="Remove from engagement" theme="warning">
        Once a requirement is removed from an engagement, existing and future
        contractors assigned to that engagement will no longer need to complete
        it to be eligible to receive payment.
      </WSAlert>

      <WSErrorMessage
        contextKey="UpdateEngagement"
        error={removedRequirementMeta.error}
      />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Remove requirement",
            kind: "Primary",
            destructive: true,
            onAsyncClick: async () => {
              await removedRequirement(
                {
                  engagementId: engagement.engagementId,
                  requirementDefinitionId:
                    requirementDefinition.requirementDefinitionId
                },
                {
                  onSuccess() {
                    openSnackbar({
                      message: "Requirement removed from engagement",
                      type: "success"
                    });
                    onClose();
                  },
                  onError() {
                    openSnackbar({
                      message: "Failed to remove requirement from engagement",
                      type: "error"
                    });
                  }
                }
              );
            },
            type: "button"
          },
          {
            label: "Cancel",
            kind: "Tertiary",
            type: "button",
            onClick() {
              onClose();
            }
          }
        ]}
      />
    </WSList>
  );
};

export function useRemoveRequirementFromEngagementModal() {
  return useWSModal(RemoveRequirementFromEngagementModal, {
    title: "Remove requirement from engagement",
    size: "M"
  });
}
