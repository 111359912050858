import {
  toWSDateString,
  toWSMoney,
  WSButton,
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSMarginProps,
  WSPanel,
  WSPill,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import {
  IPayoutResponse,
  PayoutTypeEnum
} from "../../../../services/api/payments/payouts/types";
import { BadgePayoutPreference } from "../../../PaymentMethods/components/BadgePayoutPreference";
import { getPayoutsStatusPillProps } from "../../utils/getPayoutsStatusPillProps";
import { selectorIsPayoutItemVisible } from "../../utils/getPayoutTotalAmount";
import { getPayoutNet } from "../../utils/utils";
import { useWherePayoutModal } from "../WherePayoutModal/useWherePayoutModal";
import styles from "./styles.module.scss";

export interface PayoutInfoPanelProps extends WSMarginProps {
  payout: IPayoutResponse;
}

export const PayoutInfoPanel: React.FC<PayoutInfoPanelProps> = ({
  payout,
  ...elementProps
}) => {
  const wherePayoutModal = useWherePayoutModal();

  const invoicesLength: number = useMemo(
    () =>
      payout?.payoutItems
        ? payout?.payoutItems.filter(selectorIsPayoutItemVisible).length
        : 0,
    [payout?.payoutItems]
  );

  const payoutTotalAmount = useMemo(() => getPayoutNet(payout), [payout]);

  return (
    <WSPanel p="NONE" className={styles.panel} {...elementProps}>
      <WSPanel noBorder>
        <WSSectionToolbar mb="L" title="Payout total" />
        <WSGrid gutter={{ xs: "XL", m: "3XL" }}>
          <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
            <WSFlexBox direction="column">
              <WSText.Heading1 weight="book" mb="S" color="gray500">
                {toWSMoney(payoutTotalAmount).formattedValue}
              </WSText.Heading1>
            </WSFlexBox>
          </WSGrid.Item>

          <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
            <WSFlexBox direction="column">
              <WSPill mb="XS" {...getPayoutsStatusPillProps(payout)} />
              <WSText.ParagraphXs weight="book" color="gray500">
                {/* {`${accountLength} account${
                  accountLength === 1 ? "" : "s"
                } • `}  //todo - status */}
                {toWSDateString(payout.createdAt, "monthDayYear")}
              </WSText.ParagraphXs>
            </WSFlexBox>
          </WSGrid.Item>

          <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
            <WSFlexBox direction="column">
              <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                Initiated date
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="book" color="gray700">
                {toWSDateString(payout.createdAt, "monthDayYear")}
              </WSText.ParagraphSm>
            </WSFlexBox>
          </WSGrid.Item>

          {payout?.payoutType && (
            <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
              <WSFlexBox direction="column">
                <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                  Type
                </WSText.ParagraphSm>
                <BadgePayoutPreference
                  payoutPreference={PayoutTypeEnum[payout?.payoutType]}
                />
              </WSFlexBox>
            </WSGrid.Item>
          )}

          <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
            <WSFlexBox direction="column">
              <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                Invoices
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="book" color="gray700">
                {invoicesLength}
              </WSText.ParagraphSm>
            </WSFlexBox>
          </WSGrid.Item>

          <WSGrid.Item span={{ xs: "12", s: "6", l: "6" }}>
            <WSFlexBox direction="column">
              <WSText.ParagraphSm weight="book" mb="S" color="gray500">
                Description
              </WSText.ParagraphSm>
              <WSText.ParagraphSm weight="book" color="gray700">
                Wingspan Payout
              </WSText.ParagraphSm>
            </WSFlexBox>
          </WSGrid.Item>
        </WSGrid>
      </WSPanel>
      <WSDivider />

      <WSPanel noBorder p="S">
        <WSFlexBox.CenterY justify="flex-end">
          <WSButton.Link
            size="M"
            name="viewRoutingNumber"
            onClick={async () => {
              await wherePayoutModal.open();
            }}
          >
            Where is my payout?
          </WSButton.Link>
        </WSFlexBox.CenterY>
      </WSPanel>
    </WSPanel>
  );
};
