import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorPayeeTaxFormPayerName } from "./selectorPayeeTaxFormPayerName";

export const selectorPayeeTaxFormPayerCompanyName = (
  taxForm: IPayeeTaxFormResponse
): string => {
  return (
    taxForm.payer?.profile?.company?.legalBusinessName ||
    taxForm.payer?.profile?.company?.name ||
    selectorPayeeTaxFormPayerName(taxForm)
  );
};
