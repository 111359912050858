import React from "react";
import { WSFormOld, WSRadioInputGroup } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import { TaxFilingCode } from "@wingspanhq/users/dist/lib/interfaces";
import * as Yup from "yup";

import { FormActions } from "./FormActions";

import styles from "./SettingsUpdateForms.module.scss";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { queryCache } from "react-query";
import { QUERY_BOOKKEEPING_PROFIT_AND_LOSS } from "../../../query/bookkeeping/keys";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { WSQuery } from "../../../query/WSQuery";

export const SINGLE_OPTIONS = [
  {
    value: "40000",
    label: "0-40k"
  },
  {
    value: "80000",
    label: "40k-80k"
  },
  {
    value: "160000",
    label: "80k-160k"
  },
  {
    value: "200000",
    label: "160k-200k"
  },
  {
    value: "500000",
    label: "200k-500k"
  },
  {
    value: "510000",
    label: "500k+"
  }
];

export const MARRIED_OPTIONS = [
  {
    value: "80000",
    label: "0-80k"
  },
  {
    value: "160000",
    label: "80k-160k"
  },
  {
    value: "320000",
    label: "160k-320k"
  },
  {
    value: "400000",
    label: "320k-400k"
  },
  {
    value: "600000",
    label: "400k-600k"
  },
  {
    value: "610000",
    label: "600k+"
  }
];

export const HEAD_OF_HOUSEHOLD_OPTIONS = [
  {
    value: "50000",
    label: "0-50k"
  },
  {
    value: "80000",
    label: "50k-80k"
  },
  {
    value: "160000",
    label: "80k-160k"
  },
  {
    value: "200000",
    label: "160k-200k"
  },
  {
    value: "500000",
    label: "200k-500k"
  },
  {
    value: "510000",
    label: "500k+"
  }
];

interface TaxIncomeEstimateForYearFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const TaxIncomeEstimateForYearForm: React.FC<
  TaxIncomeEstimateForYearFormProps
> = ({ field, onSubmit, defaultValues, onCancel }) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const memberQuery = useMemberProfile(userId);
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        taxInfo: {
          [field]: Number(data[field])
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
        queryCache.invalidateQueries(QUERY_BOOKKEEPING_PROFIT_AND_LOSS);
      }
    });
  };
  return (
    <WSQuery query={memberQuery}>
      {({ data: member }) => {
        return (
          <WSFormOld
            className={styles.form}
            onSubmit={onFormSubmit}
            defaultValues={defaultValues}
            validationSchema={Yup.object().shape({
              [field]: Yup.string().required("Income estimate is required")
            })}
          >
            <WSFormOld.Field name={field}>
              <WSFormOld.Input
                render={props => (
                  <WSRadioInputGroup
                    {...props}
                    options={
                      member?.profile.taxInfo?.filingCode
                        ? [
                            TaxFilingCode.Single,
                            TaxFilingCode.MarriedFilingSeparately,
                            TaxFilingCode.QualifiedWidower
                          ].includes(member?.profile.taxInfo?.filingCode)
                          ? SINGLE_OPTIONS
                          : [TaxFilingCode.Married].includes(
                              member?.profile.taxInfo?.filingCode
                            )
                          ? MARRIED_OPTIONS
                          : HEAD_OF_HOUSEHOLD_OPTIONS
                        : []
                    }
                  />
                )}
              />
              <WSFormOld.Error />
            </WSFormOld.Field>
            <WSFormOld.Context>
              {({ formState: { isSubmitting } }) => (
                <FormActions onCancel={onCancel} loading={isSubmitting} />
              )}
            </WSFormOld.Context>
          </WSFormOld>
        );
      }}
    </WSQuery>
  );
};
