import {
  toCamelCase,
  WSButton,
  WSButtons,
  WSElement,
  WSFormOld,
  WSPanel,
  WSRadioInputGroup,
  WSSidebar,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import {
  CustomFieldResourceType,
  ICustomField,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import * as Yup from "yup";
import { WSQueries } from "../../../../query/WSQuery";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useCustomField } from "../../../../query/customFields/queries/useCustomField";
import { useUpdateCustomField } from "../../../../query/customFields/mutations/useUpdateCustomField";
import { useDeleteCustomField } from "../../../../query/customFields/mutations/useDeleteCustomField";
import { getParentPath } from "../../../../utils/goToParentRoute";
import {
  CustomFieldResourceTypeRouteSlug,
  ResourceTypeSlugToResourceType
} from "../../utils";

export const RouteCustomFieldDetails: React.FC<
  RouteComponentProps<{
    customFieldId: string;
    resourceTypeSlug: CustomFieldResourceTypeRouteSlug;
  }>
> = ({ match }) => {
  const history = useHistory();
  const location = useLocation<{ backPath: string }>();
  const backPath = location.state?.backPath || getParentPath(history);
  const queryCustomField = useCustomField(match.params.customFieldId);

  const onBack = () => {
    if (backPath) {
      history.push({
        pathname: backPath,
        search: location.search,
        state: {
          ...(location.state ?? {})
        }
      });
    }
  };

  return (
    <WSSidebar.Container onClose={onBack}>
      <WSQueries queries={{ queryCustomField }}>
        {({ queryCustomFieldData: customField }) => (
          <WSSidebar.Layout header={customField.name}>
            <WSPanel noBorder>
              <Inner
                data={customField}
                resourceType={
                  ResourceTypeSlugToResourceType[match.params.resourceTypeSlug]
                }
                onBack={onBack}
              />
            </WSPanel>
          </WSSidebar.Layout>
        )}
      </WSQueries>
    </WSSidebar.Container>
  );
};

export const Inner: React.FC<{
  data: ICustomField;
  resourceType: CustomFieldResourceType;
  onBack: () => void;
}> = ({ data, resourceType, onBack }) => {
  const queryCustomFieldList = useCustomFieldsAll({
    resourceType: [resourceType]
  });

  const [updateCustomField, updateCustomFieldMeta] = useUpdateCustomField();
  const [deleteCustomField, deleteCustomFieldMeta] = useDeleteCustomField();

  return (
    <>
      <WSFormOld
        defaultValues={{
          name: data.name,
          fieldType: data.type,
          required: data.required ? "yes" : "no"
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .notOneOf(
              queryCustomFieldList?.data
                ?.map(a => a.name)
                .filter(name => name !== data.name) || [],
              "A custom field with this name already exists"
            )
            .required("Name is required"),
          fieldType: Yup.string(),
          required: Yup.string()
        })}
        onSubmit={async formData => {
          await updateCustomField(
            {
              id: data.id,
              data: {
                name: formData.name,
                required: formData.required === "yes",
                type: formData.fieldType,
                key: toCamelCase(formData.name)
              }
            },
            {
              onSuccess() {
                onBack();
              }
            }
          );
        }}
      >
        <WSFormOld.Field
          mb="XL"
          label="Field name"
          name="name"
          component={WSTextInput}
        />

        <WSFormOld.Field
          mb="2XL"
          label=" "
          name="required"
          component={WSRadioInputGroup}
          componentProps={{
            options: [
              {
                label: "Required",
                value: "yes"
              },
              {
                label: "Optional",
                value: "no"
              }
            ]
          }}
        />

        <WSElement className={styles.disabled}>
          <WSFormOld.Label mb="M">Field type</WSFormOld.Label>
          <WSRadioInputGroup
            value={data.type}
            options={[
              {
                label: "Text field",
                value: WSDataTypes.String
              },
              {
                label: "Checkbox",
                value: WSDataTypes.Boolean
              }
            ]}
            name="disabled"
          />
          <WSText.ParagraphSm mt="M" color="gray500">
            Field type cannot be edited for existing fields.
          </WSText.ParagraphSm>
        </WSElement>

        <WSErrorMessage
          mt="XL"
          error={updateCustomFieldMeta.error || deleteCustomFieldMeta.error}
          contextKey="CreateCustomField"
        />

        <WSButtons mt="3XL" forceFullWidth>
          <WSFormOld.SubmitButton data-testid="submit">
            Update field
          </WSFormOld.SubmitButton>
          <WSButton
            destructive
            type="button"
            onAsyncClick={async () => {
              await deleteCustomField(data.id);
              onBack();
            }}
          >
            Delete field
          </WSButton>
        </WSButtons>
      </WSFormOld>
    </>
  );
};
