import {
  detachLayoutProps,
  WSButton,
  WSButtons,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSForm,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IInvoicingConfigUpdateRequest,
  InvoiceSettingKeys
} from "@wingspanhq/payments/dist/interfaces";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { IPredefinedLineItem } from "../../../../Invoices/components/EditInvoicingConfigModals/PredefinedLineItemsModal";
import { useQueryInvoicingConfigForPayer } from "../../../../query/invoicingConfig/queries/useQueryInvoicingConfigForPayer";
import { useAdditionalGuidelinesModal } from "../../../../Invoices/components/EditInvoicingConfigModals/useAdditionalGuidelinesModal";
import { usePredefinedLineItemsModal } from "../../../../Invoices/components/EditInvoicingConfigModals/usePredefinedLineItemsModal";
import { useDefaultDueDateModal } from "../../../../Invoices/components/EditInvoicingConfigModals/useDefaultDueDateModal";
import { useUpdateInvoicingConfig } from "../../../../query/invoicingConfig/mutations/useUpdateInvoicingConfig";
import { WSQueries } from "../../../../query/WSQuery";
import { getInvoicingConfigFormDefaultValues } from "../../../../Invoices/screens/payables/invoicingConfigUtils";
import { InvoicingConfigSection } from "../../../../Invoices/components/InvoicingConfigSection/InvoicingConfigSection";
import { InvoicingConfigField } from "../../../../Invoices/components/InvoicingConfigField/InvoicingConfigField";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { ErrorContextKey } from "../../../../services/platform";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

export const dueInDaysValueMap: any = {
  0: "On created date",
  7: "7 days after created date",
  15: "15 days after created date",
  30: "30 days after created date"
};

export type FormInvoicingConfigRef =
  // Define any custom props/methods to expose via ref
  HTMLFormElement | null;

export interface FormInvoicingConfigProps extends WSElementProps {
  formContainerClassName?: string;
  setLoading?: (loading: boolean) => void;
  hideSaveButton?: boolean;
  onSaveSuccess?: () => void;
  onCancel?: () => void;
}

function _FormInvoicingConfig(
  props: FormInvoicingConfigProps,
  ref: React.Ref<FormInvoicingConfigRef>
) {
  const {
    formContainerClassName,
    setLoading,
    hideSaveButton,
    onSaveSuccess,
    onCancel,
    ...otherProps
  } = props;
  const [additionalGuidelinesValue, setAdditionalGuidelinesValue] =
    useState<string>("");
  const [predefinedLineItems, setPredefinedLineItems] = useState<
    IPredefinedLineItem[]
  >([]);
  const [dueInDaysValue, setDueInDaysValue] = useState<string>("0"); // string number
  const queryInvoicingConfigForPayer = useQueryInvoicingConfigForPayer();

  const additionalGuidelinesModal = useAdditionalGuidelinesModal();
  const predefinedLineItemsModal = usePredefinedLineItemsModal();
  const defaultDueDateModal = useDefaultDueDateModal();

  const invoicingConfigFormRef = React.useRef<HTMLFormElement>(null);

  const [updateInvoicingConfig, updateInvoicingConfigMeta] =
    useUpdateInvoicingConfig();
  useEffect(() => {
    const invoicingConfigForPayer = queryInvoicingConfigForPayer?.data;
    if (invoicingConfigForPayer) {
      setAdditionalGuidelinesValue(
        invoicingConfigForPayer?.[InvoiceSettingKeys.additionalGuidelines]
          ?.enabled &&
          invoicingConfigForPayer?.[InvoiceSettingKeys.additionalGuidelines]
            ?.value
          ? invoicingConfigForPayer[InvoiceSettingKeys.additionalGuidelines]
              ?.value
          : ""
      );
      try {
        const predefinedLineItems =
          invoicingConfigForPayer?.[
            InvoiceSettingKeys.allowOnlyPredefinedLineItems
          ]?.value ?? [];
        setPredefinedLineItems(predefinedLineItems);
      } catch (e) {
        setPredefinedLineItems([]);
      }

      setDueInDaysValue(
        invoicingConfigForPayer?.[InvoiceSettingKeys.dueInDays]?.value
          ? invoicingConfigForPayer?.[InvoiceSettingKeys.dueInDays]?.value + ""
          : ""
      );
    }
  }, [queryInvoicingConfigForPayer?.data]);

  useEffect(() => {
    if (setLoading) {
      setLoading(updateInvoicingConfigMeta.isLoading);
    }
  }, [updateInvoicingConfigMeta.isLoading, setLoading]);

  useImperativeHandle(
    ref,
    () => {
      return {
        requestSubmit: () => {
          invoicingConfigFormRef.current?.requestSubmit();
        }
      } as FormInvoicingConfigRef;
    },
    []
  );

  const onSubmit = async (data: any) => {
    // iterate over data object and create a request object of type IInvoicingConfigUpdateRequest
    // send the request object to the updateInvoicingConfig mutation

    const payload = Object.keys(data).reduce((acc: any, key) => {
      if (key === InvoiceSettingKeys.additionalGuidelines) {
        acc[key] = {
          enabled: data[key],
          value: additionalGuidelinesValue
        };
      } else if (key === InvoiceSettingKeys.allowOnlyPredefinedLineItems) {
        acc[key] = {
          enabled: data[key],
          value: predefinedLineItems
        };
      } else if (key === InvoiceSettingKeys.dueInDays) {
        acc[key] = {
          enabled: data[key],
          value: Number(dueInDaysValue)
        };
      } else {
        acc[key] = {
          enabled: data[key]
        };
      }
      return acc;
    }, {} as IInvoicingConfigUpdateRequest);

    await updateInvoicingConfig(payload, {
      onSuccess: () => {
        onSaveSuccess?.();
      }
    });
  };

  const { layoutProps } = detachLayoutProps(otherProps);

  return (
    <WSQueries queries={{ queryInvoicingConfigForPayer }}>
      {({ queryInvoicingConfigForPayerData: invoicingConfigForPayer }) => {
        return (
          <WSForm
            ref={invoicingConfigFormRef}
            defaultValues={getInvoicingConfigFormDefaultValues(
              invoicingConfigForPayer
            )}
            onSubmit={onSubmit}
          >
            <WSElement className={formContainerClassName} {...layoutProps}>
              <InvoicingConfigSection title="Basic info" mb="XL">
                <InvoicingConfigField
                  mb="XS"
                  label="Client (selection)"
                  badgeText="Required"
                  enabled
                />
                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.additionalGuidelines}
                  label="Additional guidelines to show contractors"
                  helpText="Turn this on to show a custom message or guidelines (under the client name) to all contractors when they create an invoice."
                  valuePreviewText={additionalGuidelinesValue}
                  {...(invoicingConfigForPayer
                    ? invoicingConfigForPayer?.[
                        InvoiceSettingKeys.additionalGuidelines
                      ]
                    : {})}
                >
                  <WSForm.Values
                    names={[InvoiceSettingKeys.additionalGuidelines]}
                  >
                    {values => {
                      if (values[InvoiceSettingKeys.additionalGuidelines]) {
                        return (
                          <WSFlexBox alignItems="center" ml="2XL" wrap="nowrap">
                            {additionalGuidelinesValue ? (
                              <WSText.ParagraphXs color="gray400">
                                {additionalGuidelinesValue}
                              </WSText.ParagraphXs>
                            ) : (
                              <WSText.ParagraphXs color="gray400">
                                No text added yet.
                              </WSText.ParagraphXs>
                            )}
                            <WSButton.Link
                              type="button"
                              ml="XL"
                              size="S"
                              icon="edit"
                              onClick={() => {
                                additionalGuidelinesModal.open({
                                  additionalGuidelinesValue,
                                  onSubmit: data => {
                                    setAdditionalGuidelinesValue(
                                      data.additionalGuidelinesValue
                                    );
                                  }
                                });
                              }}
                            >
                              Edit
                            </WSButton.Link>
                          </WSFlexBox>
                        );
                      }
                      return null;
                    }}
                  </WSForm.Values>
                </InvoicingConfigField>
              </InvoicingConfigSection>

              <InvoicingConfigSection title="Work summary (line items)" mb="XL">
                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowOnlyPredefinedLineItems}
                  label="Allow only pre-defined line item descriptions"
                  helpText="Turn this on to restrict line item descriptions to a predefined set created by you that a contractor can select from."
                  valuePreviewText={`${
                    predefinedLineItems.length ?? 0
                  } options added.`}
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowOnlyPredefinedLineItems
                  ]}
                >
                  <WSForm.Values
                    names={[InvoiceSettingKeys.allowOnlyPredefinedLineItems]}
                  >
                    {values => {
                      if (
                        values[InvoiceSettingKeys.allowOnlyPredefinedLineItems]
                      ) {
                        return (
                          <WSFlexBox alignItems="center" ml="2XL">
                            <WSText.ParagraphXs color="gray400">
                              {predefinedLineItems?.length || 0} options added.
                            </WSText.ParagraphXs>
                            <WSButton.Link
                              type="button"
                              ml="XL"
                              size="S"
                              icon="edit"
                              onClick={() => {
                                predefinedLineItemsModal.open({
                                  predefinedLineItemsValue: predefinedLineItems,
                                  onSubmit: data => {
                                    setPredefinedLineItems(
                                      data?.predefinedLineItemsValue
                                        ?.map((item: { value: string }) => ({
                                          description: item.value
                                        }))
                                        .filter(Boolean)
                                    );
                                  }
                                });
                              }}
                            >
                              Edit
                            </WSButton.Link>
                          </WSFlexBox>
                        );
                      }
                      return null;
                    }}
                  </WSForm.Values>
                </InvoicingConfigField>

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowLineItemDiscounts}
                  label="Allow line item discounts"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowLineItemDiscounts
                  ]}
                />

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowLineItemReimbursableExpenses}
                  label="Allow line item reimbursable expenses"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowLineItemReimbursableExpenses
                  ]}
                />

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowLateFees}
                  label="Allow late fees"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowLateFees
                  ]}
                />

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowProcessingFeePercentage}
                  label="Allow processing fee percentage (%)"
                  helpText="Wingspan charges 2.9% for card payments. Turn this on to allow contractors to pass on all, part, or more of this fee to you (the payer)."
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowProcessingFeePercentage
                  ]}
                />
              </InvoicingConfigSection>

              <InvoicingConfigSection title="Due date and frequency" mb="XL">
                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowRecurringInvoices}
                  label="Allow recurring payables (invoices)"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowRecurringInvoices
                  ]}
                />

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.dueInDays}
                  label="Set a default due date"
                  valuePreviewText={dueInDaysValueMap[dueInDaysValue]}
                  {...invoicingConfigForPayer?.[InvoiceSettingKeys.dueInDays]}
                >
                  <WSForm.Values names={[InvoiceSettingKeys.dueInDays]}>
                    {values => {
                      if (values[InvoiceSettingKeys.dueInDays]) {
                        return (
                          <WSFlexBox alignItems="center" ml="2XL" wrap="nowrap">
                            {Object.keys(dueInDaysValueMap).includes(
                              dueInDaysValue
                            ) ? (
                              <WSText.ParagraphXs color="gray400">
                                {dueInDaysValueMap[dueInDaysValue]}
                              </WSText.ParagraphXs>
                            ) : (
                              <WSText.ParagraphXs color="gray400">
                                Default due date not selected yet.
                              </WSText.ParagraphXs>
                            )}
                            <WSButton.Link
                              type="button"
                              ml="XL"
                              size="S"
                              icon="edit"
                              onClick={() => {
                                defaultDueDateModal.open({
                                  dueInDaysValue,
                                  onSubmit: data => {
                                    setDueInDaysValue(data.dueInDaysValue);
                                  }
                                });
                              }}
                            >
                              Edit
                            </WSButton.Link>
                          </WSFlexBox>
                        );
                      }
                      return null;
                    }}
                  </WSForm.Values>
                </InvoicingConfigField>
              </InvoicingConfigSection>

              <InvoicingConfigSection title="Contractors" mb="XL">
                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowCollaboratorSplits}
                  label="Allow contractor splits"
                  helpText="Turn this on to allow contractors to route payments to subcontractors when invoice is paid."
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowCollaboratorSplits
                  ]}
                />
              </InvoicingConfigSection>

              <InvoicingConfigSection title="Additional info" mb="XL">
                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.requireProjectName}
                  label="Require project name"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.requireProjectName
                  ]}
                />

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.requirePONumber}
                  label="Require PO number"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.requirePONumber
                  ]}
                />

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.requireAttachments}
                  label="Require attachment(s)"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.requireAttachments
                  ]}
                />

                <InvoicingConfigField
                  mb="XS"
                  editable
                  name={InvoiceSettingKeys.allowPaymentReminders}
                  label="Allow payment reminders sent to you"
                  helpText="Turn this on to allow reminder emails sent to you when payables (invoices) are almost due and overdue"
                  {...invoicingConfigForPayer?.[
                    InvoiceSettingKeys.allowPaymentReminders
                  ]}
                />
              </InvoicingConfigSection>

              <WSText.ParagraphSm weight="light">
                Learn more about{" "}
                <WSButton.Link
                  size="M"
                  type="button"
                  rightIcon="open-tab"
                  onClick={() => {
                    openInNewTab(
                      "https://docs.wingspan.app/docs/payables-configuration"
                    );
                  }}
                >
                  payable settings
                </WSButton.Link>
              </WSText.ParagraphSm>

              <WSErrorMessage
                m="M"
                error={updateInvoicingConfigMeta.error}
                contextKey={ErrorContextKey.UpdateInvoicingConfig}
              />
            </WSElement>
            {hideSaveButton ? null : (
              <WSForm.Context>
                {context => {
                  return (
                    <WSButtons p="S" format="modal">
                      <WSButton.Primary
                        size="S"
                        type="submit"
                        loading={updateInvoicingConfigMeta.isLoading}
                      >
                        Save changes
                      </WSButton.Primary>
                      <WSButton.Tertiary
                        type="button"
                        size="S"
                        onClick={() => {
                          onCancel?.();
                        }}
                      >
                        Cancel
                      </WSButton.Tertiary>
                    </WSButtons>
                  );
                }}
              </WSForm.Context>
            )}
          </WSForm>
        );
      }}
    </WSQueries>
  );
}

export const FormInvoicingConfig = React.forwardRef<
  FormInvoicingConfigRef,
  FormInvoicingConfigProps
>(_FormInvoicingConfig);
