import {
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { Tooltip } from "../../../components/Tooltip";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdateMemberClient } from "../../../query/payments/mutations";
import { useMemberClientQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { validatorEmail } from "../../../shared/validators/validatorEmail";
import { updateArrayString } from "../../../utils/serviceHelper";
import { AdditionalEmailsFieldOld } from "../AdditionalEmailsField/AdditionalEmailsFieldOld";

type Props = WSElementProps & { memberClientId: string };

export const EDIT_CONTACT_INFO_MODAL = "EDIT_CONTACT_INFO_MODAL";

export const EditContactInfoModal: React.FC<Props> = ({ memberClientId }) => {
  const memberClientQuery = useMemberClientQuery(memberClientId);
  const [updateMemberClient, updateMemberClientMeta] =
    useUpdateMemberClient(memberClientId);
  const { closeModal } = useModalContext();

  return (
    <WSModal name={EDIT_CONTACT_INFO_MODAL} size="S" title="Contact info">
      <WSQueries queries={{ memberClientQuery }}>
        {({ memberClientQuery: { data: memberClient } }) => (
          <WSFormOld<{
            additionalEmails?: { email: string }[];
          }>
            defaultValues={{
              additionalEmails: (memberClient.emailCC || []).map(email => ({
                email
              }))
            }}
            onSubmit={values => {
              updateMemberClient(
                {
                  emailCC: updateArrayString(
                    memberClient.emailCC || [],
                    values.additionalEmails
                      ? values.additionalEmails.map(e => e.email)
                      : []
                  ) as any
                },
                {
                  onSuccess: () => {
                    closeModal(EDIT_CONTACT_INFO_MODAL);
                  }
                }
              );
            }}
            validationSchema={Yup.object().shape({
              additionalEmails: Yup.array(
                Yup.object().shape({
                  email: validatorEmail
                })
              )
            })}
          >
            <WSFlexBox mb="XS">
              <WSText.ParagraphSm color="gray500" mr="XS">
                Client contact email
              </WSText.ParagraphSm>
              <Tooltip>Client email can not be changed</Tooltip>
            </WSFlexBox>
            <WSText mb="XL">{memberClient.emailTo}</WSText>

            <AdditionalEmailsFieldOld
              name="additionalEmails"
              inputLabel="Contact email CC"
              buttonLabel="Add email recipient"
              buttonDescription="Additional email addresses will be cc’d"
              mb="XL"
            />

            <WSErrorMessage
              mb="XL"
              error={updateMemberClientMeta.error}
              contextKey="EditContact"
            />

            <WSButtons format="modal">
              <WSButton loading={updateMemberClientMeta.isLoading}>
                Update
              </WSButton>
              <WSButton.Tertiary
                onClick={() => {
                  closeModal(EDIT_CONTACT_INFO_MODAL);
                }}
              >
                Cancel
              </WSButton.Tertiary>
            </WSButtons>
          </WSFormOld>
        )}
      </WSQueries>
    </WSModal>
  );
};
