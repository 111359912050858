import { TaxFilingCode } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import {
  WSButton,
  WSCentered,
  WSContainer,
  WSElementProps,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSScreen,
  WSSelectOld,
  WSText,
  useIsMobile,
  useModalContext
} from "@wingspanhq/fe-component-library";
import { AccountStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import {
  EXPENSES_OPTIONS,
  FILLING_CODE_OPTIONS,
  HEAD_OF_HOUSEHOLD_OPTIONS,
  MARRIED_OPTIONS,
  SINGLE_OPTIONS,
  US_STATES_OPTIONS
} from "../../../constants/user";
import { WSQueries } from "../../../query/WSQuery";
import { useSetBookkeepingEnable } from "../../../query/bookkeeping/mutations";
import {
  useCatchUpTransactionsExpense,
  useCatchUpTransactionsIncome
} from "../../../query/bookkeeping/queries";
import {
  getAllTotalCatchUpExpenseTransactions,
  getAllTotalCatchUpIncomeTransactions
} from "../../../query/bookkeeping/selectors";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useUpdateActivity,
  useUpdateMemberProfile
} from "../../../query/users/mutations";
import { useAccounts, useMemberProfile } from "../../../query/users/queries";
import { getIncomeOptionsByFilingCode } from "../../../query/users/selectors";
import { useSetWSStore } from "../../../store";
import { track } from "../../../utils/analytics";
import { BOOKKEEPING_SUCCESS_ONBOARDING } from "../modals/SuccessOnboarding";
import styles from "./Index.module.scss";

export const TaxProfile: React.FC<{ noSync?: boolean }> = ({
  noSync = false
}) => {
  const { openModal } = useModalContext();
  const userId = useUserId();
  const history = useHistory();
  const qMember = useMemberProfile(userId);
  const qAccounts = useAccounts();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);
  const [setBookkeepingEnable, setBookkeepingEnableMeta] =
    useSetBookkeepingEnable();
  const [updateMemberProfile, updateMemberProfileMeta] = useUpdateMemberProfile(
    {
      throwOnError: true
    }
  );
  const setStore = useSetWSStore();

  const qCatchUpTransactionsIncome = useCatchUpTransactionsIncome(
    userId,
    undefined,
    {
      forceFetchOnMount: true
    }
  );
  const qCatchUpTransactionsExpense = useCatchUpTransactionsExpense(
    userId,
    undefined,
    {
      forceFetchOnMount: true
    }
  );

  const isMobile = useIsMobile();

  return (
    <WSQueries
      queries={{
        qMember,
        qAccounts,
        qCatchUpTransactionsIncome,
        qCatchUpTransactionsExpense
      }}
    >
      {({
        qMember,
        qAccounts,
        qCatchUpTransactionsIncome,
        qCatchUpTransactionsExpense
      }) => {
        const memberData = qMember.data;
        const bookkeepingConnected =
          qAccounts.data.filter(
            a => a.usedFor?.bookkeeping && a.status !== AccountStatus.Inactive
          ).length > 0;

        const initialValues = {
          stateOfResidence: memberData.profile.taxInfo?.stateOfResidence,
          filingCode: memberData.profile.taxInfo?.filingCode,
          incomeEstimateForYear: memberData.profile.taxInfo
            ?.incomeEstimateForYear
            ? String(memberData.profile.taxInfo.incomeEstimateForYear)
            : undefined,
          expensesEstimateForYear: memberData.profile.taxInfo
            ?.expensesEstimateForYear
            ? String(memberData.profile.taxInfo.expensesEstimateForYear)
            : undefined
        };

        const totalCatchUpTransactions =
          getAllTotalCatchUpIncomeTransactions(
            qCatchUpTransactionsIncome.data
          ) +
          getAllTotalCatchUpExpenseTransactions(
            qCatchUpTransactionsExpense.data
          );

        return (
          <ProjectOnboardingLayout progress={(100 / 5) * 5}>
            <BrowserPageTitle title="Your Tax Profile" />
            {bookkeepingConnected ? (
              <WSFlexBox alignItems="center" mt="2XL">
                <WSIcon block name="refresh-v" mr="M" color="blue500" />
                <WSText weight="medium" color="gray500">
                  Syncing in progress!
                </WSText>
              </WSFlexBox>
            ) : null}

            <h1 className={styles.title}>Your Tax Profile</h1>
            <p className={styles.description}>
              This will help us calibrate your tax savings.
            </p>

            <WSContainer>
              <WSCentered span={{ s: "6", l: "4" }}>
                <WSFormOld
                  defaultValues={initialValues}
                  validationSchema={Yup.object().shape({
                    stateOfResidence: Yup.string().required(
                      "State of residence is required"
                    ),
                    filingCode: Yup.mixed()
                      .oneOf(FILLING_CODE_OPTIONS.map(({ value }) => value))
                      .required("Tax filing status is required"),
                    incomeEstimateForYear: Yup.string()
                      .test(
                        "income",
                        "Income is required",
                        function (currentValue) {
                          const filingCode = this.parent.filingCode;

                          if (filingCode) {
                            if (
                              [
                                TaxFilingCode.Single,
                                TaxFilingCode.MarriedFilingSeparately,
                                TaxFilingCode.QualifiedWidower
                              ].includes(filingCode)
                            ) {
                              return SINGLE_OPTIONS.some(
                                ({ value }) => value === Number(currentValue)
                              );
                            } else if (
                              [TaxFilingCode.Married].includes(filingCode)
                            ) {
                              return MARRIED_OPTIONS.some(
                                ({ value }) => value === Number(currentValue)
                              );
                            } else {
                              return HEAD_OF_HOUSEHOLD_OPTIONS.some(
                                ({ value }) => value === Number(currentValue)
                              );
                            }
                          } else {
                            return false;
                          }
                        }
                      )
                      .required("Income is required"),
                    expensesEstimateForYear: Yup.string()
                      .test(
                        "expenses",
                        "Expenses is required",
                        function (currentValue) {
                          const filingCode = this.parent.filingCode;
                          if (filingCode) {
                            return EXPENSES_OPTIONS.some(
                              ({ value }) => value === Number(currentValue)
                            );
                          } else {
                            return false;
                          }
                        }
                      )
                      .required("Expenses is required")
                  })}
                  onSubmit={async values => {
                    try {
                      await updateMemberProfile({
                        memberId: userId,
                        profile: {
                          taxInfo: {
                            stateOfResidence: values.stateOfResidence,
                            filingCode: values.filingCode,
                            incomeEstimateForYear: Number(
                              values.incomeEstimateForYear
                            ),
                            expensesEstimateForYear: Number(
                              values.expensesEstimateForYear
                            ),
                            numExemptions: 0
                          }
                        }
                      });

                      await setBookkeepingEnable(userId);

                      track("Product activation");

                      await updateActivity({
                        flows: {
                          bookkeepingSetup: {
                            version: 4,
                            currentStep: 6,
                            totalSteps: 6,
                            complete: true
                          }
                        }
                      });

                      if (noSync) {
                        track("Bookkeeping Setup Completed");
                        history.push("/member/bookkeeping");

                        setTimeout(
                          () => openModal(BOOKKEEPING_SUCCESS_ONBOARDING),
                          500
                        );
                      } else {
                        if (!totalCatchUpTransactions) {
                          track("Bookkeeping Setup Completed");
                          history.push("/member/bookkeeping");

                          setTimeout(
                            () => openModal(BOOKKEEPING_SUCCESS_ONBOARDING),
                            500
                          );
                        } else {
                          history.push(
                            "/member/bookkeeping/setup/catch-up/income"
                          );
                        }
                      }
                      setStore({ productActivationAfterOnboarding: false });
                    } catch (error) {
                      console.error("Failed updating user profile");
                      console.error(error);
                    }
                  }}
                >
                  <WSFormOld.Field
                    mb="2XL"
                    name="filingCode"
                    label="Tax filing status"
                    component={WSSelectOld}
                    componentProps={{
                      options: FILLING_CODE_OPTIONS
                    }}
                  />

                  <WSFormOld.Field
                    mb="2XL"
                    name="stateOfResidence"
                    label="State of residence"
                    component={WSSelectOld}
                    componentProps={{
                      searchable: true,
                      options: US_STATES_OPTIONS
                    }}
                  />

                  <WSFormOld.Value name="filingCode">
                    {filingCode => {
                      return (
                        <>
                          <IncomeField filingCode={filingCode} mb="2XL" />
                          <ExpensesField filingCode={filingCode} mb="2XL" />
                        </>
                      );
                    }}
                  </WSFormOld.Value>

                  <WSErrorMessage
                    my="2XL"
                    contextKey="CreatePPL"
                    error={
                      updateMemberProfileMeta.error ||
                      setBookkeepingEnableMeta.error ||
                      updateActivityMeta.error
                    }
                  />

                  <WSButton
                    mb="2XL"
                    loading={
                      updateMemberProfileMeta.isLoading ||
                      setBookkeepingEnableMeta.isLoading ||
                      updateActivityMeta.isLoading
                    }
                    fullWidth
                    name="submitTaxProfile"
                  >
                    <WSScreen.Mobile>Save and continue</WSScreen.Mobile>
                    <WSScreen.TabletAndDesktop>
                      Save and continue to bookkeeping
                    </WSScreen.TabletAndDesktop>
                  </WSButton>
                </WSFormOld>
              </WSCentered>
            </WSContainer>
          </ProjectOnboardingLayout>
        );
      }}
    </WSQueries>
  );
};

const IncomeField: React.FC<
  {
    filingCode?: TaxFilingCode;
  } & WSElementProps
> = ({ filingCode, ...elementProps }) => {
  const { register, watch, errors, setValue } = useFormContext();

  const name = "incomeEstimateForYear";
  const value = watch(name);

  useEffect(() => {
    register(name);
  }, [register]);

  const options = getIncomeOptionsByFilingCode(filingCode).map(o => ({
    value: String(o.value),
    label: `$${o.label}`
  }));

  return (
    <WSFormOld.Field {...elementProps} name={name}>
      <WSFormOld.Label>Estimated annual freelance income</WSFormOld.Label>

      <WSSelectOld
        value={value}
        name={name}
        options={options}
        placeholder="$"
        error={!!errors[name]}
        onChange={newValue => {
          setValue(name, newValue);
        }}
      />

      <WSFormOld.Error />
    </WSFormOld.Field>
  );
};

const ExpensesField: React.FC<
  {
    filingCode?: TaxFilingCode;
  } & WSElementProps
> = ({ filingCode, ...elementProps }) => {
  const { register, watch, errors, setValue } = useFormContext();

  const name = "expensesEstimateForYear";
  const value = watch(name);

  useEffect(() => {
    register(name);
  }, [register]);

  const options = (filingCode ? EXPENSES_OPTIONS : []).map(o => ({
    value: String(o.value),
    label: `$${o.label}`
  }));

  return (
    <WSFormOld.Field {...elementProps} name={name}>
      <WSFormOld.Label>Estimated annual freelance expenses</WSFormOld.Label>

      <WSSelectOld
        value={value}
        name={name}
        options={options}
        placeholder="$"
        error={!!errors[name]}
        onChange={newValue => {
          setValue(name, newValue);
        }}
      />

      <WSFormOld.Error />
    </WSFormOld.Field>
  );
};
