import {
  getAdminSessionToken,
  getWingspanAdminSessionToken
} from "../../../services/sessionStorage";

export const selectorIsImpersonatedWingspanAdmin = () => {
  const adminToken = getAdminSessionToken();
  const wingspanAdminToken = getWingspanAdminSessionToken();

  return adminToken ? adminToken === wingspanAdminToken : false;
};
