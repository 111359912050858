import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorPayerTaxFormIsBusiness } from "./selectorPayerTaxFormIsBusiness";
import { selectorPayerTaxFormIsIndividual } from "./selectorPayerTaxFormIsIndividual";

export const selectorPayerTaxFormDefaultLastFourTaxId = (
  data: IPayerTaxFormResponse
) => {
  return selectorPayerTaxFormIsBusiness(data)
    ? data.data.w9Info?.einLastFour
    : selectorPayerTaxFormIsIndividual(data)
    ? data.data.w9Info?.ssnLastFour
    : data.data.w9Info?.einLastFour && data.tinVerification?.ein?.status
    ? data.data.w9Info?.einLastFour
    : data.data.w9Info?.ssnLastFour && data.tinVerification?.ssn?.status
    ? data.data.w9Info?.ssnLastFour
    : data.data.w9Info?.einLastFour || data.data.w9Info?.ssnLastFour;
};
