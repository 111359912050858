import {
  WSAvatar,
  WSButton,
  WSCentered,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSLayout,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { IAuthorizedAccount } from "@wingspanhq/users";
import classNames from "classnames";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../../../../MemberOnboarding/screens/SelectAccount.module.scss";
import { BrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../../query/WSQuery";
import { useUserId } from "../../../../query/hooks/helpers";
import { useRequestPrincipalUser } from "../../../../query/users/mutations";
import {
  useAuthorizedAccounts,
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { getAuthorizedAccountName } from "../../../../shared/utils/teamUtils";
import { useResetWSStore } from "../../../../store";
import { selectorAccessibleMemberEmail } from "../../selectors/selectorAccessibleMemberEmail";
import { selectorAuthorizedAccountByEmail } from "../../selectors/selectorAuthorizedAccountByEmail";
import { redirectByScope } from "../../utils/redirectByScope";
import { useStoredSelectedAccountEmail } from "../../utils/useStoredSelectedAccountEmail";
import { useModalPassword } from "../ModalPassword/useModalPassword";
import { useStoredDefaultSelectedAccount } from "../../utils/useStoredDefaultSelectedAccount";

export interface SelectAccountProps {
  onClose: (result: boolean) => void;
}

export const ModalSelectAccounts: React.FC<SelectAccountProps> = ({
  onClose
}) => {
  const history = useHistory();
  const resetWSStore = useResetWSStore();

  const userId = useUserId();
  const [storedDefaultSelectedEmail, setStoredDefaultSelectedEmail] =
    useStoredDefaultSelectedAccount();
  const [storedSelectedEmail, setStoredSelectedEmail] =
    useStoredSelectedAccountEmail();

  const [remember, setRemember] = useState(true);
  const [selectedEmailAccount, setSelectedEmailAccount] = useState<
    string | undefined
  >(storedDefaultSelectedEmail);

  const queryUserProfile = useUserProfile(userId);
  const queryMemberProfile = useMemberProfile(userId);
  const queryAuthorizedAccounts = useAuthorizedAccounts(userId);
  const [requestPrincipalUser, requestPrincipalUserMeta] =
    useRequestPrincipalUser();
  const modalPassword = useModalPassword();

  return (
    <WSLayout
      smallHeader
      headerCenter={<WSText weight="medium">Select account</WSText>}
      line
      contentClassName={styles.container}
    >
      <BrowserPageTitle title="Select Account" />
      <WSElement className={styles.content}>
        <WSCentered span={{ m: "10", l: "8", xl: "6" }}>
          <WSPanel mt="5XL">
            <WSText.Heading5 mb="M">
              Who would you like to continue as?
            </WSText.Heading5>
            <WSText color="gray600" mb="XL">
              You have access to the following accounts.
            </WSText>

            <WSQueries
              queries={{
                queryAuthorizedAccounts,
                queryUserProfile,
                queryMemberProfile
              }}
            >
              {({
                queryAuthorizedAccountsData,
                queryUserProfileData,
                queryMemberProfileData
              }) => {
                const accounts: IAuthorizedAccount[] = uniqBy(
                  [
                    {
                      userId,
                      accessibleMember: queryMemberProfileData,
                      accessibleUser: queryUserProfileData,
                      allowedScopeGroupIds: []
                    },
                    ...queryAuthorizedAccountsData
                  ],
                  ac => ac.accessibleMember.user.email
                );

                const currentSelectedEmail =
                  accounts.find(
                    ac =>
                      ac.accessibleMember.user.email === selectedEmailAccount
                  )?.accessibleMember.user.email ||
                  queryMemberProfileData.user.email;

                const handleClose = (result: boolean) => {
                  setStoredDefaultSelectedEmail(
                    remember ? currentSelectedEmail : ""
                  );
                  setStoredSelectedEmail(currentSelectedEmail);

                  onClose(result);
                };

                return (
                  <>
                    {accounts.map(account => {
                      const name = getAuthorizedAccountName(
                        account.accessibleMember,
                        account.accessibleUser
                      );

                      const isSelected =
                        currentSelectedEmail ===
                        selectorAccessibleMemberEmail(account);
                      const isYourAccount =
                        queryMemberProfileData.user.email ===
                        selectorAccessibleMemberEmail(account);

                      return (
                        <WSPanel
                          key={selectorAccessibleMemberEmail(account)}
                          mb="M"
                          className={classNames({
                            [styles.selected]: isSelected
                          })}
                          onClick={
                            isSelected
                              ? undefined
                              : () =>
                                  setSelectedEmailAccount(
                                    selectorAccessibleMemberEmail(account)
                                  )
                          }
                        >
                          <WSFlexBox.CenterY wrap="nowrap">
                            <WSAvatar.Text mr="M" text={name} />
                            <WSText.ParagraphSm weight="medium" mr="XS">
                              {name}
                            </WSText.ParagraphSm>
                            {isYourAccount ? (
                              <WSText.ParagraphSm color="gray400">
                                (Your account)
                              </WSText.ParagraphSm>
                            ) : null}
                          </WSFlexBox.CenterY>
                        </WSPanel>
                      );
                    })}

                    <WSCheckboxToggle
                      my="2XL"
                      name="remember"
                      label="Always log in to this account"
                      value={remember}
                      onChange={setRemember}
                    />

                    <WSErrorMessage
                      contextKey="Other"
                      error={requestPrincipalUserMeta.error}
                      mt="2XL"
                      color="red400"
                    />

                    <WSButton.Primary
                      name="continue"
                      fullWidth
                      onAsyncClick={async () => {
                        if (
                          currentSelectedEmail ===
                          queryMemberProfileData.user.email
                        ) {
                          handleClose(false);
                          return;
                        }

                        const account = selectorAuthorizedAccountByEmail(
                          accounts,
                          currentSelectedEmail
                        )!;

                        await requestPrincipalUser(
                          { email: selectedEmailAccount },
                          {
                            onSuccess: () => {
                              handleClose(true);
                              redirectByScope(account, history, resetWSStore);
                            },
                            onError: async err => {
                              if (err.response?.status === 401) {
                                const result = await modalPassword.open({
                                  account
                                });

                                if (result) {
                                  handleClose(true);
                                  redirectByScope(
                                    account,
                                    history,
                                    resetWSStore
                                  );
                                  return;
                                }
                              }
                              handleClose(false);
                            }
                          }
                        );
                      }}
                    >
                      Continue
                    </WSButton.Primary>
                  </>
                );
              }}
            </WSQueries>
          </WSPanel>
        </WSCentered>
      </WSElement>
    </WSLayout>
  );
};
