import {
  SelectOptionOld,
  useModalContext,
  WSButton,
  WSButtons,
  WSElementProps,
  WSFormOld,
  WSModal,
  WSSelectOld
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksCustomers,
  useIntegrationsQuickbooksItems
} from "../../../query/integrations/queries";
import { useUpdateMemberClient } from "../../../query/payments/mutations";
import { useMemberClientQuery } from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { selectQuickbooksUserOptions } from "../../../modules/Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../modules/Integrations/selectors/selectQuickbooksAccountOrItemOptions";
import { selectorIsQBOActive } from "../../../shared/selectors/selectorIsQBOActive";

type Props = WSElementProps & { memberClientId: string };

export const EDIT_CLIENT_INTEGRATIONS_MODAL = "EDIT_CLIENT_INTEGRATIONS_MODAL";

export const EditClientIntegrationsModal: React.FC<Props> = ({
  memberClientId
}) => {
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksCustomers = useIntegrationsQuickbooksCustomers();
  const qIntegrationsQuickbooksItems = useIntegrationsQuickbooksItems();

  const qFeatureFlags = useFeatureFlags();

  const qMemberClient = useMemberClientQuery(memberClientId);
  const [updateMemberClient, updateMemberClientMeta] =
    useUpdateMemberClient(memberClientId);

  const { openModal, closeModal } = useModalContext();

  return (
    <WSModal
      name={EDIT_CLIENT_INTEGRATIONS_MODAL}
      size="S"
      title="Client integrations"
    >
      <WSQueries
        queries={{
          qFeatureFlags,
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksCustomers,
          qIntegrationsQuickbooksItems,
          qMemberClient
        }}
      >
        {({
          qFeatureFlags: { data: featureFlags },
          qIntegrationsQuickbooks: { data: integrationsQuickbooks },
          qIntegrationsQuickbooksCustomers: {
            data: integrationsQuickbooksCustomers
          },
          qIntegrationsQuickbooksItems: { data: integrationsQuickbooksItems },
          qMemberClient: { data: memberClient }
        }) => {
          const isQuickbooksActive = selectorIsQBOActive(
            featureFlags,
            integrationsQuickbooks
          );

          const quickbooksCustomerOptions: SelectOptionOld[] =
            selectQuickbooksUserOptions(integrationsQuickbooksCustomers);

          const quickbooksItemsOptions: SelectOptionOld[] =
            selectQuickbooksAccountOrItemOptions(integrationsQuickbooksItems);

          const defaultItem = integrationsQuickbooksItems.find(
            item => item.itemId === integrationsQuickbooks.defaults?.itemId
          );

          const mappedCustomer =
            memberClient.integration?.quickbooks?.customerId;
          const mappedItem = memberClient.integration?.quickbooks?.itemId;

          if (isQuickbooksActive) {
            return (
              <>
                <WSFormOld<{
                  qboCustomerId?: string | undefined;
                  qboItemId?: string | undefined;
                }>
                  defaultValues={{
                    qboCustomerId: mappedCustomer,
                    qboItemId: mappedItem
                  }}
                  onSubmit={values => {
                    updateMemberClient(
                      {
                        integration: {
                          quickbooks: {
                            customerId: values.qboCustomerId
                              ? values.qboCustomerId
                              : null,
                            itemId: values.qboItemId ? values.qboItemId : null
                          }
                        }
                      },
                      {
                        onSuccess: () => {
                          closeModal(EDIT_CLIENT_INTEGRATIONS_MODAL);
                        }
                      }
                    );
                  }}
                >
                  <WSFormOld.Field
                    mb="XL"
                    name="qboCustomerId"
                    component={WSSelectOld}
                    componentProps={{
                      placeholder: "Not mapped, create new Customer",
                      options: quickbooksCustomerOptions,
                      searchable: true,
                      cleanable: true,
                      placeholderActions: [
                        {
                          label: "Resync QBO Customers",
                          icon: "refresh-v",
                          callback() {
                            openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                              entity: QUICKBOOKS_ENTITY.CUSTOMERS
                            });
                          }
                        }
                      ]
                    }}
                    label="QBO Customer"
                  />
                  <WSFormOld.Field
                    mb="2XL"
                    name="qboItemId"
                    component={WSSelectOld}
                    componentProps={{
                      options: quickbooksItemsOptions,
                      placeholder: `Use default "${defaultItem?.fullyQualifiedName}"`,
                      cleanable: true,
                      searchable: true,
                      placeholderActions: [
                        {
                          label: "Resync QBO Items",
                          icon: "refresh-v",
                          callback() {
                            openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                              entity: QUICKBOOKS_ENTITY.ITEMS
                            });
                          }
                        }
                      ]
                    }}
                    label="Default QBO Item"
                  />

                  <WSErrorMessage
                    mb="XL"
                    error={updateMemberClientMeta.error}
                    contextKey="EditClientIntegrations"
                  />

                  <WSButtons format="modal">
                    <WSButton loading={updateMemberClientMeta.isLoading}>
                      Update
                    </WSButton>
                    <WSButton.Tertiary
                      onClick={() => {
                        closeModal(EDIT_CLIENT_INTEGRATIONS_MODAL);
                      }}
                    >
                      Cancel
                    </WSButton.Tertiary>
                  </WSButtons>
                </WSFormOld>
                <RefreshModal />
              </>
            );
          } else {
            closeModal(EDIT_CLIENT_INTEGRATIONS_MODAL);
            return null;
          }
        }}
      </WSQueries>
    </WSModal>
  );
};
