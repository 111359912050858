import {
  ITransaction,
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import {
  FiltersOld,
  toWSDate,
  toWSDateString,
  toWSMoneyString,
  useIsMobile,
  useModalContext,
  useWSSnackbar,
  WSButton,
  WSCheckboxGroup,
  WSDateRangeSelectOptions,
  WSElement,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSInfiniteScroll,
  WSInputDateOld,
  WSRadioInputGroup,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableItem,
  WSTableToolbar,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import reduce from "lodash/reduce";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Actions } from "../../../components/Actions";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQueries } from "../../../query/WSQuery";
import { useUpdateTransaction } from "../../../query/bookkeeping/mutations";
import {
  TransactionParams,
  useCatchUpTransactionsExpense,
  useProfitAndLoss,
  useTransactions
} from "../../../query/bookkeeping/queries";
import { getAccountName } from "../../../query/bookkeeping/selectors";
import { useUserId } from "../../../query/hooks/helpers";
import { useCardsQuery } from "../../../query/payments/queries";
import { useAccounts, useActivities } from "../../../query/users/queries";
import { Review } from "../../components/Review/Review";
import {
  WSCategories,
  WSCategoryIcon
} from "../../components/WSCategories/WSCategories";
import {
  getAmountValueAndFormat,
  getTransactionAvatarProps,
  getTransactionName,
  isValidDateRange
} from "../../utils";
import {
  BOOKKEEPING_MODAL_BULK_AMOUNT,
  BookkeepingModalBulkAmount
} from "../modals/bulkEdit/BulkAmount";
import {
  BOOKKEEPING_MODAL_BULK_BUSINESS,
  BookkeepingModalBulkBusiness
} from "../modals/bulkEdit/BulkBusiness";
import {
  BOOKKEEPING_MODAL_BULK_DELETE,
  BookkeepingModalBulkDelete
} from "../modals/bulkEdit/BulkDelete";
import {
  BOOKKEEPING_MODAL_BULK_RENAME,
  BookkeepingModalBulkRename
} from "../modals/bulkEdit/BulkRename";
import { runner } from "../modals/bulkEdit/utils.";
import { BookkeepingAddTransactionV2 } from "./BookkeepingAddTransactionV2";
import { useBookkeepingFilters } from "./BookkeepingIndex";
import { BookkeepingTransactionDetailsV2 } from "./BookkeepingTransactionV2";
import styles from "./BookkeepingTransactions.module.scss";

export const createTransactionParams: (
  params?: Partial<TransactionParams>
) => TransactionParams = (params = {}): TransactionParams => {
  return {
    status: null,
    type: "",
    size: "any",
    wsCategory: undefined,
    subcategory: undefined,
    customDateRange: [],
    dateRange: "ALL",
    category: "ALL",
    nonBusiness: false,
    accountId: ["ALL"],
    businessBanking: undefined,
    pageSize: 10,
    sort: { date: "desc" },
    ...params
  };
};

const columns: Array<WSTableColumn> = [
  {
    config: {
      header: { text: "Merchant" },
      gridTemplateSizeMax: "0.8fr"
    },
    renderFunction: rowData => (
      <WSTableCell
        avatar={getTransactionAvatarProps(rowData.data.source)}
        text={rowData.data.merchant}
        secondaryText={toWSDateString(rowData.data.date, "monthDayYear")}
        icon={rowData.data.business && rowData.data.receipt && "report"}
      />
    )
  },
  {
    config: {
      gridTemplateSizeMax: "0.8fr"
    },
    renderFunction: rowData =>
      rowData.data.business ? null : <WSTableCell pill={{ text: "Ignored" }} />
  },
  {
    config: {
      hideOnScreens: ["XS", "S", "M"],
      header: { text: "Category" },
      justify: "end"
    },
    renderFunction: rowData => (
      <WSTableCell
        text={
          rowData.data.category
            ? `${rowData.data.category}${
                rowData.data.subcategory ? ` - ${rowData.data.subcategory}` : ""
              }`
            : rowData.data.type
        }
      />
    )
  },
  {
    config: {
      hideOnScreens: ["XS"],
      header: { text: "Account" },
      justify: "end"
    },
    renderFunction: rowData => <WSTableCell text={rowData.data.account} />
  },
  {
    config: {
      justify: "end",
      header: { text: "Amount" }
    },
    renderFunction: rowData => {
      if (!rowData.data?.source) {
        return null;
      }

      return (
        <WSTableCell
          text={toWSMoneyString(-rowData.data.source.amount, "bookkeeping")}
        />
      );
    }
  }
];

export interface AmountColumnProps {
  transaction?: ITransaction;
}

export const AmountColumn: React.FC<AmountColumnProps> = ({ transaction }) =>
  transaction ? (
    <WSText {...getAmountValueAndFormat(transaction)} />
  ) : (
    <WSText>0</WSText>
  );

export const BookkeepingTransactionsV2: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const { openModal } = useModalContext();
  const { openSnackbar } = useWSSnackbar();

  const [updateTransaction] = useUpdateTransaction();

  const userId = useUserId();
  const qAccounts = useAccounts();
  const qCardsQuery = useCardsQuery();

  const { defaultFilters, filters, setFilters, redirectWithFilters } =
    useBookkeepingFilters();

  const { search, ...params } = filters;

  const dateRange = (
    isValidDateRange(filters.customDateRange)
      ? filters.customDateRange
      : WSDateRangeSelectOptions.ThisYear.range
  ) as any;

  const qProfitAndLoss = useProfitAndLoss({
    startDate: dateRange ? dateRange[0] : new Date(),
    endDate: dateRange ? dateRange[1] : new Date()
  });

  const qTransactions = useTransactions(filters);
  const qCatchUpExpenseTransactions = useCatchUpTransactionsExpense(userId);
  const qActivity = useActivities(userId);

  const transactionsList = flatten(qTransactions.data || []);

  const [selection, setSelection] = useState<WSTableItem[]>([]);

  const onActionFinish = () => setSelection([]);

  const areDefaultFilters = isEqual(defaultFilters, params);

  return (
    <>
      <BrowserPageTitle title="Income & Expenses: Transactions" />
      <WSFlexBox.CenterY justify="space-between">
        <WSText.Heading1 mb={isMobile ? "XL" : "NONE"}>
          Transactions
        </WSText.Heading1>

        <WSFlexBox.CenterY>
          <WSButton
            mr="M"
            onClick={() => {
              redirectWithFilters(
                "/member/bookkeeping/transactions/add-transaction"
              );
            }}
          >
            Add Transaction
          </WSButton>
          <Actions
            name="actions"
            items={[
              {
                label: "Add single transaction",
                onClick() {
                  redirectWithFilters(
                    "/member/bookkeeping/transactions/add-transaction"
                  );
                }
              },
              {
                label: "Upload CSV",
                onClick() {
                  history.push("/member/bookkeeping/imports/csv");
                }
              },
              {
                label: "Sync new account",
                onClick() {
                  history.push("/member/bookkeeping/imports");
                }
              }
            ]}
          />
        </WSFlexBox.CenterY>
      </WSFlexBox.CenterY>

      <Switch>
        <Route
          path={"/member/bookkeeping/transactions/add-transaction"}
          component={BookkeepingAddTransactionV2}
          exact
        />
        <Route
          path={"/member/bookkeeping/transactions/:transactionId"}
          component={BookkeepingTransactionDetailsV2}
          exact
        />
      </Switch>
      <WSQueries
        queries={{
          qAccounts,
          qProfitAndLoss,
          qCatchUpExpenseTransactions,
          qActivity
        }}
      >
        {({
          qAccounts,
          qProfitAndLoss,
          qActivity,
          qCatchUpExpenseTransactions
        }) => {
          const accounts = qAccounts.data.filter(a => a.usedFor?.bookkeeping);
          const profitAndLossData = qProfitAndLoss.data;

          const transactionsListMapped = transactionsList.map(transaction => {
            const account = qAccounts.data.find(
              ({ accountId }) => accountId === transaction.accountId
            );

            const last4 =
              qCardsQuery.data?.find(
                card => card.cardId === transaction.wingspanCardId
              )?.last4Digits || "";

            return {
              id: transaction.transactionId,
              data: {
                source: transaction,
                merchant: getTransactionName(transaction),
                date: transaction.date,
                type: transaction.type,
                amount: transaction.amount,
                receipt: transaction.receiptFileId,
                business: transaction.business,
                category: transaction.wsCategory,
                subcategory: transaction.labels.subcategory,
                account:
                  transaction.wsCategory === WSCategory.ContractorPayment &&
                  transaction.invoiceId
                    ? "-"
                    : transaction.accountId && account
                    ? getAccountName(account)
                    : transaction.businessBanking
                    ? `Wingspan Wallet ${last4 ? `(${last4})` : ""}`
                    : "Manually added"
              }
            };
          });

          let selectedAreAllBusiness = true;
          let selectedAreNotTransfers = true;
          const selectedTransactions = selection.map(({ data: { source } }) => {
            if (!source.business) {
              selectedAreAllBusiness = false;
            }
            if (source.type !== TransactionType.Expense) {
              if (source.type === TransactionType.Transfer) {
                selectedAreNotTransfers = false;
              }
            }
            return source;
          });

          return (
            <>
              {filters.nonBusiness ? (
                <WSText mt="M" mb="XL">
                  Showing every imported transaction - tax deductible and non
                  tax deductible (ignored).
                </WSText>
              ) : (
                <WSText mt="M" mb="XL">
                  Showing tax deductible transactions only. To see every
                  imported transaction,{" "}
                  <WSElement
                    as="a"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        nonBusiness: true
                      });
                    }}
                  >
                    click here
                  </WSElement>
                </WSText>
              )}
              <WSFiltersOld
                className={classNames(styles.filters, styles.sticky)}
                getCount={(defaultValues, currentValues) => {
                  const _defaultValues: FiltersOld = omit(defaultValues, [
                    "search",
                    "customDateRange"
                  ]);
                  const _currentValues: FiltersOld = omit(currentValues, [
                    "search",
                    "customDateRange"
                  ]);

                  return reduce<any, any>(
                    _currentValues,
                    (result, value, key) =>
                      isEqual(value, _defaultValues[key])
                        ? result
                        : result.concat(key),
                    []
                  ).length;
                }}
                filters={params}
                searchQuery={search}
                defaultFilters={defaultFilters}
                onFilter={(newParams: TransactionParams) => {
                  let custom = {};

                  if (
                    newParams.dateRange !== filters.dateRange &&
                    newParams.dateRange !== "CUSTOM"
                  ) {
                    custom = {
                      customDateRange:
                        newParams.dateRange === "PAST_WEEK"
                          ? WSDateRangeSelectOptions.PastWeek.range
                          : newParams.dateRange === "PAST_MONTH"
                          ? WSDateRangeSelectOptions.PastMonth.range
                          : newParams.dateRange === "PAST_YEAR"
                          ? WSDateRangeSelectOptions.Past12Months.range
                          : []
                    };
                  }

                  if (newParams.category !== "CUSTOM") {
                    newParams.wsCategory = undefined;
                    newParams.type = undefined;
                    newParams.subcategory = undefined;
                    newParams.nonBusiness = undefined;
                  }

                  setFilters({
                    ...filters,
                    ...(newParams as TransactionParams),
                    ...custom
                  });
                }}
                onSearch={newSearch =>
                  setFilters({
                    ...filters,
                    search: newSearch
                  })
                }
              >
                <WSFormOld.Field
                  name="dateRange"
                  label="Date range"
                  mb="XL"
                  component={WSRadioInputGroup}
                  componentProps={{
                    options: [
                      {
                        label: "All time",
                        value: "ALL"
                      },
                      {
                        label: "Past week",
                        value: "PAST_WEEK"
                      },
                      {
                        label: "Past month",
                        value: "PAST_MONTH"
                      },
                      {
                        label: "Past year",
                        value: "PAST_YEAR"
                      },
                      {
                        label: (
                          <WSFormOld.Context>
                            {({ setValue }) => (
                              <WSFlexBox
                                alignItems="center"
                                className={styles.dateRange}
                              >
                                <WSText mr="M">From</WSText>
                                <WSFormOld.Field
                                  name="customDateRange"
                                  component={WSInputDateOld}
                                  componentProps={{
                                    selectionMode: "range",
                                    onFocus() {
                                      setValue("dateRange", "CUSTOM");
                                    }
                                  }}
                                />
                              </WSFlexBox>
                            )}
                          </WSFormOld.Context>
                        ),
                        value: "CUSTOM"
                      }
                    ]
                  }}
                />

                <WSFormOld.Field
                  name="category"
                  label="Category"
                  mb="XL"
                  component={WSRadioInputGroup}
                  componentProps={{
                    options: [
                      {
                        label: "All",
                        value: "ALL"
                      },
                      {
                        label: (
                          <WSFormOld.Context>
                            {({ setValue }) => (
                              <WSFormOld.Values
                                names={[
                                  "wsCategory",
                                  "subcategory",
                                  "type",
                                  "nonBusiness",
                                  "isPositive"
                                ]}
                              >
                                {({
                                  wsCategory,
                                  subcategory,
                                  type,
                                  isPositive,
                                  nonBusiness
                                }) => (
                                  <>
                                    <WSCategories
                                      header={"Filter by category"}
                                      isSelectOnly={true}
                                      data={{
                                        type,
                                        business: !nonBusiness,
                                        isPositive: true,
                                        wsCategory,
                                        subcategory
                                      }}
                                      renderTrigger={({
                                        label,
                                        value,
                                        open
                                      }) => {
                                        if (
                                          label.wsCategory ||
                                          label.pseudoCategory
                                        ) {
                                          return (
                                            <WSFlexBox.CenterY
                                              wrap="nowrap"
                                              justify="space-between"
                                              className={styles.selectCategory}
                                              onClick={open}
                                              data-testid="categoryLabel"
                                            >
                                              <WSFlexBox.CenterY>
                                                <WSCategoryIcon
                                                  value={value}
                                                  mr="M"
                                                  size="M"
                                                />
                                                <WSElement>
                                                  <WSText color="gray600">
                                                    {label.pseudoCategory ??
                                                      label.wsCategory}
                                                  </WSText>
                                                  <WSText.ParagraphSm color="gray500">
                                                    {label.pseudoCategory
                                                      ? label.wsCategory
                                                      : label.subcategory}
                                                  </WSText.ParagraphSm>
                                                </WSElement>
                                              </WSFlexBox.CenterY>
                                              <WSIcon
                                                block
                                                size="XS"
                                                name="chevron-down"
                                                color="blue500"
                                              />
                                            </WSFlexBox.CenterY>
                                          );
                                        }

                                        return (
                                          <WSFlexBox.CenterY
                                            wrap="nowrap"
                                            justify="space-between"
                                            className={styles.selectCategory}
                                            onClick={open}
                                            data-testid="addCategory"
                                          >
                                            <WSFlexBox.CenterY>
                                              <WSText color="blue500">
                                                Add category
                                              </WSText>
                                            </WSFlexBox.CenterY>
                                            <WSIcon
                                              block
                                              size="XS"
                                              name="chevron-down"
                                              color="blue500"
                                            />
                                          </WSFlexBox.CenterY>
                                        );
                                      }}
                                      onUpdate={async data => {
                                        setValue("category", "CUSTOM");
                                        setValue("wsCategory", data.wsCategory);
                                        setValue(
                                          "subcategory",
                                          data.subcategory
                                        );
                                        setValue("type", data.type);
                                        setValue("nonBusiness", !data.business);
                                      }}
                                    />
                                  </>
                                )}
                              </WSFormOld.Values>
                            )}
                          </WSFormOld.Context>
                        ),
                        value: "CUSTOM"
                      }
                    ]
                  }}
                />

                <WSFormOld.Field
                  hidden={true}
                  label="Type"
                  name="type"
                  component={WSTextInput}
                />
                <WSFormOld.Field
                  hidden={true}
                  name="wsCategory"
                  component={WSTextInput}
                />
                <WSFormOld.Field
                  hidden={true}
                  name="subcategory"
                  component={WSTextInput}
                />
                <WSFormOld.Field
                  hidden={true}
                  name="nonBusiness"
                  component={WSTextInput}
                />
                <WSFormOld.Field
                  hidden={true}
                  name="isPositive"
                  component={WSTextInput}
                />

                <WSFormOld.Field
                  name="accountId"
                  label="Account"
                  mt="XL"
                  component={WSCheckboxGroup}
                  componentProps={{
                    options: [
                      {
                        label: "All",
                        value: "ALL"
                      },
                      ...accounts.map(a => ({
                        label: getAccountName(a),
                        value: a.accountId
                      }))
                    ]
                  }}
                />
              </WSFiltersOld>

              <Review
                mt="2XL"
                profitAndLoss={profitAndLossData}
                activity={qActivity.data}
                expenseCatchUp={qCatchUpExpenseTransactions.data}
              />
              {selection.length > 0 && (
                <WSCategories
                  key={selectedTransactions
                    .map(({ transactionId }) => transactionId)
                    .join(",")}
                  header="Select category"
                  renderTrigger={({ open, close }) => {
                    return (
                      <WSTableToolbar
                        mb="M"
                        count={selection.length}
                        onClear={() => setSelection([])}
                        actions={[
                          {
                            label: "Rename",
                            onClick() {
                              openModal(BOOKKEEPING_MODAL_BULK_RENAME, {
                                selectedTransactions,
                                onActionFinish
                              });
                            }
                          },
                          {
                            label: selectedAreAllBusiness
                              ? "Mark Not Tax Deductible (Ignored)"
                              : "Mark Tax Deductible",
                            onClick() {
                              openModal(BOOKKEEPING_MODAL_BULK_BUSINESS, {
                                selectedAreAllBusiness,
                                selectedTransactions,
                                onActionFinish
                              });
                            }
                          },
                          {
                            label: "Update category",
                            onClick: open
                          },
                          ...(selectedAreAllBusiness && selectedAreNotTransfers
                            ? [
                                {
                                  label: "Update amount",
                                  onClick() {
                                    openModal(BOOKKEEPING_MODAL_BULK_AMOUNT, {
                                      selectedTransactions,
                                      onActionFinish
                                    });
                                  }
                                }
                              ]
                            : []),
                          {
                            label: "Delete",
                            onClick() {
                              openModal(BOOKKEEPING_MODAL_BULK_DELETE, {
                                selectedTransactions,
                                onActionFinish
                              });
                            }
                          }
                        ]}
                      />
                    );
                  }}
                  onUpdate={async formData => {
                    await runner({
                      items: selectedTransactions,
                      openSnackbar,
                      tryAction: async transaction =>
                        await updateTransaction(
                          {
                            transactionId: transaction.transactionId,
                            wsCategory: formData.wsCategory,
                            amount:
                              Math.abs(transaction.amount) *
                              (formData.isPositive ? 1 : -1),
                            type: formData.type,
                            businessAmount:
                              Math.abs(transaction.amount) *
                              (formData.isPositive ? 1 : -1),
                            business: formData.business,
                            labels: {
                              subcategory: formData.subcategory ?? ""
                            }
                          },
                          {
                            throwOnError: true
                          }
                        ),
                      undoAction: async transaction =>
                        await updateTransaction({
                          transactionId: transaction.transactionId,
                          wsCategory: transaction.wsCategory,
                          amount: transaction.amount,
                          type: transaction.type,
                          businessAmount: transaction.businessAmount,
                          business: transaction.business,
                          labels: transaction.labels
                        }),
                      failMessage: failedItems =>
                        `${failedItems.length} category updates failed`,
                      successMessage: successItems =>
                        `${successItems.length} marked "${formData.wsCategory}"`
                    });
                  }}
                />
              )}
              <BookkeepingModalBulkRename />
              <BookkeepingModalBulkBusiness />
              <BookkeepingModalBulkAmount />
              <BookkeepingModalBulkDelete />
              <WSInfiniteScroll
                onLoad={() => {
                  qTransactions.fetchMore();
                }}
                loadMore={transactionsList.length > 0}
                endOfList={!qTransactions.canFetchMore}
                loading={!!qTransactions.isFetchingMore}
              >
                <WSTable
                  showHeader={false}
                  mb="M"
                  loading={qTransactions.isLoading}
                  columns={columns}
                  selection={selection}
                  onSelectionChange={setSelection}
                  onRowClick={item => {
                    redirectWithFilters(
                      `/member/bookkeeping/transactions/${item.data.source.transactionId}`
                    );
                  }}
                  getGroupName={item => {
                    const year = new Date(item.data.date).getFullYear();
                    const currentYear = new Date().getFullYear();

                    const month = toWSDate(item.data.date, "month").toString();

                    if (year === currentYear) {
                      return month;
                    }

                    return `${month} ${year}`;
                  }}
                  tableData={transactionsListMapped}
                  bottomMessage={
                    qTransactions.isLoading === false &&
                    transactionsList.length === 0 &&
                    (!areDefaultFilters || search ? (
                      <>
                        No transactions found.{" "}
                        <WSText.ParagraphXs
                          color="blue500"
                          inline
                          onClick={() => setFilters(defaultFilters)}
                        >
                          Show full transaction history.
                        </WSText.ParagraphXs>
                      </>
                    ) : (
                      "No transactions for this date range."
                    ))
                  }
                />
              </WSInfiniteScroll>
            </>
          );
        }}
      </WSQueries>
    </>
  );
};
