import {
  useWSModal,
  WSButtons,
  WSForm,
  WSFormOld,
  WSInputDate,
  WSInputNumber,
  WSInputText,
  WSText
} from "@wingspanhq/fe-component-library";
import { MemberClientStatus } from "@wingspanhq/payments/dist/interfaces";
import { DeductionType } from "@wingspanhq/payments/dist/interfaces/deductions";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import { sortBy } from "lodash";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../query/hooks/helpers";
import { useCreateCollaboratorDeduction } from "../../../../query/payments/mutations";
import { useCollaboratorsQuery } from "../../../../query/payments/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { SelectPayee } from "../../../../modules/Payees/components/SelectPayee";

type Props = {
  onClose: () => void;
};

export const AddPayeeDeduction: React.FC<Props> = ({ onClose }) => {
  const qCollaborators = useCollaboratorsQuery();
  const clientId = useUserId();

  const [createDeduction, createDeductionMeta] =
    useCreateCollaboratorDeduction();

  return (
    <WSQueries queries={{ qCollaborators }}>
      {({ qCollaborators }) => {
        const filteredCollaborators = qCollaborators.data.filter(
          c => c.status !== MemberClientStatus.Inactive
        );

        const recentCollaborators = sortBy(filteredCollaborators, "updatedAt")
          .reverse()
          .slice(0, 3);

        return (
          <WSForm<{
            name: string;
            amount: number;
            startDate: Date;
            payeeId: string;
          }>
            defaultValues={{
              startDate: new Date()
            }}
            validationSchema={Yup.object().shape({
              payeeId: Yup.string().required("Contractor is required"),
              name: Yup.string().required("Name is required"),
              amount: Yup.number().required("Amount is required"),
              startDate: Yup.date().required("Start date is required")
            })}
            onSubmit={async formData => {
              await createDeduction(
                {
                  name: formData.name,
                  type: DeductionType.PrePayment,
                  amount: formData.amount,
                  startDate: formData.startDate,
                  clientId,
                  currency: Currency.USD,
                  memberId: formData.payeeId
                },
                {
                  onSuccess() {
                    onClose();
                  }
                }
              );
            }}
          >
            <WSForm.Field
              mb="XL"
              name="payeeId"
              label="Contractor"
              component={SelectPayee}
            />
            <WSForm.Field
              mb="XL"
              label="Deduction name"
              name="name"
              component={WSInputText}
            />
            <WSForm.Field
              mb="XL"
              label="Deduction amount"
              name="amount"
              component={WSInputNumber}
              componentProps={{
                currency: "USD",
                maxFractionDigits: 2,
                min: 0
              }}
            />
            <WSForm.Field
              mb="XL"
              label="Start date"
              name="startDate"
              component={WSInputDate}
            />

            <WSErrorMessage
              mt="XL"
              error={createDeductionMeta.error}
              contextKey="Other"
            />

            <WSText mt="2XL" color="gray500">
              Scheduled deductions are completed when you pay a contractor. If a
              deduction is larger than a payable (or your specified percentage
              of the payable) the remaining balance will be deducted from the
              next payable.
            </WSText>

            <WSButtons mt="3XL" forceFullWidth>
              <WSFormOld.SubmitButton data-testid="submit">
                Add new deduction
              </WSFormOld.SubmitButton>
            </WSButtons>
          </WSForm>
        );
      }}
    </WSQueries>
  );
};

export const useAddPayeeDeduction = () =>
  useWSModal(AddPayeeDeduction, {
    title: "Add new deduction",
    size: "S"
  });
