import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { PayerSetupLayout } from "../../../components/PayerSetupLayout";
import { FormCalculationSettings } from "../../../components/FormCalculationSettings";

export const RouteSetupCalculationSettings: React.FC<
  SetupRouteComponentProps
> = ({ onBack, onNext }) => {
  return (
    <PayerSetupLayout title="Calculation settings">
      <FormCalculationSettings onNext={onNext} onBack={onBack} />
    </PayerSetupLayout>
  );
};
