import {
  useIsMobile,
  WSFormOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IUserUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";

import { useUpdateUserProfile } from "../../../query/users/mutations";
import styles from "./SettingsUpdateForms.module.scss";

import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { openIntercomMessage } from "../../../shared/utils/intercom";

interface FormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const LegalFirstNameForm: React.FC<FormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const isMobile = useIsMobile();
  const queryVerifications = useQueryVerifications();
  const isVerified = queryVerifications.data?.tax === "Verified";

  const [updateUserProfile, { error: updateUserProfileError }] =
    useUpdateUserProfile();

  const onFormSubmit = async (data: any) => {
    if (defaultValues.firstName && isVerified) {
      openIntercomMessage(
        `Hi there! I want to update my legal first name to "${data.firstName}".\n\n`
      );
      onSubmit(data);
      return;
    }

    const request: IUserUpdateRequest = {
      profile: {
        firstName: data.firstName
      }
    };
    await updateUserProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("This field is required")
      })}
    >
      <WSFormOld.Field name="firstName">
        <WSFormOld.Input render={props => <WSTextInput {...props} />} />
      </WSFormOld.Field>
      <WSText.ParagraphSm weight="medium" className={styles.errorText} mt="XS">
        {updateUserProfileError?.response?.data["error"]}
      </WSText.ParagraphSm>

      <WSFormOld.SubmitButton
        name="update"
        type="submit"
        mt="2XL"
        fullWidth={isMobile}
      >
        {defaultValues.firstName && isVerified
          ? "Send request to customer support"
          : "Update"}
      </WSFormOld.SubmitButton>
    </WSFormOld>
  );
};
