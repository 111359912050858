import {
  IPayeeTaxFormResponse,
  VerificationStatus
} from "@wingspanhq/payments/dist/interfaces";

import { IMember } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorPayeeTaxFormIsW9Completed = (
  taxForms: IPayeeTaxFormResponse[],
  member: IMember
) => {
  const isW9FlowTotallyCompleted =
    (member.profile.tinVerification?.ein?.status ===
      VerificationStatus.Verified ||
      member.profile.tinVerification?.ssn?.status ===
        VerificationStatus.Verified) &&
    taxForms.length &&
    taxForms.every(taxForm => taxForm.recipientConfirmedW9Info);

  return !!isW9FlowTotallyCompleted;
};
