import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { DataView } from "../../../shared/components/DataView";

export const ReviewOriginalBusinessName: React.FC = () => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  return (
    <DataView
      title="Legal business name"
      pill={{ text: "Original" }}
      data={[
        {
          label: "Legal business name",
          value: queryMember.data?.profile.company?.legalBusinessName
        },
        {
          label: "Doing business as (DBA)",
          value: queryMember.data?.profile.company?.name
        }
      ]}
    />
  );
};
