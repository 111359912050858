import { useEditEmailModal } from "./useEditEmailModal";
import { useEditEmailConfirmModal } from "./useEditEmailConfirmModal";
import { PayeeEmailChangeResultType } from "@wingspanhq/payments/dist/interfaces";
import { useEditEmailUpdateContractorModal } from "./useEditEmailUpdateContractorModal";
import { useEditEmailUpdateContractorProcessModal } from "./useEditEmailUpdateContractorProcessModal";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";

type RunArguments = {
  email: string;
  payeeId: string;
  onSuccess?: () => void;
};

export const useEditEmail = () => {
  const { openSnackbar } = useWSSnackbar();
  const history = useHistory();
  const previewEmailModal = useEditEmailModal();
  const confirmEmailModal = useEditEmailConfirmModal();
  const updateContractorModal = useEditEmailUpdateContractorModal();
  const updateContractorProgressModal =
    useEditEmailUpdateContractorProcessModal();

  return {
    run({ email, payeeId, onSuccess }: RunArguments) {
      previewEmailModal.open({
        email,
        payeeId,
        onSuccess: response => {
          switch (response.resultType) {
            case PayeeEmailChangeResultType.ExistingUserExistingPayee:
              updateContractorModal.open({
                response,
                onSuccess() {
                  updateContractorProgressModal.open({
                    response,
                    onSuccess() {
                      openSnackbar({
                        message: "Contractor successfully updated",
                        type: "success"
                      });
                      history.push(`/member/payees/${response.targetPayeeId}`);
                      onSuccess?.();
                    }
                  });
                }
              });
              break;
            case PayeeEmailChangeResultType.UpdatedEmail:
            case PayeeEmailChangeResultType.ExistingUserNewPayee:
              confirmEmailModal.open({
                response,
                onSuccess() {
                  openSnackbar({
                    message: "Email successfully updated",
                    type: "success"
                  });
                  if (response.targetPayeeId) {
                    history.push(`/member/payees/${response.targetPayeeId}`);
                  }

                  onSuccess?.();
                }
              });
              break;
          }
        }
      });
    }
  };
};
