import { useMemo } from "react";
import { useQueryInternalAccount } from "../../../../query/banking/queries/useQueryInternalAccount";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryPaymentsCard } from "../../../../query/paymentCards/queries/useQueryPaymentsCard";
import { usePayoutSettingsDebitCard } from "../../../../query/payments/queries";
import { useAccount, useClientQuery } from "../../../../query/users/queries";
import { avatarBankAccount } from "../../../../shared/constants/avatars";
import { MethodType } from "../ManageMethods";
import {
  getAccountLabelProps,
  getCheckbookCardLabelProps,
  getCreditCardLabelProps,
  getInternalAccountLabelProps,
  getPaymentCardLabelProps
} from "../ManageMethods/utils";

const emptyLabel = {
  text: "Account",
  avatar: avatarBankAccount
};

export function useMethodLabelProps(id: string, type: MethodType) {
  const userId = useUserId();

  const queryAccount = useAccount(id, {
    enabled: type === "Account"
  });
  const queryInternalAccount = useQueryInternalAccount(id, {
    enabled: type === "InternalAccount"
  });
  const queryClient = useClientQuery(userId, {
    enabled: type === "CreditCard"
  });
  const queryCheckbookCard = usePayoutSettingsDebitCard(userId, id, {
    enabled: type === "CheckbookCard"
  });
  const queryPaymentCard = useQueryPaymentsCard(id, {
    enabled: type === "PaymentCard"
  });

  const isLoading = useMemo(() => {
    if (type === "Account") {
      return queryAccount.isLoading;
    }
    if (type === "InternalAccount") {
      return queryInternalAccount.isLoading;
    }

    return undefined;
  }, [type, queryAccount.isLoading, queryInternalAccount.isLoading]);

  const label = useMemo(() => {
    if (type === "Account" && queryAccount.data) {
      return getAccountLabelProps(queryAccount.data);
    }

    if (type === "InternalAccount" && queryInternalAccount.data) {
      return getInternalAccountLabelProps(queryInternalAccount.data);
    }

    if (type === "CreditCard") {
      const creditCard = queryClient.data?.profile.savedPaymentMethods?.find(
        creditCard => creditCard.paymentMethodId === id
      );

      if (creditCard) {
        return getCreditCardLabelProps(creditCard);
      }
    }

    if (type === "CheckbookCard" && queryCheckbookCard.data) {
      return getCheckbookCardLabelProps(queryCheckbookCard.data);
    }

    if (type === "PaymentCard" && queryPaymentCard.data) {
      return getPaymentCardLabelProps(queryPaymentCard.data);
    }

    return emptyLabel;
  }, [
    id,
    queryAccount.data,
    queryCheckbookCard.data,
    queryClient.data?.profile.savedPaymentMethods,
    queryInternalAccount.data,
    queryPaymentCard.data,
    type
  ]);

  return { isLoading, label };
}
