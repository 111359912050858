import {
  useModalContext,
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSModal
} from "@wingspanhq/fe-component-library";
import { IAccount, ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";
import React, { useEffect, useMemo, useState } from "react";
import { PaymentMethodType } from "../../../components/WSAccountsListV2/types";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_PAYROLL_SETTINGS } from "../../../query/payments/keys";
import {
  QUERY_USERS_ACCOUNTS,
  QUERY_USERS_CLIENT
} from "../../../query/users/keys";
import { useClientQuery } from "../../../query/users/queries";
import { paymentsService } from "../../../services/payments";
import { usersService } from "../../../services/users";
import {
  DELETE_PAYMENT_METHOD_MODAL,
  DeletePaymentMethodModal
} from "./DeletePaymentMethodModal";

export const EDIT_PAYMENT_METHOD_MODAL = "EDIT_PAYMENT_METHOD_MODAL";

interface Props {
  paymentMethod: IAccount | ICreditCard;
  paymentMethodType: PaymentMethodType;
}

const EditPaymentMethodModalBody: React.FC<Props> = ({
  paymentMethod,
  paymentMethodType
}) => {
  const userId = useUserId();
  const { closeModal, openModal } = useModalContext();
  const [defaultPMChecboxValue, setDefaultPMChecboxValue] =
    useState<boolean>(false);
  const clientQuery = useClientQuery(userId);

  const defaultPMChecboxInitialValue = useMemo(() => {
    if (paymentMethodType === PaymentMethodType.CREDIT_CARD) {
      if (
        clientQuery.data?.profile.defaultPaymentMethod?.paymentMethodId ===
        (paymentMethod as ICreditCard).paymentMethodId
      ) {
        return true;
      }
    } else {
      if (
        clientQuery.data?.profile.defaultPaymentMethod?.accountId ===
        (paymentMethod as IAccount)?.accountId
      ) {
        return true;
      }
    }

    return false;
  }, [
    clientQuery.data?.profile.defaultPaymentMethod?.paymentMethodId,
    clientQuery.data?.profile.defaultPaymentMethod?.accountId
  ]);

  useEffect(() => {
    if (defaultPMChecboxInitialValue) {
      setDefaultPMChecboxValue(defaultPMChecboxInitialValue);
    }
  }, [defaultPMChecboxInitialValue]);

  const [updatePaymentMethod, updatePaymentMethodMeta] = useWSMutation(
    async () => {
      if (defaultPMChecboxValue && !defaultPMChecboxInitialValue) {
        await usersService.client.update(userId, {
          clientId: userId,
          ...(paymentMethodType === PaymentMethodType.CREDIT_CARD
            ? {
                profile: {
                  defaultPaymentMethod: {
                    paymentMethodId: (paymentMethod as ICreditCard)
                      .paymentMethodId
                  }
                }
              }
            : {
                profile: {
                  defaultPaymentMethod: {
                    accountId: (paymentMethod as IAccount).accountId
                  }
                }
              })
        });

        await paymentsService.payrollSettings.update(userId, {
          fundingSource: null
        });

        if (paymentMethodType === PaymentMethodType.BANK_ACCOUNT) {
          await usersService.account.update(
            (paymentMethod as IAccount).accountId,
            {
              usedFor: {
                payments: true
              }
            }
          );
        }
      } else if (!defaultPMChecboxValue && defaultPMChecboxInitialValue) {
        await usersService.client.update(userId, {
          clientId: userId,
          ...(paymentMethodType === PaymentMethodType.CREDIT_CARD
            ? {
                profile: {
                  defaultPaymentMethod: {
                    paymentMethodId: null
                  }
                }
              }
            : {
                profile: {
                  defaultPaymentMethod: {
                    accountId: null
                  }
                }
              })
        });
      }
    },
    {
      dependencies: [
        QUERY_USERS_CLIENT,
        QUERY_USERS_ACCOUNTS,
        QUERY_PAYROLL_SETTINGS
      ],
      onSuccess: () => {
        closeModal(EDIT_PAYMENT_METHOD_MODAL);
      }
    }
  );

  return (
    <>
      <WSCheckboxToggle
        name="defaultPaymentMethod"
        value={defaultPMChecboxValue}
        label="Default Payment method"
        description="We'll make transactions from the default payment method"
        onChange={value => setDefaultPMChecboxValue(value)}
      />

      <WSButtons mt="XL" format="modal">
        <WSButton.Primary
          loading={updatePaymentMethodMeta.isLoading}
          onClick={updatePaymentMethod}
        >
          Update
        </WSButton.Primary>
        <WSButton.Secondary
          onClick={() => {
            closeModal(EDIT_PAYMENT_METHOD_MODAL);
          }}
        >
          Cancel
        </WSButton.Secondary>
      </WSButtons>

      <WSButton.Secondary
        destructive
        mt="XL"
        onClick={() => {
          openModal(DELETE_PAYMENT_METHOD_MODAL, {
            paymentMethod,
            paymentMethodType,
            defaultPaymentMethod: clientQuery.data?.profile.defaultPaymentMethod
          });
        }}
      >
        Delete payment method
      </WSButton.Secondary>
    </>
  );
};

export const EditPaymentMethodModal: React.FC = () => {
  const { closeModal } = useModalContext();
  return (
    <>
      <DeletePaymentMethodModal
        onSuccess={() => {
          closeModal(EDIT_PAYMENT_METHOD_MODAL);
        }}
      />
      <WSModal
        size="XS"
        name={EDIT_PAYMENT_METHOD_MODAL}
        title="Edit payment method"
      >
        {(props: Props) => <EditPaymentMethodModalBody {...props} />}
      </WSModal>
    </>
  );
};
