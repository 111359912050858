import {
  AuthorizedAction,
  IAuthorizationCreateRequest,
  IUserCreateRequest,
  ScopeModificationAction,
  ScopeModificationComparator,
  SubscriptionPackage,
  UserStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useCreateSubscriptionGrant } from "../../../../query/subscriptions/mutations";
import {
  useCreateAuthorization,
  useCreateUser
} from "../../../../query/users/mutations";
import { usersService } from "../../../../services/users";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { ScopeGroupId, scopeGroups } from "../../../../shared/utils/teamUtils";
import { SUBSCRIPTION_SELECT_OPTIONS } from "../../constants/subscriptionOptions";
import { FormTeamMember } from "../../components/FormTeamMember";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useAllOrganizationUsers } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { queryCache } from "react-query";
import { QUERY_AUTHORIZATIONS } from "../../../../query/users/keys";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { WSIcon } from "@wingspanhq/fe-component-library";

interface AddTeamMemberProps {}

export const TeamAddMember: React.FC<AddTeamMemberProps> = () => {
  const history = useHistory<{ permission: ScopeGroupId }>();

  const permissions = (
    history.location.state?.permission &&
    scopeGroups[history.location.state?.permission]
      ? { [history.location.state.permission]: true }
      : {}
  ) as { [key in ScopeGroupId]: boolean };

  const [createAuthorization, createAuthorizationMeta] =
    useCreateAuthorization();
  const [createUser, createUserMeta] = useCreateUser();
  const [createSubscriptionGrant, createSubscriptionGrantMeta] =
    useCreateSubscriptionGrant();

  const goBack = useMemo(
    () => () => history.push("/member/settings/team"),
    [history]
  );

  const queryOrganizationUsers = useAllOrganizationUsers();

  return (
    <LayoutFullscreen
      headTitle="Add team member"
      onBack={goBack}
      headerRight={
        <WSIcon block size="XS" name="exit" onClick={goBack} color="gray600" />
      }
    >
      <WSQueries
        queries={{
          queryOrganizationUsers
        }}
      >
        {({ queryOrganizationUsersData }) => {
          const hasOrganizations = queryOrganizationUsersData.length > 0;

          return (
            <>
              <FormTeamMember
                title={"Add team member"}
                hasOrganizations={hasOrganizations}
                submitButtonLabel={"Add member"}
                onBack={goBack}
                onSubmit={async data => {
                  createAuthorizationMeta.reset();
                  createUserMeta.reset();

                  let request: IAuthorizationCreateRequest = {
                    allowedAction: AuthorizedAction.Write,
                    requestingUserId: ""
                  };

                  if (data.email) {
                    try {
                      const userByEmailRes = await usersService.user.email.get(
                        data.email
                      );
                      request.requestingUserId = userByEmailRes.userId;
                    } catch (err) {
                      if ((err as WSServiceError).response?.status === 404) {
                        const payload: IUserCreateRequest = {
                          email: data.email,
                          status: UserStatus.Pending,
                          notificationSettings: {
                            reviewNotifications: true,
                            newsletters: true
                          }
                        };
                        if (data.firstName && data.lastName) {
                          payload.profile = {
                            firstName: data.firstName,
                            lastName: data.lastName
                          };
                        }
                        const newUser = await createUser(payload, {
                          throwOnError: true
                        });
                        request.requestingUserId = newUser?.userId as string;
                      }
                    }
                  }
                  if (request.requestingUserId) {
                    const newScopeGroups = Object.keys(data.permissions).filter(
                      key => data.permissions[key as ScopeGroupId]
                    ) as Array<ScopeGroupId>;

                    await Promise.all(
                      newScopeGroups.map(async (scopeGroupId: string) => {
                        const payload = {
                          ...request,
                          allowedScopeGroupId: scopeGroupId
                        };
                        await createAuthorization(payload);
                      })
                    );

                    if (hasOrganizations) {
                      const payload: IAuthorizationCreateRequest = {
                        ...request,
                        allowedScope: "users.organizationAccount", // <-- MUST BE users.organizationAccount
                        allowedAction: AuthorizedAction.Write,
                        scopeModifications: {
                          users: {
                            organizationAccount: [
                              {
                                action: ScopeModificationAction.Include,
                                attribute: "userRoles.ownerIds",
                                value:
                                  data.selectedOrganizations.sort().join(",") ||
                                  "*",
                                comparator: ScopeModificationComparator.Includes
                              }
                            ]
                          }
                        }
                      };
                      await createAuthorization(payload);
                    }
                  }

                  /**
                   * We don't grant subscription if None is selected. `None` option can be
                   * used to delete the given subscription-grant.
                   */
                  const selectedSubscriptionOption =
                    SUBSCRIPTION_SELECT_OPTIONS.find(
                      op => op.title === data.subscription
                    )!;

                  if (
                    selectedSubscriptionOption.value.package !==
                    SubscriptionPackage.None
                  ) {
                    await createSubscriptionGrant({
                      granteeId: request.requestingUserId,
                      package: selectedSubscriptionOption.value.package,
                      packageTier: selectedSubscriptionOption.value.packageTier
                    });
                  }

                  queryCache.removeQueries(QUERY_AUTHORIZATIONS);

                  goBack();
                }}
                defaultValues={{
                  email: "",
                  firstName: "",
                  lastName: "",
                  subscription: SUBSCRIPTION_SELECT_OPTIONS[0].title,
                  selectedOrganizations: [],
                  permissions
                }}
              />
              <WSErrorMessage
                my="XL"
                contextKey="Other"
                forceShowApiErrors
                error={
                  createUserMeta.error ||
                  createAuthorizationMeta.error ||
                  createSubscriptionGrantMeta.error
                }
              />
            </>
          );
        }}
      </WSQueries>
    </LayoutFullscreen>
  );
};
