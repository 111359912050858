import {
  useWSModal,
  WSCard,
  WSDataItemProps,
  WSIconProps,
  WSInfoBox,
  WSPillProps
} from "@wingspanhq/fe-component-library";
import { IMember, INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { components } from "../../../services/api/onboarding/types";
import { DataView } from "../../../shared/components/DataView";
import { selectorAccountType } from "../../../shared/selectors/selectorAccountType";
import { selectorCountryName } from "../../../shared/selectors/selectorCountryName";
import { selectorTaxClassificationLabel } from "../../../shared/selectors/selectorTaxClassificationLabel";
import {
  PATH_ONBOARDING_CHANGE,
  PATH_ONBOARDING_VERIFICAIONS_TAX
} from "../../Onboarding";
import { getTaxIdLabel } from "../../Onboarding/utils/taxId";

type Props = {
  monitoredEntity?: components["schemas"]["CustomerEntity"];
  user: INewUser;
  member: IMember;
  verificationStatus?: components["schemas"]["VerificationStatus"];
};

function getVerificationStatusPill(
  status: components["schemas"]["VerificationStatus"]
): WSPillProps {
  if (status === "Verified") {
    return {
      theme: "success",
      icon: true,
      text: "Verified"
    };
  }

  if (status === "Pending") {
    return {
      theme: "warning",
      icon: true,
      text: "Verification pending"
    };
  }

  if (status === "UpdateRequired") {
    return {
      theme: "warning",
      icon: true,
      text: "Update required"
    };
  }

  if (status === "Failed") {
    return {
      theme: "error",
      icon: true,
      text: "Failed"
    };
  }

  return {
    theme: "neutral",
    text: "Not verified"
  };
}

const verifiedIcon: WSIconProps = {
  name: "check-circle",
  color: "green400"
};

export const DataViewTaxpayerInformation: React.FC<Props> = ({
  monitoredEntity,
  user,
  member,
  verificationStatus
}) => {
  const history = useHistory();
  const modalInfo = useWSModal(ModalInfo, {
    size: "S",
    style: { padding: 0, borderRadius: 8 }
  });

  const country = useMemo(
    () => monitoredEntity?.country || member.profile.address?.country || "US",
    [member.profile.address?.country, monitoredEntity?.country]
  );

  const accountType = useMemo(
    () => selectorAccountType(monitoredEntity, member),
    [member, monitoredEntity]
  );

  const isVerified = useMemo(
    () => verificationStatus === "Verified",
    [verificationStatus]
  );

  const nameItems = useMemo((): WSDataItemProps[] => {
    if (accountType === "Business") {
      return [
        {
          label: "Legal business name",
          value: member.profile.company?.legalBusinessName,
          icon: isVerified ? verifiedIcon : undefined
        }
      ];
    }

    return [
      {
        label: "Legal first name",
        value: user.profile.firstName,
        icon: isVerified ? verifiedIcon : undefined
      },
      {
        label: "Legal last name",
        value: user.profile.lastName,
        icon: isVerified ? verifiedIcon : undefined
      }
    ];
  }, [
    accountType,
    isVerified,
    member.profile.company?.legalBusinessName,
    user.profile.firstName,
    user.profile.lastName
  ]);

  return (
    <DataView
      header={{
        label: "Taxpayer information",
        value: {
          action: {
            label: "Request update",
            onClick: () => {
              if (!verificationStatus || verificationStatus === "None") {
                history.push(PATH_ONBOARDING_VERIFICAIONS_TAX);
              } else if (verificationStatus === "Verified") {
                history.push(PATH_ONBOARDING_CHANGE);
              } else {
                modalInfo.open({
                  onContinue: () => {
                    history.push(PATH_ONBOARDING_CHANGE);
                  }
                });
              }
            }
          },
          pill: verificationStatus
            ? getVerificationStatusPill(verificationStatus)
            : undefined
        }
      }}
      data={[
        {
          label: "Country of tax residency",
          value: selectorCountryName(country),
          icon: isVerified ? verifiedIcon : undefined
        },
        {
          label: "Business type",
          value: accountType,
          icon: isVerified ? verifiedIcon : undefined
        },
        {
          label: "Federal tax classification",
          value:
            accountType === "Individual"
              ? "Sole proprietorship / Single member LLC"
              : member.profile.company?.structure
              ? selectorTaxClassificationLabel(member.profile.company.structure)
              : undefined,
          icon: isVerified ? verifiedIcon : undefined
        },
        {
          label: getTaxIdLabel(accountType, country),
          value: "•••••••••",
          icon: isVerified ? verifiedIcon : undefined,
          alert:
            country === "US" && isVerified
              ? {
                  theme: "success",
                  icon: "check-circle",
                  title: "IRS verified"
                }
              : undefined
        },
        ...nameItems
      ]}
    />
  );
};

const ModalInfo: React.FC<{
  onClose: () => void;
  onContinue: () => void;
}> = props => (
  <WSCard
    divider
    header="Request update to taxpayer information"
    actions={{
      alignment: "fill",
      buttons: [
        {
          kind: "Secondary",
          label: "Cancel",
          onClick: props.onClose
        },
        {
          label: "Continue",
          onClick: props.onContinue
        }
      ]
    }}
  >
    <WSInfoBox title="What to expect:">
      <ol>
        <li>
          You will need to complete and submit your updated information to be
          re-verified.
        </li>
        <li>Your previous information will be discarded.</li>
        <li>
          Your client(s) will be informed of any tax-related changes to your
          profile for compliance and tax reporting purposes.
        </li>
      </ol>
    </WSInfoBox>
  </WSCard>
);
