import {
  WSElementProps,
  WSPageToolbar
} from "@wingspanhq/fe-component-library";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useCreateContractorModal } from "../../components/CreateContractorModal/useCreateContractorModal";
import { useAddCollaboratorGroupModal } from "../../components/AddCollaboratorGroupModal";
import { buildPayeeDetailsPath, PAYEES_ROOT_PATH } from "../../utils";
import { useOpenModalBySearchParam } from "../../../../utils/useOpenModalBySearchParam";
import { useRedirectToByKeyPress } from "../../../../utils/useRedirectToByKeyPress";
import { KEYBOARD_SHORTCUT_SEARCH } from "../../../../constants/keyboardShortcuts";
import { useCustomization } from "../../../customization";
import { plural } from "../../../../shared/utils/plural";
import { useUserProfile } from "../../../../query/users/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { useCreateEngagementModal } from "../../components/CreateEngagementModal";
import { useIsEngagementsActive } from "../../../../shared/hooks/useIsEngagementsActive";

export const DashboardHeader: React.FC<WSElementProps> = props => {
  const { terminology } = useCustomization();
  const history = useHistory();
  const modalCreateContractor = useCreateContractorModal();
  const modalAddCollaboratorGroup = useAddCollaboratorGroupModal();
  const modalCreateEngagement = useCreateEngagementModal();
  const isEngagementsActive = useIsEngagementsActive();

  useOpenModalBySearchParam("add_contractor", modalCreateContractor);
  useOpenModalBySearchParam("add_group", modalAddCollaboratorGroup);

  const onAddContractor = useCallback(async () => {
    const result = await modalCreateContractor.open();

    if (result) {
      history.push(buildPayeeDetailsPath(result.payeeId));
    }
  }, [modalCreateContractor, history]);

  useRedirectToByKeyPress(
    KEYBOARD_SHORTCUT_SEARCH,
    `${PAYEES_ROOT_PATH}/search`
  );

  return (
    <WSPageToolbar
      title={plural(terminology().sendPaymentsContractor)}
      actions={[
        {
          label: "Search",
          icon: "search",
          buttonKind: "Tertiary",
          hideButtonOn: false,
          buttonOrder: 0,
          onClick: () => history.push(`${PAYEES_ROOT_PATH}/search`)
        },
        isEngagementsActive
          ? {
              label: "Add engagement",
              buttonKind: "Secondary",
              icon: "plus",
              hideButtonOn: ["XS", "S"],
              async onClick() {
                await modalCreateEngagement.open();
              }
            }
          : {
              label: "Add group",
              buttonKind: "Secondary",
              icon: "plus",
              hideButtonOn: ["XS", "S"],
              async onClick() {
                await modalAddCollaboratorGroup.open();
              }
            },
        {
          label: "Add contractor",
          icon: "plus",
          buttonKind: "Primary",
          onClick: onAddContractor
        },
        {
          label: "Bulk upload contractors",
          icon: "upload",
          onClick() {
            history.push(
              "/member/invoices/contacts/collaborators/bulk-create/info"
            );
          }
        },
        {
          separator: true
        },
        {
          label: "View archived contractors",
          icon: "archive",
          onClick() {
            history.push(`${PAYEES_ROOT_PATH}/archived`);
          }
        }
        // {
        //   label: "View archived engagements",
        //   icon: "archive",
        //   onClick() {
        //     alert("TODO");
        //     // history.push("/member/contractors/add-contractor");
        //   }
        // }
      ]}
    />
  );
};
