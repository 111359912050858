import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import { getCountryName } from "../../query/users/selectors";

export const selectorAddressString = (
  address: IAddress | undefined,
  separator: string = ", "
): string => {
  const { addressLine1, addressLine2, state, postalCode, city, country } =
    address || {};

  const stateAddress = [city ? `${city},` : null, state, postalCode]
    .filter(Boolean)
    .join(" ");

  const countryName =
    country && getCountryName(country) ? getCountryName(country) : null;

  return [addressLine1, addressLine2, stateAddress, countryName]
    .filter(Boolean)
    .join(separator);
};
