import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { AccountStatus, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useCreateAccount } from "../../../../query/users/mutations";
import { StepLayoutWithOptions } from "../../../../shared/components/Layout/StepLayoutWithOptions";
import {
  avatarBankAccount,
  avatarBankCard
} from "../../../../shared/constants/avatars";
import { useMemberPlaidLink } from "../../../../utils/usePlaidLink";
import { selectorAccountDepositReady } from "../../../Transfer/components/FormTransfer/selectorAccountDepositReady";
import { FormAddCreditCard } from "../FormAddCreditCard";
import { FormSelectAccount } from "../FormSelectAccount";

type Props = { basePath: string; onBack?: () => void; onNext?: () => void };

export const FlowAddPaymentMethod: React.FC<Props> = ({
  basePath,
  onBack,
  onNext
}) => {
  const history = useHistory();
  const [createAccount] = useCreateAccount();
  const [createdAccounts, setCreatedAccounts] = useState<IAccount[]>([]);

  const { openSnackbar } = useWSSnackbar();

  const plaidHandler = useMemberPlaidLink({
    onSuccess: async (publicToken: string) => {
      const createResponse = await createAccount({
        publicToken,
        status: AccountStatus.Pending
      });

      let accounts = createResponse
        ? Array.isArray(createResponse)
          ? createResponse
          : [createResponse]
        : [];

      const filteredAccounts = accounts.filter(selectorAccountDepositReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/accounts");

        return {
          success: true,
          data: accounts
        };
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payroll funding method"
        });

        return {
          success: false
        };
      }
    }
  });

  return (
    <Switch>
      <Route path={basePath + "/type"}>
        <StepLayoutWithOptions<"plaid" | "credit">
          key="type"
          title="Add payment method"
          options={[
            {
              header: {
                label: {
                  text: "Bank account",
                  helperText: "Connect your bank account securely",
                  avatar: avatarBankAccount
                }
              },
              value: "plaid"
            },
            {
              header: {
                label: {
                  text: "Credit card",
                  helperText: "Credit card",
                  avatar: avatarBankCard
                }
              },
              value: "credit"
            }
          ]}
          onBack={onBack}
          onNext={value => {
            if (value === "plaid") {
              plaidHandler.open();
            } else {
              history.push(basePath + "/credit");
            }
          }}
        />
      </Route>

      <Route path={basePath + "/credit"}>
        <FormAddCreditCard
          setPaymentMethod
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onNext={onNext}
        />
      </Route>

      <Route path={basePath + "/accounts"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your default payroll funding method"
          setPaymentMethod
          accounts={createdAccounts}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onNext={onNext}
        />
      </Route>

      <Redirect path={basePath} to={basePath + "/type"} />
    </Switch>
  );
};
