import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { PayoutPreferences } from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import { queryCache } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import { addNotification } from "../../components/Notification/Notification";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { QUERY_KEY_INVOICES_ROWS } from "../../modules/Invoicing/queries/useQueryInvoicesRows";
import { WSQueries } from "../../query/WSQuery";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import {
  QUERY_INVOICE,
  QUERY_INVOICES,
  QUERY_PAID_INVOICES,
  QUERY_PAYOUT_SETTINGS
} from "../../query/payments/keys";
import {
  useInvoiceQuery,
  usePayoutSettings
} from "../../query/payments/queries";
import { paymentsService } from "../../services/payments";
import { ErrorContextKey } from "../../services/platform";
import { FormAddDebitCard } from "../../shared/components/FormAddDebitCard";
import { selectorInstantPayoutDefaultFee } from "../../shared/selectors/selectorInstantPayoutDefaultFee";
import { instantPayoutService } from "../../shared/services/instantPayout";
import { track } from "../../utils/analytics";
import { WSServiceError } from "../../utils/serviceHelper";

type InstantPayoutsProps = RouteComponentProps<{
  invoiceId: string;
}>;

export const InstantPayoutsModal: React.FC<InstantPayoutsProps> = ({
  match,
  history,
  location
}) => {
  const onBack = () => {
    history.goBack();
  };
  const userId = useUserId();

  const invoiceId = match.params.invoiceId;
  const invoiceQuery = useInvoiceQuery(invoiceId);
  const queryPayoutSettings = usePayoutSettings(userId);

  const [submit, submitMeta] = useWSMutation<
    void,
    WSServiceError,
    { enableInstantDeposits?: boolean }
  >(
    async ({ enableInstantDeposits }) => {
      track("Invoice Instant Deposit Requested", {
        invoiceId: invoiceId
      });

      await paymentsService.invoice.update(invoiceId, {
        member: {
          payoutPreferences: PayoutPreferences.Instant
        }
      });

      if (enableInstantDeposits) {
        await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Instant
        });
      }
    },
    {
      onSuccess: () => {
        history.goBack();

        addNotification({
          text: "Instant payout successfully requested"
        });

        queryCache.invalidateQueries(QUERY_INVOICES);
        queryCache.invalidateQueries(QUERY_PAID_INVOICES);
        queryCache.invalidateQueries(QUERY_KEY_INVOICES_ROWS);
        queryCache.invalidateQueries([QUERY_INVOICE, invoiceId]);
        queryCache.invalidateQueries(QUERY_PAYOUT_SETTINGS);
      }
    }
  );

  const invoiceIsDeposited = !!invoiceQuery.data?.events.depositedAt;

  return (
    <WSModal
      size="S"
      title={
        invoiceIsDeposited
          ? `Invoice #${invoiceQuery.data?.invoiceNumber}`
          : `Instant payout for invoice #${
              invoiceQuery.data?.invoiceNumber || "###"
            }`
      }
      onClose={onBack}
    >
      {() => (
        <WSQueries queries={{ invoiceQuery, queryPayoutSettings }}>
          {({
            invoiceQuery: { data: invoice },
            queryPayoutSettings: { data: payoutSettings }
          }) => {
            const invoiceAmount = Math.abs(
              invoice.amountDetails?.memberGross || 0
            );
            const instantPayoutFee =
              instantPayoutService.calculateInvoiceInstantPayoutFee(
                Math.abs(invoiceAmount),
                payoutSettings
              );
            const instantPayoutAmount = invoiceAmount - instantPayoutFee;
            const hasInstantPayoutAccount =
              payoutSettings.payoutDestinations.find(
                d => d.payoutMethod === PayoutPreferences.Instant
              );

            if (invoiceIsDeposited) {
              return (
                <>
                  <WSText.Heading5 my="2XL">
                    This invoice has already been deposited
                  </WSText.Heading5>
                  <WSButtons forceFullWidth mt="2XL">
                    <WSButton.Secondary onClick={onBack}>
                      Close
                    </WSButton.Secondary>
                  </WSButtons>
                </>
              );
            }

            return hasInstantPayoutAccount ? (
              <>
                <WSText my="2XL">
                  Instant payout requests are generally processed within 60
                  minutes.
                </WSText>
                <WSElement>
                  <WSFlexBox.CenterY mt="M" justify="space-between">
                    <WSText.ParagraphSm weight="medium">
                      Invoice number
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm>
                      {invoice.invoiceNumber}
                    </WSText.ParagraphSm>
                  </WSFlexBox.CenterY>
                  <WSDivider my="M" />
                  <WSFlexBox.CenterY justify="space-between">
                    <WSText.ParagraphSm weight="medium">
                      Invoice amount
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm formatMoney="default">
                      {invoiceAmount}
                    </WSText.ParagraphSm>
                  </WSFlexBox.CenterY>
                  <WSDivider my="M" />
                  <WSFlexBox.CenterY justify="space-between">
                    <WSText.ParagraphSm weight="medium">
                      Instant payout fee (
                      {selectorInstantPayoutDefaultFee(payoutSettings)}%)
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm formatMoney="default">
                      {instantPayoutFee}
                    </WSText.ParagraphSm>
                  </WSFlexBox.CenterY>
                  <WSDivider my="M" />
                  <WSFlexBox.CenterY justify="space-between">
                    <WSText.ParagraphSm weight="medium">
                      Instant payout amount
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm formatMoney="default">
                      {instantPayoutAmount}
                    </WSText.ParagraphSm>
                  </WSFlexBox.CenterY>
                  <WSDivider my="M" />
                </WSElement>

                <WSFormOld
                  mt="2XL"
                  defaultValues={{
                    // TODO(artemk): show "use as default payment method" checkbox only when it's not default paymetn method
                    instantDeposits: false
                  }}
                  onSubmit={async formData => {
                    await submit({
                      enableInstantDeposits: formData.instantDeposits
                    });
                  }}
                >
                  <WSFormOld.Field
                    name="instantDeposits"
                    component={WSCheckboxToggle}
                    componentProps={{
                      label:
                        "Make instant payouts my default for all future payments"
                    }}
                  />

                  <WSErrorMessage
                    mt="2XL"
                    contextKey={ErrorContextKey.Other}
                    error={submitMeta.error}
                  />

                  <WSButtons forceFullWidth mt="2XL">
                    <WSFormOld.SubmitButton>
                      Confirm instant payout request
                    </WSFormOld.SubmitButton>
                    <WSButton.Secondary type="button" onClick={onBack}>
                      Cancel
                    </WSButton.Secondary>
                  </WSButtons>
                </WSFormOld>
              </>
            ) : (
              <>
                <WSText mb="XL">
                  Instant payouts are sent over the debit card network. To
                  receive instant payouts, please provide the details for the
                  debit card associated to your payout account.
                </WSText>
                <FormAddDebitCard updateInstantDestinaiton />
                <WSButton.Secondary mt="M" onClick={onBack} fullWidth>
                  Cancel
                </WSButton.Secondary>
              </>
            );
          }}
        </WSQueries>
      )}
    </WSModal>
  );
};
