import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useWSMutation } from "../../helpers";
import { pdf } from "@react-pdf/renderer";
import { IMember } from "@wingspanhq/users";
import { getStatement } from "../../../services/api/banking/statements";
import { queryCache } from "react-query";
import { QUERY_STATEMENT } from "../keys";
import { IStatementResponse } from "../../../services/api/banking/statements/types";
import {
  StatementPDFDocument,
  StatementPDFDocumentProps
} from "../../../modules/Statements/components/StatementPDFDocument/StatementPDFDocument";
import {
  BankingInternalAccount,
  getInternalAccount
} from "../../../services/api/banking/internalAccounts";
import { QUERY_INTERNAL_ACCOUNT } from "../../banking/queries/useQueryInternalAccount";
import { accountTypeToHumanReadable } from "../../../modules/Statements/utils/accountTypeToHumanReadable";

export const useDownloadBankStatements = () => {
  const { openSnackbar } = useWSSnackbar();
  return useWSMutation(
    async ({
      statementId,
      member
    }: {
      statementId: string;
      member: IMember;
    }) => {
      const cachedStatementData = queryCache.getQueryData<IStatementResponse>([
        QUERY_STATEMENT,
        statementId
      ]);

      let statement: IStatementResponse;

      if (cachedStatementData) {
        statement = cachedStatementData;
      } else {
        statement = await getStatement(statementId);
        queryCache.setQueryData([QUERY_STATEMENT, statementId], statement);
      }

      let internalAccount: BankingInternalAccount;

      const cachedInternalAccount =
        queryCache.getQueryData<BankingInternalAccount>([
          QUERY_INTERNAL_ACCOUNT,
          statement.internalAccountId
        ]);

      if (cachedInternalAccount) {
        internalAccount = cachedInternalAccount;
      } else {
        internalAccount = await getInternalAccount(statement.internalAccountId);

        queryCache.setQueryData(
          [QUERY_INTERNAL_ACCOUNT, statement.internalAccountId],
          internalAccount
        );
      }

      const pdfData: StatementPDFDocumentProps = {
        statement,
        internalAccount,
        member
      };

      try {
        const blob = await pdf(<StatementPDFDocument {...pdfData} />).toBlob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${accountTypeToHumanReadable(
          statement.internalAccountType
        )} Statement (${pdfData.statement.startDateString}-${
          pdfData.statement.endDateString
        }).pdf`;
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        openSnackbar({ type: "success", message: "Statement downloaded" });
      } catch (error) {
        openSnackbar({
          type: "error",
          message: "Statement downloading is failed"
        });
      }
    }
  );
};
