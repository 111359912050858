import {
  useIsDesktop,
  useIsMobile,
  WSAvatar,
  WSDateRangeSelectOptions,
  WSElement,
  WSFlexBox,
  WSLoader,
  WSMessageBox,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { Tabs } from "../../../components/Tabs";
import { TransactionParams } from "../../../query/bookkeeping/queries";
import { getAllTransactionsSyncCompleted } from "../../../query/bookkeeping/selectors";
import { useUserId } from "../../../query/hooks/helpers";
import { useAccounts } from "../../../query/users/queries";
import { HeroRangeSelect } from "../../../shared/components/HeroRangeSelect/HeroRangeSelect";
import { FINANCES_SCOPE_GROUP_ID } from "../../../shared/utils/teamUtils";
import { useUrlQueryFilters } from "../../../utils/router";
import { DEFAULT_FILTER_RANGE, isValidDateRange } from "../../utils";
import { SuccessOnboarding } from "../modals/SuccessOnboarding";
import { BookkeepingDashboardV2 } from "./BookkeepingDashboardV2";
import { BookkeepingProfitAndLoss } from "./BookkeepingProfitAndLoss";
import { BookkeepingReports } from "./BookkeepingReports";
import styles from "./BookkeepingTransactions.module.scss";
import {
  BookkeepingTransactionsV2,
  createTransactionParams
} from "./BookkeepingTransactionsV2";

import { CURRENT_TAX_DOCUMENTS_YEAR } from "../../../shared/utils/taxes";

export type DefaultBookkeepingFilters = TransactionParams & { search: string };

export const defaultBookkeepingFilters: DefaultBookkeepingFilters = {
  ...createTransactionParams(),
  search: "",
  customDateRange: DEFAULT_FILTER_RANGE
};

export const useBookkeepingFiltersYear = () => {
  const { filters } = useUrlQueryFilters<DefaultBookkeepingFilters>(
    defaultBookkeepingFilters
  );

  const dateRange = isValidDateRange(filters.customDateRange)
    ? filters.customDateRange
    : WSDateRangeSelectOptions.LastYear.range;

  const currentYear = dateRange && dateRange[0]?.getFullYear();

  return currentYear || CURRENT_TAX_DOCUMENTS_YEAR;
};

export const useBookkeepingFilters = () =>
  useUrlQueryFilters<DefaultBookkeepingFilters>(defaultBookkeepingFilters);

export const BookkeepingIndex: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const userId = useUserId();

  const { filters, setFilters, changedFilters } = useBookkeepingFilters();

  const { search, ...params } = filters;

  const [showBadge, setShowBadge] = useState(
    !window.localStorage.getItem(`${userId}-BK-Share`)
  );

  const [isSyncing, setIsSyncing] = useState(false);
  const [isSynced, setIsSynced] = useState(false);

  useAccounts({
    refetchInterval: isSynced ? false : 5000,
    onSuccess(accounts) {
      const isCompleted = getAllTransactionsSyncCompleted(accounts);
      setIsSyncing(!isCompleted);
      setIsSynced(isCompleted);
    }
  });

  const dateRange = (
    isValidDateRange(filters.customDateRange)
      ? filters.customDateRange
      : WSDateRangeSelectOptions.LastYear.range
  ) as any;

  const badge = showBadge && (
    <WSFlexBox.CenterY
      wrap="nowrap"
      justify="space-between"
      className={styles.notificationPanel}
      mr={isMobile ? "NONE" : "XL"}
      my="M"
      py="XS"
      px="M"
    >
      <WSText
        onClick={() => {
          history.push("/member/settings/team/add-team-member", {
            permission: FINANCES_SCOPE_GROUP_ID
          });
        }}
        color="blue500"
      >
        {isMobile
          ? "Share access with your accountant."
          : "Share access with your accountant or team."}
      </WSText>
      <WSAvatar.Icon
        icon="exit"
        size="S"
        ml="M"
        colorBackground="gray50"
        onClick={() => {
          setShowBadge(false);
          window.localStorage.setItem(`${userId}-BK-Share`, "true");
        }}
      />
    </WSFlexBox.CenterY>
  );

  return (
    <>
      <SuccessOnboarding />
      {isSyncing ? (
        <WSMessageBox.Regular centered noBorder>
          <WSFlexBox.Center>
            <WSLoader.Spinner />
            <WSText ml="M">Transaction sync in progress</WSText>
          </WSFlexBox.Center>
        </WSMessageBox.Regular>
      ) : null}
      <WSPage
        title="Income & Expenses"
        sideContent={
          <WSFlexBox.CenterY>
            {isDesktop ? badge : null}
            <HeroRangeSelect
              position={isMobile ? "right" : "left"}
              customLabel={
                filters.customDateRange?.[0] === DEFAULT_FILTER_RANGE[0]
                  ? `${DEFAULT_FILTER_RANGE[0].getFullYear()}`
                  : undefined
              }
              onChange={data => {
                setFilters({
                  ...filters,
                  dateRange: "CUSTOM",
                  customDateRange: data.value as any
                });
              }}
              value={dateRange}
            />
          </WSFlexBox.CenterY>
        }
      >
        {isDesktop ? null : badge}
        <Tabs
          tabs={[
            {
              label: "Dashboard",
              path: "/member/bookkeeping/dashboard",
              params: changedFilters
            },
            {
              label: "Profit & Loss",
              path: "/member/bookkeeping/profit-and-loss",
              params: changedFilters
            },
            {
              label: "Transactions",
              path: "/member/bookkeeping/transactions",
              params: changedFilters
            },
            {
              label: "Reports",
              path: "/member/bookkeeping/reports",
              params: changedFilters
            }
          ]}
        />
        <WSElement pt="2XL">
          <Switch>
            <Route
              component={BookkeepingDashboardV2}
              path="/member/bookkeeping/dashboard"
            />
            <Route
              component={BookkeepingProfitAndLoss}
              path="/member/bookkeeping/profit-and-loss"
            />
            <Route
              component={BookkeepingTransactionsV2}
              path="/member/bookkeeping/transactions"
            />
            <Route
              component={BookkeepingReports}
              path="/member/bookkeeping/reports"
            />
            <Redirect
              from="/member/bookkeeping"
              to="/member/bookkeeping/dashboard"
            />
          </Switch>
        </WSElement>
      </WSPage>
    </>
  );
};
