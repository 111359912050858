import cn from "classnames";
import React, { useMemo, useState } from "react";
import { useIsMounted } from "../../../../utils/useIsMounted";
import { WSElement, WSElementProps } from "../../WSElement/WSElement.component";
import { WSBadge, WSBadgeProps } from "../../common/WSBadge/WSBadge.component";
import { WSIcon, WSIconName, WSIconSize } from "../WSIcon/WSIcon.component";
import { WSText } from "../WSText/WSText.component";
import styles from "./WSButton.module.scss";
import { toCamelCase } from "../../../../utils";

export type WSButtonDestcurtiveKind = "Primary" | "Secondary";
export type WSButtonNonDestcurtiveKind = "Tertiary" | "Link";

export type WSButtonKind = WSButtonDestcurtiveKind | WSButtonNonDestcurtiveKind;

const DEFAULT_KIND: WSButtonKind = "Primary";

export type WSButtonSize = "S" | "M" | "L" | "XL";

const DEFAULT_SIZE: WSButtonSize = "M";

const getBadgeProps = (kind: WSButtonKind, buttonSize: WSButtonSize) => {
  const theme: WSBadgeProps["theme"] = kind === "Primary" ? "neutral" : "dark";
  const size: WSBadgeProps["size"] = ["S", "M"].includes(buttonSize)
    ? "S"
    : "M";

  return { theme, size };
};

const getKindClassName = (kind: WSButtonKind, destructive?: boolean) =>
  destructive ? styles[`${kind}--destructive`] : styles[kind];

const iconSizes: Record<
  WSButtonSize,
  { sideIconSize: WSIconSize; iconOnlySize: WSIconSize }
> = {
  S: { sideIconSize: "S", iconOnlySize: "S" },
  M: { sideIconSize: "S", iconOnlySize: "S" },
  L: { sideIconSize: "M", iconOnlySize: "L" },
  XL: { sideIconSize: "M", iconOnlySize: "L" }
};

export interface WSButtonProps<K extends WSButtonKind = WSButtonKind>
  extends Omit<WSElementProps<HTMLButtonElement>, "color" | "colorBackground"> {
  kind?: K;
  destructive?: K extends WSButtonDestcurtiveKind ? boolean : never;
  size?: WSButtonSize;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  textClassName?: string;
  type?: string;
  icon?: WSIconName;
  rightIcon?: WSIconName;
  badgeNumber?: number;
  loading?: boolean;
  onAsyncClick?: () => Promise<void>;
  name?: string;
  label?: string;
}

const getClassNames = ({
  kind,
  size,
  loading,
  destructive,
  disabled,
  fullWidth,
  className
}: WSButtonProps) => {
  const sizeClassName = styles[`buttonSize-${size || DEFAULT_SIZE}`];
  const kindClassName = getKindClassName(kind || "Primary", destructive);

  const classNames = cn(
    styles.button,
    kindClassName,
    sizeClassName,
    loading && styles.loading,
    !loading && disabled && styles.disabled,
    fullWidth && styles.fullWidth,
    className
  );

  return classNames;
};

function Button<K extends WSButtonKind = WSButtonKind>(
  props: WSButtonProps<K>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const isMounted = useIsMounted();

  const {
    kind = DEFAULT_KIND,
    destructive,
    size = DEFAULT_SIZE,
    icon,
    badgeNumber,
    rightIcon,
    loading,
    disabled,
    fullWidth,
    onClick,
    onAsyncClick,
    textClassName,
    children,
    className,
    name,
    label,
    type,
    ...componentProps
  } = props;

  const hasChildren = !!children || !!label;

  const isLoading = !!children && (loading || isAsyncLoading);
  const isDisabled = disabled || isLoading;

  const classNames = useMemo(
    () =>
      getClassNames({
        ...props,
        kind: kind,
        loading: isLoading
      }),
    [isLoading, kind, props]
  );

  const clickHandler = useMemo(
    () =>
      !onAsyncClick
        ? onClick
        : async () => {
            setIsAsyncLoading(true);
            try {
              await onAsyncClick?.();
              if (isMounted()) {
                setIsAsyncLoading(false);
              }
            } catch (error) {
              if (isMounted()) {
                setIsAsyncLoading(false);
              }
              throw error;
            }
          },
    [onAsyncClick, onClick, isMounted]
  );

  const { sideIconSize, iconOnlySize } = iconSizes[size];

  const badgeProps: WSBadgeProps | false = useMemo(
    () =>
      typeof badgeNumber === "number" && {
        number: badgeNumber,
        ...getBadgeProps(kind, size)
      },
    [badgeNumber, kind, size]
  );

  const testId = useMemo(
    () => (name ? toCamelCase(name, "button") : undefined),
    [name]
  );

  const customDefaultProps = useMemo(() => {
    return {
      disabled: isDisabled,
      type: type ?? (kind === "Link" ? "button" : undefined)
    };
  }, [type, kind, isDisabled]);

  return (
    <WSElement
      as="button"
      ref={ref}
      onClick={isDisabled ? undefined : clickHandler}
      className={classNames}
      data-testid={testId}
      {...customDefaultProps}
      {...componentProps}
    >
      {icon && !badgeNumber && (
        <WSIcon
          name={icon}
          size={hasChildren ? sideIconSize : iconOnlySize}
          className={hasChildren ? styles.iconLeft : styles.iconLeftOnly}
        />
      )}
      {badgeProps && <WSBadge {...badgeProps} className={styles.iconLeft} />}
      <WSText className={cn(styles.label, textClassName)}>
        {children !== undefined ? children : label}
      </WSText>
      {rightIcon && (
        <WSIcon
          name={rightIcon}
          size={sideIconSize}
          className={styles.iconRight}
        />
      )}
    </WSElement>
  );
}

type ButtonForwardRef = <K extends WSButtonKind = WSButtonKind>(
  props: WSButtonProps<K> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof Button>;

const WSButtonMain = React.forwardRef(Button) as ButtonForwardRef;

export const WSButton = Object.assign(WSButtonMain, {
  Primary: React.forwardRef<HTMLButtonElement, WSButtonProps<"Primary">>(
    (props, ref) => <WSButton ref={ref} kind="Primary" {...props} />
  ),

  Secondary: React.forwardRef<HTMLButtonElement, WSButtonProps<"Secondary">>(
    (props, ref) => <WSButton ref={ref} kind="Secondary" {...props} />
  ),

  Tertiary: React.forwardRef<HTMLButtonElement, WSButtonProps<"Tertiary">>(
    (props, ref) => <WSButton ref={ref} kind="Tertiary" {...props} />
  ),

  Link: React.forwardRef<HTMLButtonElement, WSButtonProps<"Link">>(
    (props, ref) => <WSButton ref={ref} kind="Link" {...props} />
  )
});
