import { useUserId } from "../../query/hooks/helpers";
import {
  IFeatureFlags,
  useFeatureFlags
} from "../../query/hooks/useFeatureFlags";
import { useMemberProfile, useUserProfile } from "../../query/users/queries";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces";
import { UserAccountType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import { useCustomization } from "../../modules/customization";
import { useAuthorizedScopeGroups } from "../../shared/utils/teamUtils";
import { useShowBatchImportsNav } from "../../userFeatures/useShowBatchImportsNav";
import { useShowCustomFieldsNav } from "../../userFeatures/useShowCustomFieldsNav";
import { useShowGetPaidNav } from "../../userFeatures/useShowGetPaidNav";
import { useShowNewCollaboratorPaymentNav } from "../../userFeatures/useShowNewCollaboratorPaymentNav";
import { useShowNewOneTimeInvoiceNav } from "../../userFeatures/useShowNewOneTimeInvoiceNav";
import { useShowNewRecurringInvoicesNav } from "../../userFeatures/useShowNewRecurringInvoicesNav";
import { useShowPayeableAndPayrollSettingsNav } from "../../userFeatures/useShowPayeableAndPayrollSettingsNav";
import { useShowPaymentNav } from "../../userFeatures/useShowPaymentNav";
import { useShowPayoutNav } from "../../userFeatures/useShowPayoutNav";
import { useShowSendPaymentsNav } from "../../userFeatures/useShowSendPaymentsNav";
import { selectorIsImpersonatedWingspanAdmin } from "./selectors/selectorIsImpersonatedWingspanAdmin";

export type DashboardFocusedFeatureContext = {
  experiments: IFeatureFlags;
  hardcoded: {
    showBatchImportsNav: boolean;
    showCustomFieldsNav: boolean;
    showGetPaidNav: boolean;
    showNewCollaboratorPaymentNav: boolean;
    showNewOneTimeInvoiceNav: boolean;
    showNewRecurringInvoicesNav: boolean;
    showPayableAndPayrollSettingsNav: boolean;
    showPaymentNav: boolean;
    showPayoutNav: boolean;
    showSendPaymentsNav: boolean;
  };
  session: {
    isImpersonatedWingspanAdmin: boolean;
    isImpersonated: boolean;

    isAdminSessionExists: boolean;
    isRequestingUserSessionExists: boolean;
    isRequestingUserTokenFromURL: boolean;
  };
  user: {
    country: string;
    contexts: PlatformContextType[];
    userType?: UserAccountType;
    labels?: { [label: string]: string | boolean | null };
  };
  access: {
    hasAdminScope: boolean;
    hasPaymentsScope: boolean;
    hasCollaboratorsScope: boolean;
    hasFinancesScope: boolean;
    hasDocumentsScope: boolean;
  };
  customization: ReturnType<typeof useCustomization>;
};

export const useGetDashboardFocusedFeaturesContext =
  (): DashboardFocusedFeatureContext | null => {
    const userId = useUserId();

    const featureFlagsQuery = useFeatureFlags({
      refetchOnMount: false
    });
    const userProfileQuery = useUserProfile(userId, {
      refetchOnMount: false
    });
    const memberProfileQuery = useMemberProfile(userId, {
      refetchOnMount: false
    });

    const customization = useCustomization();

    const hardcoded = {
      showBatchImportsNav: useShowBatchImportsNav(),
      showCustomFieldsNav: useShowCustomFieldsNav(),
      showGetPaidNav: useShowGetPaidNav(),
      showNewCollaboratorPaymentNav: useShowNewCollaboratorPaymentNav(),
      showNewOneTimeInvoiceNav: useShowNewOneTimeInvoiceNav(),
      showNewRecurringInvoicesNav: useShowNewRecurringInvoicesNav(),
      showPayableAndPayrollSettingsNav: useShowPayeableAndPayrollSettingsNav(),
      showPaymentNav: useShowPaymentNav(),
      showPayoutNav: useShowPayoutNav(),
      showSendPaymentsNav: useShowSendPaymentsNav()
    };

    const {
      isAdminSessionExists,
      isRequestingUserSessionExists,
      isRequestingUserTokenFromURL,
      hasAdminScope,
      hasPaymentsScope,
      hasCollaboratorsScope,
      hasFinancesScope,
      hasDocumentsScope
    } = useAuthorizedScopeGroups();

    if (
      !featureFlagsQuery.data ||
      !userProfileQuery.data ||
      !memberProfileQuery.data
    ) {
      return null;
    }

    const user = userProfileQuery.data;
    const member = memberProfileQuery.data;
    const experiments = featureFlagsQuery.data;

    const country = member.profile.address?.country || "US";
    const isImpersonatedWingspanAdmin = selectorIsImpersonatedWingspanAdmin();
    const isImpersonated =
      isRequestingUserSessionExists || isRequestingUserTokenFromURL;

    const contexts = user.contexts?.map(c => c.type) || [];
    const userType = user.settings?.userAccountType;

    return {
      hardcoded,
      experiments,
      user: {
        country,
        contexts,
        userType,
        labels: user.labels
      },
      session: {
        isImpersonatedWingspanAdmin,
        isImpersonated,

        isAdminSessionExists,
        isRequestingUserSessionExists,
        isRequestingUserTokenFromURL
      },
      access: {
        hasAdminScope,
        hasPaymentsScope,
        hasCollaboratorsScope,
        hasFinancesScope,
        hasDocumentsScope
      },
      customization
    };
  };
