import {
  WSButton,
  WSButtons,
  WSFormOld,
  WSInputNumberOld,
  WSText,
  WSTextArea,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import * as Yup from "yup";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { IInvoiceRow } from "../../modules/Invoicing/service";
import { useWSMutation } from "../../query/helpers";
import { QUERY_INVOICE } from "../../query/payments/keys";
import { paymentsService } from "../../services/payments";
import { WSServiceError } from "../../utils/serviceHelper";
import { calculateAmountAvailableToRefund } from "../utils/calculateAmountAvailableToRefund";

type FormData = {
  amount: number;
  reason: string;
};

type Props = {
  onClose: () => void;
  invoice: IInvoice | IInvoiceRow;
};

export const ModalRefund: React.FC<Props> = ({ invoice, onClose }) => {
  const [refund, refundMeta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      await paymentsService.invoice.refund(invoice.invoiceId, {
        amount: data.amount,
        description: data.reason ? data.reason : undefined
      });
    },
    {
      dependencies: [[QUERY_INVOICE, invoice.invoiceId]],
      onSuccess: onClose
    }
  );

  const amountAvailableToRefund = calculateAmountAvailableToRefund(invoice);

  return (
    <WSFormOld<FormData>
      onSubmit={refund}
      validationSchema={Yup.object().shape({
        amount: Yup.number().required("Required").min(0.01, "Minimum $0.01")
      })}
    >
      <WSText.Heading5 mb="XS">Refund invoice</WSText.Heading5>
      <WSText mb="M" color="gray500">
        You can refund up to {toWSMoneyString(amountAvailableToRefund)}
      </WSText>

      <WSFormOld.Field
        mb="M"
        name="amount"
        label="Refund amount"
        component={WSInputNumberOld}
        componentProps={{
          mode: "currency",
          currency: "USD",
          min: 0.01,
          max: amountAvailableToRefund
        }}
      />

      <WSFormOld.Field
        name="reason"
        label="Refund reason (optional)"
        component={WSTextArea}
      />

      <WSErrorMessage
        contextKey="InvoiceRefund"
        error={refundMeta.error}
        mt="XL"
      />

      <WSButtons mt="XL" forceFullWidth>
        <WSButton loading={refundMeta.isLoading}>Refund</WSButton>
        <WSButton.Secondary onClick={onClose}>Cancel</WSButton.Secondary>
      </WSButtons>
    </WSFormOld>
  );
};
