import {
  WSEmptyState,
  WSGrid,
  WSInfoBox,
  WSList,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import { usePayeeTaxForms } from "../../../query/taxForm/queries/usePayeeTaxForms";
import { useBrowserPageTitle } from "../../../shared/hooks/useBrowserPageTitle";
import { FAQ1099 } from "../../1099NECFiling/components/FAQ1099";
import { TaxFormPreview } from "../components/TaxForm";
import { useUserId } from "../../../query/hooks/helpers";
import { useActivities, useMemberProfile } from "../../../query/users/queries";
import { CURRENT_YEAR } from "../../TaxFiling/constants/currentYear";
import { DataView } from "../../../shared/components/DataView";
import { selectorAddressString } from "../../../shared/selectors/selectorAddressString";
import { useUpdateDeliveryPreferencesModal } from "../components/useUpdateDeliveryPreferencesModal";

const headerContent = (
  <>
    <WSText.Heading1 mr="M" inline>
      Tax Documents
    </WSText.Heading1>
    <WSText.Heading1 inline ml="2XL" color="gray500">
      {CURRENT_YEAR}
    </WSText.Heading1>
  </>
);

export const RouteIndex: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  useBrowserPageTitle(`${CURRENT_YEAR} Tax Documents`);

  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryPayerTaxForms = usePayeeTaxForms(CURRENT_YEAR);
  const queryActivity = useActivities(userId);
  const updateDeliveryPreferencesModal = useUpdateDeliveryPreferencesModal();

  return (
    <WSPage headerContent={headerContent}>
      <WSGrid gutter="2XL">
        <WSGrid.Item span={{ m: "8" }}>
          <WSList gap="2XL">
            <WSInfoBox>
              Ensure your personal or business information is up to date for tax
              reporting purposes in{" "}
              <a
                target="_self"
                onClick={() => history.push("/member/settings/account")}
              >
                Account Settings
              </a>
              .
            </WSInfoBox>
            <WSQueries
              queries={{ queryPayerTaxForms, queryMember, queryActivity }}
            >
              {({
                queryPayerTaxFormsData,
                queryMemberData,
                queryActivityData
              }) => (
                <>
                  <DataView
                    data={[
                      {
                        label: {
                          text: "Delivery preference",
                          action: {
                            label: "Edit",
                            onClick: () => {
                              updateDeliveryPreferencesModal.open({
                                mailDelivery:
                                  !queryActivityData.events
                                    .electronic1099Consent
                              });
                            }
                          },
                          actionPosition: "left"
                        },
                        value: queryActivityData.events.electronic1099Consent
                          ? "Electronic delivery"
                          : "Mail"
                      },
                      {
                        label: {
                          text: "Delivery address",
                          tooltip: {
                            title:
                              "The delivery address is either your personal or business address provided in your account settings. If your address has changed, please update it in your Account Settings to ensure your information is accurate and up to date.",
                            dark: true
                          }
                        },
                        value: queryMemberData.profile?.address
                          ? selectorAddressString(
                              queryMemberData.profile?.address,
                              "\n"
                            )
                          : "No address provided"
                      }
                    ]}
                  />

                  {queryPayerTaxFormsData.length > 0 ? (
                    <>
                      {queryPayerTaxFormsData.map(taxForm => (
                        <TaxFormPreview
                          key={taxForm.taxFormId}
                          mb="XL"
                          taxForm={taxForm}
                          member={queryMemberData}
                        />
                      ))}
                    </>
                  ) : (
                    <WSEmptyState
                      py="3XL"
                      hasBorder
                      orientation="vertical"
                      fullWidth
                      type="documents"
                      title="No tax documents yet for this year"
                      description="Once a tax document is available, it will appear here. Ensure your personal or business information is up to date for tax reporting purposes in account settings."
                      buttons={[
                        {
                          label: "View account settings",
                          kind: "Secondary",
                          onClick: async () => {
                            history.push("/member/settings/account");
                          }
                        }
                      ]}
                    />
                  )}
                </>
              )}
            </WSQueries>
          </WSList>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "4" }}>
          <FAQ1099 mb="2XL" />
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
