import {
  toWSMoney,
  WSAvatar,
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSList,
  WSMarginProps,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { PAYOUTS_PATH } from "../..";
import { IPayoutResponse } from "../../../../services/api/payments/payouts/types";
import {
  avatarBankAccount,
  avatarWallet
} from "../../../../shared/constants/avatars";
import { getAccountDetailsName } from "../../utils/getAccountDetailsName";
import { getPayoutNet } from "../../utils/utils";
import styles from "./styles.module.scss";

export interface PayoutAccountsPanelProps extends WSMarginProps {
  payout: IPayoutResponse;
}

export const PayoutAccountsPanel: React.FC<PayoutAccountsPanelProps> = ({
  payout,
  ...elementProps
}) => {
  const history = useHistory();

  const uniqueExternalTransfers = useMemo(
    () =>
      uniqBy(
        (payout.externalFundsTransfers || []).filter(
          transfer => !!transfer.destination?.destinationId
        ),
        transfer => transfer.destination?.destinationId
      ),
    [payout.externalFundsTransfers]
  );

  const disbursementTotal = useMemo(() => getPayoutNet(payout), [payout]);

  if (uniqueExternalTransfers.length === 0 && !payout?.bankingFundsTransfer) {
    return null;
  }

  return (
    <WSElement>
      <WSSectionToolbar
        button={{
          label: "View details",
          onClick: () => {
            history.push(`${PAYOUTS_PATH}/${payout.payoutId}/account-details`);
          }
        }}
        title="Payout accounts"
        mb={"S"}
      />
      <WSPanel className={styles.panel} {...elementProps}>
        <WSList gap="L" mb="L">
          {uniqueExternalTransfers.map(({ destination, amount }) => (
            <WSFlexBox
              wrap="nowrap"
              key={destination!.destinationId}
              justify="space-between"
            >
              <WSFlexBox wrap="nowrap" alignItems="center">
                <WSAvatar {...avatarBankAccount} mr="M" />
                <WSText.ParagraphSm color={"gray500"} mr="2XL">
                  {getAccountDetailsName(destination!)}
                </WSText.ParagraphSm>
              </WSFlexBox>
              <WSText.ParagraphSm color={"gray700"}>
                {toWSMoney(amount).formattedValue}
              </WSText.ParagraphSm>
            </WSFlexBox>
          ))}

          {payout?.bankingFundsTransfer && (
            <WSFlexBox justify="space-between">
              <WSFlexBox alignItems="center">
                <WSAvatar {...avatarWallet} mr="M" />
                <WSButton.Link
                  size="M"
                  name="viewRoutingNumber"
                  onClick={() => history.push("/member/wallet")}
                >
                  Wingspan Wallet
                </WSButton.Link>
              </WSFlexBox>

              <WSText.ParagraphSm color={"gray700"}>
                {toWSMoney(payout?.bankingFundsTransfer?.amount).formattedValue}
              </WSText.ParagraphSm>
            </WSFlexBox>
          )}
        </WSList>

        <WSDivider mb="L" />
        <WSFlexBox justify="space-between">
          <WSText.ParagraphSm color={"gray500"} mr="2XL">
            Disbursement total
          </WSText.ParagraphSm>
          <WSText.ParagraphSm weight={"bold"} color={"gray700"}>
            {toWSMoney(disbursementTotal).formattedValue}
          </WSText.ParagraphSm>
        </WSFlexBox>
      </WSPanel>
    </WSElement>
  );
};
