import { useHistory, useRouteMatch } from "react-router-dom";
import {
  useBulkPayableBatch,
  useBulkPayableBatchSummary
} from "../../../BulkImporter/query/bulkPayable/queries";
import { useUpdateBulkPayableBatch } from "../../../BulkImporter/query/bulkPayable/mutations";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { SetupImportPaymentsComponentProps } from "./index";
import { EditLayout } from "../../components/EditLayout";
import { WSQueries } from "../../../../query/WSQuery";
import {
  WSButton,
  WSIconList,
  WSMessageBox,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import { pluralize } from "../../../../shared/utils/pluralize";
import { useBulkCalculate1099BatchesQuery } from "../../../BulkImporter/query/bulkCalculation1099/queries";
import { CURRENT_YEAR } from "../../constants/currentYear";

const lineItemPluralizeOptions = {
  one: "line item",
  other: "line items"
};

const recipientPluralizeOptions = {
  one: "recipient",
  other: "unique recipients"
};

export const RouteImportPaymentsPreview: React.FC<
  SetupImportPaymentsComponentProps & {
    basePath?: string;
  }
> = ({ basePath, onBack, onNext }) => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const qPayableBulkBatch = useBulkPayableBatch(bulkBatchId);
  const qPayableBatchSummary = useBulkPayableBatchSummary(bulkBatchId);
  const qBulkCalculate1099Batches = useBulkCalculate1099BatchesQuery({
    filingYear: `${CURRENT_YEAR}`,
    status: [BulkStatus.Complete, BulkStatus.Failed]
  });

  const [updateBulkPayableBatch, updateBulkPayableBatchMeta] =
    useUpdateBulkPayableBatch();

  const startImport = async () => {
    await updateBulkPayableBatch(
      {
        id: bulkBatchId,
        data: {
          status: BulkStatus.Pending
        }
      },
      {
        onSuccess: () => {
          onNext({
            bulkBatchId
          });
        }
      }
    );
  };

  return (
    <EditLayout title="Batch upload payments">
      <WSQueries
        queries={{
          qPayableBulkBatch,
          qPayableBatchSummary,
          qBulkCalculate1099Batches
        }}
      >
        {({
          qPayableBulkBatchData: payableBulkBatch,
          qPayableBatchSummaryData: payableBatchSummary,
          qBulkCalculate1099BatchesData: bulkCalculate1099Batches
        }) => {
          const isBatchProcessed = [
            BulkStatus.Complete,
            BulkStatus.Failed
          ].includes(payableBulkBatch.status);

          return (
            <>
              <WSText.Heading4 mb="XL">
                Batch upload payments with a spreadsheet
              </WSText.Heading4>
              <WSText weight="medium" mb="XL">
                Upload preview
              </WSText>

              <WSIconList
                mb="2XL"
                items={[
                  {
                    description: `${pluralize(
                      payableBatchSummary.totalItemsImported,
                      lineItemPluralizeOptions
                    )} will be uploaded`,
                    icon: {
                      name: "upload",
                      color: "blue500",
                      size: "M"
                    }
                  },
                  {
                    description: `${pluralize(
                      payableBatchSummary.collaboratorsImpactedCount,
                      recipientPluralizeOptions
                    )} received payments`,
                    icon: {
                      name: "user",
                      color: "blue500",
                      size: "M"
                    }
                  },
                  {
                    description: `${toWSMoneyString(
                      payableBatchSummary.netImportAmount
                    )} of payments will be uploaded`,
                    icon: {
                      name: "dollar-circle",
                      color: "blue500",
                      size: "M"
                    }
                  }
                ]}
              />
              {isBatchProcessed ? (
                <WSMessageBox.Regular mb="XL">
                  Payments have already been imported for this batch.{" "}
                  <WSButton.Link
                    onClick={() => {
                      history.push(basePath + `/${bulkBatchId}/upload`);
                    }}
                  >
                    See status here.
                  </WSButton.Link>
                </WSMessageBox.Regular>
              ) : null}
              {payableBulkBatch.status === BulkStatus.Processing ? (
                <WSMessageBox.Regular mb="XL">
                  Payments are being imported. This may take a few minutes.{" "}
                  <WSButton.Link
                    onClick={() => {
                      history.push(basePath + `/${bulkBatchId}/upload`);
                    }}
                  >
                    See progress here.
                  </WSButton.Link>
                </WSMessageBox.Regular>
              ) : null}

              {bulkCalculate1099Batches.length > 0 ? (
                <WSMessageBox.Info mb="XL" size="Large">
                  <WSText mb="XL" color="gray500">
                    What to expect:
                  </WSText>

                  <WSText as="ol">
                    <WSText as="li" mb="M" color="gray500">
                      1. New payments uploaded for existing recipients will
                      replace their previous totals
                    </WSText>
                    <WSText as="li" mb="M" color="gray500">
                      2. Statuses may be impacted as a result of new payments
                    </WSText>
                    <WSText as="li" mb="M" color="gray500">
                      3.. You will be prompted to re-generate amounts and
                      statuses for all recipients after the upload
                      <WSText my="XS" ml="M" color="gray500">
                        • Manually overwritten recipient information on
                        individual 1099s will be retained after re-generating
                        amounts and statuses
                      </WSText>
                      <WSText my="XS" ml="M" color="gray500">
                        • You can revert overwritten information on an
                        individual basis at any time
                      </WSText>
                      <WSText my="XS" ml="M" color="gray500">
                        • You can proceed to make new manual changes after the
                        upload, if needed
                      </WSText>
                    </WSText>
                  </WSText>
                </WSMessageBox.Info>
              ) : null}

              <WSButton
                onClick={startImport}
                fullWidth
                name="paymentsBatchImport"
                loading={updateBulkPayableBatchMeta.isLoading}
                disabled={isBatchProcessed}
              >
                Continue
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
