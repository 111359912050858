import { WSButton, WSCentered, WSText } from "@wingspanhq/fe-component-library";
import { EmailVerificationStatus } from "@wingspanhq/users/dist/lib/interfaces";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { WSQueries } from "../../query/WSQuery";
import {
  useCMSWebflowEnterprise,
  useCMSWebflowPartner
} from "../../query/cms/queries";
import { useUserId } from "../../query/hooks/helpers";
import { useMemberClientsQuery } from "../../query/payments/queries";
import {
  getCurrentEntepriseId,
  getShouldForceToVerifyEmail
} from "../../query/platform/selectors";
import { useSession } from "../../query/session";
import { useBeginExistingEmailVerification } from "../../query/users/mutations";
import {
  useActivities,
  useUserProfile,
  useUserTag
} from "../../query/users/queries";
import { useSetWSStore, useWSStore } from "../../store";
import { censorEmail } from "../../utils/stringHelper";

export interface VerifyOTPProps {}

export const OnboardingEmailVerification: React.FC<VerifyOTPProps> = () => {
  const location = useLocation();
  const setStore = useSetWSStore();
  const store = useWSStore();
  const userId = useUserId();
  const queries = queryString.parse(location.search);
  const partnerId = queries.partner as string;
  const qPartner = useCMSWebflowPartner(partnerId, {
    enabled: partnerId,
    onSuccess(partner) {
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  });
  const partner = qPartner.data;

  const userQuery = useUserProfile(userId);
  const activityQuery = useActivities(userId);
  const memberClientsQuery = useMemberClientsQuery();

  const enterpriseId = getCurrentEntepriseId(store, activityQuery.data);
  const enterpriseQuery = useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: !!enterpriseId
  });
  const sessionQuery = useSession({
    enabled: !!enterpriseId
  });
  useEffect(() => {
    if (sessionQuery.data && !!enterpriseId) {
      if (userTagQuery.isIdle) {
        userTagQuery.refetch();
      }
    }
  }, [sessionQuery, enterpriseId]);

  const userTagQuery = useUserTag(enterpriseId || "", {
    enabled: !!enterpriseId && sessionQuery.data,
    retry: false
  });

  const enterpriseForUserTag = userTagQuery.data
    ? {
        logo: {
          src: userTagQuery.data.member?.profile?.logoUrl
        }
      }
    : undefined;

  const enterprise = enterpriseQuery.data || enterpriseForUserTag || undefined;

  const [beginEmailVerification, beginEmailVerificationMeta] =
    useBeginExistingEmailVerification(userId);

  useEffect(() => {
    if (
      memberClientsQuery.data &&
      userQuery.data &&
      beginEmailVerificationMeta.isIdle
    ) {
      const isEmailVerificationEmailSent =
        userQuery.data.emailVerificationState?.status ===
        EmailVerificationStatus.Pending;

      if (
        getShouldForceToVerifyEmail(userQuery.data, memberClientsQuery.data) &&
        !isEmailVerificationEmailSent
      ) {
        beginEmailVerification({ email: userQuery.data.email });
      }
    }
  }, [memberClientsQuery.status, userQuery.status]);

  return (
    <OnboardingLayout partnerLogo={enterprise?.logo.src || partner?.logo?.src}>
      <WSQueries queries={{ memberClientsQuery, userQuery }}>
        {({ userQuery: { data: user } }) => (
          <WSCentered span={{ m: "6", xl: "4" }}>
            <WSText.Heading5 mb="M" align="center">
              Verify your e-mail
            </WSText.Heading5>
            <WSText color="gray600" mb="XL" align="center">
              {`A verification e-mail has been sent to ${censorEmail(
                user.email
              )}. Please check your E-mail inbox for further instructions.`}
            </WSText>
            <WSText color="gray600" mb="XL" align="center">
              Didn't receive e-mail yet?{" "}
              <WSButton.Link
                onClick={beginEmailVerification}
                loading={beginEmailVerificationMeta.isLoading}
              >
                Resend
              </WSButton.Link>
            </WSText>
          </WSCentered>
        )}
      </WSQueries>
    </OnboardingLayout>
  );
};
