import {
  WSElement,
  WSElementProps,
  WSInfiniteScroll,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableColumnSortDirection,
  WSTableItem,
  WSText,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import React, { useCallback, useState } from "react";
import { TaxID } from "../../../components/TaxID";

import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { TaxFormDeliveryMethod } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import flatten from "lodash/flatten";
import { useHistory, useRouteMatch } from "react-router-dom";
import { TaxFormRowSearchSort } from "../../../../../query/search/taxForm/queries/usePaginatedTaxFormRowSearch";
import { usePaginatedPayerTaxForms } from "../../../../../query/taxForm/queries/usePaginatedPayerTaxForms";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { TaxFormFilingStatusBadge } from "../../../components/TaxFormFilingStatusBadge";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";
import { NameCell } from "./NameCell";

export interface NEC1099FilingTableProps extends WSElementProps {
  queryPayerTaxForms: ReturnType<typeof usePaginatedPayerTaxForms>;
  organizations: INewUser[];
  onSort?: (sortConfig: any) => void;
}

export const NEC1099FilingTable: React.FC<NEC1099FilingTableProps> = ({
  queryPayerTaxForms,
  organizations,
  onSort,
  ...elementProps
}) => {
  const history = useHistory();
  const match = useRouteMatch();

  const [amountSortDirection, setAmountSortDirection] =
    useState<WSTableColumnSortDirection>("descending");

  const setColumnSort = (sortConfig: TaxFormRowSearchSort) => {};

  const onView = useCallback(
    (item: WSTableItem) => {
      history.push(`/member/1099-filing/details/${item.id}`);
    },
    [history, match.url]
  );

  const firstColumns: WSTableColumn<IPayerTaxFormResponse>[] = [
    {
      config: {
        gridTemplateSizeMax: "1.5fr",
        header: {
          text: "Name"
        }
      },
      renderFunction: ({ data: payerTaxForm }) => {
        return <NameCell taxForm={payerTaxForm} />;
      }
    }
  ];

  if (organizations.length > 0) {
    firstColumns.push({
      config: {
        gridTemplateSizeMax: "1.5fr",
        hideOnScreens: ["XS", "S"],
        header: {
          text: "Org Account Name"
        }
      },
      renderFunction: ({ data }) => (
        <WSText color="gray600">
          {data.payer ? selectorMemberName(data.payer) : "-"}
        </WSText>
      )
    });
  }
  const list = flatten(queryPayerTaxForms.data || []);

  return (
    <WSElement {...elementProps}>
      <WSInfiniteScroll
        onLoad={() => {
          queryPayerTaxForms.fetchMore();
        }}
        loadMore={list.length > 0}
        endOfList={!queryPayerTaxForms.canFetchMore}
        loading={!!queryPayerTaxForms.isFetchingMore}
      >
        <WSTable
          onRowClick={onView}
          columns={[
            ...firstColumns,
            {
              config: {
                sortDirection: "none",
                onColumnSort: () => {},
                gridTemplateSizeMax: "1.5fr",
                hideOnScreens: ["XS"],
                header: {
                  text: "Tax ID"
                }
              },
              renderFunction: ({ data }) => <TaxID taxForm={data} />
            },
            {
              config: {
                sortDirection: "none",
                onColumnSort: () => {},
                gridTemplateSizeMax: "1fr",
                hideOnScreens: ["XS", "S"],
                header: {
                  text: "Delivery"
                }
              },
              renderFunction: ({ data }) => {
                return (
                  <WSTableCell
                    text={
                      data.deliveryMethod === TaxFormDeliveryMethod.Electronic
                        ? "Electronic"
                        : "Mail"
                    }
                  />
                );
              }
            },
            {
              config: {
                sortDirection: "none",
                onColumnSort: () => {},
                gridTemplateSizeMax: "1fr",
                header: {
                  text: "Filing Status"
                }
              },
              renderFunction: ({ data }) => {
                return (
                  <TaxFormFilingStatusBadge
                    status={data.status}
                    // FIX: derive this later
                    isCorrection={false}
                  />
                );
              }
            },
            {
              config: {
                sortDirection: amountSortDirection,
                onColumnSort: () => {},
                gridTemplateSizeMax: "0.5fr",
                justify: "end",
                header: {
                  text: "1099-NEC Total"
                }
              },
              renderFunction: ({ data }) => {
                const nec1099Total = selector1099NECTotal(data);

                return (
                  <WSTableCell
                    text={
                      Number.isFinite(nec1099Total)
                        ? toWSMoneyString(nec1099Total)
                        : "-"
                    }
                  />
                );
              }
            }
          ]}
          tableData={list.map(row => {
            return {
              id: row.taxFormId,
              data: row
            };
          })}
        />
      </WSInfiniteScroll>
    </WSElement>
  );
};
