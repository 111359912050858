import { useHistory } from "react-router-dom";
import {
  WSButton,
  WSContainer,
  WSFlexBox,
  WSGrid,
  WSLayout,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { PreviewPhysicalCard } from "../components/PreviewPhysicalCard";
import { WALLET_ROOT_PATH } from "../index";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberProfile } from "../../../query/users/queries";
import { FieldView } from "../../../shared/components/FieldView/FieldView";
import { WSQueries } from "../../../query/WSQuery";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { AddressText } from "../../../shared/components/AddressText/AddressText";
import { useCreateCard } from "../../../query/cards/mutations/useCreateCard";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../services/platform";
import { useUpdateBillingAddressModal } from "../modals/OrderConfirmation/useUpdateBillingAddress";
import { IAddress } from "@wingspanhq/users/dist/lib/interfaces";
import { useCardOrderedModal } from "../modals/OrderConfirmation/useCardOrderedModal";
import { getValidationSchemaAddress } from "../../Onboarding/components/FormPartialAddress";

export const RouteConfirmOrderPhysicalCard = () => {
  const history = useHistory();
  const userId = useUserId();
  const memberProfileQuery = useMemberProfile(userId);
  const [createCard, createCardMeta] = useCreateCard();
  const updateBillingAddressModal = useUpdateBillingAddressModal();
  const cardOrderedModal = useCardOrderedModal();
  const [shippingAddress, setShippingAddress] = React.useState<IAddress | null>(
    null
  );

  const [error, setError] = React.useState<string>();

  return (
    <WSLayout
      headerCenter={
        <WSText.ParagraphSm weight="medium">Order card</WSText.ParagraphSm>
      }
      smallHeader={true}
      onClose={() => {
        history.push(WALLET_ROOT_PATH);
      }}
      line
    >
      <WSContainer verticalPadding>
        <WSGrid>
          <WSGrid.Item span={{ m: "4" }} />
          <WSGrid.Item span={{ m: "4" }}>
            <WSQueries queries={{ memberProfileQuery }}>
              {({ memberProfileQueryData }) => {
                const names = wsName(memberProfileQueryData);
                const address =
                  shippingAddress || memberProfileQueryData.profile.address;

                return (
                  <WSList gap="L">
                    <WSText.Paragraph weight="medium">
                      Order a free Wingspan business debit card
                    </WSText.Paragraph>
                    <PreviewPhysicalCard />
                    <WSText.Paragraph weight="medium">
                      Confirm your information
                    </WSText.Paragraph>
                    <WSText.ParagraphSm color="gray500">
                      Please review the information we have on file. Confirm or
                      update the mailing address where the card will be sent.
                    </WSText.ParagraphSm>
                    <WSPanel colorBackground="white">
                      <WSGrid gutter={{ m: "XL" }}>
                        <WSGrid.Item span={{ m: "6" }}>
                          <FieldView
                            label="Legal first name"
                            value={names.firstName}
                          ></FieldView>
                        </WSGrid.Item>
                        <WSGrid.Item span={{ m: "6" }}>
                          <FieldView
                            label="Legal last name"
                            value={names.lastName}
                          ></FieldView>
                        </WSGrid.Item>
                        <WSGrid.Item span={{ m: "12" }}>
                          <WSFlexBox justify="space-between">
                            <FieldView
                              label="Address"
                              value={
                                <WSText.ParagraphSm
                                  weight="book"
                                  singleLine={false}
                                >
                                  <AddressText {...address} />
                                </WSText.ParagraphSm>
                              }
                            />
                            <WSButton.Link
                              size="M"
                              onClick={() => {
                                updateBillingAddressModal.open({
                                  address,
                                  onSuccess: newAddress => {
                                    setShippingAddress(newAddress);
                                  }
                                });
                              }}
                            >
                              Edit
                            </WSButton.Link>
                          </WSFlexBox>
                        </WSGrid.Item>
                      </WSGrid>
                    </WSPanel>
                    <WSErrorMessage
                      mb="XL"
                      error={error || createCardMeta.error}
                      contextKey={ErrorContextKey.BankCard}
                    />
                    <WSButton.Primary
                      mt="XL"
                      fullWidth
                      size="M"
                      onAsyncClick={async () => {
                        const addressValidationSchema =
                          getValidationSchemaAddress(address?.country || "US");

                        if (addressValidationSchema.isValidSync(address)) {
                          await createCard(
                            {
                              userId,
                              isVirtualCard: false,
                              address: {
                                address1: address?.addressLine1!,
                                city: address?.city!,
                                state: address?.state!,
                                postal_code: address?.postalCode!,
                                country: address?.country!
                              }
                            },
                            {
                              onSuccess: async () => {
                                await cardOrderedModal.open();

                                history.push(WALLET_ROOT_PATH);
                              }
                            }
                          );
                        } else {
                          setError(
                            "Invalid address. Please update your address."
                          );
                        }
                      }}
                    >
                      Confirm & Continue
                    </WSButton.Primary>
                  </WSList>
                );
              }}
            </WSQueries>
          </WSGrid.Item>
        </WSGrid>
      </WSContainer>
    </WSLayout>
  );
};
