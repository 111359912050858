import {
  useIsMobile,
  WSFormOld,
  WSInputMaskOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";
import { useUserId } from "../../../query/hooks/helpers";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { openIntercomMessage } from "../../../shared/utils/intercom";
import { validatorTaxId } from "../../../shared/validators/validatorTaxId";
import styles from "./SettingsUpdateForms.module.scss";

interface UpdateEINFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (e: any) => void;
}

export const UpdateEINForm: React.FC<UpdateEINFormProps> = ({
  onSubmit,
  defaultValues,
  onCancel
}) => {
  const isMobile = useIsMobile();
  const queryVerifications = useQueryVerifications();
  const isVerified = queryVerifications.data?.tax === "Verified";

  const userId = useUserId();
  const [updateMemberProfile, { error: updateMemberProfileError }] =
    useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    if (defaultValues.taxId && isVerified) {
      openIntercomMessage(`Hi there! I want to update my EIN.\n\n`);
      onSubmit(data);
      return;
    }

    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        company: {
          taxId: data.taxId
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };

  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        taxId: validatorTaxId.required("Required")
      })}
    >
      <WSFormOld.Field
        name="taxId"
        label="EIN"
        component={WSInputMaskOld}
        componentProps={{
          mask: "99-9999999",
          unmask: true,
          fsExclude: true
        }}
      />

      <WSText.ParagraphSm weight="medium" className={styles.errorText} mt="XS">
        {updateMemberProfileError?.response?.data["error"]}
      </WSText.ParagraphSm>
      <WSFormOld.SubmitButton
        type="submit"
        name="update"
        mt="2XL"
        fullWidth={isMobile}
      >
        {defaultValues.taxId && isVerified
          ? "Send request to customer support"
          : "Update"}
      </WSFormOld.SubmitButton>
    </WSFormOld>
  );
};
