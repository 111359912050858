import {
  WSAlert,
  WSButton,
  WSGrid,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { LayoutFullscreen } from "../../../../shared/components/LayoutFullscreen";
import { FlowOption } from "../../components/FlowOption";
import { useShowMultiCurrencyInvoicesBanner } from "../../../../userFeatures/useShowMultiCurrencyInvoicesBanner";
import { useCustomization } from "../../../customization";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { useUserId } from "../../../../query/hooks/helpers";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { selectorInstantPayoutDefaultFee } from "../../../../shared/selectors/selectorInstantPayoutDefaultFee";

export const RouteAdd: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const [value, setValue] = useState<"standard" | "instant" | undefined>();
  const showMultiCurrencyInvoicesBanner = useShowMultiCurrencyInvoicesBanner();
  const { support } = useCustomization();
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);

  return (
    <LayoutFullscreen
      headTitle="Add payout method"
      title="How would you like to get paid?"
      description="This will be your default payout method. You can always come back and
      change this later."
      onBack={() => {
        history.push("/member/settings/payment-methods/edit");
      }}
    >
      <WSGrid>
        <WSGrid.Item>
          <FlowOption
            kind="vertical"
            icon={{
              name: "bank",
              color: "gray500",
              secondaryColor: "white"
            }}
            name="Standard payout (free)"
            desctiption="Get your funds in 2-5 business days"
            onClick={() => {
              setValue("standard");
            }}
            isSelected={value === "standard"}
            tooltip={
              <>
                <WSText mb="XS" color="white">
                  <b>Standard payout</b>
                </WSText>
                <WSText color="white">
                  Receive your payments in 2-5 business days over the bank
                  transfer (ACH) network.
                </WSText>
              </>
            }
          />
        </WSGrid.Item>
        <WSGrid.Item>
          <FlowOption
            kind="vertical"
            icon={{
              name: "instant",
              color: "black",
              secondaryColor: "white"
            }}
            name={`Instant payout (${selectorInstantPayoutDefaultFee(
              queryPayoutSettings.data
            )}% fee)`}
            desctiption="Get your funds in under an hour"
            onClick={() => {
              setValue("instant");
            }}
            isSelected={value === "instant"}
            tooltip={
              <>
                <WSText mb="XS" color="white">
                  <b>
                    Instant payout (
                    {selectorInstantPayoutDefaultFee(queryPayoutSettings.data)}%
                    fee)
                  </b>
                </WSText>
                <WSText color="white">
                  Instant payouts only work with U.S. based bank accounts or
                  debit cards that participate in instant transfer services.
                  Find out if your bank account or card is eligible by adding it
                  to your Wingspan account. Once added, eligible accounts will
                  appear here under instant payout methods.
                </WSText>
              </>
            }
          />
        </WSGrid.Item>
      </WSGrid>

      {showMultiCurrencyInvoicesBanner && (
        <WSAlert
          icon="info-circle"
          size="S"
          mt="XL"
          mb="XL"
          theme="info"
          title="Multi-currency invoices"
          action={{
            onClick: () => {
              const payoutInformationUrl =
                support(false)?.documentation?.payoutInformationUrl;
              const generalUrl = support(true)?.documentation?.generalUrl;
              openInNewTab(payoutInformationUrl ?? generalUrl!);
            },
            text: "Learn more"
          }}
        >
          <WSText.ParagraphSm weight="book" color="gray700" m="NONE">
            If the invoice currency is different than that of your external bank
            account, the payout will be automatically converted to your
            currency.
          </WSText.ParagraphSm>
        </WSAlert>
      )}

      <WSButton
        mt="XL"
        fullWidth
        disabled={!value}
        onClick={() => {
          if (value === "standard") {
            history.push("/member/settings/payment-methods/add-standard");
          } else if (value === "instant") {
            history.push("/member/settings/payment-methods/add-instant");
          }
        }}
        name="continue"
      >
        Continue
      </WSButton>
    </LayoutFullscreen>
  );
};
