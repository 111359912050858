import { WSActions, WSList } from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";

type Props = {
  error?: any;
  isLoading?: boolean;
  onCancel: () => void;
};

export const LayoutFormEdit: React.FC<Props> = props => {
  return (
    <WSList gap="L">
      {props.children}

      <WSErrorMessage contextKey="Settings" error={props.error} />

      <WSActions
        buttons={[
          {
            label: "Update",
            loading: props.isLoading
          },
          {
            label: "Cancel",
            kind: "Secondary",
            type: "button",
            onClick: props.onCancel
          }
        ]}
      />
    </WSList>
  );
};
