import {
  IPendingTransactionResponse,
  PendingTransactionSubType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import {
  toWSDateString,
  WSTable,
  WSTableCell,
  WSTableColumn
} from "@wingspanhq/fe-component-library";
import sortBy from "lodash/sortBy";
import React from "react";
import { AmountColumn } from "./AmountColumn";
import { IAccount } from "@wingspanhq/users";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces";

const columns: Array<WSTableColumn<MappedPendingTransaction>> = [
  {
    config: {
      header: { text: "Merchant" },
      gridTemplateSizeMax: "0.5fr"
    },
    renderFunction: rowData => (
      <WSTableCell
        avatar={{
          type: "icon",
          icon: "transfer",
          color: "gray500"
        }}
        text={rowData.data.name}
        // pill={rowData.data.business ? undefined : { text: "Ignored" }}
        // secondaryTextColor={
        //   rowData.data.business &&
        //   rowData.data.type !== TransactionType.Transfer
        //     ? "gray600"
        //     : "gray400"
        // }
        secondaryText={toWSDateString(rowData.data.date, "monthDayYear")}
      />
    )
  },
  {
    config: {
      hideOnScreens: ["XS", "S", "M"],
      header: { text: "Description" },
      gridTemplateSizeMax: "0.5fr"
      // justify: "end"
    },
    renderFunction: rowData => <WSTableCell text={rowData.data.description} />
  },
  {
    config: {
      hideOnScreens: ["XS"],
      header: { text: "Account" },
      gridTemplateSizeMax: "0.2fr"
      // justify: "end"
    },
    renderFunction: rowData => (
      <WSTableCell
        text={
          rowData.data.account?.mask ? `•••${rowData.data.account.mask}` : "--"
        }
      />
    )
  },
  {
    config: {
      justify: "end",
      header: { text: "Amount" },
      gridTemplateSizeMax: "auto"
    },
    renderFunction: rowData => (
      <AmountColumn transaction={rowData.data.source} />
    )
  }
];

type MappedPendingTransaction = {
  source: IPendingTransactionResponse | ITransaction;
  account?: IAccount;
  description: string;
  amount: number;
  name: string;
  status: string;
  date: Date;
};

export const TablePendingTransactions: React.FC<{
  pendingTransactions: (ITransaction | IPendingTransactionResponse)[];
  accounts: IAccount[];
}> = ({ pendingTransactions, accounts }) => {
  const tableData = sortBy(
    pendingTransactions.map((transaction, index) => {
      const account = accounts.find(
        account => account.accountId === transaction.accountId
      );

      if ("pendingTransactionId" in transaction) {
        return {
          data: {
            source: transaction,
            account,
            name: "Pending transaction",
            description: "Transfer",
            amount:
              Math.abs(transaction.amount) *
              (transaction.subType === PendingTransactionSubType.Deposit
                ? 1
                : -1),
            status: transaction.status,
            date: transaction.createdAt
          },
          id: transaction.pendingTransactionId
        };
      } else {
        return {
          data: {
            source: transaction,
            account,
            name: transaction.name || "Pending transaction",
            description: "Transfer",
            amount: transaction.amount * -1,
            status: transaction.status,
            date: transaction.date || transaction.createdAt
          },
          id: `${transaction.transactionId} ${transaction.date} ${index}`
        };
      }
    }),
    "data.date"
  ).reverse();

  if (!tableData.length) {
    return null;
  }

  return (
    <WSTable<MappedPendingTransaction>
      mt="XL"
      tableData={tableData}
      columns={columns}
      forceShowGroups
      showHeader
      getGroupName={item => "Pending"}
      // rowActions={item => {
      //   const {
      //     id,
      //     data: { source }
      //   } = item;
      //
      //   const actions: WSTableAction[] = [];
      //
      //   if (source.status === PendingTransactionStatus.Pending) {
      //     actions.push({
      //       text: "Cancel",
      //       onAsyncClick: async () => {
      //         await updateTransaction({
      //           id: source.pendingTransactionId,
      //           status: PendingTransactionStatus.Cancelled
      //         });
      //       }
      //     });
      //   }
      //
      //   return actions;
      // }}
    />
  );
};
