import {
  WSElement,
  WSPage,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import { CustomFieldResourceType } from "@wingspanhq/payments/dist/interfaces";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Tabs } from "../../../../components/Tabs";

import { useModalAddCustomField } from "../../components/AddCustomFieldModal/useAddCustomFieldModal";
import { CustomFieldResourceTypeSlug } from "../../utils";
import { CustomFieldsTable } from "./CustomFieldsTable";

export function RouteCustomFields() {
  const isMobile = useIsMobile();
  const location = useLocation();
  const [currentCustomFieldResourceType, setCurrentCustomFieldResourceType] =
    useState(CustomFieldResourceType.Collaborator);

  const addCustomFieldModal = useModalAddCustomField({
    resourceType: currentCustomFieldResourceType
  });

  useEffect(() => {
    if (
      location.pathname.includes(
        `/member/data/custom-fields/${
          CustomFieldResourceTypeSlug[CustomFieldResourceType.Collaborator]
        }`
      )
    ) {
      setCurrentCustomFieldResourceType(CustomFieldResourceType.Collaborator);
    } else if (
      location.pathname.includes(
        `/member/data/custom-fields/${
          CustomFieldResourceTypeSlug[CustomFieldResourceType.LineItem]
        }`
      )
    ) {
      setCurrentCustomFieldResourceType(CustomFieldResourceType.LineItem);
    }
  }, [location.pathname]);

  return (
    <WSPage
      title="Custom fields"
      primaryButton={
        isMobile
          ? undefined
          : {
              name: "createCustomField",
              label: "Add custom field",
              onClick: () => {
                addCustomFieldModal.open({
                  resourceType: currentCustomFieldResourceType
                });
              }
            }
      }
    >
      <Tabs
        tabs={[
          {
            path: `/member/data/custom-fields/${
              CustomFieldResourceTypeSlug[CustomFieldResourceType.Collaborator]
            }`,
            label: "Contractor"
          },
          {
            path: `/member/data/custom-fields/${
              CustomFieldResourceTypeSlug[CustomFieldResourceType.LineItem]
            }`,
            label: "Line Item"
          }
        ]}
      />
      <WSElement>
        <Switch>
          <Route
            path={`/member/data/custom-fields/:resourceTypeSlug`}
            component={CustomFieldsTable}
          />
          <Redirect
            from="*"
            to={`/member/data/custom-fields/${
              CustomFieldResourceTypeSlug[CustomFieldResourceType.Collaborator]
            }`}
          />
        </Switch>
      </WSElement>
    </WSPage>
  );
}
