import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { RouteEditCalculationSettings } from "./routes/Dashboard/RouteEditCalculationSettings";
import { RouteEditPayerInformation } from "./routes/Dashboard/RouteEditPayerInformation";
import { RouteEditStateFiling } from "./routes/Dashboard/RouteEditStateFiling";
import { PayerSetupFlow } from "./routes/PayerSetupFlow";
import { RoutePreview } from "./routes/Preview/RoutePreview";

export const TAX_FILING_PATH = "/member/tax-filing";
export const TAX_FILING_1099_FILING_PATH = TAX_FILING_PATH + "/1099-filing";
export const TAX_FILING_1099_FILING_SET_UP_PATH =
  TAX_FILING_1099_FILING_PATH + "/set-up";
export const TAX_FILING_1099_FILING_PREVIEW_PATH =
  TAX_FILING_1099_FILING_PATH + "/preview";
export const TAX_FILING_1099_FILING_UPDATE_PAYER_INFORMATION_PATH =
  TAX_FILING_1099_FILING_PATH + "/update-payer-information";
export const TAX_FILING_1099_FILING_UPDATE_STATE_FILING_SETTINGS_PATH =
  TAX_FILING_1099_FILING_PATH + "/update-state-filing-settings";
export const TAX_FILING_1099_FILING_UPDATE_CALCULATION_SETTINGS_PATH =
  TAX_FILING_1099_FILING_PATH + "/update-calculation-settings";

export const TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_SUB_PATH =
  "/confirm-payer-info";
export const TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_PATH =
  TAX_FILING_1099_FILING_SET_UP_PATH +
  TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_SUB_PATH;
export const TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_SUB_PATH =
  "/calculation-settings";
export const TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_PATH =
  TAX_FILING_1099_FILING_SET_UP_PATH +
  TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_SUB_PATH;
export const TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_SUB_PATH =
  "/state-filing-settings";
export const TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_PATH =
  TAX_FILING_1099_FILING_SET_UP_PATH +
  TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_SUB_PATH;

export const Tax1099Filing = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route
        path={TAX_FILING_1099_FILING_SET_UP_PATH}
        render={() => (
          <PayerSetupFlow
            basePath={TAX_FILING_1099_FILING_SET_UP_PATH}
            onBack={() => {
              history.push(TAX_FILING_1099_FILING_PREVIEW_PATH);
            }}
            onFinish={() => {
              history.push(TAX_FILING_1099_FILING_PREVIEW_PATH);
            }}
          />
        )}
      />

      <Route
        path={TAX_FILING_1099_FILING_UPDATE_PAYER_INFORMATION_PATH}
        component={RouteEditPayerInformation}
      />

      <Route
        path={TAX_FILING_1099_FILING_UPDATE_STATE_FILING_SETTINGS_PATH}
        component={RouteEditStateFiling}
      />

      <Route
        path={TAX_FILING_1099_FILING_UPDATE_CALCULATION_SETTINGS_PATH}
        component={RouteEditCalculationSettings}
      />

      <Route
        path={TAX_FILING_1099_FILING_PREVIEW_PATH}
        component={RoutePreview}
      />

      {/*<Route path={TAX_FILING_PATH} component={RouteInit} />*/}

      <Redirect to={TAX_FILING_1099_FILING_PREVIEW_PATH} from="*" />
    </Switch>
  );
};
