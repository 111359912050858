import {
  WSFormOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IUserUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";

import { useUpdateUserProfile } from "../../../query/users/mutations";
import { FormActions } from "./FormActions";

import styles from "./SettingsUpdateForms.module.scss";

interface DefaultUserProfileSettingFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (e: any) => void;
  label?: string;
}

export const DefaultUserProfileSettingForm: React.FC<
  DefaultUserProfileSettingFormProps
> = ({ onSubmit, field, defaultValues, onCancel }) => {
  const [updateUserProfile, { error: updateUserProfileError }] =
    useUpdateUserProfile();
  const onFormSubmit = async (data: any) => {
    const request: IUserUpdateRequest = {
      profile: {
        [field]: data[field]
      }
    };
    await updateUserProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        [field]: Yup.string().required("This field is required")
      })}
    >
      <WSFormOld.Field name={field}>
        <WSFormOld.Input render={props => <WSTextInput {...props} />} />
        <WSFormOld.Error />
      </WSFormOld.Field>
      <WSText.ParagraphSm weight="medium" className={styles.errorText} mt="XS">
        {updateUserProfileError?.response?.data["error"]}
      </WSText.ParagraphSm>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
