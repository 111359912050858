import {
  useModalContext,
  WSButton,
  WSElement,
  WSFlexBox,
  WSLoader,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ICreditCard,
  IDefaultPaymentMethod
} from "@wingspanhq/users/dist/lib/interfaces";
import queryString from "query-string";
import React, { useEffect } from "react";
import { PlaidReAuth } from "../../../components/PlaidReAuth/PlaidReAuth";
import { getAccountSubName } from "../../../components/WSAccountsListV2/utils";
import { WSInstitutionLogoV2 } from "../../../components/WSInstitutionLogoV2/WSInstitutionLogoV2.component";
import { useUserId } from "../../../query/hooks/helpers";
import { useAccounts, useClientQuery } from "../../../query/users/queries";
import { BankCardLogo } from "../../../shared/components/BankCardLogo";
import { CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL } from "../PaymentMethodsList/ChooseAddPaymentMethodFlowModal";
import styles from "./NextGenSubscriptionPaymentMethod.module.scss";

type NextGenSubscriptionPaymentMethodOwnProps = {
  paymentMethod: IDefaultPaymentMethod | null;
};
type NextGenSubscriptionPaymentMethodProps =
  NextGenSubscriptionPaymentMethodOwnProps;

export const NextGenSubscriptionPaymentMethod: React.FC<
  NextGenSubscriptionPaymentMethodProps
> = ({ paymentMethod }) => {
  const { openModal } = useModalContext();

  const userId = useUserId();
  const clientQuery = useClientQuery(userId);
  const accountsQuery = useAccounts();

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.editPm) {
      const targetEl = document.getElementById(
        "nextGenSubscriptionPaymentMethodSection"
      );
      setTimeout(() => {
        window?.scrollTo({
          behavior: targetEl ? "smooth" : "auto",
          top: targetEl ? targetEl.offsetTop + 700 : 0
        });
      }, 100);
    }
  }, [window.location, window.location.search]);

  const getPaymentMethodFromSavedPaymentMethods = ():
    | ICreditCard
    | undefined => {
    const savedPaymentMethods =
      clientQuery.data?.profile.savedPaymentMethods || [];
    const creditCard = savedPaymentMethods.find(
      pm => pm.paymentMethodId === paymentMethod?.paymentMethodId
    );
    return creditCard;
  };

  return (
    <WSElement id="nextGenSubscriptionPaymentMethodSection">
      {paymentMethod ? (
        <WSFlexBox.CenterY
          justify="space-between"
          className={styles.section}
          shimmer={accountsQuery.isLoading || clientQuery.isLoading}
        >
          <WSElement>
            <WSText.ParagraphSm color="gray500" mt="2XL">
              Payment
            </WSText.ParagraphSm>
            <WSFlexBox.CenterY mt="XL">
              <WSText.ParagraphSm
                color="gray500"
                shimmer={accountsQuery.isLoading}
              >
                {(() => {
                  if (paymentMethod.paymentMethodId) {
                    const card = getPaymentMethodFromSavedPaymentMethods();
                    return (
                      <WSFlexBox.Center>
                        <BankCardLogo
                          className={styles.cardLogo}
                          type="credit"
                          brand={card?.cardBrand}
                        />
                        <WSText.ParagraphSm color="gray500">
                          • • • • {card?.last4}
                        </WSText.ParagraphSm>
                      </WSFlexBox.Center>
                    );
                  }
                  const account = accountsQuery.data?.find(
                    acc => acc.accountId === paymentMethod.accountId
                  );
                  return account ? (
                    <WSFlexBox.Center>
                      <WSInstitutionLogoV2
                        size="M"
                        mr="M"
                        institutionId={account?.institutionId}
                      />
                      <WSText.ParagraphSm mr="XS">
                        {account?.institution}
                      </WSText.ParagraphSm>
                      <WSText.ParagraphSm mr="XL" inline>
                        {getAccountSubName(account)}
                      </WSText.ParagraphSm>
                      <PlaidReAuth accountId={account.accountId}>
                        {({ onOpen, status, loading }) =>
                          status && (
                            <WSElement
                              mx="M"
                              onClick={loading ? undefined : onOpen}
                            >
                              {loading ? (
                                <WSLoader.Spinner size="S" />
                              ) : (
                                <WSText weight="medium" color="red500">
                                  Reconnect
                                </WSText>
                              )}
                            </WSElement>
                          )
                        }
                      </PlaidReAuth>
                    </WSFlexBox.Center>
                  ) : null;
                })()}
              </WSText.ParagraphSm>
            </WSFlexBox.CenterY>
          </WSElement>
          <WSButton.Link
            onClick={() =>
              openModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL, {
                shouldSetSubscriptionPaymentMethod: true
              })
            }
            name="changePaymentMethod"
          >
            Update
          </WSButton.Link>
        </WSFlexBox.CenterY>
      ) : (
        <WSFlexBox.CenterY justify="space-between" className={styles.section}>
          <WSElement>
            <WSText.ParagraphSm weight="medium" color="gray500" mt="2XL">
              Payment method
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray500" mt="XL">
              No payment method yet.
            </WSText.ParagraphSm>
          </WSElement>
          <WSButton.Link
            onClick={() =>
              openModal(CHOOSE_ADD_PAYMENT_METHOD_FLOW_MODAL, {
                shouldSetSubscriptionPaymentMethod: true
              })
            }
            name="changePaymentMethod"
          >
            Add
          </WSButton.Link>
        </WSFlexBox.CenterY>
      )}
    </WSElement>
  );
};
