import { WSElement, WSLabel } from "@wingspanhq/fe-component-library";
import { MethodType } from "../ManageMethods";
import { useMethodLabelProps } from "./useAccountDisplayData";

export type LabelMethodProps = {
  type: MethodType;
  id: string;
};

export const LabelMethod: React.FC<LabelMethodProps> = props => {
  const { isLoading, label } = useMethodLabelProps(props.id, props.type);

  return (
    <WSElement shimmer={isLoading}>
      <WSLabel {...label} />
    </WSElement>
  );
};
