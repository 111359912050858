import {
  WSButtonProps,
  WSTimelineItem
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorV2,
  IForm1099Events,
  IMemberClientForm1099Balances
} from "@wingspanhq/payments/dist/interfaces";
import { ITaxFormSubmissionEvents } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { Snackbar } from "../../../../hooks/useSnackbar";
import {
  getCollaboratorCompany,
  getCollaboratorName,
  getCurrentYearForm1099Balance
} from "../../../../query/payments/selectors";
import { getCurrentFilingYearStr } from "../../../../utils/getCurrentFilingYear";
import download1099NecPdf from "./download1099NecPdf";

type Form1099EventKeys =
  | keyof Omit<IForm1099Events, "correctedVersionSubmittedAt">
  | keyof ITaxFormSubmissionEvents;

type Form1099EventToDescriptionMap = {
  [key in Form1099EventKeys]:
    | string
    | ((collaborator: ICollaboratorV2) => string);
};

const makeGetDescriptionGetter =
  (
    baseText: string,
    getExecutor: (collaborator: ICollaboratorV2) => string | undefined
  ) =>
  (collaborator: ICollaboratorV2) => {
    const executor = getExecutor(collaborator);

    return executor ? `${baseText} by ${executor}` : baseText;
  };

const form1099EventToDescriptionMap: Form1099EventToDescriptionMap = {
  submittedForCollaboratorReviewAt: "Shared for contractor review",
  suggestionMadeAt: makeGetDescriptionGetter(
    "Suggestion made",
    getCollaboratorName
  ),
  suggestionResolvedAt: makeGetDescriptionGetter(
    "Suggestion resolved",
    getCollaboratorCompany
  ),
  acceptedByCollaboratorAt: makeGetDescriptionGetter(
    "Approved",
    getCollaboratorName
  ),
  submittedAt: "Submitted to IRS for filing",
  rejectedByIrsAt: "Rejected by IRS",
  acceptedByIrsAt: "Accepted by IRS",
  deliveredElectronicallyAt: "Delivered electronically",
  deliveredByMailAt: "Delivered by mail",
  sentByMailAt: "Sent by mail",
  mailDeliveryFailedAt: "Mail delivery failed",
  openedByCollaboratorAt: makeGetDescriptionGetter(
    "Opened",
    getCollaboratorName
  ),
  createdAt: "Created",
  updatedAt: "Updated",
  voidedAt: "Voided",
  postFilingSuggestionMadeAt: makeGetDescriptionGetter(
    "Correction suggested",
    getCollaboratorName
  ),
  postFilingSuggestionResolvedAt: "Correction resolved",
  returnedToSenderAt: "Returned to Sender",
  resentByMailAt: "Resent by Mail",
  remailingFailedAt: "Remailing Failed",
  redeliveredByMailAt: "Redelivered by Mail",
  documentCreatedAt: "Document Created",
  mailProcessingAt: "Mail Processing",
  mailInTransitAt: "Main In Transit"
};

const makeGetDescription =
  (collaborator: ICollaboratorV2) => (key: Form1099EventKeys) => {
    const descriptionOrGetter = form1099EventToDescriptionMap[key];

    if (typeof descriptionOrGetter === "string") return descriptionOrGetter;

    return typeof descriptionOrGetter === "function"
      ? descriptionOrGetter(collaborator)
      : "-";
  };

type ITimeLineItemBasic = {
  eventName: string;
  moreActions?: WSButtonProps<"Link">[];
} & WSTimelineItem;

interface ISubmissionEventMetadata {
  index?: number;
  isCorrected?: boolean;
}

const makeGetTimelineItem = (
  collaborator: ICollaboratorV2,
  snackbar: Snackbar
) => {
  const getDescription = makeGetDescription(collaborator);

  return (
    key: Form1099EventKeys,
    datetime: Date,
    metadata?: ISubmissionEventMetadata
  ): ITimeLineItemBasic => {
    const baseTimelineItem = {
      eventName: key,
      date: datetime,
      content: getDescription(key)
    };

    switch (key) {
      case "acceptedByIrsAt":
        return {
          ...baseTimelineItem,
          content: metadata?.isCorrected
            ? "Correction accepted by IRS"
            : "Accepted by IRS",
          icon: "check",
          moreActions: [
            {
              children: "Download PDF",
              icon: "download",
              onClick: () =>
                download1099NecPdf(
                  collaborator.primaryCollaborationId,
                  getCurrentFilingYearStr(),
                  metadata?.index ?? 0,
                  snackbar
                )
            }
          ]
        };
      case "rejectedByIrsAt":
        return {
          ...baseTimelineItem,
          content: metadata?.isCorrected
            ? "Correction rejected by IRS"
            : "Rejected by IRS",
          icon: "exit",
          moreActions: [
            {
              children: "Download PDF",
              icon: "download",
              onClick: () =>
                download1099NecPdf(
                  collaborator.primaryCollaborationId,
                  getCurrentFilingYearStr(),
                  metadata?.index ?? 0,
                  snackbar
                )
            }
          ]
        };
      case "submittedAt":
        return {
          ...baseTimelineItem,
          content: metadata?.isCorrected
            ? "Correction submitted to IRS"
            : "Submitted to IRS",
          moreActions: [
            {
              children: "Download PDF",
              icon: "download",
              onClick: () =>
                download1099NecPdf(
                  collaborator.primaryCollaborationId,
                  getCurrentFilingYearStr(),
                  metadata?.index ?? 0,
                  snackbar
                )
            }
          ]
        };
      case "createdAt":
        return {
          ...baseTimelineItem
        };
      default:
        return baseTimelineItem;
    }
  };
};

type GetTimeLineItemFn = (
  key: Form1099EventKeys,
  datetime: Date,
  metadata?: ISubmissionEventMetadata
) => ITimeLineItemBasic;

function convertEventsObjectToTimelines(
  events: IForm1099Events | ITaxFormSubmissionEvents,
  getTimelineItem: GetTimeLineItemFn,
  metadata?: ISubmissionEventMetadata
): ITimeLineItemBasic[] {
  if (!events || !Object.keys(events).length) {
    return [];
  }
  return Object.entries(events).reduce(
    (eventsArr: ITimeLineItemBasic[], [eventName, eventDate]) => {
      eventsArr.push(
        getTimelineItem(eventName as Form1099EventKeys, eventDate, metadata)
      );
      return eventsArr;
    },
    []
  );
}

function prepareTimelineItemsFrom1099Balances(
  form1099Balances: IMemberClientForm1099Balances,
  getTimelineItem: GetTimeLineItemFn
) {
  const { events, taxForm } = form1099Balances;
  const form1099Events = events
    ? convertEventsObjectToTimelines(events, getTimelineItem)
    : [];
  let submissionEvents =
    taxForm?.submissions && taxForm.submissions?.length > 0
      ? taxForm.submissions.reduce(
          (submissionsArr: ITimeLineItemBasic[], submission, index) => {
            submissionsArr.push(
              ...convertEventsObjectToTimelines(
                submission.events ?? {},
                getTimelineItem,
                { index, isCorrected: submission.isCorrected }
              )
            );
            return submissionsArr;
          },
          []
        )
      : [];
  return [...form1099Events, ...submissionEvents];
}

const payeeWhitelistedEvents = ["submittedAt", "acceptedByIrsAt"];

const getPayeeEvents = (events: ITimeLineItemBasic[]) =>
  events.filter(event => payeeWhitelistedEvents.includes(event.eventName));

const getActivityFrom1099Events = (
  collaborator: ICollaboratorV2,
  snackbar: Snackbar,
  options?: {
    /**
     * Filter only collaborator specific events
     */
    showPayeeEventsOnly?: boolean;
  }
) => {
  const currentYearForm1099Balances =
    getCurrentYearForm1099Balance(collaborator);
  const getTimelineItem = makeGetTimelineItem(collaborator, snackbar);
  if (
    !currentYearForm1099Balances ||
    !Object.keys(currentYearForm1099Balances).length
  ) {
    return [];
  }
  const events = prepareTimelineItemsFrom1099Balances(
    currentYearForm1099Balances,
    getTimelineItem
  );

  const filteredEvents = options?.showPayeeEventsOnly
    ? getPayeeEvents(events)
    : events;

  return filteredEvents.sort(
    (event1, event2) => event1.date?.getTime() - event2.date?.getTime()
  );
};

export default getActivityFrom1099Events;
