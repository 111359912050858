import React from "react";

import {
  AccountStatus,
  IAccount
} from "@wingspanhq/users/dist/lib/interfaces/account";

import styles from "./WSAccountsListV2.module.scss";

import { WSElement, WSElementProps } from "@wingspanhq/fe-component-library";
import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import {
  ICreditCard,
  IDefaultPaymentMethod
} from "@wingspanhq/users/dist/lib/interfaces";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { CreditCardBrandTemplate } from "./CreditCardBrandTemplate";
import { InstitutionTemplate } from "./InstitutionTemplate";
import { PaymentCards } from "./PaymentCards";
import { CreditCardBrand, Institution, PaymentMethodType } from "./types";

export interface WSAccountsListV2Props extends WSElementProps {
  isSingleAccountPreview?: boolean;
  accounts: Array<IAccount>;
  creditCards?: Array<ICreditCard>;
  paymentCards?: Array<IPaymentCard>;
  defaultPaymentMethod?: IDefaultPaymentMethod;
  getInstitutionSubtitle?: (institution: Institution) => string;
  onDeleteInstitution?: (institution: Institution) => void;
  onManageInstitution?: (institution: Institution) => void;
  getAccountSubtitle?: (account: IAccount) => string;
  onEditAccount?: (
    account: IAccount | ICreditCard,
    paymentMethodType?: PaymentMethodType
  ) => void;
  onDeleteAccount?: (
    account: IAccount,
    defaultPaymentMethod?: IDefaultPaymentMethod
  ) => void;
}

export const WSAccountsListV2: React.FC<WSAccountsListV2Props> = ({
  accounts,
  creditCards = [],
  paymentCards = [],
  getInstitutionSubtitle,
  onDeleteInstitution,
  onManageInstitution,
  getAccountSubtitle,
  onEditAccount,
  onDeleteAccount,
  defaultPaymentMethod,
  isSingleAccountPreview,
  ...elementProps
}) => {
  const queryFeatureFlags = useFeatureFlags();
  // group accounts by institution
  const accountsByInstitution: { [institutionId: string]: Institution } = {};

  accounts.forEach(account => {
    const { institutionId = "" } = account;

    if (accountsByInstitution[institutionId]) {
      accountsByInstitution[institutionId].accounts.push(account);
    } else {
      accountsByInstitution[institutionId] = {
        id: institutionId,
        name: account.institution,
        accounts: [account]
      };
    }
  });

  const getInstitutionDetailsFromAccounts = (accountsList: Array<IAccount>) => {
    let lastSynced: Date | undefined;
    let isDisconnected: boolean | undefined;
    accountsList.forEach(account => {
      const { events, status } = account;
      if (events?.transactionsSyncedAt) {
        if (
          lastSynced === undefined ||
          (lastSynced !== undefined && lastSynced < events.transactionsSyncedAt)
        ) {
          lastSynced = events.transactionsSyncedAt;
        }
      }
      if (
        isDisconnected === undefined &&
        status === AccountStatus.Disconnected
      ) {
        isDisconnected = true;
      }
    });
    return {
      lastSynced,
      isDisconnected
    };
  };

  Object.keys(accountsByInstitution).forEach(institutionId => {
    const institution = accountsByInstitution[institutionId];
    const details = getInstitutionDetailsFromAccounts(institution.accounts);
    accountsByInstitution[institutionId] = {
      ...accountsByInstitution[institutionId],
      ...details
    };
  });

  // group credit cards by brands
  const creditCardsByBrand: { [institutionId: string]: CreditCardBrand } = {};
  creditCards.forEach(cc => {
    const { cardBrand } = cc;
    if (cardBrand) {
      if (creditCardsByBrand[cardBrand]) {
        creditCardsByBrand[cardBrand].cards.push(cc);
      } else {
        creditCardsByBrand[cardBrand] = {
          name: cc.cardBrand,
          cards: [cc]
        };
      }
    }
  });
  const institutionNames = Object.keys(accountsByInstitution);
  const creditCardBrands = Object.keys(creditCardsByBrand);
  return (
    <WSElement className={styles.accountsList} {...elementProps}>
      {institutionNames
        .sort((a, b) => {
          const aInstitution = accountsByInstitution[a];
          const bInstitution = accountsByInstitution[b];

          if (!aInstitution.name || !bInstitution.name) {
            return 0;
          }

          return aInstitution.name > bInstitution.name ? 1 : -1;
        })
        .map((institutionId, index) => {
          const institution = accountsByInstitution[institutionId];
          return (
            <WSElement
              key={institutionId}
              data-testid={`institution-container-${institutionId}`}
            >
              <InstitutionTemplate
                institution={institution}
                getInstitutionSubtitle={getInstitutionSubtitle}
                onDeleteInstitution={onDeleteInstitution}
                onManageInstitution={onManageInstitution}
                onEditAccount={onEditAccount}
                onDeleteAccount={onDeleteAccount}
                getAccountSubtitle={getAccountSubtitle}
                defaultPaymentMethod={defaultPaymentMethod}
                isSingleAccountPreview={isSingleAccountPreview}
              />
            </WSElement>
          );
        })}

      {queryFeatureFlags.data?.paymentCardAsPaymentMethod
        ? paymentCards.length > 0 && (
            <PaymentCards
              defaultPaymentCardId={
                defaultPaymentMethod?.paymentMethodId || undefined
              }
              paymentCards={paymentCards}
              onEdit={() => {}}
            />
          )
        : creditCardBrands.map((ccBrandName, index) => {
            const creditCardBrand = creditCardsByBrand[ccBrandName];
            return (
              <WSElement
                key={ccBrandName}
                data-testid={`credit-card-brand-container-${ccBrandName}`}
              >
                <CreditCardBrandTemplate
                  creditCardBrand={creditCardBrand}
                  defaultPaymentMethod={defaultPaymentMethod}
                  onEditAccount={onEditAccount}
                />
              </WSElement>
            );
          })}
    </WSElement>
  );
};
