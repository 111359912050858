import {
  useWSSnackbar,
  WSElement,
  WSGrid,
  WSList,
  WSLoader,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { NotificationType } from "@wingspanhq/notifications/dist/lib/interfaces";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQueryInternalAccounts } from "../../../../query/banking/queries/useQueryInternalAccounts";
import { useUserId } from "../../../../query/hooks/helpers";
import { useDismissNotification } from "../../../../query/notifications/mutations/useDismissNotification";
import { useViewNotification } from "../../../../query/notifications/mutations/useViewNotification";
import { useNotifications } from "../../../../query/notifications/queries/useNotifications";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { selectorHasPayerContextUser } from "../../../../shared/selectors/selectorHasPayerContextUser";
import { selectorIsEnterpriseUser } from "../../../../shared/selectors/selectorIsEnterpriseUser";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { useCustomization } from "../../../customization";
import { BannerBankingVerification } from "../../../Wallet/components/BannerBankingVerification";
import { useModalPromptDismiss } from "../../components/ModalPromptDismiss/useModalPromptDismiss";
import {
  NotificationProps,
  NotificationTemplates
} from "../../components/Notifications";
import { TodoItem } from "../../components/TodoItem";
import { selectorGroupedVisibleNotifications } from "../../selectors/selectorGroupedVisibleNotifications";
import { useTrackClickNotification } from "../../utils/useTrackClickNotification";
import { GettingStartedSidePanel } from "./GettingStartedSidePanel";

export const RouteGettingStarted: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const trackClickNotification = useTrackClickNotification(userId);
  const queryMember = useMemberProfile(userId);
  const modalDismiss = useModalPromptDismiss();
  const queryTasks = useNotifications({
    forceFetchOnMount: true,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });
  const [dismissNotification, metaDismissTask] = useDismissNotification();
  const [readNotification, metaReadTask] = useViewNotification();
  const { openSnackbar } = useWSSnackbar();
  const { branding } = useCustomization();
  const queryInternalAccounts = useQueryInternalAccounts();

  const hasPendingBankingAccount = useMemo(
    () =>
      (queryInternalAccounts.data || []).some(
        internalAccount =>
          internalAccount.type === "Banking" &&
          internalAccount.status === "Pending"
      ),
    [queryInternalAccounts.data]
  );

  if (!queryMember.data) {
    return <WSLoader.Spinner size="M" m="2XL" />;
  }

  const { name: platformName } = branding(true);
  const isPayerView =
    selectorIsEnterpriseUser(queryMember.data.user) ||
    selectorHasPayerContextUser(queryMember.data.user);
  const names = wsName({
    user: queryMember.data.user,
    member: queryMember.data
  });

  const userName = names.getResolvedName(true);

  return (
    <WSPage title={`Welcome to ${platformName}, ${userName}.`}>
      <WSElement shimmer={queryMember.isLoading}>
        {isPayerView ? (
          <WSText mb="XL" data-testid="gettingStartedTitle">
            Here are some things you need to do to make sure {userName} is up
            and running with {platformName}.
          </WSText>
        ) : (
          <WSText mb="XL" data-testid="gettingStartedTitle">
            Here are things you need to do to ensure you’re paid on time.
          </WSText>
        )}
      </WSElement>
      <WSGrid gutter="2XL">
        <WSGrid.Item span={{ s: "8", m: "9" }}>
          <WSList gap="2XL">
            {!isPayerView && hasPendingBankingAccount && (
              <BannerBankingVerification />
            )}

            <WSElement>
              <WSText.Heading4 mb="XL">To-Do</WSText.Heading4>
              <WSQueries queries={{ queryTasks }}>
                {({ queryTasksData }) => {
                  const groupedTasks =
                    selectorGroupedVisibleNotifications(queryTasksData);

                  return (
                    <>
                      {groupedTasks.length === 0 ? (
                        <EmptyState
                          title={
                            isPayerView
                              ? `You’re all set for now.`
                              : `You’re ready to get paid.`
                          }
                          description="We’ll add tasks here as they arise."
                        />
                      ) : (
                        groupedTasks.map(({ group, ...notification }) => {
                          const taskPreset = notification.handle
                            ? NotificationTemplates[
                                notification.handle as keyof typeof NotificationTemplates
                              ]
                            : undefined;

                          const Button = taskPreset?.Footer;
                          const Header = taskPreset?.Header;
                          const taskProps = {
                            ...notification,
                            group
                          } as NotificationProps<never>;
                          const hasGroups = group.length > 1;

                          return (
                            <TodoItem
                              key={notification.channelMessageId}
                              isPending={taskPreset?.isPending}
                              onClick={() => {
                                if (group.length === 1) {
                                  trackClickNotification(notification);
                                }
                              }}
                              viewed={group.every(data => data.isViewed)}
                              body={
                                taskPreset
                                  ? taskPreset.renderBody(taskProps)
                                  : notification.message
                              }
                              buttonAction={
                                taskPreset
                                  ? taskPreset.buttonAction?.(
                                      taskProps,
                                      history
                                    )
                                  : notification.actionURL
                                  ? () => {
                                      const {
                                        hostname,
                                        pathname,
                                        search,
                                        hash
                                      } = new URL(notification.actionURL!);

                                      if (
                                        hostname !== window.location.hostname
                                      ) {
                                        openInNewTab(notification.actionURL!);
                                        return;
                                      }

                                      history.push(pathname + search + hash);
                                    }
                                  : undefined
                              }
                              buttonActionAsync={
                                taskPreset
                                  ? taskPreset.buttonActionAsync?.(
                                      taskProps,
                                      history
                                    )
                                  : undefined
                              }
                              title={
                                taskPreset
                                  ? taskPreset.renderTitle(taskProps)
                                  : notification.subject
                              }
                              status={notification.status}
                              footer={Button ? <Button {...taskProps} /> : null}
                              header={Header ? <Header {...taskProps} /> : null}
                              buttonText={
                                taskPreset
                                  ? taskPreset.buttonText?.(taskProps)
                                  : notification.actionTitle
                              }
                              numberOfTasks={
                                hasGroups ? group.length : undefined
                              }
                              company={
                                notification.context?.client?.logoFileId &&
                                !hasGroups
                                  ? {
                                      logoFileId:
                                        notification.context.client.logoFileId,
                                      name: notification.context.client
                                        .companyName
                                    }
                                  : undefined
                              }
                              onRead={
                                group.some(data => !data.isViewed)
                                  ? () => {
                                      group.forEach(data => {
                                        if (!data.isViewed) {
                                          readNotification(
                                            data.channelMessageId
                                          );
                                        }
                                      });
                                    }
                                  : undefined
                              }
                              onDismiss={
                                notification.type ===
                                NotificationType.Dismissable
                                  ? async () => {
                                      const result = await modalDismiss.open(
                                        {}
                                      );

                                      if (result) {
                                        await dismissNotification(
                                          notification.channelMessageId,
                                          {
                                            onSuccess() {
                                              openSnackbar({
                                                type: "success",
                                                duration: 3000,
                                                message: `${
                                                  taskPreset?.renderTitle(
                                                    taskProps
                                                  ) || notification.subject
                                                } marked as completed`
                                              });
                                            },
                                            onError() {
                                              openSnackbar({
                                                type: "warning",
                                                duration: 3000,
                                                message: `Sorry, something went wrong`
                                              });
                                            }
                                          }
                                        );
                                      }
                                    }
                                  : undefined
                              }
                            />
                          );
                        })
                      )}
                    </>
                  );
                }}
              </WSQueries>
            </WSElement>
          </WSList>
        </WSGrid.Item>
        {queryMember.data && !isPayerView ? (
          <WSGrid.Item span={{ s: "4", m: "3" }}>
            <GettingStartedSidePanel />
          </WSGrid.Item>
        ) : null}
      </WSGrid>
    </WSPage>
  );
};
