import {
  WSElement,
  WSElementProps,
  WSFormOld,
  WSInputMaskOld,
  WSMessageBox,
  WSRadioInputGroup,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { getSsnInputProps } from "../../utils/getSsnInputProps";
import { TinType } from "@wingspanhq/payments/dist/interfaces";
import { Tooltip } from "../../../components/Tooltip";
import {
  SELECT_OPTIONS_BUSINESS_TYPE,
  SELECT_OPTIONS_BUSINESS_TYPE_SSN
} from "../../constants/member";
import { selectorTaxClassificationLabel } from "../../selectors/selectorTaxClassificationLabel";
import { selectorNo1099 } from "../../../modules/1099NECFiling/components/FormTaxpayerInformation/selectorNo1099";
import { TaxFormViewer } from "../../../modules/TaxDocuments/types";

export type FormPartialIdentificationNumberProps = {
  name: string;
  ssnProvided: boolean;
  isTaxFormCorrection?: boolean;
  taxFormViewer?: TaxFormViewer;
} & WSElementProps;

export const FormPartialIdentificationNumber: React.FC<
  FormPartialIdentificationNumberProps
> = ({
  name,
  ssnProvided,
  isTaxFormCorrection,
  taxFormViewer = TaxFormViewer.Payee,
  ...elementProps
}) => {
  return (
    <WSElement {...elementProps}>
      <WSElement mb="2XL">
        <WSText weight="medium" mb="M">
          Identification number
        </WSText>
        <WSFormOld.Field
          mb="XL"
          name={name}
          component={WSRadioInputGroup}
          componentProps={{
            optionHasBorder: true,
            options: [
              {
                label: "Social Security Number (SSN)",
                description:
                  "Ideal for individuals, sole proprietors without a formal business structure. This is your personal 9-digit number.",
                value: TinType.Individual
              },
              {
                label: "Employer Identification Number (EIN)",
                description:
                  "Choose this if you’re representing a legally registered business. If you’ve set up an LLC, Corporation, or Partnership, you likely have an EIN.",
                value: TinType.Business
              }
            ]
          }}
        />

        <WSFormOld.Value name={name}>
          {identificationNumberType => (
            <>
              <WSFormOld.Field
                name="ssn"
                label="SSN"
                component={WSInputMaskOld}
                componentProps={getSsnInputProps({
                  showPlaceholder: true,
                  isValueProvided: ssnProvided
                })}
                hidden={identificationNumberType !== TinType.Individual}
              />
              <WSFormOld.Field
                name="ein"
                label="Employer Identification Number"
                component={WSInputMaskOld}
                componentProps={{
                  mask: "99-9999999",
                  placeholder: "Employer Identification Number",
                  unmask: true,
                  fsExclude: true
                }}
                hidden={identificationNumberType !== TinType.Business}
              />
            </>
          )}
        </WSFormOld.Value>
      </WSElement>

      <WSFormOld.Value name={name}>
        {identificationNumberType => (
          <>
            <WSElement
              mb="2XL"
              hidden={identificationNumberType === TinType.Business}
            >
              <WSText weight="medium" mb="XS">
                What is your legal name?
              </WSText>
              <WSFormOld.Field
                mb="M"
                name="firstName"
                component={WSTextInput}
                componentProps={{ placeholder: "First name" }}
              />
              <WSFormOld.Field
                mb="XS"
                name="lastName"
                component={WSTextInput}
                componentProps={{ placeholder: "Last name" }}
              />
              <WSText.ParagraphSm color="gray400">
                Your name should match exactly with what's listed on your
                federal tax return to avoid discrepancies with the IRS.
              </WSText.ParagraphSm>
            </WSElement>

            <WSElement
              mb="2XL"
              hidden={identificationNumberType === TinType.Individual}
            >
              <WSText weight="medium" mb="XS">
                Business name{" "}
                <Tooltip placement="right">
                  <WSText weight="medium" color="gray50">
                    Business name
                  </WSText>
                  <WSText.ParagraphSm color="gray50" mt="M">
                    Your name should match exactly with what's listed on your
                    federal tax return to avoid discrepancies with the IRS.
                  </WSText.ParagraphSm>
                </Tooltip>
              </WSText>
              <WSFormOld.Field
                name="businessName"
                component={WSTextInput}
                componentProps={{ placeholder: "Warren Workshop Co." }}
                description="Your name should match exactly with what's listed on your federal tax return to avoid discrepancies with the IRS."
              />
            </WSElement>

            <WSElement
              mb="2XL"
              hidden={identificationNumberType === TinType.Individual}
            >
              <WSText weight="medium" mb="XS">
                Business name/disregarded entity (optional)
              </WSText>
              <WSFormOld.Field
                name="disregardedEntityName"
                component={WSTextInput}
                componentProps={{ placeholder: "Warren Workshop Company" }}
              />
            </WSElement>
          </>
        )}
      </WSFormOld.Value>

      <WSElement mb="2XL">
        <WSText weight="medium" mb="M">
          Federal tax classification
        </WSText>
        <WSFormOld.Value name="identificationNumberType">
          {identificationNumberType => (
            <WSFormOld.Field
              key={identificationNumberType}
              name="taxClassification"
              component={WSSelectOld}
              componentProps={{
                options:
                  identificationNumberType === TinType.Business
                    ? SELECT_OPTIONS_BUSINESS_TYPE
                    : SELECT_OPTIONS_BUSINESS_TYPE_SSN
              }}
            />
          )}
        </WSFormOld.Value>

        <WSFormOld.Value name="taxClassification">
          {taxClassification =>
            selectorNo1099(taxClassification) ? (
              isTaxFormCorrection ? (
                <WSMessageBox.Info noBorder mt="S">
                  <WSText.ParagraphSm weight="medium" mb="S">
                    Note:
                  </WSText.ParagraphSm>
                  {taxFormViewer === TaxFormViewer.Payer ? (
                    <>
                      <WSText.ParagraphSm mb="M" color="gray500">
                        As a {selectorTaxClassificationLabel(taxClassification)}
                        , recipient is not eligible for a 1099 according to IRS
                        guidelines. Please verify this is accurate before
                        proceeding.
                      </WSText.ParagraphSm>

                      <WSText.ParagraphSm mb="M" color="gray500">
                        If the you accept and file this correction with the IRS,
                        it will void the original 1099 filing.
                      </WSText.ParagraphSm>
                    </>
                  ) : (
                    <>
                      <WSText.ParagraphSm mb="M" color="gray500">
                        As a {selectorTaxClassificationLabel(taxClassification)}
                        , you're not eligible for a 1099 according to IRS
                        guidelines. Please verify this is accurate before
                        proceeding.
                      </WSText.ParagraphSm>

                      <WSText.ParagraphSm mb="M" color="gray500">
                        If the payer accepts and files this correction with the
                        IRS, it will void the original 1099 filing.
                      </WSText.ParagraphSm>
                    </>
                  )}
                </WSMessageBox.Info>
              ) : (
                <WSMessageBox.Info noBorder mt="S">
                  <WSText.ParagraphSm weight="medium" mb="S">
                    No 1099-NEC form will be issued
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm mb="M" color="gray500">
                    As a {selectorTaxClassificationLabel(taxClassification)},
                    you're not eligible for a 1099 according to IRS guidelines.
                    Instead, Wingspan will provide a summary of your 2023
                    payments to assist you during tax season. If this doesn't
                    align with your business structure, update your details
                    promptly.
                  </WSText.ParagraphSm>
                </WSMessageBox.Info>
              )
            ) : null
          }
        </WSFormOld.Value>
      </WSElement>
    </WSElement>
  );
};
