import { DashboardFocusedFeatureContext } from "../useGetDashboardFocusedFeaturesContext";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces";

export const selectorIsTaxOnlyPayerContext = (
  context: DashboardFocusedFeatureContext
) => {
  return (
    context.user.contexts?.length === 1 &&
    context.user.contexts.includes(PlatformContextType.TaxOnlyPayer)
  );
};
