import {
  BankingInternalAccount,
  getInternalAccount
} from "../../../services/api/banking/internalAccounts";
import { queryCache, QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../../query/helpers";
import { QUERY_INTERNAL_ACCOUNT } from "../../../query/banking/queries/useQueryInternalAccount";

export const QUERY_INTERNAL_ACCOUNT_BY_IDS = "QUERY_INTERNAL_ACCOUNT_BY_IDS";

export const useInternalAccountsForStatements = (
  internalAccountIds: string[],
  config?: QueryConfig<BankingInternalAccount[], WSServiceError>
) =>
  useWSQuery<BankingInternalAccount[], WSServiceError>(
    [QUERY_INTERNAL_ACCOUNT_BY_IDS, internalAccountIds],
    async () => {
      const actions = internalAccountIds.map(internalAccountId => async () => {
        const cachedInternalAccount =
          queryCache.getQueryData<BankingInternalAccount>([
            QUERY_INTERNAL_ACCOUNT,
            internalAccountId
          ]);

        if (cachedInternalAccount) {
          return cachedInternalAccount;
        }

        const internalAccount = await getInternalAccount(internalAccountId);

        queryCache.setQueryData(
          [QUERY_INTERNAL_ACCOUNT, internalAccountId],
          internalAccount
        );

        return internalAccount;
      });

      const allAccounts = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return allAccounts;
    },
    config
  );
