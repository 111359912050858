import {
  WSMenuItem,
  WSPanelProps,
  WSTable,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useCallback, useMemo } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { SidePanelSection } from "../../../components/SidePanel";
import { UrlIdKey } from "../../../constants/routing";
import use1099NECDownload from "../../../modules/1099NECFiling/deprecated/hooks/use1099NECDownload";
import { NEC_1099_SUMMARY_SLUG } from "../../../shared/constants/nec1099";
import { getCurrentFilingYearStr } from "../../../utils/getCurrentFilingYear";
import DocumentMeta from "./DocumentMeta";

export enum DocumentsSectionType {
  Client = "Client",
  Collaborator = "Collaborator"
}

const DocumentTitle: React.FC<{ data: DocumentMeta }> = ({ data }) => (
  <WSText>
    {data.title}
    <WSText inline color="gray400" ml="XS">
      {data.year}
    </WSText>
  </WSText>
);

const DocumentAmount: React.FC<{ amount: number }> = ({ amount }) => (
  <WSText formatMoney color="gray400" mt="XS">
    {amount}
  </WSText>
);

const OWNER_URL_ID_KEYS: { [key in DocumentsSectionType]: UrlIdKey } = {
  [DocumentsSectionType.Client]: UrlIdKey.Client,
  [DocumentsSectionType.Collaborator]: UrlIdKey.Collaborator
};

export interface DocumentsProps extends WSPanelProps {
  type: DocumentsSectionType;
  documents: DocumentMeta[];
  renderBadge?: (data: WSTableItem<DocumentMeta>) => React.ReactNode;
  showAmounts?: boolean;
  testid?: string;
}

const TaxDocumentsSection: React.FC<DocumentsProps> = ({
  type,
  documents,
  showAmounts,
  renderBadge,
  testid,
  ...otherProps
}) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ [key in UrlIdKey]: string }>();

  const currentFilingYearStr = useMemo(() => getCurrentFilingYearStr(), []);

  const ownerId = match.params[OWNER_URL_ID_KEYS[type]];

  const { handle1099NECDownload, ConditionalConsentDialog } =
    use1099NECDownload(ownerId, type === DocumentsSectionType.Client);

  const tableData = useMemo(
    () => documents.map(doc => ({ id: doc.year, data: doc })),
    [documents]
  );

  const renderDocInfo = useCallback(
    ({ data }: WSTableItem<DocumentMeta>) => (
      <>
        <DocumentTitle data={data} />
        {showAmounts && <DocumentAmount amount={data.amount} />}
      </>
    ),
    [showAmounts]
  );

  const columnsConfig = useMemo(() => {
    const badgeColumnConfig = renderBadge
      ? [
          {
            config: {
              gridTemplateSizeMin: "auto",
              gridTemplateSizeMax: "auto"
            },
            renderFunction: renderBadge
          }
        ]
      : [];

    return [
      {
        config: {
          gridTemplateSizeMin: "auto",
          gridTemplateSizeMax: "1fr"
        },
        renderFunction: renderDocInfo
      },
      ...badgeColumnConfig
    ];
  }, [renderBadge, renderDocInfo]);

  const getDownloadPdfAction = useCallback(
    (docMeta: DocumentMeta) => {
      const latestFormIndex = docMeta.latest1099NECIndex;

      if (typeof latestFormIndex === "undefined") return [];

      return [
        {
          label: "Download PDF",
          icon: "download" as const,
          onClick: () => handle1099NECDownload(latestFormIndex, docMeta.year)
        }
      ];
    },
    [handle1099NECDownload]
  );

  const getActions = useCallback(
    ({ data: docMeta }: { data: DocumentMeta }): WSMenuItem[] => [
      ...(docMeta.year === currentFilingYearStr
        ? [
            {
              label: "View",
              icon: "search" as const,
              onClick: () => {
                history.push(
                  type === DocumentsSectionType.Client
                    ? `${location.pathname}/${NEC_1099_SUMMARY_SLUG}`
                    : `/member/1099-filing/dashboard/all/${docMeta.memberId}`
                );
              }
            }
          ]
        : []),
      ...getDownloadPdfAction(docMeta)
    ],
    [
      currentFilingYearStr,
      getDownloadPdfAction,
      history,
      type,
      location.pathname
    ]
  );

  return (
    <>
      <ConditionalConsentDialog />

      <SidePanelSection data-testid={testid} {...otherProps}>
        <WSText.Heading5 mb="M">Tax documents</WSText.Heading5>
        <WSTable
          showHeader={false}
          columns={columnsConfig}
          rowMenuActions={getActions}
          tableData={tableData}
        />
      </SidePanelSection>
    </>
  );
};

export default TaxDocumentsSection;
