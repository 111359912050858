import {
  WSDivider,
  WSList,
  WSListProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { openInNewTab } from "../../utils/openInNewTab";
import React from "react";
import styles from "./styles.module.scss";

export type FAQSection = {
  title: string;
  links: { title: string; url: string }[];
};

export type FAQProps = {
  title?: string;
  sections: FAQSection[];
} & WSListProps;

const link = (url: string) => () => {
  openInNewTab(url);
};

const Link: React.FC<{ url: string; text: string }> = ({ url, text }) => (
  <WSText.ParagraphXs
    color="blue400"
    weight="medium"
    className={styles.link}
    onClick={link(url)}
  >
    {text}
  </WSText.ParagraphXs>
);

export const FAQ: React.FC<FAQProps> = ({
  sections,
  title = "Frequently asked questions",
  ...props
}) => {
  return (
    <WSList gap="XL" {...props}>
      {title && <WSText.Paragraph weight="medium">{title}</WSText.Paragraph>}
      <WSPanel p="XL" colorBackground="gray50" noBorder>
        <WSList gap="2XL">
          {sections.map((section, index) => (
            <WSList gap="M" key={section.title + index}>
              <WSText.ParagraphSm color="gray400">
                {section.title}
              </WSText.ParagraphSm>
              <WSDivider />

              {section.links.map(({ url, title }, index) => (
                <Link key={title + index} url={url} text={title} />
              ))}
            </WSList>
          ))}
        </WSList>
      </WSPanel>
    </WSList>
  );
};
