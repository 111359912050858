import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import { PartialRejection } from "@flatfile/sdk";
import { FlatfileError } from "@flatfile/sdk/dist/errors/FlatfileError";

import {
  WSButton,
  WSButtons,
  WSFormOld,
  WSMessageBox,
  WSPanel,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  CustomFieldResourceType,
  IBulkCollaboratorBatch
} from "@wingspanhq/payments/dist/interfaces";

import { defaultCollaboratorGroupOption } from "../../../../Invoices/screens/bulkUploadCollaborators/BulkUploadCollaboratorsStep2";
import { WSQueries } from "../../../../query/WSQuery";
import { useCollaboratorGroupsQuery } from "../../../../query/payments/queries";

import styles from "../../components/styles.module.scss";
import { useCustomFieldsAll } from "../../../../query/customFields/queries/useCustomFieldsAll";
import { useFlatfileErrorModal } from "../../../BulkImporter/components/FlatfileError/useFlatfileErrorModal";
import { BulkResource } from "../../../BulkImporter/types";
import { useFlatfile } from "../../../../hooks/useFlatfile";
import {
  flatfileConfig,
  flatfileTheme
} from "../../../BulkImporter/utils/flatfileThemeConfig";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { pullSessionToken } from "../../../../services/sessionStorage";
import {
  useBulkCollaboratorBatch,
  useBulkCollaboratorBatchSummary
} from "../../../BulkImporter/query/bulkCollaborator/queries";
import { processFlatfileChunk } from "../../../BulkImporter/utils/flatfileBulkCollaborator";
import useSnackbar from "../../../../hooks/useSnackbar";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";
import { EditLayout } from "../../components/EditLayout";
import { SetupImportRecipientsComponentProps } from "./index";
import {
  useAllOrganizationUsers,
  useUserProfile
} from "../../../../query/users/queries";
import { KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID } from "../../constants/storage";
import { OverlaySpinner } from "../../../../components/OverlaySpinner";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { useUpdateBulkCollaboratorBatch } from "../../../BulkImporter/query/bulkCollaborator/mutations";
import { useUserId } from "../../../../query/hooks/helpers";
import { useEngagementsListAllQuery } from "../../../../query/engagements/queries/useEngagementsListAllQuery";

export const RouteImportRecipients: React.FC<
  SetupImportRecipientsComponentProps
> = ({ params, onBack, onNext }) => {
  const Layout = params?.Layout || EditLayout;
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;
  const history = useHistory();
  const { warning } = useSnackbar();
  const userId = useUserId();

  const [selectedCollaboratorGroup, setSelectedCollaboratorGroup] = useState(
    defaultCollaboratorGroupOption
  );
  const [selectedEngagement, setSelectedEngagement] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const queryUser = useUserProfile(userId);

  const qAllOrganizationUsers = useAllOrganizationUsers();
  const qCollaboratorCustomFields = useCustomFieldsAll({
    resourceType: [CustomFieldResourceType.Collaborator]
  });
  const collaboratorCustomFields = qCollaboratorCustomFields.data || [];

  const qCollaboratorBulkBatch = useBulkCollaboratorBatch(bulkBatchId, {
    enabled: !!bulkBatchId
  });
  const qCollaboratorBulkBatchItems =
    useBulkCollaboratorBatchSummary(bulkBatchId);
  const qCollaboratorGroups = useCollaboratorGroupsQuery();
  const queryEngagements = useEngagementsListAllQuery();
  const [updateBulkCollaboratorBatch, updateBulkCollaboratorBatchMeta] =
    useUpdateBulkCollaboratorBatch();

  const downloadExampleCollaborators = [
    {
      email: "wade@wingspan.app",
      contractorId: "",

      organizationAccount: "",

      firstName: "Wade",
      lastName: "Warren",
      phoneNumber: "",

      legalBusinessName: "",
      companyStructure: "",
      disregardedEntityName: "",

      ein: "",
      ssn: "",

      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: ""
    }
  ];
  const downloadTemplateCsvProps = {
    filename: `Wingspan_Bulk_Upload_Contractors_Template.csv`,
    headers: [
      { label: "Email", key: "email" },
      { label: "External Contractor ID", key: "contractorId" }, // externalId

      { label: "Sub Organization Name", key: "organizationAccount" },

      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Phone number", key: "phoneNumber" },

      {
        label: "W-9 Legal Business Name",
        key: "legalBusinessName"
      },
      {
        label: "W-9 Disregarded Entity Name",
        key: "disregardedEntityName"
      },
      { label: "W-9 Company Structure", key: "companyStructure" },
      { label: "EIN", key: "ein" },
      { label: "SSN", key: "ssn" },

      { label: "Address Line1", key: "addressLine1" },
      { label: "Address Line2", key: "addressLine2" },
      { label: "City", key: "city" },
      { label: "State", key: "state" },
      { label: "Postal Code", key: "postalCode" },
      { label: "Country", key: "country" },
      ...collaboratorCustomFields.map(field => ({
        label: field.name,
        key: field.key
      }))
    ],
    data: downloadExampleCollaborators
  };

  // Flatfile error handling
  const flatfileErrorModal = useFlatfileErrorModal();

  const onFlatfileError = (error: FlatfileError) => {
    flatfileErrorModal.open({
      error,

      batchId: bulkBatchId,
      bulkResource: BulkResource.Collaborator,
      onStartNewImport: bulkBatch => {
        history.push(
          `/member/1099-filing/set-up/recipients-import/${
            (bulkBatch as IBulkCollaboratorBatch).bulkCollaboratorBatchId
          }`
        );
      }
    });
  };

  // Flatfile initialization
  const EMBED_ID = process.env
    .REACT_APP_FLATFILE_NEC_1099_FILING_RECIPIENTS_EMBED_ID as string;

  const flatfile = useFlatfile({
    embedId: EMBED_ID,
    onError: onFlatfileError
  });

  return (
    <Layout title="Add recipients">
      {updateBulkCollaboratorBatchMeta.isLoading && <OverlaySpinner />}
      <WSQueries
        queries={{
          queryUser,
          qCollaboratorGroups,
          qAllOrganizationUsers,
          qCollaboratorCustomFields,
          queryEngagements
        }}
      >
        {({
          queryUserData: user,
          qCollaboratorGroupsData: collaboratorGroups,
          queryEngagementsData: engagements,
          qAllOrganizationUsersData: allOrganizationUsers,
          qCollaboratorCustomFieldsData: collaboratorCustomFields
        }) => {
          const collaboratorGroupOptions = [
            defaultCollaboratorGroupOption,
            ...collaboratorGroups.map(collaboratorGroup => ({
              label: collaboratorGroup.name,
              value: (collaboratorGroup as any).collaboratorGroupId
            }))
          ];

          const engagementOptions = engagements
            .map(engagement => ({
              label: engagement.name,
              value: engagement.engagementId
            }))
            .filter(option => option.label && option.value); // filter out empty values

          const onSelectCollaboratorGroup = (value: any) => {
            const collaboratorGroup = collaboratorGroupOptions.find(
              cg => cg.value === value
            );
            setSelectedCollaboratorGroup(
              collaboratorGroup ?? defaultCollaboratorGroupOption
            );
          };

          const onSelectEngagement = (value: any) => {
            const engagement = engagementOptions.find(e => e.value === value);
            setSelectedEngagement(engagement ?? null);
          };

          // Flatfile import data
          const importDataV3 = async () => {
            flatfile.requestDataFromUser({
              ...flatfileConfig,
              theme: {
                ...flatfileTheme
              },
              onInit: ({ session }) => {
                session.updateEnvironment({
                  selectedCollaboratorGroupId: selectedCollaboratorGroup?.value,
                  selectedCollaboratorGroupName:
                    selectedCollaboratorGroup?.label,
                  selectedEngagementName: selectedEngagement?.label || "",
                  selectedEngagementId: selectedEngagement?.value || "",
                  isProdEnv: IS_PRODUCTION_ENV,
                  authToken: pullSessionToken(),
                  bulkBatchId
                });

                session.on("close", () => {
                  // Just refetch the batch items query to update the batch details view
                  // so that user won't upload the spreadsheet again in the same batch.
                  qCollaboratorBulkBatchItems.refetch();
                });
              },
              customFields: collaboratorCustomFields.map(({ key, name }) => {
                return {
                  field: key,
                  label: name,
                  // FIX: boolean fields are not supported in Flatfile
                  type: "string"
                };
              }),
              /**
               * Flatfile streams the uploaded spreadsheet data as chunks of size 1000 (default) to onData
               * callback function where we can process the spreadsheet data (like making an external API call)
               * and reject any spreadsheet row(s) if there are any failures during processing.
               */
              onData: async (chunk, next) => {
                const rejections = await processFlatfileChunk(
                  chunk,
                  bulkBatchId,
                  {
                    engagementId: selectedEngagement?.value,
                    collaboratorGroupId: selectedCollaboratorGroup.value
                  },
                  collaboratorCustomFields,
                  allOrganizationUsers,
                  true // shouldCreateTaxForms
                );
                next(new PartialRejection(rejections));
              },
              onComplete: async payload => {
                const csvRecords = await payload.data();
                const csvRecipients = csvRecords.rows
                  .filter(row => row.valid)
                  .map(row => row.data);

                console.group("Bulk Import Recipients");
                console.log("CSV Recipients", csvRecipients);
                console.groupEnd();

                if (csvRecipients.length === 0) {
                  warning(
                    "Sorry, Looks like the uploaded spreadsheet is empty!"
                  );
                  return;
                }
                await updateBulkCollaboratorBatch({
                  id: bulkBatchId,
                  data: {
                    labels: {
                      flatfileBatchId: payload.batchId,
                      ...(selectedCollaboratorGroup.value !==
                      defaultCollaboratorGroupOption.value
                        ? {
                            [BATCH_LABELS_WS_KEYS.bulkImporterCollaboratorGroupId]:
                              selectedCollaboratorGroup.value
                          }
                        : {})
                    }
                  }
                });
                // save the bulk batch id in local storage to use it for redirection
                // make sure we are removing it once the import is completed
                window.localStorage.setItem(
                  KEY_IMPORT_RECIPIENTS_BULK_BATCH_ID,
                  bulkBatchId
                );
                onNext({
                  bulkBatchId
                });
              }
            });
          };

          const uploadSpreadsheet = () => {
            importDataV3();
          };

          const customFieldsListDisplayValue = collaboratorCustomFields
            .map(customField =>
              customField.required
                ? `${customField.name} (Required)`
                : customField.name
            )
            .join(", ");

          return (
            <>
              <WSText.Heading4>Batch add recipients</WSText.Heading4>
              <WSText weight="medium" mt="XL">
                Upload recipients for 1099s
              </WSText>

              <WSText.ParagraphSm mt="M" color="gray400">
                Welcome to Wingspan's 1099 recipient batch upload feature. If
                you've been making payments outside of Wingspan or switched to
                Wingspan mid-year, this is your seamless pathway to manage 1099s
                for all your recipients. You can upload as often as you like
                until the January 25th filing deadline.
              </WSText.ParagraphSm>

              <WSText.Heading5 mt="XL">Preparing your document</WSText.Heading5>
              <WSMessageBox.Info mt="XL" size="Large">
                <WSText.ParagraphSm weight="medium" color="gray600">
                  ESSENTIAL COLUMN (REQUIRED)
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="XS" color="gray500">
                  Identifier: Provide either an{" "}
                  <WSText.ParagraphSm weight="medium" inline color="gray600">
                    email address
                  </WSText.ParagraphSm>{" "}
                  or{" "}
                  <WSText.ParagraphSm weight="medium" inline color="gray600">
                    external ID
                  </WSText.ParagraphSm>
                  .
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  At a minimum, the essential column and information must be
                  provided. The remaining columns below are optional. We
                  encourage you to include the information if you have it. A
                  template is available for download to get you started.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  To learn more, follow this step-by-step{" "}
                  <WSButton.Link
                    type="button"
                    onClick={() =>
                      openInNewTab(
                        "https://docs.wingspan.app/docs/importing-recipients-from-off-platform-to-wingspan"
                      )
                    }
                  >
                    batch uploading guide
                  </WSButton.Link>
                </WSText.ParagraphSm>
              </WSMessageBox.Info>

              <WSPanel.BS0 mt="2XL">
                <WSText.ParagraphSm weight="medium" color="gray600">
                  OPTIONAL COLUMNS
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  First Name: Must match IRS records. (Required if SSN is
                  provided)
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Last Name: Must match IRS records. (Required if SSN is
                  provided)
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Sub-Organization Name: If using Organization Accounts, assign
                  a recipient to a sub-org.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  External ID: For your internal tracking.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Phone Number: Provide a contact number for the recipient.
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" mt="XL" color="gray600">
                  W-9 FIELDS
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  SSN or EIN: Use one or the other, but not both.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Legal Business Name: Required if EIN is provided.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Disregarded Entity Name: If different from the legal business
                  name.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Company Structure: Specify if SSN or EIN is provided.
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" mt="XL" color="gray600">
                  ADDITIONAL INFORMATION
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Country, Address Line 1, City, State, Postal Code: If
                  providing an address, follow ISO 3166-2 standards.
                </WSText.ParagraphSm>
                <WSText.ParagraphSm mt="M" color="gray500">
                  Custom Fields:{" "}
                  {customFieldsListDisplayValue ||
                    "Add any contractor custom fields as needed."}
                </WSText.ParagraphSm>

                <WSText.ParagraphSm weight="medium" mt="XL" color="gray600">
                  FORMATTING GUIDELINES
                </WSText.ParagraphSm>

                <WSText.ParagraphSm mt="M" color="gray500">
                  Only A-Z, 0-9, hyphens, and ampersands are allowed for names
                  due to IRS restrictions.
                </WSText.ParagraphSm>
              </WSPanel.BS0>

              <WSText.Heading5 mt="XL">What to expect</WSText.Heading5>
              <WSText.ParagraphSm mt="M" color="gray400">
                In order for Wingspan to prepare a Form 1099-NEC for a
                recipient, specific details are needed for IRS submission,
                including legal name and address, among others.
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mt="M" color="gray400">
                Upon successful upload, your recipients will receive an invite
                to join Wingspan and will be asked to verify their tax
                information. If W-9 details are provided, recipients will
                confirm this with the last four digits of their Tax
                Identification Number (TIN).
              </WSText.ParagraphSm>

              {/* Show engagement selection only for non-default engagements */}
              {user.labels?.engagements && engagementOptions.length > 1 && (
                <WSFormOld.Field
                  name="engagementId"
                  label="Engagement"
                  component={WSSelectOld}
                  componentProps={{
                    options: engagementOptions,
                    onChange: onSelectEngagement
                  }}
                />
              )}

              {!user.labels?.engagements && collaboratorGroups.length > 0 && (
                <WSFormOld
                  defaultValues={{
                    collaboratorGroupId: selectedCollaboratorGroup.value
                  }}
                >
                  <WSFormOld.Field
                    name="collaboratorGroupId"
                    label="Contractor group"
                    component={WSSelectOld}
                    componentProps={{
                      options: collaboratorGroupOptions,
                      onChange: onSelectCollaboratorGroup
                    }}
                    my="XL"
                  />
                </WSFormOld>
              )}

              <WSButtons mt="3XL">
                <CSVLink {...downloadTemplateCsvProps}>
                  <WSButton.Secondary
                    className={cn(styles.actionBtn, styles.downloadBtn)}
                    fullWidth
                    icon="download"
                    p="S"
                  >
                    Download template
                  </WSButton.Secondary>
                </CSVLink>

                <WSButton.Primary
                  className={cn(styles.actionBtn, styles.uploadBtn)}
                  fullWidth
                  icon="upload"
                  p="S"
                  data-testid="importRecipients"
                  loading={updateBulkCollaboratorBatchMeta.isLoading}
                  onClick={uploadSpreadsheet}
                >
                  Import recipients
                </WSButton.Primary>
              </WSButtons>
            </>
          );
        }}
      </WSQueries>
    </Layout>
  );
};
