import {
  WSActions,
  WSFlexBox,
  WSForm,
  WSInputNumber,
  WSInputText,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { FormFieldSelectPayeeEngagement } from "../../../modules/Payees/components/SelectPayeeEngagement/FormField";

type FormData = {
  payee: {
    payeeId: string;
    payerPayeeEngagementId: string;
  } | null;
  description: string;
  amount: number | null;
};

type Props = {
  defaultValues?: FormData;
  onClose: () => void;
  onSubmit: (values: FormData) => void;
  onDelete?: () => void;
};

export const ModalInvoiceCollaboratorForm: React.FC<Props> = ({
  defaultValues,
  onClose,
  onSubmit,
  onDelete
}) => {
  return (
    <WSForm<FormData>
      defaultValues={
        defaultValues || {
          payee: null,
          description: "",
          amount: null
        }
      }
      validationSchema={Yup.object().shape({
        payee: Yup.object().shape({
          payeeId: Yup.string().required("Required"),
          payerPayeeEngagementId: Yup.string().required("Required")
        }),
        description: Yup.string().required("Required"),
        amount: Yup.number().nullable().required("Required")
      })}
      onSubmit={values => {
        onSubmit(values);
        onClose();
      }}
    >
      <WSText.Heading5 mb="L">Add invoice contractor</WSText.Heading5>
      <WSFlexBox direction="column" gap="M">
        <FormFieldSelectPayeeEngagement name="payee" />
        <WSForm.Field
          name="description"
          label="Description"
          component={WSInputText}
        />
        <WSForm.Field name="amount" label="Amount" component={WSInputNumber} />
      </WSFlexBox>

      <WSActions
        mt="L"
        buttons={[
          {
            kind: "Secondary",
            label: "Delete item",
            destructive: true,
            onClick: () => {
              onDelete?.();
              onClose();
            },
            type: "button",
            visible: !!onDelete
          },
          {
            kind: "Secondary",
            label: "Cancel",
            onClick: onClose,
            type: "button"
          },
          {
            kind: "Primary",
            label: "Save",
            type: "submit"
          }
        ]}
      />
    </WSForm>
  );
};
