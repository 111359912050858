import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { useBrowserPageTitle } from "../../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSNavHistoryListItem } from "@wingspanhq/fe-component-library/dist/lib/components/common/WSNavHistoryList";
import { Main1099LayoutModule } from "../Main1099Layout/Main1099Layout.module";
import {
  TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_PATH,
  TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_PATH,
  TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_PATH
} from "../../index";

interface PayerSetupLayoutProps {
  title: string;
  children: ReactNode;
  onBack?: () => void;
}

const sections: WSNavHistoryListItem[] = [
  {
    key: "PayerInformation",
    title: "Payer information"
  },
  // {
  //   key: "Recipients",
  //   title: "Recipients"
  // },
  {
    key: "CalculationSettings",
    title: "Calculation settings"
  },
  {
    key: "StateFilingSettings",
    title: "State filing settings"
  }
];

const getActiveSection = (pathname: string) => {
  switch (pathname) {
    case TAX_FILING_1099_FILING_SET_UP_CONFIRM_PAYER_INFO_PATH:
      return "PayerInformation";
    // case "/member/1099-filing/set-up/import-collaborators":
    // case "/member/1099-filing/set-up/select-import-collaborators":
    //   return "Recipients";
    case TAX_FILING_1099_FILING_SET_UP_CALCULATION_SETTINGS_PATH:
      return "CalculationSettings";
    case TAX_FILING_1099_FILING_SET_UP_STATE_FILING_SETTINGS_PATH:
      return "StateFilingSettings";
    default: {
      return "Recipients";
    }
  }
};

export const PayerSetupLayout: React.FC<PayerSetupLayoutProps> = ({
  title,
  children,
  onBack
}) => {
  useBrowserPageTitle(title);
  const location = useLocation();

  return (
    <Main1099LayoutModule
      title={title}
      sections={sections}
      activeSection={getActiveSection(location.pathname)}
      onSaveAndExit={onBack}
    >
      {children}
    </Main1099LayoutModule>
  );
};
