import { INewUser } from "@wingspanhq/users/dist/lib/interfaces";
import { format } from "date-fns";
import { getVerificationMissingData } from "../../../../services/api/onboarding/missingFields";
import { operations } from "../../../../services/api/onboarding/types";
import { FormDataBusinessInformation } from "../../components/FormBusinessInformation";
import { phoneFormDataToE164 } from "../../components/FormPartialPhoneNumber";
import { FormDataPersonalInformation } from "../../components/FormPersonalInformation";
import { FormDataRepresentative } from "../../components/FormRepresentative";

export async function getIndividualData(
  formData: FormDataPersonalInformation,
  user: INewUser,
  verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"],
  forceSetUserData?: boolean
) {
  const data: {
    [key: string]: unknown;
  } = {
    firstName: formData.person.firstName || undefined,
    middleName: formData.person.middleName || undefined,
    lastName: formData.person.lastName || undefined,
    occupation: formData.person.jobTitle || undefined,
    dateOfBirth: formData.person.birthday
      ? format(formData.person.birthday, "yyyy-MM-dd")
      : undefined,
    country: formData.person.country || undefined,
    individualTaxId: formData.person.taxId || undefined,
    addressLine1: formData.person.address.addressLine1 || undefined,
    addressLine2: formData.person.address.addressLine2 || undefined,
    city: formData.person.address.city || undefined,
    region: formData.person.address.state || undefined,
    postalCode: formData.person.address.postalCode || undefined
  };

  const missingData = await getMissingData(verificationLevel);

  if (forceSetUserData || missingData?.requiredFields?.includes("email")) {
    data.email = user.email;
  }

  if (
    forceSetUserData ||
    missingData?.requiredFields?.includes("phoneNumber")
  ) {
    data.phoneNumber = user.phone.number || undefined;
  }

  return data;
}

export function getBusinessData(
  formData: FormDataBusinessInformation,
  country: string,
  userEmail: string
) {
  return {
    legalBusinessName: formData.legalBusinessName || undefined,
    doingBusinessAs: formData.dba || undefined,
    federalTaxClassification: formData.taxClassification || undefined,
    businessTaxId: formData.taxId || undefined,
    industry: formData.industry || undefined,
    email:
      (formData.usePersonalEmail ? userEmail : formData.email) || undefined,
    phoneNumber: formData.phone
      ? phoneFormDataToE164(formData.phone)
      : undefined,
    website: formData.noWebsite ? "None" : formData.website,
    numberOfEmployees: formData.numberOfEmployees || undefined,
    addressLine1: formData.address.addressLine1 || undefined,
    addressLine2: formData.address.addressLine2 || undefined,
    city: formData.address.city || undefined,
    region: formData.address.state || undefined,
    postalCode: formData.address.postalCode || undefined,
    country: country,
    canadaQuebecEnterpriseNumber:
      country === "CA" && formData.address.state === "QC"
        ? formData.canadaQuebecEnterpriseNumber || undefined
        : undefined,
    regionOfFormation:
      country === "US" || country === "CA"
        ? formData.stateOfIncorporation || undefined
        : undefined,
    yearOfFormation:
      country === "CA" ? formData.yearOfIncorporation || undefined : undefined
  };
}

export async function getRepresentativeData(
  formData: FormDataRepresentative,
  user: INewUser,
  verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"]
) {
  const data: {
    [key: string]: unknown;
  } = {
    firstName: formData.representative.firstName || undefined,
    middleName: formData.representative.middleName || undefined,
    lastName: formData.representative.lastName || undefined,
    occupation: formData.representative.jobTitle || undefined,
    dateOfBirth: formData.representative.birthday
      ? format(formData.representative.birthday, "yyyy-MM-dd")
      : undefined,
    country: formData.representative.country || undefined,
    individualTaxId: formData.representative.taxId || undefined,
    addressLine1: formData.representative.address.addressLine1 || undefined,
    addressLine2: formData.representative.address.addressLine2 || undefined,
    city: formData.representative.address.city || undefined,
    region: formData.representative.address.state || undefined,
    postalCode: formData.representative.address.postalCode || undefined
  };

  const missingData = await getMissingData(verificationLevel);

  if (missingData?.requiredFields?.includes("Representative.email")) {
    data.email = user.email;
  }

  if (missingData?.requiredFields?.includes("Representative.phoneNumber")) {
    data.phoneNumber = user.phone.number || undefined;
  }

  return data;
}

export async function getUserEmailAndPhone(
  user: INewUser,
  isRepresentative: boolean,
  verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"]
) {
  let missingData;
  try {
    missingData = await getVerificationMissingData(verificationLevel);
  } catch (error) {}

  const shouldSetReprecentativeEmail = missingData?.requiredFields?.includes(
    isRepresentative ? "Representative.email" : "email"
  );
  const shouldSetReprecentativePhoneNumber =
    missingData?.requiredFields?.includes(
      isRepresentative ? "Representative.phoneNumber" : "phoneNumber"
    );

  const userData: { [key: string]: unknown } = {};
  if (shouldSetReprecentativeEmail || shouldSetReprecentativePhoneNumber) {
    if (shouldSetReprecentativeEmail) {
      userData.email = user.email;
    }

    if (shouldSetReprecentativePhoneNumber) {
      userData.phoneNumber = user.phone.number || undefined;
    }
  }

  return userData;
}

export async function getMissingData(
  verificationLevel: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"]
) {
  let missingData;
  try {
    missingData = await getVerificationMissingData(verificationLevel);
  } catch (error) {
    return;
  }

  return missingData;
}
