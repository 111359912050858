import {
  WSActions,
  WSElement,
  WSForm,
  WSInputMask,
  WSInputText,
  WSList,
  WSPanel,
  WSSelect
} from "@wingspanhq/fe-component-library";
import React, { useRef } from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import styles from "./index.module.scss";
import { InstitutionPreview } from "./InstitutionPreview";
import { FormData } from "./types";
import { validationSchema } from "./validationSchema";

const accountNumberMask = "?" + Array(30).fill("9").join("");

type Props = {
  rtpRequired?: boolean;
  onBack?: () => void;
  onSubmit: (data: FormData) => void;
  submitButtonText?: string;
  error?: any;
  isLoading?: boolean;
  withPanel?: boolean;
};

export const Form: React.FC<Props> = ({
  rtpRequired,
  onSubmit,
  submitButtonText = "Continue",
  error,
  isLoading,
  withPanel,
  onBack
}) => {
  const refPreview = useRef<HTMLDivElement>(null);

  const panelContent = (
    <WSList gap="XL">
      <WSElement ref={refPreview} className={styles.image} data-focus="init" />

      <WSForm.Field
        label="Account type"
        name="type"
        component={WSSelect}
        componentProps={{
          options: [
            { value: "checking", label: "Checking" },
            { value: "savings", label: "Savings" }
          ]
        }}
      />

      <WSElement>
        <WSForm.Field
          name="accountRoutingNumber"
          label="Routing number"
          component={WSInputMask}
          componentProps={{
            mask: "999999999",
            slotChar: "",
            autoClear: false,
            onFocus() {
              refPreview.current?.setAttribute("data-focus", "routing");
            }
          }}
        />

        <InstitutionPreview
          routingNumberFieldName="accountRoutingNumber"
          rtpRequired={rtpRequired}
        />
      </WSElement>

      {/* <WSForm.Field name="accountName" component={WSTextInput} hidden /> */}

      <WSForm.Field
        name="accountNumber"
        label="Account number"
        component={WSInputMask}
        componentProps={{
          mask: accountNumberMask,
          slotChar: "",
          autoClear: false,
          fsExclude: true,
          onFocus() {
            refPreview.current?.setAttribute("data-focus", "account");
          }
        }}
      />

      <WSForm.Field
        name="accountNumberConfirmation"
        label="Re-enter account number"
        component={WSInputMask}
        componentProps={{
          mask: accountNumberMask,
          slotChar: "",
          autoClear: false,
          fsExclude: true,
          onFocus() {
            refPreview.current?.setAttribute("data-focus", "account");
          }
        }}
      />

      <WSForm.Field
        name="accountHolderName"
        label="Name of account holder"
        component={WSInputText}
        componentProps={{
          fsExclude: true,
          onFocus() {
            refPreview.current?.setAttribute("data-focus", "success");
          }
        }}
      />

      <WSErrorMessage contextKey="PaymentsSetup" error={error} />
    </WSList>
  );

  return (
    <WSForm<FormData>
      onSubmit={onSubmit}
      defaultValues={{
        type: "checking",
        accountRoutingNumber: "",
        accountNumber: "",
        accountHolderName: ""
      }}
      validationSchema={validationSchema}
    >
      <WSList gap="2XL">
        {withPanel ? <WSPanel>{panelContent}</WSPanel> : panelContent}

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              kind: "Secondary",
              onClick: onBack,
              visible: !!onBack,
              type: "button"
            },
            {
              label: submitButtonText,
              loading: isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
