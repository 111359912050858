import {
  useIsMobile,
  WSBanner,
  WSFiltersOld,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSText
} from "@wingspanhq/fe-component-library";
import { UserStatus } from "@wingspanhq/users/dist/lib/interfaces";
import * as React from "react";
import { useHistory } from "react-router";
import { WSPersistentUpgradeButton } from "../../../../components/Membership/WSPersistentUpgradeButton";
import { useSubscriptionGrantListQuery } from "../../../../query/subscriptions/queries";
import {
  useAllOrganizationUsers,
  useAuthorizations
} from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { WSFrontendFeature } from "../../../../Settings/utils/subscriptionUtils";
import {
  getAuthorizedAccountName,
  getMemberName,
  getTeamMemberList,
  ITeamMemberRecord
} from "../../../../shared/utils/teamUtils";
import {
  mapAndRevertParamsSortToTable,
  mapAndRevertTableSortToParams
} from "../../../../utils/tableSortingHelpers";
import { useModalDeleteConfirmation } from "../../components/ModalDeleteConfirmation/useModalDeleteConfirmation";
import { getOrganizationScopesCell } from "../../utils/getOrganizationScopesCell";
import { getPermissionScopesCell } from "../../utils/getPermissionScopesCell";
import { getSearchedTeamMembers } from "../../utils/getSearchedTeamMembers";
import { useTeamFilters } from "../../utils/teamFilters";
import { sortTableData } from "./sortTableData";
import { useTeamsManagedByOrganization } from "../../../../userFeatures/useTeamsManagedByOrganization";
import { useUserOrganizationName } from "../../../../userFeatures/useUserOrganizationName";

export interface TeamTabProps {}

export const TeamDashboard: React.FC<TeamTabProps> = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const hasManagedTeam = useTeamsManagedByOrganization();
  const organizationName = useUserOrganizationName();

  const queryAuthorizations = useAuthorizations();
  const queryOrganizationUsers = useAllOrganizationUsers();
  const querySubscriptionGrantsList = useSubscriptionGrantListQuery();
  const modalDelete = useModalDeleteConfirmation();

  const { defaultFilters, filters, setFilters } = useTeamFilters();

  const { search = "", sort } = filters;

  const onNameSort = () => {
    setFilters({
      sort: { name: mapAndRevertTableSortToParams(sort?.name) }
    });
  };

  const onStatusSort = () => {
    setFilters({
      sort: {
        status: mapAndRevertTableSortToParams(sort?.status)
      }
    });
  };

  const onPermissionSort = () => {
    setFilters({
      sort: {
        permission: mapAndRevertTableSortToParams(sort?.permission)
      }
    });
  };

  const onOrganizationSort = () => {
    setFilters({
      sort: {
        permission: mapAndRevertTableSortToParams(sort?.permission)
      }
    });
  };

  return (
    <WSPage
      title="Team"
      primaryButtonComponent={
        hasManagedTeam
          ? undefined
          : () => (
              <WSPersistentUpgradeButton
                fullWidth
                onClick={() => {
                  history.push("/member/settings/team/add-team-member");
                }}
                feature={WSFrontendFeature.Teams}
                name="addTeamMember"
              >
                Add team member
              </WSPersistentUpgradeButton>
            )
      }
    >
      <WSGrid>
        <WSGrid.Item span={{ m: "12" }}>
          {hasManagedTeam ? (
            <WSBanner my="L" theme="info">
              Team members are managed by <b>{organizationName}</b> - contact
              them for support.
            </WSBanner>
          ) : null}

          <WSFiltersOld
            mb="2XL"
            searchQuery={search}
            defaultFilters={defaultFilters}
            onSearch={newSearch =>
              setFilters({
                ...filters,
                search: newSearch
              })
            }
          />

          <WSQueries
            queries={{
              querySubscriptionGrantsList,
              queryAuthorizations,
              queryOrganizationUsers
            }}
          >
            {({
              querySubscriptionGrantsListData,
              queryAuthorizationsData,
              queryOrganizationUsersData
            }) => {
              const teamMembers = getTeamMemberList(
                queryAuthorizationsData,
                querySubscriptionGrantsListData
              );

              const tableData = getSearchedTeamMembers(search, teamMembers).map(
                tableItem => {
                  return {
                    id: tableItem.teamMemberUserId,
                    data: tableItem
                  };
                }
              );

              const sortedData = search
                ? tableData
                : sortTableData(
                    filters.sort,
                    tableData,
                    queryOrganizationUsersData
                  );

              const nameColumn: WSTableColumn<ITeamMemberRecord> = {
                config: {
                  justify: "start",
                  header: {
                    text: "Name"
                  },
                  sortDirection: mapAndRevertParamsSortToTable(
                    filters.sort?.name
                  ),
                  onColumnSort: search ? undefined : onNameSort
                },
                renderFunction: ({ data }) => {
                  const name = getAuthorizedAccountName(
                    data.teamMemberWSMember,
                    data.teamMemberWSUser
                  );
                  const memberName = getMemberName(data.teamMemberWSMember);

                  return (
                    <WSTableCell
                      avatar={{
                        type: "text",
                        text: getAuthorizedAccountName(
                          data.teamMemberWSMember,
                          data.teamMemberWSUser
                        ),
                        color: "gray600",
                        colorBackground: "gray200"
                      }}
                      text={name}
                      secondaryText={memberName}
                      truncationOptions={{
                        text: {
                          allowTwoLines: true
                        }
                      }}
                    />
                  );
                }
              };

              const statusColumn: WSTableColumn<ITeamMemberRecord> = {
                config: {
                  justify: "start",
                  header: {
                    text: "Status"
                  },
                  sortDirection: mapAndRevertParamsSortToTable(
                    filters.sort?.status
                  ),
                  onColumnSort: search ? undefined : onStatusSort
                },
                renderFunction: rowItem => {
                  if (
                    rowItem.data.teamMemberWSUser.status === UserStatus.Active
                  ) {
                    return (
                      <WSTableCell
                        pill={{
                          theme: "success",
                          icon: true,
                          text: "Signed up"
                        }}
                      />
                    );
                  } else {
                    return (
                      <WSTableCell
                        pill={{
                          theme: "warning",
                          icon: true,
                          text: "Not signed up"
                        }}
                      />
                    );
                  }
                }
              };

              const organizationColumn: WSTableColumn<ITeamMemberRecord> = {
                config: {
                  header: {
                    text: "Org. accounts"
                  },
                  sortDirection: mapAndRevertParamsSortToTable(
                    filters.sort?.organization
                  ),
                  onColumnSort: search ? undefined : onOrganizationSort
                },
                renderFunction: rowItem => {
                  return (
                    <WSTableCell
                      text={
                        rowItem.data.authorization
                          ? getOrganizationScopesCell(
                              rowItem.data.authorization,
                              queryOrganizationUsersData
                            )
                          : "All"
                      }
                    />
                  );
                }
              };

              const permissionColumn: WSTableColumn<ITeamMemberRecord> = {
                config: {
                  header: {
                    text: "Permissions"
                  },
                  sortDirection: mapAndRevertParamsSortToTable(
                    filters.sort?.permission
                  ),
                  onColumnSort: search ? undefined : onPermissionSort
                },
                renderFunction: rowItem => {
                  return (
                    <WSTableCell
                      text={getPermissionScopesCell(
                        rowItem.data.authorization?.allowedScopeGroupIds || []
                      )}
                    />
                  );
                }
              };

              const columns: Array<WSTableColumn<ITeamMemberRecord>> = [
                nameColumn,
                statusColumn
              ];

              if (!isMobile) {
                if (
                  queryOrganizationUsersData.length > 0 &&
                  tableData.some(
                    rowItem =>
                      !!rowItem.data.authorization?.scopeModifications?.users
                        ?.organizationAccount?.length
                  )
                ) {
                  columns.push(organizationColumn);
                }

                columns.push(permissionColumn);
              }

              return (
                <WSTable<ITeamMemberRecord>
                  showHeader={tableData.length > 0}
                  bottomMessage={
                    search && tableData.length === 0 ? (
                      <WSFlexBox justify="center" my="3XL">
                        <WSText color="gray500">No results found</WSText>
                      </WSFlexBox>
                    ) : hasManagedTeam && tableData.length === 0 ? (
                      <WSFlexBox justify="center" my="3XL">
                        <WSText color="gray500">List is empty</WSText>
                      </WSFlexBox>
                    ) : tableData.length === 0 ? (
                      <WSFlexBox justify="center" my="3XL">
                        <WSPersistentUpgradeButton
                          feature={WSFrontendFeature.Teams}
                          onClick={() => {
                            history.push(
                              "/member/settings/team/add-team-member"
                            );
                          }}
                          kind="Secondary"
                        >
                          Add team member
                        </WSPersistentUpgradeButton>
                      </WSFlexBox>
                    ) : undefined
                  }
                  rowMenuActions={
                    hasManagedTeam
                      ? undefined
                      : rowItem => [
                          {
                            label: "Edit",
                            onClick: () => {
                              history.push({
                                pathname: `/member/settings/team/${rowItem.id}/edit-team-member`
                              });
                            }
                          },
                          {
                            label: "Delete",
                            onClick: () =>
                              modalDelete.open({
                                authorization: rowItem.data.authorization,
                                subscriptionGrant:
                                  rowItem.data.subscriptionGrant
                              })
                          }
                        ]
                  }
                  columns={columns}
                  tableData={sortedData}
                />
              );
            }}
          </WSQueries>
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
