import {
  IWebflowPartner,
  IWebflowPartnerSignUpTypes
} from "@wingspanhq/cms/dist/lib/interfaces";
import {
  useIsMobile,
  useModalContext,
  WSButton,
  WSGrid,
  WSModal,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import { SessionType } from "@wingspanhq/users/dist/lib/interfaces/session";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SignUpForm } from "../../Auth/components/SignUpForm/SignUpForm";
import {
  FeatureInfo,
  FeatureInfoProps
} from "../../MemberOnboarding/components/FeatureInfo/FeatureInfo";
import { BrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import {
  useCMSWebflowEnterprise,
  useCMSWebflowPartner
} from "../../query/cms/queries";
import {
  getCurrentEntepriseId,
  getCurrentPartnerId
} from "../../query/platform/selectors";
import { useSession } from "../../query/session";
import { useCreateSession } from "../../query/users/mutations";
import { useUserTag } from "../../query/users/queries";
import { useSetWSStore, useWSStore } from "../../store";
import { WSVirtualAssistant } from "../components/WSVirtualAssistant/WSVirtualAssistant";

import { WingspanIsPCICertifiedSecure } from "../../ClientPayments/components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";

export interface SignUpWelcomeProps extends FeatureInfoProps {
  isEnterprise?: boolean;
  showHeader?: boolean;
  showContent?: boolean;
}

export const SignUpWelcome: React.FC<SignUpWelcomeProps> = ({
  title,
  description,
  featureItems,
  isEnterprise,
  showHeader = true,
  showContent = true
}) => {
  const store = useWSStore();
  const isMobile = useIsMobile();
  const { openModal } = useModalContext();

  return (
    <>
      <WSScreen.Mobile>
        <WSText.Heading4 mb="M">{title}</WSText.Heading4>
        <WSText mb="XS">{description}</WSText>
        <WSButton.Link
          mb="XL"
          onClick={() => {
            openModal("SIGN_UP_FEATURES_LIST");
          }}
        >
          Learn more
        </WSButton.Link>

        <WSModal name="SIGN_UP_FEATURES_LIST">
          <FeatureInfo
            pt={isMobile ? "XL" : "M"}
            title={title}
            description={description}
            featureItems={featureItems}
          />
        </WSModal>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <FeatureInfo
          pt={isMobile ? "XL" : "M"}
          title={title}
          description={description}
          featureItems={featureItems}
        />
        {!isEnterprise && !store.signUpAutopayDetails && (
          <WSVirtualAssistant
            mt="XL"
            message="Wingspan is entirely tax deductible! And - you guessed it - we'll deduct it for you."
          />
        )}
      </WSScreen.TabletAndDesktop>
    </>
  );
};

export const MemberSignUpV2: React.FC = () => {
  const store = useWSStore();

  const [_, setSignUpPartner] = useLocalStorage("sign-up-with-partner", "");

  const history = useHistory();
  const location = useLocation();
  const { search, pathname } = location;
  const setStore = useSetWSStore();
  const queries = queryString.parse(search);

  const email = queries.email as string | undefined;
  const tagName = queries.tag as string | undefined;

  const firstName = queries.firstName as string | undefined;
  const companyName = queries.companyName as string | undefined;
  const refereeEmail = queries.refereeEmail as string | undefined;

  const inviteType = queries.inviteType as string | undefined;
  const inviterName = queries.inviterName as string | undefined;
  const inviterCompany = queries.inviterCompany as string | undefined;
  const inviteeEmail = queries.inviteeEmail as string | undefined;
  const payeeId = queries.p as string | undefined;

  const partnerId = getCurrentPartnerId(store);
  const enterpriseId = getCurrentEntepriseId(store);

  const [isLoading, setIsLoading] = useState(
    !!tagName || !!partnerId || !!enterpriseId
  );

  const [isLoadingCustomSignUpExperience, setIsLoadingCustomSignUpExperience] =
    useState(false);

  const partnerQuery = useCMSWebflowPartner(partnerId, {
    enabled: false,
    retry: false
  });
  const partner = partnerQuery.data;

  const enterpriseQuery = useCMSWebflowEnterprise(enterpriseId || "", {
    enabled: false,
    retry: false
  });

  const sessionQuery = useSession({
    enabled: false,
    retry: false
  });

  const [signInAsGuest] = useCreateSession();

  const userTagQuery = useUserTag(enterpriseId || partnerId || tagName || "", {
    enabled: false,
    retry: false
  });

  const [
    alreadyCheckedForCustomSignUpExperience,
    setAlreadyCheckedForCustomSignUpExperience
  ] = useState(false);

  const [isProbablyCheckingV2Captcha, setIsProbablyCheckingV2Captcha] =
    useState(false);

  const [
    alreadyCheckedUserTagAfterV2Captcha,
    setAlreadyCheckedUserTagAfterV2Captcha
  ] = useState(false);

  const checkGrowthName = async () => {
    // this is painful because we're breaking RQ, but doing it this way just helps maintain order with the network calls
    // if we're here, it's because someone has put in either an enterpriseId or a partnerId and we've never run this before
    setIsLoadingCustomSignUpExperience(true);
    setAlreadyCheckedForCustomSignUpExperience(true);
    let isDataInCMS = false;
    if (!!enterpriseId) {
      // check if there's a matching CMS enterprise entry
      const result = await enterpriseQuery.refetch();
      if (result) {
        // if so, we're done. use that info!
        setIsLoadingCustomSignUpExperience(false);
        isDataInCMS = true;
      }
    } else if (!!partnerId) {
      //  check if there's a matching CMS partner entry
      const result = await partnerQuery.refetch();
      if (result) {
        // if so, we're done. use that info!
        setIsLoadingCustomSignUpExperience(false);
      }
      isDataInCMS = true;
    }

    if (!isDataInCMS && (!!enterpriseId || !!partnerId || !!tagName)) {
      // if none of the above, we're gonna create a guest session and check the user tag
      const result = await signInAsGuest();
      if (result) {
        // once the guest session returns, let's fetch the user tag
        await userTagQuery.refetch();
        setIsLoadingCustomSignUpExperience(false);
      } else {
        // we probably had session creation error
        setIsProbablyCheckingV2Captcha(true);
      }
    } else {
      setIsLoadingCustomSignUpExperience(false);
      console.error(
        `No CMS entry or userTag matches ${enterpriseId || partnerId}`
      );
    }
    setIsLoading(false);
  };

  const checkUserTagIfWeHadToDoV2Captcha = async () => {
    // so we can get here only if we got interrupted by the V2 captcha and need to run the userTagQuery
    setAlreadyCheckedUserTagAfterV2Captcha(true);
    await userTagQuery.refetch();
    setIsLoadingCustomSignUpExperience(false);
  };

  useEffect(() => {
    if (
      sessionQuery.data?.sessionType === SessionType.guest &&
      isProbablyCheckingV2Captcha &&
      !alreadyCheckedUserTagAfterV2Captcha
    ) {
      checkUserTagIfWeHadToDoV2Captcha();
    }
  }, [
    sessionQuery,
    isProbablyCheckingV2Captcha,
    alreadyCheckedUserTagAfterV2Captcha
  ]);

  useEffect(() => {
    if (
      (!!enterpriseId || !!partnerId || !!tagName) &&
      !isLoadingCustomSignUpExperience &&
      !alreadyCheckedForCustomSignUpExperience
    ) {
      checkGrowthName();
    }
  }, [
    enterpriseId,
    partnerId,
    tagName,
    isLoadingCustomSignUpExperience,
    alreadyCheckedForCustomSignUpExperience
  ]);

  const enterpriseForUserTag = userTagQuery.data
    ? {
        is1099FilingOnly: false,
        isGiftCardPayoutsEnabled: false,
        logo: {
          src: userTagQuery.data.member?.profile?.logoUrl
        },
        name: "Wingspan",
        signUpDescription: `Create your free account now, setup your direct deposit information, and get paid!`,
        signUpDescriptionItems: `Tax Withholding: Automatically set aside for taxes from your income. Few basic questions and we can tell you how much federal and state tax you’ll owe.
Federal Tax Payments: Make a quarterly or annual payment without leaving Wingspan.        
Get Insured: Access health, dental and vision insurance at discounted rates.        
Big-Company Benefits: 24/7 Telemedicine, Health Advocate and Tax Hotline.        
Income & Expense Tracking: Link your bank account and identify deductible expenses. Export tax-ready financials.        
Fast payments: Receive payments, send invoices, accept digital payments with ACH and cards.`,
        signUpFormHeader: "Sign up to get paid",
        signUpHeader: `${userTagQuery.data.member?.profile?.company?.name} wants to pay you through Wingspan.`,
        slug: "wingspan"
      }
    : undefined;

  const enterprise = enterpriseQuery.data || enterpriseForUserTag;

  useEffect(() => {
    if (partner) {
      setSignUpPartner(partner.slug);
      setStore({ signUpType: partner.signUpType });
      setStore({ discountCode: partner.stripeDiscountCode });
    }
  }, [partner]);

  useEffect(() => {
    if (firstName) {
      setStore({ signUpType: IWebflowPartnerSignUpTypes.CollaboratorPayments });
    }
  }, [firstName]);

  useEffect(() => {
    if (inviteType === "teams") {
      setStore({ signUpType: IWebflowPartnerSignUpTypes.TeamsInvite });
    }
  }, [inviteType]);

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  let bannerMessage = null;
  let title =
    partner?.header ?? enterprise?.signUpHeader ?? "Welcome to Wingspan";
  let description =
    "The best way to work for yourself! Wingspan provides independent professionals with invoicing, expense tracking, taxes, benefits - all back office needs, all in one place.";
  let featureItems = [
    "Supercharged invoicing - retainers, auto reminders & more",
    "Easy subcontractor payments",
    "Automated tax withholding and quarterly filings",
    "Expense tracking",
    "Big company benefits for you and yours"
  ];
  if (store.isEmployerSignUp) {
    title = "Welcome to Wingspan for Employers";
    description = "The best way to onboard, pay and manage your contractors.";
    featureItems = [
      "90-second contractor onboarding",
      "Fast payments",
      "Automatic 1099 filling",
      "Integrations & reporting",
      "Health & financial benefits for contractors"
    ];
  } else if (partner && (partner as IWebflowPartner)?.description) {
    description = (partner as IWebflowPartner).description ?? description;
  } else if (partner) {
    description =
      "Invoices, benefits, and bookkeeping for independent professionals, all in one place. Wingspan is the best way to work for yourself.";
  } else if (companyName) {
    description = `${companyName} wants to pay you through Wingspan. Create your free account now, setup your direct deposit information, and get paid!`;
  } else if (inviteType === "teams") {
    description = `To access ${inviterName}'s invite and log into ${
      inviterCompany ? `${inviterCompany}'s` : "their"
    } Wingspan account, simply register for a free Wingspan account. After registration, you'll be taken directly to their account.`;
  } else if (enterprise) {
    if (enterprise.signUpDescriptionItems) {
      featureItems =
        enterprise.signUpDescriptionItems.split(/\r\n|\n\r|\n|\r/g);
    }
    if (enterprise.signUpDescription) {
      description = enterprise.signUpDescription;
    }
  }

  const defaultValues = {
    email: email || inviteeEmail || refereeEmail || ""
  };

  const isAutoPay = queries.autopay && tagName && companyName && payeeId;

  let signUpFormHeader = enterprise?.signUpFormHeader;

  if (isAutoPay) {
    title = `${companyName} invited you to set up Autopay for their invoices`;
    description = "";
    bannerMessage = <WingspanIsPCICertifiedSecure name={companyName} />;
    signUpFormHeader = "Sign up to set up payments";
    featureItems = [
      "Make payments with credit, debit, & ACH",
      "Easily change your payment method",
      "Revoke Autopay consent at any time",
      "See invoice history"
    ];
  }

  return (
    <React.Fragment>
      <BrowserPageTitle title="Sign up" />
      <OnboardingLayout
        onBack={() => history.goBack()}
        partnerLogo={partner?.logo?.src || enterprise?.logo.src}
        bannerMessage={bannerMessage}
      >
        <WSGrid>
          <WSScreen.TabletAndDesktop>
            <WSGrid.Item span={{ m: "6" }}>
              <SignUpWelcome
                title={title}
                description={description}
                featureItems={featureItems}
              />
            </WSGrid.Item>
          </WSScreen.TabletAndDesktop>
          <WSScreen.Mobile>
            <WSGrid.Item span={{ m: "6" }}>
              <SignUpWelcome
                showContent={false}
                title={title}
                description={description}
                featureItems={featureItems}
              />
            </WSGrid.Item>
          </WSScreen.Mobile>
          <WSGrid.Item span={{ m: "6" }}>
            <SignUpForm
              defaultValues={defaultValues}
              title={signUpFormHeader}
              description=""
              buttonText={
                inviteType === "teams"
                  ? "Create account"
                  : "Get started with Wingspan"
              }
            />
          </WSGrid.Item>
        </WSGrid>
      </OnboardingLayout>
    </React.Fragment>
  );
};
