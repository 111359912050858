import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import { IPayoutResponse } from "../../../services/api/payments/payouts/types";
import { components } from "../../../services/api/payments/types";
import { getGrossPayment } from "./getGrossPayment";
import { getPayoutSummaryCollaboratorPayments } from "./getPayoutSummaryCollaboratorPayments";
import { getPayoutSummaryDeductions } from "./getPayoutSummaryDeductions";
import { getPayoutSummaryFees } from "./getPayoutSummaryFees";

export function isInstantPayoutItem(item: components["schemas"]["PayoutItem"]) {
  return item.sourceSubType === "InstantPayoutDrawback";
}

export function getPayoutInvoiceIds(payout: IPayoutResponse) {
  return (payout.payoutItems || [])
    .filter(item => item.sourceType === "Invoice" && !isInstantPayoutItem(item))
    .map(i => i.sourceId);
}

export function getTotals(payout: IPayoutResponse, invoices: IInvoice[]) {
  const grossPayments = getGrossPayment(invoices);

  const instantPayoutItems = (payout.payoutItems || []).filter(
    isInstantPayoutItem
  );

  const instantPayoutInvoices = invoices.filter(invoice =>
    instantPayoutItems.some(item => item.sourceId === invoice.invoiceId)
  );

  const nonInstantPayoutInvoices = invoices.filter(
    invoice =>
      !instantPayoutItems.some(item => item.sourceId === invoice.invoiceId)
  );

  const instantPayouts = instantPayoutItems.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const fees = getPayoutSummaryFees(nonInstantPayoutInvoices);
  const deductions = getPayoutSummaryDeductions(nonInstantPayoutInvoices);
  const contractorPayments = getPayoutSummaryCollaboratorPayments(
    nonInstantPayoutInvoices
  );

  console.log({
    grossPayments,
    instantPayoutItems,
    instantPayoutInvoices,
    instantPayouts,
    nonInstantPayoutInvoices,
    fees,
    deductions,
    contractorPayments
  });

  return {
    grossPayments,
    instantPayoutItems,
    instantPayoutInvoices,
    instantPayouts,
    nonInstantPayoutInvoices,
    fees,
    deductions,
    contractorPayments
  };
}

export function getPayoutNet(payout: IPayoutResponse) {
  const amount =
    (payout.currentPeriodBalance || 0) - (payout.futureDebits || 0);

  if (amount < 0) {
    return 0;
  }

  return amount;
}
