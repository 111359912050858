import {
  WSButton,
  WSButtons,
  WSElement,
  WSElementProps,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useDeleteAuthorization } from "../../../../query/users/mutations";
import { useDeleteSubscriptionGrant } from "../../../../query/subscriptions/mutations";
import { INormalizedAuthorization } from "../../../../shared/utils/teamUtils";
import { ISubscriptionGrantResponse } from "@wingspanhq/users/dist/lib/interfaces";

export type PromptDismissProps = {
  authorization?: INormalizedAuthorization;
  subscriptionGrant?: ISubscriptionGrantResponse;
  onClose: (result: boolean) => void;
} & WSElementProps;

export const DeleteConfirmation: React.FC<PromptDismissProps> = ({
  onClose,
  authorization,
  subscriptionGrant,
  ...elementProps
}) => {
  const [deleteAuthorization, deleteAuthorizationMeta] =
    useDeleteAuthorization();
  const [deleteSubscriptionGrant, deleteSubscriptionGrantMeta] =
    useDeleteSubscriptionGrant();

  return (
    <WSElement {...elementProps}>
      <WSText mb="2XL">Once confirmed, this action cannot be undone.</WSText>

      <WSButtons forceFullWidth>
        <WSButton
          destructive
          onAsyncClick={async () => {
            if (!!subscriptionGrant) {
              await deleteSubscriptionGrant(
                subscriptionGrant.subscriptionGrantId
              );
            }

            if (authorization) {
              if (authorization.allowedScopeGroupIds.length > 0) {
                await Promise.all(
                  authorization?.allowedScopeGroupIds.map(
                    async ({ authorizationId }: any) => {
                      await deleteAuthorization(authorizationId);
                    }
                  )
                );
              }

              if (authorization.organizationAccountAuthorizationId) {
                await deleteAuthorization(
                  authorization.organizationAccountAuthorizationId
                );
              }
            }

            onClose(true);
          }}
        >
          Remove team member
        </WSButton>
        <WSButton.Tertiary onClick={() => onClose(false)}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSElement>
  );
};
