import { Route, Switch } from "react-router-dom";
import { RouteBankingVerification } from "./routes/RouteBankingVerificaiton";
import { RouteChange } from "./routes/RouteChange";
import { RoutePayeeOnboarding } from "./routes/RoutePayeeOnboarding";
import { RoutePayerOnboarding } from "./routes/RoutePayerOnboarding";
import { RouteTaxVerificaiton } from "./routes/RouteTaxVerificaiton";
import { RouteTaxPayeeOnboarding } from "./routes/RouteTaxPayeeOnboarding";
import { RouteTaxPayerOnboarding } from "./routes/RouteTaxPayerOnboarding";

export const PATH_ONBOARDING = "/member/onboarding";
export const PATH_ONBOARDING_PAYER = PATH_ONBOARDING + "/payer";
export const PATH_ONBOARDING_PAYEE = PATH_ONBOARDING + "/payee";
export const PATH_ONBOARDING_TAX_PAYEE = PATH_ONBOARDING + "/tax-payee";
export const PATH_ONBOARDING_TAX_PAYER = PATH_ONBOARDING + "/tax-payer";
export const PATH_ONBOARDING_VERIFICAIONS_TAX =
  PATH_ONBOARDING + "/verifications/tax";
export const PATH_ONBOARDING_VERIFICAIONS_BANKING =
  PATH_ONBOARDING + "/verifications/banking";
export const PATH_ONBOARDING_CHANGE = PATH_ONBOARDING + "/change";

export const Onboarding = () => (
  <Switch>
    <Route path={PATH_ONBOARDING_PAYER} component={RoutePayerOnboarding} />

    <Route path={PATH_ONBOARDING_PAYEE} component={RoutePayeeOnboarding} />

    <Route
      path={PATH_ONBOARDING_TAX_PAYEE}
      component={RouteTaxPayeeOnboarding}
    />

    <Route
      path={PATH_ONBOARDING_TAX_PAYER}
      component={RouteTaxPayerOnboarding}
    />

    <Route
      path={PATH_ONBOARDING_VERIFICAIONS_TAX}
      component={RouteTaxVerificaiton}
    />

    <Route
      path={PATH_ONBOARDING_VERIFICAIONS_BANKING}
      component={RouteBankingVerification}
    />

    <Route path={PATH_ONBOARDING_CHANGE} component={RouteChange} />
  </Switch>
);
