import { RecordsChunk, RecordError, FlatfileRecord } from "@flatfile/sdk";
import {
  IAdditionalData,
  BulkPayableStatus,
  IBulkPayableItemCreate,
  IBulkPayableItem
} from "@wingspanhq/payments/dist/interfaces";
import { getFlatfileCustomFields } from "./getFlatfileCustomFields";
import axios from "axios";
import { bulkPayableService } from "../services/bulkPayable";
import { getRecordRejectionErrors } from "./getFlatfileRecordRejectionErrors";
import { getCustomFieldsLabelsRequest } from "./getCustomFieldsLabelsRequest";
import { pMap } from "../../../utils/pMap";

export const lineItemToBulkBatchItem = (
  lineItem: any,
  recordId: number,
  bulkPayableBatchId: string,
  payableStatus: BulkPayableStatus,
  additionalData?: IAdditionalData[]
): IBulkPayableItemCreate => {
  const customFields = getFlatfileCustomFields(lineItem);
  const labels = getCustomFieldsLabelsRequest(additionalData || [], lineItem);
  return {
    bulkPayableBatchId,
    ...(lineItem.email ? { collaboratorEmail: lineItem.email } : {}),
    ...(lineItem.collaboratorId
      ? { collaboratorId: lineItem.collaboratorId }
      : {}),
    ...(lineItem.contractorId
      ? { collaboratorExternalId: lineItem.contractorId }
      : {}),
    payableStatus: payableStatus || BulkPayableStatus.Paid,
    amount: Number(lineItem.amount?.replaceAll(",", "")),
    lineItemDescription: lineItem.lineItemTitle ?? "",
    lineItemDetail: lineItem.lineItemDescription ?? "",
    dueDate: lineItem.dueDate as Date,
    ...(lineItem.paidDate ? { paidDate: lineItem.paidDate as Date } : {}),
    payableNotes: lineItem.invoiceNotes ?? "",
    reimbursableExpense: lineItem.reimbursable,
    attachmentId: lineItem.attachmentId,
    bulkPayableItemReference: lineItem.paymentRefId,
    invoiceDate: lineItem.invoiceDate as Date,
    labels: {
      ...customFields,
      ...labels,
      bulkImporterItemNumber: String(recordId)
    }
  };
};

export async function processFlatfileChunk(
  chunk: RecordsChunk,
  bulkBatchId: string,
  status: BulkPayableStatus,
  additionalData?: IAdditionalData[]
) {
  const validChunkRecords = chunk.records.filter(record => record.valid);
  const batchItemRequestsWithRecordId = validChunkRecords.map(record => {
    const batchItemRequest = lineItemToBulkBatchItem(
      record.data,
      record.recordId,
      bulkBatchId,
      status,
      additionalData
    );
    return {
      ...batchItemRequest,
      recordId: record.recordId
    };
  });

  async function createBatchItem({
    recordId: _,
    ...batchItemRequest
  }: IBulkPayableItemCreate & {
    recordId: FlatfileRecord["recordId"];
  }): Promise<IBulkPayableItem> {
    const response = await bulkPayableService.batchItem.create(
      bulkBatchId,
      batchItemRequest
    );
    return response;
  }

  let rejections: RecordError[] = [];

  try {
    const { errors } = await pMap(
      batchItemRequestsWithRecordId,
      createBatchItem,
      {
        concurrency: 100,
        retryConfig: {
          // number of maximal retry attempts
          retries: 3,
          // wait time between retries in ms
          delay: 100,
          // increase delay with every retry (default: "FIXED")
          backoff: "EXPONENTIAL"
        }
      }
    );
    console.log("Bulk Import Payables Chunk Errors", errors);
    for (let i = 0; i < errors.length; i++) {
      const { error } = errors[i];
      // NOTE: Sometimes, POST batch/item API throws 502 as FE creates batchItems in bulk,
      // so for some of the records which failed for this reason have a generic error message  i.e.
      // "Failed to import this item, try to edit it here and re-submit."
      const errorMessage =
        (error as any)?.response?.data?.error ||
        "Failed to import this item, try to edit it here and re-submit.";
      const csvRow = validChunkRecords.find(
        rec => rec.recordId === errors[i].item.recordId
      )?.data;
      const recordErrors = getRecordRejectionErrors(errorMessage, csvRow);
      rejections.push(new RecordError(errors[i].item.recordId, recordErrors));
    }
  } catch (err) {
    console.log("An error occurred:", err);
  }

  return rejections;
}

export async function handleFlatfileCSVChunk(
  chunk: RecordsChunk,
  bulkBatchId: string,
  status: BulkPayableStatus,
  additionalData?: IAdditionalData[]
) {
  const validChunkRecords = chunk.records.filter(record => record.valid);

  const bulkBatchItemPromises = validChunkRecords.map((record, index) => {
    const batchItemRequest = lineItemToBulkBatchItem(
      record.data,
      record.recordId,
      bulkBatchId,
      status,
      additionalData
    );
    let promise;
    try {
      promise = bulkPayableService.batchItem.create(
        bulkBatchId,
        batchItemRequest
      );
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log(
          `Error while creating bulk payable item: ${err.response?.data?.error}`
        );
      } else if (err instanceof Error) {
        console.log(`Error while creating bulk payable item: ${err.message}`);
      } else {
        console.log(
          `Error while creating bulk payable item: ${JSON.stringify(err)}`
        );
      }
    }
    return {
      // Need this recordId to display an error in Flatfile during processing
      recordId: record.recordId,
      promise
    };
  });

  let rejections: RecordError[] = [];
  const settledPromises = await Promise.allSettled(
    bulkBatchItemPromises.map(
      bulkBatchItemPromise => bulkBatchItemPromise.promise
    )
  );
  for (let i = 0; i < settledPromises.length; i++) {
    const settledPromise = settledPromises[i];
    if (settledPromise.status === "rejected") {
      //NOTE: Sometimes, POST batch/item API throws 502 as FE creates batchItems in bulk,
      // so for some of the records which failed for this reason have a generic error message  i.e.
      // "Failed to import this item. If you think nothing is wrong try to edit it here and re-submit."
      const errorMessage =
        settledPromise?.reason?.response?.data?.error ||
        "Failed to import this item, try to edit it here and re-submit.";
      const csvRow = validChunkRecords.find(
        rec => rec.recordId === bulkBatchItemPromises[i].recordId
      )?.data;
      const recordErrors = getRecordRejectionErrors(errorMessage, csvRow);
      rejections.push(
        new RecordError(bulkBatchItemPromises[i].recordId, recordErrors)
      );
    }
  }

  return rejections;
}
