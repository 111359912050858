import { BUILD_ENV, IS_HOSTED_ENV } from "../../shared/constants/environment";
import StackdriverErrorReporterEnhanced, {
  CatchType,
  CatchTypePropName
} from "./StackdriverErrorReporterEnhanced";

export { CatchType, CatchTypePropName };

const stackdriverErrorService = new StackdriverErrorReporterEnhanced();

/**
 * Initialize the Stackdriver Error Service.
 *   At this point, uncaught exceptions and
 *   uncaught promise exceptions are reported
 *   to GCP Error Reporting / Cloud Logging.
 * Overrides console.error which, from now,
 *   can be used to report errors to GCP.
 */
function initializeCloudLogging() {
  stackdriverErrorService.start({
    key: process.env.REACT_APP_STACKDRIVER_ERRORS,
    projectId: `wingspan-${BUILD_ENV}`,
    service: "wingspan-app",
    version: process.env.version,
    context: {
      [CatchTypePropName]: CatchType.UnhandledError
    }
  });

  console.error = function (error: any, ...data) {
    stackdriverErrorService.report(error, {
      [CatchTypePropName]: CatchType.HandledError,
      data
    });
  };
}

if (IS_HOSTED_ENV) initializeCloudLogging();

export default stackdriverErrorService;
