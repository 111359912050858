import {
  DashboardFocusedFeatureKey,
  DashboardFocusedFeaturesConfig
} from "./features";
import { useGetDashboardFocusedFeaturesContext } from "./useGetDashboardFocusedFeaturesContext";

export type DashboardFocusedFeatures = Record<
  DashboardFocusedFeatureKey,
  boolean
>;

export const useFocusedFeatures = (): DashboardFocusedFeatures => {
  const context = useGetDashboardFocusedFeaturesContext();
  let result: DashboardFocusedFeatures = {} as DashboardFocusedFeatures;

  for (const name in DashboardFocusedFeaturesConfig) {
    const key = name as DashboardFocusedFeatureKey;

    if (context) {
      result[key] = DashboardFocusedFeaturesConfig[key](context);
    } else {
      result[key] = false;
    }
  }

  return result;
};
