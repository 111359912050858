import {
  ContextItem,
  RequirementContextType,
  RequirementTableCallback,
  RequirementTableItemData,
  RequirementTableRowConfig
} from "./types";
import { useSignatureRequirementTableConfig } from "./modules/Signature";
import { useSharedFileRequirementTableConfig } from "./modules/SharedFile";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/requirement";
import { useBackgroundChecksRequirementTableConfig } from "./modules/BackgroundChecks";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces";
import { useRequirementOverrideActions } from "./modules/hooks/useRequirementOverrideActions";
import { useRequirementOverrideStatuses } from "./modules/hooks/useRequirementOverrideStatuses";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";

export const useRequirementTableConfig = (
  contextType: RequirementContextType,
  callback: RequirementTableCallback
) => {
  const featureFlag = useFeatureFlags();
  const signatureConfig = useSignatureRequirementTableConfig(callback);
  const sharedFileConfig = useSharedFileRequirementTableConfig(callback);
  const backgroundCheckConfig =
    useBackgroundChecksRequirementTableConfig(callback);

  const requirementOverrideActions = useRequirementOverrideActions(contextType);
  const requirementOverrideStatuses =
    useRequirementOverrideStatuses(contextType);

  const isOverrideFeatureFlagEnabled = featureFlag.data?.backgroundChecks;

  const config: RequirementTableRowConfig = {
    [RequirementType.Signature]: signatureConfig,
    [RequirementType.SharedFile]: sharedFileConfig,
    [RequirementType.BackgroundCheck]: backgroundCheckConfig
  };

  return {
    renderName(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].nameCell[contextType](
        item as IRequirementResponse,
        context
      );
    },
    renderType(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].typeCell[contextType](
        item as IRequirementResponse,
        context
      );
    },
    renderStatus(item: RequirementTableItemData, context: ContextItem) {
      const status = config[item.requirementType].statusCell[contextType](
        item as IRequirementResponse,
        context
      );

      //override status if requirement has payerOverrideStatus
      const overrideStatus = requirementOverrideStatuses(
        item as IRequirementResponse,
        status
      );

      return overrideStatus &&
        isOverrideFeatureFlagEnabled &&
        item.requirementType === RequirementType.BackgroundCheck
        ? overrideStatus
        : status;
    },
    renderRowActions(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].rowActions[contextType](
        item as IRequirementResponse,
        context
      );
    },
    renderRowMenuActions(item: RequirementTableItemData, context: ContextItem) {
      const actions = config[item.requirementType].rowMenuActions[contextType](
        item as IRequirementResponse,
        context
      );

      //add override actions(should it be with condition?)
      const overrideActions = requirementOverrideActions(
        item as IRequirementResponse,
        context
      );

      return contextType === RequirementContextType.Payee &&
        overrideActions &&
        context.showOverrideActions &&
        isOverrideFeatureFlagEnabled &&
        item.requirementType === RequirementType.BackgroundCheck
        ? [...actions, overrideActions]
        : actions;
    },
    fetchContext: async (
      requirements: RequirementTableItemData[]
    ): Promise<ContextItem> => {
      let context: ContextItem = {};

      if (sharedFileConfig?.fetchContext) {
        context[RequirementType.SharedFile] =
          await sharedFileConfig?.fetchContext(
            requirements as IRequirementResponse[]
          );
      }

      if (signatureConfig.fetchContext) {
        context[RequirementType.Signature] =
          await signatureConfig?.fetchContext(
            requirements as IRequirementResponse[]
          );
      }

      if (backgroundCheckConfig.fetchContext) {
        context[RequirementType.BackgroundCheck] =
          await backgroundCheckConfig?.fetchContext(
            requirements as IRequirementResponse[]
          );
      }

      return context;
    }
  } as const;
};
