import {
  TransactionStatus,
  TransactionType
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { WSCategory } from "@wingspanhq/bookkeeping/dist/lib/interfaces/transaction";
import {
  WSAmountInput,
  WSButton,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSInputDateOld,
  WSMessageBox,
  WSModal,
  WSPage,
  WSScreen,
  WSText,
  WSTextInput,
  useIsMobile
} from "@wingspanhq/fe-component-library";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { BrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { useCreateTransaction } from "../../../query/bookkeeping/mutations";
import { CurrencyUSD } from "../../../services/bookkeeping";
import { useGoBackOrToParent } from "../../../utils/goToParentRoute";
import {
  WSCategories,
  WSCategoryIcon
} from "../../components/WSCategories/WSCategories";
import { WhatIsARefund } from "../modals/WhatIsARefund";
import styles from "./BookkeepingTransaction.module.scss";

type Props = {
  onClose?: () => void;
} & RouteComponentProps;

const currentDate = new Date();

export const BookkeepingAddTransactionV2: React.FC<Props> = ({
  onClose = () => {},
  history
}) => {
  const goBack = useGoBackOrToParent(true);
  const isMobile = useIsMobile();
  const [createTransaction, createTransactionMeta] = useCreateTransaction();
  const addTxnForm = (
    <WSFormOld
      defaultValues={{
        amount: undefined,
        name: "",
        type: undefined,
        date: currentDate,
        wsCategory: "",
        subcategory: "",
        isPositive: false,
        business: true
      }}
      validationSchema={Yup.object().shape({
        amount: Yup.number()
          .typeError("Amount is required")
          .required("Amount is required"),
        name: Yup.string()
          .max(100, "Must be at most 100 characters")
          .required("Name is required"),
        wsCategory: Yup.string().required("Category is required"),
        date: Yup.date().required("Date is required")
      })}
      onSubmit={async formData => {
        const amount =
          Math.abs(formData.amount || 0) * (formData.isPositive ? 1 : -1);

        const newTransaction = {
          wsCategory: formData.wsCategory as WSCategory,
          currency: CurrencyUSD,
          amount,
          type: formData.type as any as TransactionType,
          name: formData.name,
          date: formData.date as any,
          status: TransactionStatus.Active,
          business: formData.business,
          businessAmount: amount,
          labels: {
            ...(formData.subcategory
              ? { subcategory: formData.subcategory }
              : null)
          }
        };
        const result = await createTransaction(newTransaction);
        if (result) {
          goBack();
        }
      }}
    >
      {({ setValue, getValues, clearErrors }) => (
        <>
          <WSFormOld.Field
            mb="XL"
            label="Dollar amount"
            name="amount"
            component={WSAmountInput}
            componentProps={{
              min: 0,
              onBlur: () =>
                // this should definitely be part of the base component in FECL
                setValue(
                  "amount",
                  getValues("amount") === ""
                    ? ""
                    : Number(getValues("amount")).toFixed(2)
                )
            }}
          />

          <WSFormOld.Field
            hidden={true}
            label="Type"
            name="type"
            component={WSTextInput}
          />
          <WSFormOld.Field
            hidden={true}
            name="wsCategory"
            component={WSTextInput}
          />
          <WSFormOld.Field
            hidden={true}
            name="subcategory"
            component={WSTextInput}
          />
          <WSFormOld.Field
            hidden={true}
            name="briefcase"
            component={WSTextInput}
          />
          <WSFormOld.Field
            hidden={true}
            name="business"
            component={WSTextInput}
          />
          <WSFormOld.Field
            hidden={true}
            name="isPositive"
            component={WSTextInput}
          />
          <WSFormOld.Values
            names={[
              "wsCategory",
              "subcategory",
              "type",
              "name",
              "amount",
              "business",
              "isPositive"
            ]}
          >
            {({
              wsCategory,
              subcategory,
              type,
              name,
              business,
              amount,
              isPositive
            }) => (
              <>
                <WSCategories
                  header={name}
                  data={{
                    wsCategory,
                    isPositive: true,
                    subcategory,
                    type,
                    business
                  }}
                  renderTrigger={({
                    label,
                    pseudoCategory,
                    value,
                    isOpened,
                    isLoading,
                    open,
                    close
                  }) => {
                    if (isLoading) {
                      return (
                        <WSFlexBox.CenterY
                          wrap="nowrap"
                          justify="space-between"
                          className={styles.selectCategory}
                        >
                          <WSFlexBox.CenterY>
                            <WSCategoryIcon
                              value={value}
                              shimmer
                              size="M"
                              mr="M"
                            />
                            <WSText shimmer>Category placeholder name</WSText>
                          </WSFlexBox.CenterY>
                        </WSFlexBox.CenterY>
                      );
                    }

                    if (label.wsCategory || label.pseudoCategory) {
                      return (
                        <WSFlexBox.CenterY
                          wrap="nowrap"
                          justify="space-between"
                          className={styles.selectCategory}
                          onClick={open}
                          data-testid="categoryLabel"
                          data-testdata={JSON.stringify({
                            wsCategory,
                            subcategory,
                            type,
                            name,
                            business,
                            amount,
                            isPositive
                          })}
                        >
                          <WSFlexBox.CenterY>
                            <WSCategoryIcon value={value} mr="M" size="M" />
                            <WSElement>
                              <WSText color="gray600">
                                {label.pseudoCategory ?? label.wsCategory}
                              </WSText>
                              <WSText.ParagraphSm color="gray500">
                                {label.pseudoCategory
                                  ? label.wsCategory
                                  : label.subcategory}
                              </WSText.ParagraphSm>
                            </WSElement>
                          </WSFlexBox.CenterY>
                          <WSIcon
                            block
                            size="XS"
                            name="chevron-down"
                            color="blue500"
                          />
                        </WSFlexBox.CenterY>
                      );
                    }

                    return (
                      <WSFlexBox.CenterY
                        wrap="nowrap"
                        justify="space-between"
                        className={styles.selectCategory}
                        onClick={open}
                        data-testid="addCategory"
                      >
                        <WSFlexBox.CenterY>
                          <WSButton.Link>Add category</WSButton.Link>
                        </WSFlexBox.CenterY>
                        <WSIcon
                          block
                          size="XS"
                          name="chevron-down"
                          color="blue500"
                        />
                      </WSFlexBox.CenterY>
                    );
                  }}
                  onUpdate={async data => {
                    setValue("wsCategory", data.wsCategory);
                    setValue("subcategory", data.subcategory);
                    setValue("type", data.type);
                    setValue("business", data.business);
                    setValue("isPositive", data.isPositive);
                    clearErrors("wsCategory");
                  }}
                />
              </>
            )}
          </WSFormOld.Values>
          <WSFormOld.Error name="wsCategory" />

          <WSFormOld.Field
            my="XL"
            label="Name"
            name="name"
            component={WSTextInput}
          />

          <WSFormOld.Field
            mb="2XL"
            label="Date"
            name="date"
            component={WSInputDateOld}
            componentProps={{
              selectionMode: "single",
              placeholder: "Select"
            }}
          />

          {createTransactionMeta.isError ? (
            <WSMessageBox.Error>
              Sorry, we encountered an error while creating your transaction!
              Please try again. If this persists, please contact support.
            </WSMessageBox.Error>
          ) : null}

          <WSFlexBox
            wrap={isMobile ? "wrap" : "nowrap"}
            direction={isMobile ? "column" : "row"}
            mt="XL"
          >
            <WSButton.Tertiary
              type="button"
              fullWidth={isMobile}
              mr={!isMobile ? "M" : "NONE"}
              mb={isMobile ? "M" : "NONE"}
              onClick={goBack}
            >
              Cancel
            </WSButton.Tertiary>
            <WSButton.Primary
              type="submit"
              fullWidth={isMobile}
              loading={createTransactionMeta.isLoading}
              data-testId="saveTransaction"
            >
              Add transaction
            </WSButton.Primary>
          </WSFlexBox>
        </>
      )}
    </WSFormOld>
  );
  return (
    <>
      <BrowserPageTitle title="Add transaction" />
      <WhatIsARefund />
      <WSScreen.MobileAndTablet>
        <WSPage
          title="Add transaction"
          breadcrumb={{
            label: "Transactions",
            icon: "arrow-left",
            onClick: goBack
          }}
        >
          {addTxnForm}
        </WSPage>
      </WSScreen.MobileAndTablet>
      <WSScreen.Desktop>
        <WSModal size={"S"} onClose={goBack}>
          <WSText.Heading4 mr="M" mb="M">
            Add transaction
          </WSText.Heading4>
          {addTxnForm}
        </WSModal>
      </WSScreen.Desktop>
    </>
  );
};
