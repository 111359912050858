import classNames from "classnames";
import React from "react";
import { WSAvatar, WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";
import { WSBadge, WSBadgeProps } from "../common/WSBadge/WSBadge.component";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSElement } from "../WSElement/WSElement.component";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";
import styles from "./WSLabel.module.scss";

const DEFAULT_AVATAR_SIZE = "S";
const DEFAULT_LINK_SIZE = "L";
const DEFAULT_HELPER_LINK_SIZE = "S";

export type WSLabelProps = {
  avatar?: WSAvatarProps;
  badge?: WSBadgeProps;
  text?: string;
  link?: WSButtonProps<"Link">;
  secondaryText?: string;
  helperText?: string;
  helperLink?: WSButtonProps<"Link">;
  tooltip?: WSTooltipIconProps["tooltip"];
};

export const WSLabel: React.FC<WSLabelProps> = (props) => {
  const avatarElement = props.avatar ? (
    <WSAvatar size={DEFAULT_AVATAR_SIZE} {...props.avatar} />
  ) : undefined;

  const badgeElement = props.badge ? <WSBadge {...props.badge} /> : undefined;

  const textElement = props.text ? (
    <WSText weight="medium" color="gray600">
      {props.text}
    </WSText>
  ) : undefined;

  const linkElement = props.link ? (
    <WSButton.Link
      size={DEFAULT_LINK_SIZE}
      {...props.link}
      className={classNames(styles.link, props.link.className)}
    />
  ) : undefined;

  const secondaryTextElement = props.secondaryText ? (
    <WSText color="gray500">{props.secondaryText}</WSText>
  ) : undefined;

  const helperTextElement = props.helperText ? (
    <WSText kind="ParagraphXs" color="gray400">
      {props.helperText}
    </WSText>
  ) : undefined;

  const helperLinkElement = props.helperLink ? (
    <WSButton.Link
      size={DEFAULT_HELPER_LINK_SIZE}
      {...props.helperLink}
      className={classNames(styles.helperLink, props.helperLink.className)}
    />
  ) : undefined;

  const tooltipElement = props.tooltip ? (
    <WSTooltipIcon tooltip={props.tooltip} />
  ) : undefined;

  const bottomElement = helperTextElement || helperLinkElement;

  return (
    <WSElement className={styles.label}>
      {avatarElement || badgeElement}
      {bottomElement ? (
        <WSElement>
          <WSElement className={styles.top}>
            {textElement || linkElement}
            {secondaryTextElement}
            {tooltipElement}
          </WSElement>
          {bottomElement}
        </WSElement>
      ) : (
        <>
          {textElement || linkElement}
          {secondaryTextElement}
          {tooltipElement}
        </>
      )}
    </WSElement>
  );
};
