import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useUserId } from "../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { FormAddDebitCard } from "../../../../shared/components/FormAddDebitCard";
import { FormManuallyAddAccount } from "../../../../shared/components/FormManuallyAddAccount";
import { FormManuallyAddInternationalAccount } from "../../../../shared/components/FormManuallyAddInternationalAccount";
import { StepLayoutWithOptions } from "../../../../shared/components/Layout/StepLayoutWithOptions";
import {
  avatarInternationalBankAccount,
  avatarUSBankAccount,
  avatarInstantPayout,
  avatarWallet,
  avatarBankAccount,
  avatarBankCard,
  avatarPlaid
} from "../../../../shared/constants/avatars";
import { selectorInstantPayoutDefaultFee } from "../../../../shared/selectors/selectorInstantPayoutDefaultFee";
import { useWSPlaidLinkExtended } from "../../../../utils/useWSPlaidLinkExtended";
import { FormCreateInternalAccount } from "../../../Onboarding/components/FormCreateInternalAccount";
import { selectorAccountRtpReady } from "../../../Transfer/components/FormTransfer/selectorAccountRtpReady";
import { selectorAccountWithdrawalReady } from "../../../Transfer/components/FormTransfer/selectorAccountWithdrawalReady";
import { FormSelectAccount } from "../FormSelectAccount";

export type PayoutMethodType =
  | "instant"
  | "standard"
  | "wallet"
  | "international";

export type PayoutMethodInstantType =
  | "instant-card"
  | "instant-plaid"
  | "instant-manual";

export type PayoutMethodStandardType = "standard-plaid" | "standard-manual";

type Props = { basePath: string; onSuccess?: () => void };

export const FlowAddPayoutMethod: React.FC<Props> = ({
  basePath,
  onSuccess
}) => {
  const queryCustomerEntity = useQueryCustomerEntity();
  const isInternational =
    queryCustomerEntity.data?.country &&
    queryCustomerEntity.data?.country !== "US";
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);

  const history = useHistory();
  const [createdAccounts, setCreatedAccounts] = useState<IAccount[]>([]);

  const { openSnackbar } = useWSSnackbar();

  const instantPlaidLink = useWSPlaidLinkExtended({
    onSuccess: accounts => {
      const filteredAccounts = accounts.filter(selectorAccountRtpReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/instant-plaid");
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payout method"
        });
      }
    }
  });

  const standardPlaidLink = useWSPlaidLinkExtended({
    onSuccess: accounts => {
      const filteredAccounts = accounts.filter(selectorAccountWithdrawalReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/standard-plaid");
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payout method"
        });
      }
    }
  });

  return (
    <Switch>
      <Route path={basePath + "/type"}>
        <StepLayoutWithOptions<PayoutMethodType>
          key="type"
          title="Add payout method"
          description="Add a payout method to deposit your income"
          options={
            isInternational
              ? [
                  {
                    header: {
                      label: {
                        text: "Non-U.S. account",
                        helperText:
                          "Wingspan will deposit into your bank account in the currency of that account in a non-U.S. country or in U.S.-dollars for USD accounts.",
                        avatar: avatarInternationalBankAccount
                      }
                    },
                    value: "international"
                  },
                  {
                    header: {
                      label: {
                        text: "U.S. based account",
                        helperText:
                          "Wingspan will keep your deposits in USD and deposit it in your U.S.-based account.",

                        avatar: avatarUSBankAccount
                      }
                    },
                    value: "standard"
                  }
                ]
              : [
                  {
                    header: {
                      label: {
                        text: `Instant payout (${selectorInstantPayoutDefaultFee(
                          queryPayoutSettings.data
                        )}% fee)`,
                        helperText:
                          "Fastest. Receive your payments immediately when sent to eligible bank & debit cards.",
                        avatar: avatarInstantPayout
                      }
                    },
                    value: "instant"
                  },
                  {
                    header: {
                      label: {
                        text: "Wingspan Wallet (free)",
                        helperText: "Faster. Free business bank & debit card.",
                        avatar: avatarWallet
                      }
                    },
                    value: "wallet"
                  },
                  {
                    header: {
                      label: {
                        text: "Bank account (free)",
                        helperText:
                          "Standard. Connect your existing bank account.",
                        avatar: avatarBankAccount
                      }
                    },
                    value: "standard"
                  }
                ]
          }
          onNext={value => {
            history.push(basePath + "/" + value);
          }}
        />
      </Route>

      <Route path={basePath + "/instant"}>
        <StepLayoutWithOptions<PayoutMethodInstantType>
          key="instant"
          title={`Add instant payout method (${selectorInstantPayoutDefaultFee(
            queryPayoutSettings.data
          )}% fee)`}
          description={`Receive your funds in under an hour. A ${selectorInstantPayoutDefaultFee(
            queryPayoutSettings.data
          )}% fee will be applied when the transaction is made.`}
          options={[
            {
              header: {
                label: {
                  text: "Connect a debit card",
                  helperText:
                    "Receive payments by connecting an eligible Visa or Mastercard debit card",
                  avatar: avatarBankCard
                }
              },
              value: "instant-card"
            },
            {
              header: {
                label: {
                  text: "Set up direct deposit with Plaid",
                  helperText:
                    "Instantly connect your bank accounts securely (Instant verification)",
                  avatar: avatarPlaid
                }
              },
              value: "instant-plaid"
            },
            {
              header: {
                label: {
                  text: "Set up direct deposit manually",
                  helperText: "Manually enter your routing and account numbers",
                  avatar: avatarBankAccount
                }
              },
              value: "instant-manual"
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onNext={value => {
            if (value === "instant-plaid") {
              instantPlaidLink.open();
            } else {
              history.push(basePath + "/" + value);
            }
          }}
        />
      </Route>

      <Route path={basePath + "/standard"}>
        <StepLayoutWithOptions<PayoutMethodStandardType>
          key="stanrard"
          title="Add bank account (free)"
          description="Add a bank account and receive your funds in the standard 2-5 business days."
          options={[
            {
              header: {
                label: {
                  text: "Link account with Plaid",
                  helperText:
                    "Instantly verify and connect your bank accounts securely",
                  avatar: avatarPlaid
                }
              },
              value: "standard-plaid"
            },
            {
              header: {
                label: {
                  text: "Set up account manually",
                  helperText: "Manually enter your routing and account numbers",
                  avatar: avatarBankAccount
                }
              },
              value: "standard-manual"
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onNext={value => {
            if (value === "standard-plaid") {
              standardPlaidLink.open();
            } else {
              history.push(basePath + "/" + value);
            }
          }}
        />
      </Route>

      <Route path={basePath + "/wallet"}>
        <FormCreateInternalAccount
          type="Banking"
          currency="USD"
          setStandardPayoutDestination
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={onSuccess}
        />
      </Route>

      <Route path={basePath + "/instant-card"}>
        <FormAddDebitCard
          setInstantPayoutPreference
          updateInstantDestinaiton
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Route path={basePath + "/instant-plaid"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your instant payout method"
          setInstantPayoutDestination
          accounts={createdAccounts}
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onNext={onSuccess}
        />
      </Route>

      <Route path={basePath + "/instant-manual"}>
        <FormManuallyAddAccount
          rtpRequired
          setInstantPayoutDestination
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Route path={basePath + "/standard-plaid"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your payout method"
          setStandardPayoutDestination
          accounts={createdAccounts}
          onBack={() => {
            history.push(basePath + "/standard");
          }}
          onNext={onSuccess}
        />
      </Route>

      <Route path={basePath + "/standard-manual"}>
        <FormManuallyAddAccount
          setStandardPayoutDestination
          onBack={() => {
            history.push(basePath + "/standard");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Route path={basePath + "/international"}>
        <FormManuallyAddInternationalAccount
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Redirect path={basePath} to={basePath + "/type"} />
    </Switch>
  );
};
