import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { FlowMEChangeContext } from "..";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { FormBusinessInformation } from "../../../components/FormBusinessInformation";
import { getProfileDataBusiness } from "../../../components/FormBusinessInformation/getProfileDataBusiness";
import { OnboardingStep } from "../../../types";

export const StepMaterialUpdateBusinessInformation: React.FC<
  OnboardingStep
> = props => {
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const profileData = useMemo(
    () => getProfileDataBusiness(queryMember.data),
    [queryMember.data]
  );

  return (
    <WSList gap="2XL">
      <FormBusinessInformation
        country={context.accountType?.country || "US"}
        defaultValues={context.businessInformation || profileData}
        onBack={props.onBack}
        onSubmit={data => {
          setContext(prev => ({ ...prev, businessInformation: data }));
          props.onNext?.();
        }}
        show1099Message
        personalEmail={queryUser.data?.email}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
