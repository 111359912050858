import { FAQ } from "../../../../shared/components/FAQ";
import React from "react";
import {
  WSMarginProps,
  WSPaddingProps
} from "@wingspanhq/fe-component-library/dist/lib/components/WSElement/WSElement.component";

export const TaxFilingFAQ: React.FC<WSMarginProps & WSPaddingProps> = props => (
  <FAQ
    {...props}
    mb="2XL"
    sections={[
      {
        title: "About 1099-NEC tax filing",
        links: [
          {
            title: "What is the 1099 tax filing process on Wingspan?",
            url: "https://docs.wingspan.app/v2.0/docs/getting-started-1099-filing"
          },
          {
            title: "How do I set up 1099 filing?",
            url: "https://docs.wingspan.app/v2.0/docs/create-1099-nec-forms"
          },
          {
            title: "What are the 1099 statuses?",
            url: "https://docs.wingspan.app/v2.0/docs/understanding-1099-statuses"
          },
          {
            title: "How do I generate tax forms?",
            url: "https://docs.wingspan.app/docs/calculate-review-and-update-1099-tax-forms"
          },
          {
            title: "How do I update tax forms?",
            url: "https://docs.wingspan.app/docs/updating-tax-forms"
          },
          {
            title: "How do I file and deliver tax forms?",
            url: "https://docs.wingspan.app/docs/deliver-tax-forms"
          }
        ]
      },
      {
        title: "Contractors & payments",
        links: [
          {
            title: "How do I upload contractors to Wingspan?",
            url: "https://docs.wingspan.app/docs/importing-recipients-from-off-platform-to-wingspan"
          },
          {
            title: "How do I confirm contractors information?",
            url: "https://docs.wingspan.app/docs/recipient-information-determination-logic-for-1099-forms-in-wingspan"
          },
          {
            title: "How do I upload off-platform payments?",
            url: "https://docs.wingspan.app/docs/batch-uploading-off-platform-payments"
          },
          {
            title: "What is the contractor experience to access tax forms?",
            url: "https://docs.wingspan.app/docs/collaborator-experience-walkthrough"
          }
        ]
      },
      {
        title: "Contractor support",
        links: [
          {
            title: "What are my responsibilities as a payer?",
            url: "https://docs.wingspan.app/docs/support-ownership"
          },
          {
            title: "How should I communicate to my contractors?",
            url: "https://docs.wingspan.app/docs/support-communication-guide"
          }
        ]
      },
      {
        title: "Misc.",
        links: [
          {
            title: "How do I correct 1099 forms after filing?",
            url: "https://docs.wingspan.app/docs/correct-tax-forms"
          },
          {
            title: "What are the limitations of filing support at Wingspan?",
            url: "https://docs.wingspan.app/docs/limitations-of-1099-nec-filing-support-at-wingspan"
          },
          {
            title: "Other FAQs",
            url: "https://docs.wingspan.app/docs/payer-frequently-asked-questions"
          }
        ]
      }
    ]}
  />
);
