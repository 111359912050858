import {
  WSAlert,
  WSButton,
  WSControl,
  WSElement,
  WSElementProps,
  WSForm,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormPartialDataPerson, FormPartialPerson } from "../FormPartialPerson";
import { emptyValues } from "../FormPartialPerson/emptyValues";
import { calculateTotalStake } from "./helpers";

type Props = { country: string } & WSElementProps;

const fieldName = "beneficialOwners";

export const BeneficialOwners: React.FC<Props> = ({
  country,
  ...elementProps
}) => {
  const { control, setValue, getValues, setError, clearErrors } =
    useFormContext();
  const {
    fields: beneficialOwners,
    append,
    remove
  } = useFieldArray<FormPartialDataPerson>({
    control,
    name: fieldName
  });

  const initialValues = useMemo(
    () => ({ ...emptyValues, ownershipStake: 25, country }),
    [country]
  );

  useEffect(() => {
    // Hack: set default values for the created beneficial owner
    const values = getValues();

    if (
      values.beneficialOwners &&
      values.beneficialOwners.length > 0 &&
      values.beneficialOwners[values.beneficialOwners.length - 1].firstName ===
        undefined
    ) {
      setValue(
        `${fieldName}[${values.beneficialOwners.length - 1}]`,
        initialValues
      );

      // Hack: trigger rerender to set default values correctly
      setError("representative.addressLine2", { message: "" });
      clearErrors("representative.addressLine2");
    }
  }, [
    beneficialOwners,
    getValues,
    initialValues,
    setValue,
    setError,
    clearErrors
  ]);

  return (
    <WSElement {...elementProps}>
      <WSList gap="2XL">
        <WSElement>
          <WSSectionToolbar title="Beneficial owners" />
          <WSText.ParagraphSm color="gray500">
            We need to verify the identity of all beneficial owners of the
            business in the process. Being a beneficial owner means the person
            owns at least 25% of the business.
          </WSText.ParagraphSm>
        </WSElement>

        {beneficialOwners.map((beneficialOwner, index) => (
          <WSPanel key={beneficialOwner.id}>
            <WSSectionToolbar
              mb="M"
              title={`Beneficial owner (${index + 1})`}
              button={{
                label: "Delete",
                onClick: () => {
                  remove(index);
                }
              }}
            />

            <FormPartialPerson
              type="BeneficialOwner"
              name={`${fieldName}[${index}]`}
              showOwnershipStake
              requireTaxId
              country={country}
            />
          </WSPanel>
        ))}

        <WSForm.Values names={["representative", "beneficialOwners"]}>
          {values => {
            const representativeOwnershipStake =
              values.representative?.ownershipStake || 0;
            const beneficialOwners = values.beneficialOwners || 0;

            const stakesSum = calculateTotalStake(
              representativeOwnershipStake,
              beneficialOwners
            );

            return stakesSum === 100 ? (
              <WSPanel>
                <WSInfoBox>
                  100% ownership stake reached across all beneficial owners
                </WSInfoBox>
              </WSPanel>
            ) : stakesSum > 100 ? (
              <WSAlert theme="error">
                Total ownership cannot be larger than 100%
              </WSAlert>
            ) : (
              <WSPanel>
                <WSButton.Link
                  type="button"
                  icon="plus"
                  mb="2XL"
                  size="M"
                  onClick={() => {
                    append(initialValues);
                  }}
                >
                  add beneficial owner
                </WSButton.Link>

                <WSForm.Field
                  name="allOwnersAdded"
                  component={WSControl}
                  componentProps={{
                    type: "checkbox",
                    size: "S",
                    label: "I have added all beneficial owners of the business"
                  }}
                />
              </WSPanel>
            );
          }}
        </WSForm.Values>
      </WSList>
    </WSElement>
  );
};
