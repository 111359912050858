import {
  SubscriptionPackage,
  SubscriptionPackageTier
} from "@wingspanhq/users/dist/lib/interfaces/subscription";

export interface IDisplaySubscriptionPackageWithoutAmount {
  title: string;
  package: keyof typeof SubscriptionPackage;
  tier: keyof typeof SubscriptionPackageTier;
  description?: string;
  valuePropostions: Array<string>;
  includeDescription?: string;
  isPopular?: boolean;
  isAddOn?: boolean;
}

export const SUBSCRIPTION_PACKAGES: IDisplaySubscriptionPackageWithoutAmount[] =
  [
    {
      title: "Individuals",
      package: SubscriptionPackage.None,
      tier: SubscriptionPackageTier.Basic,
      description: "One stop shop for your business of one.",
      valuePropostions: [
        "Send or pay unlimited invoices",
        "Tax withholdings & IRS payments",
        "Income & expense management",
        "Access to insurance products"
      ],
      isPopular: false
    },
    {
      title: "Teams",
      package: SubscriptionPackage.Professional,
      tier: SubscriptionPackageTier.Basic,
      description:
        "For when you've scaled, and your business backend needs to scale too.",
      valuePropostions: [
        "Splits and contractor payroll",
        "Form W-9 & 1099 compliance",
        "Multiple businesses/entities",
        "Unlimited users, invite your CPA"
      ],
      isPopular: false
    },
    {
      title: "Enterprises",
      // NOTE: We don't have a dedicated pair(package, tier) of values for Enterprise.
      // So just using below values.
      package: SubscriptionPackage.None,
      tier: SubscriptionPackageTier.Premium,
      description:
        "For teams that require more capabilities including custom integrations and API",
      valuePropostions: [
        "Custom approval flows",
        "API integrations",
        "Dedicated onboarding",
        "Co-branding"
      ],
      isPopular: false
    },
    {
      title: "Premium Perks",
      package: SubscriptionPackage.Benefits,
      tier: SubscriptionPackageTier.Basic,
      description: "Get the benefits & perks you need",
      valuePropostions: [
        "Teladoc ($0 per visit)",
        "Health Advocate",
        "Financial, legal & tax support",
        "Medical support & savings"
      ],
      isPopular: false
    },
    {
      title: "Dedicated CPA",
      package: SubscriptionPackage.Professional,
      tier: SubscriptionPackageTier.Premium,
      description: "For when you need an assistant.",
      valuePropostions: [
        "Dedicated bookkeeper",
        "Monthly reconciliations & reporting",
        "Business & personal tax returns"
      ],
      isPopular: false
    }
  ];

export const SUBSCRIPTION_PACKAGES_ADDONS: IDisplaySubscriptionPackageWithoutAmount[] =
  [
    {
      title: "Individual",
      package: SubscriptionPackage.None,
      tier: SubscriptionPackageTier.Basic,
      description: "For the individual freelancer",
      valuePropostions: [
        "Unlimited invoicing",
        "Unlimited payments",
        "Tax withholdings & payments to the IRS",
        "Income & expense reporting",
        "No transaction fees"
      ]
    },
    {
      title: "Teams",
      package: SubscriptionPackage.Professional,
      tier: SubscriptionPackageTier.Basic,
      description: "For teams & freelance businesses",
      includeDescription: "Everything in Free, plus",
      valuePropostions: [
        "Contractor payroll & splits",
        "Form W-9 & 1099 compliance",
        "Support for multiple businesses & entities",
        "Unlimited users, invite your CPA"
      ],
      isPopular: true
    },
    {
      title: "Enterprise",
      // NOTE: We don't have a dedicated pair(package, tier) of values for Enterprise.
      // So just using below values.
      package: SubscriptionPackage.None,
      tier: SubscriptionPackageTier.Premium,
      description: "For big businesses working with freelancer",
      includeDescription: "Everything in Teams, plus",
      valuePropostions: [
        "Custom approval flows",
        "API integrations",
        "Dedicated onboarding",
        "Co-branding"
      ]
    },
    {
      title: "Premium Perks",
      package: SubscriptionPackage.Benefits,
      tier: SubscriptionPackageTier.Basic,
      valuePropostions: [
        "Teladoc ($0 per visit)",
        "Health Advocate",
        "Financial, legal & tax support",
        "Medical support & savings"
      ],
      isAddOn: true
    },
    {
      title: "S-Corp Payroll",
      // NOTE: We don't have a dedicated pair(package, tier) of values for S-Corp Payroll.
      // So just using below values.
      package: SubscriptionPackage.None,
      tier: SubscriptionPackageTier.Basic,
      valuePropostions: [
        "Payroll on autopilot",
        "State & federal payroll taxes",
        "Accounting integrations"
      ],
      isAddOn: true
    },
    {
      title: "Dedicated CPA",
      package: SubscriptionPackage.Professional,
      tier: SubscriptionPackageTier.Premium,
      valuePropostions: [
        "Dedicated bookkeeper",
        "Monthly reconciliations & detailed reporting",
        "Business & personal tax returns"
      ],
      isAddOn: true
    }
  ];

export const FREE_TRIAL_DAYS = 14;
