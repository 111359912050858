import {
  WSButton,
  WSElement,
  WSFileInput,
  WSImage,
  WSText
} from "@wingspanhq/fe-component-library";
import { IMember, IMemberUpdateRequest } from "@wingspanhq/users";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  useMemberPublicCreate,
  useMemberPublicDelete
} from "../../../query/files/mutations";
import { useGetMemberPublicFile } from "../../../query/files/queries";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";

import settingItemStyles from "../DefaultSettingItem/DefaultSettingItem.module.scss";
import styles from "./BusinessLogoUploadSettingItem.module.scss";
import { useScrollIntoViewByHash } from "../../../utils/useScrollIntoViewByHash";

interface BusinessLogoUploadSettingItemProps {
  logoFileId: string;
}

enum LogoAction {
  CreateOrUpdate = "CreateOrUpdate",
  Delete = "Delete"
}

export const BusinessLogoUploadSettingItem: React.FC<
  BusinessLogoUploadSettingItemProps
> = ({ logoFileId }) => {
  const userId = useUserId();
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [action, setAction] = useState<LogoAction>(LogoAction.CreateOrUpdate);

  const scrollableRef = useScrollIntoViewByHash("marketing");

  useGetMemberPublicFile(logoFileId || "", {
    onSuccess: (response: any) => {
      setLogoUrl(response.url);
    }
  });

  const [uploadFile, uploadFileMeta] = useMemberPublicCreate();
  const [updateMemberProfile, updateMemberProfileMeta] =
    useUpdateMemberProfile();
  const [deletePublicFile, deletePublicFileMeta] = useMemberPublicDelete();
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAction(LogoAction.CreateOrUpdate);
    uploadFileMeta.reset();
    if (event.target && event.target.files && event.target.files) {
      const logoFile = event.target.files[0];
      const data = new FormData();
      data.append("file", logoFile);
      await uploadFile(data, {
        onSuccess: async (response: any) => {
          if (response.length > 0) {
            setLogoUrl(response[0].url);
            const request: IMemberUpdateRequest = {
              memberId: userId,
              profile: {
                logoFileId: response[0].fileId
              }
            };
            await updateMemberProfile(request, {
              onError: () => {
                setLogoUrl("");
              }
            });
          }
        }
      });
    }
  };

  const onRemoveLogo = async () => {
    deletePublicFileMeta.reset();
    setAction(LogoAction.Delete);
    await updateMemberProfile(
      {
        memberId: userId,
        profile: {
          logoFileId: ""
        }
      },
      {
        onSuccess: async () => {
          setLogoUrl("");
          await deletePublicFile(logoFileId);
        }
      }
    );
  };

  return (
    <WSElement
      mt="2XL"
      className={settingItemStyles.settingItem}
      ref={scrollableRef}
    >
      <WSElement className={settingItemStyles.infoContainer}>
        <WSImage
          src={logoUrl}
          width={80}
          placeholder={<WSElement className={styles.logoPlaceholder} />}
        />
        {uploadFileMeta.error ||
        updateMemberProfileMeta.error ||
        deletePublicFileMeta.error ? (
          <WSText.ParagraphSm weight="medium" mt="XS" color="red400">
            {uploadFileMeta.error?.response?.data["error"] ||
              updateMemberProfileMeta.error?.response?.data["error"] ||
              deletePublicFileMeta.error?.response?.data["error"]}
          </WSText.ParagraphSm>
        ) : null}
        <WSText.ParagraphSm color="gray600" mt="XL">
          Only PNG/JPG formats supported. Images with a 120px height work best.
          If your image is too large, we'll try to resize it for you.
        </WSText.ParagraphSm>
      </WSElement>
      <WSElement className={settingItemStyles.actionContainer}>
        <WSFileInput name="file" onChange={onFileChange}>
          <WSButton.Link
            loading={
              action === LogoAction.CreateOrUpdate &&
              (uploadFileMeta.isLoading || updateMemberProfileMeta.isLoading)
            }
          >
            {logoUrl ? "Edit" : "Add logo"}
          </WSButton.Link>
        </WSFileInput>
        {logoUrl ? (
          <WSButton.Link
            mt="M"
            onClick={onRemoveLogo}
            loading={
              action === LogoAction.Delete &&
              (deletePublicFileMeta.isLoading ||
                updateMemberProfileMeta.isLoading)
            }
          >
            Remove
          </WSButton.Link>
        ) : null}
      </WSElement>
    </WSElement>
  );
};
