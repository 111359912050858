import {
  WSButton,
  WSCheckboxToggle,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { FormInputOnboardingPayoutMethod } from "../../../../shared/components/FormInputOnboardingPayoutMethod";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { usePaymentCardForm } from "../../../../shared/hooks/usePaymentCardForm";
import { PayoutMethodType } from "../../../../shared/types/payments";
import { WS_LINKS } from "../../../../types/wsLinks";
import { RouteComponentProps } from "../../types";
import { useMutationSubmit } from "./useMutationSubmit";

export const RoutePayouts: React.FC<RouteComponentProps> = ({
  onBack,
  onNext,
  params
}) => {
  const [payoutMethodType, setPayoutMethodType] = useState<
    PayoutMethodType | undefined
  >();
  const [agreement, setAgreement] = useState(false);
  const [submit, submitMeta] = useMutationSubmit({ onSuccess: onNext });
  const payoutMethods = params?.availablePayoutMethods || [
    PayoutMethodType.Instant,
    PayoutMethodType.Wallet,
    PayoutMethodType.ACH
  ];

  const queryFeatureFlags = useFeatureFlags();
  const paymentCardForm = usePaymentCardForm();

  return (
    <LayoutPaymentsSetup
      onBack={onBack}
      title={
        params?.skipTaxVerification
          ? "Where should we deposit your payments?"
          : "Where should we deposit your income?"
      }
    >
      {payoutMethods.map(payoutMethod => (
        <FormInputOnboardingPayoutMethod
          type={payoutMethod}
          checked={payoutMethodType === payoutMethod}
          onChange={setPayoutMethodType}
        />
      ))}

      <WSCheckboxToggle
        mt="3XL"
        mb="XL"
        name="agreement"
        label={
          <>
            I agree to the{" "}
            <a
              href={WS_LINKS.depositAccountTermsConditions}
              target="_blank"
              rel="noreferrer"
            >
              Deposit Account Terms and Conditions
            </a>
            ,{" "}
            <a
              href={WS_LINKS.electronicDisclosureAndConsent}
              target="_blank"
              rel="noreferrer"
            >
              Electronic Disclosure and Consent
            </a>
            , and{" "}
            <a href={WS_LINKS.TCCITerms} target="_blank" rel="noreferrer">
              TCCI Terms of Use
            </a>
            .
          </>
        }
        value={agreement}
        onChange={value => {
          setAgreement(value);
        }}
      />

      <WSButton
        mb="XL"
        name="continue"
        onClick={() => {
          if (
            queryFeatureFlags.data?.paymentCardAsPayoutMethod &&
            payoutMethodType === PayoutMethodType.Instant
          ) {
            paymentCardForm.open({
              onSuccess: () => {
                submit({ payoutMethodType });
              }
            });
          } else {
            submit({ payoutMethodType });
          }
        }}
        loading={submitMeta.isLoading}
        disabled={!payoutMethodType || !agreement}
        fullWidth
      >
        Continue
      </WSButton>

      <WSText.ParagraphXs color="gray600" mb="M">
        We work with Stripe to power credit payments. By continuing, you agree
        to{" "}
        <a
          href={WS_LINKS.stripeConnectedTerms}
          target="_blank"
          rel="noreferrer"
        >
          Stripe Connected Account Agreement
        </a>
        .
      </WSText.ParagraphXs>

      <WSText.ParagraphXs color="gray600">
        Wingspan is a financial services technology company and is not a bank.
        Banking services provided by Lead Bank, Member FDIC.
      </WSText.ParagraphXs>
    </LayoutPaymentsSetup>
  );
};
