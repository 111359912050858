import { useUserId } from "../../query/hooks/helpers";
import { queryCache } from "react-query";
import { QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE } from "../../query/users/keys";

export const useXWingspanExpansion = () => {
  const userId = useUserId();

  const organizationUsersListSize = queryCache.getQueryData([
    QUERY_USERS_ALL_ORGANIZATION_USERS_LIST_SIZE,
    undefined
  ]);

  return organizationUsersListSize ? userId : undefined;
};
