import {
  useWSModal,
  WSControl,
  WSElement,
  WSForm,
  WSInputText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { queryCache } from "react-query";
import * as Yup from "yup";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { QUERY_USERS_MEMBER_PROFILE } from "../../../../../query/users/keys";
import { useMemberProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { validatorWebsite } from "../../../../../shared/validators/validatorWebsite";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { website: string; noWebsite: boolean };

type Props = {
  onClose: () => void;
};

export const FormEditWebsite: React.FC<Props> = props => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const member = await usersService.member.update(userId, {
        memberId: userId,
        profile: {
          company: {
            website: data.noWebsite ? "" : data.website
          }
        }
      });

      queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);
    },
    {
      dependencies: [QUERY_USERS_MEMBER_PROFILE],
      onSuccess: props.onClose
    }
  );

  const initialWebsite = queryMember.data?.profile.company?.website;

  return (
    <WSForm<FormData>
      onSubmit={mutate}
      defaultValues={{
        website:
          !initialWebsite || initialWebsite === "None" ? "" : initialWebsite,
        noWebsite: !initialWebsite
      }}
      validationSchema={Yup.object({
        website: validatorWebsite,
        noWebsite: Yup.boolean()
          .nullable()
          .when("website", {
            is: (website: string) => !website,
            then: Yup.boolean()
              .nullable()
              .isTrue(
                "Please check the box if your business does not have a website"
              )
          })
      })}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        <WSElement>
          <WSForm.Value name="noWebsite">
            {noWebsite => (
              <WSForm.Field
                mb="S"
                name="website"
                label="Business website (optional)"
                component={WSInputText}
                componentProps={{
                  disabled: noWebsite,
                  autoComplete: "url",
                  placeholder: "http://"
                }}
              />
            )}
          </WSForm.Value>

          <WSForm.Field
            name="noWebsite"
            component={WSControl}
            componentProps={{
              type: "checkbox",
              size: "S",
              label: "The business does not have a website"
            }}
          />
        </WSElement>
      </LayoutFormEdit>
    </WSForm>
  );
};

export const useModalEditWebsite = () =>
  useWSModal(FormEditWebsite, {
    title: "Business website",
    size: "S"
  });
