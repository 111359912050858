import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { FlowMEChangeContext } from "..";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { FormAccountType } from "../../../components/FormAccountType";
import { getProfileDataAccountType } from "../../../components/FormAccountType/getProfileDataAccountType";
import { OnboardingStep } from "../../../types";

export const StepMaterialUpdateAccountType: React.FC<
  OnboardingStep
> = props => {
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();

  const queryCustomerEntity = useQueryCustomerEntity();
  const profileData = useMemo(
    () => getProfileDataAccountType(queryCustomerEntity.data),
    [queryCustomerEntity.data]
  );

  return (
    <WSList gap="2XL">
      <FormAccountType
        accountTypes={["Individual", "Business"]}
        defaultValues={context.accountType || profileData}
        onSubmit={data => {
          setContext(prev => ({
            ...prev,
            accountType: data
          }));
          props.onNext?.();
        }}
        onChange={data => {
          setContext(prev => ({
            ...prev,
            accountType: data
          }));
        }}
        onBack={props.onBack}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};
