import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const getPayee = async (
  id: string,
  payerId?: string
): Promise<IPayeeResponse> => {
  const { data } = await servicePayments.get(`/payee/${id}`, {
    ...(payerId
      ? {
          headers: {
            "x-wingspan-user": payerId
          }
        }
      : {})
  });

  return data;
};
