import { useIsDesktop } from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Admin } from "../../Admin";
import { BenefitsRoutes } from "../../Benefits";
import { BookkeepingRoutes } from "../../Bookkeeping/index";
import { Data } from "../../Data";
import { Documents } from "../../Documents/screens/Documents";
import { Invoices } from "../../Invoices";
import { DebitCardsList } from "../../Invoices/screens/cards/DebitCardsList";
import { KnowledgeCenterRoot } from "../../KnowledgeCenter";
import { BeginEmailVerification } from "../../MemberOnboarding/screens/BeginEmailVerification";
import { CompleteEmailVerification } from "../../MemberOnboarding/screens/CompleteEmailVerification";
import { EmailMagicLink2FAInstructions } from "../../MemberOnboarding/screens/EmailMagicLink2FAInstructions";
import { EmailMagicLinkHandler } from "../../MemberOnboarding/screens/EmailMagicLinkHandler";
import { OnboardingEmailVerification } from "../../MemberOnboarding/screens/OnboardingEmailVerification";
import { OnboardingSetPassword } from "../../MemberOnboarding/screens/OnboardingSetPassword";
import { VerifyOTP } from "../../MemberOnboarding/screens/VerifyOTP";
import { VerifyPhoneNumber } from "../../MemberOnboarding/screens/VerifyPhoneNumber";
import { VerifyPhoneNumberExistingAccount } from "../../MemberOnboarding/screens/VerifyPhoneNumberExistingAccount";
import { OrganizationIndex } from "../../Organization";
import { PersonalPayLinkRoutes } from "../../PersonalPayLink";
import { AddNewToken } from "../../Settings/screens/AddNewToken";
import { EditNewToken } from "../../Settings/screens/EditNewToken";
import { MembershipBillingHistory } from "../../Settings/screens/MembershipBillingHistory";
import { MembershipPlansWithAddOns } from "../../Settings/screens/MembershipPlansWithAddOns";
import { MembershipTrialSuccess } from "../../Settings/screens/MembershipTrialSuccess";
import { NextGenSubscriptionPlans } from "../../Settings/screens/NextGenSubscriptionPlans";
import { SettingsMain as Settings } from "../../Settings/screens/SettingsMain";
import { AutopaySetup } from "../../SignUp/screens/AutopaySetup";
import { BulkImports } from "../../modules/BulkImporter/routes/BulkImports";
import { DashboardIndex } from "../../modules/Dashboard";
import { Onboarding } from "../../modules/Onboarding";
import { PayeesIndex } from "../../modules/Payees";
import { PayersIndex } from "../../modules/Payers";
import { ReportsIndex } from "../../modules/Reports";
import { StatementIndex } from "../../modules/Statements";
import { PayoutsIndex } from "../../modules/Payouts";
import { TaxDocuments } from "../../modules/TaxDocuments";
import { RouteActivateTaxWithholding } from "../../modules/Taxes/routes/RouteActivateTaxWithholding";
import { TaxesIndexRoute } from "../../modules/Taxes/routes/TaxesIndexRoute";
import { Transfer } from "../../modules/Transfer";
import { Wallet } from "../../modules/Wallet";
import { RouteActivateBanking } from "../../modules/Wallet/routes/RouteActivateBanking";
import { PaymentsSecurityScreen } from "../../screens/PaymentsSecurity";
import { ModalPayoutAccounts } from "../../shared/components/ModalPayoutAccounts";
import { useWSStore } from "../../store";
import { wsEvents } from "../../utils/WSEvents";
import { MemberPlaidModals } from "../../utils/usePlaidLink";
import { BaseAppLayout } from "../BaseAppLayout/BaseAppLayout.component";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { OptVerification } from "../OptVerification";
import DefaultErrorPage from "../RenderDefaultPageErrorMessage";
import { useInitMemberApp } from "./useInitMemberApp";
import { Tax1099Filing } from "../../modules/TaxFiling";
import { NEC1099Filing } from "../../modules/1099NECFiling";

export const MemberApp: React.FC = () => {
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const [initMemberApp, initMemberAppMeta] = useInitMemberApp();

  const store = useWSStore();
  useEffect(() => {
    initMemberApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listener = () => {
      // force logout
      history.replace("/logout", {
        forceLogout: true
      });
    };
    wsEvents.addListener(wsEvents.list.FORCE_LOGOUT, listener, {
      once: true
    });
    return () => {
      wsEvents.removeListener(wsEvents.list.FORCE_LOGOUT, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initMemberAppMeta.isLoading || initMemberAppMeta.isIdle) {
    return <LoadingPlaceholder />;
  } else if (initMemberAppMeta.isError) {
    return <DefaultErrorPage />;
  }

  return (
    <React.Fragment>
      <OptVerification />
      <MemberPlaidModals />
      <ModalPayoutAccounts />
      <Switch>
        {/* Fullscreen page routes */}

        <Route path="/member/onboarding" component={Onboarding} />

        <Route
          path="/member/activate-banking"
          component={RouteActivateBanking}
        />

        <Route
          path="/member/activate-tax-withholding"
          component={RouteActivateTaxWithholding}
        />

        <Route
          path="/member/authorize/2fa/email/confirm"
          exact
          component={EmailMagicLinkHandler}
        />
        <Route
          path="/member/authorize/2fa/email"
          component={EmailMagicLink2FAInstructions}
        />
        <Route
          path="/member/authorize/verification/email/confirm"
          component={CompleteEmailVerification}
        />
        <Route path="/member/verify/email" component={BeginEmailVerification} />
        <Route
          path="/member/verify/phone-number"
          exact
          component={VerifyPhoneNumber}
        />

        <Route
          path="/member/verify/phone-number/existing-account"
          exact
          component={VerifyPhoneNumberExistingAccount}
        />

        <Route path="/member/verify/phone-number/otp" component={VerifyOTP} />

        <Route
          path="/member/onboarding/verify-email"
          exact
          component={OnboardingEmailVerification}
        />

        <Route
          path="/member/onboarding/set-password"
          exact
          component={OnboardingSetPassword}
        />

        <Route
          path={
            store.signUpAutopayDetails?.memberClientId
              ? "/member/autopay/:collaboratorId"
              : "/member/autopay/:payeeId"
          }
          component={AutopaySetup}
        />

        <Route
          path="/member/:partner/subscription-plans/"
          component={NextGenSubscriptionPlans}
        />
        <Route
          path="/member/membership-plans"
          component={MembershipPlansWithAddOns}
        />
        <Route
          path="/member/subscription/trial-success"
          component={MembershipTrialSuccess}
        />
        {!isDesktop && (
          <Route
            key="add-new-token"
            exact
            path="/member/settings/tokens/new"
            component={AddNewToken}
          />
        )}
        {!isDesktop && (
          <Route
            key="edit-api-token"
            exact
            path={`/member/settings/tokens/edit/:sessionId`}
            component={EditNewToken}
          />
        )}

        <Route
          path="*"
          children={
            <BaseAppLayout>
              <Switch>
                <Route path="/member/statements" component={StatementIndex} />
                <Route
                  path="/member/invoices/payouts"
                  component={PayoutsIndex}
                />

                {/* Nav page routes */}
                <Route path="/member/dashboard" component={DashboardIndex} />

                <Route path="/member/taxes" component={TaxesIndexRoute} />
                <Route path="/member/tax-documents" component={TaxDocuments} />
                <Route path="/member/invoices" component={Invoices} />
                <Route
                  path={["/member/imports", "/member/data/batch-imports"]}
                  component={BulkImports}
                />
                <Route path="/member/data" component={Data} />
                <Route path="/member/1099-filing" component={NEC1099Filing} />
                <Route path="/member/tax-filing" component={Tax1099Filing} />
                <Route path="/member/payees" component={PayeesIndex} />
                <Route path="/member/payers" component={PayersIndex} />
                <Route path="/member/benefits" component={BenefitsRoutes} />

                <Route path="/member/wallet" component={Wallet} />
                <Route
                  path="/member/organization"
                  component={OrganizationIndex}
                />
                <Route
                  path="/member/bookkeeping"
                  component={BookkeepingRoutes}
                />
                <Route path="/member/documents" exact component={Documents} />
                <Route
                  path="/member/knowledge-center"
                  component={KnowledgeCenterRoot}
                />
                <Route path="/member/settings" component={Settings} />
                <Route
                  path="/member/personal-pay-link"
                  component={PersonalPayLinkRoutes}
                />
                <Route
                  path="/member/membership-billing-history"
                  component={MembershipBillingHistory}
                />
                <Route
                  path="/member/banking/cards"
                  component={DebitCardsList}
                />
                <Route
                  path="/member/payments-security"
                  component={PaymentsSecurityScreen}
                />
                <Route path="/member/reports" component={ReportsIndex} />
                <Route path="/member/transfer" component={Transfer} />

                <Route path="/member/admin" component={Admin} />
                <Redirect from="*" to="/member/dashboard" />
              </Switch>
            </BaseAppLayout>
          }
        />
      </Switch>
    </React.Fragment>
  );
};
