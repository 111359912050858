import { IAuthorizedAccount } from "@wingspanhq/users";
import { selectorAccessibleMemberEmail } from "./selectorAccessibleMemberEmail";

export const selectorAuthorizedAccountByEmail = (
  accounts: IAuthorizedAccount[],
  email: string
) => {
  return accounts.find(
    account => selectorAccessibleMemberEmail(account) === email
  );
};
