import {
  useIsMobile,
  WSActions,
  WSCard,
  WSElement,
  WSFlexBox,
  WSForm,
  WSInputText,
  WSList,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { FlowMEChangeContext } from "..";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { validatorName } from "../../../../../shared/validators/validatorName";
import { ReviewOriginalIndividualName } from "../../../components/ReviewOriginalIndividualName";
import { OnboardingStep } from "../../../types";

export type FormDataIndividualLegalName = {
  firstName: string;
  middleName: string;
  lastName: string;
};

export const StepImmaterialUpdateIndividualLegalName: React.FC<
  OnboardingStep
> = props => {
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();
  const isMobile = useIsMobile();

  return (
    <WSForm<FormDataIndividualLegalName>
      defaultValues={context.individualLegalName}
      validationSchema={Yup.object().shape({
        firstName: validatorName.required("Required"),
        middleName: validatorName,
        lastName: validatorName.required("Required")
      })}
      onSubmit={data => {
        setContext(prev => ({ ...prev, individualLegalName: data }));
        props.onNext?.();
      }}
    >
      <WSList gap="2XL">
        <WSElement>
          <WSSectionToolbar title="Update taxpayer information" />
          <WSText.ParagraphSm mt="L" color="gray500">
            Please review to ensure the information is accurate before
            submitting
          </WSText.ParagraphSm>
        </WSElement>

        <ReviewOriginalIndividualName />

        <WSCard
          header={{
            label: "Legal name change",
            value: {
              pill: {
                theme: "warning",
                text: "Requested update"
              }
            }
          }}
          divider
        >
          <WSFlexBox
            direction={isMobile ? "column" : "row"}
            wrap="nowrap"
            gap="L"
          >
            <WSForm.Field
              name="firstName"
              label="Legal first name"
              component={WSInputText}
              componentProps={{
                required: true,
                placeholder: "Enter first name",
                fsExclude: true,
                autoComplete: "given-name"
              }}
            />

            <WSForm.Field
              name="middleName"
              label="Middle name"
              component={WSInputText}
              componentProps={{
                placeholder: "Enter middle name",
                fsExclude: true,
                autoComplete: "additional-name"
              }}
            />

            <WSForm.Field
              name="lastName"
              label="Legal last name"
              component={WSInputText}
              componentProps={{
                required: true,
                placeholder: "Enter last name",
                fsExclude: true,
                autoComplete: "family-name"
              }}
            />
          </WSFlexBox>
        </WSCard>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              onClick: props.onBack,
              kind: "Secondary",
              type: "button",
              visible: !!props.onBack
            },
            {
              label: "Continue"
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
