import {
  WSElement,
  WSText,
  WSFormOld,
  WSSelectOld,
  WSTextArea
} from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../query/hooks/helpers";
import { useMemberProfile } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { FormPartialIdentificationNumber } from "../../../../shared/components/FormPartialIdentificationNumber";
import { Tooltip } from "../../../../components/Tooltip";
import {
  correctionReasonMap,
  CORRECTION_REASON_OPTIONS
} from "../../constants/corrections";
import {
  TINOrNameCorrectionDiffPreview,
  TinData
} from "../TINOrNameCorrectionDiffPreview";
import {
  IPayeeTaxFormResponse,
  IPayerTaxFormResponse,
  PayeeCorrectionReason,
  TinType
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormViewer } from "../../types";

export interface TINOrNameCorrectionFormProps {
  taxForm: IPayerTaxFormResponse | IPayeeTaxFormResponse;
  originalTinData: TinData;
  taxFormViewer?: TaxFormViewer;
  showRequestedValue?: boolean;

  correctedTinData?: TinData;
  reasonForChange?: string;
  otherReason?: string;
  identificationNumberType?: TinType;
}

export const TINOrNameCorrectionForm: React.FC<
  TINOrNameCorrectionFormProps
> = ({
  taxForm,
  originalTinData,
  taxFormViewer = TaxFormViewer.Payee,
  showRequestedValue,
  correctedTinData,
  reasonForChange,
  otherReason,
  identificationNumberType
}) => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);

  return (
    <WSQueries queries={{ queryMember }}>
      {({ queryMemberData: member }) => (
        <>
          <TINOrNameCorrectionDiffPreview
            taxForm={taxForm}
            originalTinData={originalTinData}
            correctedTinData={correctedTinData!}
            reasonForChange={reasonForChange!}
            identificationNumberType={identificationNumberType!}
            otherReason={otherReason}
            showRequestedValue={showRequestedValue}
          />

          <FormPartialIdentificationNumber
            name="identificationNumberType"
            ssnProvided={!!member.profile.ssnProvided}
            isTaxFormCorrection={true}
            taxFormViewer={taxFormViewer}
            mb="L"
          />

          {taxFormViewer === TaxFormViewer.Payer ? null : (
            <>
              <WSElement mb="2XL">
                <WSText.Heading4 mb="XS">
                  Why are you requesting a change?{" "}
                  <Tooltip placement="right">
                    <WSText weight="medium" color="gray100" mb="M">
                      Reasons for change
                    </WSText>
                    <WSText.ParagraphSm as="ol" color="gray100">
                      {Object.keys(correctionReasonMap).map((reason, index) => (
                        <WSText.ParagraphSm as="li" mb="XS" color="gray100">
                          <WSText.ParagraphSm
                            weight="medium"
                            color="gray100"
                            inline={reason !== PayeeCorrectionReason.Other}
                          >
                            {`${index + 1}. ${reason}:`}
                          </WSText.ParagraphSm>{" "}
                          {correctionReasonMap[reason as PayeeCorrectionReason]}
                        </WSText.ParagraphSm>
                      ))}
                    </WSText.ParagraphSm>
                  </Tooltip>
                </WSText.Heading4>
                <WSFormOld.Field
                  name="reasonForChange"
                  component={WSSelectOld}
                  componentProps={{
                    placeholder: "Select reason",
                    options: CORRECTION_REASON_OPTIONS
                  }}
                />
              </WSElement>
              <WSFormOld.Value name="reasonForChange">
                {reasonForChange => (
                  <WSElement
                    mb="2XL"
                    hidden={reasonForChange !== PayeeCorrectionReason.Other}
                  >
                    <WSText.Heading4 mb="XS">
                      Please describe the reason
                    </WSText.Heading4>
                    <WSFormOld.Field
                      name="otherReason"
                      component={WSTextArea}
                      componentProps={{
                        placeholder: `You selected "other". Please describe the reason for changing the federal tax classification`
                      }}
                    />
                  </WSElement>
                )}
              </WSFormOld.Value>
            </>
          )}
        </>
      )}
    </WSQueries>
  );
};
