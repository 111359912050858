import {
  IPayerResponse,
  PayeeTaxDocumentSharePermission,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import { operations } from "../../../services/api/onboarding/types";
import { CertifyTaxPayerInfo } from "../steps/CertifyTaxPayerInfo";
import { Tax1099Consent } from "../steps/CertifyTaxPayerInfo/Tax1099Consent";
import {
  OnboardingContext,
  OnboardingModuleCertifyTaxPayerInfo,
  OnboardingStep
} from "../types";

const getStatus = (
  payers?: IPayerResponse[],
  electronicTaxFormConsent?: operations["getAcknowledgement"]["responses"]["200"]["content"]["application/json"]
) => {
  const activePayers = payers?.filter(
    payer => payer.payeeOwnedData.status === PayerPayeeStatus.Active
  );

  const isTaxInfoSharedToPayers = activePayers?.every(
    payer =>
      payer.payeeOwnedData.shareTaxDocument ===
      PayeeTaxDocumentSharePermission.Allow
  );

  const isElectronicTaxFormConsentSet =
    !!electronicTaxFormConsent?.acknowledgementStatus;

  if (isTaxInfoSharedToPayers && isElectronicTaxFormConsentSet) {
    return "Complete";
  }

  return "None";
};

export const getCertifyTaxPayerInfoStepConfig = (
  _: OnboardingModuleCertifyTaxPayerInfo,
  __: OnboardingContext,
  payers: IPayerResponse[] = [],
  electronicTaxFormConsent?: operations["getAcknowledgement"]["responses"]["200"]["content"]["application/json"]
): OnboardingStep => {
  const slug = "taxpayer-info";

  const steps: OnboardingStep[] = [
    {
      title: "Certify taxpayer info",
      slug: "certify",
      component: CertifyTaxPayerInfo
    },
    {
      title: "Consent to e-delivery",
      slug: "e-delivery",
      component: Tax1099Consent
    }
  ];

  const status = getStatus(payers, electronicTaxFormConsent);

  return {
    title: "Certify taxpayer info",
    slug,
    steps,

    status,
    result: {
      Complete: {
        title: "You certified your taxpayer information",
        description:
          "You certified your taxpayer information is accurate. Click continue for the next step."
      }
    }
  };
};
