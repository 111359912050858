import {
  WSButton,
  WSButtonProps,
  WSCard,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSGrid,
  WSHorizontalScrollCards,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { openInNewTab } from "../../../utils/openInNewTab";

import templates from "../../templates.json";

interface RecommendedTemplatesProps extends WSElementProps {}

interface TemplateCardProps extends WSElementProps {
  title: string;
  link: string;
  type?: string;
  footer?(link: string): React.ReactNode;
}
export const FileCard: React.FC<TemplateCardProps> = ({
  title,
  link,
  type = "Google Doc"
}) => {
  const actionConfig: WSButtonProps<"Link"> = ["Google Doc", "Form"].includes(
    type
  )
    ? {
        label: "View",
        rightIcon: "open-tab",
        onClick: () => openInNewTab(link)
      }
    : {
        label: "Download",
        icon: "download",
        onClick: () => openInNewTab(link)
      };

  return (
    <WSCard
      header={{
        label: title,
        value: {
          action: actionConfig
        }
      }}
    >
      <WSText.ParagraphSm color="gray600">{type}</WSText.ParagraphSm>
    </WSCard>
  );
};

export const RecommendedTemplates: React.FC<
  RecommendedTemplatesProps
> = props => {
  const history = useHistory();
  const featureFlagsQuery = useFeatureFlags();
  const onClickViewAll = () => {
    history.push("/member/knowledge-center");
  };

  const recommendedTemplates = templates.slice(0, 4);
  return (
    <>
      <WSScreen.Desktop>
        <WSFlexBox mb="XL" justify="space-between">
          <WSText.Heading5>Recommended templates</WSText.Heading5>
          <WSButton kind="Link" onClick={onClickViewAll}>
            View all
          </WSButton>
        </WSFlexBox>
        <WSElement mb="2XL">
          <WSGrid>
            {recommendedTemplates.map(template => (
              <WSGrid.Item span={{ m: "3" }}>
                <FileCard {...template} />
              </WSGrid.Item>
            ))}
          </WSGrid>
        </WSElement>
      </WSScreen.Desktop>
      <WSScreen.MobileAndTablet>
        <WSText.Heading5 mt="2XL">Recommended templates</WSText.Heading5>
        <WSHorizontalScrollCards
          mb="XL"
          endCard={{
            label: "View all",
            onClick: onClickViewAll
          }}
          py="M"
        >
          {recommendedTemplates.map(template => (
            <FileCard {...template} />
          ))}
        </WSHorizontalScrollCards>
      </WSScreen.MobileAndTablet>
    </>
  );
};
