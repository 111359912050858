import {
  useIsMobile,
  useModalContext,
  WSElement,
  WSIcon,
  WSPage
} from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";

import { Tabs } from "../../../../components/Tabs";
import { WSQueries } from "../../../../query/WSQuery";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";

import styles from "./styles.module.scss";
import { BulkImportPayables } from "../BulkImportPayables";
import { BulkImportCollaborators } from "../BulkImportCollaborators";
import {
  BULK_IMPORT_PAYABLES_AND_DEDUCTIONS_MODAL,
  BulkImportPayablesAndDeductionsModal
} from "./ImportInfoModals";
import { BulkResource } from "../../types";
import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";

export function BulkImports() {
  const qFeatureFlags = useFeatureFlags();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { openModal } = useModalContext();
  const [currentBulkImportResource, setCurrentBulkImportResource] = useState(
    BulkResource.Payable
  );

  const { hasPaymentsScope, hasCollaboratorsScope } =
    useAuthorizedScopeGroups();

  useEffect(() => {
    if (location.pathname.includes("/member/imports/collaborators")) {
      setCurrentBulkImportResource(BulkResource.Collaborator);
    } else if (location.pathname.includes("/member/imports/payables")) {
      setCurrentBulkImportResource(BulkResource.Payable);
    }
  }, [location.pathname]);

  let bulkImportResourceProps: any = {
    infoModalName: BULK_IMPORT_PAYABLES_AND_DEDUCTIONS_MODAL,
    primaryButtonProps: {
      name: "importPayablesAndDeductions",
      label: "Import payables & deductions",
      onClick: () => {
        history.push("/member/invoices/payables/bulk-upload/info");
      }
    },
    headerContent: (
      <WSIcon
        block
        name="info-circle"
        color="gray400"
        size="XS"
        onClick={() => openModal(BULK_IMPORT_PAYABLES_AND_DEDUCTIONS_MODAL)}
      />
    )
  };
  if (currentBulkImportResource === BulkResource.Collaborator) {
    bulkImportResourceProps = {
      primaryButtonProps: {
        name: "importCollaborators",
        label: "Import contractors",
        onClick: () => {
          history.push(
            "/member/invoices/contacts/collaborators/bulk-create/info"
          );
        }
      }
    };
  }

  // TODO

  const { primaryButtonProps, ...others } = bulkImportResourceProps;
  return (
    <WSPage title="Importer" primaryButton={primaryButtonProps} {...others}>
      <BulkImportPayablesAndDeductionsModal />
      <Tabs
        tabs={[
          ...(hasPaymentsScope
            ? [
                {
                  path: "/member/imports/payables",
                  label: "Payables & deductions"
                }
              ]
            : []),
          ...(hasCollaboratorsScope &&
          qFeatureFlags.data?.bulkImporterCollaborator
            ? [
                {
                  path: "/member/imports/collaborators",
                  label: "Contractors"
                }
              ]
            : [])
        ]}
      />
      <WSElement mt={isMobile ? "XL" : "3XL"} className={styles.wrapper}>
        <WSQueries queries={{ qFeatureFlags }}>
          {({ qFeatureFlags: { data: featureFlags } }) => (
            <Switch>
              {hasPaymentsScope ? (
                <Route
                  path="/member/imports/payables"
                  component={BulkImportPayables}
                />
              ) : null}
              {hasCollaboratorsScope &&
              featureFlags.bulkImporterCollaborator ? (
                <Route
                  path="/member/imports/collaborators"
                  component={BulkImportCollaborators}
                />
              ) : null}
              <Redirect from="*" to="/member/imports/payables" />
            </Switch>
          )}
        </WSQueries>
      </WSElement>
    </WSPage>
  );
}
