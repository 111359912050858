export const themeConfig = {
  // reference https://flatfile.com/docs/guides/theming#font
  theme: {
    root: {
      primaryColor: "#0284c7",
      textColor: "white"
    },
    sidebar: {
      logo: "https://my.wingspan.app/static/media/ws-logo.16a504445c5bd444cacdad1deb69677f.svg"
    }
  }
};
