import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { useHistory, useRouteMatch } from "react-router-dom";

import { CURRENT_YEAR } from "../../../1099NECFiling/constants/currentYear";
import { EditLayout } from "../../../1099NECFiling/components/EditLayout";

import { SelectCorrectionTypeForm } from "../../components/SelectCorrectionTypeForm";

import { SetupRequestCorrectionFlowProps } from "./index";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { selectorPayeeTaxFormPayerName } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormPayerName";
import { TAX_DOCUMENTS_PATH } from "../../index";
import { PreventLeave } from "../../../../components/PreventLeave/PreventLeave";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";

interface SelectCorrectionTypeFormData {
  corrections: string[];
}

export const RouteSelectCorrectionType: React.FC<
  SetupRequestCorrectionFlowProps
> = ({ onNext }) => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayeeTaxForm(taxFormId);

  const onFormSubmit = (data: SelectCorrectionTypeFormData) => {
    onNext(data.corrections);
  };

  const onCancel = () => {
    history.push(`${TAX_DOCUMENTS_PATH}/${taxFormId}`);
  };

  return (
    <EditLayout title="Request correction">
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => (
          <>
            <WSText.Heading4 mb="2XL">
              {selectorPayeeTaxFormPayerName(taxForm)} {CURRENT_YEAR} Form
              1099-NEC
            </WSText.Heading4>
            <SelectCorrectionTypeForm
              onCancel={onCancel}
              onContinue={onFormSubmit}
            />
            <WSText.ParagraphSm mb="2XL" color="gray400">
              After you submit a correction request, your payer will be notified
              of your request. Ultimately it is your payers discretion as to
              whether they accept the corrections. You will be notified when
              they respond to your request. If they accept your request, a
              correction will be delivered to you depending on your delivery
              preferences.
            </WSText.ParagraphSm>

            <WSButton.Link
              rightIcon="chevron-right"
              onClick={() =>
                openInNewTab(
                  "https://help.wingspan.app/en/articles/8892926-requesting-corrections-for-unsupported-1099-nec-error-types"
                )
              }
            >
              There is another issue with my Form 1099
            </WSButton.Link>
          </>
        )}
      </WSQueries>
    </EditLayout>
  );
};
