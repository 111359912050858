import {
  useModalContext,
  WSPill,
  WSButtons,
  WSInputDateOld,
  WSFlexBox,
  WSFormOld,
  WSInputNumberOld,
  WSModal,
  WSSearch,
  WSSearchProps,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import {
  ICollaboratorSchema,
  MemberClientStatus
} from "@wingspanhq/payments/dist/interfaces";
import { DeductionType } from "@wingspanhq/payments/dist/interfaces/deductions";
import { Currency } from "@wingspanhq/users/dist/lib/interfaces";
import { sortBy } from "lodash";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../query/hooks/helpers";
import { useCreateCollaboratorDeduction } from "../../../../query/payments/mutations";
import { useCollaboratorsQuery } from "../../../../query/payments/queries";
import { WSQueries } from "../../../../query/WSQuery";

type Props = {};

export const ADD_PAYABLE_DEDUCTION_MODAL = "ADD_PAYABLE_DEDUCTION_MODAL";

export const Inner: React.FC<Props> = (props, context) => {
  const { closeModal } = useModalContext();

  const qCollaborators = useCollaboratorsQuery();
  const clientId = useUserId();

  const [createDeduction, createDeductionMeta] =
    useCreateCollaboratorDeduction();

  return (
    <WSQueries queries={{ qCollaborators }}>
      {({ qCollaborators }) => {
        const filteredCollaborators = qCollaborators.data.filter(
          c => c.status !== MemberClientStatus.Inactive
        );

        const recentCollaborators = sortBy(filteredCollaborators, "updatedAt")
          .reverse()
          .slice(0, 3);

        return (
          <WSFormOld<{
            name: string;
            amount: number;
            startDate: Date;
            collaboratorEmail: string;
          }>
            defaultValues={{
              startDate: new Date()
            }}
            validationSchema={Yup.object().shape({
              collaboratorEmail: Yup.string().required(
                "Contractor is required"
              ),
              name: Yup.string().required("Name is required"),
              amount: Yup.number().required("Amount is required"),
              startDate: Yup.date().required("Start date is required")
            })}
            onSubmit={async formData => {
              const collaborator = qCollaborators.data.find(
                c => c.member.user.email === formData.collaboratorEmail
              );

              await createDeduction(
                {
                  name: formData.name,
                  type: DeductionType.PrePayment,
                  amount: formData.amount,
                  startDate: formData.startDate,
                  clientId,
                  currency: Currency.USD,
                  memberId: collaborator?.memberId as string
                },
                {
                  onSuccess() {
                    closeModal(ADD_PAYABLE_DEDUCTION_MODAL);
                  }
                }
              );
            }}
          >
            <WSFormOld.Field<WSSearchProps<ICollaboratorSchema>>
              mb="XL"
              name="collaboratorEmail"
              label="Contractor"
              component={WSSearch}
              componentProps={{
                placeholder: "Search by email, name, id..",
                threshold: 0.8,
                itemToString: item =>
                  [
                    item?.data.member.user.profile?.firstName,
                    item?.data.member.user.profile?.lastName
                  ]
                    .filter(Boolean)
                    .join(" ")
                    .trim() ||
                  item?.data.member.user.email ||
                  "",
                defaultItems: recentCollaborators.map(collaborator => ({
                  value: collaborator.member.user.email || "",
                  searchText: collaborator.collaboratorId,
                  data: collaborator
                })),
                emptyResults: () => (
                  <WSFlexBox.Center p="L" direction="column">
                    <WSText.ParagraphSm color="gray400">
                      No Results
                    </WSText.ParagraphSm>
                  </WSFlexBox.Center>
                ),
                items: filteredCollaborators.map(collaborator => ({
                  value: collaborator.member.user.email || "",
                  searchText: [
                    collaborator.member.user.profile?.firstName,
                    collaborator.member.user.profile?.lastName,
                    collaborator.member.user.email,
                    collaborator.member.profile?.company?.name,
                    collaborator.collaboratorId,
                    collaborator.labels.contractorId
                  ]
                    .filter(Boolean)
                    .join(" "),
                  data: collaborator
                })),
                getTitle: collaborator =>
                  [
                    collaborator.member.user.profile?.firstName,
                    collaborator.member.user.profile?.lastName
                  ]
                    .filter(Boolean)
                    .join(" "),
                getDetails: collaborator => `${collaborator.member.user.email}`,
                getSideDetails: collaborator =>
                  collaborator.member.profile?.company?.name ? (
                    <WSPill
                      theme="blue"
                      text={collaborator.member.profile?.company?.name}
                    />
                  ) : null
              }}
            />
            <WSFormOld.Field
              mb="XL"
              label="Deduction name"
              name="name"
              component={WSTextInput}
            />
            <WSFormOld.Field
              mb="XL"
              label="Deduction amount"
              name="amount"
              component={WSInputNumberOld}
              componentProps={{
                mode: "currency",
                currency: "USD",
                maxFractionDigits: 2
              }}
            />
            <WSFormOld.Field
              mb="XL"
              label="Start date"
              name="startDate"
              component={WSInputDateOld}
            />

            <WSErrorMessage
              mt="XL"
              error={createDeductionMeta.error}
              contextKey="Other"
            />

            <WSText mt="2XL" color="gray500">
              Scheduled deductions are completed when you pay a contractor. If a
              deduction is larger than a payable (or your specified percentage
              of the payable) the remaining balance will be deducted from the
              next payable.
            </WSText>

            <WSButtons mt="3XL" forceFullWidth>
              <WSFormOld.SubmitButton data-testid="submit">
                Add new deduction
              </WSFormOld.SubmitButton>
            </WSButtons>
          </WSFormOld>
        );
      }}
    </WSQueries>
  );
};

export const AddPayableDeduction: React.FC = () => {
  return (
    <WSModal
      name={ADD_PAYABLE_DEDUCTION_MODAL}
      size="S"
      title="New pre-payment deduction"
    >
      <Inner />
    </WSModal>
  );
};
