import flatten from "lodash/flatten";
import { QueryConfig } from "react-query";

import {
  getPayerRows,
  getPayerRowsSummary,
  IPayerRow,
  PayerRowsQuery
} from "../../../../services/search/payer";
import {
  concurrentActions,
  WSServiceError
} from "../../../../utils/serviceHelper";
import { useWSQuery } from "../../../helpers";
import times from "lodash/times";

export const QUERY_PAYER_ROWS_ALL = "QUERY_PAYER_ROWS_ALL";

export const useQueryPayerRowsAll = (
  requestQuery?: PayerRowsQuery,
  config?: QueryConfig<IPayerRow[], WSServiceError>
) => {
  return useWSQuery<IPayerRow[], WSServiceError>(
    [QUERY_PAYER_ROWS_ALL, requestQuery],
    async () => {
      const pageSize = 100;
      const { listSize } = await getPayerRowsSummary(requestQuery);
      const pages = Math.ceil(listSize / pageSize);

      const actions = times(pages).map(
        (_, i) => () =>
          getPayerRows({
            ...requestQuery,
            page: { size: pageSize, number: i + 1 }
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      const payers = flatten(allPages);

      return payers;
    },
    {
      ...config
    }
  );
};
