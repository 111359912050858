import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSAlert,
  WSElement,
  WSFlexBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeEngagementResponse,
  IPayeeResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdatePayeeEngagement } from "../../../query/payeeEngagements/mutations/useUpdatePayeeEngagement";
import { FieldView } from "../../../shared/components/FieldView/FieldView";
import React from "react";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";
import { ArchiveEngagementForContractorModal } from "./ArchiveEngagementForContractorModal";

export interface ArchiveEngagementModalProps {
  payee: IPayeeResponse;
  engagement: IPayeeEngagementResponse;
  onClose: () => void;
  nonDefaultEngagements: IPayeeEngagementResponse[];
}

export const ArchiveEngagementModal: React.FC<ArchiveEngagementModalProps> = ({
  payee,
  engagement,
  onClose
}) => {
  const history = useHistory();

  const { openSnackbar } = useWSSnackbar();
  const [updateEngagement, updateEngagementMeta] = useUpdatePayeeEngagement();

  return (
    <>
      <WSText.ParagraphSm mb="L" weight="book">
        Are you sure you want to archive this engagement for the contractor?
      </WSText.ParagraphSm>
      <WSPanel>
        <WSFlexBox gap="XL">
          <FieldView label="Engagement" value={engagement.engagementName} />
          {/*
            <FieldView
              label="Type"
              value={getEngagementLabelByType(engagement.type, {
                descriptive: true
              })}
            />
          */}
        </WSFlexBox>
        {/* <FieldView
          mt="XL"
          label="Description"
          value={"TODO:description"}
        /> */}
      </WSPanel>

      <WSAlert
        my="2XL"
        theme="info"
        icon="info-circle"
        size="M"
        title="Archive engagement for contractor"
      >
        After the engagement is archived for a contractor, the contractor is
        made archived within the engagement. No additional invoices for this
        contractor can be created for this engagement. The engagement will
        remain for other contractors assigned to the engagement.
        <WSElement mt="M" />
        Existing invoices will remain on the Invoicing page until you delete
        them. You can always restore the engagement for the contractor when
        needed.
      </WSAlert>

      <WSErrorMessage
        error={updateEngagementMeta.error}
        mb="XL"
        contextKey="ArchiveClient"
      />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          },
          {
            label: "Archive engagement for contractor",
            loading: updateEngagementMeta.isLoading,
            onAsyncClick: async () => {
              await updateEngagement(
                {
                  payeeId: payee.payeeId,
                  engagementId: engagement.payerPayeeEngagementId,
                  status: PayerPayeeEngagementStatus.Inactive
                },
                {
                  onSuccess: () => {
                    onClose();
                  }
                }
              );
            }
          }
        ]}
      />
    </>
  );
};

export const useArchiveEngagementModal = () => {
  const queryFeatureFlags = useFeatureFlags();
  const isFlagEnabled =
    queryFeatureFlags.data?.allowDefaultEngagementPaymentsFallback;

  return useWSModal(
    isFlagEnabled
      ? ArchiveEngagementForContractorModal
      : ArchiveEngagementModal,
    {
      title: "Archive engagement for contractor",
      size: "M"
    }
  );
};
