import { useHistory, useRouteMatch } from "react-router-dom";
import React, { ReactNode } from "react";
import {
  useBulkCollaboratorBatch,
  useBulkCollaboratorBatchSummary
} from "../../../BulkImporter/query/bulkCollaborator/queries";
import { WSQueries } from "../../../../query/WSQuery";
import {
  IconListItem,
  WSButton,
  WSIconList,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { useUpdateBulkCollaboratorBatch } from "../../../BulkImporter/query/bulkCollaborator/mutations";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { pluralize } from "../../../../shared/utils/pluralize";
import { formatNumber } from "accounting";
import { useCollaboratorGroupQuery } from "../../../../query/payments/queries";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { PayerSetupLayout } from "../../components/PayerSetupLayout";

export const recipientPluralizeOptions = {
  one: "recipient",
  other: "recipients"
};

export const RouteRecipientsImportPreview: React.FC<
  SetupRouteComponentProps & {
    basePath?: string;
    Layout?: React.FC<{
      title: string;
      children: ReactNode;
      onBack?: () => void;
    }>;
  }
> = ({
  onNext,
  Layout,
  basePath = "/member/1099-filing/set-up/recipients-import"
}) => {
  const history = useHistory();
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const qCollaboratorBulkBatch = useBulkCollaboratorBatch(bulkBatchId);
  const qCollaboratorBatchSummary =
    useBulkCollaboratorBatchSummary(bulkBatchId);
  const collaboratorGroupId = qCollaboratorBulkBatch.data?.labels
    .collaboratorGroupId as string;

  const qCollaboratorGroup = useCollaboratorGroupQuery(
    collaboratorGroupId as string,
    {
      enabled: !!collaboratorGroupId
    }
  );

  const [updateBulkCollaboratorBatch, updateBulkCollaboratorBatchMeta] =
    useUpdateBulkCollaboratorBatch();

  const startImport = async () => {
    await updateBulkCollaboratorBatch(
      {
        id: bulkBatchId,
        data: {
          status: BulkStatus.Pending
        }
      },
      {
        onSuccess: () => {
          onNext({
            bulkBatchId
          });
        }
      }
    );
  };

  const LayoutComponent = Layout || PayerSetupLayout;

  return (
    <LayoutComponent title="Add recipients">
      <WSQueries
        queries={{
          qCollaboratorBulkBatch,
          qCollaboratorBatchSummary
        }}
      >
        {({
          qCollaboratorBulkBatchData: collaboratorBulkBatch,
          qCollaboratorBatchSummaryData: collaboratorBatchSummary
        }) => {
          const selectedCollaboratorGroup = qCollaboratorGroup.data;
          const isBatchProcessed = [
            BulkStatus.Complete,
            BulkStatus.Failed
          ].includes(collaboratorBulkBatch.status);
          return (
            <>
              <WSText.Heading4 mb="XL">
                Batch upload recipients with a spreadsheet
              </WSText.Heading4>
              <WSText weight="medium" mb="XL">
                Import preview
              </WSText>

              <WSIconList
                mb="2XL"
                items={[
                  {
                    description: `${pluralize(
                      collaboratorBatchSummary.collaboratorsCount,
                      recipientPluralizeOptions
                    )} will be imported.`,
                    icon: {
                      name: "user",
                      color: "blue500",
                      size: "M"
                    }
                  },
                  ...(selectedCollaboratorGroup
                    ? [
                        {
                          description: `${formatNumber(
                            collaboratorBatchSummary.collaboratorsCount
                          )} new ${pluralize(
                            collaboratorBatchSummary.collaboratorsCount,
                            recipientPluralizeOptions,
                            { hideCount: true }
                          )} will be ${
                            selectedCollaboratorGroup
                              ? `created and added to ${selectedCollaboratorGroup.name}`
                              : "created."
                          }`,
                          icon: {
                            name: "edit",
                            color: "blue500",
                            size: "M"
                          }
                        } as IconListItem
                      ]
                    : [])
                ]}
              />

              {isBatchProcessed ? (
                <WSMessageBox.Regular mb="XL">
                  Recipients have already been imported for this batch.{" "}
                  <WSButton.Link
                    onClick={() => {
                      history.push(basePath + `/${bulkBatchId}/upload`);
                    }}
                  >
                    See status here.
                  </WSButton.Link>
                </WSMessageBox.Regular>
              ) : null}
              {collaboratorBulkBatch.status === BulkStatus.Processing ? (
                <WSMessageBox.Regular mb="XL">
                  Recipients are being imported. This may take a few minutes.{" "}
                  <WSButton.Link
                    onClick={() => {
                      history.push(basePath + `/${bulkBatchId}/upload`);
                    }}
                  >
                    See progress here.
                  </WSButton.Link>
                </WSMessageBox.Regular>
              ) : null}
              <WSButton
                onClick={startImport}
                fullWidth
                name="recipientsBatchImport"
                loading={updateBulkCollaboratorBatchMeta.isLoading}
                disabled={isBatchProcessed}
              >
                Import recipients
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </LayoutComponent>
  );
};
