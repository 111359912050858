import {
  WSActions,
  WSForm,
  WSInfoBox,
  WSInputText,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSSelect
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { COUNTRY_OPTIONS } from "../../../constants/user";
import { useQueryAccountRequirements } from "../../../query/users/queries";
import { CURRENCY_OPTIONS } from "../../constants/currency";
import { FormData } from "./types";
import { getValidationSchema } from "./validationSchema";
import { AccountBeneficiaryVerificationAlert } from "./AccountBeneficiaryVerificationAlert";
import { IBeneficiaryVerificationResponse } from "@wingspanhq/payments/dist/interfaces";
import { useVerifyAccountBeneficiary } from "../../../query/accounts/queries/useAccountBeneficiaryVerification";
import { useFeatureFlags } from "../../../query/hooks/useFeatureFlags";

type Props = {
  onSubmit: (data: FormData) => void;
  error?: any;
  isLoading?: boolean;
  defaultValues?: Partial<FormData>;
  onBack?: () => void;
  withPanel?: boolean;
};

export const Form: React.FC<Props> = ({
  onSubmit,
  error,
  isLoading,
  defaultValues,
  onBack,
  withPanel
}) => {
  const featureFlags = useFeatureFlags();
  const [country, setCountry] = useState<string>(defaultValues?.country || "");
  const [currency, setCurrency] = useState<string>(
    defaultValues?.currency || ""
  );
  const [verificationIssue, setVerificationIssue] =
    useState<IBeneficiaryVerificationResponse>();

  const [verifyAccount, verifyAccountMeta] = useVerifyAccountBeneficiary();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const queryAccountRequirements = useQueryAccountRequirements(
    {
      country,
      currency
    },
    {
      enabled: !!country && !!currency
    }
  );

  const accountRequirements = queryAccountRequirements.data;

  const handleSubmit = async (formValues: FormData) => {
    if (
      !verificationIssue &&
      formValues.country === "GB" &&
      featureFlags.data?.currencyCloudVerification
    ) {
      let isWarning = false;

      try {
        const verificationResponse = await verifyAccount(
          {
            country: formValues.country,
            accountNumber: formValues.accountNumber,
            routingNumber: formValues.routing
          },
          {
            throwOnError: true
          }
        );

        isWarning = verificationResponse?.reasonCode !== "AV100";

        if (isWarning) {
          setVerificationIssue(verificationResponse);
        }
      } catch (error) {
        isWarning = true;
        setVerificationIssue({
          reasonType: "",
          reason: "",
          reasonCode: "SERVICE_ERROR"
        });
      }

      if (isWarning) {
        setSubmitDisabled(true);
        setTimeout(setSubmitDisabled, 5000, false);
        return;
      }
    }

    await onSubmit(formValues);
  };

  const panelContent = (
    <WSList gap="2XL">
      <WSSectionToolbar title="Enter your payout account details" />

      <WSForm.Field
        name="country"
        label="Account country"
        component={WSSelect}
        componentProps={{
          options: COUNTRY_OPTIONS,
          internalSearch: true
        }}
        onChange={value => {
          setCountry(value);
        }}
      />

      <WSForm.Field
        name="currency"
        label="Account currency"
        component={WSSelect}
        componentProps={{
          options: CURRENCY_OPTIONS,
          internalSearch: true
        }}
        onChange={value => {
          setCurrency(value);
        }}
      />

      <WSForm.Value name="currency">
        {currency =>
          currency === "USD" ? (
            <WSInfoBox title="International wire transfer fees may apply">
              You selected USD for a non-US account. Please contact your bank
              for details on international wire transfer fees.
            </WSInfoBox>
          ) : null
        }
      </WSForm.Value>

      <WSForm.Field
        name="accountNumber"
        label={accountRequirements?.accountNumber.name || "Account Number"}
        component={WSInputText}
        componentProps={{
          fsExclude: true
        }}
      />

      <WSForm.Field
        name="confirmAccountNumber"
        label={
          accountRequirements?.accountNumber.name
            ? `Confirm ${accountRequirements.accountNumber.name}`
            : "Confirm Account Number"
        }
        component={WSInputText}
        componentProps={{
          fsExclude: true
        }}
      />

      {verificationIssue ? (
        <AccountBeneficiaryVerificationAlert {...verificationIssue} />
      ) : null}

      {accountRequirements?.routing && (
        <WSForm.Field
          name="routing"
          label={accountRequirements?.routing.name || "Bank identifier"}
          component={WSInputText}
          componentProps={{
            fsExclude: true
          }}
        />
      )}

      {accountRequirements?.routingSecondary && (
        <WSForm.Field
          name="routingSecondary"
          label={accountRequirements.routingSecondary.name}
          component={WSInputText}
          componentProps={{
            fsExclude: true
          }}
        />
      )}

      <WSErrorMessage contextKey="PaymentsSetup" error={error} />
    </WSList>
  );

  return (
    <WSForm<FormData>
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(accountRequirements)}
      onChange={() => {
        if (verificationIssue || submitDisabled) {
          setVerificationIssue(undefined);
          setSubmitDisabled(false);
        }
      }}
    >
      <WSList gap="2XL">
        {withPanel ? <WSPanel>{panelContent}</WSPanel> : panelContent}

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              kind: "Secondary",
              onClick: onBack,
              visible: !!onBack,
              type: "button"
            },
            {
              label: "Continue",
              type: "submit",
              loading: isLoading,
              disabled: !!submitDisabled
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
