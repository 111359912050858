import React, { useState } from "react";
import {
  useIsMobile,
  useWSSnackbar,
  WSContainer,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSCentered,
  WSMessageBox,
  WSPanel,
  WSProgressBar,
  WSRadioInputGroup,
  WSText
} from "@wingspanhq/fe-component-library";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { ProjectOnboardingLayout } from "../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import * as Yup from "yup";
import {
  ITransaction,
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { truncate } from "lodash";
import { useCreateTransaction } from "../../../query/bookkeeping/mutations";
import { useHistory } from "react-router";
import { track } from "../../../utils/analytics";
import useSnackbar from "../../../hooks/useSnackbar";

type Props = { next: string };

export const BookkeepingCSVVerifyType: React.FC<Props> = ({ next }) => {
  const history = useHistory<{
    transactions: Array<ITransaction>;
    accountId?: string;
  }>();

  useBrowserPageTitle("Bookkeeping - CSV: Verify income and expenses");
  const [showErrors, setShowErrors] = useState(false);

  const snackbar = useSnackbar();

  const [results, setResults] = useState<
    {
      error?: any;
      name: string;
    }[]
  >([]);

  const isMobile = useIsMobile();

  const transactions =
    history.location.state?.transactions &&
    Array.isArray(history.location.state?.transactions)
      ? history.location.state?.transactions
      : [];

  const percent = (results.length * 100) / transactions.length;

  const failedResults = results.filter(r => r.error);

  const isPositiveMajority =
    transactions.filter(t => t.amount >= 0).length >= transactions.length / 2;

  const majorityTransactions = transactions
    .filter(t => (isPositiveMajority ? t.amount >= 0 : t.amount < 0))
    .sort((a, b) =>
      Math.abs(a.amount) < Math.abs(b.amount)
        ? 1
        : Math.abs(a.amount) === Math.abs(b.amount)
        ? 0
        : -1
    )
    .slice(0, 3);

  const [createTransaction, createTransactionMeta] = useCreateTransaction();

  return (
    <ProjectOnboardingLayout progress={(100 / 5) * 4} noBack>
      <WSContainer verticalPadding>
        <WSFormOld
          validationSchema={Yup.object().shape({
            type: Yup.string()
          })}
          defaultValues={{ type: "EXPENSES" }}
          onSubmit={async formData => {
            const isExpenses = formData.type === "EXPENSES";

            const newTransactions = transactions.map(transaction => {
              let currentType: TransactionType = TransactionType.Expense;

              if (isExpenses) {
                if (isPositiveMajority) {
                  if (transaction.amount < 0) {
                    currentType = TransactionType.Income;
                  } else {
                    currentType = TransactionType.Expense;
                  }
                } else {
                  if (transaction.amount < 0) {
                    currentType = TransactionType.Expense;
                  } else {
                    currentType = TransactionType.Income;
                  }
                }
              } else {
                if (isPositiveMajority) {
                  if (transaction.amount < 0) {
                    currentType = TransactionType.Expense;
                  } else {
                    currentType = TransactionType.Income;
                  }
                } else {
                  if (transaction.amount < 0) {
                    currentType = TransactionType.Income;
                  } else {
                    currentType = TransactionType.Expense;
                  }
                }
              }

              let currentWSCategory = transaction.wsCategory;

              if (!currentWSCategory) {
                if (currentType === TransactionType.Income) {
                  currentWSCategory = WSCategory.Income;
                } else {
                  currentWSCategory = WSCategory.Supplies;
                }
              }

              return {
                ...transaction,
                amount:
                  Math.abs(transaction.amount) *
                  (currentType === TransactionType.Expense ? 1 : -1),
                wsCategory: currentWSCategory,
                type: currentType
              };
            });

            const errors = [];

            for (let transaction of newTransactions) {
              await createTransaction(transaction, {
                onSuccess(transaction) {
                  setResults(prev => [
                    ...prev,
                    {
                      name: transaction.name
                    }
                  ]);
                },
                onError(error) {
                  errors.push(error);
                  setResults(prev => [
                    ...prev,
                    {
                      name: transaction.name,
                      error: error || "Oops!"
                    }
                  ]);
                }
              });
            }

            track("Transaction Spreadsheet Upload Completed", {
              type: next.includes("setup") ? "initial" : "incremental",
              transactionsCount: results.filter(r => !r.error).length,
              failedTransactionsCount: results.filter(r => r.error).length
            });

            history.replace(next, history.location.state);

            snackbar.success("CSV Uploaded");

            if (errors.length) {
              snackbar.warning(
                `Failed upload for ${errors.length} transactions`
              );
            }
          }}
        >
          <WSCentered span={{ m: "6" }}>
            <WSText.ParagraphSm color="gray500" mt="M" pt="M">
              4 of 5
            </WSText.ParagraphSm>
            <WSText.Heading4>
              Import historic business transactions
            </WSText.Heading4>

            <WSPanel mt="2XL">
              <WSText.Heading5>Verify income and expenses</WSText.Heading5>

              <WSDivider type="expand" mt="XL" />

              <WSElement py="M">
                {majorityTransactions.map((transaction, i) => (
                  <WSFlexBox.CenterY
                    key={i}
                    justify="space-between"
                    py="M"
                    wrap="nowrap"
                  >
                    <WSElement>
                      <WSText>
                        {isMobile
                          ? truncate(transaction.name, {
                              length: 20
                            })
                          : transaction.name}
                      </WSText>
                      <WSText.ParagraphSm
                        mt="XS"
                        color="gray500"
                        formatDate="monthDate"
                      >
                        {transaction.date}
                      </WSText.ParagraphSm>
                    </WSElement>
                    <WSText formatMoney>{transaction.amount}</WSText>
                  </WSFlexBox.CenterY>
                ))}
              </WSElement>

              <WSDivider type="expand" />

              <WSText mt="XL">
                Are the above transactions income or expenses?
              </WSText>

              <WSFormOld.Field
                mt="M"
                label=""
                name="type"
                component={WSRadioInputGroup}
                componentProps={{
                  options: [
                    {
                      value: "INCOME",
                      label: "Income"
                    },
                    {
                      value: "EXPENSES",
                      label: "Expenses"
                    }
                  ]
                }}
              />

              {createTransactionMeta.isLoading || results.length > 0 ? (
                <WSProgressBar
                  mt="2XL"
                  percent={percent || 0}
                  text={`Uploading: ${results.length} of ${transactions.length} transactions...`}
                />
              ) : null}

              {failedResults.length ? (
                <WSMessageBox.Error
                  onClick={() => setShowErrors(p => !p)}
                  icon="alert-circle"
                >
                  <WSText>{`Failed ${failedResults.length} transactions`}</WSText>
                  {showErrors &&
                    failedResults.map((r, i) => (
                      <WSText key={`${i}_${r.name}`} mt="M">
                        {`Transaction: "${r.name}".`}
                        <br />{" "}
                        {`Error: "${
                          r.error?.response?.data?.error || r.error
                        }"`}
                      </WSText>
                    ))}
                </WSMessageBox.Error>
              ) : null}

              <WSFormOld.SubmitButton
                name="submitVerifyImport"
                mt="2XL"
                fullWidth
              >
                Continue
              </WSFormOld.SubmitButton>
            </WSPanel>
          </WSCentered>
        </WSFormOld>
      </WSContainer>
    </ProjectOnboardingLayout>
  );
};
