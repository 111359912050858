import {
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import { IPayerResponse } from "@wingspanhq/payments/dist/interfaces";
import { InfoItem } from "../../components/ClientInfoPanel/InfoItem";
import { copyText } from "../../../../utils/copyText";

import styles from "./styles.module.scss";
import { useState } from "react";

export interface IWireTransferSectionProps extends WSElementProps {
  client: IPayerResponse;
}

export const WireTransferSection: React.FC<IWireTransferSectionProps> = ({
  client,
  ...elementProps
}) => {
  const [showInternationalInfo, setShowInternationalInfo] =
    useState<boolean>(false);
  const {
    bankTransferProcessingAccount,
    internationalBankTransferProcessingAccount
  } = client;
  return (bankTransferProcessingAccount &&
    bankTransferProcessingAccount.routingNumber) ||
    (internationalBankTransferProcessingAccount &&
      internationalBankTransferProcessingAccount.accountNumber) ? (
    <WSElement {...elementProps}>
      <WSSectionToolbar
        title="Wire transfers"
        tooltip={{
          title: "Wire transfers",
          text: "This is the secure payment gateway setup for this client that routes money to your direct deposit without you having to provide your own banking info."
        }}
      />

      <WSPanel className={styles.panel}>
        {showInternationalInfo &&
        internationalBankTransferProcessingAccount?.accountNumber ? (
          // international wire details
          <>
            {internationalBankTransferProcessingAccount?.bankName && (
              <WSFlexBox.CenterY mb="XL" justify="space-between">
                <InfoItem
                  label="Bank name"
                  value={
                    internationalBankTransferProcessingAccount?.bankName ?? ""
                  }
                />
                <WSButton.Link
                  onClick={() => {
                    copyText(
                      internationalBankTransferProcessingAccount?.bankName ??
                        "",
                      "Bank name copied to clipboard"
                    );
                  }}
                >
                  Copy
                </WSButton.Link>
              </WSFlexBox.CenterY>
            )}

            {internationalBankTransferProcessingAccount?.routingNumber && (
              <WSFlexBox.CenterY mb="XL" justify="space-between">
                <InfoItem
                  label="Routing number"
                  value={
                    internationalBankTransferProcessingAccount?.routingNumber ??
                    ""
                  }
                />
                <WSButton.Link
                  onClick={() => {
                    copyText(
                      internationalBankTransferProcessingAccount?.routingNumber ??
                        "",
                      "Routing number copied to clipboard"
                    );
                  }}
                >
                  Copy
                </WSButton.Link>
              </WSFlexBox.CenterY>
            )}

            {internationalBankTransferProcessingAccount?.swiftCode && (
              <WSFlexBox.CenterY mb="XL" justify="space-between">
                <InfoItem
                  label="SWIFT code"
                  value={
                    internationalBankTransferProcessingAccount?.swiftCode ?? ""
                  }
                />
                <WSButton.Link
                  onClick={() => {
                    copyText(
                      internationalBankTransferProcessingAccount?.swiftCode ??
                        "",
                      "SWIFT code copied to clipboard"
                    );
                  }}
                >
                  Copy
                </WSButton.Link>
              </WSFlexBox.CenterY>
            )}

            {internationalBankTransferProcessingAccount?.accountNumber && (
              <WSFlexBox.CenterY mb="XL" justify="space-between">
                <InfoItem
                  label="Account number"
                  value={
                    internationalBankTransferProcessingAccount?.accountNumber ??
                    ""
                  }
                />
                <WSButton.Link
                  onClick={() => {
                    copyText(
                      internationalBankTransferProcessingAccount?.routingNumber ??
                        "",
                      "Account number copied to clipboard"
                    );
                  }}
                >
                  Copy
                </WSButton.Link>
              </WSFlexBox.CenterY>
            )}

            {bankTransferProcessingAccount?.accountNumber ? (
              <WSButton.Link
                onClick={() => setShowInternationalInfo(prev => !prev)}
              >
                View domestic wire details
              </WSButton.Link>
            ) : null}
          </>
        ) : bankTransferProcessingAccount?.routingNumber ? (
          // domestic wire details
          <>
            <WSFlexBox.CenterY mb="XL" justify="space-between">
              <InfoItem
                label="Bank name"
                value={bankTransferProcessingAccount?.bankName ?? ""}
              />
              <WSButton.Link
                onClick={() => {
                  copyText(
                    bankTransferProcessingAccount?.bankName ?? "",
                    "Bank name copied to clipboard"
                  );
                }}
              >
                Copy
              </WSButton.Link>
            </WSFlexBox.CenterY>

            <WSFlexBox.CenterY mb="XL" justify="space-between">
              <InfoItem
                label="Routing number"
                value={bankTransferProcessingAccount?.routingNumber ?? ""}
              />
              <WSButton.Link
                onClick={() => {
                  copyText(
                    bankTransferProcessingAccount?.routingNumber ?? "",
                    "Routing number copied to clipboard"
                  );
                }}
              >
                Copy
              </WSButton.Link>
            </WSFlexBox.CenterY>

            <WSFlexBox.CenterY mb="XL" justify="space-between">
              <InfoItem
                label="Account number"
                value={bankTransferProcessingAccount?.accountNumber ?? ""}
              />
              <WSButton.Link
                onClick={() => {
                  copyText(
                    bankTransferProcessingAccount?.accountNumber ?? "",
                    "Account number copied to clipboard"
                  );
                }}
              >
                Copy
              </WSButton.Link>
            </WSFlexBox.CenterY>
            {/* 
              TODO: implement tabs for domestic & international 
              link: https://www.figma.com/design/Ip82EFJ9vjzqLmCDIt8lZV/Engagements?node-id=2033-100595&m=dev
            */}
            {internationalBankTransferProcessingAccount?.accountNumber ? (
              <WSButton.Link
                onClick={() => setShowInternationalInfo(prev => !prev)}
              >
                View international wire details
              </WSButton.Link>
            ) : null}
          </>
        ) : null}
      </WSPanel>
    </WSElement>
  ) : null;
};
