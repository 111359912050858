import {
  copyText,
  useIsMobile,
  useWSSnackbar,
  WSAvatar,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import { truncate } from "lodash";
import * as React from "react";
import { TouchEventHandler } from "react";
import { createPPL } from "../../PersonalPayLink/utils";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../../modules/EngagementRequirementsSettings";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useUserProfile } from "../../query/users/queries";
import { openIntercom } from "../../shared/utils/intercom";
import { HeadwayNotification } from "../HeadwayNotification";
import { CollapsibleMenu } from "./CollapsibleMenu";
import { SideNavHeader } from "./SideNavHeader";
import { SideNavMenuItem } from "./SideNavMenuItem";
import { SideNavRootLinkOption } from "./SideNavRootLinkOption";
import { SideNavRootOption } from "./SideNavRootOption";
import styles from "./styles.module.scss";
import { PATH_SETTINGS_PAYMENTS } from "../../modules/Settings/routes/RoutePayments";
import { useFocusedFeatures } from "../../FocusedFeatures";
import {
  DefaultNav,
  ExpandableSideNavMenuOptions
} from "./contexts/DefaultNav";
import { SwitchAccounts } from "../../modules/SelectOrganizationAccount/components/SwitchAccountsDropdown";

export interface SideNavMenuProps {
  isSideNavOpened?: boolean;
  setIsSideNavOpened?: (isDrawerOpened: boolean) => void;
  setIsDrawerOpened?: (isDrawerOpened: boolean) => void;
}

const SideNavMenu: React.FC<SideNavMenuProps> = ({
  isSideNavOpened,
  setIsSideNavOpened = () => {},
  setIsDrawerOpened = () => {}
}) => {
  const [topMenu, setTopMenu] = React.useState<ExpandableSideNavMenuOptions[]>(
    []
  );
  const [bottomMenu, setBottomMenu] = React.useState<string[]>([]);
  const focusedFeatures = useFocusedFeatures();

  const { openSnackbar } = useWSSnackbar();
  const isMobile = useIsMobile();
  const userId = useUserId();
  const user = useUserProfile(userId);

  React.useEffect(() => {
    if (!isSideNavOpened) {
      setTopMenu([]);
      setBottomMenu([]);
    }
  }, [isSideNavOpened]);

  const featureFlagsQuery = useFeatureFlags();

  const onExpandMainMenuItem = (
    item: ExpandableSideNavMenuOptions,
    top = true
  ) => {
    if (!isSideNavOpened) {
      setIsSideNavOpened(true);
    }
    if (top) {
      if (topMenu.includes(item)) {
        setTopMenu(topMenu.filter(menu => menu !== item));
      } else {
        setTopMenu([...topMenu, item]);
        setBottomMenu([]);
      }
    } else {
      if (bottomMenu.includes(item)) {
        setBottomMenu(bottomMenu.filter(menu => menu !== item));
      } else {
        setBottomMenu([...bottomMenu, item]);
        setTopMenu([]);
      }
    }
  };

  return (
    <WSElement
      as="nav"
      className={classNames(isMobile ? styles.navMobile : styles.nav, {
        [styles.collapsed]: !isMobile && !isSideNavOpened
      })}
      data-testuseremail={user.data?.email}
    >
      <WSFlexBox direction="column" className={styles.navItemsContainer}>
        <WSScreen.TabletAndDesktop>
          <SideNavHeader
            p="M"
            isSideNavOpened={isSideNavOpened}
            setIsSideNavOpened={setIsSideNavOpened}
          />
        </WSScreen.TabletAndDesktop>

        {isMobile ? (
          <>
            <WSFlexBox.CenterY>
              {user.data?.profile.photo ? (
                <WSAvatar.Image
                  m="M"
                  size="L"
                  image={user.data?.profile.photo}
                />
              ) : (
                <WSAvatar.Image
                  m="M"
                  size="L"
                  image={
                    require("../../assets/images/defaultUserPhoto.svg").default
                  }
                />
              )}
              <WSElement>
                <WSText
                  color="gray700"
                  style={{ fontSize: "17px", lineHeight: "24px" }}
                >
                  <b>
                    {truncate(
                      `${user.data?.profile.firstName || ""} ${
                        user.data?.profile.lastName || ""
                      }`,
                      {
                        length: 20,
                        separator: " ",
                        omission: ""
                      }
                    )}
                  </b>
                </WSText>
                {/* {qActivity.data?.flows.settingsFoundingMemberInterest
                  ?.complete ? (
                  <WSText.ParagraphSm color="gray500">
                    Early Access Member 🌟
                  </WSText.ParagraphSm>
                ) : (
                  <WSButton.Link
                    onClick={async () => {
                      history.push("/member/settings/account/early-access");
                      setIsDrawerOpened(false);
                    }}
                  >
                    Request Early Access
                  </WSButton.Link>
                )} */}
              </WSElement>
            </WSFlexBox.CenterY>
            <WSDivider mb="XL" />
          </>
        ) : null}

        {focusedFeatures.showSideNavSwitchAccount && (
          <SwitchAccounts
            isSideNavOpened={!!isSideNavOpened}
            setIsSideNavOpened={
              isMobile ? setIsDrawerOpened : setIsSideNavOpened
            }
          />
        )}

        {/*{focusedFeatures.showTaxOnlyPayeeNavigation ? (*/}
        {/*  <TaxOnlyPayeeNav*/}
        {/*    isSideNavOpened={!!isSideNavOpened}*/}
        {/*    setIsDrawerOpened={setIsDrawerOpened}*/}
        {/*    setIsSideNavOpened={setIsSideNavOpened}*/}
        {/*    expandedItems={topMenu}*/}
        {/*    onExpand={onExpandMainMenuItem}*/}
        {/*  />*/}
        {/*) : (*/}
        <DefaultNav
          isSideNavOpened={!!isSideNavOpened}
          setIsDrawerOpened={setIsDrawerOpened}
          setIsSideNavOpened={setIsSideNavOpened}
          expandedItems={topMenu}
          onExpand={onExpandMainMenuItem}
        />
        {/*)}*/}

        <WSDivider my="XL" />
        <SideNavRootOption
          onClick={openIntercom}
          label="Contact Support"
          icon="support"
          name="contactSupport"
          isSideNavOpened={isSideNavOpened}
        />
        {focusedFeatures.showSideNavHeadway && (
          <HeadwayNotification
            isMobile={isMobile}
            isSideNavOpened={isSideNavOpened}
            kind="nav"
          />
        )}

        {/* Data & Integrations */}
        <CollapsibleMenu
          isVisible={focusedFeatures.showSideNavDataAndIntegrations}
          isSideNavOpened={isSideNavOpened}
          icon="data"
          label="Data & integrations"
          isExpanded={bottomMenu.includes(
            ExpandableSideNavMenuOptions.DataAndIntegrations
          )}
          onClick={() =>
            onExpandMainMenuItem(
              ExpandableSideNavMenuOptions.DataAndIntegrations,
              false
            )
          }
        >
          <SideNavMenuItem
            linkTo="/member/documents"
            testId="resourceHubMyDocuments"
            isVisible={focusedFeatures.showSideNavDocuments}
            label="Documents"
          />

          <SideNavMenuItem
            linkTo="/member/data/custom-fields"
            testId="navCustomFields"
            isVisible={focusedFeatures.showSideNavCustomFields}
            label="Custom fields"
          />

          <SideNavMenuItem
            linkTo="/member/imports"
            testId="navBatchImports"
            isVisible={focusedFeatures.showSideNavButchImports}
            label="Batch imports"
          />

          <SideNavMenuItem
            linkTo="/member/settings/integrations"
            testId="settingsIntegrations"
            isVisible={focusedFeatures.showSideNavIntegrations}
            label="Integrations"
          />

          <SideNavMenuItem
            linkTo="/member/settings/tokens"
            testId="navTokens"
            isVisible={focusedFeatures.showSideNavTokens}
            label="Developer"
          />

          <SideNavMenuItem
            linkTo="/member/organization/accounts"
            testId="organizationAccounts"
            isVisible={focusedFeatures.showSideNavOrganizationAccounts}
            label="Organization accounts"
          />
        </CollapsibleMenu>
        {/* Settings */}
        <CollapsibleMenu
          isVisible={focusedFeatures.showSideNavSettings}
          isSideNavOpened={isSideNavOpened}
          icon="settings"
          label="Settings"
          isExpanded={bottomMenu.includes(
            ExpandableSideNavMenuOptions.Settings
          )}
          onClick={() =>
            onExpandMainMenuItem(ExpandableSideNavMenuOptions.Settings, false)
          }
        >
          <SideNavMenuItem
            linkTo="/member/settings/account"
            testId="settingsAccount"
            isVisible={focusedFeatures.showSideNavAccountSettings}
            label={
              featureFlagsQuery.data?.newAccountSettings
                ? "Account settings"
                : "Account & subscription"
            }
          />
          <SideNavMenuItem
            linkTo="/member/settings/payment-methods"
            testId="settingsPaymentMethods"
            isVisible={focusedFeatures.showSideNavPayoutMethodSettings}
            label={"Payout Method"}
          />
          {/* else */}
          <SideNavMenuItem
            linkTo="/member/settings/payment-methods"
            testId="settingsPaymentMethods"
            isVisible={focusedFeatures.showSideNavPaymentMethodSettings}
            label={"Payment methods"}
          />
          {/* else */}
          <SideNavMenuItem
            linkTo="/member/settings/payment-methods"
            testId="settingsPaymentMethods"
            isVisible={
              focusedFeatures.showSideNavPayoutAndPaymentsMethodSettings
            }
            label="Payout & payment methods"
          />

          <SideNavMenuItem
            linkTo="/member/settings/personal-info"
            testId="settingsPersonalInfo"
            isVisible={focusedFeatures.showSideNavPersonalInfoSettings}
            label="Personal info"
          />
          <SideNavMenuItem
            linkTo="/member/settings/business-info"
            testId="settingsBusinessInfo"
            isVisible={focusedFeatures.showSideNavBusinessInfoSettings}
            label="Business info"
          />
          <SideNavMenuItem
            linkTo="/member/settings/billing/payables"
            testId="settingsPayroll"
            isVisible={focusedFeatures.showSideNavPayrollSettings}
            label="Payable & payroll settings"
          />
          <SideNavMenuItem
            linkTo={PATH_SETTINGS_PAYMENTS}
            testId="settingsPaymentMethods"
            isVisible={focusedFeatures.showSideNavSendPaymentsSettings}
            label="Send payments settings"
          />
          <SideNavMenuItem
            linkTo={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
            testId="settingsEngagementRequirements"
            isVisible={focusedFeatures.showSideNavEngagementRequirements}
            label="Engagement requirements"
          />
          <SideNavMenuItem
            linkTo="/member/settings/tax-profile"
            testId="settingsTaxProfile"
            isVisible={focusedFeatures.showSideNavTaxProfileSettings}
            label="Tax profile"
          />
          <SideNavMenuItem
            linkTo="/member/tax-documents"
            testId="settingsTaxDocuments"
            isVisible={focusedFeatures.showSideNavTaxDocumentsSettings}
            label="Tax documents"
          />
          <SideNavMenuItem
            linkTo="/member/settings/notifications"
            testId="settingsNotifications"
            isVisible={focusedFeatures.showSideNavNotificationsSettings}
            label="Notifications"
          />
          <SideNavMenuItem
            linkTo="/member/settings/team"
            testId="settingsTeam"
            isVisible={focusedFeatures.showSideNavTeamsSettings}
            label="Team"
          />
          <SideNavMenuItem
            linkTo="/member/settings/email-settings"
            testId="settingsEmail"
            isVisible={focusedFeatures.showSideNavEmailSettings}
            label="Email settings"
          />
        </CollapsibleMenu>
        <WSFlexBox
          direction="column"
          className={styles.bottomNavItems}
          wrap="nowrap"
        >
          <SideNavRootLinkOption
            isVisible={true}
            to="/sign-out"
            testId="logout"
            label="Log out"
            icon="logout"
            isSideNavOpened={isSideNavOpened}
          />
        </WSFlexBox>

        <WSScreen.Mobile>
          {user.data?.tag ? (
            <WSFlexBox.Center className={styles.copyLinkWrapper}>
              <WSElement
                as="button"
                className={styles.copyLink}
                px="3XL"
                py="M"
                onClick={async () => {
                  await copyText(createPPL(user.data?.tag as string));

                  openSnackbar({
                    type: "success",
                    message: "Copied",
                    duration: 3000
                  });
                }}
              >
                {truncate(createPPL(user.data?.tag), { length: 25 })}
              </WSElement>
            </WSFlexBox.Center>
          ) : null}
        </WSScreen.Mobile>
      </WSFlexBox>
    </WSElement>
  );
};

export interface SideNavProps extends WSElementProps {
  isDrawerOpened?: boolean;
  setIsDrawerOpened?: (isDrawerOpened: boolean) => void;
  isSideNavOpened?: boolean;
  setIsSideNavOpened?: (isSideNavOpened: boolean) => void;
  desktopTopPadding?: number;
}

export const SideNav: React.FC<SideNavProps> = ({
  isDrawerOpened,
  setIsDrawerOpened = () => {},
  isSideNavOpened,
  setIsSideNavOpened,
  desktopTopPadding
}) => {
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  const handleTouchStart: TouchEventHandler<HTMLElement> = event => {
    const clientX = event.targetTouches[0].clientX;

    setTouchStart(clientX);
    setTouchEnd(clientX);
  };

  const handleTouchMove: TouchEventHandler<HTMLElement> = event => {
    const clientX = event.targetTouches[0].clientX;

    setTouchEnd(clientX);
  };

  const handleTouchEnd: TouchEventHandler<HTMLElement> = () => {
    if (touchStart - touchEnd > 100) {
      setIsDrawerOpened(false);
    }
  };

  return (
    <>
      <WSScreen.Mobile>
        <WSElement
          onTouchEnd={handleTouchEnd}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onClick={event => {
            const target = event.target as HTMLElement;

            if (target === event.currentTarget) {
              setIsDrawerOpened(false);
            }

            if (target.tagName.toLowerCase() === "a" || target.closest("a")) {
              setIsDrawerOpened(false);
            }
          }}
          className={classNames(styles.mobileNavContainer, {
            [styles.mobileNavOpened]: isDrawerOpened
          })}
        >
          <SideNavMenu setIsDrawerOpened={setIsDrawerOpened} />
        </WSElement>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSElement
          className={styles.desktopNavContainer}
          style={{ top: desktopTopPadding }}
        >
          <SideNavMenu
            isSideNavOpened={isSideNavOpened}
            setIsSideNavOpened={setIsSideNavOpened}
          />
        </WSElement>
      </WSScreen.TabletAndDesktop>
    </>
  );
};
