import {
  useWSModal,
  useWSSnackbar,
  WSActions,
  WSAlert,
  WSCard,
  WSControl,
  WSDivider,
  WSElement,
  WSGrid,
  WSInfoBox,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IPayeeEngagementResponse,
  IPayeeResponse,
  PayerPayeeEngagementStatus,
  PayerPayeeStatus
} from "@wingspanhq/payments/dist/interfaces";
import { useHistory } from "react-router-dom";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useUpdatePayeeEngagement } from "../../../query/payeeEngagements/mutations/useUpdatePayeeEngagement";
import {
  FieldView,
  FieldViewProps
} from "../../../shared/components/FieldView/FieldView";
import React, { useMemo, useState } from "react";
import { useUpdatePayee } from "../../../query/payee/mutations/useUpdatePayee";
import { useArchivePayeeEngagements } from "../../../query/payeeEngagements/mutations/useArchivePayeeEngagements";
import { usePayeeEngagementsListAllQuery } from "../../../query/payeeEngagements/queries/usePayeeEngagementsListAllQuery";
import { selectorIsEngagementsEnabled } from "../../Onboarding/selectors/getActivePayersWithEngagement";

export interface ArchiveEngagementForContractorModalProps {
  payee: IPayeeResponse;
  engagement: IPayeeEngagementResponse;
  onClose: () => void;
  nonDefaultEngagements: IPayeeEngagementResponse[];
}

export const ArchiveEngagementForContractorModal: React.FC<
  ArchiveEngagementForContractorModalProps
> = ({ payee, engagement, onClose, nonDefaultEngagements }) => {
  const history = useHistory();
  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const [archiveEngagements, archiveEngagementsMeta] =
    useArchivePayeeEngagements();
  const queryAllEngagements = usePayeeEngagementsListAllQuery(payee.payeeId);

  const isEngagementsEnabled = selectorIsEngagementsEnabled(
    queryAllEngagements.data || []
  );

  const { openSnackbar } = useWSSnackbar();
  const [updateEngagement, updateEngagementMeta] = useUpdatePayeeEngagement();
  const [isStopAll, setIsStopAll] = useState<boolean>(
    queryAllEngagements.data ? !isEngagementsEnabled : false
  );

  const fields = useMemo(() => {
    const result: FieldViewProps[] = [
      {
        label: "Engagement name",
        value: engagement.engagementName
      },
      {
        label: "Type",
        value: engagement.type
      }
      // {
      //   label: "Description",
      //   value: engagement.description
      // }
    ].filter(i => i.value);

    return result;
  }, [engagement]);

  const archiveContractor = async () => {
    const visibleEngagements =
      queryAllEngagements.data?.filter(
        e => e.status !== PayerPayeeEngagementStatus.Inactive
      ) || [];

    await archiveEngagements(
      {
        payeeId: payee.payeeId,
        engagementIds: visibleEngagements.map(e => e.payerPayeeEngagementId)
      },
      {
        onSuccess: async () => {
          await updatePayee(
            {
              payeeId: payee.payeeId,
              payerOwnedData: {
                status: PayerPayeeStatus.Inactive
              }
            },
            {
              onSuccess: () => {
                openSnackbar({
                  duration: 5000,
                  type: "success",
                  message: "Contractor archived successfully"
                });
                onClose();
              },
              onError: () => {
                openSnackbar({
                  duration: 5000,
                  type: "warning",
                  message: `Failed to archive contractor`
                });
              }
            }
          );
        }
      }
    );
  };

  const isLastEngagement =
    nonDefaultEngagements.filter(
      engagement => engagement.status === PayerPayeeEngagementStatus.Active
    ).length === 1;

  return (
    <>
      <WSDivider mb="XL" />

      <WSText.ParagraphSm mb="L" weight="book" color="gray500">
        {isLastEngagement
          ? "Are you sure you want to archive the last active contractor engagement?"
          : "Are you sure you want to archive this engagement for the contractor?"}
      </WSText.ParagraphSm>

      <WSCard>
        <WSGrid gutter="2XL">
          {fields.map(props => (
            <WSGrid.Item
              key={props.label}
              span={{
                xs: "12",
                s: props.label === "Description" ? "12" : "6",
                l: props.label === "Description" ? "12" : "4"
              }}
            >
              <FieldView {...props} />
            </WSGrid.Item>
          ))}
        </WSGrid>
      </WSCard>

      <WSAlert
        mt="2XL"
        mb={isLastEngagement ? "S" : "2XL"}
        theme="info"
        icon="info-circle"
        size="M"
        title="Archive engagement for contractor"
      >
        After the engagement is archived for a contractor, the contractor is
        made archived within the engagement. No additional invoices for this
        contractor can be created for this engagement. The engagement will
        remain for other contractors assigned to the engagement.
        <WSElement mt="M" />
        Existing invoices will remain on the Invoicing page until you delete
        them. You can always restore the engagement for the contractor when
        needed.
      </WSAlert>

      {isLastEngagement && (
        <WSAlert
          mb="2XL"
          theme="warning"
          icon="alert"
          size="M"
          title="No active engagements remaining"
        >
          After this engagement is archived, there are no active engagements
          remaining for this contractor.
        </WSAlert>
      )}
      {isLastEngagement && isEngagementsEnabled && (
        <WSInfoBox title="About payments to contractor" mb="S">
          <ul>
            <li>
              <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                When no active engagements exist for a contractor, they can
                still invoice and receive direct payments (non-engagement) from
                you.
              </WSText.ParagraphSm>
            </li>
            <li>
              <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                In order to stop all invoices and payments to a contractor,
                check the box to archive the contractor. (They can be restored
                by you at any time)
              </WSText.ParagraphSm>
            </li>
          </ul>
        </WSInfoBox>
      )}

      <WSErrorMessage
        error={
          updateEngagementMeta.error ||
          archiveEngagementsMeta.error ||
          updatePayeeMeta.error
        }
        mb="XL"
        contextKey="ArchiveClient"
      />

      {isLastEngagement && isEngagementsEnabled && (
        <WSControl
          type="checkbox"
          size="S"
          mb="XL"
          value={isStopAll}
          onChange={setIsStopAll}
          label="Stop all invoices and payments to this contractor."
          tooltip="To stop all invoices and payments to this contractor, they will be
          archived along with their last active engagement. To restore the
          ability create invoices and send payments to this contractor, they
          must be unarchived to receive direct payments (non-engagement) and/or
          added to an engagements to receive engagements-specific payments."
        />
      )}

      <WSDivider my="L" />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Cancel",
            onClick: onClose,
            kind: "Secondary"
          },
          {
            label:
              isLastEngagement && isStopAll
                ? "Archive engagement and contractor"
                : "Archive engagement",
            loading:
              updateEngagementMeta.isLoading || queryAllEngagements.isLoading,
            onAsyncClick: async () => {
              isLastEngagement && isStopAll
                ? await archiveContractor()
                : await updateEngagement(
                    {
                      payeeId: payee.payeeId,
                      engagementId: engagement.payerPayeeEngagementId,
                      status: PayerPayeeEngagementStatus.Inactive
                    },
                    {
                      onSuccess: () => {
                        onClose();
                      }
                    }
                  );
            }
          }
        ]}
      />
    </>
  );
};
