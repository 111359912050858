import {
  WSButton,
  WSElement,
  WSGrid,
  WSList,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./RoutePreview.module.scss";
import { CURRENT_YEAR } from "../../constants/currentYear";
import { TAX_FILING_1099_FILING_SET_UP_PATH } from "../../index";
import { WS_LINKS } from "../../../../types/wsLinks";
import { ContractorsInfo } from "./ContractorsInfo";
import { TaxFilingFAQ } from "../../components/TaxFilingFAQ/TaxFilingFAQ";

export const RoutePreview: React.FC = () => {
  const history = useHistory();

  const onClickBeginFiling = () => {
    history.push(TAX_FILING_1099_FILING_SET_UP_PATH);
  };

  return (
    <WSPage
      title={
        <>
          <WSText.Heading1 mr="M" inline>
            1099-NEC filing
          </WSText.Heading1>
          <WSText.ParagraphSm
            className={styles.filingYear}
            inline
            color="gray500"
          >
            {CURRENT_YEAR}
          </WSText.ParagraphSm>
        </>
      }
      primaryButton={{
        name: "beginFiling",
        onClick: onClickBeginFiling,
        label: "Filing settings"
      }}
    >
      <WSGrid>
        <WSGrid.Item span={{ m: "8" }}>
          <WSPanel>
            <WSText.Paragraph weight="medium" color="gray600">
              Preparing for 1099-NEC tax filing
            </WSText.Paragraph>
            <WSText.ParagraphSm color="gray500" mt="M">
              Here are a few key reminders to prepare for tax filing with
              Wingspan:
            </WSText.ParagraphSm>

            <WSPanel noBorder colorBackground="gray50" px="2XL" py="L" mt="L">
              <WSElement className={styles.infoList}>
                <WSText.ParagraphSm weight="medium">
                  <ol>
                    <li>
                      Confirm your tax filing settings
                      <WSText.ParagraphSm color="gray500" mt="M">
                        <ul>
                          <li>
                            Confirm your business information, calculation, and
                            state filing settings. Update your settings from the
                            1099 filing dashboard.
                          </li>
                          <li>
                            Wingspan advises you to consult with a tax
                            professional to choose the most suitable options for
                            your business.
                          </li>
                        </ul>
                      </WSText.ParagraphSm>
                    </li>
                    <WSButton.Secondary
                      fullWidth
                      mt="M"
                      mb="L"
                      onClick={onClickBeginFiling}
                    >
                      Manage tax filing settings
                    </WSButton.Secondary>
                    <li>
                      Contractor information
                      <WSText.ParagraphSm color="gray500" mt="M">
                        <ul>
                          <li>
                            Contractors must meet the eligible criteria for
                            filing including minimum amounts, tax
                            classification, and tax residency. Learn more about{" "}
                            <a
                              href={WS_LINKS.eligibilityCriteria}
                              target="_blank"
                              rel="noreferrer"
                            >
                              eligibility criteria
                            </a>
                            .
                          </li>
                          <li>
                            To file with Wingspan, ensure that taxpayer
                            information and payment information is complete and
                            accurate before generating tax forms. Learn more
                            about the{" "}
                            <a
                              href={WS_LINKS.taxFilingProcess}
                              target="_blank"
                              rel="noreferrer"
                            >
                              tax filing
                            </a>{" "}
                            process.
                          </li>
                          <li>
                            Take advantage of Wingspan’s upload features to
                            ensure your contractors’ taxpayer and payments
                            information is up to date and accurate by using the
                            shortcuts below.
                          </li>
                        </ul>
                      </WSText.ParagraphSm>
                    </li>
                    <WSElement mb="L">
                      <WSGrid gutter="M">
                        <WSGrid.Item span={{ m: "6" }}>
                          <ContractorsInfo />
                        </WSGrid.Item>
                        <WSGrid.Item span={{ m: "6" }}>
                          <WSPanel style={{ height: "100%" }}>
                            <WSList gap="M">
                              <WSText.Paragraph color="gray700" weight="medium">
                                Other useful links
                              </WSText.Paragraph>
                              <WSElement>
                                <WSText.ParagraphSm color="gray500" mb="S">
                                  Looking to bulk upload contractors?
                                </WSText.ParagraphSm>
                                <WSButton.Link
                                  size="S"
                                  icon="upload"
                                  rightIcon="chevron-right"
                                  onClick={() => {
                                    history.push(
                                      "/member/imports/collaborators"
                                    );
                                  }}
                                >
                                  Bulk upload contractors
                                </WSButton.Link>
                              </WSElement>
                              <WSElement>
                                <WSText.ParagraphSm color="gray500" mb="S">
                                  Looking to bulk upload payments?
                                </WSText.ParagraphSm>
                                <WSButton.Link
                                  size="S"
                                  icon="upload"
                                  rightIcon="chevron-right"
                                  onClick={() => {
                                    history.push("/member/imports/payables");
                                  }}
                                >
                                  Bulk upload payments
                                </WSButton.Link>
                              </WSElement>
                            </WSList>
                          </WSPanel>
                        </WSGrid.Item>
                      </WSGrid>
                    </WSElement>
                    <li>
                      Generate tax forms
                      <WSText.ParagraphSm color="gray500" mt="M">
                        <ul>
                          <li>
                            Beginning in January {CURRENT_YEAR + 1} (when all
                            payments for {CURRENT_YEAR} are settled), Form
                            1099-NECs can be generated for eligible contractors
                            in the U.S. only.
                          </li>
                        </ul>
                      </WSText.ParagraphSm>
                    </li>
                    <li>
                      Submit for filing
                      <WSText.ParagraphSm color="gray500" mt="M">
                        <ul>
                          <li>
                            Only forms with filing status “Ready” will be
                            submitted for filing with the IRS. We recommend
                            reviewing forms with statuses “Needs action” and
                            “Excluded” to ensure they have the correct
                            information before filing.
                          </li>
                        </ul>
                      </WSText.ParagraphSm>
                    </li>
                    <li>
                      Manage corrections
                      <WSText.ParagraphSm color="gray500">
                        <ul>
                          <li>
                            After forms have been filed, contractors can request
                            corrections to their Form 1099-NEC if any errors are
                            noticed. You can review the request, verify the
                            corrections, and determine whether to accept the
                            changes and submit to the IRS or reject the changes.
                          </li>
                        </ul>
                      </WSText.ParagraphSm>
                    </li>
                  </ol>
                </WSText.ParagraphSm>
              </WSElement>
            </WSPanel>
          </WSPanel>
        </WSGrid.Item>
        <WSGrid.Item span={{ m: "4" }}>
          <TaxFilingFAQ mt="S" />
        </WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
