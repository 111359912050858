import {
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSSidebar,
  WSText,
  toWSDateString
} from "@wingspanhq/fe-component-library";
import { IInvoiceLineItem } from "@wingspanhq/payments/dist/interfaces";
import { formatMoney } from "accounting";
import React, { useRef } from "react";
import { CompanyLogo } from "../../../PersonalPayLink/screens/PersonalPayLinkDashboard";
import { PublicAttachmentDownloadLink } from "../../../components/PublicAttachmentDownloadLink/PublicAttachmentDownloadLink";
import { Tooltip } from "../../../components/Tooltip";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useMemberClientsQuery } from "../../../query/payments/queries";
import { useMemberProfile, useUserProfile } from "../../../query/users/queries";
import { openInNewTab } from "../../../utils/openInNewTab";
import { calculateLineItemsTotal } from "../../utils";
import { getLineItemsTotals } from "../../utils/getLineItemsTotals";
import {
  convertToLineItemsUpdateRequest,
  generateDueDate
} from "../../utils/invoicesFormUtils";
import { InvoiceFooter } from "../InvoiceFooter/InvoiceFooter";
import { InvoicesFormValues } from "../InvoicesForm/InvoicesForm";
import { Totals } from "../Totals/Totals";
import classes from "./InvoicePreview.module.scss";

type Props = {
  data: InvoicesFormValues;
  onClose: () => void;
  invoiceLink?: string;
  subject: string;
  onSubjectChange: (newSubject: string) => void;
};

export const InvoicePreview: React.FC<Props> = ({
  data,
  onClose,
  invoiceLink,
  subject,
  onSubjectChange
}) => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const memberQuery = useMemberProfile(userId);
  const queryMemberClients = useMemberClientsQuery();

  const dueDate = generateDueDate({
    formValues: { due: data.due, customDueDate: data.customDueDate },
    sendDate: data.send.type === "immediately" ? new Date() : data.send.date
  });

  const lineItems = convertToLineItemsUpdateRequest(data.lineItems).filter(
    i => i
  ) as any as IInvoiceLineItem[];

  const total = calculateLineItemsTotal(lineItems);

  const subjectSpanRef = useRef<HTMLInputElement>(null);

  return (
    <WSSidebar onClose={onClose} className={classes.drawerBase}>
      <WSQueries queries={{ memberQuery, userQuery, queryMemberClients }}>
        {({
          memberQuery: { data: member },
          userQuery: { data: user },
          queryMemberClients: { data: memberClients }
        }) => {
          const companyName =
            member.profile.company?.name ||
            `${user.profile.firstName} ${user.profile.lastName}`;

          return (
            <>
              <WSFlexBox
                justify="space-between"
                alignItems="center"
                px="2XL"
                py="M"
              >
                <WSText.Heading4>Preview invoice</WSText.Heading4>
              </WSFlexBox>

              <WSDivider />

              <WSElement px="2XL" py="M"></WSElement>
              <WSDivider />
              <WSElement px="2XL" py="M">
                <WSText>
                  From: {companyName} (via Wingspan) {"<invoices@wingspan.app>"}
                </WSText>
              </WSElement>
              <WSDivider />
              <WSElement px="2XL" py="M">
                <WSFlexBox wrap="nowrap" justify="space-between">
                  <WSFlexBox.CenterY
                    className={classes.subject}
                    wrap="nowrap"
                    mr="XL"
                  >
                    <WSText mr="XS">Subject:</WSText>
                    <input
                      className={classes.subjectInput}
                      ref={subjectSpanRef}
                      name="subject"
                      value={subject}
                      onChange={event => {
                        onSubjectChange(event.target.value);
                      }}
                    />
                  </WSFlexBox.CenterY>
                  <WSIcon
                    block
                    name="edit"
                    color="gray400"
                    onClick={() => {
                      if (subjectSpanRef.current) {
                        subjectSpanRef.current.focus();
                      }
                    }}
                  />
                </WSFlexBox>
              </WSElement>
              <WSDivider />

              <div className={classes.content}>
                <section className={classes.section}>
                  <WSFlexBox.Center>
                    {member.profile.logoFileId ? (
                      <CompanyLogo id={member.profile.logoFileId} />
                    ) : (
                      <WSText.Heading5>{companyName}</WSText.Heading5>
                    )}
                  </WSFlexBox.Center>
                </section>

                <header className={classes.header}>
                  <WSText color="blue400" mb="XL">
                    <b>Payment requested</b>
                  </WSText>

                  <WSText.Display2 mb="2XL">
                    {total > 0 ? formatMoney(total) : "$—.——"}
                  </WSText.Display2>

                  <WSText mb="XL">Due {toWSDateString(dueDate)}</WSText>

                  {invoiceLink ? (
                    <WSButton
                      name="previewPayInvoice"
                      onClick={() => {
                        openInNewTab(invoiceLink);
                      }}
                    >
                      Pay invoice
                    </WSButton>
                  ) : (
                    <Tooltip
                      trigger={
                        <WSButton
                          name="previewPayInvoice"
                          disabled
                          style={{ pointerEvents: "none" }}
                        >
                          Pay invoice
                        </WSButton>
                      }
                    >
                      Link inactive until invoice has been sent.
                    </Tooltip>
                  )}
                </header>

                {data.other.notes && (
                  <section className={classes.section}>
                    <WSText
                      dangerouslySetInnerHTML={{
                        __html: data.other.notes.replace(/\n/g, "<br />")
                      }}
                    />
                  </section>
                )}

                <section className={classes.section}>
                  <WSElement mb="L">
                    <WSText.ParagraphSm weight="medium" mb="M">
                      {companyName} +{" "}
                    </WSText.ParagraphSm>
                    <WSText.ParagraphSm>Invoice # Pending</WSText.ParagraphSm>
                    <WSText.ParagraphSm>
                      Sent {toWSDateString(new Date())}
                    </WSText.ParagraphSm>
                  </WSElement>

                  <WSElement>
                    <WSText.ParagraphSm color="gray500">
                      Client info
                    </WSText.ParagraphSm>
                  </WSElement>
                </section>

                <section className={classes.section}>
                  <Totals
                    sections={[
                      getLineItemsTotals(lineItems),
                      {
                        items: [["Total", formatMoney(total)]]
                      }
                    ]}
                  />
                </section>
                {data.includesLateFee && (
                  <section className={classes.section}>
                    <WSText className={classes.label}>Late fees</WSText>
                    <WSText className={classes.text}>
                      Additional{" "}
                      {data.lateFee.type === "percent"
                        ? `${data.lateFee.amount}% of invoice total`
                        : formatMoney(data.lateFee.amount || 0)}{" "}
                      after every {data.lateFee.every}{" "}
                      {data.lateFee.interval.toLowerCase()}(s) a payment is
                      overdue.
                    </WSText>
                  </section>
                )}

                {data.other.attachments &&
                  data.other.attachments.length > 0 && (
                    <section className={classes.section}>
                      <WSText className={classes.label}>Attachments</WSText>
                      {data.other.attachments.map(attachment =>
                        attachment.fileId && !attachment.remove ? (
                          <PublicAttachmentDownloadLink
                            key={attachment.fileId}
                            id={attachment.fileId}
                            mb="M"
                          />
                        ) : null
                      )}
                    </section>
                  )}

                <footer className={classes.footer}>
                  <InvoiceFooter
                    data={{
                      name: companyName,
                      email: user.email,
                      address: member.profile.address
                    }}
                  />
                </footer>
              </div>
            </>
          );
        }}
      </WSQueries>
    </WSSidebar>
  );
};
