import {
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { SubcategoriesMap } from "@wingspanhq/bookkeeping/dist/lib/mappings/wsCategorySubcategories.map";

export enum PseudoCategory {
  EXPENSE_REFUND = "Expense Refund",
  EXPENSE = "Expense",
  INCOME_REFUND = "Income Refund",
  INCOME = "Income",
  IGNORED = "Ignored"
}

export type CategoryItemValue = {
  wsCategory?: WSCategory;
  type?: TransactionType;
  subcategory?: string;
  pseudoCategory?: PseudoCategory;
  isCustom?: boolean;
};

export type CategoryItem = {
  value: CategoryItemValue;
  children?: CategoryItem[];
  name: string;
};

export const getSubCategories = (
  wsCategory: keyof typeof SubcategoriesMap,
  type: TransactionType,
  pseudoCategory?: PseudoCategory
) => {
  const subCategories = SubcategoriesMap[wsCategory];

  if (!subCategories) {
    return [];
  }

  return Object.values<string>(subCategories as any).map(subcategory => ({
    value: { wsCategory, subcategory, type, pseudoCategory },
    name: subcategory
  })) as any as CategoryItem[];
};

export const toItemsWithPseudoCategory = (
  categories: WSCategory[],
  type: TransactionType,
  pseudoCategory: PseudoCategory
) =>
  categories.map(
    wsCategory =>
      ({
        value: { wsCategory, type, pseudoCategory },
        name: wsCategory
      } as CategoryItem)
  );

export const toItems = (categories: WSCategory[], type: TransactionType) =>
  categories.map(
    wsCategory =>
      ({
        value: { wsCategory, type },
        children: getSubCategories(
          wsCategory as keyof typeof SubcategoriesMap,
          type
        ),
        name: wsCategory
      } as CategoryItem)
  );

export const OtherBusinessExpenses: CategoryItem[] = [
  {
    value: {
      wsCategory: WSCategory.CarExpenses,
      type: TransactionType.Expense
    },
    children: [
      ...getSubCategories(WSCategory.CarExpenses, TransactionType.Expense),
      ...getSubCategories(
        WSCategory.VehiclesMachineryEquipment,
        TransactionType.Expense
      )
    ],
    name: WSCategory.CarExpenses
  },
  ...toItems(
    [
      WSCategory.AdvertisingMarketing,
      WSCategory.Education,
      WSCategory.ContractorPayment,
      WSCategory.LegalProfessionalService,
      WSCategory.BusinessLicense,
      WSCategory.PhoneBill,
      WSCategory.BusinessInsurance,
      WSCategory.Gift,
      WSCategory.Entertainment,
      WSCategory.OfficeRent,
      WSCategory.Commission,
      WSCategory.Payroll,
      WSCategory.BankFee,
      WSCategory.Interest,
      WSCategory.Taxes
    ],
    TransactionType.Expense
  )
];

export const RefundExpenses: CategoryItem[] = [
  ...toItemsWithPseudoCategory(
    [
      WSCategory.Supplies,
      WSCategory.Meal,
      WSCategory.Software,
      WSCategory.HomeExpenses,
      WSCategory.TravelTransport,
      WSCategory.CarExpenses,
      WSCategory.VehiclesMachineryEquipment,
      WSCategory.AdvertisingMarketing,
      WSCategory.Education,
      WSCategory.ContractorPayment,
      WSCategory.LegalProfessionalService,
      WSCategory.BusinessLicense,
      WSCategory.PhoneBill,
      WSCategory.BusinessInsurance,
      WSCategory.Gift,
      WSCategory.Entertainment,
      WSCategory.OfficeRent,
      WSCategory.Commission,
      WSCategory.Payroll,
      WSCategory.BankFee,
      WSCategory.Interest,
      WSCategory.Taxes
    ],
    TransactionType.Expense,
    PseudoCategory.EXPENSE_REFUND
  )
];

export const InflowIncome: CategoryItem[] = [
  ...toItems([WSCategory.Income], TransactionType.Income),
  {
    value: {
      pseudoCategory: PseudoCategory.INCOME_REFUND,
      type: TransactionType.Income,
      wsCategory: WSCategory.Income
    },
    name: PseudoCategory.INCOME_REFUND
  }
];

export const InflowOther: CategoryItem[] = [
  {
    value: { pseudoCategory: PseudoCategory.EXPENSE },
    name: PseudoCategory.EXPENSE
  },
  ...toItems([WSCategory.Transfer], TransactionType.Transfer)
];

export const OutflowBusinessExpenses: CategoryItem[] = [
  ...toItems(
    [
      WSCategory.Supplies,
      WSCategory.Meal,
      WSCategory.Software,
      WSCategory.HomeExpenses,
      WSCategory.TravelTransport
    ],
    TransactionType.Expense
  ),
  {
    value: { wsCategory: WSCategory.Other, type: TransactionType.Expense },
    children: OtherBusinessExpenses,
    name: "Other Business Expense"
  }
];

export const OutflowOther: CategoryItem[] = [
  {
    value: {
      wsCategory: WSCategory.OtherDeductions,
      type: TransactionType.Expense
    },
    children: getSubCategories(
      WSCategory.OtherDeductions,
      TransactionType.Expense
    ),
    name: "Other Deductible Expenses"
  },
  {
    value: {
      pseudoCategory: PseudoCategory.EXPENSE_REFUND,
      type: TransactionType.Expense
    },
    name: PseudoCategory.EXPENSE_REFUND,
    children: RefundExpenses
  },
  {
    value: { wsCategory: WSCategory.Transfer, type: TransactionType.Transfer },
    children: getSubCategories(WSCategory.Transfer, TransactionType.Transfer),
    name: "Internal Transfer"
  },
  {
    value: {
      pseudoCategory: PseudoCategory.INCOME,
      type: TransactionType.Income
    },
    name: PseudoCategory.INCOME
  }
];

export const AllBusinessExpenses: CategoryItem[] = [
  ...toItems(
    [
      WSCategory.Supplies,
      WSCategory.Meal,
      WSCategory.Software,
      WSCategory.HomeExpenses,
      WSCategory.TravelTransport
    ],
    TransactionType.Expense
  ),
  {
    value: { wsCategory: WSCategory.Other, type: TransactionType.Expense },
    children: OtherBusinessExpenses,
    name: "Other Business Expense"
  }
];

export const AllOther: CategoryItem[] = [
  {
    value: {
      wsCategory: WSCategory.OtherDeductions,
      type: TransactionType.Expense
    },
    children: getSubCategories(
      WSCategory.OtherDeductions,
      TransactionType.Expense
    ),
    name: "Other Deductible Expenses"
  },
  {
    value: {
      pseudoCategory: PseudoCategory.EXPENSE_REFUND,
      type: TransactionType.Expense
    },
    name: PseudoCategory.EXPENSE_REFUND,
    children: RefundExpenses
  },
  ...toItems([WSCategory.Income], TransactionType.Income),
  {
    value: {
      pseudoCategory: PseudoCategory.INCOME_REFUND,
      type: TransactionType.Income,
      wsCategory: WSCategory.Income
    },
    name: PseudoCategory.INCOME_REFUND
  },
  {
    value: {
      wsCategory: WSCategory.Transfer,
      type: TransactionType.Transfer,
      pseudoCategory: PseudoCategory.IGNORED
    },
    children: getSubCategories(
      WSCategory.Transfer,
      TransactionType.Transfer,
      PseudoCategory.IGNORED
    ),
    name: "Internal Transfer"
  }
];

export type CategorySections = { title: string; categories: CategoryItem[] }[];

export const INFLOW_SECTIONS = [
  {
    title: "Income",
    categories: InflowIncome
  },
  {
    title: "Other",
    categories: InflowOther
  }
];

export const OUTFLOW_SECTIONS = [
  {
    title: "Business Expenses",
    categories: OutflowBusinessExpenses
  },
  {
    title: "Other",
    categories: OutflowOther
  }
];

export const ALL_SECTIONS = [
  {
    title: "Business Expenses",
    categories: AllBusinessExpenses
  },
  {
    title: "Other",
    categories: AllOther
  }
];
