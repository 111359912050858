import {
  WSButton,
  WSButtons,
  WSElement,
  WSFlexBox,
  WSForm,
  WSInputText,
  WSText
} from "@wingspanhq/fe-component-library";
import { useFieldArray, useFormContext } from "react-hook-form";

import styles from "./styles.module.scss";
import { useMemo } from "react";

export interface IPredefinedLineItem {
  description: string;
}

export interface PredefinedLineItemsModalProps {
  predefinedLineItemsValue: IPredefinedLineItem[];
  onSubmit: (data: { predefinedLineItemsValue: { value: string }[] }) => void;
  onClose: () => void;
}

const NestedForm: React.FC = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "predefinedLineItemsValue"
  });

  return (
    <WSElement key="1">
      {fields.map((field, index) => {
        return (
          <WSFlexBox
            key={field.id}
            wrap="nowrap"
            alignItems="flex-end"
            mb="M"
            className={styles.lineItemField}
          >
            <WSForm.Field
              defaultValue={field.value}
              name={`predefinedLineItemsValue[${index}].value`}
              label={`Option ${index + 1}`}
              component={WSInputText}
              componentProps={{
                placeholder: "Line item description"
              }}
            />
            <WSButton.Link
              ml="M"
              type="button"
              icon="minus-circle"
              onClick={() => {
                remove(index);
              }}
            />
          </WSFlexBox>
        );
      })}
      <WSButton.Link
        icon="plus-circle"
        onClick={() => {
          append({ text: "" }, true);
        }}
        mb="S"
        type="button"
      >
        Add line item description
      </WSButton.Link>
    </WSElement>
  );
};

export const PredefinedLineItemsModal: React.FC<
  PredefinedLineItemsModalProps
> = ({ predefinedLineItemsValue = [], onSubmit, onClose }) => {
  const defaultValues = useMemo(
    () => ({
      predefinedLineItemsValue: predefinedLineItemsValue?.length
        ? predefinedLineItemsValue.map(li => ({
            value: li.description
          }))
        : [{ value: "" }, { value: "" }]
    }),
    []
  );

  return (
    <WSForm
      onSubmit={data => {
        console.log("predefinedLineItemsValue onSubmit > ", data);
        onSubmit(data as any);
        onClose();
      }}
      defaultValues={defaultValues}
    >
      <NestedForm />
      <WSText.ParagraphXs color="gray400" mb="M">
        Pre-defined line item description a contractor can select to include on
        a payable (invoice)
      </WSText.ParagraphXs>

      <WSButtons p="S" forceFullWidth wrapped={false}>
        <WSButton.Primary size="S" type="submit">
          Save
        </WSButton.Primary>
        <WSButton.Tertiary size="S" onClick={onClose}>
          Cancel
        </WSButton.Tertiary>
      </WSButtons>
    </WSForm>
  );
};
