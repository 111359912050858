import {
  WSCard,
  WSDataItemProps,
  WSGrid,
  WSPillProps
} from "@wingspanhq/fe-component-library";
import { WSCardHeaderProps } from "@wingspanhq/fe-component-library/dist/lib/components/WSCard/WSCardHeader";
import { useMemo } from "react";
import { DataItem } from "./DataItem";

type Props = {
  title?: string;
  onEdit?: () => void;
  data: (WSDataItemProps | null)[];
  pill?: WSPillProps;
  header?: WSCardHeaderProps;
};

export const DataView: React.FC<Props> = props => {
  const header = useMemo(() => {
    if (props.header) {
      return props.header;
    }

    if (!props.title && !props.onEdit && !props.pill) {
      return;
    }

    if (props.onEdit || props.pill) {
      return {
        label: props.title,
        value: {
          pill: props.pill,
          action: props.onEdit
            ? {
                label: "Edit",
                onClick: props.onEdit
              }
            : undefined
        }
      };
    }

    return props.title;
  }, [props.header, props.onEdit, props.pill, props.title]);

  return (
    <WSCard divider header={header}>
      <WSGrid>
        {props.data.filter(Boolean).map(
          (item, colIndex) =>
            item && (
              <WSGrid.Item
                key={String(colIndex) + item.label}
                span={{ m: "6" }}
              >
                <DataItem {...item} />
              </WSGrid.Item>
            )
        )}
      </WSGrid>
    </WSCard>
  );
};
