import { useRouteMatch } from "react-router-dom";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateActivity } from "../../../query/users/mutations";
import { usePayrollFundingMethodsManagedByOrganization } from "../../../userFeatures/usePayrollFundingMethodsManagedByOrganization";
import { FlowAddFundingMethod } from "../../Settings/components/funding/FlowAddFundingMethod";
import { ManagedFundingMethod } from "../components/ManagedFundingMethod";
import { OnboardingStep } from "../types";

export const PayrollFundingMethod: React.FC<OnboardingStep> = ({ onNext }) => {
  const match = useRouteMatch();
  const isManagedByOrg = usePayrollFundingMethodsManagedByOrganization();
  const userId = useUserId();
  const [updateActivity] = useUpdateActivity(userId, {
    onSuccess: onNext
  });

  const handleSuccess = () => {
    updateActivity({
      events: {
        onboardingFundingMethodCompletedAt: new Date()
      }
    });
  };

  if (isManagedByOrg) {
    return <ManagedFundingMethod onContinue={handleSuccess} />;
  }

  return <FlowAddFundingMethod basePath={match.path} onNext={handleSuccess} />;
};
