import { WSInfoBox, WSList } from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { useQueryVerifications } from "../../../../../query/onboarding/queries/useQueryVerifications";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { DataView } from "../../../../../shared/components/DataView";
import { DataViewTaxpayerInformation } from "../../../components/DataViewTaxpayerInformation";
import { useModalEditAddress } from "../components/FormEditAddress";
import { useModalEditBirthday } from "../components/FormEditBirthday";
import { useModalEditPreferredName } from "../components/FormEditPreferredName";
import { useModalEditWebsite } from "../components/FormEditWebsite";

type Props = {};

export const RoutePersonalInformation: React.FC<Props> = () => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity();
  const queryVerifications = useQueryVerifications();

  const modalEditPreferredName = useModalEditPreferredName();
  const modalEditBirthday = useModalEditBirthday();
  const modalEditWebsite = useModalEditWebsite();
  const modalEditAddress = useModalEditAddress();

  if (!queryUser.data || !queryMember.data) {
    return null;
  }

  const user = queryUser.data;
  const member = queryMember.data;

  return (
    <WSList gap="2XL">
      <WSInfoBox title="Please ensure your information is up to date and accurate">
        <ol>
          <li>Click edit to edit your personal information.</li>
          <li>
            Your taxpayer information cannot be edited once your account has
            been verified (green checkmark).
          </li>
          <li>To make changes to verified fields, click “Request update”.</li>
        </ol>
      </WSInfoBox>

      <DataViewTaxpayerInformation
        monitoredEntity={queryMonitoredEntity.data}
        user={user}
        member={member}
        verificationStatus={queryVerifications.data?.tax}
      />

      <DataView
        title="Personal information"
        data={[
          {
            label: {
              text: "Preferred name",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditPreferredName.open();
                }
              }
            },
            value: user.profile.preferredName || undefined
          },
          {
            label: {
              text: "Birthday",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditBirthday.open();
                }
              }
            },
            value: user.profile.dob,
            fsExclude: true,
            formatterOptions: {
              dateType: "monthDayYear"
            }
          },
          {
            label: {
              text: "Business website",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditWebsite.open();
                }
              }
            },
            value: member.profile.company?.website || "None"
          },
          {
            label: {
              text: "Personal address",
              actionPosition: "left",
              action: {
                label: "Edit",
                onClick: () => {
                  modalEditAddress.open();
                }
              }
            },
            value: member.profile.address
              ? [
                  member.profile.address.addressLine1,
                  member.profile.address.addressLine2,
                  [
                    member.profile.address.city,
                    member.profile.address.state,
                    member.profile.address.postalCode
                  ].join(" ")
                ]
                  .filter(Boolean)
                  .join("\n")
              : undefined,
            fsExclude: true
          },
          {
            label: "Contact info",
            value: `${user.email}\n${user.phone.number}`
          }
        ]}
      />
    </WSList>
  );
};
