import { IEngagement } from "@wingspanhq/payments/dist/interfaces";
import { servicePayments } from "../servicePayments";

export const getEngagement = async (
  engagementId: string,
  xWingspanUser?: string
): Promise<IEngagement> => {
  const response = await servicePayments.get(`/engagement/${engagementId}`, {
    ...(xWingspanUser
      ? {
          headers: {
            "x-wingspan-user": xWingspanUser
          }
        }
      : {})
  });
  return response.data;
};
