import {
  useIsMobile,
  WSButton,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { ISubscription } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { useBrowserPageTitle } from "../../components/BrowserPageTitle/BrowserPageTitle";
import { Tabs } from "../../components/Tabs";
import { useBenefitsEnrollment } from "../../query/benefits";
import { useUserId } from "../../query/hooks/helpers";
import { useSubscriptionV3Query } from "../../query/subscriptions/queries";
import { WSQueries } from "../../query/WSQuery";
import { isPremiumSubscription } from "../../Settings/utils/subscriptionUtils";
import { openInNewTab } from "../../utils/openInNewTab";
import { BenefitDetailsFaqs } from "../components/BenefitDetailsV2/BenefitDetailsFaqs";
import { BenefitDetailsTab } from "../components/BenefitDetailsV2/BenefitDetailsTab";
import { BenefitDetailsTransitionModal } from "../components/BenefitDetailsV2/BenefitDetailsTransitionModal";
import { BenefitDetailsWidget } from "../components/BenefitDetailsV2/BenefitDetailsWidget";
import { BenefitsPending } from "../modals/BenefitsPending";
import { getTabLinkPathForBenefit } from "../utils";
import { BenefitData, IBenefitCTAWidget, IBenefitTab } from "./BenefitsV3";

export const BenefitDetailsV2: React.FC<
  BenefitData & {
    isFree?: boolean;
  }
> = props => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const memberId = useUserId();
  const qSubscription = useSubscriptionV3Query(memberId);

  const qEnrollment = useBenefitsEnrollment(memberId);

  const location = useLocation();

  useBrowserPageTitle(`Benefits - ${props.title}`);
  const isGettingStartedTab = () => {
    return (
      isMobile &&
      location.pathname.startsWith("/member/benefits/my-benefits") &&
      location.pathname.endsWith("/getting-started")
    );
  };

  return (
    <WSQueries
      queries={{
        enrollmentData: qEnrollment
      }}
    >
      {({ enrollmentData }) => {
        const subscription = qSubscription.data as ISubscription;
        const isPremium = isPremiumSubscription(subscription);
        const memberNumber =
          enrollmentData.data?.wingspanBenefits?.memberNumber;

        const { detailsJson: benefitData } = props;
        const tabLinks = [];
        if (benefitData && benefitData?.tabs.length > 0) {
          if (isMobile) {
            tabLinks.push({
              path: getTabLinkPathForBenefit(
                props.title,
                "Getting started",
                "my-benefits"
              ),
              label: "Getting started"
            });
          }
          benefitData.tabs.forEach((tab: IBenefitTab) => {
            tabLinks.push({
              path: getTabLinkPathForBenefit(
                props.title,
                tab?.title as string,
                "my-benefits"
              ),
              label: tab.title
            });
          });
        }
        const ctaWidgetData = {
          ...benefitData?.gettingStarted,
          memberNumber,
          cost: props.cost,
          status: props.status,
          isPremium
        };
        return (
          <WSPage
            title={
              <WSFlexBox justify={isMobile ? "space-between" : "flex-start"}>
                <WSElement as="h1" mr="M" mb="M">
                  {benefitData?.title}
                </WSElement>
              </WSFlexBox>
            }
            breadcrumb={{
              label: "Back",
              icon: "arrow-left",
              onClick() {
                history.push("/member/benefits/my-benefits");
              }
            }}
          >
            <BenefitsPending />
            <BenefitDetailsTransitionModal />
            <WSGrid data-testid="benefitContent">
              <WSGrid.Item span={{ s: "6", m: "8" }}>
                {benefitData?.subtitle ? (
                  <WSText.Heading5>{benefitData.subtitle}</WSText.Heading5>
                ) : null}
              </WSGrid.Item>
            </WSGrid>
            <WSElement my="XL">
              {tabLinks.length > 1 ? (
                <>
                  <Tabs tabs={tabLinks} />
                  <Switch>
                    {tabLinks.map(({ path, title }: any, index: number) => (
                      <Route
                        key={path}
                        path={path}
                        exact
                        render={() =>
                          isMobile && index === 0 ? (
                            <BenefitDetailsWidget
                              widgetData={ctaWidgetData as IBenefitCTAWidget}
                              mt="2XL"
                            />
                          ) : (
                            <BenefitDetailsTab
                              widgetData={ctaWidgetData as IBenefitCTAWidget}
                              tab={
                                benefitData?.tabs.find(
                                  (tab: IBenefitTab) => tab.title === title
                                ) as IBenefitTab
                              }
                            />
                          )
                        }
                      />
                    ))}
                    <Redirect from="*" to={tabLinks[0].path} />
                  </Switch>
                </>
              ) : (
                <BenefitDetailsTab
                  widgetData={ctaWidgetData as IBenefitCTAWidget}
                  noTabs
                  tab={benefitData?.tabs[0] as IBenefitTab}
                />
              )}
            </WSElement>
            {isGettingStartedTab() ? null : (
              <WSGrid>
                <WSGrid.Item span={{ s: "6", m: "8" }}>
                  <React.Fragment>
                    <BenefitDetailsFaqs faqs={benefitData?.faqs || []} />
                    {props.linkMore ? (
                      <WSButton.Link
                        mt="M"
                        onClick={() => openInNewTab(props.linkMore as string)}
                        type="button"
                      >
                        Learn more about {props.detailsJson?.title}
                      </WSButton.Link>
                    ) : null}
                  </React.Fragment>
                </WSGrid.Item>
              </WSGrid>
            )}
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
