import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import { selectorPayerTaxFormIsBusiness } from "./selectorPayerTaxFormIsBusiness";
import { selectorPayerTaxFormIsIndividual } from "./selectorPayerTaxFormIsIndividual";

export const selectorPayerTaxFormDefaultStatus = (
  data: IPayerTaxFormResponse
) => {
  return selectorPayerTaxFormIsBusiness(data)
    ? data.tinVerification?.ein?.status
    : selectorPayerTaxFormIsIndividual(data)
    ? data.tinVerification?.ssn?.status
    : data.data.w9Info?.einLastFour && data.tinVerification?.ein?.status
    ? data.tinVerification?.ein?.status
    : data.data.w9Info?.ssnLastFour && data.tinVerification?.ssn?.status
    ? data.tinVerification?.ssn?.status
    : data.tinVerification?.ein?.status || data.tinVerification?.ssn?.status;
};
