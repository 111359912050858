import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { Main1099LayoutModule } from "../../../components/Main1099Layout/Main1099Layout.module";
import { WSPanel, WSText } from "@wingspanhq/fe-component-library";
import { openInNewTab } from "../../../../../utils/openInNewTab";
import { usePayeeTaxForm } from "../../../../../query/taxForm/queries/usePayeeTaxForm";
import { useRouteMatch } from "react-router-dom";
import { WSQueries } from "../../../../../query/WSQuery";
import { selectorPayeeTaxFormPayerCompanyName } from "../../../selectors/selectorPayeeTaxFormPayerCompanyName";
import { selectorPayeeTaxFormPayerContact } from "../../../selectors/selectorPayeeTaxFormPayerContact";
import { useWSStore } from "../../../../../store";

export const RouteVerifyYourIdentityLocked: React.FC<
  SetupRouteComponentProps
> = ({ onBack, onNext }) => {
  const match = useRouteMatch<{ taxFormId: string }>();
  const queryTaxForm = usePayeeTaxForm(match.params.taxFormId);
  const wsStore = useWSStore();

  return (
    <Main1099LayoutModule title="Your account has been locked">
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData }) => {
          const companyName =
            selectorPayeeTaxFormPayerCompanyName(queryTaxFormData) ||
            wsStore.signUp1099?.companyName ||
            "the company who paid you";
          const [payerName, supportEmail] =
            selectorPayeeTaxFormPayerContact(queryTaxFormData);

          return (
            <WSPanel>
              <WSText.Heading5>Your account has been locked</WSText.Heading5>
              <WSText.ParagraphSm mt="M" color="gray500">
                Your access to tax preferences and forms has been locked due to
                multiple invalid verification attempts
              </WSText.ParagraphSm>
              <WSText.ParagraphSm mt="XS" mb="M" color="gray500">
                Please reach out to{" "}
                <a
                  href={`mailto:${supportEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {payerName || "the payer"}
                </a>{" "}
                at {companyName} for assistance.
              </WSText.ParagraphSm>
              <WSText.ParagraphSm
                inline
                color="gray500"
                underline
                onClick={() => openInNewTab("https://www.wingspan.app/support")}
              >
                Need more help?
              </WSText.ParagraphSm>
            </WSPanel>
          );
        }}
      </WSQueries>
    </Main1099LayoutModule>
  );
};
