import {
  WSActions,
  WSElement,
  WSEmptyState,
  WSInfoBox,
  WSList,
  WSLoader,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { FundingSourceType } from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../query/hooks/helpers";
import { usePayrollSettings } from "../../../query/payments/queries";
import { useClientQuery } from "../../../query/users/queries";
import { BankCard } from "../../../shared/components/BankCard";
import { MethodWallet } from "../../../shared/components/MethodWallet";
import { useUserOrganizationName } from "../../../userFeatures/useUserOrganizationName";
import { BankAccount } from "../../PaymentMethods/components/BankAccount";
import { ContainerAccount } from "../../PaymentMethods/components/ContainerAccount";

type Props = {
  onContinue?: () => void;
};

export const ManagedFundingMethod: React.FC<Props> = ({ onContinue }) => {
  const userId = useUserId();
  const organizationName = useUserOrganizationName();
  const queryPayrollSettings = usePayrollSettings(userId);
  const queryClient = useClientQuery(userId);

  let fundingSourceId, fundingSourceType;

  if (queryPayrollSettings.data?.fundingSource) {
    fundingSourceId = queryPayrollSettings.data?.fundingSource?.fundingSourceId;
    fundingSourceType =
      queryPayrollSettings.data?.fundingSource?.fundingSourceType;
  } else {
    if (queryClient.data?.profile.defaultPaymentMethod?.accountId) {
      fundingSourceId =
        queryClient.data?.profile.defaultPaymentMethod.accountId;
      fundingSourceType = FundingSourceType.Account;
    } else if (
      queryClient.data?.profile.defaultPaymentMethod?.paymentMethodId
    ) {
      fundingSourceId =
        queryClient.data?.profile.defaultPaymentMethod.paymentMethodId;
      fundingSourceType = FundingSourceType.PaymentCard;
    }
  }

  return (
    <WSList gap="2XL">
      <WSPanel>
        <WSList gap="2XL">
          <WSElement>
            <WSSectionToolbar title="Add payroll funding method" />
            <WSText.ParagraphSm color="gray500">
              Add a payroll funding method to fund your payrolls and start
              sending payments
            </WSText.ParagraphSm>
          </WSElement>

          {queryPayrollSettings.isLoading ? (
            <WSLoader.Spinner size="XS" />
          ) : fundingSourceType === FundingSourceType.Account &&
            fundingSourceId ? (
            <WSPanel>
              <ContainerAccount accountId={fundingSourceId}>
                {account => <BankAccount account={account} />}
              </ContainerAccount>
            </WSPanel>
          ) : fundingSourceType === FundingSourceType.InternalAccount &&
            fundingSourceId ? (
            <WSPanel>
              <MethodWallet />
            </WSPanel>
          ) : fundingSourceType === FundingSourceType.PaymentCard &&
            fundingSourceId ? (
            <WSPanel>
              <BankCard type="credit" cardId={fundingSourceId} />
            </WSPanel>
          ) : (
            <WSEmptyState
              hasBorder
              orientation="horizontal"
              type="card"
              title="No payroll funding method yet"
              description="Once a payroll funding method is added, it will appear here."
            />
          )}

          <WSInfoBox>
            Payroll funding method is managed by {organizationName} - contact{" "}
            {organizationName} for support.
          </WSInfoBox>
        </WSList>
      </WSPanel>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            onClick: onContinue
          }
        ]}
      />
    </WSList>
  );
};
