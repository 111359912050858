import { WSText, WSInfoBox, WSCard } from "@wingspanhq/fe-component-library";
import styles from "./styles.module.scss";
import cn from "classnames";

export const CommonUseCasesPanel = () => {
  return (
    <WSCard>
      <WSText.ParagraphSm weight="medium" mb="L">
        Common use cases
      </WSText.ParagraphSm>
      <WSInfoBox className={cn(styles.list, styles.elem)}>
        <ol>
          <li>
            <WSText.ParagraphSm color="gray600" weight="medium">
              Contractor invites:
            </WSText.ParagraphSm>
          </li>
          <ul>
            <li>
              <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                After adding a contractor, they will be invited by the email
                provided to sign-up for a Wingspan account in order to receive
                payments from you.
              </WSText.ParagraphSm>
            </li>
          </ul>

          <li>
            <WSText.ParagraphSm color="gray600" weight="medium">
              Eligibility:
            </WSText.ParagraphSm>
          </li>
          <ul>
            <li>
              <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                A contractor must complete all their onboarding requirements in
                order to work with you and receive payments through Wingspan. If
                the contractor is assigned an Engagement with its own set of
                additional requirements specified by you, they must complete
                those requirements as well.
              </WSText.ParagraphSm>
            </li>
          </ul>

          <li>
            <WSText.ParagraphSm color="gray600" weight="medium">
              Tax filing (e.g. 1099s):
            </WSText.ParagraphSm>
          </li>
          <ul>
            <li>
              <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                Once your contractor starts receiving payments on Wingspan, that
                information will automatically populate their 1099-NECs (U.S.
                only) when it comes time to generate tax forms.
              </WSText.ParagraphSm>
            </li>
            <li>
              <WSText.ParagraphSm color="gray600" pl="S" mb="L">
                If you've been making payments outside of Wingspan or switched
                to Wingspan mid-year, ensure that you’ve uploaded all
                contractors here and their payments information (through the
                payables page) by the January filing deadline to complete any
                gaps in payment information.
              </WSText.ParagraphSm>
            </li>
          </ul>
        </ol>
      </WSInfoBox>
    </WSCard>
  );
};
