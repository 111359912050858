import { ISharedFileRequestSettingsResponse } from "@wingspanhq/shared-files/dist/lib/interfaces";
import { serviceSharedFiles } from "./serviceSharedFiles";

export const getSharedFileSettings = async (sharedFileSettingsId: string) => {
  const { data } =
    await serviceSharedFiles.get<ISharedFileRequestSettingsResponse>(
      `/settings/${sharedFileSettingsId}`
    );

  return data;
};
