import React from "react";
import {
  WSActions,
  WSDivider,
  WSElement,
  WSElementProps,
  WSPill,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IRequirementResponse,
  RequirementEmbeddedStatus
} from "@wingspanhq/payments/dist/interfaces";
import { ContextItem } from "../../types";
import { getConfirmModalConfig } from "./getConfirmModalConfig";
import { useChangeRequirementStatuses } from "../../../../query/payee/mutations/useChangeRequirementStatuses";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";

export interface IRequirementWithEngagementIdsResponse
  extends IRequirementResponse {
  relatedEngagementRequirementIds: {
    engagementId: string;
    relatedRequirementId: string;
  }[];
}

export interface OverwriteStatusModalProps extends WSElementProps {
  onClose: () => void;
  requirement: IRequirementResponse;
  context: ContextItem;
  status: RequirementEmbeddedStatus | null;
  onSuccess: () => void;
}

export const OverwriteStatusModal: React.FC<OverwriteStatusModalProps> = ({
  onClose,
  requirement,
  context,
  status,
  onSuccess
}) => {
  const [changeRequirementStatus, changeRequirementStatusMeta] =
    useChangeRequirementStatuses();

  const config = getConfirmModalConfig(
    status,
    requirement as IRequirementWithEngagementIdsResponse
  );

  const onSubmit = () => {
    changeRequirementStatus(
      {
        payeeId: context.payeeId || "",
        relatedEngagementRequirementIds: (
          requirement as IRequirementWithEngagementIdsResponse
        ).relatedEngagementRequirementIds,
        requirementId: requirement.requirementId,
        request: { status: status }
      },
      {
        onSuccess
      }
    );
  };

  return (
    <WSElement>
      <WSText.Heading5 mb="S">{config.title}</WSText.Heading5>
      <WSDivider mb="XL" />
      <WSText.ParagraphSm color="gray600" mb="L">
        {config.title === "Overwrite status"
          ? "Overwrite status preview:"
          : "Revert status preview:"}
      </WSText.ParagraphSm>

      <WSPill {...config.pill} mb="XS" ml="M" />
      <WSText.ParagraphXs color="gray400" mb="XL" ml="M">
        {config.secondaryText}
      </WSText.ParagraphXs>
      <WSElement mb="XL" colorBackground="gray50" p="L">
        <WSText.ParagraphSm color="gray600">
          {config.message}
        </WSText.ParagraphSm>
      </WSElement>
      <WSErrorMessage
        mt="XL"
        contextKey="Other"
        error={changeRequirementStatusMeta.isError}
      />
      <WSDivider mb="XL" />
      <WSActions
        mt="L"
        alignment="center"
        buttons={[
          {
            kind: "Secondary",
            label: "Cancel",
            onClick: onClose,
            type: "button",
            fullWidth: true
          },
          {
            kind: "Primary",
            label: config.completeActionBtnText,
            onClick: onSubmit,
            type: "submit",
            fullWidth: true,
            loading: changeRequirementStatusMeta.isLoading
          }
        ]}
      />
    </WSElement>
  );
};
