import {
  useWSModal,
  WSActions,
  WSControl,
  WSInfoBox,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useUpdateActivity } from "../../../query/users/mutations";
import { useUserId } from "../../../query/hooks/helpers";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";

type Props = {
  mailDelivery: boolean;
  onClose: () => void;
};

const DeliveryPreferences: React.FC<Props> = ({ mailDelivery, onClose }) => {
  const [mail, setMail] = React.useState(mailDelivery);
  const userId = useUserId();
  const [updateActivity, updateActivityMeta] = useUpdateActivity(userId);

  return (
    <WSList gap="L">
      <WSInfoBox>
        Electronic delivery (paperless) will be available by default. To receive
        mailed copies, set the delivery preference below.
      </WSInfoBox>
      <WSControl
        chip
        onChange={() => {}}
        value={true}
        label="Electronic delivery"
        type="switch"
        disabled
      />
      <WSControl
        onChange={setMail}
        value={mail}
        chip
        label="Mail me a physical copy (optional)"
        type="switch"
      />
      <WSInfoBox>
        Note: If delivery preference is updated when tax forms have already been
        delivered to you for the year, please contact your payer(s) directly to
        request a mailed copy directly from them.
      </WSInfoBox>
      <WSErrorMessage
        error={updateActivityMeta.error}
        contextKey="NEC1099TaxDocuments"
      />
      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Save",
            kind: "Primary",
            onAsyncClick: async () => {
              if (mailDelivery !== mail) {
                await updateActivity(
                  { events: { electronic1099Consent: !mail } },
                  {
                    onSuccess() {
                      onClose();
                    }
                  }
                );
              } else {
                onClose();
              }
            }
          },
          {
            label: "Cancel",
            kind: "Tertiary",
            onClick: onClose
          }
        ]}
      ></WSActions>
    </WSList>
  );
};

export const useUpdateDeliveryPreferencesModal = () =>
  useWSModal(DeliveryPreferences, {
    title: "Delivery preference",
    size: "S"
  });
