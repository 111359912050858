import {
  WSDivider,
  WSElement,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { ICreditCard } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { BankCardLogo } from "../../shared/components/BankCardLogo";
import { creditCardBrandNameMap } from "../../shared/constants/bankCard";
import { CreditCardTemplate } from "./CreditCardTemplate";
import { WSAccountsListV2Props } from "./WSAccountsListV2.component";
import styles from "./WSAccountsListV2.module.scss";
import { CreditCardBrand } from "./types";

export interface CreditCardBrandTemplateProps
  extends Partial<WSAccountsListV2Props> {
  creditCardBrand: CreditCardBrand;
  customEdit?: (card: ICreditCard) => React.ReactNode;
}

export const CreditCardBrandTemplate: React.FC<
  CreditCardBrandTemplateProps
> = ({ creditCardBrand, defaultPaymentMethod, onEditAccount, customEdit }) => {
  return (
    <WSElement className={styles.institution}>
      {creditCardBrand.name && (
        <WSFlexBox.CenterY mb="M">
          <BankCardLogo.Circle type="credit" brand={creditCardBrand.name} />
          <WSText weight="medium" singleLine ml="M">
            {creditCardBrandNameMap[creditCardBrand.name]}
          </WSText>
        </WSFlexBox.CenterY>
      )}
      <WSDivider />
      {creditCardBrand.cards.map(cc => (
        <WSElement key={`${cc.cardBrand}-${cc.last4}`}>
          <CreditCardTemplate
            defaultPaymentMethod={defaultPaymentMethod}
            creditCard={cc}
            onEditAccount={onEditAccount}
            customEdit={customEdit}
          />
        </WSElement>
      ))}
    </WSElement>
  );
};
