import {
  ISharedFileRequestSettingsCreateRequest,
  ISharedFileRequestSettingsResponse
} from "@wingspanhq/shared-files/dist/lib/interfaces";
import { serviceSharedFiles } from "./serviceSharedFiles";

export const createSharedFileSettings = async (
  request: ISharedFileRequestSettingsCreateRequest
) => {
  const { data } =
    await serviceSharedFiles.post<ISharedFileRequestSettingsResponse>(
      "/settings",
      request
    );

  return data;
};
