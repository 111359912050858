import {
  WSButton,
  WSElement,
  WSElementProps
} from "@wingspanhq/fe-component-library";
import React from "react";
import { useMemberPublicFileSummaryQuery } from "../../query/files/queries";
import { WSQueries } from "../../query/WSQuery";
import { downloadFile } from "../../utils/files";

type PublicAttachmentDownloadLinkProps = {
  id: string;
} & WSElementProps;

export const PublicAttachmentDownloadLink: React.FC<
  PublicAttachmentDownloadLinkProps
> = ({ id, ...elementProps }) => {
  const memberPublicFileSummaryQuery = useMemberPublicFileSummaryQuery(id);

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ memberPublicFileSummaryQuery }}>
        {({
          memberPublicFileSummaryQuery: { data: memberPublicFileSummary }
        }) => (
          <WSButton.Link
            icon="download"
            onClick={async () => {
              downloadFile(
                memberPublicFileSummary.url,
                memberPublicFileSummary.filename
              );
            }}
          >
            {memberPublicFileSummary.filename}
          </WSButton.Link>
        )}
      </WSQueries>
    </WSElement>
  );
};
