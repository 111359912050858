import {
  toWSMoneyString,
  useModalContext,
  WSButton,
  WSButtons,
  WSDivider,
  WSFlexBox,
  WSPanel,
  WSPill,
  WSSidebar,
  WSText
} from "@wingspanhq/fe-component-library";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import { DeductionType } from "@wingspanhq/payments/dist/interfaces/deductions";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import { useCollaboratorDeduction } from "../../../query/payments/queries";
import { goBackOrToParent } from "../../../utils/goToParentRoute";
import { DeductionsHistoryList } from "../../components/DeductionsHistoryList/DeductionsHistoryList";
import { DELETE_DEDUCTION_MODAL } from "./modals/DeleteDeduction";

export const ScheduledDeductionDetails: React.FC<
  RouteComponentProps<{
    deductionId: string;
  }>
> = ({ match, location }) => {
  const history = useHistory();
  const qDeduction = useCollaboratorDeduction(match.params.deductionId);

  const onClose = () => goBackOrToParent(history);

  return (
    <WSSidebar onClose={onClose} header="Scheduled deduction details">
      <WSQueries queries={{ qDeduction }} renderErrors={() => null}>
        {({ qDeduction: { data: deduction } }) => <Inner data={deduction} />}
      </WSQueries>
    </WSSidebar>
  );
};

export const Inner: React.FC<{ data: IDeductionResponse }> = ({ data }) => {
  const history = useHistory();
  const { openModal } = useModalContext();

  const deductedAmount = data.application
    .map(a => a.amountDeducted)
    .reduce((a, b) => a + b, 0);

  const remainingAmount = deductedAmount
    ? data.amount - deductedAmount
    : data.amount;

  return (
    <>
      <WSPanel noBorder mb="XL" data-testid="deductionsPanel">
        <WSFlexBox.CenterY mt="M" mb="XL" alignItems="flex-end">
          {remainingAmount !== data.amount ? (
            <>
              <WSText.Heading5 mr="XS" formatMoney>
                {remainingAmount}
              </WSText.Heading5>
              <WSText.ParagraphSm color="gray400">
                remaining of
                {` ${toWSMoneyString(data.amount)} `}total
              </WSText.ParagraphSm>
            </>
          ) : (
            <WSText.Heading5 formatMoney>{data.amount}</WSText.Heading5>
          )}
        </WSFlexBox.CenterY>
        <WSFlexBox.CenterY justify="space-between" my="M">
          <WSText weight="medium">{data.name}</WSText>
          <WSPill
            icon={data.type === DeductionType.PostPayment ? "after" : "before"}
            text={
              data.type === DeductionType.PostPayment
                ? "Post-payment"
                : "Pre-payment"
            }
          />
        </WSFlexBox.CenterY>
        <WSDivider />
        <WSFlexBox.CenterY justify="space-between" my="M">
          <WSText.ParagraphSm>Total scheduled deduction</WSText.ParagraphSm>
          <WSText.ParagraphSm formatMoney>{data.amount}</WSText.ParagraphSm>
        </WSFlexBox.CenterY>
        <WSDivider />
        <WSFlexBox.CenterY justify="space-between" my="M">
          <WSText.ParagraphSm>Already deducted</WSText.ParagraphSm>
          <WSText.ParagraphSm formatMoney>{deductedAmount}</WSText.ParagraphSm>
        </WSFlexBox.CenterY>

        <WSDivider />
        <WSFlexBox.CenterY justify="space-between" my="M">
          <WSText weight="medium">Remaining balance</WSText>
          <WSText weight="medium" formatMoney>
            {remainingAmount}
          </WSText>
        </WSFlexBox.CenterY>

        <DeductionsHistoryList deduction={data} />

        <WSButtons forceFullWidth mt="XL">
          <WSButton
            destructive
            onAsyncClick={async () =>
              openModal(DELETE_DEDUCTION_MODAL, { deduction: data })
            }
          >
            Delete deduction
          </WSButton>
        </WSButtons>
      </WSPanel>
    </>
  );
};
