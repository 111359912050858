import { WSFilterValue } from "@wingspanhq/fe-component-library";
import QueryString from "qs";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isSerializedDate } from "../../utils/serviceHelper";

export type FiltersValueBase = Partial<Record<string, WSFilterValue>>;

export function useUrlQueryFilters<
  F extends FiltersValueBase = FiltersValueBase
>() {
  const history = useHistory();
  const location = useLocation();

  const filters: F = useMemo(() => {
    return QueryString.parse(location.search.slice(1), {
      // synced with BE limit https://github.com/wingspanHQ/routing/pull/96/files
      arrayLimit: 200,
      decoder: function (str, defaultDecoder, charset) {
        const decodedValue = defaultDecoder(str, defaultDecoder, charset);
        if (isSerializedDate(decodedValue)) {
          return new Date(decodedValue);
        } else {
          return decodedValue;
        }
      }
    }) as any as F;
  }, [location.search]);

  return {
    filters,
    setFilters: (newFilters: F) => {
      history.push({
        search: QueryString.stringify(newFilters)
      });
    }
  };
}
