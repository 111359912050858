import { WSList, WSText } from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import { FlowMEChangeContext } from "..";
import { useUserId } from "../../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../../query/users/queries";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { getProfileDataPerson } from "../../../components/FormPartialPerson/getProfileDataPerson";
import { FormPersonalInformation } from "../../../components/FormPersonalInformation";
import { OnboardingStep } from "../../../types";

export const StepMaterialUpdatePersonalInformation: React.FC<
  OnboardingStep
> = props => {
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();

  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const profileData = useMemo(
    () => ({
      person: getProfileDataPerson(queryUser.data, queryMember.data)
    }),
    [queryMember.data, queryUser.data]
  );

  return (
    <WSList gap="2XL">
      <FormPersonalInformation
        defaultValues={context.personalInformation || profileData}
        onBack={props.onBack}
        onSubmit={data => {
          setContext(prev => ({ ...prev, personalInformation: data }));
          props.onNext?.();
        }}
        country={context.accountType?.country || "US"}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to request a taxpayer
        information update, if you exit or leave without completing this step,
        it will cancel the request.
      </WSText.ParagraphXs>
    </WSList>
  );
};
