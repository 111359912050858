import React from "react";
import {
  toWSMoneyString,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import { getAmountValueAndFormat } from "../../../Bookkeeping/utils";
import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { IPendingTransactionResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";

type AmountColumnProps = {
  transaction?: ITransaction | IPendingTransactionResponse;
};

export const AmountColumn: React.FC<AmountColumnProps> = ({ transaction }) =>
  transaction ? (
    <WSElement>
      <WSText
        align="right"
        {...getAmountValueAndFormat(transaction)}
        {...(transaction?.amount < 0
          ? {
              color: "green500"
            }
          : {})}
      />
      {transaction?.labels?.rewardTransactionId ? (
        <WSText mt="XS" color="green500">
          +{toWSMoneyString(Math.abs(transaction.amount) * 0.01)} cashback
        </WSText>
      ) : null}
    </WSElement>
  ) : (
    <WSText>0</WSText>
  );
