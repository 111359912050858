import { useWSModal, WSForm, WSSelect } from "@wingspanhq/fe-component-library";
import React, { useMemo } from "react";
import { queryCache } from "react-query";
import * as Yup from "yup";
import { US_STATES_OPTIONS } from "../../../../../constants/user";
import { useWSMutation } from "../../../../../query/helpers";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { QUERY_USERS_MEMBER_PROFILE } from "../../../../../query/users/keys";
import { useMemberProfile } from "../../../../../query/users/queries";
import { usersService } from "../../../../../services/users";
import { SELECT_OPTIONS_CANADIAN_PROVINCES } from "../../../../../shared/constants/user";
import { WSServiceError } from "../../../../../utils/serviceHelper";
import { LayoutFormEdit } from "./LayoutFormEdit";

type FormData = { newValue: string };

type Props = {
  onClose: () => void;
};

export const FormEditStateOfInroproration: React.FC<Props> = props => {
  const userId = useUserId();
  const queryMember = useMemberProfile(userId);
  const queryMonitoredEntity = useQueryCustomerEntity();

  const member = queryMember.data;
  const monitoredEntity = queryMonitoredEntity.data;

  const country = useMemo(
    () => monitoredEntity?.country || member?.profile.address?.country || "US",
    [member?.profile.address?.country, monitoredEntity?.country]
  );

  const [mutate, meta] = useWSMutation<void, WSServiceError, FormData>(
    async data => {
      const member = await usersService.member.update(userId, {
        memberId: userId,
        profile: {
          company: {
            stateOfIncorporation: data.newValue
          }
        }
      });

      queryCache.setQueryData(QUERY_USERS_MEMBER_PROFILE, member);
    },
    {
      dependencies: [QUERY_USERS_MEMBER_PROFILE],
      onSuccess: props.onClose
    }
  );

  return (
    <WSForm<FormData>
      onSubmit={mutate}
      defaultValues={{
        newValue: member?.profile.company?.stateOfIncorporation
      }}
      validationSchema={Yup.object({
        newValue: Yup.string().nullable().required("Required")
      })}
    >
      <LayoutFormEdit
        isLoading={meta.isLoading}
        error={meta.error}
        onCancel={props.onClose}
      >
        {country === "US" ? (
          <WSForm.Field
            key="US-state-of-incorporation"
            name="newValue"
            label="State/Territory of incorporation"
            component={WSSelect}
            componentProps={{
              required: true,
              internalSearch: true,
              options: US_STATES_OPTIONS
            }}
          />
        ) : country === "CA" ? (
          <WSForm.Field
            key="CA-state-of-incorporation"
            name="newValue"
            label="Province of incorporation"
            component={WSSelect}
            componentProps={{
              required: true,
              internalSearch: true,
              options: SELECT_OPTIONS_CANADIAN_PROVINCES
            }}
          />
        ) : null}
      </LayoutFormEdit>
    </WSForm>
  );
};

export const useModalEditStateOfInroproration = () =>
  useWSModal(FormEditStateOfInroproration, {
    title: "State/Territory of incorporation",
    size: "S"
  });
