/* eslint-disable no-useless-escape */

import { toWSDateString } from "@wingspanhq/fe-component-library";
import * as Yup from "yup";

export const REGEX_TEXT_ONLY = new RegExp("^[ a-zA-Z\u00C0-\u017F'.\\-]+$");

export const genericStringValidator = Yup.string()
  .trim()
  .max(100, "Can't be more than 100 symbols");

export const textOnlyStringValidator = genericStringValidator.matches(
  REGEX_TEXT_ONLY,
  {
    excludeEmptyString: true,
    message:
      "May contain only characters: a-z, A-Z, ', ., -, inner spaces and accented characters"
  }
);

export const REGEX_NUMBER_SYMBOLS_TEXT = new RegExp(
  "^[ a-zA-Z0-9\u00C0-\u017F'\\#’.,:;()?&*-]+$"
);

export const textNumbersSymbolsStringValidator = genericStringValidator.matches(
  REGEX_NUMBER_SYMBOLS_TEXT,
  {
    excludeEmptyString: true,
    message:
      "May contain only characters: a-z, A-Z, 0-9, #, ', ., &, -, inner spaces and accented characters"
  }
);

export const REGEX_NUMBER_SYMBOLS_TEXT_EXT = new RegExp(
  "^[ a-zA-Z0-9\u00C0-\u017F'\\#’\".,:;()?&*/@!$%^=+_~{}\\[\\]-]+$"
);

export const textNumbersSymbolsExtStringValidator =
  genericStringValidator.matches(REGEX_NUMBER_SYMBOLS_TEXT_EXT, {
    excludeEmptyString: true,
    message:
      "May contain only characters: a-z, A-Z, 0-9, #, ', ., &, - and inner spaces"
  });

export const companyNameValidator = textNumbersSymbolsExtStringValidator;

export const contactCompanyValidator = textNumbersSymbolsExtStringValidator;

export const contactNameValidator = genericStringValidator.matches(
  new RegExp("^[A-Za-z0-9.&\\-\\s]+$"),
  {
    excludeEmptyString: true,
    message:
      "Please ensure the contact name contains only uppercase or lowercase letters (A-Z, a-z), numbers (0-9), ampersands (&), hyphens (-), full stops(.) and spaces."
  }
);

const REGEX_PO_BOX =
  /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/gim;
const testNoPOBox = {
  name: "No PO Box",
  message: "PO Boxes are not supported",
  test: (value?: string) => !REGEX_PO_BOX.test(value || "")
};
const nameValidationRegex = /^[a-zA-Z'.\-\s]+$/;
const nameValidationError = "Must be A-Z characters only";

export const validatorAddressLine1 = textNumbersSymbolsStringValidator
  .required("Address line 1 is required")
  .test(testNoPOBox);

export const validatorAddressLine2 =
  textNumbersSymbolsStringValidator.test(testNoPOBox);

export const validatorAddress = Yup.object().shape({
  addressLine1: validatorAddressLine1,
  addressLine2: validatorAddressLine2,
  city: textOnlyStringValidator.required("Required"),
  state: Yup.string().when("country", {
    is: "US",
    then: Yup.string().required("Required"),
    otherwise: textOnlyStringValidator
  }),
  zipCode: Yup.string().when("country", {
    is: "US",
    then: Yup.string().length(5, "Should be 5 characters").required("Required"),
    otherwise: Yup.string().required("Required")
  })
});

export const validatorFirstName = textOnlyStringValidator;

export const validatorLastName = textOnlyStringValidator;

export const validatorSSN = Yup.string().length(9, "Should be 9 characters");

export const validatorTaxId = Yup.string().test(
  "length9",
  "Should be 9 characters",
  value => (value && value.length > 0 ? value.length === 9 : true)
);

export const validatorZipCode = Yup.string()
  .required("Zip code is required")
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(5, "Must be exactly 5 digits")
  .max(5, "Must be exactly 5 digits");

// Bank account details

export const validatorRoutingNumber = Yup.string()
  .required("Routing number is required")
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(9, "Routing number must be exactly 9 digits")
  .max(9, "Routing number must be exactly 9 digits");

export const validatorDOB = Yup.date()
  .min(
    new Date(new Date().getFullYear() - 100, 0, 0),
    "Invalid date (too far in the past)"
  )
  .max(
    new Date(new Date().getFullYear() - 16, 0, 0),
    "All users must be at least 16 years old"
  );

declare module "yup" {
  interface DateSchema {
    isTodayAndInFuture(): Yup.DateSchema;

    isAfter(key: string): Yup.DateSchema;
  }

  interface ArraySchema<T> {
    uniqueByProperty(message?: string, path?: string): ArraySchema<T>;

    notEqualToField(
      fieldName: string,
      message?: string,
      path?: string
    ): ArraySchema<T>;
  }
}

Yup.addMethod(
  Yup.array,
  "notEqualToField",
  function (
    this: Yup.ArraySchema<any>,
    fieldName: string,
    message = "${path} can't be equal with other field",
    path?: string
  ) {
    return this.test("notEqualToField", message, function (list, context) {
      if (!list?.length) {
        return true;
      }

      const errors: Yup.ValidationError[] = [];

      const getValue = (value: any) => (path ? value[path] : value);

      list.forEach((element, index) => {
        if (context.parent[fieldName] === getValue(element)) {
          errors.push(
            new Yup.ValidationError(
              message,
              element,
              `${context.path}[${index}]${path ? `.${path}` : ""}`
            )
          );
        }
      });

      if (errors.length > 0) {
        return context.createError({ message: () => errors });
      }

      return true;
    });
  }
);

Yup.addMethod(
  Yup.array,
  "uniqueByProperty",
  function (
    this: Yup.ArraySchema<any>,
    message = "${path} may not have duplicates",
    property?: string
  ) {
    return this.test("uniqueByProperty", message, function (list, context) {
      if (!list?.length) {
        return true;
      }

      const getValue = (value: any) => (property ? value[property] : value);

      const errors: Yup.ValidationError[] = [];
      const duplicateProperties = list
        .filter(
          (e, i) => list.findIndex(e2 => getValue(e2) === getValue(e)) !== i
        )
        .map(getValue);
      for (let i = 0; i < list.length; i += 1) {
        const element = list[i];
        if (
          getValue(element) !== "" &&
          duplicateProperties.includes(getValue(element))
        ) {
          errors.push(
            new Yup.ValidationError(
              message,
              element,
              `${context.path}[${i}].${property}`
            )
          );
        }
      }

      if (errors.length > 0) {
        return context.createError({ message: () => errors });
      }

      return true;
    });
  }
);

Yup.addMethod(Yup.date, "isTodayAndInFuture", function () {
  return this.test(
    "dateIsTodayAndInFuture",
    "Should be today or in the future",
    function (value?: Date) {
      if (!value) return false;

      const todayMidnight = new Date();
      todayMidnight.setHours(0, 0, 0, 0);
      return value >= todayMidnight;
    }
  );
});

Yup.addMethod(Yup.date, "isAfter", function (key: string) {
  return this.test("dateIsAfter", "Should be after", function (value?: Date) {
    if (!value) return false;

    value = new Date(value);
    value.setHours(0, 0, 0, 0);

    const date = new Date(this.resolve(Yup.ref(key)));
    date.setHours(0, 0, 0, 0);

    if (value >= date) {
      return true;
    } else {
      return this.createError({
        path: this.path,
        message: `Should be after ${toWSDateString(date, "monthDayYear")}`
      });
    }
  });
});

// Taxes
export const validatorAmount = (max: number) =>
  Yup.number()
    .typeError("Must be a number")
    .moreThan(0, "Must be greater than 0")
    .max(max, "Must be less than or equal to available balance")
    .required("Amount is required");

// Email validation with regex
export const validateEmail = (email: string) => {
  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegEx.test(email);
};

export const validatePhone = (phone: string) => {
  const phoneRegEx =
    /^(\+?1\s?)?((\([2-9][0-9]{2}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
  return phoneRegEx.test(phone);
};

export const validateName = (name: string) => {
  return nameValidationRegex.test(name);
};

export const validateEIN = (ein: string) => {
  return /^[1-9]\d?-\d{7}$/.test(ein);
};

export const validateSSN = (ssn: string) => {
  const regex = new RegExp(
    /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
  );
  return regex.test(ssn);
};

export const validateAddressLine = (addressLine: string) => {
  const regex = new RegExp(
    "^(?! )[ a-zA-Z0-9\u00C0-\u017F'\\#!’.,:;()?&*-]+(?<! )$"
  );
  return regex.test(addressLine);
};

export const validateZipCode = (zipCode: string) => {
  return /^\d{5}$|^\d{5}-\d{4}$/.test(zipCode);
};

export const textNumbersSymbolsExt = (value: string) => {
  const regex = new RegExp(
    "^(?! )[ a-zA-Z0-9\u00C0-\u017F'\\#’\".,:;()?&*/@!$%^=+_~{}\\[\\]-]+(?<! )$"
  );
  return regex.test(value);
};
