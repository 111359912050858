import React, { useContext, useMemo, useState } from "react";

type Data = { [key: string]: any };

export const createWSStore = function <S extends Data>(
  getDefaultData: () => S
): {
  initialData: S;
  context: React.Context<{ update: (store: Partial<S>) => S; data: S }>;
  useWSStore(): S;
  useResetWSStore(): () => void;
  useSetWSStore(): (store: Partial<S>) => S;
} {
  const defaultData = getDefaultData();

  const context = React.createContext({
    data: defaultData,
    update(store: Partial<S>) {
      this.data = { ...this.data, ...store };

      return this.data;
    }
  });

  return {
    context,
    initialData: defaultData,
    useWSStore() {
      return useContext(context).data;
    },
    useSetWSStore() {
      return useContext(context).update;
    },
    useResetWSStore() {
      const { update } = useContext(context);

      return () => update(getDefaultData());
    }
  };
};

type WSStoreProviderProps = {
  store: any;
};

export const WSStoreProvider: React.FC<WSStoreProviderProps> = ({
  children,
  store
}) => {
  const {
    context: { Provider },
    initialData
  } = store;

  const [data, setData] = useState(initialData);

  const update = useMemo(
    () => (newData: Data) => {
      setData((prevData: Data) => ({ ...prevData, ...newData }));
    },
    []
  );

  return <Provider value={{ data, update }}>{children}</Provider>;
};
