import React, { useRef } from "react";
import {
  WSMenu,
  WSMenuProps,
  WSMenuRef
} from "../common/WSMenu/WSMenu.component";
import { WSPill, WSPillProps } from "../common/WSPill/WSPill.component";
import { WSButton, WSButtonProps, WSIcon } from "../core";
import { WSText } from "../core/WSText/WSText.component";
import { WSElement } from "../WSElement/WSElement.component";
import styles from "./WSCardHeaderValue.module.scss";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";

export type WSCardHeaderValueProps = {
  value?: string;
  action?: WSButtonProps<"Link">;
  pill?: WSPillProps;
  stacked?: boolean;
  menu?: WSMenuProps["items"];
  tooltip?: WSTooltipIconProps["tooltip"];
};

export const WSCardHeaderValue: React.FC<WSCardHeaderValueProps> = (props) => {
  const valueElement = props.value ? (
    <WSText kind="Paragraph" weight="medium" color="gray700">
      {props.value}
    </WSText>
  ) : undefined;

  const actionElement = props.action ? (
    <WSButton.Link {...props.action} />
  ) : undefined;

  const pillElement = props.pill ? <WSPill {...props.pill} /> : undefined;

  const menuElement =
    props.menu && props.menu.length > 0 ? (
      <MenuIcon items={props.menu} />
    ) : undefined;

  const tooltipElement = props.tooltip ? (
    <WSTooltipIcon tooltip={props.tooltip} />
  ) : undefined;

  return (
    <WSElement className={styles.value}>
      {tooltipElement}
      {valueElement}
      {pillElement}
      {actionElement}
      {menuElement}
    </WSElement>
  );
};

const MenuIcon: React.FC<WSMenuProps> = (props) => {
  const menuRef = useRef<WSMenuRef>(null);

  return (
    <>
      <WSMenu ref={menuRef} {...props} />
      <WSIcon
        color="gray500"
        size="M"
        name="dots-v"
        onClick={(event) => {
          menuRef.current?.toggle(event);
        }}
      />
    </>
  );
};
