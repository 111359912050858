import {
  WSElementProps,
  WSField,
  WSFlexBox,
  WSIcon,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useBankInstitution } from "../../../query/payments/queries";
import { validatorRoutingNumber } from "../../validators/validatorRoutingNumber";

type Props = {
  routingNumberFieldName: string;
  rtpRequired?: boolean;
} & WSElementProps;

export const InstitutionPreview: React.FC<Props> = ({
  routingNumberFieldName,
  rtpRequired,
  ...props
}) => {
  const [warning, setWarning] = React.useState<string>("");
  const { watch } = useFormContext();

  const routingNumberFieldValue = watch(routingNumberFieldName);

  const isValidRoutingNumber = useMemo(() => {
    return (
      routingNumberFieldValue &&
      validatorRoutingNumber.isValidSync(routingNumberFieldValue)
    );
  }, [routingNumberFieldValue]);

  useEffect(() => {
    if (!isValidRoutingNumber) {
      setWarning("");
    }
  }, [routingNumberFieldValue, isValidRoutingNumber]);

  const queryBankInstitution = useBankInstitution(routingNumberFieldValue, {
    enabled: isValidRoutingNumber,
    onError() {
      setWarning("We couldn't find a bank with this routing number.");
    },
    onSuccess(data) {
      if (rtpRequired && !data.isRTPSupported) {
        setWarning("This bank does not support instant payments");
      } else {
        setWarning("");
      }
    }
  });

  if (isValidRoutingNumber) {
    if (warning) {
      return <WSField mt="S" message={warning} status="warning" {...props} />;
    }

    if (queryBankInstitution.data) {
      return (
        <WSFlexBox.CenterY mt="S" {...props}>
          <WSIcon block name="check" size="S" color="green500" mr="XS" />
          <WSText.ParagraphSm weight="medium" color="gray500">
            {queryBankInstitution.data.name}
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
      );
    }
  }

  return null;
};
