import classNames from "classnames";
import React from "react";
import { WSAvatar, WSAvatarProps } from "../common/WSAvatar/WSAvatar.component";
import { WSButton, WSButtonProps } from "../core/WSButton/WSButton.component";
import { WSText } from "../core/WSText/WSText.component";
import { WSElement } from "../WSElement/WSElement.component";
import {
  WSTooltipIcon,
  WSTooltipIconProps
} from "../WSTooltipIcon/WSTooltipIcon";
import styles from "./Label.module.scss";

const DEFAULT_AVATAR_SIZE = "S";
const DEFAULT_LINK_BUTTON_SIZE = "S";

export type WSDataItemLabelProps = {
  text?: string;
  avatar?: WSAvatarProps;
  action?: WSButtonProps<"Link">;
  actionPosition?: "right" | "left";
  helperText?: string;
  tooltip?: WSTooltipIconProps["tooltip"];
};

export const Label: React.FC<WSDataItemLabelProps> = (props) => {
  const avatarElement = props.avatar ? (
    <WSAvatar size={DEFAULT_AVATAR_SIZE} {...props.avatar} />
  ) : undefined;

  const textElement = props.text ? (
    <WSText kind="ParagraphSm" color="gray500">
      {props.text}
    </WSText>
  ) : undefined;

  const helperTextElement = props.helperText ? (
    <WSText kind="ParagraphXs" color="gray400">
      {props.helperText}
    </WSText>
  ) : undefined;

  const tooltipElement = props.tooltip ? (
    <WSTooltipIcon tooltip={props.tooltip} />
  ) : undefined;

  const actionElement = props.action ? (
    <WSButton.Link size={DEFAULT_LINK_BUTTON_SIZE} {...props.action} />
  ) : undefined;

  return (
    <WSElement
      className={classNames(
        styles.labelWrapper,
        props.actionPosition === "left" && styles.actionLeft
      )}
    >
      <WSElement className={styles.label}>
        {avatarElement}

        {helperTextElement ? (
          <WSElement>
            {textElement}
            {helperTextElement}
          </WSElement>
        ) : (
          textElement
        )}

        {tooltipElement}
      </WSElement>

      {actionElement}
    </WSElement>
  );
};
