import { useHistory, useRouteMatch } from "react-router-dom";
import { EditLayout } from "../../components/EditLayout";
import { usePayerTaxForm } from "../../../../query/taxForm/queries/usePayerTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import {
  WSButton,
  WSDivider,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { selectorTaxFormRecipientName } from "../../selectors/selectorTaxFormRecipientName";
import { getCorrectionTypeFromCorrectionRequest } from "./RouteSubmitCorrection/getCorrectionTypeFromCorrectionRequest";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../TaxDocuments/constants/corrections";
import { AmountCorrectionDiffPreview } from "../../../TaxDocuments/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../TaxDocuments/components/TINOrNameCorrectionDiffPreview";
import {
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../TaxDocuments/routes/RequestCorrectionFlow/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { AddressCorrectionDiffPreview } from "../../../TaxDocuments/components/AddressCorrectionDiffPreview";
import { ITaxFormCorrectionRequest } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import {
  PayeeCorrectionReason,
  TinType
} from "@wingspanhq/payments/dist/interfaces/taxForm";
import { usePayerTaxFormWithCorrection } from "../../../../query/taxForm/queries/usePayerTaxFormWithCorrection";

export const RouteViewCorrection: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxFormWithCorrection(taxFormId);

  const onBack = () => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  return (
    <EditLayout title="View correction">
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const recipientName = selectorTaxFormRecipientName(taxForm);
          // If user visits page then we assume correction request exists already
          const correctionRequest = taxForm?.pendingCorrection;

          if (!correctionRequest) {
            return (
              <WSMessageBox.Info
                button={{
                  kind: "Link",
                  children: "Refresh",
                  onClick: () => {
                    window.location.reload();
                  }
                }}
              >
                Please refresh the web page if you can't see correction details.
              </WSMessageBox.Info>
            );
          }
          const { corrections } =
            getCorrectionTypeFromCorrectionRequest(taxForm);

          const correctionTypeToPreviewComponentMap: Record<
            string,
            JSX.Element
          > = {
            [CORRECTION_AMOUNT]: (
              <AmountCorrectionDiffPreview
                taxForm={taxForm}
                amount={taxForm?.data?.totalAmount ?? 0}
                correctedAmount={correctionRequest.data?.totalAmount ?? 0}
                comment={correctionRequest.payeeOwnedData?.comment ?? ""}
              />
            ),
            [CORRECTION_TIN_OR_NAME]: (
              <TINOrNameCorrectionDiffPreview
                taxForm={taxForm}
                identificationNumberType={
                  correctionRequest.data?.w9Info?.ein ||
                  correctionRequest.data?.w9Info?.einLastFour
                    ? TinType.Business
                    : TinType.Individual
                }
                originalTinData={getPayeeTINorName(taxForm)}
                correctedTinData={{
                  ssn: correctionRequest?.data?.w9Info?.ssn ?? "",
                  ein: correctionRequest?.data?.w9Info?.ein ?? "",
                  businessName:
                    correctionRequest?.data?.w9Info?.legalBusinessName ?? "",
                  firstName: correctionRequest?.data?.w9Info?.firstName,
                  lastName: correctionRequest?.data?.w9Info?.lastName,
                  disregardedEntityName:
                    correctionRequest?.data?.w9Info?.disregardedEntityName,
                  taxClassification: correctionRequest?.data?.w9Info
                    ?.companyStructure as CompanyStructure
                }}
                reasonForChange={
                  correctionRequest?.payeeOwnedData?.reason ?? ""
                }
                otherReason={correctionRequest?.payeeOwnedData?.reasonComment}
              />
            ),
            [CORRECTION_ADDRESS]: (
              <AddressCorrectionDiffPreview
                taxForm={taxForm}
                originalAddress={getPayeeFormW9Address(taxForm)}
                correctedAddress={{
                  addressLine1:
                    correctionRequest?.data?.w9Info?.addressLine1 ?? "",
                  addressLine2:
                    correctionRequest?.data?.w9Info?.addressLine2 ?? "",
                  city: correctionRequest?.data?.w9Info?.city ?? "",
                  state: correctionRequest?.data?.w9Info?.state ?? "",
                  zipCode: correctionRequest?.data?.w9Info?.postalCode ?? "",
                  country: correctionRequest?.data?.w9Info?.country ?? ""
                }}
              />
            )
          };
          return (
            <WSElement>
              <WSText.Heading2 mb="XS">
                View 1099-NEC correction request
              </WSText.Heading2>
              <WSMessageBox.Warning
                noBorder
                icon="alert"
                title="Form 1099-NEC pending acceptance by the IRS "
              >
                <WSText.ParagraphSm color="gray600" mb="L">
                  The recipient has requested corrections to their Form
                  1099-NEC. The form has been submitted to the IRS but has not
                  yet been accepted (or rejected).
                </WSText.ParagraphSm>

                <WSText.ParagraphSm color="gray600">
                  Once the IRS has accepted the filing, you can review the
                  request, verify the corrections, and determine whether to
                  accept the changes and submit to the IRS or reject the
                  changes.
                </WSText.ParagraphSm>
              </WSMessageBox.Warning>

              <WSDivider my="XL" />

              <WSText.Heading2 mb="XL">{recipientName}</WSText.Heading2>

              {corrections.map(correctionType => {
                const DiffPreviewComponent =
                  correctionTypeToPreviewComponentMap[correctionType];
                return (
                  <WSElement mb="L" key={correctionType}>
                    {DiffPreviewComponent}
                    <WSDivider my="XL" />
                  </WSElement>
                );
              })}

              <WSButton.Tertiary fullWidth onClick={onBack}>
                Back
              </WSButton.Tertiary>
            </WSElement>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
