import {
  useIsDesktop,
  WSButton,
  WSButtons,
  WSCentered,
  WSCheckboxToggle,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSPanel,
  WSTable,
  WSTableCell,
  WSText
} from "@wingspanhq/fe-component-library";
import { IIntegrationVendor } from "@wingspanhq/integrations/dist/lib/interfaces/vendor";
import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../../query/WSQuery";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksVendors
} from "../../../../query/integrations/queries";
import { isQuickbooksAuthenticated } from "../../../../query/integrations/selectors";
import { useCreateCollaborator } from "../../../../query/payments/mutations";
import { useCollaboratorsQuery } from "../../../../query/payments/queries";
import { bulkActionFactory } from "../../../../utils/bulkActionFactory";
import { getChangedData } from "../../../../utils/getChangedData";
import {
  REGEX_NUMBER_SYMBOLS_TEXT_EXT,
  REGEX_TEXT_ONLY
} from "../../../../utils/validators";
import { getSortedAndGroupedQuickbooksUserList } from "../../../../modules/Integrations/utils/getSortedAndGroupedQuickbooksUserList";
import { openIntercom } from "../../../../shared/utils/intercom";

interface FormData {
  [key: string]: string;
}

export const QuickbooksSetupStep7: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const userId = useUserId();
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const isDesktop = useIsDesktop();

  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();

  const [createCollaborator] = useCreateCollaborator();
  const qMemberClients = useCollaboratorsQuery();

  const [error, setError] = useState("");

  const [
    successfullyCreatedCollaboratorVendorIDs,
    setSuccessfullyCreatedCollaboratorVendorIDs
  ] = useState<string[]>([]);

  const createMemberClientAction = bulkActionFactory({
    onStart() {
      setError("");
    },
    async action(vendor: IIntegrationVendor & { email: string | undefined }) {
      if (
        vendor.email !== undefined &&
        !successfullyCreatedCollaboratorVendorIDs.includes(vendor.vendorId)
      ) {
        let cleanedName = vendor.displayName;
        if (cleanedName && !REGEX_TEXT_ONLY.test(cleanedName)) {
          cleanedName = [...cleanedName]
            .filter(c => REGEX_TEXT_ONLY.test(c))
            .join("");
        }

        let cleanedCompanyName = vendor.companyName;
        if (
          cleanedCompanyName &&
          !REGEX_NUMBER_SYMBOLS_TEXT_EXT.test(cleanedCompanyName)
        ) {
          cleanedCompanyName = [...cleanedCompanyName]
            .filter(c => REGEX_NUMBER_SYMBOLS_TEXT_EXT.test(c))
            .join("");
        }

        await createCollaborator(
          {
            clientId: userId,
            memberCompany: cleanedCompanyName,
            memberName: cleanedName,
            memberEmail: vendor.email,
            integration: {
              quickbooks: {
                vendorId: vendor.vendorId
              }
            }
          },
          {
            throwOnError: true
          }
        );
        setSuccessfullyCreatedCollaboratorVendorIDs(
          successfullyCreatedVendors => [
            ...successfullyCreatedVendors,
            vendor.vendorId
          ]
        );
      }
    },
    onFinish({ failedItems }) {
      if (failedItems.length) {
        setError(
          `Sorry! Something went wrong while trying to create collaborators for ${failedItems
            .map(item => item.email)
            .join(
              ", "
            )}. Please try again, or if this error persists, please remove from your list, continue, and contact support for a follow up.`
        );
      } else {
        history.push("/member/settings/integrations/quickbooks/setup/step/8");
      }
    }
  });

  return (
    <ProjectOnboardingLayout progress={(100 / 8) * 7}>
      <WSQueries
        queries={{
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksVendors,
          qMemberClients
        }}
      >
        {({
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksVendors,
          qMemberClients
        }) => {
          const integrationState = qIntegrationsQuickbooks.data;
          const memberClients = qMemberClients.data;

          const vendors = getSortedAndGroupedQuickbooksUserList(
            qIntegrationsQuickbooksVendors.data.filter(
              v => v.primaryEmailAddress !== undefined
            )
          )
            .filter(
              v =>
                !memberClients.find(
                  m => m.integration?.quickbooks?.vendorId === v.vendorId
                )
            )
            .map(v => ({ email: v.primaryEmailAddress?.split(",")[0], ...v }));

          if (!isQuickbooksAuthenticated(integrationState)) {
            return (
              <Redirect to="/member/settings/integrations/quickbooks/setup" />
            );
          }

          const defaultFormData = vendors.reduce(
            (res, vendor) => ({
              ...res,
              [vendor.vendorId]: memberClients.find(
                m => m.integration?.quickbooks?.vendorId === vendor.vendorId
              )
            }),
            {}
          );

          return (
            <WSContainer verticalPadding>
              <WSCentered span={{ m: "8" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  7 of 8
                </WSText.ParagraphSm>
                <WSText.Heading4>Map Vendors</WSText.Heading4>

                <WSPanel mt="2XL" mb="5XL">
                  <>
                    <WSFormOld
                      defaultValues={defaultFormData}
                      onSubmit={async (formData: FormData) => {
                        const changedData = getChangedData(
                          defaultFormData,
                          formData
                        );
                        const collaboratorsToCreate = Object.keys(
                          changedData
                        ).filter(key => formData[key]);
                        await createMemberClientAction.run(
                          vendors.filter(vendor =>
                            collaboratorsToCreate.includes(vendor.vendorId)
                          )
                        );
                      }}
                    >
                      <WSText.Heading5 mt="XL">
                        Import and associate Quickbooks Vendors with Wingspan
                        Contractors
                      </WSText.Heading5>

                      {vendors.length > 0 ? (
                        <>
                          <WSText mt="3XL">
                            For Vendors you haven't mapped, select the ones
                            you'd like to import to Wingspan. We'll send them
                            invites as well!
                          </WSText>
                          <WSText mt="XL">
                            Just a note, we can only import Vendors from QBO
                            that have an email address filled out. We may also
                            have to make changes to the Vendors's information to
                            match our validation requirements. For instance, no
                            special characters, etc.
                          </WSText>

                          <WSFlexBox.CenterY justify="flex-end" my="XL">
                            <WSFormOld.Context>
                              {({ setValue }) => {
                                const names = vendors
                                  .filter(
                                    vendor =>
                                      !successfullyCreatedCollaboratorVendorIDs.includes(
                                        vendor.vendorId
                                      )
                                  )
                                  .map(vendor => vendor.vendorId);

                                return (
                                  <WSFormOld.Values names={names}>
                                    {values => {
                                      const currentValue =
                                        Object.values(values).every(Boolean);

                                      return (
                                        <>
                                          <WSText mr="M" color="gray500">
                                            {currentValue
                                              ? "Unselect "
                                              : "Select "}
                                            All
                                          </WSText>
                                          <WSCheckboxToggle
                                            label=""
                                            mr={isDesktop ? "M" : "NONE"}
                                            name="selectAll"
                                            value={currentValue}
                                            onChange={() => {
                                              Object.keys(values).forEach(
                                                key => {
                                                  setValue(key, !currentValue);
                                                }
                                              );
                                            }}
                                          />
                                        </>
                                      );
                                    }}
                                  </WSFormOld.Values>
                                );
                              }}
                            </WSFormOld.Context>
                          </WSFlexBox.CenterY>

                          <WSTable
                            mt="XL"
                            tableData={vendors.map(
                              (vendor: IIntegrationVendor) => ({
                                id: vendor.vendorId,
                                data: vendor
                              })
                            )}
                            columns={[
                              {
                                config: {
                                  gridTemplateSizeMax: "1fr",
                                  header: { text: "Vendor" }
                                },
                                renderFunction: ({ data }) => {
                                  const vendor: IIntegrationVendor = data;
                                  return (
                                    <WSTableCell
                                      avatar={
                                        successfullyCreatedCollaboratorVendorIDs.includes(
                                          vendor.vendorId
                                        )
                                          ? {
                                              type: "icon",
                                              colorBackground: "green50",
                                              color: "gray500",
                                              icon: "check"
                                            }
                                          : {
                                              type: "text",
                                              text: vendor.displayName || ""
                                            }
                                      }
                                      text={vendor.displayName}
                                      secondaryText={data.primaryEmailAddress}
                                    />
                                  );
                                }
                              },
                              {
                                config: {
                                  gridTemplateSizeMax: "1fr",
                                  justify: "end",
                                  header: { text: "Import?" }
                                },
                                renderFunction: ({ data }) =>
                                  successfullyCreatedCollaboratorVendorIDs.includes(
                                    data.vendorId
                                  ) ? (
                                    <></>
                                  ) : (
                                    <WSFormOld.Field
                                      name={data.vendorId}
                                      component={WSCheckboxToggle}
                                      componentProps={{ label: "" }}
                                    />
                                  )
                              }
                            ]}
                          />
                          <WSErrorMessage
                            mt="2XL"
                            contextKey="IntegrationsQuickbooksMapping"
                            error={error}
                          />
                        </>
                      ) : (
                        <>
                          <WSText mt="XL">
                            Sorry! It appears that you don't have any Vendors at
                            this time. If you think this is an error, please{" "}
                            <WSButton.Link onClick={openIntercom}>
                              contact support
                            </WSButton.Link>
                          </WSText>
                        </>
                      )}

                      <WSFlexBox.Center mt="2XL">
                        <WSButtons>
                          <WSFormOld.SubmitButton name="finish">
                            Finish
                          </WSFormOld.SubmitButton>
                        </WSButtons>
                      </WSFlexBox.Center>
                    </WSFormOld>
                  </>
                </WSPanel>
              </WSCentered>
            </WSContainer>
          );
        }}
      </WSQueries>
    </ProjectOnboardingLayout>
  );
};
