import {
  CellsConfig,
  ContextItem,
  RequirementTableCallback
} from "../../../../types";
import {
  IMemberClientRequirementResponse,
  IRequirementResponse
} from "@wingspanhq/payments/dist/interfaces";
import {
  toWSDateString,
  useWSSnackbar,
  WSTableCell
} from "@wingspanhq/fe-component-library";
import React from "react";
import { SHARED_FILE_REQUIREMENT_EVENT } from "../utils";
import { RequirementEmbeddedStatus } from "@wingspanhq/payments/dist/interfaces/requirement";
import { useDownloadPrivateDocument } from "../../../../../../query/files/mutations";
import {
  WSTableAction,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { getSharedFileFromCache } from "../helpers/getSharedFileFromCache";
import {
  selectorRequirementHumanReadableType,
  selectorRequirementIcon
} from "../../../../selectors/selectorRequirementHumanReadableType";
import {
  ISharedFileRequestResponse,
  SharedFileRequestStatus
} from "@wingspanhq/shared-files/dist/lib/interfaces";
import { selectorIsRequirementReadyToUploadFile } from "../../../../selectors/selectorIsRequirementReadyToUploadFile";
import { useUploadFileModal } from "../../components/useUploadFileModal";
import { downloadFileFromBuffer } from "../../../../../../utils/files";
import { filesService } from "../../../../../../services/files";

export const usePayerSharedFileRequirementConfig = (
  callback: RequirementTableCallback
): CellsConfig<IRequirementResponse> => {
  const { openSnackbar } = useWSSnackbar();
  const uploadFileModal = useUploadFileModal();
  const [downloadDocument] = useDownloadPrivateDocument();

  const handleRequirementDownload = async (
    sharedDocument: ISharedFileRequestResponse,
    requirement: IRequirementResponse
  ) => {
    const file = await filesService.member.private.get(sharedDocument.fileId!);

    await downloadDocument(file.fileId, {
      onSuccess: blob => {
        downloadFileFromBuffer(blob, file?.filename, file.mimetype);
        openSnackbar({
          message: "File downloaded successfully",
          type: "success"
        });
        callback(SHARED_FILE_REQUIREMENT_EVENT.DownloadAsPayee, requirement);
      },
      onError: () => {
        openSnackbar({
          message: "Download failed",
          type: "warning"
        });
      }
    });
  };

  const handleUploadFile = async (
    requirement: IRequirementResponse,
    sharedFile: ISharedFileRequestResponse,
    context: ContextItem
  ) => {
    await uploadFileModal.open({
      sharedFileRequestId: sharedFile.sharedFileRequestId,
      sharedFileRequestSettingsId: sharedFile.sharedFileRequestSettingsId,
      payeeId: context.payeeId,
      payerId: context.payerId,
      onSuccess: () => {
        callback(SHARED_FILE_REQUIREMENT_EVENT.UploadFile, requirement);
      }
    });
  };

  return {
    nameCell: (requirement, context) => {
      const sharedFile = getSharedFileFromCache(requirement, context);

      if (requirement.dataSourceId && !sharedFile) {
        return (
          <WSTableCell
            text="--"
            secondaryText="error loading"
            secondaryTextColor="amber400"
          />
        );
      }

      return <WSTableCell text={sharedFile?.title} />;
    },
    typeCell: requirement => {
      return (
        <WSTableCell
          icon={selectorRequirementIcon(requirement.requirementType)}
          text={selectorRequirementHumanReadableType(
            requirement.requirementType
          )}
        />
      );
    },
    statusCell: (requirement, context) => {
      const sharedFile = getSharedFileFromCache(requirement, context);

      if (requirement.dataSourceId && !sharedFile) {
        return (
          <WSTableCell
            text="--"
            secondaryText="error loading"
            secondaryTextColor="amber400"
          />
        );
      }

      return (
        <WSTableCell
          pill={
            requirement.status === RequirementEmbeddedStatus.Complete
              ? {
                  theme: "success",
                  text: "Complete",
                  icon: true
                }
              : {
                  theme: "warning",
                  text: "Pending",
                  icon: true
                }
          }
          secondaryText={
            requirement.status === RequirementEmbeddedStatus.Complete
              ? sharedFile?.events?.completedAt || sharedFile?.updatedAt
                ? toWSDateString(
                    sharedFile?.events?.completedAt || sharedFile?.updatedAt,
                    "monthDayYear"
                  )
                : ""
              : sharedFile?.status === SharedFileRequestStatus.Complete
              ? "File uploaded"
              : "Awaiting for file"
          }
        />
      );
    },
    rowActions: (requirement, context) => {
      const actions: WSTableAction[] = [];

      const sharedFile = getSharedFileFromCache(requirement, context);

      if (selectorIsRequirementReadyToUploadFile(requirement, sharedFile)) {
        actions.push({
          name: "SignDocument",
          onAsyncClick: async () => {
            await handleUploadFile(requirement, sharedFile!, context);
          },
          text: "Start",
          kind: "Link",
          hideOn: []
        });
      }

      return actions;
    },
    rowMenuActions: (requirement, context) => {
      const actions: WSTableRowMenuAction<IMemberClientRequirementResponse>[] =
        [];

      const sharedFile = getSharedFileFromCache(requirement, context);

      const isDocumentReadyToDownload =
        requirement.dataSourceId &&
        sharedFile?.fileId &&
        requirement.dataSourceStatus === RequirementEmbeddedStatus.Complete;

      if (isDocumentReadyToDownload) {
        actions.push({
          onAsyncClick: async () => {
            await handleRequirementDownload(sharedFile!, requirement);
          },
          label: "Download File",
          icon: "download"
        });
      }

      if (selectorIsRequirementReadyToUploadFile(requirement, sharedFile)) {
        actions.push({
          onAsyncClick: async () => {
            await handleUploadFile(requirement, sharedFile!, context);
          },
          label: "Countersign",
          icon: "file"
        });
      }

      // hide actions if only sign is available
      return isDocumentReadyToDownload ? actions : [];
    }
  };
};
