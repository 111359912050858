import { loadStripe, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import {
  useModalContext,
  WSButton,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSRadioInputGroup,
  WSStripeInput,
  WSStripeInputRef,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  AutoPayStrategy,
  IClientInvoice
} from "@wingspanhq/payments/dist/interfaces";
import {
  IPaymentMethod,
  SessionType
} from "@wingspanhq/users/dist/lib/interfaces";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import { formatMoney } from "accounting";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { CertifiedSecureDigitalPayments } from "../../components/CertifiedSecureDigitalPayments/CertifiedSecureDigitalPayments";
import { Overlay } from "../../components/Overlay";
import { WSErrorMessage } from "../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../query/helpers";
import { useUserId } from "../../query/hooks/helpers";
import {
  useClientInvoiceFeesQuery,
  useClientInvoiceQuery
} from "../../query/payments/queries";
import {
  getClientInvoiceMemberName,
  getClientName
} from "../../query/payments/selectors";
import { useSession } from "../../query/session";
import { QUERY_USERS_CLIENT } from "../../query/users/keys";
import { useClientQuery } from "../../query/users/queries";
import { getRedactedMemberName } from "../../query/users/selectors";
import { paymentsService } from "../../services/payments";
import { usersService } from "../../services/users";
import { selectorClientCreditCardFee } from "../../shared/selectors/selectorClientCreditCardFee";
import { useWSStore } from "../../store";
import { addBusinessDays } from "../../utils/dates";
import { addElementToArray, WSServiceError } from "../../utils/serviceHelper";
import { useWSAnalytics } from "../../utils/WSAnalytics";
import { AutopayCard } from "../components/AutopayCard";
import { Card } from "../components/Card/Card";
import { ClientInvoiceDetailsDrawer } from "../components/ClientInvoiceDetailsDrawer/ClientInvoiceDetailsDrawer";
import { ClientInvoiceWrapper } from "../components/ClientInvoiceWrapper/ClientInvoiceWrapper";
import { ClientPaymentsWrapper } from "../components/ClientPaymentsWrapper/ClientPaymentsWrapper";
import { CLIENT_SIGN_IN_MODAL_KEY } from "../components/ClientSignInModal/ClientSignInModal";
import { CreditCardPreview } from "../components/CreditCardPreview/CreditCardPreview";
import { DonwloadPdfButton } from "../components/DownloadPdfButton/DonwloadPdfButton";
import { InfoRow } from "../components/InfoRow/InfoRow";
import { PrivacyPolicy } from "../components/PrivacyPolicy/PrivacyPolicy";
import {
  REMOVE_CREDIT_CARD_MODAL_KEY,
  RemoveCreditCardModal
} from "../components/RemoveCreditCardModal/RemoveCreditCardModal";
import {
  getIsAutopayRequired,
  getPaymentStepText,
  isClientInvoiceCancelled,
  isClientInvoicePaid
} from "../utils";
import { VerticalDivider } from "../../shared/components/VerticalDivider";

type Props = RouteComponentProps<{ invoiceId: string }>;

export const ClientPaymentsInvoicePaymentCreditCardStripe: React.FC<Props> = ({
  match
}) => {
  const invoiceId = match.params.invoiceId;
  const history = useHistory();
  const stripeInput = useRef<WSStripeInputRef>(null);
  const clientInvoiceFeesQuery = useClientInvoiceFeesQuery(invoiceId);
  const creditCardFee = clientInvoiceFeesQuery.data?.creditFeeTotal || 0;
  const [stripeInputState, setStripeInputState] = useState<
    StripeCardElementChangeEvent | undefined
  >();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);
  const sessionQuery = useSession({ refetchOnWindowFocus: false });
  const userId = useUserId();
  const clientQuery = useClientQuery(userId, {
    enabled: sessionQuery.data?.sessionType === SessionType.user
  });
  const { openModal } = useModalContext();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");
  const isGuest = sessionQuery.data?.sessionType !== SessionType.user;
  const [autopay, setAutopay] = useState<"enable" | "disable">("disable");
  const [canNotBePaid, setCanNotBePaid] = useState(false);
  const [tryingToPayOwnInvoice, setTryingToPayOwnInvoice] = useState(false);

  const [payInvoice, payInvoiceMeta] = useWSMutation<
    void,
    WSServiceError,
    {
      clientInvoice: IClientInvoice;
    }
  >(
    async ({ clientInvoice }) => {
      if (clientInvoice.memberClient.memberId === userId) {
        throw new Error("You cannot pay your own invoices");
      }

      let paymentMethodId: string | undefined;

      if (selectedPaymentMethodId) {
        paymentMethodId = selectedPaymentMethodId;
      } else {
        if (stripeInputState?.complete) {
          const result = await stripeInput?.current?.createPaymentMethod();
          if (result?.token) {
            paymentMethodId = result?.token.id;

            if (savePaymentMethod || autopay === "enable") {
              await usersService.client.update(userId, {
                clientId: userId,
                profile: {
                  savedPaymentMethods: addElementToArray<IPaymentMethod>(
                    clientQuery.data?.profile.savedPaymentMethods?.length || 0,
                    { paymentMethodId: paymentMethodId }
                  )
                }
              });
            }
          }
        } else {
          throw new Error(
            "Oops! Some of your credit card info is missing or incorrect."
          );
        }
      }

      if (!paymentMethodId) {
        console.error("No payment method id provided");
        throw new Error("Something went wrong");
      }

      await paymentsService.client.invoice.pay(invoiceId, {
        paymentMethodId
      });

      try {
        if (autopay === "enable" && clientInvoice.invoiceTemplateId) {
          await usersService.client.update(userId, {
            clientId: userId,
            profile: {
              defaultPaymentMethod: {
                paymentMethodId
              }
            }
          });

          await paymentsService.collaborator.update(
            clientInvoice.memberClient.memberClientId,
            {
              clientData: {
                autoPayStrategy: AutoPayStrategy.All
              }
            }
          );

          await paymentsService.client.invoiceTemplate.update(
            clientInvoice.invoiceTemplateId,
            {
              clientId: userId,
              paymentMethodId
            }
          );
        }
      } catch (error) {
        console.error("Failed while setting autopay", error);
      }
    },
    {
      onSuccess: () => {
        history.push(`/payment/${invoiceId}/success`);
      },
      dependencies: [QUERY_USERS_CLIENT]
    }
  );

  useEffect(() => {
    if (
      !selectedPaymentMethodId &&
      clientQuery.data?.profile.savedPaymentMethods &&
      clientQuery.data?.profile.savedPaymentMethods.length > 0 &&
      clientQuery.data.profile.savedPaymentMethods[0].paymentMethodId
    ) {
      setSelectedPaymentMethodId(
        clientQuery.data.profile.savedPaymentMethods[0].paymentMethodId
      );
    }
  }, [clientQuery.data]);

  const { track } = useWSAnalytics();
  const stripeElementsTrackEventDebounced = debounce(event => {
    track.other("Stripe Elements Event Occurred", {
      ...event,
      invoiceId
    });
  }, 200);

  useEffect(() => {
    if (sessionQuery.data?.sessionType === SessionType.user) {
      setAutopay("enable");
    }
  }, [sessionQuery.data]);

  const stripePromise = useMemo(
    () => loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`),
    []
  );

  const clientInvoiceQuery = useClientInvoiceQuery(invoiceId);

  useEffect(() => {
    if (
      clientInvoiceQuery.isFetched &&
      clientInvoiceQuery.data &&
      getIsAutopayRequired(clientInvoiceQuery.data)
    ) {
      setAutopay("enable");
    }
  }, [clientInvoiceQuery.isFetched]);

  const store = useWSStore();

  return (
    <>
      <ClientInvoiceWrapper invoiceId={invoiceId}>
        {clientInvoice => {
          const allowAutopay = !!clientInvoice.invoiceTemplateId;

          const submitSection = (
            <>
              <WSButton
                fullWidth
                icon="lock"
                mt="2XL"
                onClick={async () => {
                  if (clientInvoice.memberClient.memberId === userId) {
                    setTryingToPayOwnInvoice(true);
                    return;
                  }

                  if (!clientInvoice.memberAcceptsPayments) {
                    setCanNotBePaid(true);
                    return;
                  }

                  payInvoice({
                    clientInvoice
                  });
                }}
                loading={payInvoiceMeta.isLoading}
                name="pay"
              >
                Confirm payment
              </WSButton>

              {userId && clientInvoice.memberClient.memberId === userId && (
                <WSText.ParagraphSm mt="XL" color="gray500">
                  You are the link owner. This is what your customers will see.
                </WSText.ParagraphSm>
              )}

              {canNotBePaid && (
                <WSText.ParagraphSm color="garnet" mt="XL">
                  {clientInvoice.memberCompany ||
                    getClientInvoiceMemberName(clientInvoice)}{" "}
                  does not currently accept digital payments. Please contact{" "}
                  <a href="mailto:support@wingspan.app">support@wingspan.app</a>{" "}
                  for more details.
                </WSText.ParagraphSm>
              )}

              {tryingToPayOwnInvoice && (
                <WSText.ParagraphSm color="garnet" mt="XL">
                  You cannot pay your own invoices
                </WSText.ParagraphSm>
              )}

              <WSErrorMessage
                mt="XL"
                contextKey="PayInvoice"
                error={payInvoiceMeta.error}
              />
            </>
          );

          return isClientInvoicePaid(clientInvoice) ? (
            <Redirect to={`/payment/${invoiceId}/success`} />
          ) : isClientInvoiceCancelled(clientInvoice) ? (
            <Redirect to={`/payment/${invoiceId}`} />
          ) : (
            <ClientPaymentsWrapper
              clientInvoice={clientInvoice}
              companyName={clientInvoice.memberCompany}
              clientEmail={clientInvoice.memberClient.emailTo}
              memberName={getClientInvoiceMemberName(clientInvoice)}
              companyLogoUrl={clientInvoice.memberLogoUrl}
              onBack={() => {
                history.goBack();
              }}
            >
              {drawerVisible && (
                <ClientInvoiceDetailsDrawer
                  invoiceId={invoiceId}
                  onClose={() => {
                    setDrawerVisible(false);
                  }}
                  processingFee={creditCardFee}
                />
              )}

              <WSText.ParagraphSm>
                {getPaymentStepText(clientInvoice, "payment")}
              </WSText.ParagraphSm>
              <WSText.Heading4 mb="2XL">Review & pay</WSText.Heading4>

              <Card
                title="Credit card information"
                mb="M"
                action={
                  selectedPaymentMethodId
                    ? {
                        icon: "plus-circle",
                        children: "Add",
                        onClick: () => {
                          setSelectedPaymentMethodId("");
                        },
                        kind: "Link"
                      }
                    : undefined
                }
              >
                {clientQuery.data?.profile.savedPaymentMethods &&
                  clientQuery.data?.profile.savedPaymentMethods.length > 0 && (
                    <WSRadioInputGroup
                      name="paymentMethod"
                      value={selectedPaymentMethodId}
                      options={clientQuery.data.profile.savedPaymentMethods.map(
                        (paymentMethod, index) => ({
                          value: paymentMethod.paymentMethodId,
                          label: (
                            <WSFlexBox wrap="nowrap" justify="space-between">
                              <CreditCardPreview
                                paymentMethod={paymentMethod}
                                mr="XL"
                              />

                              <WSButton.Link
                                type="button"
                                onClick={() => {
                                  openModal(REMOVE_CREDIT_CARD_MODAL_KEY, {
                                    paymentMethod,
                                    index
                                  });
                                }}
                              >
                                Remove
                              </WSButton.Link>
                            </WSFlexBox>
                          )
                        })
                      )}
                      onChange={(event: any) => {
                        setSelectedPaymentMethodId(event.target.value);
                      }}
                      mb={selectedPaymentMethodId ? undefined : "M"}
                    />
                  )}

                {!selectedPaymentMethodId && (
                  <>
                    <WSElement
                      mb={
                        !allowAutopay || autopay === "disable" ? "S" : undefined
                      }
                    >
                      {isGuest && getIsAutopayRequired(clientInvoice) && (
                        <Overlay
                          overParent
                          onClick={() => {
                            openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                              signUp: true,
                              clientEmailToPrefill:
                                clientInvoice.memberClient.emailTo,
                              memberId: clientInvoice.memberClient.memberId,
                              member: clientInvoice.memberClient.member
                            });
                          }}
                        />
                      )}
                      <WSStripeInput
                        ref={stripeInput}
                        stripeInstance={stripePromise}
                        onInputChange={event => {
                          stripeElementsTrackEventDebounced(event);
                          setStripeInputState(event);
                        }}
                        name="card"
                      />
                    </WSElement>

                    {(!allowAutopay || autopay === "disable") && (
                      <WSCheckboxToggle
                        label="Save for 1-click payment"
                        name="savePaymentMethod"
                        value={savePaymentMethod}
                        onChange={value => {
                          if (isGuest) {
                            openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                              signUp: true,
                              clientEmailToPrefill:
                                clientInvoice.memberClient.emailTo,
                              onSuccess: () => {
                                setSavePaymentMethod(true);
                              },
                              memberId: clientInvoice.memberClient.memberId,
                              member: clientInvoice.memberClient.member
                            });
                          } else {
                            setSavePaymentMethod(value);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Card>

              <Card mb="M">
                <WSText.Heading5 mb="XS">Payment timing</WSText.Heading5>
                <WSText mb="XS">Within 2 business days</WSText>
                <WSText.ParagraphSm color="gray500">
                  Estimated arrival:{" "}
                  {moment(addBusinessDays(new Date(), 2)).format(
                    "MMMM D, YYYY"
                  )}
                </WSText.ParagraphSm>
              </Card>

              <Card mb={allowAutopay ? "M" : "3XL"}>
                <WSFlexBox justify="space-between" mb="XS">
                  <WSText.ParagraphSm>Total</WSText.ParagraphSm>
                  <WSFlexBox.CenterY>
                    <WSButton.Link
                      onClick={() => {
                        setDrawerVisible(true);
                      }}
                    >
                      View
                    </WSButton.Link>
                    {clientInvoice.attachments?.invoicePdf && (
                      <>
                        <VerticalDivider mx="M" my="XL" />

                        <DonwloadPdfButton clientInvoice={clientInvoice} />
                      </>
                    )}
                  </WSFlexBox.CenterY>
                </WSFlexBox>
                <WSText.Heading1 weight="book" mb="XS">
                  {formatMoney(clientInvoice.amount + creditCardFee)}
                </WSText.Heading1>
                <WSText.ParagraphSm color="gray500" mb="2XL">
                  {formatMoney(clientInvoice.amount)}
                  {creditCardFee > 0 &&
                    ` + ${selectorClientCreditCardFee(
                      clientInvoice.creditFeeHandling
                    )} processing fee`}
                </WSText.ParagraphSm>

                <InfoRow
                  label={store.createdWithPPL ? "From" : "To"}
                  value={getClientName(clientInvoice.memberClient)}
                  mb="M"
                />
                <InfoRow
                  label={store.createdWithPPL ? "To" : "From"}
                  value={getRedactedMemberName(
                    clientInvoice.memberClient.member
                  )}
                  mb="M"
                />
                {clientInvoice.invoiceNotes && (
                  <InfoRow
                    label="Note"
                    value={clientInvoice.invoiceNotes}
                    mb="M"
                  />
                )}

                {!allowAutopay && submitSection}
              </Card>

              {allowAutopay && (
                <AutopayCard
                  mb="XL"
                  clientInvoice={clientInvoice}
                  agreementValue={autopay === "enable"}
                  onAgreemenChange={newValue => {
                    if (newValue) {
                      if (isGuest) {
                        openModal(CLIENT_SIGN_IN_MODAL_KEY, {
                          signUp: true,
                          clientEmailToPrefill:
                            clientInvoice.memberClient.emailTo,
                          onSuccess: () => {
                            setAutopay("enable");
                          },
                          memberId: clientInvoice.memberClient.memberId,
                          member: clientInvoice.memberClient.member
                        });
                      } else {
                        setAutopay("enable");
                      }
                    } else {
                      setAutopay("disable");
                    }
                  }}
                  submitSection={submitSection}
                />
              )}

              <CertifiedSecureDigitalPayments mb="M" />
              <PrivacyPolicy />
            </ClientPaymentsWrapper>
          );
        }}
      </ClientInvoiceWrapper>

      <RemoveCreditCardModal />
    </>
  );
};
