import { IPaymentCard } from "@wingspanhq/payments/dist/interfaces";
import { QueryConfig } from "react-query";
import { paymentsService } from "../../../services/payments";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYMENT_CARD } from "../keys";

export const useQueryPaymentsCard = (
  paymentCardId: string,
  config?: QueryConfig<IPaymentCard, WSServiceError>
) =>
  useWSQuery(
    [QUERY_PAYMENT_CARD, paymentCardId],
    () => paymentsService.paymentCard.get(paymentCardId),
    config
  );
