import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYEE_ENGAGEMENT } from "../keys";
import { getPayeeEngagement } from "../../../services/payeeEngagement";
import { IPayeeEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const usePayeeEngagementQuery = (
  payeeId: string,
  engagementId: string,
  config?: QueryConfig<IPayeeEngagementResponse, WSServiceError>
) => {
  const orgPayerId = useXWingspanPayerId(payeeId);

  return useWSQuery<IPayeeEngagementResponse, WSServiceError>(
    [QUERY_PAYEE_ENGAGEMENT, payeeId, engagementId],
    async (query, queryParams) => {
      return await getPayeeEngagement(payeeId, engagementId, orgPayerId);
    },
    {
      ...config
    }
  );
};
