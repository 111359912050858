import { WSElement, WSFlexBox, WSText } from "@wingspanhq/fe-component-library";
import React, { useEffect, useRef, useState } from "react";
import { useWSStore } from "../../store";
import { WSServiceError } from "../../utils/serviceHelper";
import { useLoadCaptcha } from "../../utils/useLoadCaptcha";
import { useWaitFor } from "../../utils/useWaitFor";
import styles from "./styles.module.scss";
import { IS_DEV_ENV } from "../../shared/constants/environment";

const RECAPTCHA_V2_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY || "";
const RECAPTCHA_V3_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY || "";
const RECAPTCHA_VERSION_2 = "2";
const RECAPTCHA_VERSION_3 = "3";

const CaptchaContext = React.createContext({
  mount: (action: string) => {},
  unmount: () => {},
  async callWithCaptcha(
    callback: (
      captchaToken: string,
      captchaVersion: string,
      forceCaptcha?: boolean
    ) => any,
    action: string
  ) {
    return callback("", "");
  }
});

export const CaptchaProvider: React.FC = ({ children }) => {
  const store = useWSStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(0);
  const modalClose = useRef<any>();
  const v2Token = useRef<string | null>(null);
  const waitForScript = useWaitFor(store.captchaScriptIsLoaded);
  const placeholderId = `wingspan-recaptcha-${updatedAt}`;

  useLoadCaptcha();

  const v2GetToken = async (): Promise<string> => {
    if (v2Token.current) {
      return v2Token.current;
    }

    setIsOpen(true);
    await new Promise(r => setTimeout(r, 250));
    return await new Promise<string>((resolve, reject) => {
      try {
        window.grecaptcha.render(placeholderId, {
          sitekey: RECAPTCHA_V2_SITE_KEY,
          callback: (token: string) => {
            v2Token.current = token;
            resolve(token);
            setIsOpen(false);
          },
          "expired-callback": (error: any) => {
            v2Token.current = null;
            window.grecaptcha.reset(RECAPTCHA_V2_SITE_KEY);
            setUpdatedAt(Date.now);
            reject(error);
          },
          "error-callback": (error: any) => {
            setUpdatedAt(Date.now);
            reject(error);
          },
          theme: "light",
          size: "normal",
          tabIndex: 0
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  const v3GetToken = async (action: string): Promise<string> => {
    return await new Promise<string>((resolve, reject) => {
      try {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute(RECAPTCHA_V3_SITE_KEY, { action })
            .then((token: string): void => {
              resolve(token);
            });
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  const v2Reset = (): void => {
    try {
      window.grecaptcha.reset(RECAPTCHA_V2_SITE_KEY);
    } catch (e) {}
  };

  const v3Reset = (): void => {
    try {
      window.grecaptcha.reset(RECAPTCHA_V3_SITE_KEY);
    } catch (e) {}
  };

  const resetReCaptcha = (): void => {
    v2Reset();
    v3Reset();
  };

  return (
    <>
      {isMounted ? (
        <WSElement
          className={isOpen ? styles.isOpen : styles.isHidden}
          key={updatedAt}
        >
          <WSElement className={styles.modal}>
            <WSText mb="XL">
              Sorry! We need to verify that you're not a robot... <br />
              Please complete the challenge below.
            </WSText>
            <WSFlexBox.Center>
              <div id={placeholderId} />
            </WSFlexBox.Center>
          </WSElement>
        </WSElement>
      ) : null}
      <CaptchaContext.Provider
        value={{
          mount: () => {
            resetReCaptcha();
            setIsMounted(true);
          },
          unmount: () => {
            setIsMounted(false);
            setUpdatedAt(Date.now());
          },
          callWithCaptcha: async (callback, action) => {
            let forceCaptcha = false;

            if (IS_DEV_ENV) {
              forceCaptcha = !!window.localStorage.getItem("FORCE_CAPTCHA");
            }

            if (window.TEST_ENV) {
              return callback("", "");
            }

            try {
              await waitForScript(30000);
            } catch {
              throw new Error(
                "An unexpected error occurred verifying your request. Please try refreshing the page before trying again."
              );
            }

            try {
              const captchaToken = await v3GetToken(action);
              return await callback(
                captchaToken,
                RECAPTCHA_VERSION_3,
                forceCaptcha
              );
            } catch (error) {
              if (
                (error as WSServiceError)?.response?.data.error ===
                CaptchaV3FailureMessage
              ) {
                const captchaToken = await v2GetToken();
                return await callback(captchaToken, RECAPTCHA_VERSION_2);
              } else {
                resetReCaptcha();
                throw error;
              }
            }
          }
        }}
      >
        {children}
      </CaptchaContext.Provider>
    </>
  );
};

export const useCaptcha = <R extends any>(action: string) => {
  const { mount, unmount, callWithCaptcha } = React.useContext(CaptchaContext);

  useEffect(() => {
    mount(action);

    return () => unmount();
  }, []);

  return (callback: Parameters<typeof callWithCaptcha>[0]) =>
    callWithCaptcha(callback, action);
};

export const CaptchaV3FailureMessage =
  "Verification failed, please try your request again.";
