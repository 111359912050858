import {
  toWSMoneyString,
  useWSModal,
  WSActions,
  WSAvatar,
  WSControl,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSGrid,
  WSInfoBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { IRequirementDefinitionResponse } from "@wingspanhq/payments/dist/interfaces";
import styles from "./styles.module.scss";
import React from "react";

interface BackgroundCheckFeatureUpgradeModalProps {
  onClose: () => void;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
}

const ListItem: React.FC<{ label: string }> = ({ label }) => {
  return (
    <WSFlexBox.CenterY wrap="nowrap">
      <WSAvatar
        type="icon"
        format="square"
        icon="shield-check-fill"
        colorBackground="blue500"
        color="white"
        size="S"
        mr="M"
      />
      <WSText.ParagraphSm>{label}</WSText.ParagraphSm>
    </WSFlexBox.CenterY>
  );
};

const BackgroundCheckFeatureUpgradeModal: React.FC<
  BackgroundCheckFeatureUpgradeModalProps
> = ({ onClose, onSuccess }) => {
  return (
    <WSList gap="L">
      <WSInfoBox title="How it works?">
        To get started with background checks, select a background check package
        and request the upgrade with your account manager or sales
        representative. Once a contract has been completed for the background
        check, you will be invited to get set up with the background check
        portal.
      </WSInfoBox>

      <WSGrid gutter="M">
        <WSGrid.Item span={{ s: "6", m: "4" }}>
          <WSPanel className={styles.panel}>
            <WSList gap="L">
              <WSFlexBox.CenterY>
                <WSAvatar type="wingspan" size="M" mr="M" />
                <WSText.Paragraph>Basic</WSText.Paragraph>
              </WSFlexBox.CenterY>
              <WSFlexBox.CenterY>
                <WSText.Heading3 mr="M">{toWSMoneyString(27)}</WSText.Heading3>
                <WSText.Paragraph color="gray600">per check</WSText.Paragraph>
              </WSFlexBox.CenterY>
              <ListItem label="SSN Trace" />
              <ListItem label="Sex Offender Registry" />
              <ListItem label="Global Watchlist Records" />
              <ListItem label="National Criminal Records" />
              <WSElement className={styles.divider}></WSElement>
              <WSDivider />
              <WSActions
                alignment="left"
                buttons={[
                  {
                    label: "View",
                    kind: "Link",
                    rightIcon: "open-tab"
                  }
                ]}
              />
            </WSList>
          </WSPanel>
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "6", m: "4" }}>
          <WSPanel className={styles.panel}>
            <WSList gap="L">
              <WSFlexBox.CenterY>
                <WSAvatar type="wingspan" size="M" mr="M" />
                <WSText.Paragraph>Standard</WSText.Paragraph>
              </WSFlexBox.CenterY>
              <WSFlexBox.CenterY>
                <WSText.Heading3 mr="M">
                  {toWSMoneyString(47.95)}
                </WSText.Heading3>
                <WSText.Paragraph color="gray600">per check</WSText.Paragraph>
              </WSFlexBox.CenterY>
              <ListItem label="SSN Trace" />
              <ListItem label="Sex Offender Registry" />
              <ListItem label="Global Watchlist Records" />
              <ListItem label="National Criminal Records" />
              <ListItem label="Unlimited Country Criminal Search" />
              <ListItem label="Motor Vehicle Report" />
              <ListItem label="National Criminal Records" />
              <WSElement className={styles.divider}></WSElement>
              <WSDivider />
              <WSActions
                alignment="left"
                buttons={[
                  {
                    label: "View",
                    kind: "Link",
                    rightIcon: "open-tab"
                  }
                ]}
              />
            </WSList>
          </WSPanel>
        </WSGrid.Item>
        <WSGrid.Item span={{ s: "6", m: "4" }}>
          <WSPanel className={styles.panel}>
            <WSList gap="L">
              <WSFlexBox.CenterY>
                <WSAvatar type="wingspan" size="M" mr="M" />
                <WSText.Paragraph>Premium</WSText.Paragraph>
              </WSFlexBox.CenterY>
              <WSFlexBox.CenterY>
                <WSText.Heading3 mr="M">
                  {toWSMoneyString(70.75)}
                </WSText.Heading3>
                <WSText.Paragraph color="gray600">per check</WSText.Paragraph>
              </WSFlexBox.CenterY>
              <ListItem label="SSN Trace" />
              <ListItem label="Sex Offender Registry" />
              <ListItem label="Global Watchlist Records" />
              <ListItem label="National Criminal Records" />
              <ListItem label="Unlimited Country Criminal Search" />
              <ListItem label="Motor Vehicle Report" />
              <ListItem label="Education Verification (Highest Level)" />
              <ListItem label="Employment Verification (Current)" />
              <WSElement className={styles.divider}></WSElement>
              <WSDivider />
              <WSActions
                alignment="left"
                buttons={[
                  {
                    label: "View",
                    kind: "Link",
                    rightIcon: "open-tab"
                  }
                ]}
              />
            </WSList>
          </WSPanel>
        </WSGrid.Item>
      </WSGrid>

      <WSPanel>
        <WSControl
          type="checkbox"
          onChange={() => {}}
          size="S"
          label="I need a custom solution, add-ons, or additional support on background check packages."
          name="terms"
          value={false}
        />
      </WSPanel>

      <WSActions
        buttons={[
          {
            label: "Request background check upgrade",
            kind: "Primary",
            name: "submit",
            onClick: async () => {
              onClose();
            }
          }
        ]}
        alignment="fill"
      />
    </WSList>
  );
};

export function useBackgroundCheckFeatureUpgradeModal() {
  return useWSModal(BackgroundCheckFeatureUpgradeModal, {
    title: "Background check feature upgrade",
    size: "XL"
  });
}
