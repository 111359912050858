import {
  useIsMobile,
  useModalContext,
  WSAvatar,
  WSDivider,
  WSFlexBox,
  WSGrid,
  WSIcon,
  WSInfiniteScroll,
  WSPage,
  WSPageProps,
  WSTable,
  WSTableColumn,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  ISubscriptionGrantResponse,
  SubscriptionGrantStatus
} from "@wingspanhq/users/dist/lib/interfaces";
import flatten from "lodash/flatten";
import * as React from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { WSPersistentUpgradeButton } from "../../../../components/Membership/WSPersistentUpgradeButton";
import { useSubscriptionGrantListQuery } from "../../../../query/subscriptions/queries";
import { useAuthorizationsInfinite } from "../../../../query/users/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { WSFrontendFeature } from "../../../../Settings/utils/subscriptionUtils";
import {
  getAuthorizedAccountName,
  getMemberName,
  INormalizedScopeGroupId,
  ITeamMemberRecord,
  getTeamMemberList,
  scopeGroups
} from "../../../../shared/utils/teamUtils";
import { AddTeamMember } from "../RouteAddTeamMember/AddTeamMember";
import {
  DELETE_TEAMMATE_MODAL,
  DeleteTeammateModal
} from "../../../../Settings/screens/DeleteTeammateModal";
import { EditTeamMember } from "../RouteEditTeamMember/EditTeamMember";
import styles from "./TeamTab.module.scss";
import { SUBSCRIPTION_SELECT_OPTIONS } from "../../constants/subscriptionOptions";

export interface TeamTabProps {}

export const TeamTab: React.FC<TeamTabProps> = () => {
  const history = useHistory();
  const qAuthorizations = useAuthorizationsInfinite();
  const qSubscriptionGrantsList = useSubscriptionGrantListQuery();

  const { openModal } = useModalContext();
  const isMobile = useIsMobile();

  const getScopesText = (scopeGroupIds: Array<INormalizedScopeGroupId>) => {
    if (scopeGroupIds.length === 0) {
      return "No permissions";
    }
    const scopesList = scopeGroupIds
      .map(({ allowedScopeGroupId }: INormalizedScopeGroupId) => {
        // @ts-ignore
        return scopeGroups[allowedScopeGroupId as string].title.toLowerCase();
      })
      .sort();
    if (isMobile) {
      if (scopesList.length <= 2) {
        return scopesList.join(", ");
      }
      return `${scopesList[0]}, + ${scopesList.slice(1).length} more`;
    }
    return scopesList.join(", ");
  };
  const content = (
    <>
      <DeleteTeammateModal />
      <WSDivider />

      <Switch>
        <Route
          path="/member/settings/team/add-team-member"
          component={AddTeamMember}
          exact
        />

        <Route
          path="/member/settings/team/edit-team-member"
          component={EditTeamMember}
          exact
        />
      </Switch>

      <WSQueries queries={{ qSubscriptionGrantsList }}>
        {({ qSubscriptionGrantsList: { data: subscriptionGrantsList } }) => {
          const columnsNoHeader: Array<WSTableColumn> = [
            {
              config: {
                gridTemplateSizeMax: isMobile ? "1fr" : "0.5fr"
              },
              renderFunction: (rowItem: any) => {
                const tableRecord = rowItem.data as ITeamMemberRecord;
                const name = getAuthorizedAccountName(
                  tableRecord.teamMemberWSMember,
                  tableRecord.teamMemberWSUser
                );
                const memberName = getMemberName(
                  tableRecord.teamMemberWSMember
                );
                const scopes = getScopesText(
                  tableRecord.authorization?.allowedScopeGroupIds || []
                );
                let colText;

                const subscriptionGrant =
                  tableRecord.subscriptionGrant as ISubscriptionGrantResponse;
                colText = !!subscriptionGrant
                  ? SUBSCRIPTION_SELECT_OPTIONS.find(sub => {
                      return (
                        subscriptionGrant.package === sub.value.package &&
                        subscriptionGrant.packageTier === sub.value.packageTier
                      );
                    })?.title
                  : "";

                return (
                  <WSFlexBox.CenterY
                    wrap="nowrap"
                    justify="space-between"
                    data-testdata={tableRecord.teamMemberWSUser.email}
                  >
                    <WSAvatar.Text size="M" mr="M" text={name.charAt(0)} />
                    {isMobile ? (
                      <WSFlexBox
                        direction="column"
                        className={styles.memberName}
                      >
                        <WSText singleLine>{name}</WSText>
                        {memberName && (
                          <WSText.ParagraphSm singleLine color="gray500">
                            {memberName}
                          </WSText.ParagraphSm>
                        )}
                        <WSText.ParagraphSm
                          singleLine
                          color="gray500"
                          data-testid="teamMemberPermissions"
                        >
                          {scopes}
                        </WSText.ParagraphSm>
                      </WSFlexBox>
                    ) : (
                      <WSFlexBox
                        direction="column"
                        className={styles.memberName}
                      >
                        <WSText singleLine>{name}</WSText>
                        {memberName && (
                          <WSText.ParagraphSm singleLine color="gray500">
                            {memberName}
                          </WSText.ParagraphSm>
                        )}
                      </WSFlexBox>
                    )}
                    {isMobile && colText ? (
                      <WSText
                        color="gray500"
                        data-testid="teamMemberSubscription"
                      >
                        {colText}
                      </WSText>
                    ) : null}
                  </WSFlexBox.CenterY>
                );
              }
            }
          ];

          columnsNoHeader.push({
            config: {
              hideOnScreens: ["XS"],
              gridTemplateSizeMax: "0.5fr"
            },
            renderFunction: rowItem => {
              const subscriptionGrant = rowItem.data
                .subscriptionGrant as ISubscriptionGrantResponse;
              const colText = SUBSCRIPTION_SELECT_OPTIONS.find(sub => {
                return (
                  subscriptionGrant.package === sub.value.package &&
                  subscriptionGrant.packageTier === sub.value.packageTier
                );
              })?.title;
              return rowItem.data.subscriptionGrant && colText ? (
                <WSFlexBox.CenterY>
                  <WSIcon block name="star" mr="XS" color="gray500" />
                  <WSText singleLine data-testid="teamMemberSubscription">
                    {colText}
                  </WSText>
                </WSFlexBox.CenterY>
              ) : null;
            }
          });

          columnsNoHeader.push({
            config: {
              hideOnScreens: ["XS"],
              gridTemplateSizeMax: "0.5fr",
              justify: "end"
            },
            renderFunction: rowItem => {
              const colText = getScopesText(
                rowItem.data.authorization?.allowedScopeGroupIds
              );
              return (
                <WSText singleLine data-testid="teamMemberPermissions">
                  {colText}
                </WSText>
              );
            }
          });
          const teamMembers = getTeamMemberList(
            flatten(qAuthorizations.data || []),
            subscriptionGrantsList.filter(
              sg => sg.status !== SubscriptionGrantStatus.Canceled
            )
          );
          const tableData = teamMembers.map(tm => {
            return {
              id: tm.teamMemberUserId,
              data: tm
            };
          });
          return (
            <WSInfiniteScroll
              onLoad={() => {
                qAuthorizations.fetchMore();
              }}
              loadMore={teamMembers.length > 0}
              endOfList={!qAuthorizations.canFetchMore}
              loading={!!qAuthorizations.isFetchingMore}
            >
              <WSTable
                bottomMessage={
                  tableData.length === 0 ? (
                    <WSFlexBox justify="center" mt="M">
                      <WSText>
                        Grant full or partial access to your account.{" "}
                      </WSText>
                      <WSPersistentUpgradeButton
                        feature={WSFrontendFeature.Teams}
                        onClick={() => {
                          history.push("/member/settings/team/add-team-member");
                        }}
                        kind="Link"
                      >
                        Add team member
                      </WSPersistentUpgradeButton>
                    </WSFlexBox>
                  ) : (
                    ""
                  )
                }
                rowMenuActions={(rowItem: any) => [
                  {
                    label: "Edit",
                    onClick: () => {
                      history.push({
                        pathname: "/member/settings/team/edit-team-member",
                        state: {
                          teamMember: rowItem.data
                        }
                      });
                    }
                  },
                  {
                    label: "Delete",
                    onClick: () =>
                      openModal(DELETE_TEAMMATE_MODAL, {
                        teamMember: rowItem.data
                      })
                  }
                ]}
                columns={columnsNoHeader}
                // @ts-ignore
                tableData={tableData}
              />
            </WSInfiniteScroll>
          );
        }}
      </WSQueries>
    </>
  );
  const wsPageProps: WSPageProps = {};
  wsPageProps.primaryButtonComponent = props => {
    return (
      <WSPersistentUpgradeButton
        onClick={() => {
          history.push("/member/settings/team/add-team-member");
        }}
        feature={WSFrontendFeature.Teams}
        name="addTeamMember"
        {...props}
      >
        Add team member
      </WSPersistentUpgradeButton>
    );
  };
  return (
    <WSPage title="Team" {...wsPageProps}>
      <WSGrid>
        <WSGrid.Item span={{ m: "12" }}>{content}</WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
