import { v4 as uuidv4 } from "uuid";
import StackdriverErrorReporter from "stackdriver-errors-js";

import { track } from "../../utils/analytics";

export enum CatchType {
  UnhandledError = "UnhandledError",
  ErrorBoundary = "ErrorBoundary",
  HandledError = "HandledError"
}

export const CatchTypePropName = "catchType";

interface StackdriverErrorReporterOptions {
  skipLocalFrames?: number;
}

interface ContextExtra {
  [CatchTypePropName]: string;
  data?: any[];
}

export type ErrorReporterOptions = StackdriverErrorReporterOptions &
  ContextExtra;

const consoleErrorOriginal = console.error.bind(console);

const notLoggedMessageFragments: string[] = [
  "Request failed with status code",
  "Looks like Portal takes too long to load.",
  "No authorization token was found",
  "Sorry, please reload your page!",
  "Network Error"
];

const hasNotLoggedMessageFragment = (errorMessage: string) =>
  notLoggedMessageFragments.some(notLoggedMessageFragment =>
    errorMessage.includes(notLoggedMessageFragment)
  );

const shouldSendErrorToBackEnd = (error: any) => {
  const errorMessage = error.message || error;

  return typeof errorMessage === "string"
    ? !hasNotLoggedMessageFragment(errorMessage)
    : true;
};

const stackdriverReportOriginal = StackdriverErrorReporter.prototype.report;

StackdriverErrorReporter.prototype.report = async function (
  error: any,
  { skipLocalFrames, ...contextExtra }: ErrorReporterOptions
) {
  consoleErrorOriginal(error, ...(contextExtra.data ? contextExtra.data : []));
  if (!shouldSendErrorToBackEnd(error)) return;

  const contextOriginal = this.context;
  const reportId = uuidv4();
  this.context = { ...this.context, reportId, ...contextExtra };

  try {
    track(`Error Occurred`, { contents: error });
    await stackdriverReportOriginal.call(this, error, { skipLocalFrames });
    consoleErrorOriginal(
      'Reported the above error with reportId "%s".',
      reportId
    );
  } catch (err) {
    consoleErrorOriginal(
      "Error occured during reporting of the above error via Stackdriver:"
    );
    consoleErrorOriginal(err);
  } finally {
    if (contextExtra) this.context = contextOriginal;
  }
};

export default StackdriverErrorReporter;
