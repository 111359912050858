import "@wingspanhq/fe-component-library/dist/style.css";
import { SessionType } from "@wingspanhq/users/dist/lib/interfaces";
import "primereact/resources/primereact.min.css";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./services/stackdriverErrorService";
import "@onefootprint/footprint-js/dist/footprint-js.css";
import { IPublicCustomizationSettingsResponse } from "@wingspanhq/users/dist/lib/interfaces/api/organizationAccountConfig";

declare global {
  interface Window {
    sessionType?: SessionType;
    userEmail: string;
    intercomUserHash: string;
    analytics?: {
      track(...prop: any): void;
      load(...prop: any): void;
      page(...prop: any): void;
      identify(...prop: any): void;
      reset(...prop: any): void;
      group(...prop: any): void;
    };
    grecaptcha: any;
    RESET_PASSWORD_TEST?: boolean;
    TEST_ENV?: boolean;
    wingspanReCaptchaOnLoad: Function;
    organizationAccountConfig?: IPublicCustomizationSettingsResponse;
    Intercom: any;
    VGSShow: any;
    VGSCollect: any;
    forceSendSegment: boolean;
    debugSegment: boolean;
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

if (process.env.NODE_ENV !== "production") {
  if ((module as any).hot) {
    (module as any).hot.accept("./components/App", function () {
      console.warn("HOT RELOADING...");

      const NextApp = require("./components/App").default;

      ReactDOM.render(<NextApp />, document.getElementById("root"));
    });
  }

  // Fix for running FE in codespace
  const LOCALHOST_IP_ADDRESS = "127.0.0.1";
  const LOCALHOST_DOMAIN_NAME = "localhost";
  if (window.location.host.startsWith(LOCALHOST_IP_ADDRESS)) {
    window.location.href = window.location.href.replace(
      LOCALHOST_IP_ADDRESS,
      LOCALHOST_DOMAIN_NAME
    );
  }
}

// add an event listener to remove the recaptcha offline warning from the body if it pops up
document.body.addEventListener(
  "DOMNodeInserted",
  function (event) {
    // console.log("Checking if recaptcha is throwing an warning..");
    const newNode = event.target as HTMLElement;
    if (
      typeof newNode.innerText === "string" &&
      newNode.innerText.startsWith(
        "Could not connect to the reCAPTCHA service."
      )
    ) {
      try {
        newNode.parentNode && newNode.parentNode.removeChild(newNode);
        console.log("Removed recaptcha warning");
      } catch (e) {
        console.error(
          `Tried removing recaptcha warning and failed: ${(e as any).message}`
        );
      }
    }
  },
  false
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export {
  NEC_1099_PAYMENTS_SLUG,
  NEC_1099_SUMMARY_DISPUTE_SLUG,
  NEC_1099_SUMMARY_SLUG
} from "./shared/constants/nec1099";
