import { WSElement, WSPage } from "@wingspanhq/fe-component-library";
import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory
} from "react-router-dom";
import { Tabs } from "../../../components/Tabs";
import styles from "./DeductionsIndex.module.scss";
import { DeductionsCompleted } from "./DuductionsCompleted";
import { DeductionsScheduled } from "./DuductionsScheduled";
import { AddDeduction } from "./modals/AddDeduction";

export const DeductionsIndex: React.FC<
  RouteComponentProps<{
    collaboratorId: string;
  }>
> = props => {
  const history = useHistory();

  return (
    <WSPage
      breadcrumb={{
        label: "Collaborators",
        icon: "arrow-left",
        onClick: () => {
          history.push(
            `/member/invoices/contacts/collaborators/${props.match.params.collaboratorId}`
          );
        }
      }}
    >
      <AddDeduction />
      <Tabs
        tabs={[
          {
            path: `/member/invoices/contacts/collaborators/${props.match.params.collaboratorId}/deductions/scheduled`,
            label: "Scheduled"
          },
          {
            path: `/member/invoices/contacts/collaborators/${props.match.params.collaboratorId}/deductions/completed`,
            label: "Completed"
          }
        ]}
      />
      <WSElement className={styles.wrapper}>
        <Switch>
          <Route
            path="/member/invoices/contacts/collaborators/:collaboratorId/deductions/scheduled"
            component={DeductionsScheduled}
          />
          <Route
            path="/member/invoices/contacts/collaborators/:collaboratorId/deductions/completed"
            component={DeductionsCompleted}
          />
          <Redirect from="*" to="/member/invoices/deductions/scheduled" />
        </Switch>
      </WSElement>
    </WSPage>
  );
};
