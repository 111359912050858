import { RouteComponentProps } from "react-router-dom";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../..";
import React from "react";
import { CreateRequirementDefinitionForm } from "../../../Requirements/components/CreateRequirementDefinitionForm";
import { PAYEES_ENGAGEMENTS_LIST_PATH } from "../../../Payees/utils";

export const RouteCreateRequirement: React.FC<
  RouteComponentProps<{}, {}, { engagementId: string }>
> = ({ location: { state }, history }) => {
  const engagementId = state?.engagementId;

  return (
    <CreateRequirementDefinitionForm
      engagementId={engagementId}
      onBack={() => {
        history.replace(ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH);
      }}
      onSuccess={requirement => {
        if (engagementId) {
          history.push(`${PAYEES_ENGAGEMENTS_LIST_PATH}/${engagementId}`);
        } else {
          history.push(
            `${ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}/${requirement.requirementDefinitionId}`
          );
        }
      }}
    />
  );
};
