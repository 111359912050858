import React, { useEffect } from "react";
import { FlatfileError } from "@flatfile/sdk/dist/errors/FlatfileError";

import { WSButton, WSButtons, WSText } from "@wingspanhq/fe-component-library";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkResource } from "../../types";
import {
  BatchProcessingStrategy,
  IBulkBatch
} from "@wingspanhq/payments/dist/interfaces";
import { getImportErrorUserMessageByErrorCode } from "../../utils/getImportErrorUserMessageByErrorCode";

export interface FlatfileErrorModalProps {
  error: FlatfileError;
  userMessage?: string;

  batchId: string;
  bulkResource: BulkResource;
  onStartNewImport: (bulkBatch: IBulkBatch) => void;
  onClose(): void;
}

export function FlatfileErrorModal({
  error,
  userMessage,
  batchId,
  bulkResource,
  onStartNewImport,
  onClose
}: FlatfileErrorModalProps) {
  const { useUpdateBulkBatch, useCreateBulkBatch } =
    getBulkQueriesByResource(bulkResource);

  const [createBulkBatch, createBulkBatchMeta] = useCreateBulkBatch();
  const [updateBulkBatch] = useUpdateBulkBatch();

  const saveError = async () => {
    await updateBulkBatch({
      id: batchId,
      data: {
        labels: {
          [BATCH_LABELS_WS_KEYS.bulkImporterErrorCode]: error.code,
          [BATCH_LABELS_WS_KEYS.bulkImporterErrorDebug]: error.debug,
          [BATCH_LABELS_WS_KEYS.bulkImporterErrorUserMessage]: error.userMessage
        }
      }
    });
  };

  useEffect(() => {
    saveError();
  }, []);

  const onCloseModal = () => {
    onClose();
  };

  const startNewImport = async () => {
    let createBulkBatchRequest;
    if (bulkResource === BulkResource.Payable) {
      createBulkBatchRequest = {
        processingStrategy: BatchProcessingStrategy.Merge
      };
    }
    await createBulkBatch(createBulkBatchRequest, {
      onSuccess: response => {
        onStartNewImport(response);
        onClose();
      }
    });
  };

  return (
    <>
      <WSText color="gray500" mb="XL">
        {userMessage ||
          getImportErrorUserMessageByErrorCode(error.code, error.userMessage)}
      </WSText>
      <WSButtons format="modal" justify="flex-start">
        <WSButton
          name="startNewImport"
          loading={createBulkBatchMeta.isLoading}
          onClick={startNewImport}
        >
          Start new import
        </WSButton>
        <WSButton.Tertiary name="cancelStartNewImport" onClick={onCloseModal}>
          Close
        </WSButton.Tertiary>
      </WSButtons>
    </>
  );
}
