import React from "react";
import {
  WSButton,
  WSElement,
  WSFlexBox,
  WSPage,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { WSQueries } from "../../../../query/WSQuery";
import { useNotifications } from "../../../../query/notifications/queries/useNotifications";
import { selectorBackgroundCheckRequestContractorNotifications } from "../../selectors/selectorBackgroundCheckRequestContractorNotifications";
import { useUserId } from "../../../../query/hooks/helpers";
import { useTrackClickNotification } from "../../utils/useTrackClickNotification";
import styles from "./styles.module.scss";
import { useBackgroundChecksStartModal } from "../../../Requirements/modules/BackgroundChecks/modals/useBackgroundChecksStartModal";

type RequestsToComplete = {
  title: string;
  description: string;
  onClick: () => Promise<void>;
};

export const RouteBackgroundChecksToComplete: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const trackClickNotification = useTrackClickNotification(userId);
  const queryNotifications = useNotifications();
  const backgroundChecksStartModal = useBackgroundChecksStartModal();

  return (
    <WSQueries queries={{ queryNotifications }}>
      {({ queryNotificationsData }) => {
        const requests: RequestsToComplete[] = [];

        const notifications =
          selectorBackgroundCheckRequestContractorNotifications(
            queryNotificationsData
          );
        notifications.forEach(notification => {
          const title = notification.context.title;
          const payerName = notification.context.payer.payerName;

          requests.push({
            title,
            description: `Company ${payerName} has requested a background check ${title} to complete the onboarding process.`,
            onClick: async () => {
              trackClickNotification(notification);
              await backgroundChecksStartModal.open({
                backgroundCheckRequestId:
                  notification.context.backgroundCheckRequestId
              });
            }
          });
        });

        return (
          <WSPage
            breadcrumb={{
              label: "Back",
              onClick() {
                history.push("/member/dashboard");
              },
              icon: "arrow-left"
            }}
          >
            <WSText.Heading4 my="XL">
              Background checks pending ({requests.length})
            </WSText.Heading4>
            {requests.length === 0 ? (
              <WSText.ParagraphSm mt="3XL" align="center" color="gray400">
                No background checks to complete
              </WSText.ParagraphSm>
            ) : (
              requests.map(({ title, description, onClick }) => {
                return (
                  <WSPanel
                    key={title}
                    mb="M"
                    as="label"
                    className={styles.item}
                  >
                    <WSFlexBox.CenterY justify="space-between">
                      <WSElement>
                        <WSText weight="medium" pb="M">
                          {title}
                        </WSText>
                        <WSText.ParagraphSm>{description}</WSText.ParagraphSm>
                      </WSElement>
                      <WSButton.Secondary size="S" onAsyncClick={onClick}>
                        Start
                      </WSButton.Secondary>
                    </WSFlexBox.CenterY>
                  </WSPanel>
                );
              })
            )}
          </WSPage>
        );
      }}
    </WSQueries>
  );
};
