import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { serviceNotifications } from "./serviceNotifications";

export const getNotifications = async (): Promise<
  IChannelNotificationResponse[]
> => {
  const { data } = await serviceNotifications.get("/in-app");

  return data;
};
