import {
  detachLayoutProps,
  useModalContext,
  WSCarousel,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSScreen
} from "@wingspanhq/fe-component-library";
import {
  NextgenSubscriptionPartner,
  NextgenSubscriptionPlanType
} from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { ChooseAccountForPaymentMethodsModal } from "../../../Invoices/components/ChooseAccountForPaymentMethodsModal/ChooseAccountForPaymentMethodsModal";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useNextGenSubscriptionPlans,
  useNextGenSubscriptions
} from "../../../query/subscriptions/queries";
import { WSQueries } from "../../../query/WSQuery";
import { prepareNextGenSubscriptionPlansData } from "../../utils/subscriptionUtils";
import { ChooseAddPaymentMethodFlowModal } from "../PaymentMethodsList/ChooseAddPaymentMethodFlowModal";
import { SavedPaymentMethodCreditCardModal } from "../PaymentMethodsList/SavedPaymentMethodCreditCardModal";
import { NextGenSubscriptionPlanCard } from "./NextGenSubscriptionPlanCard";
import styles from "./NextGenSubscriptionPlanCards.module.scss";
import { IDisplayNextGenSubscriptionPlan } from "./nextGenSubscriptionPlans";

export interface NextGenSubscriptionPlanCardsProps extends WSElementProps {
  isAnnualBilling: boolean;
  planType: NextgenSubscriptionPlanType;
  packages: IDisplayNextGenSubscriptionPlan[];
}

export const NextGenSubscriptionPlanCards: React.FC<
  NextGenSubscriptionPlanCardsProps
> = ({ isAnnualBilling, planType, packages, ...otherProps }) => {
  const userId = useUserId();
  const history = useHistory();
  const { closeModal } = useModalContext();
  const nextGenSubscriptionPlansQuery = useNextGenSubscriptionPlans(userId);
  const nextGenSubscriptionsQuery = useNextGenSubscriptions(userId);
  const { layoutProps } = detachLayoutProps(otherProps);
  return (
    <WSQueries
      queries={{ nextGenSubscriptionPlansQuery, nextGenSubscriptionsQuery }}
    >
      {({
        nextGenSubscriptionPlansQuery: { data: nextGenSubscriptionPlans }
      }) => {
        const subscriptionPackages = prepareNextGenSubscriptionPlansData(
          nextGenSubscriptionPlans,
          packages,
          planType,
          NextgenSubscriptionPartner.USA
        );
        return (
          <WSElement {...layoutProps}>
            <ChooseAccountForPaymentMethodsModal />
            <ChooseAddPaymentMethodFlowModal />
            <SavedPaymentMethodCreditCardModal />
            <WSScreen.Desktop>
              <WSFlexBox alignItems="flex-end" wrap="nowrap" mx="XL">
                {subscriptionPackages.map(subscriptionPackage => (
                  <NextGenSubscriptionPlanCard
                    key={subscriptionPackage.name}
                    isAnnualBilling={isAnnualBilling}
                    subscriptionPackage={subscriptionPackage}
                  />
                ))}
              </WSFlexBox>
            </WSScreen.Desktop>
            <WSScreen.MobileAndTablet>
              <WSCarousel
                className={styles.carousel}
                defaultItemIdx={1}
                items={subscriptionPackages.map(subscriptionPackage => (
                  <NextGenSubscriptionPlanCard
                    key={subscriptionPackage.name}
                    isAnnualBilling={isAnnualBilling}
                    subscriptionPackage={subscriptionPackage}
                  />
                ))}
                width={280}
              />
            </WSScreen.MobileAndTablet>
          </WSElement>
        );
      }}
    </WSQueries>
  );
};
