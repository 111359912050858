import {
  IMember,
  INewUser,
  IRedactedMember,
  IRedactedUser
} from "@wingspanhq/users/dist/lib/interfaces";
import { selectorUserLegalName } from "./selectorUserLegalName";

export const selectorMemberName = (
  member: IMember | IRedactedMember
): string => {
  if (member.profile?.company?.name) {
    return member.profile.company.name;
  } else if (member.user.profile?.firstName && member.user.profile.lastName) {
    return `${member.user?.profile?.firstName} ${member.user?.profile?.lastName}`;
  } else if (member.user.email) {
    return member.user.email;
  }

  return "";
};
