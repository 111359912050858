import { IMember } from "@wingspanhq/users";
import merge from "lodash/merge";
import { useMemo } from "react";
import { components } from "../../../services/api/onboarding/types";
import { selectorCountryName } from "../../../shared/selectors/selectorCountryName";
import { DataView } from "../../../shared/components/DataView";
import { FormDataAccountType } from "./FormAccountType";
import { getProfileDataAccountType } from "./FormAccountType/getProfileDataAccountType";

type Props = {
  monitoredEntity?: components["schemas"]["CustomerEntity"];
  member?: IMember;
  formData?: FormDataAccountType;
  onEdit?: () => void;
};

export const ReviewAccountType: React.FC<Props> = ({
  monitoredEntity,
  member,
  formData,
  onEdit
}) => {
  const profileData = useMemo(
    () => getProfileDataAccountType(monitoredEntity, member),
    [monitoredEntity, member]
  );

  const data = useMemo(() => {
    if (!formData) {
      return profileData;
    }

    return merge(profileData, formData);
  }, [formData, profileData]);

  return (
    <DataView
      title="Account type"
      onEdit={onEdit}
      data={[
        {
          label:
            data.type === "Business"
              ? "Country of Incorporation"
              : "Country of Tax Residence",
          value: data.country ? selectorCountryName(data.country) : undefined
        },
        {
          label: "Account type",
          value: data.type
        }
      ]}
    />
  );
};
