import {
  WSActions,
  WSCard,
  WSElement,
  WSForm,
  WSInputText,
  WSList,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import * as Yup from "yup";
import { FlowMEChangeContext } from "..";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { validatorCompanyName } from "../../../../../shared/validators/validatorCompanyName";
import { validatorStringTextNumbersSymbols } from "../../../../../shared/validators/validatorStringTextNumbersSymbols";
import { ReviewOriginalBusinessName } from "../../../components/ReviewOriginalBusinessName";
import { OnboardingStep } from "../../../types";

export type FormDataBusinessLegalName = {
  legalBusinessName: string;
  dba: string;
};

export const StepImmaterialUpdateBusinessLegalName: React.FC<
  OnboardingStep
> = props => {
  const [context, setContext] = useFlowContext<FlowMEChangeContext>();

  return (
    <WSForm<FormDataBusinessLegalName>
      defaultValues={context.businessLegalName}
      validationSchema={Yup.object().shape({
        legalBusinessName: validatorCompanyName.required("Required"),
        dba: validatorStringTextNumbersSymbols
      })}
      onSubmit={data => {
        setContext(prev => ({ ...prev, businessLegalName: data }));
        props.onNext?.();
      }}
    >
      <WSList gap="2XL">
        <WSElement>
          <WSSectionToolbar title="Update taxpayer information" />
          <WSText.ParagraphSm mt="L" color="gray500">
            Please review to ensure the information is accurate before
            submitting
          </WSText.ParagraphSm>
        </WSElement>

        <ReviewOriginalBusinessName />

        <WSCard
          header={{
            label: "Legal business name change",
            value: {
              pill: {
                theme: "warning",
                text: "Requested update"
              }
            }
          }}
          divider
        >
          <WSList gap="2XL">
            <WSForm.Field
              name="legalBusinessName"
              label="Legal business name"
              component={WSInputText}
              componentProps={{
                required: true,
                placeholder: "Enter business name",
                fsExclude: true,
                helperText:
                  "The official registered name of the entity when it was formed"
              }}
            />

            <WSForm.Field
              name="dba"
              label="Doing business as (DBA) (optional)"
              component={WSInputText}
              componentProps={{
                placeholder: "Enter DBA",
                fsExclude: true,
                helperText:
                  "Additional name under which the business operates and recognized in the marketplace"
              }}
            />
          </WSList>
        </WSCard>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              onClick: props.onBack,
              kind: "Secondary",
              type: "button",
              visible: !!props.onBack
            },
            {
              label: "Continue"
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
