import {
  WSPill,
  WSAvatar,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { DocumentRole } from "@wingspanhq/files/dist/lib/interfaces";
import React from "react";
import { useTemplateQuery } from "../../../query/files/queries";
import {
  useCollaboratorGroupsQuery,
  useEligibilityRequirementQuery
} from "../../../query/payments/queries";
import { WSQueries } from "../../../query/WSQuery";
import { pluralize } from "../../../utils/stringHelper";
import { CollaboratorSignatureDiagram } from "../../components/CollaboratorSignatureDiagram/CollaboratorSignatureDiagram";

export const RequirementDetails: React.FC<
  {
    eligibilityRequirementId: string;
  } & WSElementProps
> = ({ eligibilityRequirementId, ...elementProps }) => {
  const eligibilityRequirementQuery = useEligibilityRequirementQuery(
    eligibilityRequirementId
  );
  const templateQuery = useTemplateQuery(
    eligibilityRequirementQuery.data?.templateId as string,
    { enabled: !!eligibilityRequirementQuery.data?.templateId }
  );

  return (
    <WSElement {...elementProps}>
      <WSQueries queries={{ eligibilityRequirementQuery, templateQuery }}>
        {({
          eligibilityRequirementQuery: { data: eligibilityRequirement },
          templateQuery: { data: template }
        }) => (
          <>
            <WSText.Heading4 mb="2XL">{template.title}</WSText.Heading4>

            <WSFlexBox wrap="nowrap" justify="space-between" mb="2XL">
              <WSElement mr="XL">
                {(template.roles || []).includes(DocumentRole.Client) ? (
                  <>
                    <WSAvatar.Icon
                      mb="M"
                      icon="group"
                      color="green500"
                      colorBackground="green50"
                    />
                    <WSText weight="medium" mb="XS">
                      Mutual
                    </WSText>
                    <WSText>
                      Both contractor and company signatures are required.
                    </WSText>
                  </>
                ) : (
                  <>
                    <WSAvatar.Icon
                      mb="M"
                      icon="user"
                      color="blue500"
                      colorBackground="blue50"
                    />
                    <WSText weight="medium" mb="XS">
                      Contractor
                    </WSText>
                    <WSText>Only a contractor signature is required.</WSText>
                  </>
                )}

                <WSText weight="medium" mb="XS" mt="XL">
                  Expiration
                </WSText>
                {eligibilityRequirement.validFor ? (
                  <WSText>
                    This document expires{" "}
                    {pluralize(eligibilityRequirement.validFor, {
                      one: "day",
                      other: "days"
                    })}{" "}
                    after each contractor signs it.
                  </WSText>
                ) : (
                  <WSText>This document does not expire.</WSText>
                )}
              </WSElement>
              <CollaboratorSignatureDiagram type="mutual" width={100} />
            </WSFlexBox>

            <GroupsWithCurrentRequirement
              eligibilityRequirementId={eligibilityRequirementId}
            />
          </>
        )}
      </WSQueries>
    </WSElement>
  );
};

const GroupsWithCurrentRequirement: React.FC<
  {
    eligibilityRequirementId: string;
  } & WSElementProps
> = ({ eligibilityRequirementId, ...elementProps }) => {
  const collaboratorGroupsQuery = useCollaboratorGroupsQuery();

  return (
    <WSQueries queries={{ collaboratorGroupsQuery }}>
      {({ collaboratorGroupsQuery: { data: collaboratorGroups } }) => {
        const matchingGroups = collaboratorGroups.filter(
          group =>
            !!(group.eligibilityRequirements || []).find(
              r => r.eligibilityRequirementId === eligibilityRequirementId
            )
        );

        return matchingGroups.length > 0 ? (
          <WSElement {...elementProps}>
            <WSText weight="medium" mb="XS">
              Required in groups
            </WSText>
            {matchingGroups.map(group => (
              <WSPill
                mr="M"
                mb="M"
                key={group.collaboratorGroupId}
                text={group.name}
              />
            ))}
          </WSElement>
        ) : null;
      }}
    </WSQueries>
  );
};
