import {
  WSElement,
  WSElementProps,
  WSInfiniteScroll,
  WSMenuItem,
  WSTable,
  WSTableCell,
  WSTableColumn,
  WSTableItem,
  toWSMoneyString
} from "@wingspanhq/fe-component-library";
import React, { useCallback } from "react";

import {
  IPayerTaxFormResponse,
  TaxFormCorrectionStatus,
  TaxFormDeliveryMethod,
  TaxFormStatus
} from "@wingspanhq/payments/dist/interfaces";
import { TaxFormViewer } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { INewUser } from "@wingspanhq/users";
import flatten from "lodash/flatten";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTaxFormDownloadDocument } from "../../../../../query/taxForm/mutations/useTaxFormDownloadDocument";
import { selectorMemberName } from "../../../../../shared/selectors/selectorMemberName";
import { downloadFileFromBlob } from "../../../../../utils/files";
import { FillingStatusBadge } from "../../../components/FillingStatusBadge";
import { TaxID } from "../../../components/TaxID";
import { selector1099NECTotal } from "../../../selectors/selector1099NECTotal";
import { selectorTaxFormIsEmailResendAvailable } from "../../../selectors/selectorTaxFormIsEmailResendAvailable";
import { selectorTaxFormIsPDFDownloadAvailableForPayer } from "../../../selectors/selectorTaxFormIsPDFDownloadAvailableForPayer";
import { useRecipientCorrectionExistsModal } from "../RouteTaxFormDetails/ModalRecipientCorrectionExists";
import { NameCell } from "./NameCell";
import { useModalResendElectronicCopy } from "./useModalResendElectronicCopy";
import { useModalResendMailCopy } from "./useModalResendMailCopy";

export interface NEC1099FilingSearchTableProps extends WSElementProps {
  queryTaxFormRowSearch: any;
  organizations: INewUser[];
}

export const NEC1099FilingSearchTable: React.FC<
  NEC1099FilingSearchTableProps
> = ({ queryTaxFormRowSearch, organizations, ...elementProps }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [downloadDocument, downloadDocumentMeta] = useTaxFormDownloadDocument();
  const modalResendElectronicCopy = useModalResendElectronicCopy();
  const modalResendMailCopy = useModalResendMailCopy();
  const recipientCorrectionExistsModal = useRecipientCorrectionExistsModal();

  const onView = useCallback(
    (item: WSTableItem) => {
      history.push(`/member/1099-filing/details/${item.id}`);
    },
    [history, match.url]
  );

  const firstColumns: WSTableColumn<IPayerTaxFormResponse>[] = [
    {
      config: {
        header: {
          text: "Name"
        }
      },
      renderFunction: ({ data }) => {
        try {
          return <NameCell taxForm={data} />;
        } catch (err) {
          console.log(err);
          return "err";
        }
      }
    }
  ];

  if (organizations.length > 0) {
    firstColumns.push({
      config: {
        hideOnScreens: ["XS", "S"],
        header: {
          text: "Org Account Name"
        }
      },
      renderFunction: ({ data }) => {
        try {
          return (
            <WSTableCell
              text={data.payer ? selectorMemberName(data.payer) : "-"}
            />
          );
        } catch (err) {
          console.log(err);
          return "err";
        }
      }
    });
  }

  const list = flatten(queryTaxFormRowSearch.data) || [];

  return (
    <WSElement {...elementProps}>
      <WSInfiniteScroll
        onLoad={() => {
          queryTaxFormRowSearch.fetchMore();
        }}
        loadMore={list.length > 0}
        endOfList={!queryTaxFormRowSearch.canFetchMore}
        loading={!!queryTaxFormRowSearch.isFetchingMore}
      >
        <WSTable<
          IPayerTaxFormResponse & {
            lastCorrectionStatus: TaxFormCorrectionStatus;
          }
        >
          onRowClick={onView}
          columns={[
            ...firstColumns,
            {
              config: {
                hideOnScreens: ["XS", "S"],
                header: {
                  text: "Tax ID"
                }
              },
              renderFunction: ({ data }) => {
                try {
                  return <TaxID taxForm={data} />;
                } catch (err) {
                  console.log(err);
                  return "err";
                }
              }
            },
            {
              config: {
                hideOnScreens: ["XS", "S", "M"],
                header: {
                  text: "Delivery"
                }
              },
              renderFunction: ({ data }) => {
                try {
                  return (
                    <WSTableCell
                      text={
                        data.data.deliveryMethod ===
                        TaxFormDeliveryMethod.Electronic
                          ? "Electronic"
                          : "Mail"
                      }
                    />
                  );
                } catch (err) {
                  console.log(err);
                  return "err";
                }
              }
            },
            {
              config: {
                header: {
                  text: "Filing Status"
                }
              },
              renderFunction: ({ data }) => {
                try {
                  /* TODO: use interface from search service */
                  return (
                    <FillingStatusBadge
                      status={data.status}
                      correctionStatus={data.lastCorrectionStatus}
                    />
                  );
                } catch (err) {
                  console.log(err);
                  return "err";
                }
              }
            },
            {
              config: {
                gridTemplateSizeMin: "min-content",
                gridTemplateSizeMax: "1fr",
                header: {
                  text: "1099-NEC Total"
                }
              },
              renderFunction: ({ data }) => {
                try {
                  const nec1099Total = selector1099NECTotal(data);

                  return Number.isFinite(nec1099Total) ? (
                    <WSTableCell text={toWSMoneyString(nec1099Total)} />
                  ) : null;
                } catch (err) {
                  console.log(err);
                  return "err";
                }
              }
            }
          ]}
          rowMenuActions={item => {
            const menuItems: WSMenuItem[] = [];

            menuItems.push({
              label: "View",
              onClick: () => {
                onView(item);
              }
            });

            menuItems.push({
              label:
                item.data.lastCorrectionStatus ===
                TaxFormCorrectionStatus.Pending
                  ? "Review correction request"
                  : "Start new correction",
              onClick: () => {
                const taxForm = item.data;
                if (
                  taxForm.lastCorrectionStatus ===
                  TaxFormCorrectionStatus.Pending
                ) {
                  recipientCorrectionExistsModal.open({
                    taxForm,
                    onReviewCorrectionRequest: () => {
                      if (taxForm.status === TaxFormStatus.AcceptedByIrs) {
                        history.push(
                          `/member/1099-filing/details/${taxForm.taxFormId}/review-correction-request`
                        );
                      } else {
                        history.push(
                          `/member/1099-filing/details/${taxForm.taxFormId}/view-correction`
                        );
                      }
                    }
                  });
                } else {
                  history.push(
                    `/member/1099-filing/details/${taxForm.taxFormId}/start-correction`
                  );
                }
              }
            });

            if (selectorTaxFormIsPDFDownloadAvailableForPayer(item.data)) {
              menuItems.push({
                label: "Download payer copy",
                onClick: () => {
                  downloadDocument(
                    {
                      taxFormId: item.data.taxFormId,
                      submissionId: item.data.currentSubmissionId as string,
                      viewer: TaxFormViewer.payer
                    },
                    {
                      onSuccess(pdf) {
                        downloadFileFromBlob(
                          pdf,
                          `1099-NEC_${item.data.year || ""}-payer-copy.pdf`
                        );
                      }
                    }
                  );
                }
              });
              menuItems.push({
                label: "Download recipient copy",
                onClick: () => {
                  downloadDocument(
                    {
                      taxFormId: item.data.taxFormId,
                      submissionId: item.data.currentSubmissionId as string,
                      viewer: TaxFormViewer.payee
                    },
                    {
                      onSuccess(pdf) {
                        downloadFileFromBlob(
                          pdf,
                          `1099-NEC_${item.data.year || ""}-recipient-copy.pdf`
                        );
                      }
                    }
                  );
                }
              });
            }

            if (selectorTaxFormIsEmailResendAvailable(item.data)) {
              menuItems.push({
                label: "Re-send electronic copy",
                onClick: () => {
                  modalResendElectronicCopy.open({
                    taxForm: item.data
                  });
                }
              });

              menuItems.push({
                label: "Re-mail tax forms",
                onClick: () => {
                  modalResendMailCopy.open({
                    taxForm: item.data
                  });
                }
              });
            }

            return menuItems;
          }}
          tableData={list.map((row: any) => {
            return {
              id: row.taxFormId,
              data: row
            };
          })}
        />
      </WSInfiniteScroll>
    </WSElement>
  );
};
