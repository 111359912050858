import {
  WSElement,
  WSInfiniteScroll,
  WSTable
} from "@wingspanhq/fe-component-library";
import { WSTableProps } from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { useMemo, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

const PAGE_SIZE = 10;

export const ReportsTable = <T extends object = any>(
  props: WSTableProps<T>
) => {
  const [page, setPage] = useState(0);
  const tableDataByPage = useMemo(
    () => props.tableData.slice(0, page * PAGE_SIZE),
    [props.tableData, page]
  );

  const columnLength = props.columns?.length || 0;
  const showScroll = columnLength > 6;

  return (
    <WSElement
      className={classNames(styles.rotate, {
        [styles.showScroll]: showScroll
      })}
    >
      <WSElement className={styles.rotate}>
        <WSInfiniteScroll
          onLoad={() => setPage(page + 1)}
          endOfList={page * PAGE_SIZE >= props.tableData.length}
          loadMore={props.tableData.length > 0}
        >
          <WSTable
            style={{
              minWidth: showScroll ? columnLength * 175 : undefined
            }}
            {...props}
            tableData={tableDataByPage}
          />
        </WSInfiniteScroll>
      </WSElement>
    </WSElement>
  );
};
