import React from "react";
import {
  WSContainer,
  WSElement,
  WSEmptyState,
  WSGrid
} from "@wingspanhq/fe-component-library";
import { InvitationAuthHeader } from "../../components/InvitationAuthHeader";

import { RouteComponentProps } from "react-router-dom";

export const RouteRequestLinkSuccess: React.FC<
  RouteComponentProps<{
    email: string;
  }>
> = ({
  match: {
    params: { email }
  }
}) => {
  return (
    <WSElement>
      <InvitationAuthHeader />
      <WSContainer>
        <WSElement pt="3XL" pb="2XL">
          <WSGrid gutter="2XL">
            <WSGrid.Item span={{ s: "12", m: "2" }} />
            <WSGrid.Item span={{ s: "12", m: "8" }}>
              <WSEmptyState
                orientation="horizontal"
                description={`If an account exists for ${email}, we’ll send an email with a link to log in. It should arrive in your inbox shortly.
                
Contact your client/inviter for help if you don’t receive the email`}
                title="Your request was submitted"
                type="done"
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "12", m: "2" }} />
          </WSGrid>
        </WSElement>
      </WSContainer>
    </WSElement>
  );
};
