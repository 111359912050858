import { NextgenSubscriptionPlanType } from "@wingspanhq/users/dist/lib/interfaces";

export interface IDisplayNextGenSubscriptionPlan {
  name: string;
  description?: string;
  valuePropostions: Array<string>;
  includeDescription?: string;
  isPopular?: boolean;
  planType: NextgenSubscriptionPlanType;
}

export const NEXT_GEN_SUBSCRIPTION_PLANS_USA: IDisplayNextGenSubscriptionPlan[] =
  [
    {
      name: "Free",
      description: "For the individual freelancer",
      valuePropostions: [
        "Unlimited invoicing",
        "Unlimited payments",
        "Tax withholdings & payments to the IRS",
        "Business Banking & debit card",
        "Income & expense reporting"
      ],
      planType: NextgenSubscriptionPlanType.Base
    },
    {
      name: "Premium",
      description: "For teams & freelance businesses",
      includeDescription: "Everything in Free, plus",
      valuePropostions: [
        "Everything in Free plan",
        "Medical support & savings",
        "Professional Email Address",
        "Google Workspace productivity and collaboration tools, such as Docs, Sheets, Slides, Drive, Calendar, Meet, and more",
        "Financial, legal, & tax support"
      ],
      planType: NextgenSubscriptionPlanType.Base
    }
  ];
