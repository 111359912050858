import { ITransaction } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { IMember } from "@wingspanhq/users";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { openInNewTab } from "../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../types/wsLinks";
import { toWSDateString } from "@wingspanhq/fe-component-library";

export const disputeTransaction = (
  transaction: ITransaction,
  member: IMember,
  cardNumber?: string
) => {
  const names = wsName({
    user: member.user,
    member
  });

  openInNewTab(
    WS_LINKS.disputeWalletTransaction({
      userId: member.user.userId,
      email: names.email,
      fullName: names.fullName || names.getResolvedName(),
      transactionId: transaction.transactionId,
      transactionAmount: transaction.amount,
      transactionDate: toWSDateString(transaction.date),
      cardNumber: cardNumber || ""
    })
  );
};
