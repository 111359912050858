import {
  WSFormOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import React from "react";
import * as Yup from "yup";

import { useUpdateMemberProfile } from "../../../query/users/mutations";

import { useUserId } from "../../../query/hooks/helpers";
import { FormActions } from "./FormActions";
import styles from "./SettingsUpdateForms.module.scss";

interface DefaultMemberProfileBusinessUrlsFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (e: any) => void;
  label?: string;
}

export const DefaultMemberProfileBusinessUrlsForm: React.FC<
  DefaultMemberProfileBusinessUrlsFormProps
> = ({ onSubmit, field, defaultValues, onCancel }) => {
  const userId = useUserId();
  const [updateMemberProfile, { error: updateMemberProfileError }] =
    useUpdateMemberProfile();

  const isCompanyLink = (field: string) => {
    return [
      "websiteUrl",
      "linkedInUrl",
      "instagramUrl",
      "twitterUrl",
      "otherUrl"
    ].includes(field);
  };

  const onFormSubmit = async (data: any) => {
    const updatePayload = {
      ...(!isCompanyLink(field) && { [field]: data[field] }),
      ...(isCompanyLink(field) && { links: { [field]: data[field] } })
    };
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        company: {
          ...updatePayload
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        [field]: Yup.string()
          .required("This field is required")
          .test("url", "Enter valid URL", function (currentValue?: string) {
            const regex =
              /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
            return regex.test(currentValue || "");
          })
      })}
    >
      <WSFormOld.Field name={field}>
        <WSFormOld.Input render={props => <WSTextInput {...props} />} />
        <WSFormOld.Error />
      </WSFormOld.Field>
      <WSText.ParagraphSm weight="medium" className={styles.errorText} mt="XS">
        {updateMemberProfileError?.response?.data["error"]}
      </WSText.ParagraphSm>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
