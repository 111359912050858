import React, { useEffect } from "react";

import { Prompt } from "react-router-dom";

type Props = {
  isEnabled: boolean;
  promptMessage?: string;
};

export const PROMPT_MESSAGE =
  "Are you sure? Changes you made may not be saved.";

export const PreventLeave: React.FC<Props> = ({
  isEnabled,
  promptMessage = PROMPT_MESSAGE
}) => {
  useEffect(() => {
    if (isEnabled) {
      window.onbeforeunload = function () {
        return promptMessage;
      };
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isEnabled]);

  return (
    <Prompt
      key="prompt"
      when={isEnabled}
      message={(...props) => {
        // @ts-ignore
        const [location, action] = props;
        if (action === "POP") {
          return promptMessage;
        } else {
          return true;
        }
      }}
    />
  );
};
