import { useCallback, useEffect, useState } from "react";
import { queryCache } from "react-query";
import { FlatfileClient } from "@flatfile/api";

import { WSProgressBar } from "@wingspanhq/fe-component-library";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import {
  QUERY_BULK_COLLABORATOR_BATCH,
  QUERY_BULK_COLLABORATOR_BATCH_SUMMARY
} from "../../query/bulkCollaborator/keys";
import { BulkResource } from "../../types";

interface BulkBatchUploadProgressProps {
  bulkBatchId: string;
  bulkResource: BulkResource;
}

/**
 * This component is responsible for displaying the progress of a batch upload
 * in Flatfile platform while creating import items in the Bulk Importer.
 */
export const BulkBatchUploadProgress: React.FC<
  BulkBatchUploadProgressProps
> = ({ bulkBatchId, bulkResource }) => {
  const [flatfileApiClient, setFlatfileApiClient] =
    useState<FlatfileClient | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const { useBulkBatch, useBulkBatchSummary } =
    getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId, {
    refetchInterval: 2000
  });

  // to update the collaborators count on details page
  const qBulkBatchSummary = useBulkBatchSummary(
    bulkBatchId,
    {},
    {
      refetchInterval: 2000
    }
  );

  const bulkBatch = qBulkBatch.data;

  const initFlatfileClient = useCallback(
    (token: string) => {
      if (!token) return;
      if (!flatfileApiClient) {
        const ffClient = new FlatfileClient({
          token
        });
        setFlatfileApiClient(ffClient);
      }
    },
    [flatfileApiClient]
  );

  // Initialize the Flatfile client
  useEffect(() => {
    initFlatfileClient(
      bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileAccessToken] as string
    );

    return () => {
      // As uploading is done, invalidate the queries to refetch the updated data
      queryCache.invalidateQueries([
        QUERY_BULK_COLLABORATOR_BATCH,
        bulkBatchId
      ]);
      queryCache.invalidateQueries(QUERY_BULK_COLLABORATOR_BATCH_SUMMARY);
    };
  }, [
    bulkBatchId,
    bulkBatch?.labels,
    bulkBatch?.labels?.[BATCH_LABELS_WS_KEYS.flatfileAccessToken],
    initFlatfileClient
  ]);

  // Fetch the progress of the batch upload
  useEffect(() => {
    const setJobProgress = async (jobId: string) => {
      if (!flatfileApiClient) return;

      // fetch the main action job to get the progress
      const job = await flatfileApiClient?.jobs.get(jobId);
      setProgress(job?.data.progress ?? 0);
    };
    const jobId = bulkBatch?.labels?.[
      BATCH_LABELS_WS_KEYS.flatfileJobId
    ] as string;
    setJobProgress(jobId);
  }, [bulkBatch, bulkBatchId, flatfileApiClient]);

  return <WSProgressBar mb="M" percent={progress || 0} text={null} />;
};
