import {
  useWSModal,
  WSActions,
  WSDivider,
  WSForm,
  WSInfoBox,
  WSInputText,
  WSList
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import { usePreviewPayeeEmailUpdate } from "../../../../query/payee/mutations/usePreviewPayeeEmailUpdate";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";

type FormValues = {
  email: string;
};

type EditEmailModalProps = {
  onClose: () => void;
  email: string;
  payeeId: string;
  onSuccess?: (result: IPayeeEmailChangeResponse) => void;
};

const EditEmailModal: React.FC<EditEmailModalProps> = ({
  onClose,
  email,
  payeeId,
  onSuccess
}) => {
  const [previewEmailUpdate, previewEmailUpdateMeta] =
    usePreviewPayeeEmailUpdate();

  return (
    <WSList gap="L">
      <WSInfoBox>
        Some details can only be edited before a contractor signs up and
        verifies their information.
      </WSInfoBox>
      <WSForm<FormValues>
        defaultValues={{
          email: ""
        }}
        validationSchema={Yup.object().shape({
          email: validatorEmail.required("Email is required")
        })}
        onSubmit={async formData => {
          await previewEmailUpdate(
            {
              payeeId,
              targetEmail: formData.email
            },
            {
              onSuccess: result => {
                onSuccess?.(result);
                onClose();
              }
            }
          );
        }}
      >
        <WSList gap="M">
          <WSForm.Field
            my="L"
            name="email"
            label="Contractor email"
            description="Contractor’s personal or business email"
            component={WSInputText}
            componentProps={{
              required: true,
              placeholder: "example@example.com",
              type: "email"
            }}
          />
          <WSErrorMessage
            contextKey={ErrorContextKey.Other}
            error={previewEmailUpdateMeta.error}
          />

          <WSDivider />

          <WSActions
            alignment="fill"
            buttons={[
              {
                label: "Continue",
                type: "submit",
                kind: "Primary"
              }
            ]}
          />
        </WSList>
      </WSForm>
    </WSList>
  );
};

export function useEditEmailModal() {
  return useWSModal(EditEmailModal, {
    title: "Edit email",
    size: "S"
  });
}
