import { QueryConfig } from "react-query";
import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_PAYEE } from "../keys";
import { getPayee } from "../../../services/payees";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const usePayeeQuery = (
  payeeId: string,
  config?: QueryConfig<IPayeeResponse, WSServiceError>
) => {
  const orgPayerId = useXWingspanPayerId(payeeId);

  return useWSQuery<IPayeeResponse, WSServiceError>(
    [QUERY_PAYEE, payeeId],
    async (query, queryParams) => {
      return await getPayee(payeeId, orgPayerId);
    },
    {
      ...config
    }
  );
};
