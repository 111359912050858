import { DepositProduct } from "@wingspanhq/payments/dist/interfaces";

export const WS_LINKS = {
  marketingDomain: process.env.REACT_APP_MARKETING_DOMAIN,
  wingspanTosAcceptance: "https://www.wingspan.app/terms-of-service",
  wingspanPrivacyPolicyAcceptance: "https://www.wingspan.app/privacy-policy",
  security: "https://www.wingspan.app/security",
  retool: "https://wingspan.retool.com/apps/Wingspan%20Admin",
  stripe: "https://stripe.com",
  stripeTerms: "https://stripe.com/legal",
  stripeConnectedTerms: "https://stripe.com/connect-account/legal",
  googlePrivacyPolicy: "https://policies.google.com/privacy",
  googleTermOfService: "https://policies.google.com/terms",
  changeLog:
    "https://www.notion.so/wingspan/Wingspan-Release-Notes-f5a5d2946f4647eb8fd741eb7af6af24",
  electronicDisclosureAndConsent:
    "https://www.wingspan.app/banking-disclosures/electronic-disclosure-and-consent",
  depositAccountTermsConditions:
    "https://www.wingspan.app/banking-disclosures/business-deposit-account-agreement",
  depositLimit: {
    [DepositProduct.Enterprise3]:
      "https://www.wingspan.app/banking-disclosures/deposit-agreement-limits-enterprise-3",
    [DepositProduct.Member2]:
      "https://www.wingspan.app/banking-disclosures/deposit-agreement-limits-member-2",
    [DepositProduct.Member3]:
      "https://www.wingspan.app/banking-disclosures/deposit-agreement-limits-member-3"
  },
  TCCITerms:
    "https://go.currencycloud.com/hubfs/Product/Legal/VGSI-Customer-Terms.pdf",
  directDepositsInfo:
    "https://help.wingspan.app/en/articles/5141866-invoice-direct-deposits",
  taxpayersClassification:
    "https://www.irs.gov/individuals/international-taxpayers/classification-of-taxpayers-for-us-tax-purposes",
  autopayHelpGuide:
    "https://help.wingspan.app/en/articles/7208360-getting-started-with-autopay-for-payers",
  faq: "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions",
  corrections:
    "https://help.wingspan.app/en/articles/8857626-how-to-request-corrections-for-errors-on-your-received-1099-nec-form",
  cashBackTermsAndConditions:
    "https://www.wingspan.app/banking-disclosures/wingspan-cash-back-terms-conditions",
  debitCardHolderAgreement:
    "https://www.wingspan.app/banking-disclosures/wingspan-business-visa-debit-cardholder-agreement",
  crossBorderPaymentServicesAgreement:
    "https://go.currencycloud.com/hubfs/Product/Legal/VGSI-Customer-Terms.pdf",
  moovTermsOfService: "https://moov.io/legal/platform-agreement/",
  leadBankTerms: "https://www.lead.bank/privacy-and-terms",
  onboardingHelpGuide: (o?: {
    email?: string;
    userId?: string;
    userName?: string;
  }) =>
    encodeURI(
      `https://wingspan.typeform.com/to/f0N4wUcV#email=${o?.email}&name=${
        o?.email !== o?.userName ? o?.userName : ""
      }&user_id=${o?.userId}`
    ),
  eligibilityCriteria:
    "https://docs.wingspan.app/v2.0/docs/understanding-1099-statuses",
  taxFilingProcess:
    "https://docs.wingspan.app/docs/getting-started-1099-filing",
  eligibilityRequirements:
    "https://help.wingspan.app/en/articles/6282203-payment-eligibility-requirements",
  disputeWalletTransaction: (p: {
    email: string;
    fullName: string;
    userId: string;
    cardNumber: string;
    transactionId: string;
    transactionDate: string;
    transactionAmount: number;
  }) =>
    `https://wingspan.typeform.com/to/dLdDiXo4#email=${p.email}&name=${p.fullName}&user_id=${p.userId}&card_number=${p.cardNumber}&transaction_id=${p.transactionId}&transaction_date=${p.transactionDate}&transaction_amount=${p.transactionAmount}`
};
