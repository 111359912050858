import {
  WSActions,
  WSCard,
  WSControl,
  WSElement,
  WSInfoBox,
  WSList,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { FlowMEChangeContext } from "..";
import { useQueryCustomerEntity } from "../../../../../query/onboarding/queries/useQueryCustomerEntity";
import { DataView } from "../../../../../shared/components/DataView";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { ReviewOriginalBusinessName } from "../../../components/ReviewOriginalBusinessName";
import { ReviewOriginalIndividualName } from "../../../components/ReviewOriginalIndividualName";
import { OnboardingStep } from "../../../types";
import { useMutationSubmitImmaterialLegalName } from "./useMutationSubmitImmaterialLegalName";

export const StepImmaterialUpdateReview: React.FC<OnboardingStep> = props => {
  const [context] = useFlowContext<FlowMEChangeContext>();
  const [consent, setConsent] = useState(false);
  const queryCustomerEntity = useQueryCustomerEntity();
  const [mutate, meta] = useMutationSubmitImmaterialLegalName({
    onSuccess: props.onNext
  });

  return (
    <WSList gap="2XL">
      <WSElement>
        <WSSectionToolbar title="Update taxpayer information" />
        <WSText.ParagraphSm mt="L" color="gray500">
          Please review to ensure the information is accurate before submitting
        </WSText.ParagraphSm>
      </WSElement>

      <DataView
        data={[
          {
            label: "Effective date of change",
            value: context.reason?.effectiveAt,
            formatterOptions: {
              dateType: "monthDayYear"
            }
          },
          { label: "Reason for the change", value: context.reason?.reason }
        ]}
      />

      {queryCustomerEntity.data?.type === "Individual" ? (
        <>
          <ReviewOriginalIndividualName />

          <DataView
            title="Legal name change"
            pill={{ theme: "warning", text: "Requested update" }}
            data={[
              {
                label: "Legal first name",
                value: context.individualLegalName?.firstName
              },
              {
                label: "Legal last name",
                value: context.individualLegalName?.lastName
              }
            ]}
            onEdit={props.onBack}
          />
        </>
      ) : (
        <>
          <ReviewOriginalBusinessName />

          <DataView
            title="Legal business name change"
            pill={{ theme: "warning", text: "Requested update" }}
            data={[
              {
                label: "Legal business name",
                value: context.businessLegalName?.legalBusinessName
              },
              {
                label: "Doing business as (DBA)",
                value: context.businessLegalName?.dba
              }
            ]}
            onEdit={props.onBack}
          />
        </>
      )}

      <WSCard>
        <WSInfoBox>
          This information will be used to update your Wingspan account. Once
          you continue, you cannot return to this step and the information
          cannot be changed while we are processing the change. Please verify
          all information provided is accurate.
        </WSInfoBox>

        <WSControl
          mt="2XL"
          type="checkbox"
          value={consent}
          onChange={setConsent}
          label="By checking this box, I confirm that all information is accurate. I agree to the processing of my information for identity verification as detailed in the Privacy Policy."
          size="S"
        />
      </WSCard>

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Back",
            onClick: props.onBack,
            kind: "Secondary",
            type: "button",
            visible: !!props.onBack
          },
          {
            label: "Confirm & continue",
            disabled: !consent,
            onClick: () => {
              mutate();
            },
            loading: meta.isLoading
          }
        ]}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to request a taxpayer
        information update, if you exit or leave without completing this step,
        it will cancel the request.
      </WSText.ParagraphXs>
    </WSList>
  );
};
