import {
  WSButton,
  WSButtons,
  WSFormOld,
  WSGrid,
  WSIcon,
  WSInputMaskOld,
  WSSelectOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Tooltip } from "../../../../components/Tooltip";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { US_STATES_OPTIONS } from "../../../../constants/user";
import { WSQueries } from "../../../../query/WSQuery";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useUpdateActivity,
  useUpdateMemberProfile
} from "../../../../query/users/mutations";
import {
  useActivities,
  useMemberProfile
} from "../../../../query/users/queries";
import { get1099SetupProgress } from "../../../../query/users/selectors";
import { ErrorContextKey } from "../../../../services/platform";
import { SetupRouteComponentProps } from "../../../../shared/components/FlowSetup";
import { getSsnInputProps } from "../../../../shared/utils/getSsnInputProps";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { validatorEmail } from "../../../../shared/validators/validatorEmail";
import {
  companyNameValidator,
  validatorAddressLine1,
  validatorAddressLine2,
  validatorSSN,
  validatorTaxId,
  validatorZipCode
} from "../../../../utils/validators";
import styles from "./styles.module.scss";

interface ConfirmPayerInfoFormValues {
  legalBusinessName: string;
  supportEmail?: string;
  supportPhoneNumber?: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  tin?: string;
  ssn?: string;
}

export const FormPayerInformation: React.FC<
  SetupRouteComponentProps & {
    isEdit?: boolean;
  }
> = ({ onBack, onNext, isEdit = false }) => {
  const history = useHistory();
  const userId = useUserId();
  const qMember = useMemberProfile(userId);
  const qActivity = useActivities(userId);
  const [updateMemberProfile, updateMemberProfileMeta] =
    useUpdateMemberProfile();
  const [updateActivity] = useUpdateActivity(userId);
  const [confirmPayerInfo, confirmPayerInfoMeta] = useWSMutation(async data => {
    const company = {
      ...(data.legalBusinessName
        ? { legalBusinessName: data.legalBusinessName }
        : {}),
      ...(data.supportEmail ? { supportEmail: data.supportEmail } : {}),
      ...(data.supportPhoneNumber
        ? { supportPhoneNumber: data.supportPhoneNumber }
        : {}),
      ...(data.tin ? { taxId: data.tin } : {})
    };
    await updateMemberProfile(
      {
        memberId: userId,
        profile: {
          ...(isEmpty(company) ? {} : { company }),
          ...(data.ssn ? { ssn: data.ssn } : {}),
          address: {
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            state: data.state,
            postalCode: data.zipCode,
            country: qMember.data?.profile.address?.country ?? "US"
          }
        }
      },
      {
        onSuccess: async () => {
          // skip activity update if this is edit flow
          if (!isEdit) {
            const nec1099SetupProgress = get1099SetupProgress(qActivity.data);
            await updateActivity(
              {
                flows: {
                  nec1099Setup: {
                    ...nec1099SetupProgress,
                    currentStep: 2,
                    totalSteps: 4
                  }
                }
              },
              {
                onSuccess: () => {
                  onNext();
                }
              }
            );
          } else {
            onNext();
          }
        }
      }
    );
  });

  const onFormSubmit = async (data: ConfirmPayerInfoFormValues) => {
    updateMemberProfileMeta.reset();

    await confirmPayerInfo(data);
  };

  return (
    <WSQueries queries={{ qMember }}>
      {({ qMember: { data: member } }) => {
        const hasSubmittedSSN =
          member.profile.ssnLastFourProvided || member.profile.ssnProvided;
        const { address, company } = member.profile;
        return (
          <>
            <WSFormOld<ConfirmPayerInfoFormValues>
              defaultValues={{
                legalBusinessName: company?.legalBusinessName ?? company?.name,
                addressLine1: address?.addressLine1,
                addressLine2: address?.addressLine2,
                city: address?.city,
                state: address?.state,
                zipCode: address?.postalCode,
                phoneNumber: member.user?.phone?.number ?? "",
                ...(company?.taxId && { tin: company?.taxId }),
                ...(!hasSubmittedSSN && { ssn: "" }),
                supportEmail: company?.supportEmail ?? "",
                supportPhoneNumber: company?.supportPhoneNumber ?? ""
              }}
              validationSchema={Yup.object().shape({
                legalBusinessName: companyNameValidator,
                addressLine1: validatorAddressLine1,
                addressLine2: validatorAddressLine2,
                city: Yup.string().required("Required"),
                state: Yup.string().required("Required"),
                zipCode: validatorZipCode,
                supportEmail: validatorEmail,
                supportPhoneNumber: Yup.string()
                  .matches(/^[0-9]+$/, "Should be 9 digits")
                  .length(10, "Phone number should be 10 characters")
                  .required("Phone number is required"),
                ...(company?.taxId
                  ? { tin: validatorTaxId.required("Required") }
                  : {}),
                ...(!company?.taxId && !hasSubmittedSSN
                  ? { ssn: validatorSSN.required("Required") }
                  : {})
              })}
              onSubmit={onFormSubmit}
            >
              <WSFormOld.Field
                mt="XL"
                name="legalBusinessName"
                component={WSTextInput}
                label="Legal Business Name"
              />

              <WSFormOld.Field
                mt="XL"
                name="addressLine1"
                component={WSTextInput}
                label="Address line 1"
              />
              <WSFormOld.Field
                mt="XL"
                name="addressLine2"
                component={WSTextInput}
                label="Address line 2 (optional)"
              />

              <WSGrid mt="XL">
                <WSGrid.Item span={{ m: "6" }}>
                  <WSFormOld.Field
                    mt="XL"
                    name="city"
                    component={WSTextInput}
                    label="City"
                  />
                </WSGrid.Item>
                <WSGrid.Item span={{ s: "6", m: "3" }}>
                  <WSFormOld.Field
                    mt="XL"
                    name="state"
                    component={WSSelectOld}
                    componentProps={{
                      options: US_STATES_OPTIONS,
                      placeholder: "Select",
                      searchable: true
                    }}
                    label="State"
                  />
                </WSGrid.Item>
                <WSGrid.Item span={{ s: "6", m: "3" }}>
                  <WSFormOld.Field
                    mt="XL"
                    name="zipCode"
                    component={WSInputMaskOld}
                    componentProps={{
                      mask: "99999"
                    }}
                    label="Zip code"
                  />
                </WSGrid.Item>
              </WSGrid>

              <WSFormOld.Field
                mt="XL"
                name="phoneNumber"
                component={WSInputMaskOld}
                componentProps={{
                  disabled: true,
                  type: "tel",
                  slotChar: " ",
                  placeholder: "Phone number",
                  mask: "+9(999) 999-**********",
                  unmask: true,
                  autoClear: false
                }}
                label="Phone number"
              />
              <WSButton.Link
                mt="M"
                size="S"
                type="button"
                onClick={() =>
                  openInNewTab(
                    history.createHref({
                      pathname: "/member/settings/account"
                    })
                  )
                }
              >
                Update your phone number in settings
              </WSButton.Link>

              {company?.taxId ? (
                <WSFormOld.Field
                  mt="XL"
                  name="tin"
                  label="TIN (taxpayer identification number)"
                  component={WSInputMaskOld}
                  componentProps={{
                    mask: "99-9999999",
                    unmask: true,
                    fsExclude: true
                  }}
                />
              ) : !hasSubmittedSSN ? (
                <WSFormOld.Field
                  mt="XL"
                  name="ssn"
                  label="SSN"
                  component={WSInputMaskOld}
                  componentProps={getSsnInputProps()}
                  mb="XS"
                />
              ) : null}

              <WSText.ParagraphSm mt="2XL" mb="XL">
                Support contact information{" "}
                <Tooltip
                  trigger={
                    <WSIcon
                      block
                      size="XS"
                      name="info-circle"
                      ml="XS"
                      color="gray500"
                    />
                  }
                  placement="right"
                >
                  <WSText weight="medium" color="gray50">
                    Support contact information
                  </WSText>
                  <WSText.ParagraphSm color="gray50" mt="M">
                    The support email address and phone number will be given to
                    recipients to contact your business directly to address
                    issues specific to your business. This includes issues like
                    not receiving a 1099 form or questions about specific
                    payments included in the 1099.
                  </WSText.ParagraphSm>
                </Tooltip>
              </WSText.ParagraphSm>
              <WSFormOld.Field
                mt="XL"
                name="supportEmail"
                component={WSTextInput}
                label="Support email address"
                componentProps={{
                  placeholder: "Support email address",
                  type: "email"
                }}
              />
              <WSFormOld.Field
                mt="XL"
                name="supportPhoneNumber"
                component={WSInputMaskOld}
                label="Support phone number"
                componentProps={{
                  type: "tel",
                  slotChar: " ",
                  placeholder: "Support phone number",
                  mask: "(999) 999-**********",
                  unmask: true,
                  autoClear: false
                }}
              />

              <WSErrorMessage
                mt="XL"
                contextKey={ErrorContextKey.Other}
                error={updateMemberProfileMeta.error}
              />

              <WSButtons mt="3XL">
                <WSButton.Secondary
                  type="button"
                  className={styles.actionBtn}
                  onClick={onBack}
                >
                  Back
                </WSButton.Secondary>
                <WSFormOld.SubmitButton
                  className={styles.actionBtn}
                  data-testid="confirmPayerInfo"
                  loading={confirmPayerInfoMeta.isLoading}
                >
                  {isEdit ? "Save changes" : "Continue"}
                </WSFormOld.SubmitButton>
              </WSButtons>
            </WSFormOld>
          </>
        );
      }}
    </WSQueries>
  );
};
