import React from "react";
import { WSFormOld, WSRadioInputGroup } from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import * as Yup from "yup";

import { FormActions } from "./FormActions";

import styles from "./SettingsUpdateForms.module.scss";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { USStates } from "../../../constants/USstates";
import { queryCache } from "react-query";
import { QUERY_BOOKKEEPING_PROFIT_AND_LOSS } from "../../../query/bookkeeping/keys";
import { useUserId } from "../../../query/hooks/helpers";

export const EXPENSES_OPTIONS = [
  {
    value: "1000",
    label: "0-1k"
  },
  {
    value: "5000",
    label: "1k-10k"
  },
  {
    value: "11000",
    label: "10k+"
  }
];

interface TaxExpensesEstimateForYearFormProps {
  field: string;
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const US_STATES_OPTIONS = USStates.map(s => ({
  value: s.abbreviation,
  label: s.name
}));

export const TaxExpensesEstimateForYearForm: React.FC<
  TaxExpensesEstimateForYearFormProps
> = ({ field, onSubmit, defaultValues, onCancel }) => {
  const userId = useUserId();
  const [updateMemberProfile] = useUpdateMemberProfile();
  const onFormSubmit = async (data: any) => {
    const request: IMemberUpdateRequest = {
      memberId: userId,
      profile: {
        taxInfo: {
          [field]: Number(data[field])
        }
      }
    };
    await updateMemberProfile(request, {
      onSuccess: () => {
        onSubmit(data);
        queryCache.invalidateQueries(QUERY_BOOKKEEPING_PROFIT_AND_LOSS);
      }
    });
  };
  return (
    <WSFormOld
      className={styles.form}
      onSubmit={onFormSubmit}
      defaultValues={defaultValues}
      validationSchema={Yup.object().shape({
        [field]: Yup.string().required("Expenses estimate is required")
      })}
    >
      <WSFormOld.Field name={field}>
        <WSFormOld.Input
          render={props => (
            <WSRadioInputGroup {...props} options={EXPENSES_OPTIONS} />
          )}
        />
        <WSFormOld.Error />
      </WSFormOld.Field>
      <WSFormOld.Context>
        {({ formState: { isSubmitting } }) => (
          <FormActions onCancel={onCancel} loading={isSubmitting} />
        )}
      </WSFormOld.Context>
    </WSFormOld>
  );
};
