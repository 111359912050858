import {
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFlexBox,
  WSFormOld,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { IMemberOccupation } from "@wingspanhq/users";
import { Visibility } from "@wingspanhq/users/dist/lib/interfaces";
import cn from "classnames";
import React from "react";
import { WSQuery } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../query/users/mutations";
import { useMemberProfile } from "../../query/users/queries";

import isEqual from "lodash/isEqual";
import { useQueryVerifications } from "../../query/onboarding/queries/useQueryVerifications";
import { selectorAddressDefaultValues } from "../../shared/components/FormPartialAddress";
import { openIntercom, openIntercomMessage } from "../../shared/utils/intercom";
import { BusinessLogoUploadSettingItem } from "../components/BusinessLogoUploadSettingItem/BusinessLogoUploadSettingItem.component";
import { DefaultSettingItem } from "../components/DefaultSettingItem/DefaultSettingItem.component";
import { SettingsLoader } from "../components/SettingsLoader/SettingsLoader";
import { BusinessAddressFormValues } from "../components/SettingsUpdateForms/BusinessAddressForm";
import { BUSINESS_TYPE_OPTIONS } from "../components/SettingsUpdateForms/BusinessTypeForm";
import { getAddressDisplayValue, getSelectedOptionByValue } from "../utils";
import styles from "./BusinessInfoTab.module.scss";
import mainStyles from "./SettingsMain.module.scss";

interface PrivacySettingsProps {}

const PrivacySettings: React.FC<PrivacySettingsProps> = props => {
  const userId = useUserId();
  const memberQuery = useMemberProfile(userId);

  const [updateMemberProfile] = useUpdateMemberProfile();
  const onChangePrivacy = async (value: any) => {
    await updateMemberProfile({
      memberId: userId,
      profile: {
        visibilityMap: {
          phone: value ? Visibility.Public : Visibility.Private,
          address: value ? Visibility.Public : Visibility.Private
        }
      }
    });
  };

  return (
    <WSQuery query={memberQuery}>
      {({ data: member }) => {
        return (
          <WSFlexBox direction="column" mt="3XL">
            <WSFormOld
              defaultValues={{
                checked:
                  member.profile.visibilityMap?.phone === Visibility.Public &&
                  member.profile.visibilityMap?.address === Visibility.Public
              }}
            >
              <WSFormOld.Field
                name="checked"
                component={WSCheckboxToggle}
                componentProps={{
                  label: "Show my full contact info to clients",
                  onChange: onChangePrivacy
                }}
                mb="M"
              />
            </WSFormOld>

            <WSText.ParagraphSm mb="3XL" color="gray400">
              Typically required when billing larger companies
            </WSText.ParagraphSm>
          </WSFlexBox>
        );
      }}
    </WSQuery>
  );
};

export interface BusinessInfoTabProps {}

export const BusinessInfoTab: React.FC<BusinessInfoTabProps> = props => {
  const userId = useUserId();
  const queryVerifications = useQueryVerifications();
  const isVerified = queryVerifications.data?.tax === "Verified";

  const { status: memberProfileStatus, data: memberData } =
    useMemberProfile(userId);
  const openLink = (url: string) => {
    if (url.startsWith("http")) {
      window.open(url);
    } else {
      window.open(`http://${url}`);
    }
  };
  const content = (
    <WSElement className={mainStyles.contentPane} {...props}>
      {memberProfileStatus === "loading" ||
      queryVerifications.status === "loading" ? (
        <SettingsLoader />
      ) : (
        <>
          <DefaultSettingItem
            label="Business structure"
            description={
              <>
                If you have not filed paperwork to register as a business
                entity, then your business type is likely to be Sole Prop. Not
                sure which option to select?{" "}
                <WSText.ParagraphSm
                  inline
                  color="blue400"
                  onClick={openIntercom}
                >
                  Contact support
                </WSText.ParagraphSm>
              </>
            }
            value={memberData?.profile.company?.structure || ""}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  {value
                    ? getSelectedOptionByValue(BUSINESS_TYPE_OPTIONS, value)
                        ?.label
                    : "Business type is not added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="businessType"
            action={
              memberData?.profile.company?.structure
                ? isVerified
                  ? "Request change"
                  : "Edit"
                : "Add"
            }
          />
          <DefaultSettingItem
            label="Legal business name"
            description="Enter your business name exactly as it is recorded with government tax authorities."
            value={memberData?.profile.company?.legalBusinessName || ""}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "Legal business name is not added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="legalBusinessName"
            action={
              memberData?.profile.company?.legalBusinessName
                ? isVerified
                  ? "Request change"
                  : "Edit"
                : "Add"
            }
          />
          <DefaultSettingItem
            label="Doing business as (Optional)"
            description="The operating name of your company, if it’s different from the legal name."
            value={memberData?.profile.company?.name || ""}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  {value
                    ? value
                    : "The operating business name is not added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="name"
            action={memberData?.profile.company?.name ? "Edit" : "Add"}
          />
          <DefaultSettingItem
            label="Employer Identification Number (EIN)"
            description="If you use your Social Security number for business tax purposes, leave this blank."
            value={memberData?.profile.company?.taxId || ""}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [styles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "No EIN added yet"}
                </WSText.ParagraphSm>
              );
            }}
            readonly={!!memberData?.profile.company?.taxId && isVerified}
            onClick={
              !!memberData?.profile.company?.taxId && isVerified
                ? () => {
                    openIntercomMessage(
                      `Hi there! I want to update my EIN.\n\n`
                    );
                  }
                : undefined
            }
            action={
              memberData?.profile.company?.taxId
                ? isVerified
                  ? "Request change"
                  : "Edit"
                : "Add"
            }
            field="taxId"
          />

          <DefaultSettingItem
            formContainerClassName={styles.formContainerClassName}
            label="Business address"
            description="Tax documents will be sent to this address"
            value={{
              businessAddress: selectorAddressDefaultValues(
                memberData?.profile.address
              ),
              isHomeAddressSame: isEqual(
                memberData?.profile.address,
                memberData?.profile.homeAddress
              ),
              homeAddress: selectorAddressDefaultValues(
                memberData?.profile.homeAddress
              )
            }}
            valueRenderer={({ businessAddress }: BusinessAddressFormValues) => {
              return (
                <>
                  <WSText.ParagraphSm
                    mb="XL"
                    className={cn({
                      [mainStyles.emptyStateValue]:
                        !businessAddress?.addressLine1
                    })}
                  >
                    {businessAddress?.addressLine1
                      ? getAddressDisplayValue(businessAddress)
                      : "Business address is not added yet"}
                  </WSText.ParagraphSm>
                </>
              );
            }}
            field="businessAddress"
            action={memberData?.profile.address?.addressLine1 ? "Edit" : "Add"}
          />
          <DefaultSettingItem
            label="Occupation(s)"
            value={memberData?.profile.taxInfo?.occupations || []}
            valueRenderer={(value: any) => {
              const displayValue = value
                .map((occupation: IMemberOccupation) =>
                  occupation.category === "Other"
                    ? occupation.customCategory
                    : occupation.category
                )
                .join(", ");
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  {displayValue ? displayValue : "No Occupation(s) added yet"}
                </WSText.ParagraphSm>
              );
            }}
            field="occupations"
            action={
              (memberData?.profile.taxInfo?.occupations || []).length > 0
                ? "Edit"
                : "Add"
            }
          />
          <DefaultSettingItem
            label="State of Incorporation (Optional)"
            value={memberData?.profile.company?.stateOfIncorporation}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [styles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "No State of Incorporation added yet"}
                </WSText.ParagraphSm>
              );
            }}
            action={
              memberData?.profile.company?.stateOfIncorporation ? "Edit" : "Add"
            }
            field="stateOfIncorporation"
          />
          <DefaultSettingItem
            label="Year of Incorporation (Optional)"
            value={memberData?.profile.company?.yearOfIncorporation}
            valueRenderer={(value: string) => {
              return (
                <WSText.ParagraphSm
                  className={cn({
                    [styles.emptyStateValue]: !value
                  })}
                >
                  {value ? value : "No Year of Incorporation added yet"}
                </WSText.ParagraphSm>
              );
            }}
            action={
              memberData?.profile.company?.yearOfIncorporation ? "Edit" : "Add"
            }
            field="yearOfIncorporation"
          />
          <PrivacySettings />
          <WSText.Heading4 id="marketing">Marketing</WSText.Heading4>
          <BusinessLogoUploadSettingItem
            logoFileId={memberData?.profile.logoFileId || ""}
          />
          <DefaultSettingItem
            label="Business website"
            value={memberData?.profile.company?.links?.websiteUrl || ""}
            valueRenderer={(value: string) => {
              return value ? (
                <WSButton.Link
                  rightIcon="open-tab"
                  className={styles.businessBtnLink}
                  onClick={() => openLink(value)}
                >
                  Visit website
                </WSButton.Link>
              ) : (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  Business website is not added yet
                </WSText.ParagraphSm>
              );
            }}
            field="businessWebsite"
            action={
              memberData?.profile.company?.links?.websiteUrl ? "Edit" : "Add"
            }
          />
          <DefaultSettingItem
            label="LinkedIn"
            value={memberData?.profile.company?.links?.linkedInUrl || ""}
            valueRenderer={(value: string) => {
              return value ? (
                <WSButton.Link
                  rightIcon="open-tab"
                  className={styles.businessBtnLink}
                  onClick={() => openLink(value)}
                >
                  Visit website
                </WSButton.Link>
              ) : (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  LinkedIn is not added yet
                </WSText.ParagraphSm>
              );
            }}
            field="businessLinkedInURL"
            action={
              memberData?.profile.company?.links?.linkedInUrl ? "Edit" : "Add"
            }
          />
          <DefaultSettingItem
            label="Twitter"
            value={memberData?.profile.company?.links?.twitterUrl || ""}
            valueRenderer={(value: string) => {
              return value ? (
                <WSButton.Link
                  rightIcon="open-tab"
                  className={styles.businessBtnLink}
                  onClick={() => openLink(value)}
                >
                  Visit website
                </WSButton.Link>
              ) : (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  Twitter URL is not added yet
                </WSText.ParagraphSm>
              );
            }}
            field="businessTwitterURL"
            action={
              memberData?.profile.company?.links?.twitterUrl ? "Edit" : "Add"
            }
          />
          <DefaultSettingItem
            label="Instagram"
            value={memberData?.profile.company?.links?.instagramUrl || ""}
            valueRenderer={(value: string) => {
              return value ? (
                <WSButton.Link
                  rightIcon="open-tab"
                  className={styles.businessBtnLink}
                  onClick={() => openLink(value)}
                >
                  Visit website
                </WSButton.Link>
              ) : (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  Instagram URL is not added yet
                </WSText.ParagraphSm>
              );
            }}
            field="businessInstagramURL"
            action={
              memberData?.profile.company?.links?.instagramUrl ? "Edit" : "Add"
            }
          />
          <DefaultSettingItem
            label="Other"
            value={memberData?.profile.company?.links?.otherUrl || ""}
            valueRenderer={(value: string) => {
              return value ? (
                <WSButton.Link
                  rightIcon="open-tab"
                  className={styles.businessBtnLink}
                  onClick={() => openLink(value)}
                >
                  Visit website
                </WSButton.Link>
              ) : (
                <WSText.ParagraphSm
                  className={cn({
                    [mainStyles.emptyStateValue]: !value
                  })}
                >
                  An additional URL
                </WSText.ParagraphSm>
              );
            }}
            field="businessOtherURL"
            action={
              memberData?.profile.company?.links?.otherUrl ? "Edit" : "Add"
            }
          />
        </>
      )}
    </WSElement>
  );
  return (
    <WSPage title="Business Info">
      <WSGrid>
        <WSGrid.Item span={{ m: "12" }}>{content}</WSGrid.Item>
      </WSGrid>
    </WSPage>
  );
};
