import {
  WSButton,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSLayout,
  WSSelectDisplay,
  WSText
} from "@wingspanhq/fe-component-library";
import { IMemberUpdateRequest } from "@wingspanhq/users";
import { TaxFilingCode } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSQuery } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useUpdateMemberProfile } from "../../../query/users/mutations";
import { useMemberProfile } from "../../../query/users/queries";
import { getIncomeOptionsByFilingCode } from "../../../query/users/selectors";
import { TaxesFormLayoutProps } from "./TaxesIndexRoute";

export const TaxesFormExpectedIncome: React.FC<TaxesFormLayoutProps> = ({
  progress,
  progressLabel,
  submitButton,
  next,
  prev,
  type
}) => {
  const history = useHistory();
  const memberId = useUserId();
  const qMemberProfile = useMemberProfile(memberId);
  const [updateMemberProfile, updateMemberProfileMeta] =
    useUpdateMemberProfile();
  useBrowserPageTitle("Update Tax Profile - Expected Income");

  return (
    <WSQuery query={qMemberProfile} renderLoader={() => <WSLayout line />}>
      {({ data }) => {
        const filingCode = data.profile.taxInfo?.filingCode as TaxFilingCode;
        const options = getIncomeOptionsByFilingCode(filingCode as any).map(
          o => ({ ...o, label: `$${o.label}` })
        );

        const incomeEstimateForYear = options.find(
          ({ value }) => value === data?.profile.taxInfo?.incomeEstimateForYear
        )?.value;

        return (
          <WSFormOld
            onSubmit={async formData => {
              const request: IMemberUpdateRequest = {
                memberId,
                profile: {
                  taxInfo: {
                    incomeEstimateForYear: formData.incomeEstimateForYear,
                    expensesEstimateForYear:
                      data?.profile.taxInfo?.expensesEstimateForYear || 1000
                  }
                }
              };
              await updateMemberProfile(request);
              next ? history.push(next) : history.goBack();
            }}
            defaultValues={{
              incomeEstimateForYear
            }}
            validationSchema={Yup.object().shape({
              incomeEstimateForYear: Yup.mixed()
                .oneOf(options.map(v => v.value))
                .required("Income estimate is required")
            })}
          >
            <WSLayout
              headerLeft={
                <WSIcon
                  block
                  name="chevron-left"
                  size="M"
                  color="gray500"
                  onClick={() => (prev ? history.push(prev) : history.goBack())}
                />
              }
              headerCenter={<WSText weight="medium">Find my tax rate</WSText>}
              headerRight={
                progressLabel ? (
                  <WSText.ParagraphSm color="gray500">
                    {progressLabel}
                  </WSText.ParagraphSm>
                ) : null
              }
              line
              progress={progress}
            >
              <WSContainer verticalPadding>
                <WSFlexBox.Center>
                  <WSText.Heading1>
                    How much do you expect to make this year?
                  </WSText.Heading1>
                </WSFlexBox.Center>
                <WSFlexBox.Center mt="M" mb="2XL">
                  <WSText>
                    Take your best guess. You can always change this estimate
                    later.
                  </WSText>
                </WSFlexBox.Center>

                <WSCentered span={{ s: "10", m: "8", l: "6" }}>
                  <WSFormOld.Field
                    key={JSON.stringify(options)}
                    name="incomeEstimateForYear"
                    mb="XL"
                    component={WSSelectDisplay}
                    componentProps={{ options }}
                  />
                </WSCentered>

                <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                  <WSButton
                    fullWidth
                    my="5XL"
                    type="submit"
                    data-testid="submitExpectedIncome"
                    loading={updateMemberProfileMeta.isLoading}
                  >
                    {submitButton || "Update"}
                  </WSButton>
                </WSCentered>
              </WSContainer>
            </WSLayout>
          </WSFormOld>
        );
      }}
    </WSQuery>
  );
};
