import "@typeform/embed/build/css/popup.css";
import {
  WSButton,
  WSElement,
  WSIcon,
  WSImage,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus } from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { formatMoney } from "accounting";
import React from "react";
import { queryCache } from "react-query";
import { RouteComponentProps, useHistory } from "react-router-dom";
import handsUpImage from "../../assets/icons/hands_up.svg";
import { QUERY_CLIENT_INVOICE } from "../../query/payments/keys";
import { useUpdateClientInvoice } from "../../query/payments/mutations";
import { getClientInvoiceMemberName } from "../../query/payments/selectors";
import { selectorAccountName } from "../../shared/selectors/selectorAccountName";
import { ButtonDownloadReceipt } from "../components/ButtonDownloadReceipt";
import { Card } from "../components/Card/Card";
import { ClientInvoiceWrapper } from "../components/ClientInvoiceWrapper/ClientInvoiceWrapper";
import { Header } from "../components/Header/Header";
import { InfoRow } from "../components/InfoRow/InfoRow";
import styles from "./ClientPaymentsInvoicePaymentSuccess.module.scss";
import { selectorAccountVerificationPending } from "../../shared/selectors/selectorAccountVerificationPending";

type Props = RouteComponentProps<
  { invoiceId: string },
  any,
  { paymentMethod?: "manual"; account: IAccount }
>;

export const ClientPaymentsInvoicePaymentSuccess: React.FC<Props> = ({
  match,
  location
}) => {
  const invoiceId = match.params.invoiceId;
  const [updateClientInvoice, updateClientInvoiceMeta] =
    useUpdateClientInvoice(invoiceId);
  const history = useHistory();

  const accountText = location.state?.account
    ? `payment account ${selectorAccountName(location.state.account)}`
    : "payment account";

  return (
    <>
      <ClientInvoiceWrapper invoiceId={invoiceId}>
        {clientInvoice => (
          <WSElement className={styles.wrapper}>
            <Header
              companyName={clientInvoice.memberCompany}
              companyLogoUrl={clientInvoice.memberLogoUrl}
            />
            <WSMessageBox.Success
              icon="check"
              title="Payment scheduled successfully."
              centered
              className={styles.successMessage}
            />

            <WSElement as="main" className={styles.main}>
              {(clientInvoice.status === InvoiceStatus.PaymentInTransit &&
                clientInvoice.events.payAfterAccountVerified) ||
              (location.state?.account &&
                selectorAccountVerificationPending(location.state.account)) ? (
                <>
                  <WSIcon block name="time" size="L" mb="M" />
                  <WSText.Heading5 mb="XS" data-testid="sentText">
                    Your payment is pending Micro-Deposit verification.
                  </WSText.Heading5>
                  <WSText mb="M" color="gray500">
                    We’ve sent two Micro-Deposits to your {accountText}, once
                    they arrive you’ll need to enter the amounts in{" "}
                    <WSButton.Link
                      onClick={() => {
                        history.push("/payment/history");
                      }}
                    >
                      your account settings
                    </WSButton.Link>
                    .
                  </WSText>
                  <WSText mb="XL" color="gray500">
                    Once your payment account is verified, your payment will be
                    processed automatically.
                  </WSText>

                  <WSText weight="medium" mb="M">
                    Notify your bank that Wingspan will be debiting payments.
                  </WSText>
                  <WSText mb="XL" color="gray500">
                    Your bank may require you to notify them when a third party
                    (like Wingspan) will be debiting funds from your account.
                    Failing to do so may result in payments being rejected by
                    your bank and associated fees.
                  </WSText>
                  <WSText mb="XL" color="gray500">
                    Here is the Wingspan Originator details in case you need
                    them:
                  </WSText>

                  <Card mb="3XL" className={styles.manualTransferDetailsCard}>
                    <InfoRow
                      label="Originator ID"
                      value="1832248301"
                      longName
                      mb="M"
                    />
                    <InfoRow
                      label="Originator Name"
                      value="Wingspan"
                      longName
                      mb="M"
                    />
                    <InfoRow
                      label="Bank Routing Number"
                      value="051402372"
                      longName
                      mb="M"
                    />
                    <InfoRow
                      label="Bank Name"
                      value="Lead Bank"
                      longName
                      mb="M"
                    />
                    <InfoRow label="Account Type" value="Business" longName />
                  </Card>

                  <WSText weight="medium" mb="M">
                    Want to pay with a credit card or bank debit instead of
                    wire?
                  </WSText>
                  <WSText mb="XL">
                    You chose to pay this invoice with a bank transfer from your{" "}
                    {accountText}. If you've changed your mind, you can still
                    pay this invoice a different way.
                  </WSText>
                  <WSButton
                    loading={updateClientInvoiceMeta.isLoading}
                    onClick={() => {
                      updateClientInvoice(
                        {
                          status: InvoiceStatus.Open
                        },
                        {
                          onSuccess: () => {
                            queryCache.removeQueries([
                              QUERY_CLIENT_INVOICE,
                              invoiceId
                            ]);
                            history.push(`/payment/${invoiceId}/2`);
                          }
                        }
                      );
                    }}
                  >
                    Pay a different way
                  </WSButton>
                </>
              ) : clientInvoice.status === InvoiceStatus.PaymentInTransit ||
                location.state?.paymentMethod === "manual" ? (
                <>
                  <WSIcon block name="time" size="L" mb="M" />
                  <WSText.Heading5 mb="XS" data-testid="sentText">
                    We're waiting on your wire to arrive.
                  </WSText.Heading5>
                  <WSText mb="XL" color="gray500">
                    If you haven't scheduled the wire at your bank yet, don't
                    forget to do that soon! Here are the details:
                  </WSText>

                  <Card mb="3XL" className={styles.manualTransferDetailsCard}>
                    <InfoRow
                      label="Bank name"
                      value={clientInvoice.bankTransferInfo?.bankName || "N/A"}
                      longName
                      mb="M"
                    />
                    <InfoRow
                      label="Routing"
                      value={
                        clientInvoice.bankTransferInfo?.routingNumber || "N/A"
                      }
                      longName
                      mb="M"
                    />
                    <InfoRow
                      label="Account"
                      value={
                        clientInvoice.bankTransferInfo?.accountNumber || "N/A"
                      }
                      longName
                      mb="M"
                    />
                    <InfoRow
                      label="SWIFT"
                      value={clientInvoice.bankTransferInfo?.swiftCode || "N/A"}
                      longName
                      mb="M"
                    />
                    <InfoRow
                      label="Note/memo"
                      value={`Invoice #${clientInvoice.invoiceNumber || "N/A"}`}
                      longName
                    />
                  </Card>

                  <WSText weight="medium" mb="M">
                    Want to pay with a credit card or bank debit instead of
                    wire?
                  </WSText>
                  <WSText mb="XL">
                    You chose to pay this invoice with a wire transfer to the
                    account number above. If you're changed your mind, you can
                    still pay this invoice a different way.
                  </WSText>
                  <WSButton
                    loading={updateClientInvoiceMeta.isLoading}
                    onClick={() => {
                      updateClientInvoice(
                        {
                          status: InvoiceStatus.Open
                        },
                        {
                          onSuccess: () => {
                            queryCache.removeQueries([
                              QUERY_CLIENT_INVOICE,
                              invoiceId
                            ]);
                            history.push(`/payment/${invoiceId}/2`);
                          }
                        }
                      );
                    }}
                  >
                    Pay a different way
                  </WSButton>
                </>
              ) : (
                <>
                  <WSImage
                    src={handsUpImage}
                    placeholder={<></>}
                    width={60}
                    mb="XL"
                  />
                  <WSText.Heading5 mb="XS" data-testid="sentText">
                    You sent {formatMoney(clientInvoice.amount)} to{" "}
                    {getClientInvoiceMemberName(clientInvoice)}
                    {clientInvoice.memberCompany
                      ? " at " + clientInvoice.memberCompany
                      : ""}
                    .
                  </WSText.Heading5>
                  <WSText mb="2XL" color="gray500">
                    We’ve sent confirmation emails to both of you.
                  </WSText>

                  <ButtonDownloadReceipt invoiceId={invoiceId} />

                  <WSButton.Link
                    onClick={() => {
                      history.push("/payment/history");
                    }}
                    mt="2XL"
                    rightIcon="chevron-right"
                  >
                    View history and settings
                  </WSButton.Link>
                </>
              )}
            </WSElement>
          </WSElement>
        )}
      </ClientInvoiceWrapper>
    </>
  );
};
