import {
  IPayableSchema,
  IPayrollSettings,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import { wsMoment } from "@wingspanhq/utils/dist/date/wsMoment";
import { isSameDate } from "../../utils/dates";

const UTC_PAYROLL_CUTOFF_HOUR = 17;
const UTC_PAYROLL_CUTOFF_MINUTES = 30;

export const getExactCutOffDate = (cutOffDate: Date) => {
  return new Date(
    Date.UTC(
      cutOffDate.getFullYear(),
      cutOffDate.getMonth(),
      cutOffDate.getDate(),
      UTC_PAYROLL_CUTOFF_HOUR,
      UTC_PAYROLL_CUTOFF_MINUTES
    )
  );
};

export const getNextPayroll = (payrollSettings: IPayrollSettings) =>
  payrollSettings.scheduleDates?.find(scheduleDate =>
    (scheduleDate.status === ScheduleStatus.Pending ||
      scheduleDate.status === ScheduleStatus.Modified) &&
    scheduleDate?.cutOffDate
      ? getExactCutOffDate(scheduleDate.cutOffDate) > new Date()
      : false
  );

export const getNextPayrollCutOffDate = (payrollSettings: IPayrollSettings) =>
  getNextPayroll(payrollSettings)?.cutOffDate;

export const getNextPayrollDate = (payrollSettings: IPayrollSettings) =>
  getNextPayroll(payrollSettings)?.date;

export const getMatchingPayroll = (
  payrollSettings: IPayrollSettings,
  payable: IPayableSchema
) => {
  // If feature is not enabled – just use the next payroll
  if (!payrollSettings.enableProcessDaysBeforeDue) {
    return getNextPayroll(payrollSettings);
  }

  // Set a pay date
  let payDate = payable.client.payDate || payable.dueDate;

  if (!wsMoment(payDate).isBusinessDay()) {
    payDate = wsMoment(payDate).prevBusinessDay().toDate();
  }

  // Try to find the closest payroll before pay date or at the same date
  const possiblePayrolls = payrollSettings.scheduleDates?.filter(
    scheduleDate =>
      (scheduleDate.status === ScheduleStatus.Pending ||
        scheduleDate.status === ScheduleStatus.Modified) &&
      (isSameDate(scheduleDate.date, payDate) || scheduleDate.date > payDate)
  );
  const matchingPayroll = possiblePayrolls?.[0];

  // If no matching payroll was found – just the next payroll
  if (!matchingPayroll) {
    return getNextPayroll(payrollSettings);
  }

  return matchingPayroll;
};
