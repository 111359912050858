import {
  toCamelCase,
  useModalContext,
  WSDivider,
  WSFlexBox,
  WSIcon,
  WSModal,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import styles from "./SubscriptionGrantOptionsModal.module.scss";
import {
  SUBSCRIPTION_SELECT_OPTIONS,
  SubscriptionGrantOption
} from "../../constants/subscriptionOptions";

interface Props {
  onClose: () => void;
}
interface SubscriptionGrantOptionsModalProps {
  selectedSubscription: SubscriptionGrantOption;
  onChange: (option: SubscriptionGrantOption) => void;
  action: "Add" | "Edit";
  onCloseModal: () => void;
}

export const SUBSCRIPTION_GRANT_OPTIONS_MODAL =
  "SUBSCRIPTION_GRANT_OPTIONS_MODAL";
export const SubscriptionGrantOptionsModal: React.FC<Props> = ({ onClose }) => {
  const [subscription, setSubscription] =
    React.useState<SubscriptionGrantOption | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { closeModal } = useModalContext();

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const renderOptions = (props: SubscriptionGrantOptionsModalProps) => {
    if (!!props.selectedSubscription) {
      return SUBSCRIPTION_SELECT_OPTIONS.map((option, index) => {
        const selectedSubscription = subscription ?? props.selectedSubscription;
        const isSelected =
          option.value.package === selectedSubscription?.value.package &&
          option.value.packageTier === selectedSubscription.value.packageTier;
        return (
          <>
            <WSFlexBox.CenterY
              justify="space-between"
              wrap="nowrap"
              onClick={() => {
                props.onChange(option);
                setSubscription(option);
                props.onCloseModal();
                closeModal(SUBSCRIPTION_GRANT_OPTIONS_MODAL);
              }}
              className={styles.membershipOption}
              data-testid={toCamelCase("grant", option.title)}
            >
              <WSFlexBox.CenterY direction="column" alignItems="flex-start">
                <WSText shimmer={isLoading} color="gray700" mb="XS">
                  {option.title}
                </WSText>
                <WSText.ParagraphSm shimmer={isLoading} color="gray600">
                  {option.description}
                </WSText.ParagraphSm>
              </WSFlexBox.CenterY>

              {isSelected ? (
                <WSIcon block name="check" color="blue400" size="M" />
              ) : null}
            </WSFlexBox.CenterY>
            {index === SUBSCRIPTION_SELECT_OPTIONS.length - 1 ? null : (
              <WSDivider my="M" />
            )}
          </>
        );
      });
    }
    return null;
  };
  return (
    <WSModal
      size="XS"
      name={SUBSCRIPTION_GRANT_OPTIONS_MODAL}
      onClose={onClose}
    >
      {(props: SubscriptionGrantOptionsModalProps) => {
        return (
          <React.Fragment>
            <WSScreen.MobileAndTablet>
              <WSText.Heading5 mb="2XL">Change membership</WSText.Heading5>
              <WSDivider mb="M" />
              {renderOptions(props)}
            </WSScreen.MobileAndTablet>
            <WSScreen.Desktop>
              <WSFlexBox direction="column" wrap="nowrap">
                <WSText.Heading5 mb="M">
                  {props.action} team member
                </WSText.Heading5>
                <WSFlexBox.CenterY
                  mb="2XL"
                  onClick={() => {
                    props.onCloseModal();
                    closeModal(SUBSCRIPTION_GRANT_OPTIONS_MODAL);
                  }}
                >
                  <WSIcon
                    block
                    name="arrow-left"
                    size="XS"
                    mr="XS"
                    color="gray600"
                  />
                  <WSText.ParagraphSm color="gray600">
                    <b>Back</b>
                  </WSText.ParagraphSm>
                </WSFlexBox.CenterY>
                <WSText.Heading5 mb="M">Change membership</WSText.Heading5>
                {renderOptions(props)}
              </WSFlexBox>
            </WSScreen.Desktop>
          </React.Fragment>
        );
      }}
    </WSModal>
  );
};
