import { useMemberPrivateFileCreate } from "../../../../../query/files/mutations";
import { useCreateSignedDocumentTemplate } from "../../../../../query/signedDocuments/mutations/useCreateSignedDocumentTemplate";
import { useConfirmCreateRequirementModal } from "../../../components/CreateRequirementDefinitionForm/useConfirmCreateRequirementModal";
import { SignerRole } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import {
  helloSignClient,
  helloSignPromise
} from "../../../../../utils/helloSign";
import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { useUserId } from "../../../../../query/hooks/helpers";
import { CreateRequirementDefinitionFormValues } from "../../../types";
import {
  DefinitionDataSourceType,
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { CreateRequirementDefinitionSignaturePartialForm } from "../types";

type Params = {
  engagementId?: string;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
};

export const useCreateSignatureRequirementDefinition = ({
  engagementId,
  onSuccess
}: Params) => {
  const { openSnackbar } = useWSSnackbar();
  const userId = useUserId();

  const [uploadFile, uploadFileMeta] = useMemberPrivateFileCreate({
    hidden: true
    // TODO: do we need to set tags & viewerIds here?
  });
  const [createSignedDocumentTemplate, createSignedDocumentTemplateMeta] =
    useCreateSignedDocumentTemplate();
  const confirmCreateRequirementModal = useConfirmCreateRequirementModal();

  const onSubmit = async (
    formValues: CreateRequirementDefinitionFormValues &
      CreateRequirementDefinitionSignaturePartialForm
  ) => {
    const fileId = await new Promise<string>(async (resolve, reject) => {
      const data = new FormData();
      data.append("file", formValues.eSignatureFile);
      await uploadFile(data, {
        onSuccess: response => {
          resolve(response[0].fileId);
        },
        onError: error => {
          openSnackbar({
            message: "Failed to upload file",
            type: "error"
          });
          reject(error);
        }
      });
    });

    if (fileId) {
      // create a template
      const template = await createSignedDocumentTemplate({
        userId,
        title: formValues.name,
        fileId: fileId,
        roles:
          formValues.requiredSignatures === "collaborator"
            ? [SignerRole.Payee]
            : [SignerRole.Payee, SignerRole.Payer]
      });

      // open hello sign modal
      helloSignClient.open(template?.editUrl as string);

      const status = await helloSignPromise();

      if (status === "closed") {
        // open confirmation modal to create requirement
        await confirmCreateRequirementModal.open({
          engagementId,
          dataSourceType: DefinitionDataSourceType.SignedDocumentTemplate,
          dataSourceId: template?.signedDocumentTemplateId as string,
          name: formValues.name,
          description: formValues.description,
          requirementType: RequirementType.Signature,
          onSuccess(requirement) {
            onSuccess?.(requirement);
          }
        });
      }
    }
  };

  return {
    onSubmit,
    errors: uploadFileMeta.error || createSignedDocumentTemplateMeta.error,
    isLoading:
      uploadFileMeta.isLoading || createSignedDocumentTemplateMeta.isLoading
  };
};
