import React from "react";
import { AutopaySetupParams } from ".";
import { SavedPaymentMethodCreditCardForm } from "../../../Settings/components/PaymentMethodsList/SavedPaymentMethodCreditCardModal";
import { SetupRouteComponentProps } from "../../../shared/components/FlowSetup";
import { SetupAutopayLayout } from "./SetupAutopayLayout";

export const SetupCreditCard: React.FC<
  SetupRouteComponentProps<AutopaySetupParams>
> = ({ onBack, onNext, params }) => {
  if (!params?.collaboratorId && !params?.payeeId) {
    return null;
  }

  return (
    <SetupAutopayLayout
      collaboratorId={params.collaboratorId}
      onBack={onBack}
      title={() => "Enter your credit card information."}
    >
      {({ companyName }) => (
        <SavedPaymentMethodCreditCardForm
          onFinish={onNext}
          shouldSetDefaultPaymentMethod
        />
      )}
    </SetupAutopayLayout>
  );
};
