import {
  useModalContext,
  WSButton,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import { PaymentMethod } from "@wingspanhq/payments/dist/interfaces";
import { AccountStatus, IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { AutopaySetupParams } from ".";
import { ManualWireTransferDetails } from "../../../ClientPayments/components/ManualWireTransferDetails/ManualWireTransferDetails";
import { PaymentTypeSelect } from "../../../ClientPayments/components/PaymentTypeSelect/PaymentTypeSelect";
import { OverlaySpinner } from "../../../components/OverlaySpinner";
import {
  CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL,
  ChooseAccountForPaymentMethodsModal
} from "../../../Invoices/components/ChooseAccountForPaymentMethodsModal/ChooseAccountForPaymentMethodsModal";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import { QUERY_USERS_ACTIVITIES } from "../../../query/users/keys";
import { useCreateAccount } from "../../../query/users/mutations";
import { useAccounts } from "../../../query/users/queries";
import { getPossiblePaymentsAccounts } from "../../../query/users/selectors";
import { WSQueries } from "../../../query/WSQuery";
import { usersService } from "../../../services/users";
import { SetupRouteComponentProps } from "../../../shared/components/FlowSetup";
import { useWSStore } from "../../../store";
import { useMemberPlaidLink } from "../../../utils/usePlaidLink";
import { SetupAutopayLayout } from "./SetupAutopayLayout";

export type EnabledPaymentMethod = "credit" | "ach" | "manual";

export const SetupPaymentMethod: React.FC<
  SetupRouteComponentProps<AutopaySetupParams>
> = ({ onBack, onNext, params }) => {
  const { openModal, closeModal } = useModalContext();
  const userId = useUserId();
  const [createAccount, createAccountMeta] = useCreateAccount();
  const history = useHistory();
  const accountsQuery = useAccounts();
  const store = useWSStore();

  const [completePaymentsActivity] = useWSMutation(
    async () => {
      await usersService.activity.update(userId, {
        flows: {
          paymentsSetup: {
            complete: true
          }
        }
      });
    },
    {
      dependencies: [QUERY_USERS_ACTIVITIES]
    }
  );

  const plaidHandler = useMemberPlaidLink({
    onSuccess: async (publicToken: string) => {
      const accounts = (await createAccount({
        publicToken,
        status: AccountStatus.Pending
      })) as IAccount[];

      if (accounts) {
        openModal(CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL, {
          shouldSetDefaultPaymentMethod: true
        });

        return {
          success: true,
          data: accounts
        };
      } else {
        return {
          success: false
        };
      }
    }
  });

  if (!params?.collaboratorId && !params?.payeeId) {
    return null;
  }

  return (
    <SetupAutopayLayout
      collaboratorId={params.collaboratorId}
      payeeId={params.payeeId}
      onBack={onBack}
      title={companyName => `How do you want to pay ${companyName}?`}
    >
      {({ companyName, domesticBankAccount, internationalBankAccount }) => (
        <>
          {createAccountMeta.isLoading && <OverlaySpinner />}
          <WSQueries queries={{ accountsQuery }}>
            {({ accountsQuery: { data: accounts } }) => {
              const possiblePaymentsAccounts =
                getPossiblePaymentsAccounts(accounts);

              const defaultPaymentMethods = [
                PaymentMethod.ACH,
                PaymentMethod.Credit,
                PaymentMethod.Manual
              ];

              const visiblePaymentMethods = defaultPaymentMethods.filter(
                method => {
                  const customAvailableMethods =
                    store.signUpAutopayDetails?.enabledPaymentMethods;
                  if (
                    customAvailableMethods &&
                    customAvailableMethods.length > 0
                  ) {
                    return customAvailableMethods.some(
                      _method => _method.toLowerCase() === method.toLowerCase()
                    );
                  }

                  return true;
                }
              );

              return (
                <>
                  <PaymentTypeSelect
                    availablePaymentMethods={visiblePaymentMethods}
                    onSelect={paymentMethod => {
                      if (paymentMethod === PaymentMethod.ACH) {
                        if (possiblePaymentsAccounts.length > 0) {
                          openModal(CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL, {
                            shouldSetDefaultPaymentMethod: true
                          });
                        } else {
                          plaidHandler.open();
                        }
                      } else if (paymentMethod === PaymentMethod.Credit) {
                        onNext({ paymentMethodType: PaymentMethod.Credit });
                      } else if (paymentMethod === PaymentMethod.Manual) {
                        openModal("MANUAL_WIRE_TRANSFER_MODAL");
                      }
                    }}
                    withBorder
                  />

                  <ChooseAccountForPaymentMethodsModal
                    onSuccess={() => {
                      closeModal(CHOOSE_ACCOUNT_FOR_PAYMENT_METHODS_MODAL);
                      onNext({ paymentMethodType: PaymentMethod.ACH });
                    }}
                  />

                  <WSModal
                    name="MANUAL_WIRE_TRANSFER_MODAL"
                    title={`Here are the US bank wire details to use when making one-time payments to ${companyName}.`}
                    size="S"
                  >
                    <>
                      <WSText mb="XL">
                        You may want to save this information now, but it is
                        also visible any time you select Wire / Manual ACH when
                        sending a payment. To use Autopay,{" "}
                        <WSButton.Link
                          onClick={() => {
                            closeModal("MANUAL_WIRE_TRANSFER_MODAL");
                          }}
                        >
                          add a payment method
                        </WSButton.Link>
                        .
                      </WSText>

                      <WSText.Heading5 mb="XL">
                        Wire / Manual ACH information
                      </WSText.Heading5>

                      <ManualWireTransferDetails
                        bankTransferInfo={domesticBankAccount}
                        internationalBankTransferInfo={internationalBankAccount}
                        mb="XL"
                      />

                      <WSButton
                        fullWidth
                        onAsyncClick={async () => {
                          await completePaymentsActivity();
                          closeModal("MANUAL_WIRE_TRANSFER_MODAL");
                          history.push("/member/invoices/payables");
                        }}
                      >
                        {`View payables from ${companyName}`}
                      </WSButton>
                    </>
                  </WSModal>
                </>
              );
            }}
          </WSQueries>
        </>
      )}
    </SetupAutopayLayout>
  );
};
