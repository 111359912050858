import { useUserId } from "../../../query/hooks/helpers";
import { useUserProfile } from "../../../query/users/queries";
import { DataView } from "../../../shared/components/DataView";

export const ReviewOriginalIndividualName: React.FC = () => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);

  return (
    <DataView
      title="Legal name"
      pill={{ text: "Original" }}
      data={[
        {
          label: "Legal first name",
          value: queryUser.data?.profile.firstName
        },
        {
          label: "Legal last name",
          value: queryUser.data?.profile.lastName
        }
      ]}
    />
  );
};
