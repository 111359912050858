import { useHistory } from "react-router-dom";
import { WSButton } from "@wingspanhq/fe-component-library";

import { NotificationTemplate } from "../index";
import { useUploadFileModal } from "../../../../Requirements/modules/SharedFile/components/useUploadFileModal";
import { queryCache } from "react-query";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../../../../query/notifications/keys";

export type FileSharingRequestContractorContext = {
  payerPayeeId: string;
  sharedFileRequestId: string;
  payerPayeeEngagementId: string;
  sharedFileRequestSettingsId: string;
  title: string;
  payer: {
    payerId: string;
    payerName: string;
    logoFileId?: string;
  };
};
export const FileSharingRequestContractor: NotificationTemplate<FileSharingRequestContractorContext> =
  {
    renderTitle: props => "File sharing requested",
    renderBody: props => {
      if (props.group.length > 1) {
        return "Your clients requested some documents to be shared to complete the onboarding process.";
      }

      return (
        <>
          Company <b>{props.context.payer.payerName}</b> requires you to share{" "}
          <b>{props.context.title}</b> to complete the onboarding process.
        </>
      );
    },
    Footer: props => {
      const uploadFileModal = useUploadFileModal();

      const history = useHistory();
      // const [signDocument] = useSignDocument();

      if (props.group.length > 1) {
        return (
          <WSButton.Link
            size="S"
            rightIcon="chevron-right"
            onClick={() => {
              history.push("/member/dashboard/contractor-files-sharing");
            }}
          >
            Open requests
          </WSButton.Link>
        );
      } else {
        return (
          <WSButton.Link
            size="S"
            rightIcon="chevron-right"
            onAsyncClick={async () => {
              await uploadFileModal.open({
                sharedFileRequestId: props.context.sharedFileRequestId,
                sharedFileRequestSettingsId:
                  props.context.sharedFileRequestSettingsId,
                payerId: props.context.payer.payerId,
                payeeId: props.context.payerPayeeId,
                onSuccess: () => {
                  queryCache.refetchQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
                }
              });
            }}
          >
            Upload <b>{props.context.title}</b>
          </WSButton.Link>
        );
      }
    }
  };
