import { IMember } from "@wingspanhq/users/dist/lib/interfaces";
import merge from "lodash/merge";
import { useMemo } from "react";
import { formatPhone } from "../../../shared/utils/formatPhone";
import { getLast4 } from "../../../shared/utils/getLast4";
import { employersNumberLabelMap } from "../utils/employersNumber";
import { insdustryLabelMap } from "../utils/industry";
import { taxClassificationLabelMap } from "../utils/taxClassification";
import { getTaxIdLabel } from "../utils/taxId";
import { DataView } from "../../../shared/components/DataView";
import { FormDataBusinessInformation } from "./FormBusinessInformation";
import { getProfileDataBusiness } from "./FormBusinessInformation/getProfileDataBusiness";

type Props = {
  formData?: FormDataBusinessInformation;
  onEdit?: () => void;
  country: string;
  personalEmail?: string;
  member?: IMember;
};

export const ReviewBusinessInformation: React.FC<Props> = ({
  formData,
  onEdit,
  country,
  personalEmail,
  member
}) => {
  const profileData = useMemo(() => getProfileDataBusiness(member), [member]);

  const data = useMemo(() => {
    if (!formData) {
      return profileData;
    }

    return merge(profileData, formData);
  }, [formData, profileData]);

  return (
    <DataView
      title="Business information"
      onEdit={onEdit}
      data={[
        {
          label: "Legal business name",
          value: data.legalBusinessName,
          fsExclude: true
        },
        {
          label: "Doing business as (DBA)",
          value: data.dba,
          fsExclude: true
        },
        data.taxClassification
          ? {
              label: "Federal tax classification",
              value: taxClassificationLabelMap[data.taxClassification]
            }
          : null,
        {
          label: getTaxIdLabel("Business", country),
          value:
            typeof data.taxId === "string" && data.taxId
              ? `••• ${getLast4(data.taxId)}`
              : data.taxId,
          fsExclude: true
        },
        {
          label: "Industry",
          value: data.industry ? insdustryLabelMap[data.industry] : undefined
        },
        {
          label: "Business email",
          value: data.usePersonalEmail ? personalEmail : data.email
        },
        {
          label: "Business phone number",
          value: data.phone
            ? formatPhone(`+${data.phone.countryCode}${data.phone.phoneNumber}`)
            : undefined
        },
        {
          label: "Business website",
          value: data.noWebsite ? "None" : data.website
        },
        {
          label: "Employees",
          value: data.numberOfEmployees
            ? employersNumberLabelMap[data.numberOfEmployees]
            : undefined
        },
        {
          label: "Business address",
          value: data.address
            ? [
                data.address.addressLine1,
                data.address.addressLine2,
                [
                  data.address.city,
                  data.address.state,
                  data.address.postalCode
                ].join(" ")
              ]
                .filter(Boolean)
                .join("\n")
            : undefined,
          fsExclude: true
        },
        country === "CA" &&
        data.address?.state === "QE" &&
        data.canadaQuebecEnterpriseNumber
          ? {
              label: "Québec Enterprise Number (NEQ)",
              value: data.canadaQuebecEnterpriseNumber,
              fsExclude: true
            }
          : null,
        country === "CA" || country === "US"
          ? {
              label: "State/Territory of incorporation",
              value: data.stateOfIncorporation || undefined
            }
          : null,
        country === "CA"
          ? {
              label: "Year of incorporation",
              value: data.yearOfIncorporation || undefined
            }
          : null
      ]}
    />
  );
};
