import {
  useWSModal,
  WSActions,
  WSDivider,
  WSElement,
  WSInfoBox,
  WSList,
  WSProgressBar,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import React, { useEffect } from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { useConfirmPayeeEmailUpdate } from "../../../../query/payee/mutations/useConfirmPayeeEmailUpdate";
import { IPayeeEmailChangeResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEmailChange";

type EditEmailUpdateContractorModalProps = {
  onClose: () => void;
  onSuccess?: () => void;
  response: IPayeeEmailChangeResponse;
};

const UpdateContractorProcessModal: React.FC<
  EditEmailUpdateContractorModalProps
> = ({ onClose, onSuccess, response }) => {
  const [confirmEmailUpdate, confirmEmailUpdateMeta] =
    useConfirmPayeeEmailUpdate();

  const [progress, setProgress] = React.useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => {
        if (p >= 80) {
          clearInterval(interval);
        }

        return p + 10;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    confirmEmailUpdate(
      {
        payeeId: response.sourcePayeeId,
        targetEmail: response.targetEmail
      },
      {
        onSuccess: () => {
          onSuccess?.();
          onClose();
        }
      }
    );
  }, [confirmEmailUpdate]);

  return (
    <WSList gap="L">
      <WSElement px="M">
        <WSSectionToolbar title="Adding contractor data to existing contractor profile" />

        <WSProgressBar
          mt="XL"
          noAnimation={false}
          percent={confirmEmailUpdateMeta.isLoading ? progress : 100}
          text={null}
        />
      </WSElement>

      <WSInfoBox>
        We are updating the contractor’s profile. This can take a few seconds.
      </WSInfoBox>

      <WSErrorMessage
        contextKey={ErrorContextKey.Other}
        error={confirmEmailUpdateMeta.error}
      />

      <WSDivider />

      <WSActions
        alignment="fill"
        buttons={[
          {
            label: "Continue",
            disabled: true,
            kind: "Primary"
          }
        ]}
      />
    </WSList>
  );
};

export function useEditEmailUpdateContractorProcessModal() {
  return useWSModal(UpdateContractorProcessModal, {
    title: "Update contractor profile",
    size: "S"
  });
}
