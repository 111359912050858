import React from "react";
import {
  IWSModalContext,
  WSElement,
  WSIconName,
  WSImageProps,
  WSPillProps,
  WSText
} from "@wingspanhq/fe-component-library";
import { TextList } from "../components/TextList/TextList.module";
import { createHealthSherpaLink, getPathForBenefit } from "../utils";
import { Link } from "react-router-dom";
import {
  INewUser,
  SubscriptionPackage,
  SubscriptionPackageTier
} from "@wingspanhq/users/dist/lib/interfaces";
import { IMember } from "@wingspanhq/users";
import { openInNewTab } from "../../utils/openInNewTab";
import {
  BENEFITS_ADD_ZIP_CODE_MODAL,
  BenefitsAddZipCode
} from "../modals/BenefitsAddZipCode";

import {
  BenefitBlockProps,
  BenefitBlockStatus
} from "../components/BenefitBlock/BenefitBlock.module";
import { IFeatureFlags } from "../../query/hooks/useFeatureFlags";
import HealthInsuranceImage from "../assets/HealthInsuranceImage.png";
import MeridioHealthInsuranceImage from "../assets/MeridioHorizontalLogo.png";
import MeridioHealthInsuranceLogo from "../assets/MeridioSmallLogo.png";

import {
  IWebflowBenefit,
  IWebflowBenefitCategory,
  IWebflowBenefitMinimumSubscription
} from "@wingspanhq/cms/dist/lib/interfaces";
import qs from "qs";

export interface IBenefitFaq {
  question: React.ReactNode;
  answer: React.ReactNode;
}

export interface IBenefitProviderContact {
  phone?: string;
  email?: string;
}

export interface IBenefitCTAWidget {
  headerText?: string;
  logo?: unknown;
  company?: string;
  transitionLogo?: unknown;
  usageText?: React.ReactNode;
  videoScript?: React.ReactNode;
  ctaBtnText: React.ReactNode;
  ctaLink: string;
  ctaBtnFooter: React.ReactNode;
  contactInfo?: IBenefitProviderContact;
  memberNumber?: string;
  cost?: string;
  status: BenefitBlockStatus;
  isPremium?: boolean;
}

export interface IBenefitCard {
  icon: WSIconName;
  description: React.ReactNode;
}

export interface IBenefitCardSection {
  title?: React.ReactNode;
  cards: Array<IBenefitCard>;
}

export interface IBenefitLineItem {
  description: React.ReactNode;
  amount: number;
}

export interface IBenefitCostExample {
  title: React.ReactNode;
  lineItems: Array<IBenefitLineItem>;
  finalCost: IBenefitLineItem;
}

export interface IBenefitTab {
  title: React.ReactNode;
  routePath: string;
  description: React.ReactNode;
  videoScript?: React.ReactNode;
  cardSection?: IBenefitCardSection;
  costExample?: IBenefitCostExample;
}

export interface IBenefitDetails {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  gettingStarted: IBenefitCTAWidget;
  tabs: Array<IBenefitTab>;
  faqs?: Array<IBenefitFaq>;
}

export interface BenefitData extends BenefitBlockProps {
  ctaImage?: WSImageProps;
  details?: (
    user: INewUser,
    member: IMember,
    features: IFeatureFlags,
    openModal: IWSModalContext["openModal"]
  ) => React.ReactNode;
  detailsJson?: IBenefitDetails;
  primaryButton?: (
    user: INewUser,
    member: IMember,
    features: IFeatureFlags,
    openModal: IWSModalContext["openModal"]
  ) => {
    label: string;
    link?: string;
    onClick?(): void;
    permanent?: boolean;
    modal?: React.ReactNode;
  };
  secondaryButton?: (
    user: INewUser,
    member: IMember,
    features: IFeatureFlags,
    openModal: IWSModalContext["openModal"]
  ) => {
    label: string;
    link?: string;
    onClick?(): void;
  };
  skipActivation?: boolean;
  hideUserData?: boolean;
  linkMore?: string;
}

export enum BenefitCardStatus {
  INCLUDED = "INCLUDED",
  DEFAULT = "DEFAULT",
  PREMIUM = "PREMIUM",
  COMING_SOON = "COMING_SOON"
}

export const BenefitsBadgeProps: { [key: string]: WSPillProps | undefined } = {
  [BenefitCardStatus.DEFAULT]: undefined,
  [BenefitCardStatus.COMING_SOON]: {
    text: "Coming soon"
  },
  [BenefitCardStatus.INCLUDED]: {
    text: "Included",
    theme: "success"
  },
  [BenefitCardStatus.PREMIUM]: {
    text: "Premium",
    theme: "neutral",
    icon: "lock"
  }
};

export const BENEFITS = [
  {
    ctaImage: {
      placeholder: "Health insurance logos",
      src: HealthInsuranceImage
    },
    title: "Health Insurance",
    status: BenefitBlockStatus.DEFAULT,
    cost: "Plans start as low as $149/month",
    icon: "health",
    customIcon: MeridioHealthInsuranceLogo,
    description:
      "Helping you find, enroll in, and make the best use of your health insurance plan.",
    details: (user: INewUser, member: IMember, featureFlags: IFeatureFlags) => {
      return (
        <>
          <BenefitsAddZipCode />
          <WSText.Heading5>
            Calculate your most affordable health plan
          </WSText.Heading5>
          <TextList
            mt="XL"
            list={[
              "See what savings you qualify for",
              "Compare quotes from multiple insurance companies",
              "We'll calculate the most affordable plan for your needs"
            ]}
          />
          <WSText.Heading5 mt="XL">Need help?</WSText.Heading5>
          <WSText mt="XL">
            Give us a call at <a href="tel:833-945-1760">(833) 945-1760</a>, and
            we'll help answer any questions or help you get enrolled!
          </WSText>
        </>
      );
    },
    hideUserData: true,
    skipActivation: true,
    primaryButton: (
      user: INewUser,
      member: IMember,
      featureFlags: IFeatureFlags,
      openModal: (name: string) => void
    ) => {
      const zipCode = member.profile.address?.postalCode as string;

      if (!zipCode) {
        return {
          permanent: true,
          label: "Get covered",
          onClick() {
            openModal(BENEFITS_ADD_ZIP_CODE_MODAL);
          }
        };
      }

      return {
        permanent: true,
        label: "Get covered",
        onClick() {
          openInNewTab(createHealthSherpaLink(zipCode));
        }
      };
    }
  },
  {
    title: "Pet Insurance",
    icon: "pet",
    status: BenefitCardStatus.COMING_SOON,
    description: (
      <>
        We’re still working on the best solution for you. In the meantime, check
        out{" "}
        <span onClick={e => e.stopPropagation()}>
          <Link to={getPathForBenefit("Pet Care", "my-benefits")}>
            pet care
          </Link>
        </span>
        .
      </>
    )
  },
  {
    title: "Therapy",
    status: BenefitBlockStatus.COMING_SOON,
    icon: "life",
    description: "We’re still working on the best solution for you."
  },
  {
    title: "Life Insurance",
    status: BenefitBlockStatus.COMING_SOON,
    icon: "life",
    description: "We’re still working on the best solution for you."
  },
  {
    title: "Business Liability Insurance",
    status: BenefitBlockStatus.COMING_SOON,
    icon: "briefcase",
    description: "We’re still working on the best solution for you."
  },
  {
    title: "Retirement Plans",
    status: BenefitBlockStatus.COMING_SOON,
    icon: "home-heart",
    description: "We’re still working on the best solution for you."
  }
] as const;

export const getMeridioHealthInsuranceBenefit = (member: IMember) => {
  const searchParams = qs.stringify({
    name: {
      first: member.user.profile.firstName,
      last: member.user.profile.lastName
    },
    phoneNumber: member.user.phone.number?.substring(2),
    email: member.user.email,
    wingspanId: member.memberId
  });

  const benefit: IWebflowBenefit = {
    helperLogo: {
      src: MeridioHealthInsuranceImage,
      placeholder: ""
    },
    logo: {
      src: MeridioHealthInsuranceLogo,
      placeholder: ""
    },
    mainVideo: {
      src: "https://player.vimeo.com/video/827040201?h=ae3c844ace"
    },
    collectionId: "",
    editedOn: new Date(),
    isDraft: false,
    name: "Meridio Health Insurance",
    itemId: "",
    publishedOn: new Date(),
    slug: "meridio-health-insurance",
    category: IWebflowBenefitCategory.Health,
    minSubscription: IWebflowBenefitMinimumSubscription.Benefits,
    minSubscriptionPackage: SubscriptionPackage.Benefits,
    minSubscriptionPackageTier: SubscriptionPackageTier.Basic,
    atAGlance:
      "See a doctor from the comfort of your own home via live video chat. Available 24/7.",
    featured: true,
    helperCostDescription: "Plans start as low as $149/month",
    helperCostIsNotIncluded: true,
    helperCTAPrimaryButtonLink: `https://bnfts.us/wingspan-onboard?${searchParams}`,
    helperCTAPrimaryButtonText: "Get Started!",
    helperHeader: "Helping you Work, Plan and Live Better.",
    helperHideMemberAndGroupInfo: true,
    helperUsageText:
      "Review medical, dental & vision plans and schedule a needs assessment with a licensed Benefits Guide.",
    isAdditionalCoverage: false,
    hidden: false,
    mainCards:
      "heart\nPreventive Care and Competitive Health Coverage\n-\nhealth\nDental & Vision Coverage",
    // "health\nComprehensive\nHealth\nInsurance\n-\ndental\nDental &\nVision\nCoverage\n-\nbankFees\nBudget-friendly\nOptions\n ",
    mainDescription:
      "Wingspan partners with Meridio to offer contractors access to healthcare benefits and assistance in navigating options from preventive to traditional medical, dental & vision coverage.",
    mainFAQSItems: (
      <WSText.ParagraphSm as="div">
        <WSText.Paragraph mb="M" weight="medium">
          Am I still eligible for benefits if I have not worked any hours?
        </WSText.Paragraph>
        Yes! Wingspan has partnered with Meridio to provide access to all
        contractors. Ensure that you have a default payment method by going to:
        <WSElement as="ul" ml="XL">
          <li>Settings</li>

          <li>Payout & Payment Methods</li>

          <li>Send Payments</li>

          <li>Add payment method</li>

          <li>Mark as the default payment method</li>
        </WSElement>
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          What kind of health coverage does Meridio offer?
        </WSText.Paragraph>
        Meridio offers preventive and competitive plans starting at $149/month
        per individual. Dental & Vision plans start at $30/month per individual.
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          Why choose Meridio?
        </WSText.Paragraph>
        Meridio’s health plans are designed to support contractors offering
        guaranteed acceptance, standard rates in all 50 states, $0 deductibles,
        and reasonable premiums. With Meridio, you choose the benefits that are
        right for you and your household, and if you change careers, your
        coverage plan goes with you.
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          Where can I review plans?
        </WSText.Paragraph>
        You can review plan options by visiting{" "}
        <a href="https://www.getmeridio.com/b/ws-b?companydisplayname=Wingspan&cd=Q87LLCI6&phone=1-888-294-9181">
          HERE
        </a>
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          Can I add my significant other?
        </WSText.Paragraph>
        Yes, you can add your significant other, fiance, or domestic partner.
        Meridio also provides coverage options for children and family members.
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          Will my physician be in network?
        </WSText.Paragraph>
        Meridio utilizes the PHCS network through Multiplan which is the largest
        independent PPO network in the nation. You can visit{" "}
        <a href="https://www.multiplan.us/">multiplan.com</a> to search for your
        existing providers following the steps below:
        <WSElement as="ul" ml="XL">
          <li>Click “Find a Provider” located at the top right-hand corner</li>
          <li>Click “Select Network”</li>
          <li>
            Select “PHCS” Depending on the coverage type your interested in next
            steps will vary: <br />
            <b>Preventive:</b> MEC Ultimate → select “Preventive only” MEC
            Ultimate HI → select “Specifics” <br />
            <b>Competitive:</b> Both IHP plans → select “Practitioner &
            Ancillary”
          </li>
        </WSElement>
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          When is open enrollment?
        </WSText.Paragraph>
        Open enrollment is year-round. If you’re looking for coverage as soon as
        the 1st of the following month, enroll by the 10th of the current month.
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          When do my benefits take effect?
        </WSText.Paragraph>
        Depending on when you enroll, benefits take effect on the first of the
        following month.
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          When will I get my ID cards?
        </WSText.Paragraph>
        Physical cards and packet will be mailed mid-month AFTER your coverage
        start date.
        <WSText.Paragraph mt="L" mb="M" weight="medium">
          ****To access your digital cards:
        </WSText.Paragraph>
        <WSElement as="ul" ml="XL">
          <li>Competitive (IHP) Plans: Refer to the guidebook</li>

          <li>
            Preventive (MEC) Plans: Email request to{" "}
            <a href="mailto:customercare@getmeridio.com">
              customercare@getmeridio.com
            </a>
          </li>
        </WSElement>
      </WSText.ParagraphSm>
    ) as any as string,
    mainHeader: "Everyday Health",
    orderPriority: 1
  };

  return benefit;
};
