import {
  ICheckbookCard,
  IPayoutSettingsResponse
} from "@wingspanhq/payments/dist/interfaces";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { listInternalAccounts } from "../../../services/api/banking/internalAccounts";
import { selectorAccountFullName } from "../../../shared/selectors/selectorAccountFullName";
import { selectorActiveBankingAccount } from "../../../shared/selectors/selectorActiveBankingAccount";
import { selectorDebitCardFullName } from "../../../shared/selectors/selectorDebitCardFullName";
import { selectorDefaultDestination } from "../../../shared/selectors/selectorDefaultDestination";
import { PayoutMethod } from "../steps/PayoutMethod";
import {
  OnboardingContext,
  OnboardingModulePayoutMethod,
  OnboardingStep,
  OnboardingStepStatus
} from "../types";

const getStatus = (
  payoutSettings?: IPayoutSettingsResponse,
  internalAccounts?: Awaited<ReturnType<typeof listInternalAccounts>>
): OnboardingStepStatus => {
  const hasPayoutDestination =
    !!payoutSettings && selectorDefaultDestination(payoutSettings);

  const hasActiveBankingAccount = internalAccounts?.some(
    selectorActiveBankingAccount
  );

  if (hasPayoutDestination || hasActiveBankingAccount) {
    return "Complete";
  }

  const hasPendingBankingAccount = internalAccounts?.some(
    internalAccount =>
      internalAccount.type === "Banking" && internalAccount.status === "Pending"
  );

  if (hasPendingBankingAccount) {
    return "Pending";
  }

  return "None";
};

export const getPayoutMethodStepConfig = (
  _: OnboardingModulePayoutMethod,
  __: OnboardingContext,
  payoutSettings?: IPayoutSettingsResponse,
  accounts?: IAccount[],
  debitCards?: ICheckbookCard[],
  internalAccounts?: Awaited<ReturnType<typeof listInternalAccounts>>
): OnboardingStep => {
  const slug = "payout";

  const status = getStatus(payoutSettings, internalAccounts);

  const methodText = getMethodText(
    payoutSettings,
    accounts,
    debitCards,
    internalAccounts
  );

  return {
    title: "Add payout method",
    slug,
    component: PayoutMethod,

    status,
    result: {
      Complete: {
        title: "Your payout method was added",
        description: `The payout method was added and your payments will be deposited into your ${methodText}. Click continue for the next step.`
      },
      Pending: {
        title: "Your payout method is almost set up",
        description:
          "Please, go to the Wallet and follow instructions to complete setup"
      }
    }
  };
};

const getMethodText = (
  payoutSettings?: IPayoutSettingsResponse,
  accounts?: IAccount[],
  debitCards?: ICheckbookCard[],
  internalAccounts?: Awaited<ReturnType<typeof listInternalAccounts>>
) => {
  const defaultDestination = payoutSettings
    ? selectorDefaultDestination(payoutSettings)
    : undefined;

  const matchingAccount = accounts?.find(
    account => account.accountId === defaultDestination?.destinationId
  );

  if (matchingAccount) {
    return (
      selectorAccountFullName(matchingAccount) +
      " account via direct deposit (ACH)"
    );
  }

  const matchingDebitCard = debitCards?.find(
    debitCard => debitCard.cardId === defaultDestination?.destinationId
  );

  if (matchingDebitCard) {
    return selectorDebitCardFullName(matchingDebitCard);
  }

  const activeBankingAccount = internalAccounts?.find(
    selectorActiveBankingAccount
  );

  if (activeBankingAccount) {
    return "Wingspan Wallet";
  }
};
