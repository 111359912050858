import {
  SelectOptionOld,
  useModalContext,
  WSButtons,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSPanel,
  WSSelectOld,
  WSTable,
  WSTableCell,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { ProjectOnboardingLayout } from "../../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../../query/WSQuery";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksVendors
} from "../../../../query/integrations/queries";
import { isQuickbooksAuthenticated } from "../../../../query/integrations/selectors";
import { useDynamicUpdateCollaborator } from "../../../../query/payments/mutations";
import { useCollaboratorsQuery } from "../../../../query/payments/queries";
import {
  getCollaboratorCompany,
  getCollaboratorName,
  getVisibleCollaborators
} from "../../../../query/payments/selectors";
import { bulkActionFactory } from "../../../../utils/bulkActionFactory";
import { getChangedData } from "../../../../utils/getChangedData";
import styles from "./Quickbooks.module.scss";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "./RefreshButton";
import { selectQuickbooksUserOptions } from "../../../../modules/Integrations/selectors/selectQuickbooksUserOptions";

export const QuickbooksSetupStep6: React.FC<RouteComponentProps<any>> = ({
  history
}) => {
  const { openModal } = useModalContext();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const qCollaborators = useCollaboratorsQuery();

  const [updateCollaborator] = useDynamicUpdateCollaborator();
  const [error, setError] = useState("");

  const updateCollaboratorsAction = bulkActionFactory({
    onStart() {
      setError("");
    },
    async action(data: { collaboratorId: string; vendorId: string }) {
      await updateCollaborator(
        {
          collaboratorId: data.collaboratorId,
          integration: {
            quickbooks: {
              vendorId: data.vendorId
            }
          }
        },
        {
          throwOnError: true
        }
      );
    },
    onFinish({ successItems, failedItems }) {
      if (failedItems.length) {
        setError("Something went wrong!");
      } else {
        history.push("/member/settings/integrations/quickbooks/setup/step/7");
      }
    }
  });

  return (
    <ProjectOnboardingLayout progress={(100 / 8) * 6}>
      <WSQueries
        queries={{
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksVendors,
          qCollaborators
        }}
      >
        {({
          qIntegrationsQuickbooks,
          qIntegrationsQuickbooksVendors,
          qCollaborators
        }) => {
          const integrationState = qIntegrationsQuickbooks.data;
          const vendors = qIntegrationsQuickbooksVendors.data;

          const quickbooksVendorOptions: SelectOptionOld[] =
            selectQuickbooksUserOptions(vendors);

          const collaborators = getVisibleCollaborators(qCollaborators.data);

          if (!isQuickbooksAuthenticated(integrationState)) {
            return (
              <Redirect to="/member/settings/integrations/quickbooks/setup" />
            );
          }

          const defaultFormData = collaborators.reduce(
            (res, collaborator) => ({
              ...res,
              [collaborator.collaboratorId]:
                collaborator.integration?.quickbooks?.vendorId
            }),
            {} as any
          );

          return (
            <WSContainer verticalPadding>
              <WSCentered span={{ m: "8" }}>
                <WSText.ParagraphSm color="gray500" mt="M" pt="M">
                  6 of 8
                </WSText.ParagraphSm>
                <WSText.Heading4>Map Vendors</WSText.Heading4>

                <WSPanel mt="2XL" mb="5XL">
                  <WSFormOld
                    onSubmit={async formData => {
                      const changedData = getChangedData(
                        defaultFormData,
                        formData
                      );

                      await updateCollaboratorsAction.run(
                        Object.keys(changedData).map(collaboratorId => ({
                          collaboratorId,
                          vendorId: formData[collaboratorId] ?? null
                        }))
                      );
                    }}
                    defaultValues={defaultFormData}
                  >
                    <WSText.Heading5 mt="XL">
                      Import and associate Quickbooks vendors with Wingspan
                      contractors
                    </WSText.Heading5>

                    {collaborators.length > 0 ? (
                      <>
                        <WSText mt="XL">
                          Please map your existing contractors to vendors in
                          Quickbooks. Any contractors you don't map will have a
                          new vendor created in QBO alongside any payables
                          activity
                        </WSText>

                        <WSTable
                          mt="XL"
                          tableData={collaborators.map(collaborator => ({
                            id: collaborator.collaboratorId,
                            data: collaborator
                          }))}
                          columns={[
                            {
                              config: {
                                gridTemplateSizeMax: "1fr"
                              },
                              renderFunction: ({ data }) => (
                                <WSTableCell
                                  avatar={{
                                    type: "text",
                                    text: getCollaboratorName(data)
                                  }}
                                  text={getCollaboratorName(data)}
                                  secondaryText={getCollaboratorCompany(data)}
                                />
                              )
                            },
                            {
                              config: {
                                gridTemplateSizeMax: "1fr",
                                className: styles.column
                              },
                              renderFunction: ({ data }) => (
                                <WSFlexBox.CenterY>
                                  <WSFormOld.Field
                                    name={data.collaboratorId}
                                    component={WSSelectOld}
                                    componentProps={{
                                      placeholder: "Not mapped",
                                      options: quickbooksVendorOptions,
                                      searchable: true,
                                      cleanable: true,
                                      placeholderActions: [
                                        {
                                          label: "Resync QBO Vendors",
                                          icon: "refresh-v",
                                          callback() {
                                            openModal(
                                              INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
                                              {
                                                entity:
                                                  QUICKBOOKS_ENTITY.VENDORS
                                              }
                                            );
                                          }
                                        }
                                      ]
                                    }}
                                    label=""
                                  />
                                </WSFlexBox.CenterY>
                              )
                            }
                          ]}
                        />
                        <WSErrorMessage
                          mt="2XL"
                          contextKey="IntegrationsQuickbooksMapping"
                          error={error}
                        />
                      </>
                    ) : (
                      <>
                        <WSText mt="XL">
                          It appears you have no existing contractors in
                          Wingspan. You can skip this step.
                        </WSText>
                      </>
                    )}
                    <WSFlexBox.Center mt="2XL">
                      <WSButtons>
                        <WSFormOld.SubmitButton name="next">
                          Next
                        </WSFormOld.SubmitButton>
                      </WSButtons>
                    </WSFlexBox.Center>
                  </WSFormOld>
                </WSPanel>
              </WSCentered>
            </WSContainer>
          );
        }}
      </WSQueries>
      <RefreshModal />
    </ProjectOnboardingLayout>
  );
};
