import { useEffect, useRef } from "react";
import { useWSQuery } from "../../query/helpers";
import { useIsQueriesFetched } from "./useIsQueriesFetched";

type Query = ReturnType<typeof useWSQuery>;
export const useOnQueriesFetched = (
  callback: () => void,
  ...queries: [Query, ...Query[]]
) => {
  const isTriggered = useRef(false);
  const isAllFetched = useIsQueriesFetched(...queries);

  useEffect(() => {
    if (isAllFetched && !isTriggered.current) {
      isTriggered.current = true;
      callback();
    }
  }, [isAllFetched, isTriggered.current]);
};
