import {
  WSDivider,
  WSElementProps,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { openInNewTab } from "../../../shared/utils/openInNewTab";

type Props = WSElementProps;

const link = (url: string) => () => {
  openInNewTab(url);
};

const Link: React.FC<{ url: string; text: string }> = ({ url, text }) => (
  <WSText.ParagraphSm color="blue400" weight="medium" onClick={link(url)}>
    {text}
  </WSText.ParagraphSm>
);

export const FAQ1099: React.FC<Props> = props => (
  <WSList gap="XL" {...props}>
    <WSText.Paragraph weight="medium">
      Frequently asked questions
    </WSText.Paragraph>
    <WSPanel p="XL" colorBackground="gray50" noBorder>
      <WSList gap="M" mb="2XL">
        <WSText.ParagraphSm color="gray400">About the form</WSText.ParagraphSm>
        <WSDivider />

        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_962b6bc6c0"
          text="What is the 1099-NEC Form?"
        />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_348c9b2970"
          text="When will I receive my 1099-NEC?"
        />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions?q=electron#h_0bc0865f71"
          text="Is it available electronically?"
        />
      </WSList>

      <WSList gap="M" mb="2XL">
        <WSText.ParagraphSm color="gray400">Payments</WSText.ParagraphSm>
        <WSDivider />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_08831d4825"
          text="What amounts are included?"
        />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_b93d20c425"
          text="Why is the amount different than I expected?"
        />
      </WSList>

      <WSList gap="M" mb="2XL">
        <WSText.ParagraphSm color="gray400">
          Corrections & amendments
        </WSText.ParagraphSm>
        <WSDivider />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_748f87fef0"
          text="What if there’s an error on the form?"
        />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          text="Can I get a corrected 1099-NEC?"
        />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          text="What if I never receive my 1099-NEC?"
        />
      </WSList>

      <WSList gap="M">
        <WSText.ParagraphSm color="gray400">Misc</WSText.ParagraphSm>
        <WSDivider />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          text="What should I do if I stop contracting mid-year?"
        />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          text="Is this form reported to the IRS?"
        />
        <Link
          url="https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions"
          text="What happens if I dispute the amount?"
        />
      </WSList>
    </WSPanel>
  </WSList>
);
