import { useGoBackOrToParent } from "../../../utils/goToParentRoute";
import { LogoApp } from "../../../shared/components/LogoApp";
import headerStyles from "./../../../components/ProjectOnboardingLayout/ProjectOnboardingLayout.module.scss";
import { WSIcon, WSElement } from "@wingspanhq/fe-component-library";

export const BulkUploadHeader = ({ progress }: { progress: number }) => {
  const goBackOrToParent = useGoBackOrToParent();

  return (
    <header className={headerStyles.header}>
      <WSIcon
        block
        name="arrow-left"
        onClick={() => goBackOrToParent()}
        className={headerStyles.back}
      />

      <WSElement className={headerStyles.logoContainer}>
        <LogoApp />
      </WSElement>

      {progress ? (
        <hr
          className={headerStyles.progress}
          style={{ width: `${progress}%` }}
        />
      ) : null}
    </header>
  );
};
