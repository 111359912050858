import { WSPage, WSPageToolbar } from "@wingspanhq/fe-component-library";
import { Redirect, Route, Switch } from "react-router-dom";
import { PATH_SETTINGS } from "../..";
import { Tabs } from "../../../../components/Tabs";
import { RouteAddFundingMethod } from "./routes/RouteAddFundingMethod";
import { RouteAddPaymentMethod } from "./routes/RouteAddPaymentMethod";
import { RouteManageFundingMethod } from "./routes/RouteManageFundingMethod";
import { RouteMenagePaymentMethod } from "./routes/RouteMenagePaymentMethod";
import { RouteMethod } from "./routes/RouteMethod";
import { RoutePayables } from "./routes/RoutePayables";
import { RoutePayroll } from "./routes/RoutePayroll";

export const PATH_SETTINGS_PAYMENTS = PATH_SETTINGS + "/payroll";
export const PATH_SETTINGS_PAYMENTS_METHOD =
  PATH_SETTINGS_PAYMENTS + "/funding";
export const PATH_SETTINGS_PAYMENTS_PAYROLL =
  PATH_SETTINGS_PAYMENTS + "/schedule";
export const PATH_SETTINGS_PAYMENTS_PAYABLES =
  PATH_SETTINGS_PAYMENTS + "/payables";

export const PATH_SETTINGS_PAYMENTS_MANAGE_FUNDING_METHOD =
  PATH_SETTINGS_PAYMENTS + "/manage-funding-method";
export const PATH_SETTINGS_PAYMENTS_ADD_FUNDING_METHOD =
  PATH_SETTINGS_PAYMENTS + "/add-funding-method";

export const PATH_SETTINGS_PAYMENTS_MANAGE_PAYMENT_METHOD =
  PATH_SETTINGS_PAYMENTS + "/manage-payment-method";
export const PATH_SETTINGS_PAYMENTS_ADD_PAYMENT_METHOD =
  PATH_SETTINGS_PAYMENTS + "/add-payment-method";

export const RoutePayments: React.FC = () => {
  return (
    <WSPage>
      <WSPageToolbar title="Send payments settings" />

      <Tabs
        mb="2XL"
        tabs={[
          {
            label: "Payroll funding method",
            path: PATH_SETTINGS_PAYMENTS_METHOD
          },
          {
            label: "Payroll schedule",
            path: PATH_SETTINGS_PAYMENTS_PAYROLL
          },
          {
            label: "Payables settings",
            path: PATH_SETTINGS_PAYMENTS_PAYABLES
          }
        ]}
      />

      <Switch>
        <Route path={PATH_SETTINGS_PAYMENTS_METHOD} component={RouteMethod} />
        <Route path={PATH_SETTINGS_PAYMENTS_PAYROLL} component={RoutePayroll} />
        <Route
          path={PATH_SETTINGS_PAYMENTS_PAYABLES}
          component={RoutePayables}
        />

        <Route
          path={PATH_SETTINGS_PAYMENTS_MANAGE_FUNDING_METHOD}
          component={RouteManageFundingMethod}
        />
        <Route
          path={PATH_SETTINGS_PAYMENTS_ADD_FUNDING_METHOD}
          component={RouteAddFundingMethod}
        />

        <Route
          path={PATH_SETTINGS_PAYMENTS_MANAGE_PAYMENT_METHOD}
          component={RouteMenagePaymentMethod}
        />
        <Route
          path={PATH_SETTINGS_PAYMENTS_ADD_PAYMENT_METHOD}
          component={RouteAddPaymentMethod}
        />

        <Redirect to={PATH_SETTINGS_PAYMENTS_METHOD} />
      </Switch>
    </WSPage>
  );
};
