import { IInvitation, UserStatus } from "@wingspanhq/users/dist/lib/interfaces";
import { InvitationAuthLayout } from "../InvitationAuthLayout";
import { InvitationAuthSignUpForm } from "../InvitationAuthSignUpForm";
import { IInvitationResponse } from "@wingspanhq/users";
import { useCustomization } from "../../../customization";
import { PlatformContextType } from "@wingspanhq/users/dist/lib/interfaces/newUser";
import {
  PAYEES_FEATURES,
  PAYERS_FEATURES,
  TAX_ONLY_PAYEE_FEATURES
} from "../../static/featuresList";
import { InvitationAuthSignInForm } from "../InvitationAuthSignInForm";
import {
  WSButton,
  WSDivider,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";

type SignUpPageProps = {
  invitation: IInvitation;
  invitationData: IInvitationResponse;
};

export const AuthPage: React.FC<SignUpPageProps> = ({
  invitation,
  invitationData
}) => {
  const { branding } = useCustomization(invitation.inviterUserId);
  const { primaryLogoUrl, name: platformName } = branding(true);
  const hasInviter = !!invitationData.inviter?.name;
  const inviterName = invitationData.inviter?.name;

  const form =
    invitationData.invitee.status === UserStatus.Active ? (
      <InvitationAuthSignInForm
        invitation={invitation}
        invitationData={invitationData}
        organizationName={platformName}
      />
    ) : (
      <InvitationAuthSignUpForm
        invitation={invitation}
        invitationData={invitationData}
        organizationName={platformName}
      />
    );

  if (
    invitationData.context === PlatformContextType.Payer ||
    invitationData.context === PlatformContextType.TaxOnlyPayer
  ) {
    return (
      <InvitationAuthLayout
        title={`Welcome to ${platformName}`}
        description={
          <>
            {platformName} is trusted by businesses that rely on contingent
            work. Our platform helps you save time, ensure compliance, and
            enhance contractor satisfaction by providing everything you need in
            one place.
          </>
        }
        items={PAYERS_FEATURES}
        organizationLogo={primaryLogoUrl}
        clientLogo={invitationData.inviter?.logo}
      >
        {form}
      </InvitationAuthLayout>
    );
  } else if (invitationData.context === PlatformContextType.TaxOnlyPayee) {
    return (
      <InvitationAuthLayout
        title={
          hasInviter
            ? `${inviterName} invited you to ${platformName}`
            : `${platformName} invited you to onboard`
        }
        description={
          <>
            Complete your tax information setup to manage your documents and
            handle tax requirements with {platformName}.
            <br />
            <br />
            For U.S. persons, this often includes completing a W-9 and receiving
            tax forms like the 1099-NEC. International contractors may see
            different forms tailored to their country’s requirements.
          </>
        }
        items={TAX_ONLY_PAYEE_FEATURES}
        organizationLogo={primaryLogoUrl}
        clientLogo={invitationData.inviter?.logo}
        leftFooter={
          <>
            <WSText.Paragraph my="2XL" weight="medium">
              Frequently asked questions
            </WSText.Paragraph>
            <WSText.ParagraphSm color="gray500">
              About the form
            </WSText.ParagraphSm>
            <WSDivider my="M" />
            <WSFlexBox direction="column" gap="M" alignItems="flex-start">
              <WSButton.Link
                size="S"
                onClick={() =>
                  openInNewTab(
                    "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_962b6bc6c0"
                  )
                }
              >
                What is the 1099-NEC form?
              </WSButton.Link>
              <WSButton.Link
                size="S"
                onClick={() =>
                  openInNewTab(
                    "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions#h_348c9b2970"
                  )
                }
              >
                When will I receive my 1099-NEC form?
              </WSButton.Link>
              <WSButton.Link
                size="S"
                onClick={() =>
                  openInNewTab(
                    "https://help.wingspan.app/en/articles/8549483-1099-frequently-asked-questions?q=electron#h_0bc0865f71"
                  )
                }
              >
                Is it available electronically?
              </WSButton.Link>
            </WSFlexBox>
          </>
        }
      >
        {form}
      </InvitationAuthLayout>
    );
  } else {
    return (
      <InvitationAuthLayout
        title={
          hasInviter
            ? `${inviterName} invited you to ${platformName}`
            : `${platformName} invited you to onboard`
        }
        description={
          <>
            {hasInviter
              ? `${inviterName} wants to onboard you via ${platformName}. `
              : ""}
            {platformName} is here to make your work life easier by providing
            everything you need in one place.
          </>
        }
        items={PAYEES_FEATURES}
        organizationLogo={primaryLogoUrl}
        clientLogo={invitationData.inviter?.logo}
      >
        {form}
      </InvitationAuthLayout>
    );
  }
};
