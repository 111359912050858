import { QueryConfig } from "react-query";
import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { QUERY_ENGAGEMENT_LIST_ALL } from "../keys";
import times from "lodash/times";
import flatten from "lodash/flatten";
import { getEngagements } from "../../../services/engagements";
import { IEngagement } from "@wingspanhq/payments/dist/interfaces";
import {
  EngagementListQueryParams,
  mapEngagementsFilters
} from "./useEngagementListQuery";

export const useEngagementsListAllQuery = (
  params?: EngagementListQueryParams,
  config?: QueryConfig<IEngagement[], WSServiceError>
) => {
  const { sort, filter } = params || {};

  return useWSQuery<IEngagement[], WSServiceError>(
    [QUERY_ENGAGEMENT_LIST_ALL, params],
    async () => {
      const pageSize = 100;
      const {
        summary: { listSize },
        data
      } = await getEngagements({
        filter: mapEngagementsFilters(filter),
        page: { size: pageSize, number: 1 },
        sort
      });

      const pages = Math.ceil(listSize / pageSize) - 1;

      const actions = times(pages).map(
        (_, i) => () =>
          getEngagements({
            filter,
            page: { size: pageSize, number: i + 2 },
            sort
          })
      );

      const allPages = await concurrentActions(actions, {
        concurrentLimit: 5
      });

      return flatten([data, ...allPages.map(p => p.data)]);
    },

    {
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
};
