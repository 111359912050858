import {
  useWSModal,
  WSAvatar,
  WSFlexBox,
  WSInfoBox,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { IBackgroundCheckRequestResponse } from "@wingspanhq/background-checks/dist/lib/interfaces";

interface BackgroundCheckFeatureUpgradeModalProps {
  backgroundChecksRequest: IBackgroundCheckRequestResponse;
}

const ListItem: React.FC<{ label: string }> = ({ label }) => {
  return (
    <WSFlexBox.CenterY wrap="nowrap" alignItems="flex-start">
      <WSAvatar
        type="icon"
        format="square"
        icon="shield-check-fill"
        colorBackground="blue500"
        color="white"
        size="S"
        mr="M"
      />
      <WSText.ParagraphSm>{label}</WSText.ParagraphSm>
    </WSFlexBox.CenterY>
  );
};

const BackgroundChecksPackageDetails: React.FC<
  BackgroundCheckFeatureUpgradeModalProps
> = ({ backgroundChecksRequest }) => {
  return (
    <WSInfoBox>
      <WSList gap="L" py="S">
        <WSText.ParagraphSm color="gray500">
          Included reports
        </WSText.ParagraphSm>
        {backgroundChecksRequest?.package?.searches.map((search, index) => (
          <ListItem label={search.title!} key={index} />
        ))}
      </WSList>
    </WSInfoBox>
  );
};

export function useBackgroundChecksPackageDetailsModal() {
  return useWSModal(BackgroundChecksPackageDetails, {
    title: "Background check details",
    size: "S"
  });
}
