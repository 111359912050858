import { IClientInvoice } from "@wingspanhq/payments/dist/interfaces";
import { queryCache } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { QUERY_CLIENT_INVOICE } from "../../query/payments/keys";
import { ClientPaymentsInvoicePaymentCreditCardFootprint } from "./ClientPaymentsInvoicePaymentCreditCardFootprint";
import { ClientPaymentsInvoicePaymentCreditCardStripe } from "./ClientPaymentsInvoicePaymentCreditCardStripe";

export const RouteCreditCard: React.FC<
  RouteComponentProps<{
    invoiceId: string;
  }>
> = props => {
  const invoiceId = props.match.params.invoiceId;
  const clientInvoice = queryCache.getQueryData<IClientInvoice>([
    QUERY_CLIENT_INVOICE,
    invoiceId
  ]);
  const queryFeatureFlags = useFeatureFlags();

  const isFlagEnabled =
    queryFeatureFlags.data?.paymentCardsCPEGuest ||
    queryFeatureFlags.data?.paymentCardsCPE;
  const usePaymentCard = isFlagEnabled && clientInvoice?.usePaymentCard;

  return usePaymentCard ? (
    <ClientPaymentsInvoicePaymentCreditCardFootprint {...props} />
  ) : (
    <ClientPaymentsInvoicePaymentCreditCardStripe {...props} />
  );
};
