import {
  WSPage,
  WSTable,
  WSTableCell,
  WSTableItem,
  toWSDateString,
  toWSMoneyString,
  useIsMobile,
  useModalContext
} from "@wingspanhq/fe-component-library";
import { IDeductionResponse } from "@wingspanhq/payments/dist/interfaces/api/deductions";
import {
  DeductionStatus,
  DeductionType
} from "@wingspanhq/payments/dist/interfaces/deductions";
import React from "react";
import { Route, useHistory } from "react-router-dom";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCollaboratorDeductions,
  useCollaboratorQuery
} from "../../../query/payments/queries";
import {
  getCollaboratorCompany,
  getCollaboratorName
} from "../../../query/payments/selectors";
import { CompletedDeductionDetails } from "./CompletedDuductionDetails";
import { ScheduledDeductionDetails } from "./ScheduledDuductionDetails";
import { ADD_DEDUCTION_MODAL } from "./modals/AddDeduction";

export const DeductionsTab: React.FC<{
  collaboratorId: string;
  onRowClick(d: IDeductionResponse): void;
  status: DeductionStatus[];
}> = props => {
  const { collaboratorId, onRowClick, status } = props;
  const userId = useUserId();
  const isMobile = useIsMobile();
  const history = useHistory();
  const { openModal } = useModalContext();

  const qCollaborator = useCollaboratorQuery(collaboratorId);

  const clientId = userId;
  const memberId = qCollaborator.data?.memberId as string;

  const qDeductions = useCollaboratorDeductions(
    {
      memberId,
      clientId,
      status: { in: status }
    },
    {
      enabled: !!memberId
    }
  );

  return (
    <>
      <Route
        path="/member/invoices/contacts/collaborators/:collaboratorId/deductions/scheduled/:deductionId"
        component={ScheduledDeductionDetails}
      />
      <Route
        path="/member/invoices/contacts/collaborators/:collaboratorId/deductions/completed/:deductionId"
        component={CompletedDeductionDetails}
      />

      <WSQueries queries={{ qDeductions, qCollaborator }}>
        {({
          qDeductions: { data: deductions },
          qCollaborator: { data: collaborator }
        }) => {
          const addNew = () => {
            openModal(ADD_DEDUCTION_MODAL, {
              clientId,
              memberId
            });
          };

          const tableData: WSTableItem[] = deductions.map(deduction => {
            const { deductionId } = deduction;

            return {
              id: deductionId,
              data: deduction
            };
          });

          return (
            <WSPage
              title={`${
                getCollaboratorCompany(collaborator) ||
                getCollaboratorName(collaborator)
              }'s Deductions`}
              primaryButton={{
                label: "New deduction",
                name: "addDeduction",
                onClick: addNew
              }}
            >
              <WSTable<IDeductionResponse>
                mt="2XL"
                tableData={tableData}
                onRowClick={item => {
                  onRowClick(item.data);
                }}
                columns={[
                  {
                    config: {
                      gridTemplateSizeMax: "1fr",
                      header: {
                        text: "Name"
                      }
                    },
                    renderFunction: ({ data }) => {
                      return <WSTableCell text={data.name} />;
                    }
                  },
                  {
                    config: {
                      gridTemplateSizeMin: "min-content",
                      gridTemplateSizeMax: "1fr",
                      header: {
                        text: "Amount"
                      }
                    },
                    renderFunction: ({ data }) => {
                      if (data.status !== DeductionStatus.PartiallyApplied) {
                        return (
                          <WSTableCell text={toWSMoneyString(data.amount)} />
                        );
                      }

                      const deductedAmount = data.application
                        .map(a => a.amountDeducted)
                        .reduce((a, b) => a + b, 0);

                      const remainingAmount = deductedAmount
                        ? data.amount - deductedAmount
                        : data.amount;

                      return (
                        <WSTableCell
                          text={`${toWSMoneyString(remainingAmount)} ${
                            deductedAmount ? "remaining" : ""
                          }`}
                        />
                      );
                    }
                  },
                  {
                    config: {
                      gridTemplateSizeMax: "0.2fr",
                      header: {
                        text: "Start date"
                      }
                    },
                    renderFunction: ({ data }) => (
                      <WSTableCell
                        text={toWSDateString(data.startDate, "mobileInput")}
                      />
                    )
                  },
                  {
                    config: {
                      gridTemplateSizeMax: "0.2fr",
                      header: {
                        text: "Type"
                      }
                    },
                    renderFunction: ({ data }) => (
                      <WSTableCell
                        pill={{
                          icon:
                            data.type === DeductionType.PostPayment
                              ? "after"
                              : "before",
                          text:
                            data.type === DeductionType.PostPayment
                              ? "Post-payment"
                              : "Pre-payment"
                        }}
                      />
                    )
                  }
                ]}
              />
            </WSPage>
          );
        }}
      </WSQueries>
    </>
  );
};
