import React from "react";
import { SetupRouteComponentProps } from "../../../../../shared/components/FlowSetup";
import { ImportRecipientsFlow } from "../../ImportRecipientsFlow";
import { useHistory } from "react-router-dom";
import { PayerSetupLayout } from "../../../components/PayerSetupLayout";

export const RouteSetupImportRecipients: React.FC<
  SetupRouteComponentProps & {
    parentPath?: string;
  }
> = ({ onBack, onNext, parentPath }) => {
  const history = useHistory();
  const goBack = () => {
    if (onBack) {
      onBack();
      return;
    }

    history.push("/member/1099-filing/set-up/select-import-recipients");
  };

  return (
    <ImportRecipientsFlow
      Layout={PayerSetupLayout}
      basePath="/member/1099-filing/set-up/recipients-import"
      parentPath={parentPath}
      onBack={goBack}
      onFinish={onNext}
    />
  );
};
