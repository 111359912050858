import { loadStripe, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import {
  WSActions,
  WSCard,
  WSList,
  WSStripeInput,
  WSStripeInputRef
} from "@wingspanhq/fe-component-library";
import { IPaymentMethod } from "@wingspanhq/users/dist/lib/interfaces";
import { useMemo, useRef, useState } from "react";
import { queryCache } from "react-query";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { useWSMutation } from "../../../query/helpers";
import { useUserId } from "../../../query/hooks/helpers";
import {
  QUERY_PAYOUT_SETTINGS,
  QUERY_PAYROLL_SETTINGS
} from "../../../query/payments/keys";
import { QUERY_USERS_CLIENT } from "../../../query/users/keys";
import { usersService } from "../../../services/users";
import {
  addElementToArray,
  WSServiceError
} from "../../../utils/serviceHelper";

type Props = {
  onBack?: () => void;
  onNext?: () => void;
  setPaymentMethod?: boolean;
};

export const FormAddCreditCard: React.FC<Props> = ({
  onBack,
  onNext,
  setPaymentMethod
}) => {
  const userId = useUserId();
  const [submit, meta] = useWSMutation<void, WSServiceError>(
    async ({ paymentMethodId }) => {
      if (!stripeInputState?.complete) {
        return;
      }

      let token: string | undefined;

      const result = await stripeInput?.current?.createPaymentMethod();
      if (result?.token) {
        token = result?.token.id;
      }

      if (!token) {
        return;
      }

      let client = await usersService.client.get(userId);

      client = await usersService.client.update(userId, {
        clientId: userId,
        profile: {
          savedPaymentMethods: addElementToArray<IPaymentMethod>(
            client.profile.savedPaymentMethods?.length || 0,
            { paymentMethodId: token }
          ),
          ...(setPaymentMethod
            ? {
                defaultPaymentMethod: {
                  accountId: null,
                  paymentMethodId
                }
              }
            : {})
        }
      });

      queryCache.setQueryData(QUERY_USERS_CLIENT, client);
    },
    {
      onSuccess: onNext,
      dependencies: [QUERY_PAYROLL_SETTINGS, QUERY_PAYOUT_SETTINGS]
    }
  );

  const stripeInput = useRef<WSStripeInputRef>(null);
  const [stripeComplete, setStripeComplete] = useState(false);
  const [stripeInputState, setStripeInputState] = useState<
    StripeCardElementChangeEvent | undefined
  >();
  const stripePromise = useMemo(
    () => loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`),
    []
  );

  return (
    <WSList gap="2XL">
      <WSCard
        header={{
          label: "Add credit card"
        }}
      >
        <WSStripeInput
          ref={stripeInput}
          stripeInstance={stripePromise}
          onInputChange={event => {
            setStripeInputState(event);
            setStripeComplete(event.complete);
          }}
          name="creditCard"
        />
      </WSCard>

      <WSErrorMessage contextKey="BankCard" error={meta.error} />

      <WSActions
        mb="2XL"
        alignment="fill"
        buttons={[
          {
            label: "Back",
            kind: "Secondary",
            onClick: onBack,
            visible: !!onBack
          },
          {
            label: "Continue",
            onClick: submit,
            loading: meta.isLoading
          }
        ]}
      />
    </WSList>
  );
};
