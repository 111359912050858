import {
  WSActions,
  WSAvatar,
  WSDivider,
  WSFlexBox,
  WSList,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import { usePayeeRowsSummaryQuery } from "../../../../query/search/payee/queries/usePayeeRowsSummaryQuery";
import { PayerPayeeEngagementSearchableStatus } from "../../../../services/search";
import { formatNumber } from "accounting";
import { useHistory } from "react-router-dom";

export const ContractorsInfo: React.FC<{}> = () => {
  const history = useHistory();
  const queryAllPayeeRowsSummary = usePayeeRowsSummaryQuery({
    filter: {
      searchableStatus: {
        "!=": PayerPayeeEngagementSearchableStatus.Inactive
      }
    }
  });
  const queryWithPaymentsPayeeRowsSummary = usePayeeRowsSummaryQuery({
    filter: {
      searchableStatus: PayerPayeeEngagementSearchableStatus.Eligible
    }
  });
  const queryWithoutPaymentsPayeeRowsSummary = usePayeeRowsSummaryQuery({
    filter: {
      searchableStatus: {
        in: [
          PayerPayeeEngagementSearchableStatus.NotEligible,
          PayerPayeeEngagementSearchableStatus.NotSignedUp
        ]
      }
    }
  });

  return (
    <WSPanel shimmer={queryAllPayeeRowsSummary.isLoading}>
      <WSList gap="L">
        <WSList.Horizontal gap="M">
          <WSAvatar icon="user" type="icon" />
          <WSText.Paragraph weight="medium" mt="XS">
            Contractors
          </WSText.Paragraph>
        </WSList.Horizontal>
        <WSFlexBox.CenterY justify="space-between">
          <WSText.ParagraphSm color="gray500">Contractors</WSText.ParagraphSm>
          <WSText.ParagraphSm>
            {formatNumber(queryAllPayeeRowsSummary.data?.listSize || 0)}
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
        <WSFlexBox.CenterY justify="space-between" pl="L">
          <WSText.ParagraphSm color="gray500">with payments</WSText.ParagraphSm>
          <WSText.ParagraphSm>
            {formatNumber(
              queryWithPaymentsPayeeRowsSummary.data?.listSize || 0
            )}
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
        <WSFlexBox.CenterY justify="space-between" pl="L">
          <WSText.ParagraphSm color="gray500">
            without payments
          </WSText.ParagraphSm>
          <WSText.ParagraphSm>
            {formatNumber(
              queryWithoutPaymentsPayeeRowsSummary.data?.listSize || 0
            )}
          </WSText.ParagraphSm>
        </WSFlexBox.CenterY>
        <WSDivider />

        <WSActions
          orientation="vertical"
          alignment="fill"
          buttons={[
            {
              label: "Mange contractors",
              onClick: () => {
                history.push("/member/payees");
              },
              rightIcon: "chevron-right",
              kind: "Secondary"
            },
            {
              label: "Manage payables",
              rightIcon: "chevron-right",
              onClick: () => {
                history.push("/member/invoices/payables/dashboard");
              },
              kind: "Secondary"
            }
          ]}
        />
      </WSList>
    </WSPanel>
  );
};
