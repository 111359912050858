import {
  concurrentActions,
  WSServiceError
} from "../../../utils/serviceHelper";
import { useWSMutation } from "../../helpers";
import { updateEngagementRequirement } from "../../../services/payeeEngagement/updateEngagementRequirement";
import {
  QUERY_PAYEE_ENGAGEMENT,
  QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
  QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE
} from "../../payeeEngagements/keys";
import { QUERY_PAYEE } from "../keys";
import { QUERY_PAYEE_ROWS } from "../../search/payee/queries/usePayeeRowsQuery";
import { QUERY_PAYEE_ROWS_SUMMARY } from "../../search/payee/queries/usePayeeRowsSummaryQuery";
import {
  IPayeeEngagementResponse,
  RequirementEmbeddedStatus
} from "@wingspanhq/payments/dist/interfaces";
import { queryCache } from "react-query";

export interface IChangeRequirementStatusRequest {
  payeeId: string;
  relatedEngagementRequirementIds: {
    engagementId: string;
    relatedRequirementId: string;
  }[];
  requirementId: string;
  request: { status: RequirementEmbeddedStatus | null };
}

export const useChangeRequirementStatuses = () => {
  return useWSMutation<
    IPayeeEngagementResponse[],
    WSServiceError,
    IChangeRequirementStatusRequest
  >(
    async ({
      payeeId,
      relatedEngagementRequirementIds,
      requirementId,
      request
    }) => {
      const actions = relatedEngagementRequirementIds.map(
        ({ engagementId, relatedRequirementId }) =>
          async () => {
            return await updateEngagementRequirement(
              payeeId,
              engagementId,
              relatedRequirementId,
              request
            );
          }
      );

      const results = await concurrentActions(actions);
      queryCache.removeQueries([QUERY_PAYEE_ENGAGEMENT]);
      return results;
    },
    {
      awaitDependencies: (_result, variables) => [
        QUERY_PAYEE_ENGAGEMENT,
        QUERY_PAYEE_ENGAGEMENTS_LIST_SIZE,
        QUERY_PAYEE_ENGAGEMENTS_LIST_ALL,
        [QUERY_PAYEE, variables.payeeId],
        QUERY_PAYEE_ROWS,
        QUERY_PAYEE_ROWS_SUMMARY
      ]
    }
  );
};
