import {
  WSButton,
  WSButtons,
  WSCheckboxToggle,
  WSDivider,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";

import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SetupStartNewCorrectionFlowProps } from ".";
import { usePayerTaxForm } from "../../../../../query/taxForm/queries/usePayerTaxForm";
import { useCancelCorrectionModal } from "../../../../TaxDocuments/components/CorrectionFormActions";
import { EditLayout } from "../../../components/EditLayout";
import { PreventLeave } from "../../../../../components/PreventLeave/PreventLeave";
import { WSQueries } from "../../../../../query/WSQuery";
import {
  CORRECTION_ADDRESS,
  CORRECTION_AMOUNT,
  CORRECTION_TIN_OR_NAME
} from "../../../../TaxDocuments/constants/corrections";
import { selectorTaxFormRecipientName } from "../../../selectors/selectorTaxFormRecipientName";
import { AmountCorrectionDiffPreview } from "../../../../TaxDocuments/components/AmountCorrectionDiffPreview";
import { TINOrNameCorrectionDiffPreview } from "../../../../TaxDocuments/components/TINOrNameCorrectionDiffPreview";
import { AddressCorrectionDiffPreview } from "../../../../TaxDocuments/components/AddressCorrectionDiffPreview";
import { CURRENT_YEAR } from "../../../constants/currentYear";
import { TextBulleted } from "../../../../../shared/components/TextBulleted";
import { selectorPayeeTaxFormPayerCompanyName } from "../../../selectors/selectorPayeeTaxFormPayerCompanyName";
import { getCorrectionType } from "../../../../TaxDocuments/utils/getCorrectionType";
import {
  getDefaultValuesForCorrectionsForm,
  getPayeeFormW9Address,
  getPayeeTINorName
} from "../../../../TaxDocuments/routes/RequestCorrectionFlow/getDefaultValuesForCorrectionsForm";
import { CompanyStructure } from "@wingspanhq/users/dist/lib/interfaces/company";
import { FormRequestCorrectionData } from "../../../../TaxDocuments/routes/RequestCorrectionFlow/types";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../../services/platform";
import { TaxFormViewer } from "../../../../TaxDocuments/types";
import { UnchangedRecipientDetails } from "./UnchangedRecipientDetails";
import { IPayerTaxFormResponse } from "@wingspanhq/payments/dist/interfaces";
import uniq from "lodash/uniq";
import styles from "./styles.module.scss";
import { createRequestCorrectionPayload } from "../../../../TaxDocuments/routes/RequestCorrectionFlow/createRequestCorrectionPayload";
import { useTaxFormRequestCorrection } from "../../../../../query/taxForm/mutations/useTaxFormRequestCorrection";

export const RouteReviewAndSubmitCorrection: React.FC<
  SetupStartNewCorrectionFlowProps
> = ({ onNext, onBack }) => {
  const [consent, setConsent] = useState(false);

  const history = useHistory<{
    corrections: string[];
    correctionsFormData: any;
    isForce: boolean;
  }>();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;
  const queryTaxForm = usePayerTaxForm(taxFormId);
  const [requestCorrection, requestCorrectionMeta] =
    useTaxFormRequestCorrection();

  const corrections = uniq(history.location.state?.corrections ?? []);
  const correctionsFormData =
    (history.location.state
      ?.correctionsFormData as FormRequestCorrectionData) ?? null;
  const isForce = !!history.location.state?.isForce;

  const cancelCorrectionModal = useCancelCorrectionModal();

  const onEdit = () => {
    onNext({
      isEdit: true,
      corrections,
      correctionsFormData
    });
  };

  const onEditUnchangedDetails = () => {
    const correctionTypeData = getCorrectionType(
      queryTaxForm.data as IPayerTaxFormResponse,
      correctionsFormData
    );

    const newCorrectionTypes = [
      // applied corrections list after comparing corrections data with original data
      ...correctionTypeData.corrections,
      // unchanged recipient details corrections list
      ...(!correctionTypeData.corrections.includes(CORRECTION_TIN_OR_NAME)
        ? [CORRECTION_TIN_OR_NAME]
        : []),
      ...(!correctionTypeData.corrections.includes(CORRECTION_ADDRESS)
        ? [CORRECTION_ADDRESS]
        : [])
    ];

    onNext({
      isEdit: true,
      corrections: newCorrectionTypes,
      correctionsFormData: {
        ...getDefaultValuesForCorrectionsForm(
          newCorrectionTypes,
          queryTaxForm.data as IPayerTaxFormResponse
        ),
        ...correctionsFormData
      }
    });
  };

  const onCancel = () => {
    history.push(`/member/1099-filing/details/${taxFormId}`);
  };

  const onSubmitCorrection = async () => {
    await requestCorrection(
      {
        taxFormId,
        requestData: createRequestCorrectionPayload(
          correctionsFormData,
          queryTaxForm?.data as IPayerTaxFormResponse,
          TaxFormViewer.Payer,
          isForce
        )
      },
      {
        onSuccess: res => {
          onNext();
        }
      }
    );
  };

  const onUpdateRecipientInfo = () => {
    // BE will update the recipient info without creating any creation
    onSubmitCorrection();
  };

  const onCancelCorrection = () => {
    cancelCorrectionModal.open({ onCancel });
  };

  return (
    <EditLayout title="Submit correction to the IRS">
      <PreventLeave isEnabled={true} />
      <WSText.Heading2 mb="S">Review and submit correction</WSText.Heading2>
      <WSText.ParagraphSm color="gray500">
        Please review the corrections thoroughly before submitting a correction
        to the IRS.
      </WSText.ParagraphSm>

      <WSDivider my="XL" />

      <WSQueries
        queries={{
          queryTaxForm
        }}
      >
        {({ queryTaxFormData: taxForm }) => {
          const recipientName = selectorTaxFormRecipientName(taxForm);
          const { corrections, addressStateNotChanged, isVoidType } =
            getCorrectionType(taxForm, correctionsFormData);
          const hasUnchangedDetails =
            !corrections.includes(CORRECTION_TIN_OR_NAME) ||
            !corrections.includes(CORRECTION_ADDRESS);

          const isType1Correction =
            corrections.length === 1 && corrections.includes(CORRECTION_AMOUNT);

          const isOnlyAddressCorrectionAndStateNotChanged =
            corrections.length === 1 &&
            corrections.includes(CORRECTION_ADDRESS) &&
            addressStateNotChanged;

          const correctionTypeToPreviewComponentMap: Record<
            string,
            JSX.Element
          > = {
            [CORRECTION_AMOUNT]: (
              <AmountCorrectionDiffPreview
                taxForm={taxForm}
                onEdit={onEdit}
                taxFormViewer={TaxFormViewer.Payer}
                amount={taxForm?.data?.totalAmount ?? 0}
                correctedAmount={correctionsFormData.necTotalAmount}
                comment={correctionsFormData.amountCorrectionHelpText}
              />
            ),
            [CORRECTION_TIN_OR_NAME]: (
              <TINOrNameCorrectionDiffPreview
                isVoidType={isVoidType}
                isForce={isForce}
                showRejectOptionText={true}
                taxForm={taxForm}
                identificationNumberType={
                  correctionsFormData.identificationNumberType
                }
                onEdit={onEdit}
                taxFormViewer={TaxFormViewer.Payer}
                originalTinData={getPayeeTINorName(taxForm)}
                correctedTinData={{
                  ssn: correctionsFormData.ssn,
                  ein: correctionsFormData.ein,
                  businessName: correctionsFormData.businessName,
                  firstName: correctionsFormData.firstName,
                  lastName: correctionsFormData.lastName,
                  disregardedEntityName:
                    correctionsFormData.disregardedEntityName,
                  taxClassification:
                    correctionsFormData.taxClassification as CompanyStructure
                }}
                reasonForChange={correctionsFormData.reasonForChange}
                otherReason={correctionsFormData.otherReason}
              />
            ),
            [CORRECTION_ADDRESS]: (
              <>
                <AddressCorrectionDiffPreview
                  taxForm={taxForm}
                  onEdit={onEdit}
                  taxFormViewer={TaxFormViewer.Payer}
                  originalAddress={getPayeeFormW9Address(taxForm)}
                  correctedAddress={correctionsFormData.formW9Address}
                />
                {isOnlyAddressCorrectionAndStateNotChanged ? (
                  <WSText.ParagraphSm my="S" color="gray500">
                    The address change does not require a correction to be filed
                    with the IRS because the filing State did not change.
                    Confirm and update the recipient information or edit to make
                    other corrections.
                  </WSText.ParagraphSm>
                ) : null}
              </>
            )
          };

          return (
            <>
              <WSText.Heading2 mb="L">
                {recipientName} {CURRENT_YEAR} Form 1099-NEC
              </WSText.Heading2>

              {corrections.map(correctionType => {
                const DiffPreviewComponent =
                  correctionTypeToPreviewComponentMap[correctionType];
                return (
                  <WSElement mb="L" key={correctionType}>
                    {DiffPreviewComponent}
                    <WSDivider my="XL" />
                  </WSElement>
                );
              })}

              {hasUnchangedDetails ? (
                <UnchangedRecipientDetails
                  onEdit={onEditUnchangedDetails}
                  taxForm={taxForm}
                  corrections={corrections}
                />
              ) : null}
              {!isOnlyAddressCorrectionAndStateNotChanged ? (
                <WSMessageBox.Info mb="L">
                  <WSText.ParagraphSm weight="medium" mb="S" color="gray600">
                    Are you ready to submit the correction?
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm mb="S" color="gray500">
                    If you click submit, this correction will be sent to the
                    IRS. You cannot undo this action. If needed, another
                    correction can be filed after the IRS accepts this one.
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm mb="S" weight="medium" color="gray600">
                    What to expect:
                  </WSText.ParagraphSm>
                  <TextBulleted
                    color="gray500"
                    className={styles.higherPointsList}
                  >
                    {isType1Correction ? (
                      <li>
                        One (1) updated form will be filed to the IRS based on
                        this correction
                      </li>
                    ) : (
                      <li>
                        Two (2) updated forms will be filed to the IRS based on
                        these corrections
                        <TextBulleted
                          color="gray500"
                          as="ol"
                          className={styles.lowerAlphaList}
                        >
                          <li>
                            First (1st) updated form to zero out the incorrect
                            form
                          </li>
                          <li>
                            Second (2nd) updated form with new recipient info
                          </li>
                        </TextBulleted>
                      </li>
                    )}
                    <li>
                      The updated {isType1Correction ? "1099" : "1099s"}, once
                      submitted, will be delivered immediately to the recipient
                      by mail or electronically based on their preference
                    </li>
                  </TextBulleted>
                </WSMessageBox.Info>
              ) : null}

              {!isOnlyAddressCorrectionAndStateNotChanged ? (
                <WSCheckboxToggle
                  mb="L"
                  name="consent"
                  value={consent}
                  onChange={setConsent}
                  label={`Under penalties of perjury, I declare that to the best of my knowledge. ${selectorPayeeTaxFormPayerCompanyName(
                    taxForm
                  )} attempted to obtain the payee's U.S. TIN and other identifying information under IRS Publication 1281. As a result, the information contained in the taxpayer's electronic forms 1099 file is, to the best of my knowledge and belief, true, correct and complete.`}
                />
              ) : null}

              <WSErrorMessage
                mb="M"
                contextKey={ErrorContextKey.NEC1099PayerCorrection}
                error={requestCorrectionMeta.error}
              />

              <WSButtons forceFullWidth mb="L">
                {isOnlyAddressCorrectionAndStateNotChanged ? (
                  <WSButton.Primary onClick={onUpdateRecipientInfo}>
                    Update recipient information
                  </WSButton.Primary>
                ) : (
                  <WSButton.Primary
                    disabled={!consent}
                    onClick={onSubmitCorrection}
                    loading={requestCorrectionMeta.isLoading}
                  >
                    Submit correction to the IRS
                  </WSButton.Primary>
                )}
                <WSButton.Secondary destructive onClick={onCancelCorrection}>
                  Cancel
                </WSButton.Secondary>
              </WSButtons>
            </>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
