import { WSElementColorsType } from "@wingspanhq/fe-component-library";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces/bulkUpload";

export function getBatchDetailsLabelValueColors(bulkBatchStatus?: BulkStatus): {
  labelColor: WSElementColorsType;
  valueColor: WSElementColorsType;
} {
  return [BulkStatus.Open, BulkStatus.Pending, BulkStatus.Processing].includes(
    bulkBatchStatus as BulkStatus
  )
    ? { labelColor: "gray400", valueColor: "gray500" }
    : { labelColor: "gray500", valueColor: "gray700" };
}
