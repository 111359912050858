import { formatReportsDate } from "../../utils/formatReportsDate";
import { ReportsColumn } from "../../types";
import { ICollaboratorsPayoutsSummaryReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatReportMoney } from "../../utils/formatReportMoney";

export const COLUMNS: ReportsColumn<ICollaboratorsPayoutsSummaryReportResponse>[] =
  [
    {
      label: "Contractor Member ID",
      value: "memberId",
      getTableCell(data) {
        return data.memberId;
      }
    },
    {
      label: "First Name",
      value: "member.user.profile.firstName",
      preselected: true,
      getTableCell(data) {
        return data.member.user.profile?.firstName;
      }
    },
    {
      label: "Last Name",
      preselected: true,
      value: "member.user.profile.lastName",
      getTableCell(data) {
        return data.member.user.profile?.lastName;
      }
    },
    {
      label: "Preferred Name",
      value: "member.user.profile.preferredName",
      getTableCell(data) {
        return (data.member.user.profile as any)?.preferredName;
      }
    },
    {
      label: "Contractor Company",
      value: "member.profile.company.legalBusinessName",
      getTableCell(data) {
        return (
          data.member.profile?.company?.legalBusinessName ||
          data.member.profile?.company?.name
        );
      }
    },
    {
      label: "Email",
      value: "member.user.email",
      preselected: true,
      getTableCell(data) {
        return data.member.user.email;
      }
    },
    {
      label: "Invite Email",
      value: "inviteEmail",
      getTableCell(data) {
        return data.inviteEmail;
      }
    },
    {
      label: "Status",
      value: "status",
      preselected: true,
      getTableCell(data) {
        return data.status;
      }
    },
    {
      label: "External ID",
      value: "externalId",
      getTableCell(data) {
        return data.externalId;
      }
    },
    {
      label: "SSN",
      value: "ssn",
      getTableCell(data) {
        return data.taxIdentificationInfo?.ssn;
      }
    },
    {
      label: "EIN",
      value: "ein",
      getTableCell(data) {
        return data.taxIdentificationInfo?.ein;
      }
    },
    {
      label: "Created At",
      value: "createdAt",
      getTableCell(data) {
        return formatReportsDate(data.createdAt);
      }
    },
    {
      label: "Updated At",
      value: "updatedAt",
      getTableCell(data) {
        return formatReportsDate(data.updatedAt);
      }
    },
    {
      label: "Signed Up Date",
      value: "memberEvents.signedUpAt",
      getTableCell(data) {
        return formatReportsDate(data.memberEvents?.signedUpAt);
      }
    },
    {
      label: "Payout Method Added Date",
      value: "memberEvents.payoutMethodFirstAddedAt",
      getTableCell(data) {
        return formatReportsDate(data.memberEvents?.payoutMethodFirstAddedAt);
      }
    },
    {
      label: "Banking Enabled Date",
      value: "memberEvents.knowYourCustomerVerifiedAt",
      getTableCell(data) {
        return formatReportsDate(data.memberEvents?.knowYourCustomerVerifiedAt);
      }
    },
    {
      label: "Tax Documentation Verified Date",
      value: "memberEvents.taxDocumentationVerifiedAt",
      getTableCell(data) {
        return formatReportsDate(data.memberEvents?.taxDocumentationVerifiedAt);
      }
    },
    {
      label: "Tax Status",
      value: "taxStatus",
      getTableCell(data) {
        return data.taxStatus;
      }
    },

    {
      label: "Company Structure",
      value: "member.profile.company.structure",
      getTableCell(data) {
        return data.member.profile?.company?.structure;
      }
    },
    {
      label: "Address Line 1",
      value: "member.profile.address.addressLine1",
      group: "Address fields",
      getTableCell(data) {
        return data.member.profile?.address?.addressLine1;
      }
    },
    {
      label: "Address Line 2",
      value: "member.profile.address.addressLine2",
      group: "Address fields",
      getTableCell(data) {
        return data.member.profile?.address?.addressLine2;
      }
    },
    {
      label: "City",
      value: "member.profile.address.city",
      group: "Address fields",
      getTableCell(data) {
        return data.member.profile?.address?.city;
      }
    },
    {
      label: "State",
      value: "member.profile.address.state",
      group: "Address fields",
      getTableCell(data) {
        return data.member.profile?.address?.state;
      }
    },
    {
      label: "Postal Code",
      value: "member.profile.address.postalCode",
      group: "Address fields",
      getTableCell(data) {
        return data.member.profile?.address?.postalCode;
      }
    },
    {
      label: "Country",
      value: "member.profile.address.country",
      group: "Address fields",
      getTableCell(data) {
        return data.member.profile?.address?.country;
      }
    },
    {
      label: "Group Names",
      value: "collaboratorGroupNames",
      preselected: true,
      getTableCell(data) {
        return data.collaboratorGroups.map(group => group.name).join(", ");
      }
    },
    {
      label: "Total Paid",
      value: "totalPaid",
      group: "Payable fields",
      preselected: true,
      getTableCell(data) {
        return formatReportMoney(data.payoutsSummary.totalPaid);
      }
    },
    {
      label: "Total Deducted",
      value: "totalDeducted",
      group: "Payable fields",
      preselected: true,
      getTableCell(data) {
        return formatReportMoney(data.payoutsSummary.totalDeducted);
      }
    },
    {
      label: "Payables Count",
      value: "payablesCount",
      group: "Payable fields",
      preselected: true,
      getTableCell(data) {
        return data.payoutsSummary.payablesCount;
      }
    },
    {
      label: "Deduction Count",
      value: "deductionsCount",
      group: "Payable fields",
      preselected: true,
      getTableCell(data) {
        return data.payoutsSummary.deductionsCount;
      }
    },
    {
      label: "First Paid Date",
      value: "firstPaidDate",
      group: "Payable fields",
      preselected: true,
      getTableCell(data) {
        return formatReportsDate(data.payoutsSummary.firstPaidDate);
      }
    },
    {
      label: "Last Paid Date",
      value: "lastPaidDate",
      group: "Payable fields",
      preselected: true,
      getTableCell(data) {
        return formatReportsDate(data.payoutsSummary.lastPaidDate);
      }
    },
    {
      label: "Income & Expenses Enabled",
      value: "servicesStatus.incomeAndExpenses",
      getTableCell(data) {
        return data.servicesStatus.incomeAndExpenses ? "Yes" : "No";
      }
    },
    {
      label: "Tax Withholding Enabled",
      value: "servicesStatus.taxWithholdings",
      getTableCell(data) {
        return data.servicesStatus.taxWithholdings ? "Yes" : "No";
      }
    }
  ];
