import React from "react";
import {
  useWSModal,
  WSButton,
  WSDivider,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { useTaxFormResendEmail } from "../../../../../query/taxForm/mutations/useTaxFormResendEmail";
import { TaxFormDeliveryMethod } from "@wingspanhq/payments/dist/interfaces/taxForm";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";

const ResendElectronicCopy: React.FC<{
  taxForm: IPayeeTaxFormResponse;
  onClose(result: boolean): void;
}> = ({ onClose, taxForm }) => {
  const [resendElectronicCopy, resendElectronicCopyMeta] =
    useTaxFormResendEmail();

  return (
    <WSElement mt="XS">
      <WSText color="gray600">
        Please confirm that you want to re-send an electronic copy of the
        recipient’s 1099-NEC tax forms to the following email:
      </WSText>

      <WSText weight="medium" mt="XL">
        Email
      </WSText>
      <WSDivider my="M" />
      <WSText.ParagraphSm color="gray500">
        {taxForm.payee?.user.email}
      </WSText.ParagraphSm>

      <WSErrorMessage
        mt="M"
        error={resendElectronicCopyMeta.error}
        contextKey="Other"
      />

      <WSButton.Primary
        mt="XL"
        fullWidth
        loading={resendElectronicCopyMeta.isLoading}
        onClick={() => {
          resendElectronicCopy(
            {
              taxFormId: taxForm.taxFormId,
              deliveryMethod: TaxFormDeliveryMethod.Electronic
            },
            {
              onSuccess() {
                onClose?.(true);
              }
            }
          );
        }}
      >
        Confirm and send
      </WSButton.Primary>
    </WSElement>
  );
};

export const useModalResendElectronicCopy = () =>
  useWSModal(ResendElectronicCopy, {
    title: "Re-send electronic copy",
    size: "S"
  });
