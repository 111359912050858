import {
  useIsDesktop,
  WSButton,
  WSAvatar,
  WSElement,
  WSFlexBox,
  WSMessageBox,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { EditLayout } from "../../../1099NECFiling/components/EditLayout";
import { SetupRequestCorrectionFlowProps } from "./index";
import { useHistory, useRouteMatch } from "react-router-dom";
import { openIntercom } from "../../../../shared/utils/intercom";
import { FAQ1099 } from "../../../1099NECFiling/components/FAQ1099";
import styles from "./styles.module.scss";
import { usePayeeTaxForm } from "../../../../query/taxForm/queries/usePayeeTaxForm";
import { WSQueries } from "../../../../query/WSQuery";
import { getCorrectionType } from "../../utils/getCorrectionType";
import { FormRequestCorrectionData } from "./types";
import { TAX_DOCUMENTS_PATH } from "../..";
import {
  CORRECTION_ADDRESS,
  CORRECTION_TIN_OR_NAME
} from "../../constants/corrections";
import { AxiosError } from "axios";
import { createRequestCorrectionPayload } from "./createRequestCorrectionPayload";
import { IPayeeTaxFormResponse } from "@wingspanhq/payments/dist/interfaces/api/taxForm";
import { useTaxFormRequestCorrection } from "../../../../query/taxForm/mutations/useTaxFormRequestCorrection";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { ProfileUpdateSummary } from "../../components/ProfileUpdateSummary";
import { selectorPayeeTaxFormSupportContacts } from "../../../1099NECFiling/selectors/selectorPayeeTaxFormSupportContacts";

export const RouteRequestCorrectionStatus: React.FC<
  SetupRequestCorrectionFlowProps
> = ({ onNext }) => {
  const history = useHistory<{
    isCorrectionRequestFailed: boolean;
    canTryAgain: boolean;
    corrections: string[];
    correctionsFormData: any;
  }>();
  const match = useRouteMatch<{ taxFormId: string }>();
  const { taxFormId } = match.params;

  const isCorrectionRequestFailed =
    history.location.state?.isCorrectionRequestFailed;
  const canTryAgain = history.location.state?.canTryAgain;
  const correctionsFormData =
    (history.location.state
      ?.correctionsFormData as FormRequestCorrectionData) ?? null;

  const queryTaxForm = usePayeeTaxForm(taxFormId);
  const [requestCorrection, requestCorrectionMeta] =
    useTaxFormRequestCorrection();

  const isDesktop = useIsDesktop();
  const faqsStyles = isDesktop
    ? {
        className: styles.separator
      }
    : {};

  const gotoTaxDocumentsPage = () => {
    history.push(TAX_DOCUMENTS_PATH);
  };

  const tryAgainCorrectionRequest = async () => {
    await requestCorrection({
      taxFormId,
      requestData: createRequestCorrectionPayload(
        correctionsFormData,
        queryTaxForm?.data as IPayeeTaxFormResponse
      )
    });
  };
  return (
    <EditLayout
      noBack
      title="Request correction"
      contentRight={<FAQ1099 mb="2XL" {...faqsStyles} />}
    >
      <WSQueries queries={{ queryTaxForm }}>
        {({ queryTaxFormData: taxForm }) => {
          const correctionTypeData = getCorrectionType(
            taxForm,
            correctionsFormData
          );
          const isAddressStateNotChanged =
            correctionTypeData.corrections.length === 1 &&
            correctionTypeData.corrections.includes(CORRECTION_ADDRESS) &&
            correctionTypeData.addressStateNotChanged;
          const { supportEmail } = selectorPayeeTaxFormSupportContacts(taxForm);

          return (
            <WSElement mr={isDesktop ? "3XL" : "NONE"}>
              {isCorrectionRequestFailed || requestCorrectionMeta.isError ? (
                <WSPanel mb="2XL">
                  <WSMessageBox.Error
                    mb="2XL"
                    title="There was an error submitting the correction request"
                  >
                    Please{" "}
                    <WSButton.Link onClick={openIntercom}>
                      contact support
                    </WSButton.Link>{" "}
                    and note down the corrections you were attempting to make.
                  </WSMessageBox.Error>

                  <WSErrorMessage
                    mb="XL"
                    contextKey={ErrorContextKey.NEC1099RequestCorrection}
                    error={requestCorrectionMeta.error}
                  />

                  {canTryAgain ? (
                    <WSButton.Primary
                      mb="M"
                      fullWidth
                      loading={requestCorrectionMeta.isLoading}
                      onClick={tryAgainCorrectionRequest}
                    >
                      Try again
                    </WSButton.Primary>
                  ) : null}
                  <WSButton.Tertiary fullWidth onClick={gotoTaxDocumentsPage}>
                    Back to dashboard
                  </WSButton.Tertiary>
                </WSPanel>
              ) : (
                <WSPanel mb="2XL">
                  <WSFlexBox.Center direction="column" mb="XL">
                    <WSAvatar.Icon
                      icon="check"
                      size="M"
                      color="white"
                      colorBackground="green500"
                      mb="M"
                    />
                    <WSText.Heading4>
                      {isAddressStateNotChanged
                        ? "You’re all set"
                        : "Correction request successfully submitted"}
                    </WSText.Heading4>
                  </WSFlexBox.Center>

                  <WSText.ParagraphSm color="gray500" mb="XL">
                    {isAddressStateNotChanged
                      ? `The requested address change does not require a correction to the IRS for filing purposes. We’ve updated the address on your profile and new correction request was sent to the payer.`
                      : `Your correction request has been submitted to payer. They will review the request and it is their discretion as to whether to file a correction.`}
                  </WSText.ParagraphSm>
                  <WSText.ParagraphSm color="gray500" mb="XL">
                    {isAddressStateNotChanged ? (
                      `Please contact the payer if you would like to have the tax forms re-mailed to your new address.`
                    ) : (
                      <>
                        <WSText.ParagraphSm
                          as="span"
                          weight="medium"
                          inline
                          color="gray500"
                        >
                          Starting February 12th, 2024,
                        </WSText.ParagraphSm>{" "}
                        you will be notified when the payer accepts or rejects
                        your correction request.
                      </>
                    )}
                  </WSText.ParagraphSm>

                  <WSButton.Primary fullWidth onClick={gotoTaxDocumentsPage}>
                    Go to Tax Documents
                  </WSButton.Primary>
                </WSPanel>
              )}

              {correctionTypeData.corrections.includes(
                CORRECTION_TIN_OR_NAME
              ) ||
              correctionTypeData.corrections.includes(CORRECTION_ADDRESS) ? (
                <ProfileUpdateSummary
                  mb="2XL"
                  taxForm={taxForm}
                  correctionsFormData={correctionsFormData}
                />
              ) : null}

              <WSElement mb="XL">
                <WSText weight="medium" mb="XS">
                  Have questions?
                </WSText>
                <WSButton.Link
                  onClick={() => window.open(`mailto:${supportEmail}`)}
                >
                  {supportEmail}
                </WSButton.Link>
              </WSElement>

              <WSElement mb="XL">
                <WSText weight="medium" mb="XS">
                  Need additional support?
                </WSText>
                <WSButton.Link onClick={() => openIntercom()}>
                  Chat with a live representative
                </WSButton.Link>
              </WSElement>
            </WSElement>
          );
        }}
      </WSQueries>
    </EditLayout>
  );
};
