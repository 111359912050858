import {
  ITransaction,
  ITransactionGroup,
  TransactionType,
  WSCategory
} from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { ITaxEstimate } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import {
  WSAvatarIconProps,
  WSIconName
} from "@wingspanhq/fe-component-library";
import { WSDateRangeSelectRangeValue } from "@wingspanhq/fe-component-library/dist/lib/components/common/WSDateRangeSelect/WSDateRangeSelect.component";
import { WSTextProps } from "@wingspanhq/fe-component-library/dist/lib/components/core/WSText/WSText.component";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces/account";
import chunk from "lodash/chunk";
import flatten from "lodash/flatten";
import sum from "lodash/sum";
import sumBy from "lodash/sumBy";
import { useUpdateTransaction } from "../query/bookkeeping/mutations";
import {
  getAllCatchUpFilterGroups,
  getCatchUpFilterGroups
} from "../query/bookkeeping/selectors";
import { IPendingTransactionResponse } from "@wingspanhq/bookkeeping/dist/lib/interfaces/pendingTransaction";
import { WSAvatarWingspanProps } from "@wingspanhq/fe-component-library/dist/lib/components/common/WSAvatar/WSAvatar.component";

export const MOST_COMMON_CATEGORIES = [
  WSCategory.Meal,
  WSCategory.Supplies,
  WSCategory.Software,
  WSCategory.LegalProfessionalService,
  WSCategory.TravelTransport
];

export const OTHER_CATEGORIES = Object.values(WSCategory).filter(
  category => !MOST_COMMON_CATEGORIES.includes(category)
);

export const TRANSACTION_CATEGORIES = [
  ...MOST_COMMON_CATEGORIES,
  ...OTHER_CATEGORIES
];

const USDFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});
const formatter: Intl.NumberFormat = USDFormat;

export const getAmountValueAndFormat: (
  transaction: ITransaction | IPendingTransactionResponse
) => Partial<WSTextProps> = transaction => {
  const amount = formatter.format(Math.abs(transaction.amount));
  const baseFormat = { tabularNums: true };

  return {
    ...baseFormat,
    children: `${
      transaction.amount > 0 ? "-" : transaction.amount < 0 ? "+" : ""
    }${amount}`,
    color: "gray700"
  };
};

export const getEditAmount: (
  amount: number,
  transactionType: TransactionType
) => number = (amount, transactionType) => {
  if (transactionType === TransactionType.Transfer) {
    return amount;
  } else {
    return Math.abs(amount);
  }
};

export const getSignedAmountBasedOnData = (
  amount: number,
  type: TransactionType,
  isRefund: boolean
) => {
  let signedAmount = amount;
  if (
    (!isRefund && type === TransactionType.Income) ||
    (isRefund && type === TransactionType.Expense)
  ) {
    signedAmount = -signedAmount;
  }
  return signedAmount;
};

export const getIsRefundBasedOnData = (
  amount: number,
  type: TransactionType
) => {
  let isRefund = false;
  if (
    (type === TransactionType.Income && amount > 0) ||
    (type === TransactionType.Expense && amount < 0)
  ) {
    isRefund = true;
  }
  return isRefund;
};

export const getTaxSavingsForTransaction = (
  transaction: ITransaction,
  taxEstimate: ITaxEstimate
) => {
  const amount = Math.abs(transaction.amount);
  const deductibleAmount = Math.abs(transaction.deductibleAmount || 0);
  const expense = amount;
  const expenseMultiplier = (deductibleAmount / amount) * 100; // E.g. 100%
  const deduction = deductibleAmount;
  const estimatedTaxRate = taxEstimate.taxRates.effective || 0;
  const totalSavings = (estimatedTaxRate / 100) * deduction; // Round to two decimal places (e.g. $10.00)
  return {
    expense,
    expenseMultiplier,
    deduction,
    estimatedTaxRate,
    totalSavings
  };
};

export const CURRENT_YEAR = new Date().getFullYear();
export const PREV_YEAR = new Date().getFullYear() - 1;

export const DATE_CURRENT_YEAR = new Date(CURRENT_YEAR, 0, 0);
export const DATE_PREV_YEAR = new Date(PREV_YEAR, 0, 0);

export const DATE_TODAY_SOD = (() =>
  new Date(new Date().setHours(0, 0, 0, 0)))();
export const DATE_TODAY_EOD = (() =>
  new Date(new Date().setHours(23, 59, 59, 999)))();

export const DATE_RANGE_PAST_WEEK = (() => {
  return [
    new Date(
      DATE_TODAY_SOD.getFullYear(),
      DATE_TODAY_SOD.getMonth(),
      DATE_TODAY_SOD.getDate() - 7
    ),
    DATE_TODAY_EOD
  ];
})();

export const DATE_RANGE_PAST_MONTH = (() => {
  return [
    new Date(
      DATE_TODAY_SOD.getFullYear(),
      DATE_TODAY_SOD.getMonth() - 1,
      DATE_TODAY_SOD.getDate()
    ),
    DATE_TODAY_EOD
  ];
})();

export const DATE_RANGE_PAST_6_MONTHS = (() => {
  return [
    new Date(
      DATE_TODAY_SOD.getFullYear(),
      DATE_TODAY_SOD.getMonth() - 6,
      DATE_TODAY_SOD.getDate()
    ),
    DATE_TODAY_EOD
  ];
})();

export const DATE_RANGE_PAST_12_MONTHS = (() => {
  return [
    new Date(
      DATE_TODAY_SOD.getFullYear(),
      DATE_TODAY_SOD.getMonth() - 12,
      DATE_TODAY_SOD.getDate()
    ),
    DATE_TODAY_EOD
  ];
})();

export const DATE_RANGE_LAST_YEAR = (() => {
  const lastYear = DATE_TODAY_SOD.getFullYear() - 1;
  return [
    new Date(lastYear, 0, 1, 0, 0, 0, 0),
    new Date(lastYear, 11, 31, 23, 59, 59, 999)
  ];
})();

export const DATE_RANGE_THIS_YEAR = (() => {
  const thisYear = DATE_TODAY_SOD.getFullYear();
  return [
    new Date(thisYear, 0, 1, 0, 0, 0, 0),

    new Date(thisYear, 11, 31, 23, 59, 59, 999)
  ];
})();
export const getDateEOD = (date: Date) => {
  date.setHours(23, 59, 59, 999);
  return date;
};

export const getDateSOD = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const DEFAULT_FILTER_RANGE = (() => {
  const now = DATE_TODAY_SOD;
  const thisYear = DATE_TODAY_SOD.getFullYear();

  if (now < new Date(thisYear, 3, 15, 0, 0, 0, 0)) {
    return DATE_RANGE_LAST_YEAR;
  } else {
    return DATE_RANGE_THIS_YEAR;
  }
})();

export const totalTransactionValues = (
  type: TransactionType | null,
  ...groups: ITransactionGroup[]
) => {
  return sum(
    groups.map(group =>
      sumBy(
        group.transactions.filter(
          t => t.business && (type ? t.type === type : true)
        ),
        "amount"
      )
    )
  );
};

export const updateTransactions = async (
  group: ITransactionGroup[] = [],
  old: ITransaction[] = [],
  transactionType: TransactionType,
  updateTransaction: ReturnType<typeof useUpdateTransaction>[0],
  wsCategory?: WSCategory
) => {
  const transactions = flatten(group.map(g => g.transactions)).filter(t => {
    const oldTransaction = old.find(
      ({ transactionId }) => transactionId === t.transactionId
    );

    return (
      t.business !== oldTransaction?.business ||
      (t.business && oldTransaction?.type !== transactionType)
    );
  });

  await Promise.all(
    transactions.map(
      async transaction =>
        await updateTransaction({
          type: transactionType,
          transactionId: transaction.transactionId,
          business: transaction.business,
          ...(wsCategory ? { wsCategory } : null)
        })
    )
  );
};

export const getCatchUpPagesFromGroups = (
  groupA: ITransactionGroup[] = [],
  groupB: ITransactionGroup[] = [],
  reviewedAt?: Date
) => [
  ...chunk(
    reviewedAt
      ? getCatchUpFilterGroups(groupA, reviewedAt)
      : getAllCatchUpFilterGroups(groupA),
    5
  ),
  ...chunk(
    reviewedAt
      ? getCatchUpFilterGroups(groupB, reviewedAt)
      : getAllCatchUpFilterGroups(groupB),
    5
  )
];

export const getCatchUpTransactionsFromGroups = (
  groupA: ITransactionGroup[] = [],
  groupB: ITransactionGroup[] = []
) => [
  ...flatten(groupA.map(v => v.transactions)),
  ...flatten(groupB.map(v => v.transactions))
];

export const getAccountSubName = (account: IAccount): string => {
  const { subType, mask, name } = account;
  let formattedSubType;
  if (subType) {
    formattedSubType = subType.replace(/([A-Z])/g, " $1").trim();
  }

  if (formattedSubType && mask) {
    return `${formattedSubType} (${mask})`;
  }
  if (formattedSubType) {
    return formattedSubType;
  }
  return name;
};

export const CATEGORY_ICONS: { [key in WSCategory]: WSIconName } = {
  [WSCategory.AdvertisingMarketing]: "details",
  [WSCategory.BankFee]: "dollar-circle",
  [WSCategory.BusinessInsurance]: "briefcase",
  [WSCategory.CarExpenses]: "car",
  [WSCategory.ContractorPayment]: "card",
  [WSCategory.Commission]: "dollar-circle",
  [WSCategory.Entertainment]: "smile",
  [WSCategory.Meal]: "dining",
  [WSCategory.Payroll]: "dollar-circle",
  [WSCategory.LegalProfessionalService]: "bank",
  [WSCategory.Software]: "globe",
  [WSCategory.OfficeRent]: "building",
  [WSCategory.HomeExpenses]: "building",
  [WSCategory.PhoneBill]: "phone",
  [WSCategory.Supplies]: "paperclip",
  [WSCategory.Taxes]: "calculator",
  [WSCategory.TravelTransport]: "start",
  [WSCategory.Transfer]: "transfer",
  [WSCategory.Other]: "dots-h",
  [WSCategory.OtherDeductions]: "dots-h",
  [WSCategory.Gift]: "gift",
  [WSCategory.Interest]: "percent",
  [WSCategory.Income]: "dollar-circle",
  [WSCategory.VehiclesMachineryEquipment]: "car",
  [WSCategory.Education]: "desktop",
  [WSCategory.BusinessLicense]: "info-circle",
  [WSCategory.VeterinaryServices]: "plus-circle",
  [WSCategory.ATMTransaction]: "bank",
  [WSCategory.TollFee]: "car",
  [WSCategory.Refund]: "dollar-circle"
};

export const getIconNameByWSCategory = (category: WSCategory) => {
  return CATEGORY_ICONS[category];
};

export const getTransactionAvatarProps = (
  transaction: ITransaction
): WSAvatarIconProps | WSAvatarWingspanProps => {
  if (!transaction.business) {
    return {
      type: "icon",
      icon: "block"
    };
  }

  if (transaction.name?.toLowerCase().includes("wingspan")) {
    return {
      type: "wingspan"
    };
  }

  if (transaction.type === TransactionType.Transfer) {
    return {
      type: "icon",
      icon: "transfer"
    };
  }

  if (transaction.type === TransactionType.Income) {
    return {
      type: "icon",
      icon: "dollar-circle"
    };
  }

  const wsCategory = transaction.wsCategory;
  const icon = wsCategory ? CATEGORY_ICONS[wsCategory] : "info-circle";

  return {
    type: "icon",
    icon: icon || "info-circle"
  };
};

export const isValidDateRange = (
  range: any
): range is WSDateRangeSelectRangeValue => {
  if (
    Array.isArray(range) &&
    range.length === 2 &&
    range[0] instanceof Date &&
    range[1] instanceof Date
  ) {
    return true;
  }

  return false;
};

export const getTransactionName = (transaction: ITransaction) => {
  return (
    "" +
    (transaction.labels.overrideName ||
      transaction.merchantName ||
      transaction.name ||
      "Unknown")
  );
};

export const dateIsValid = (date: any) => {
  return !Number.isNaN(new Date(date).getTime());
};
