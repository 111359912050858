import {
  WSFormOld,
  WSText,
  WSTextInput
} from "@wingspanhq/fe-component-library";
import React from "react";
import * as Yup from "yup";
import { addNotification } from "../../../components/Notification/Notification";
import { WSQuery } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import {
  useCreateSession,
  useUpdateUserProfile
} from "../../../query/users/mutations";
import { useUserProfile } from "../../../query/users/queries";
import { ISessionCreateRequest } from "../../../services/users";
import { FormActions } from "./FormActions";
import styles from "./SettingsUpdateForms.module.scss";

interface UpdateFormProps {
  onSubmit: (data: any) => void;
  defaultValues?: any;
  onCancel?: (data: any) => void;
}

export const UpdatePasswordForm: React.FC<UpdateFormProps> = ({
  onSubmit,
  onCancel
}) => {
  const userId = useUserId();
  const userQuery = useUserProfile(userId);
  const [createUserSession, { error: createUserSessionError }] =
    useCreateSession();
  const [updateUserProfile, { error: updateUserProfileError }] =
    useUpdateUserProfile({
      dependencies: []
    });

  const onSuccess = (data: any) => {
    onSubmit(data);
    userQuery.refetch();
    addNotification({
      text: "Your password has been successfully updated.",
      timeout: 3000
    });
  };

  const onUpdatePassword = async (data: any, email: string) => {
    const { currentPassword, newPassword } = data;

    const sessionRequest: ISessionCreateRequest = {
      email,
      password: currentPassword
    };
    await createUserSession(sessionRequest, {
      throwOnError: true,
      onSuccess: async () => {
        const request = {
          userId,
          password: newPassword
        };
        await updateUserProfile(request, {
          onSuccess: async () => {
            const sessionRequest: ISessionCreateRequest = {
              email,
              password: newPassword
            };

            await createUserSession(sessionRequest, {
              onSuccess: async () => {
                onSuccess(data);
              }
            });
          }
        });
      }
    });
  };

  const onAddPassword = async (data: any, email: string) => {
    const { newPassword } = data;

    const request = {
      userId,
      password: newPassword
    };

    await updateUserProfile(request, {
      onSuccess: async () => {
        const sessionRequest: ISessionCreateRequest = {
          email,
          password: newPassword
        };

        await createUserSession(sessionRequest, {
          onSuccess: async () => {
            onSuccess(data);
          }
        });
      }
    });
  };

  return (
    <WSQuery query={userQuery}>
      {({ data: user }) => {
        const isPasswordSet = !!user.isPasswordSet;

        if (!isPasswordSet) {
          return (
            <WSFormOld
              onSubmit={data => onAddPassword(data, user.email)}
              className={styles.form}
              defaultValues={{
                newPassword: "",
                confirmNewPassword: ""
              }}
              validationSchema={Yup.object({
                newPassword: Yup.string().required("This field is required"),
                confirmNewPassword: Yup.string()
                  .required("This field is required")
                  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
              })}
            >
              <WSFormOld.Field name="newPassword" mb="2XL">
                <WSFormOld.Label>New Password</WSFormOld.Label>
                <WSFormOld.Input
                  render={props => <WSTextInput type="password" {...props} />}
                />
                <WSFormOld.Error />
              </WSFormOld.Field>

              <WSFormOld.Field name="confirmNewPassword" mb="2XL">
                <WSFormOld.Label>Confirm New Password</WSFormOld.Label>
                <WSFormOld.Input
                  render={props => <WSTextInput type="password" {...props} />}
                />
                <WSFormOld.Error />
              </WSFormOld.Field>
              <WSText.ParagraphSm
                weight="medium"
                className={styles.errorText}
                mt="M"
              >
                {updateUserProfileError?.response?.data?.error}
              </WSText.ParagraphSm>
              <WSFormOld.Context>
                {({ formState: { isSubmitting } }) => (
                  <FormActions onCancel={onCancel} loading={isSubmitting} />
                )}
              </WSFormOld.Context>
            </WSFormOld>
          );
        }

        return (
          <WSFormOld
            onSubmit={data => onUpdatePassword(data, user.email)}
            className={styles.form}
            defaultValues={{
              currentPassword: "",
              newPassword: "",
              confirmNewPassword: ""
            }}
            validationSchema={Yup.object({
              currentPassword: Yup.string().required("This field is required"),
              newPassword: Yup.string().required("This field is required"),
              confirmNewPassword: Yup.string()
                .required("This field is required")
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            })}
          >
            <WSFormOld.Field name="currentPassword" mb="2XL">
              <WSFormOld.Label>Current Password</WSFormOld.Label>
              <WSFormOld.Input
                render={props => <WSTextInput type="password" {...props} />}
              />
              <WSFormOld.Error />
              <WSText.ParagraphSm
                weight="medium"
                className={styles.errorText}
                mt="XS"
              >
                {createUserSessionError?.response?.data["error"]}
              </WSText.ParagraphSm>
            </WSFormOld.Field>

            <WSFormOld.Field name="newPassword" mb="2XL">
              <WSFormOld.Label>New Password</WSFormOld.Label>
              <WSFormOld.Input
                render={props => <WSTextInput type="password" {...props} />}
              />
              <WSFormOld.Error />
            </WSFormOld.Field>

            <WSFormOld.Field name="confirmNewPassword" mb="2XL">
              <WSFormOld.Label>Confirm New Password</WSFormOld.Label>
              <WSFormOld.Input
                render={props => <WSTextInput type="password" {...props} />}
              />
              <WSFormOld.Error />
            </WSFormOld.Field>
            <WSText.ParagraphSm
              weight="medium"
              className={styles.errorText}
              mt="M"
            >
              {updateUserProfileError?.response?.data["error"]}
            </WSText.ParagraphSm>
            <WSFormOld.Context>
              {({ formState: { isSubmitting } }) => (
                <FormActions onCancel={onCancel} loading={isSubmitting} />
              )}
            </WSFormOld.Context>
          </WSFormOld>
        );
      }}
    </WSQuery>
  );
};
