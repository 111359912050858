import {
  useIsMobile,
  WSButton,
  WSCheckboxToggle,
  WSElement,
  WSFiltersOld,
  WSFlexBox,
  WSFormOld,
  WSInfiniteScroll,
  WSInputDateOld,
  WSPage,
  WSRadioInputGroup,
  WSTable,
  WSTableCell,
  WSTableItem,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  IIntegrationsQuicbooksSyncActivityAction,
  IIntegrationsQuickbooksSyncActivityGetResponse
} from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooksSyncActivity";
import React from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import {
  INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT,
  IntegrationsQuickbooksSyncActivitiesQueryFilters,
  useIntegrationsQuickbooksSyncActivities
} from "../../../../query/integrations/queries";
import { InvoicesDateRangeFilter } from "../../../../query/payments/queries";
import { WSQueries } from "../../../../query/WSQuery";
import { openInNewTab } from "../../../../utils/openInNewTab";
import { useUrlQueryFilters } from "../../../../utils/router";
import { getQuickbooksObjectPath } from "../../../utils/quickbooks";
import styles from "./IntegrationsQuickbooksSyncActivities.module.scss";
import { QuickbooksActivityDetails } from "./QuickbooksActivityDetails";

type SyncActivitiesTableItem = WSTableItem<SyncActivitiesTableItemData>;

type SyncActivitiesTableItemData = {
  syncActivity: IIntegrationsQuickbooksSyncActivityGetResponse;
};

export const IntegrationsQuickbooksSyncActivities: React.FC = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();

  const defaultFilters: IntegrationsQuickbooksSyncActivitiesQueryFilters =
    INTEGRATIONS_QUICKBOOKS_SYNC_ACTIVITIES_QUERY_FILTERS_DEFAULT;

  const { filters, setFilters } =
    useUrlQueryFilters<IntegrationsQuickbooksSyncActivitiesQueryFilters>(
      defaultFilters
    );

  const qIntegrationsQuickbooksSyncActivities =
    useIntegrationsQuickbooksSyncActivities(filters, {
      size: 20
    });

  const displayedEntitiesIds = new Set<string>();

  return (
    <WSPage
      title="Quickbooks Sync History"
      breadcrumb={{
        label: "Integrations",
        icon: "arrow-left",
        onClick: () => {
          history.push("/member/settings/integrations");
        }
      }}
    >
      <Route
        path="/member/settings/integrations/quickbooks/history/:entityId"
        component={QuickbooksActivityDetails}
      />

      <WSFiltersOld
        my="XL"
        mb="XL"
        filters={filters}
        defaultFilters={defaultFilters}
        onFilter={newFilters => {
          const updatedFilters = {
            ...filters,
            ...newFilters
          };
          setFilters(updatedFilters);
          displayedEntitiesIds.clear();
          qIntegrationsQuickbooksSyncActivities.refetch();
        }}
        onSearch={newSearchText => {
          const updatedFilters = {
            ...filters,
            displayNameSearch: newSearchText.toLowerCase() || undefined
          };
          setFilters(updatedFilters);
        }}
        searchQuery={filters.displayNameSearch}
      >
        <WSFormOld.Field
          name="createdAtRange"
          label="Sync date range"
          mb="2XL"
          component={WSRadioInputGroup}
          componentProps={{
            options: [
              {
                label: "All time",
                value: InvoicesDateRangeFilter.All
              },
              {
                label: "Past week",
                value: InvoicesDateRangeFilter.PastWeek
              },
              {
                label: "Past month",
                value: InvoicesDateRangeFilter.PastMonth
              },
              {
                label: "Past year",
                value: InvoicesDateRangeFilter.PastYear
              },
              {
                label: (
                  <WSFormOld.Context>
                    {({ setValue }) => (
                      <WSFlexBox
                        alignItems="center"
                        className={styles.dateRange}
                      >
                        <WSText mr="M">From</WSText>
                        <WSFormOld.Field
                          name="customCreatedAtRange"
                          component={WSInputDateOld}
                          componentProps={{
                            selectionMode: "range",
                            onFocus() {
                              setValue(
                                "createdAtRange",
                                InvoicesDateRangeFilter.Custom
                              );
                            }
                          }}
                        />
                      </WSFlexBox>
                    )}
                  </WSFormOld.Context>
                ),
                value: InvoicesDateRangeFilter.Custom
              }
            ]
          }}
        />
        <WSFormOld.Field
          name="onlyErrors"
          component={WSCheckboxToggle}
          componentProps={{ label: "Only errors" }}
          mb="2XL"
        />
      </WSFiltersOld>

      <WSQueries queries={{ qIntegrationsQuickbooksSyncActivities }}>
        {({
          qIntegrationsQuickbooksSyncActivities: {
            data: { data: syncActivities }
          }
        }) => {
          let data: SyncActivitiesTableItem[] = syncActivities
            .filter(activity => {
              if (!displayedEntitiesIds.has(activity.entityId)) {
                displayedEntitiesIds.add(activity.entityId);
                return true;
              }
              return false;
            })
            .map(syncActivity => {
              return {
                id: syncActivity.entityId,
                data: {
                  syncActivity: {
                    ...syncActivity,
                    displayName: syncActivity.displayName || "N/A"
                  }
                }
              };
            });

          return (
            <WSInfiniteScroll
              onLoad={() => {
                qIntegrationsQuickbooksSyncActivities.fetchMore();
              }}
              loadMore={syncActivities.length > 0}
              endOfList={!qIntegrationsQuickbooksSyncActivities.canFetchMore}
              loading={!!qIntegrationsQuickbooksSyncActivities.isFetchingMore}
            >
              <WSTable<SyncActivitiesTableItemData>
                tableData={data}
                columns={[
                  {
                    config: {
                      justify: "start",
                      header: {
                        text: "Object"
                      },
                      hideOnScreens: ["XS", "S"]
                    },
                    renderFunction: ({ data: { syncActivity } }) => (
                      <WSTableCell
                        avatar={{
                          type: "icon",
                          ...(syncActivity.isError
                            ? {
                                icon: "alert-circle",
                                colorBackground: "red50",
                                color: "red500"
                              }
                            : {
                                icon: "check",
                                colorBackground: "green50",
                                color: "green500"
                              })
                        }}
                        text={syncActivity.qboEntityName}
                      />
                    )
                  },
                  {
                    config: {
                      justify: "start",
                      header: {
                        text: "Name"
                      },
                      hideOnScreens: ["XS", "S"]
                    },
                    renderFunction: ({ data: { syncActivity } }) => (
                      <WSText>{syncActivity.displayName}</WSText>
                    )
                  },
                  {
                    config: {
                      justify: "start",
                      header: {
                        text: "Latest action"
                      },
                      hideOnScreens: ["XS", "S"]
                    },
                    renderFunction: ({ data: { syncActivity } }) => (
                      <WSText>{syncActivity.action}</WSText>
                    )
                  },
                  {
                    config: {
                      gridTemplateSizeMax: "1fr",
                      header: {
                        text: "Details"
                      }
                    },
                    renderFunction: ({ data: { syncActivity } }) => {
                      return (
                        <WSElement>
                          <WSText>
                            {`${
                              syncActivity.isError
                                ? `Failed to `
                                : `Successfully `
                            }${syncActivity.action.toLocaleLowerCase()}${
                              syncActivity.isError ? `` : `d`
                            } ${syncActivity.qboEntityName} `}
                            <WSButton.Link
                              onClick={() => {
                                openInNewTab(
                                  getQuickbooksObjectPath(syncActivity)
                                );
                              }}
                            >
                              {syncActivity.displayName}
                            </WSButton.Link>
                            {` ${
                              syncActivity.action ===
                              IIntegrationsQuicbooksSyncActivityAction.Delete
                                ? `from`
                                : `in`
                            } QBO${
                              syncActivity.message === "MarkedAsPaid"
                                ? ", marked as paid."
                                : ""
                            }`}
                          </WSText>
                          {isMobile && (
                            <WSText.ParagraphSm
                              mb="M"
                              color="gray400"
                              formatDate
                            >
                              {syncActivity.createdAt}
                            </WSText.ParagraphSm>
                          )}
                        </WSElement>
                      );
                    }
                  },
                  {
                    config: {
                      justify: "end",
                      header: {
                        text: "Synced Date"
                      },
                      hideOnScreens: ["XS", "S"]
                    },
                    renderFunction: ({ data: { syncActivity } }) => (
                      <WSText formatDate>{syncActivity.createdAt}</WSText>
                    )
                  }
                ]}
                data-testid="syncActivitiesTable"
                mb="2XL"
                onRowClick={item => {
                  history.push({
                    pathname:
                      "/member/settings/integrations/quickbooks/history/" +
                      item.id,
                    search: location.search,
                    state: {
                      activityId: item.data.syncActivity.syncActivityId
                    }
                  });
                }}
              />
            </WSInfiniteScroll>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
