import {
  toWSMoney,
  toWSMoneyString,
  WSButton,
  WSDivider,
  WSElement,
  WSFlexBox,
  WSList,
  WSLoader,
  WSMarginProps,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { IInvoice } from "@wingspanhq/payments/dist/interfaces";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { IPayoutResponse } from "../../../../services/api/payments/payouts/types";
import { usePayoutInvoicesDetails } from "../../queries/usePayoutInvoicesDetails";
import { makeNegative } from "../../utils/makeNegative";
import {
  getPayoutInvoiceIds,
  getPayoutNet,
  getTotals
} from "../../utils/utils";
import styles from "./styles.module.scss";

export interface PayoutSummaryPanelProps extends WSMarginProps {
  payout: IPayoutResponse;
}

export const PayoutSummaryPanel: React.FC<PayoutSummaryPanelProps> = ({
  payout,
  ...elementProps
}) => {
  const invoiceIds = useMemo(() => getPayoutInvoiceIds(payout), [payout]);

  const queryPayoutInvoicesDetails = usePayoutInvoicesDetails(invoiceIds);

  const payoutTotalAmount = useMemo(() => getPayoutNet(payout), [payout]);

  const invoices = useMemo(
    () => queryPayoutInvoicesDetails.data || [],
    [queryPayoutInvoicesDetails.data]
  );

  const totals = useMemo(() => getTotals(payout, invoices), [invoices, payout]);

  if (queryPayoutInvoicesDetails.isLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <WSPanel className={styles.panel} {...elementProps}>
      <WSList gap="2XL">
        <WSElement>
          <WSText.Caption mb="XS">Gross payments</WSText.Caption>

          <Row
            label="Payment for invoice(s):"
            value={toWSMoneyString(totals.grossPayments)}
          />

          <WSElement>
            {invoices.map(invoice => (
              <InvoiceLink key={invoice.invoiceId} invoice={invoice} />
            ))}
          </WSElement>
        </WSElement>

        {totals.instantPayouts !== 0 && (
          <WSElement>
            <WSText.Caption mb="XS">Instant payouts</WSText.Caption>

            <Row
              label="Instant payout(s):"
              value={toWSMoneyString(makeNegative(totals.instantPayouts))}
            />
            {totals.instantPayoutInvoices.map(invoice => (
              <InvoiceLink key={invoice.invoiceId} invoice={invoice} />
            ))}
          </WSElement>
        )}

        {(totals.fees > 0 || totals.deductions > 0) && (
          <WSElement>
            <WSText.Caption mb="XS">Fees & Deductions</WSText.Caption>
            <Row
              label="Fees & deductions for invoice(s)"
              value={toWSMoneyString(
                makeNegative(totals.fees + totals.deductions)
              )}
            />
          </WSElement>
        )}

        {totals.contractorPayments > 0 && (
          <WSElement>
            <WSText.Caption mb="XS">Payments to others</WSText.Caption>
            <Row
              label="Payments to other recipients for invoice(s)"
              value={toWSMoneyString(makeNegative(totals.contractorPayments))}
            />
          </WSElement>
        )}

        {Boolean(payout.taxWithholdingTransfer?.amount) && (
          <WSElement>
            <WSText.Caption mb="XS">Tax withholdings</WSText.Caption>

            <Row
              label="Tax withholdings for invoice(s)"
              value={
                toWSMoney(
                  makeNegative(payout.taxWithholdingTransfer?.amount || 0)
                ).formattedValue
              }
            />
          </WSElement>
        )}

        <WSDivider />

        <WSList gap="M">
          {Boolean(payout.futureDebits) && (
            <Row
              label="Future item(s) holding:"
              value={
                toWSMoney(makeNegative(payout.futureDebits || 0)).formattedValue
              }
            />
          )}

          <Row
            label="Net payout total"
            value={toWSMoney(payoutTotalAmount).formattedValue}
            valueAccented
          />
        </WSList>
      </WSList>
    </WSPanel>
  );
};

const Row: React.FC<{
  label: string;
  value: string;
  valueAccented?: boolean;
}> = props => (
  <WSFlexBox gap="2XL" justify="space-between">
    <WSText.ParagraphSm color="gray500">{props.label}</WSText.ParagraphSm>
    <WSText.ParagraphSm
      weight={props.valueAccented ? "bold" : undefined}
      color="gray700"
    >
      {props.value}
    </WSText.ParagraphSm>
  </WSFlexBox>
);

const InvoiceLink: React.FC<{ invoice: IInvoice }> = props => {
  const history = useHistory();
  return (
    <WSElement>
      <WSButton.Link
        onClick={() => {
          history.push(`/member/invoices/${props.invoice.invoiceId}`);
        }}
      >
        Invoice #{props.invoice.invoiceNumber}
      </WSButton.Link>
    </WSElement>
  );
};
