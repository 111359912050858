import { DataRequestConfig, ITheme } from "@flatfile/sdk";

export const flatfileTheme: ITheme = {
  hideConfetti: true
};

export const flatfileConfig: DataRequestConfig = {
  // https://flatfile.com/versioned-docs/3.0/guides/client-side-data/#chunktimeoutexpirederror
  chunkSize: 500, // default: 1000
  // Note: 2 requests per second assuming low internet speed
  chunkTimeout: 300000 // (500/5 mins, 1.7 prs) default: 30 sec
};

export const flatfileProviderConfig = {
  exitTitle: "Info",
  exitText:
    "All your changes have been saved. You can re-open the uploader at any time to continue.",
  exitPrimaryButtonText: "Close",
  exitSecondaryButtonText: "Go back"
};
