import { QueryConfig } from "react-query";
import { WSServiceError } from "../../../utils/serviceHelper";
import { useWSQuery } from "../../helpers";
import { IEngagementResponse } from "@wingspanhq/payments/dist/interfaces";
import { getEngagement } from "../../../services/engagements";
import { QUERY_ENGAGEMENT } from "../keys";
import { useXWingspanPayerId } from "../../../modules/Payees/routes/RoutePayeeDetails/useXWingspanPayerId";

export const useEngagementQuery = (
  id: string,
  config?: QueryConfig<IEngagementResponse, WSServiceError>
) => {
  const xWingspanPayerId = useXWingspanPayerId(id);

  return useWSQuery<IEngagementResponse, WSServiceError>(
    [QUERY_ENGAGEMENT, id, xWingspanPayerId],
    async (query, queryParams) => {
      return await getEngagement(id, xWingspanPayerId);
    },
    {
      ...config
    }
  );
};
