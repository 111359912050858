import {
  SelectOptionOld,
  useModalContext,
  useWSModal,
  WSActions,
  WSForm,
  WSSelect
} from "@wingspanhq/fe-component-library";
import React from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../../query/integrations/queries";
import { useUpdatePayee } from "../../../../query/payee/mutations/useUpdatePayee";
import { useUpdatePayeeEngagement } from "../../../../query/payeeEngagements/mutations/useUpdatePayeeEngagement";
import { WSQueries } from "../../../../query/WSQuery";
import {
  INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT,
  QUICKBOOKS_ENTITY,
  RefreshModal
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import { selectQuickbooksAccountOrItemOptions } from "../../../Integrations/selectors/selectQuickbooksAccountOrItemOptions";
import { selectQuickbooksUserOptions } from "../../../Integrations/selectors/selectQuickbooksUserOptions";

type Props = {
  payeeId: string;
  engagementId: string | undefined;
  vendorId: string | undefined;
  expenseAccountId: string | undefined;
  onClose: () => void;
};

const EditPayeeIntegrationModal: React.FC<Props> = ({
  onClose,
  payeeId,
  engagementId,
  vendorId,
  expenseAccountId
}) => {
  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const qIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const qIntegrationsQuickbooksExpenseAccounts =
    useIntegrationsQuickbooksAccountExpenses();

  const [updatePayee, updatePayeeMeta] = useUpdatePayee();
  const [updateEngagement, updateEngagementMeta] = useUpdatePayeeEngagement();

  const { openModal, closeModal } = useModalContext();

  return (
    <WSQueries
      queries={{
        qIntegrationsQuickbooks,
        qIntegrationsQuickbooksVendors,
        qIntegrationsQuickbooksExpenseAccounts
      }}
    >
      {({
        qIntegrationsQuickbooks: { data: integrationsQuickbooks },
        qIntegrationsQuickbooksVendors: { data: integrationsQuickbooksVendors },
        qIntegrationsQuickbooksExpenseAccounts: {
          data: integrationQuickbooksExpenseAccounts
        }
      }) => {
        const integrationState = integrationsQuickbooks;

        const quickbooksVendorOptions: SelectOptionOld[] =
          selectQuickbooksUserOptions(integrationsQuickbooksVendors);

        const quickbooksAccountsOptions: SelectOptionOld[] =
          selectQuickbooksAccountOrItemOptions(
            integrationQuickbooksExpenseAccounts
          );

        const defaultAccount = integrationQuickbooksExpenseAccounts.find(
          acct =>
            acct.accountId === integrationState?.defaults?.expenseAccountId
        );

        return (
          <>
            <WSForm<{
              qboVendorId?: string | undefined;
              qboExpenseAccountId?: string | undefined;
            }>
              defaultValues={{
                qboVendorId: vendorId,
                qboExpenseAccountId: expenseAccountId
              }}
              onSubmit={values => {
                if (engagementId) {
                  updateEngagement(
                    {
                      engagementId,
                      payeeId,
                      payerOwnedData: {
                        integration: {
                          quickbooks: {
                            vendorId: values.qboVendorId,
                            expenseAccountId: values.qboExpenseAccountId
                          }
                        }
                      }
                    },
                    {
                      onSuccess: () => {
                        onClose();
                      }
                    }
                  );
                  return;
                } else {
                  updatePayee(
                    {
                      payeeId,
                      payerOwnedData: {
                        integration: {
                          quickbooks: {
                            vendorId: values.qboVendorId,
                            expenseAccountId: values.qboExpenseAccountId
                          }
                        }
                      }
                    },
                    {
                      onSuccess: () => {
                        onClose();
                      }
                    }
                  );
                }
              }}
            >
              <WSForm.Field
                mb="XL"
                name="qboVendorId"
                component={WSSelect}
                componentProps={{
                  placeholder: "Not mapped, create new Vendor",
                  options: quickbooksVendorOptions,
                  action: {
                    text: "Resync vendors",
                    // icon: "refresh-v",
                    onClick: () => {
                      openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                        entity: QUICKBOOKS_ENTITY.VENDORS
                      });
                    }
                  }
                }}
                label="QBO Vendor"
              />
              <WSForm.Field
                mb="2XL"
                name="qboExpenseAccountId"
                component={WSSelect}
                componentProps={{
                  options: quickbooksAccountsOptions,
                  placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                  action: {
                    text: "Resync accounts",
                    // icon: "refresh-v",
                    onClick: () => {
                      openModal(INTEGRATIONS_QUICKBOOKS_RESYNC_SELECT, {
                        entity: QUICKBOOKS_ENTITY.EXPENSES
                      });
                    }
                  }
                }}
                label="Default QBO Expense Account"
              />

              <WSErrorMessage
                mb="XL"
                error={updatePayeeMeta.error || updateEngagementMeta.error}
                contextKey="EditCollaboratorIntegrations"
              />

              <WSActions
                alignment="fill"
                buttons={[
                  {
                    label: "Update",
                    kind: "Primary",
                    type: "submit",
                    loading:
                      updatePayeeMeta.isLoading ||
                      updateEngagementMeta.isLoading
                  },
                  {
                    kind: "Secondary",
                    label: "Cancel",
                    onClick: onClose
                  }
                ]}
              />
            </WSForm>
            <RefreshModal />
          </>
        );
      }}
    </WSQueries>
  );
};

export const useEditPayeeIntegrationModal = () =>
  useWSModal(EditPayeeIntegrationModal, {
    title: "Edit integration",
    size: "S"
  });
