import {
  useModalContext,
  WSButton,
  WSElement,
  WSFlexBox,
  WSLoader,
  WSModal,
  WSText
} from "@wingspanhq/fe-component-library";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  useCreateIntegrationQuickbooks,
  useCreateIntegrationsQuickbooksAccountAssets,
  useCreateIntegrationsQuickbooksAccountExpenses,
  useCreateIntegrationsQuickbooksAccountLiabilities,
  useCreateIntegrationsQuickbooksAccountRevenues,
  useCreateIntegrationsQuickbooksCustomers,
  useCreateIntegrationsQuickbooksItems,
  useCreateIntegrationsQuickbooksVendors,
  useUpdateIntegrationQuickbooks
} from "../../../../../query/integrations/mutations";

import {
  IntegrationsQuickbooksAuthStatus,
  IntegrationsQuickbooksSyncStatus
} from "@wingspanhq/integrations/dist/lib/interfaces/quickbooks-api/integrationsQuickbooks";
import { useIntegrationsQuickbooks } from "../../../../../query/integrations/queries";
import { openIntercom } from "../../../../../shared/utils/intercom";

export const INTEGRATIONS_QUICKBOOKS_COMPLETING_OAUTH_MODAL =
  "INTEGRATIONS_QUICKBOOKS_COMPLETING_OAUTH_MODAL";

export const IntegrationsQuickbooksCompletingOAuthModal: React.FC = () => {
  const { closeModal } = useModalContext();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const oAuthCode = params.get("code");
  const oAuthState = params.get("state");
  const oAuthRealmId = params.get("realmId");
  const oAuthTarget = params.get("oAuthTarget");

  const [createIntegrationsCreateQuickbooks] = useCreateIntegrationQuickbooks();
  const [updateIntegration, mUpdateIntegration] =
    useUpdateIntegrationQuickbooks();

  const [createIntegrationsQuickbooksAccountAssets] =
    useCreateIntegrationsQuickbooksAccountAssets();
  const [createIntegrationsQuickbooksAccountExpenses] =
    useCreateIntegrationsQuickbooksAccountExpenses();
  const [createIntegrationsQuickbooksAccountLiabilities] =
    useCreateIntegrationsQuickbooksAccountLiabilities();
  const [createIntegrationsQuickbooksAccountRevenues] =
    useCreateIntegrationsQuickbooksAccountRevenues();
  const [createIntegrationsQuickbooksCustomers] =
    useCreateIntegrationsQuickbooksCustomers();
  const [createIntegrationsQuickbooksItems] =
    useCreateIntegrationsQuickbooksItems();
  const [createIntegrationsQuickbooksVendors] =
    useCreateIntegrationsQuickbooksVendors();

  const qIntegrationsQuickbooks = useIntegrationsQuickbooks();

  const [hasError, setHasError] = useState(false);
  const [isCreatingAuth, setIsCreatingAuth] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [currentStep, setCurrentStep] = useState("Creating integration record");

  const doSync = async () => {
    setIsDone(false);
    setCurrentStep("Beginning sync");
    await updateIntegration({
      syncStatus: IntegrationsQuickbooksSyncStatus.PendingSync
    });

    setCurrentStep("Syncing asset accounts");
    await createIntegrationsQuickbooksAccountAssets();

    setCurrentStep("Syncing expense accounts");
    await createIntegrationsQuickbooksAccountExpenses();

    setCurrentStep("Syncing liability accounts");
    await createIntegrationsQuickbooksAccountLiabilities();

    setCurrentStep("Syncing revenue accounts");
    await createIntegrationsQuickbooksAccountRevenues();

    setCurrentStep("Syncing customers");
    await createIntegrationsQuickbooksCustomers();

    setCurrentStep("Syncing vendors");
    await createIntegrationsQuickbooksVendors();

    setCurrentStep("Syncing items");
    await createIntegrationsQuickbooksItems();

    setIsDone(true);
  };

  const createQuickbooksIntegration = async () => {
    setIsCreatingAuth(true);
    const redirectUrl = window.location.href;
    try {
      const createResponse = await createIntegrationsCreateQuickbooks(
        redirectUrl
      );
      if (
        createResponse?.authStatus ===
        IntegrationsQuickbooksAuthStatus.Connected
      ) {
        window.history.pushState({}, document.title, window.location.pathname);
        // start the sync
        await doSync();
      }
    } catch (err) {
      console.error(err);
      setHasError(true);
    }
  };

  useEffect(() => {
    if (
      !isCreatingAuth &&
      oAuthCode !== null &&
      oAuthState !== null &&
      oAuthRealmId !== null &&
      oAuthTarget !== null &&
      qIntegrationsQuickbooks.data &&
      qIntegrationsQuickbooks.data?.authStatus !==
        IntegrationsQuickbooksAuthStatus.Connected
    ) {
      console.log("Should create quickbooks integration!");
      createQuickbooksIntegration();
    }
  }, [
    isCreatingAuth,
    oAuthCode,
    oAuthState,
    oAuthRealmId,
    oAuthTarget,
    qIntegrationsQuickbooks
  ]);

  return (
    <WSModal
      blockClose={true}
      showCloseIcon={false}
      name={INTEGRATIONS_QUICKBOOKS_COMPLETING_OAUTH_MODAL}
    >
      {({ onSuccess, onError }) =>
        !hasError ? (
          isDone ? (
            <>
              <WSText.Heading5 mb="XL">
                Authentication and Data Import Complete! 🎉
              </WSText.Heading5>
              <WSText mb="XL">
                We've finished authorizing Wingspan to access Quickbooks and
                successfully imported the necessary data.
              </WSText>
              <WSText mb="3XL">
                The following steps will have you map your data in Wingspan to
                the data we imported from Quickbooks.
              </WSText>
              <WSButton.Primary
                mt="XL"
                fullWidth
                data-testid="finishOAuth"
                onClick={() => {
                  onSuccess && onSuccess();
                  closeModal(INTEGRATIONS_QUICKBOOKS_COMPLETING_OAUTH_MODAL);
                }}
              >
                Continue to data mapping
              </WSButton.Primary>
            </>
          ) : (
            <>
              <WSText.Heading5 mb="XL">
                All looks good so far... ✨
              </WSText.Heading5>
              <WSText mb="3XL">
                We're completing the authentication and beginning the data
                import. This process involves importing necessary data from your
                Quickbooks account into your Wingspan account. Please stand
                by...
              </WSText>

              <WSElement mb="XL">
                <WSLoader.Spinner mb="M" />
                <WSFlexBox justify="center">
                  <WSText.ParagraphXs>{currentStep}</WSText.ParagraphXs>
                </WSFlexBox>
              </WSElement>
            </>
          )
        ) : (
          <>
            <WSText.Heading5 mb="XL">
              Yikes! Something went wrong 🙀
            </WSText.Heading5>
            <WSText mb="3XL">
              Something went wrong authenticating you with Quickbooks. Please
              try again. If this error persists, please{" "}
              <WSButton.Link onClick={openIntercom}>
                contact support.
              </WSButton.Link>
            </WSText>
            <WSButton.Primary
              mt="XL"
              fullWidth
              data-testid="finishOAuth"
              onClick={() => {
                onError && onError();
                closeModal(INTEGRATIONS_QUICKBOOKS_COMPLETING_OAUTH_MODAL);
              }}
            >
              Go back
            </WSButton.Primary>
          </>
        )
      }
    </WSModal>
  );
};
