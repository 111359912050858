import React from "react";
import * as Yup from "yup";

import {
  SelectOptionOld,
  useWSSnackbar,
  WSActions,
  WSDivider,
  WSElement,
  WSForm,
  WSList,
  WSMessageBox,
  WSSelect,
  WSText
} from "@wingspanhq/fe-component-library";
import { FormPartialPayeeSearchField } from "../../../../shared/components/PayeeSearchField";
import { useAddPayeeToEngagement } from "../../../../query/payeeEngagements/mutations/useAddPayeeToEngagement";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { ErrorContextKey } from "../../../../services/platform";
import { useEngagementQuery } from "../../../../query/engagements/queries/useEngagementQuery";
import { WSQueries } from "../../../../query/WSQuery";
import {
  QUICKBOOKS_ENTITY,
  RefreshModal,
  useQboRefreshModal
} from "../../../../Settings/screens/Integrations/quickbooks/RefreshButton";
import {
  useIntegrationsQuickbooks,
  useIntegrationsQuickbooksAccountExpenses,
  useIntegrationsQuickbooksVendors
} from "../../../../query/integrations/queries";
import { selectorIsQBOActive } from "../../../../shared/selectors/selectorIsQBOActive";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { selectQuickbooksUserOptions } from "../../../Integrations/selectors/selectQuickbooksUserOptions";
import { selectQuickbooksAccountOrItemOptions } from "../../../Integrations/selectors/selectQuickbooksAccountOrItemOptions";

interface Props {
  engagementId: string;
  onClose(result: boolean): void;
}

export const AddPayeeToEngagementModal: React.FC<Props> = ({
  engagementId,
  onClose
}) => {
  const { openSnackbar } = useWSSnackbar();
  const queryEngagement = useEngagementQuery(engagementId);
  const qboRefreshModal = useQboRefreshModal();
  const queryFeatureFlags = useFeatureFlags();
  const queryIntegrationsQuickbooks = useIntegrationsQuickbooks();
  const queryIntegrationsQuickbooksVendors = useIntegrationsQuickbooksVendors();
  const queryIntegrationsQuickbooksExpenseAccounts =
    useIntegrationsQuickbooksAccountExpenses();
  const [addPayeeToEngagement, addPayeeToEngagementMeta] =
    useAddPayeeToEngagement();

  const isQuickbooksActive = selectorIsQBOActive(
    queryFeatureFlags?.data,
    queryIntegrationsQuickbooks.data
  );
  return (
    <WSQueries queries={{ queryEngagement }}>
      {({ queryEngagementData: engagement }) => {
        const quickbooksVendorOptions: SelectOptionOld[] =
          selectQuickbooksUserOptions(queryIntegrationsQuickbooksVendors.data);

        const quickbooksAccountsOptions: SelectOptionOld[] =
          selectQuickbooksAccountOrItemOptions(
            queryIntegrationsQuickbooksExpenseAccounts.data
          );

        const defaultAccount = (
          queryIntegrationsQuickbooksExpenseAccounts.data ?? []
        ).find(
          acct =>
            acct.accountId ===
            queryIntegrationsQuickbooks.data?.defaults?.expenseAccountId
        );
        return (
          <WSForm
            defaultValues={{
              engagementId,
              payeeId: "",
              qboVendorId: "",
              qboExpenseAccountId: ""
            }}
            validationSchema={Yup.object().shape({
              payeeId: Yup.string().required("Required")
            })}
            onSubmit={async data => {
              await addPayeeToEngagement(
                {
                  payerOwnedData: {
                    integration: {
                      quickbooks: {
                        vendorId: data.qboVendorId,
                        expenseAccountId: data.qboExpenseAccountId
                      }
                    }
                  },
                  engagementId: data.engagementId,
                  payeeId: data.payeeId
                },
                {
                  onSuccess() {
                    openSnackbar({
                      message: "Contractor added to engagement",
                      type: "success"
                    });
                    onClose(true);
                  },
                  onError() {
                    openSnackbar({
                      message: "Failed to add contractor to engagement",
                      type: "error"
                    });
                  }
                }
              );
            }}
          >
            <WSList gap="XL">
              <FormPartialPayeeSearchField
                name="payeeId"
                engagementId={engagementId}
              />
              <WSForm.Field
                name="engagementId"
                label="Engagement"
                component={WSSelect}
                componentProps={{
                  disabled: true,
                  options: [{ value: engagementId, label: engagement.name }]
                }}
              />
              {isQuickbooksActive &&
                quickbooksVendorOptions.length > 0 &&
                quickbooksAccountsOptions.length > 0 && (
                  <WSElement>
                    <WSDivider mb="L" label="Quickbooks Mapping" />
                    <WSText mb="XL">
                      Because you have an active integration with QBO, please
                      map this new Contractor to an existing Vendor, or leave
                      blank and we will create a new one. You can also set a
                      default expense account for payable line items.
                    </WSText>
                    <WSForm.Field
                      mb="XL"
                      name="qboVendorId"
                      component={WSSelect}
                      componentProps={{
                        placeholder: "Not mapped, create new Vendor",
                        options: quickbooksVendorOptions,
                        menuFooterAction: {
                          label: "Resync QBO Vendors",
                          icon: "refresh-v",
                          onClick: () => {
                            qboRefreshModal.open({
                              entity: QUICKBOOKS_ENTITY.VENDORS
                            });
                          }
                        }
                      }}
                      label="QBO Vendor"
                    />
                    <WSForm.Field
                      name="qboExpenseAccountId"
                      component={WSSelect}
                      componentProps={{
                        options: quickbooksAccountsOptions,
                        placeholder: `Use default "${defaultAccount?.fullyQualifiedName}"`,
                        menuFooterAction: {
                          label: "Resync QBO Expenses Accounts",
                          icon: "refresh-v",
                          onClick: () => {
                            qboRefreshModal.open({
                              entity: QUICKBOOKS_ENTITY.EXPENSES
                            });
                          }
                        }
                      }}
                      label="Default QBO Expense Account"
                    />
                    <RefreshModal />
                  </WSElement>
                )}

              <WSMessageBox.Info size="Medium" noBorder>
                <WSText.ParagraphXs weight="medium" color="gray600" mb="XS">
                  Adding contractor to engagement
                </WSText.ParagraphXs>
                <WSText.ParagraphXs weight="book" color="gray600">
                  Contractor added to an engagement will be required to complete
                  engagement-specific requirements (if any) in addition to
                  general requirements in order to receive payments for that
                  engagement. You can archive or restore a contractor’s
                  engagement at any time.
                </WSText.ParagraphXs>
              </WSMessageBox.Info>
              <WSErrorMessage
                contextKey={ErrorContextKey.AddPayeeToEngagement}
                error={addPayeeToEngagementMeta.error}
              />
            </WSList>

            <WSActions
              mt="3XL"
              alignment="fill"
              buttons={[
                {
                  label: "Add contractor",
                  kind: "Primary",
                  type: "submit",
                  loading: addPayeeToEngagementMeta.isLoading
                },
                {
                  label: "Cancel",
                  kind: "Secondary",
                  type: "button",
                  onClick() {
                    onClose(false);
                  }
                }
              ]}
            />
          </WSForm>
        );
      }}
    </WSQueries>
  );
};
