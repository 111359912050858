import { FlowMEChangeContext } from ".";
import { components } from "../../../../services/api/onboarding/types";
import { FormDataAccountType } from "../../components/FormAccountType";
import { CertifyTaxPayerInfo } from "../../steps/CertifyTaxPayerInfo";
import { OnboardingStep } from "../../types";
import { StepImmaterialUpdateBusinessLegalName } from "./steps/StepImmaterialUpdateBusinessLegalName";
import { StepImmaterialUpdateIndividualLegalName } from "./steps/StepImmaterialUpdateIndividualLegalName";
import { StepImmaterialUpdateReview } from "./steps/StepImmaterialUpdateReview";
import { StepMaterialUpdateAccountType } from "./steps/StepMaterialUpdateAccountType";
import { StepMaterialUpdateBusinessInformation } from "./steps/StepMaterialUpdateBusinessInformation";
import { StepMaterialUpdatePersonalInformation } from "./steps/StepMaterialUpdatePersonalInformation";
import { StepMaterialUpdateRepresentative } from "./steps/StepMaterialUpdateRepresentative";
import { StepMaterialUpdateReview } from "./steps/StepMaterialUpdateReview";
import { StepReason } from "./steps/StepReason";

function getMaterialUpdateSteps(accountType: FormDataAccountType["type"]) {
  if (accountType === "Business") {
    return [
      {
        title: "Account type",
        slug: "type",
        component: StepMaterialUpdateAccountType
      },
      {
        title: "Business information",
        slug: "business",
        component: StepMaterialUpdateBusinessInformation
      },
      {
        title: "Representative",
        slug: "representative",
        component: StepMaterialUpdateRepresentative
      },
      {
        title: "Review & submit",
        slug: "review",
        component: StepMaterialUpdateReview
      }
    ];
  }

  return [
    {
      title: "Account type",
      slug: "type",
      component: StepMaterialUpdateAccountType
    },
    {
      title: "Personal information",
      slug: "personal",
      component: StepMaterialUpdatePersonalInformation
    },
    {
      title: "Review & submit",
      slug: "review",
      component: StepMaterialUpdateReview
    }
  ];
}

export function getSteps(
  context: FlowMEChangeContext,
  currentMonitoredEntity?: components["schemas"]["CustomerEntity"],
  initialVerificationStatus?: components["schemas"]["VerificationStatus"],
  currentVerificationStatus?: components["schemas"]["VerificationStatus"]
): OnboardingStep[] {
  const accountType =
    context.accountType?.type || currentMonitoredEntity?.type || "Individual";

  const isMaterialChange =
    context.reason?.changeType &&
    ["business-structure", "tax-id", "tax-residency"].includes(
      context.reason?.changeType
    );

  const materialUpdateStep: OnboardingStep = {
    title: "Update taxpayer info",
    slug: "update",
    steps: getMaterialUpdateSteps(accountType),
    status:
      context.newMEcreated && currentVerificationStatus
        ? currentVerificationStatus === "Verified"
          ? "Complete"
          : "Pending"
        : "None",
    result: {
      Complete: {
        title: "Your taxpayer info was updated",
        description:
          "You changes you requested are now updated in your Wingspan account and will be used for future tax reporting purposes in accordance with applicable laws and regulations."
      },
      Pending: {
        title: "Your taxpayer info update is pending review",
        description:
          "The update to your taxpayer information is being reviewed. Keep an eye out for updates from us and if additional actions are required. You will be notified by email and on your home page.\n\nPlease note that, if necessary, your account information will be updated by our team to match official documents provided.\n\nClick continue for the next step."
      }
    }
  };

  const immaterialUpdateStep = {
    title: "Update taxpayer info",
    slug: "update",
    steps: [
      {
        title: "Update taxpayer info",
        slug: "type",
        component:
          currentMonitoredEntity?.type === "Individual"
            ? StepImmaterialUpdateIndividualLegalName
            : StepImmaterialUpdateBusinessLegalName
      },
      {
        title: "Personal information",
        slug: "personal",
        component: StepImmaterialUpdateReview
      }
    ]
  };

  const reasonStep = {
    title: "Reason for change",
    slug: "reason",
    component: StepReason,
    status: !!context.reason ? "Complete" : "None"
  };

  const cerifyStep = {
    title: "Certify taxpayer info",
    slug: "certify",
    component: CertifyTaxPayerInfo
  };

  if (initialVerificationStatus === "Verified") {
    return [
      reasonStep,
      isMaterialChange ? materialUpdateStep : immaterialUpdateStep,
      cerifyStep
    ];
  }

  return [materialUpdateStep, cerifyStep];
}
