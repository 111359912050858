import {
  WSActions,
  WSCard,
  WSControl,
  WSElement,
  WSInfoBox,
  WSList,
  WSProgressBar,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FlowMEChangeContext } from "..";
import { WSErrorMessage } from "../../../../../components/WSErrorMessage/WSErrorMessage";
import { useUserId } from "../../../../../query/hooks/helpers";
import { useQueryVerifications } from "../../../../../query/onboarding/queries/useQueryVerifications";
import { useUserProfile } from "../../../../../query/users/queries";
import { useFlowContext } from "../../../../../shared/components/Flow";
import { ReviewAccountType } from "../../../components/ReviewAccountType";
import { ReviewBusinessInformation } from "../../../components/ReviewBusinessInformation";
import { ReviewPerson } from "../../../components/ReviewPerson";
import { OnboardingStep } from "../../../types";
import { useMutationSubmitMaterial } from "./useMutationSubmitMaterial";

export const StepMaterialUpdateReview: React.FC<OnboardingStep> = ({
  onBack,
  onNext
}) => {
  const history = useHistory();
  const basePath = history.location.pathname.replace("/review", "");

  const [context] = useFlowContext<FlowMEChangeContext>();
  const [submit, meta] = useMutationSubmitMaterial({
    onSuccess: onNext
  });
  const [consent, setConsent] = useState(false);
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryVerifications = useQueryVerifications();

  if (meta.isLoading) {
    return (
      <WSList gap="2XL">
        <WSCard header="Verifying your Tax ID">
          <ProgressBar />
          <WSInfoBox>
            We are verifying your Tax ID. This can take up to 10 seconds.
          </WSInfoBox>
        </WSCard>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Continue",
              disabled: true
            }
          ]}
        />

        <WSText.ParagraphXs color="gray400">
          The information on this page is required to setup your Wingspan
          account, if you exit or leave without completing this step, you will
          return to this page the next time you sign-in
        </WSText.ParagraphXs>
      </WSList>
    );
  }

  return (
    <WSList gap="2XL">
      <WSList gap="L">
        <WSElement>
          <WSSectionToolbar title="Confirm account information" />
          <WSText.ParagraphSm mt="L" color="gray500">
            Please review to ensure the information is accurate before
            submitting for verification
          </WSText.ParagraphSm>
        </WSElement>

        {context.accountType && (
          <ReviewAccountType
            formData={context.accountType}
            onEdit={() => {
              history.push(basePath + "/type");
            }}
          />
        )}

        {context.accountType?.type === "Individual" ? (
          <ReviewPerson
            type="Individual"
            title="Personal information"
            formData={context.personalInformation?.person}
            onEdit={() => {
              history.push(basePath + "/personal");
            }}
          />
        ) : (
          <>
            <ReviewBusinessInformation
              formData={context.businessInformation}
              country={context.accountType?.country || "US"}
              onEdit={() => {
                history.push(basePath + "/business");
              }}
              personalEmail={queryUser.data?.email}
            />
            <ReviewPerson
              type="Representative"
              title="Representative information (You)"
              formData={context.representative?.representative}
              onEdit={() => {
                history.push(basePath + "/representative");
              }}
            />
          </>
        )}

        <WSCard
          header={{
            label: {
              text: "Confirm changes to your tax information"
            }
          }}
        >
          <WSList gap="2XL">
            {queryVerifications.data?.tax === "Pending" ? (
              <WSInfoBox>
                This information will be used to customize your Wingspan
                experience. Once your information is verified, you cannot return
                to this step and the information cannot be changed without
                re-verification. Please verify all information provided is
                accurate.
              </WSInfoBox>
            ) : (
              <>
                <WSText.ParagraphSm color="gray500">
                  You are updating your entity type or tax classification.
                </WSText.ParagraphSm>
                <WSInfoBox>
                  <p>Important:</p>

                  <ul>
                    <li>
                      <b>Tax reporting changes</b>: May alter how your income is
                      reported to tax authorities
                    </li>
                    <li>
                      <b>Form 1099 eligibility</b>: If changing to a
                      corporation, you may not receive a Form 1099. Your payers
                      have ultimate discretion regarding tax reporting.{" "}
                    </li>
                    <li>
                      <b>Separate tax forms</b>: Payments may be reported
                      separately before and after the change.
                    </li>
                    <li>
                      <b>Payer discretion</b>: Your payers, not Wingspan, have
                      ultimate discretion regarding tax reporting.
                    </li>
                    <li>
                      <b>Wingspan bank accounts</b>: the entity associated with
                      your Wingspan bank account(s) will not change with this
                      update. To open accounts with a new entity, please contact
                      support.
                    </li>
                  </ul>
                </WSInfoBox>
              </>
            )}

            <WSControl
              type="checkbox"
              value={consent}
              onChange={setConsent}
              label="By proceeding, I acknowledge that I understand the tax implications of these changes. I confirm that all information is accurate. I agree to the processing of my information for identity verification as detailed in the Privacy Policy."
              size="S"
            />
          </WSList>
        </WSCard>
      </WSList>

      <WSErrorMessage contextKey="CIP" error={meta.error} />

      <WSActions
        alignment="fill"
        buttons={[
          { label: "Back", onClick: onBack, kind: "Secondary" },
          {
            label: "Confirm & continue",
            disabled: !consent,
            onClick: () => {
              submit();
            }
          }
        ]}
      />

      <WSText.ParagraphXs color="gray400">
        The information on this page is required to setup your Wingspan account,
        if you exit or leave without completing this step, you will return to
        this page the next time you sign-in
      </WSText.ParagraphXs>
    </WSList>
  );
};

const ProgressBar: React.FC = () => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => {
        if (p >= 80) {
          clearInterval(interval);
        }

        return p + 10;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <WSProgressBar percent={progress} />;
};
