import React from "react";
import { useRouteMatch } from "react-router-dom";
import { FlatfileProvider } from "@flatfile/react";
import { BulkStatus } from "@wingspanhq/payments/dist/interfaces";
import { BATCH_LABELS_WS_KEYS } from "@wingspanhq/payments/dist/lib/constants";

import { WSQueries } from "../../../../query/WSQuery";

import { DefaultHeader } from "./DefaultHeader";
import { BulkResource } from "../../types";
import { getBulkQueriesByResource } from "../../query/bulkBatchResourceQueryMap";
import { BulkPayableOpenBatchHeader } from "../../routes/BulkImportPayableBatchDetails/BulkPayableOpenBatchHeader";
import { BulkCollaboratorOpenBatchHeader } from "../../routes/BulkImportCollaboratorBatchDetails/BulkCollaboratorOpenBatchHeader";
import { IS_PRODUCTION_ENV } from "../../../../shared/constants/environment";
import { flatfileProviderConfig } from "../../utils/flatfileThemeConfig";
import { isUploadStarted } from "../../utils/bulkBatchUtils";

interface BulkBatchDetailsHeaderProps {
  bulkResource: BulkResource;
}

export function BulkBatchDetailsHeader({
  bulkResource
}: BulkBatchDetailsHeaderProps) {
  const match = useRouteMatch<{ bulkBatchId: string }>();
  const { bulkBatchId } = match.params;

  const { useBulkBatch } = getBulkQueriesByResource(bulkResource);
  const qBulkBatch = useBulkBatch(bulkBatchId);

  let OpenBatchHeader = BulkPayableOpenBatchHeader;
  if (bulkResource === BulkResource.Collaborator) {
    OpenBatchHeader = BulkCollaboratorOpenBatchHeader;
  }
  return (
    <WSQueries queries={{ qBulkBatch }}>
      {({ qBulkBatch: { data: bulkBatch } }) => {
        const ffProviderProps = (bulkBatch.labels[
          BATCH_LABELS_WS_KEYS.flatfileAccessToken
        ] as string)
          ? {
              accessToken: bulkBatch.labels[
                BATCH_LABELS_WS_KEYS.flatfileAccessToken
              ] as string
            }
          : {
              publishableKey: process.env
                .REACT_APP_FLATFILE_PUBLISHABLE_KEY as string
            };
        return (
          <FlatfileProvider
            // Do not set key prop if upload is in progress
            {...(isUploadStarted(bulkBatch)
              ? {}
              : {
                  key:
                    ffProviderProps.accessToken ??
                    ffProviderProps.publishableKey
                })}
            config={{
              debug: !IS_PRODUCTION_ENV,
              ...flatfileProviderConfig
            }}
            {...ffProviderProps}
          >
            {bulkBatch.status === BulkStatus.Open ? (
              <OpenBatchHeader
                bulkBatchId={bulkBatchId}
                bulkResource={bulkResource}
              />
            ) : (
              <DefaultHeader
                bulkBatchId={bulkBatchId}
                bulkResource={bulkResource}
              />
            )}
          </FlatfileProvider>
        );
      }}
    </WSQueries>
  );
}
